import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";

import { deleteTaskCustomFieldAction } from "../../../../../store/actions/tasksAction";
import TrashIcon from "../../../../Dashboard-v2/icons/TrashIcon";

const CustomFields = ({
  item,
  customFields,
  setCustomFields,
  project,
  isNew,
  cardId,
  debouncedCreateHandler,
  debouncedChangeHandler,
}) => {
  const dispatch = useDispatch();

  const [isNewCustomField, setIsNewCustomField] = useState(false);
  const [customFieldsData, setCustomFieldsData] = useState({
    key: "",
    value: "",
  });

  const handleAddCustomField = () => {
    setIsNewCustomField(true);
  };

  const handleCustomFieldChange = (index, field, value) => {
    const newCustomFields = [...customFields];
    newCustomFields[index][field] = value;
    setCustomFields(newCustomFields);
  };

  const handleAddCustomFieldClick = () => {
    if (customFieldsData.key && customFieldsData.value) {
      const newCustomFields = [
        ...(item?.custom_fields || []),
        { key: customFieldsData.key, value: customFieldsData.value },
      ];

      setCustomFields(newCustomFields);
      setIsNewCustomField(false);
      setCustomFieldsData({ key: "", value: "" });

      const updatedItem = {
        ...item,
        custom_fields: newCustomFields,
      };

      if (isNew && !cardId) {
        debouncedCreateHandler(updatedItem);
      } else {
        debouncedChangeHandler(updatedItem);
      }
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        {customFields?.map((field, index) => (
          <div key={index} className="flex flex-col gap-1">
            <label
              htmlFor={field?.key}
              className="flex items-center justify-between px-1 text-xs font-bold text-Neutral900"
            >
              <span>{field?.key}</span>
              <Menu as="div" className="relative">
                <Menu.Button className="text-Red500">
                  <TrashIcon />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-50 origin-top-right bg-white rounded-md top-2 focus:outline-none right-5 w-max">
                    <div className="px-4 py-4">
                      <Menu.Item>
                        {({ close }) => (
                          <div className="flex flex-col">
                            <h5 className="pb-2 text-xs font-semibold border-b text-Neutral700 border-Neutral200">
                              Are you sure?
                            </h5>
                            <button
                              type="button"
                              className="w-full px-4 py-2 mt-3 text-[10px] font-semibold rounded text-Neutral000 bg-Red400 hover:bg-Red600 tracking-wide"
                              onClick={() => {
                                dispatch(
                                  deleteTaskCustomFieldAction(
                                    item?._id,
                                    field._id,
                                    project._id
                                  )
                                );
                              }}
                            >
                              Yes, Delete This Field
                            </button>
                            <button
                              onClick={close}
                              type="button"
                              className="w-full px-4 py-2 mt-2 text-[10px] tracking-wide font-semibold rounded text-Neutral700 bg-Neutral300 hover:bg-Neutral400"
                            >
                              No, I'll Still keep This Field
                            </button>
                          </div>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </label>
            <input
              id={field?.key}
              className="text-Neutral800 font-medium placeholder:text-Neutral500 py-2 px-3 placeholder:text-xs 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
              type="text"
              placeholder={field?.key}
              name={`custom_fields.${index}.value`}
              onChange={(e) =>
                handleCustomFieldChange(index, "value", e.target.value)
              }
              value={field?.value}
            />
          </div>
        ))}
      </div>

      {customFields.length < 5 && (
        <div className="flex items-center justify-between">
          <button
            type="button"
            className="text-xs font-medium text-AccentRegular"
            onClick={handleAddCustomField}
          >
            Add Custom Field
          </button>
          <span className="text-xs italic font-medium text-Neutral700">
            Optional
          </span>
        </div>
      )}

      {isNewCustomField && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <div className="flex items-center">
              <span className="text-xs font-bold text-Neutral900">
                Field Title
              </span>
            </div>
            <div className="mt-2">
              <input
                placeholder="Eg. Postcode"
                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 transition"
                type="text"
                name="key"
                onChange={(e) =>
                  setCustomFieldsData({
                    ...customFieldsData,
                    key: e.target.value,
                  })
                }
                value={customFieldsData.key}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <div className="flex items-center">
              <span className="text-xs font-bold text-Neutral900">
                Field Info
              </span>
            </div>
            <div className="mt-2">
              <input
                placeholder="Eg. 12345"
                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 transition"
                type="text"
                name="value"
                onChange={(e) =>
                  setCustomFieldsData({
                    ...customFieldsData,
                    value: e.target.value,
                  })
                }
                value={customFieldsData.value}
              />
            </div>
          </div>
          <div>
            <button
              className="relative block w-full py-3 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out rounded btn button-hover form-primary-btn text-Neutral100 bg-AccentRegular focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight"
              disabled={
                customFieldsData.key === "" || customFieldsData.value === ""
              }
              onClick={handleAddCustomFieldClick}
            >
              <span className="relative z-10">Add Custom Field</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomFields;
