import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clx from "classnames";
import moment from "moment";

import SectionTitle from "../../../common/typography/SectionTitle";
import AddIcon from "../../../Icons/AddIcon";
import ResetIcon from "../../../Icons/ResetIcon";
import ShevronLeftIcon from "../../../Icons/ShevronLeftIcon";
import TimeTrackingFilter from "./TimeTrackingFilter";
import ClockIcon from "../../../Icons/ClockIcon";
import TeamAvatarsList from "../../../common/lists/TeamAvatarsList";
import prepareTasksAndTimeCounter from "../../../../utils/prepareTasksAndTimeCounter";
import TimeTrackingTable from "./TimeTrackingTable";
import getCurrentWeekRange from "../../../../utils/getCurrentWeekRange";
import TrackerControl from "./TrackerControl";
import {
  GetProjectAction,
  GetProjectClientsList,
  GetProjectTimeEntryAction,
} from "../../../../store/actions/timeTrackingAction";
import loader from "../../../../assets/Images/loader-icon.svg";
import StartTimerModal from "../../../Modals/StartTimerModal/StartTimerModal";
import { useParams } from "react-router";

const monthsArr = moment.months();
const currentMonth = new Date().getMonth();
const currentWeek = moment().week();

const minutes_to_hhmm = (numberOfMinutes) => {
  //create duration object from moment.duration
  var duration = moment.duration(numberOfMinutes, "minutes");

  //calculate hours
  var hh =
    duration.years() * (365 * 24) +
    duration.months() * (30 * 24) +
    duration.days() * 24 +
    duration.hours();

  hh = hh <= 9 ? `0${hh}` : hh;

  //get minutes
  var mm =
    duration.minutes() <= 9 ? `0${duration.minutes()}` : duration.minutes();

  //return total time in hh:mm format
  return `${hh}:${mm}:00`;
};

const displayTime = (timeTrackingList) => {
  return minutes_to_hhmm(
    timeTrackingList?.data?.reduce(
      (acc, cur) =>
        (acc += moment
          .duration({
            hours: cur.time.split(":")[0],
            minutes: cur.time.split(":")[1],
          })
          .asMinutes()),
      0
    )
  );
};

function TimeTrackingModal({
  project = {},
  className = "",
  isOpen = false,
  setIsOpen = () => {},
  onSwitchModals = () => {},
  setIsTrackingEditModalOpen = () => {},
  addNewTimeEntry = null,
  setModalClientDetail = () => {},
  isStartTimerModalOpen = false,
  setIsStartTimerModalOpen = () => {},
  setTimeEntryList,
  timeEntryList,
}) {
  const dispatch = useDispatch();
  const isRunning = useSelector((state) => state.timer.isRunning);
  const [activeFilter, setActiveFilter] = useState("Total");
  const [week, setWeek] = useState(currentWeek);
  const [month, setMonth] = useState(currentMonth);
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const isReload = useSelector((state) => state.tracking.reload);
  const client = useSelector((state) => state.tracking.clientList);
  const params = useParams();
  const onDecrementClick = (filter) => {
    return () => {
      filter === "Monthly"
        ? setMonth((cur) => cur - 1)
        : setWeek((cur) => cur - 1);
    };
  };

  const onIncrementClick = (filter) => {
    return () => {
      filter === "Monthly"
        ? setMonth((cur) => cur + 1)
        : setWeek((cur) => cur + 1);
    };
  };

  const getTimeTrrackingEntries = async () => {
    setIsLoading(true);
    const start_date =
      (activeFilter === "Monthly" &&
        moment().month(month).startOf("month").format("YYYY-MM-DD")) ||
      (activeFilter === "Weekly" &&
        moment().week(week).startOf("week").format("YYYY-MM-DD")) ||
      "";

    const end_date =
      (activeFilter === "Monthly" &&
        moment().month(month).endOf("month").format("YYYY-MM-DD")) ||
      (activeFilter === "Weekly" &&
        moment().week(week).endOf("week").format("YYYY-MM-DD")) ||
      "";

    const res = await GetProjectTimeEntryAction(project?._id, {
      page: page,
      perPage: 10,
      start_date: start_date,
      end_date: end_date,
    });
    setTimeEntryList(res);
    setIsLoading(false);
  };

  useEffect(() => {
    if (params?.projectId == project?._id) getTimeTrrackingEntries();
  }, [
    dispatch,
    project?._id,
    activeFilter,
    week,
    month,
    page,
    addNewTimeEntry,
    isReload,
  ]);

  // useEffect(() => {
  //   dispatch(GetProjectAction({ page: page, perPage: 10 }));
  // }, [dispatch, page]);

  useEffect(() => {
    if (!client?.data?.length) {
      dispatch(GetProjectClientsList());
    }
  }, [dispatch, client?.data?.length]);

  useEffect(() => {
    // First, we sort the entries by date in descending order.
    const sortedData = [...(timeEntryList?.data ?? [])].sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });

    // Then, we group the sorted entries by their date.
    const groupedByDate = sortedData.reduce((acc, cur) => {
      // We'll use the date as the key for the grouping.
      const dateKey = new Date(cur.date).toISOString().split("T")[0]; // Formats the date as YYYY-MM-DD
      if (!acc[dateKey]) {
        acc[dateKey] = []; // Initialize an array if it doesn't exist for the dateKey
      }
      acc[dateKey].push(cur); // Push the current item into the array for that date
      return acc;
    }, {});

    setEvents(groupedByDate); // Finally, we update the state to the new grouped object
  }, [timeEntryList]);

  return (
    <div
      className="absolute top-0 left-0 h-full z-[1] w-full bg-Neutral000"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div
        className={clx(
          "flex flex-col p-[28px] pt-[23px] pr-9 rounded-xl bg-Neutral000 border-[0.5px] border-Neutral200 shadow-[0_4px_15px_rgba(0,0,0,0.08)]",
          className
        )}
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-between items-center">
            <SectionTitle
              text="Time Tracking"
              className="flex justify-center items-center mr-[78px]"
            />
            {/* date selector */}
            {activeFilter !== "Total" && (
              <div className="flex justify-between items-center w-[308px]">
                <button
                  className="flex justify-center items-center rounded-full bg-Neutral200 p-1 pl-[3px] disabled:opacity-50"
                  onClick={onDecrementClick(activeFilter)}
                  disabled={activeFilter === "Monthly" ? month === 0 : false}
                >
                  <ShevronLeftIcon />
                </button>
                <span className="flex justify-center items-center rounded text-AccentRegular text-[10px] leading-[14px] tracking-[0.04em] uppercase font-bold py-[5px] px-4 bg-Neutral200">
                  {activeFilter === "Monthly" && monthsArr[month]}
                  {activeFilter === "Weekly" && getCurrentWeekRange(week)}
                </span>
                <button
                  className="flex justify-center items-center rounded-full bg-Neutral200 p-1 pl-[3px] disabled:opacity-50"
                  onClick={onIncrementClick(activeFilter)}
                  disabled={
                    activeFilter === "Monthly"
                      ? month >= currentMonth
                      : week === currentWeek
                  }
                >
                  <ShevronLeftIcon rotated />
                </button>
              </div>
            )}
          </div>
          <button
            className="flex justify-center items-center hover:bg-Neutral100 transition-all duration-300 rounded-full"
            onClick={() => setIsOpen(false)}
            aria-label="CLose modal"
          >
            <AddIcon
              width={24}
              height={24}
              color="#E56780"
              className="rotate-45"
            />
          </button>
        </div>
        <div className="w-full flex justify-between mb-10 flex-col xl:flex-row">
          <div className="flex justify-between mt-5">
            {/* time tracking filter */}
            <div className="flex justify-stretch items-stretch">
              <TimeTrackingFilter
                active={activeFilter}
                setActive={setActiveFilter}
              />
            </div>

            {/* time data */}
            <div className="flex justify-start items-stretch w-[308px] max-h-[140px]">
              <div className="min-w-[96px] flex flex-col items-center justify-between rounded border-[0.5px] border-Neutral400 pb-2 px-[12px] pt-[18px] mr-2">
                <ClockIcon
                  width={24}
                  height={24}
                  color="#6D6DF2"
                  filled
                  className="mb-[6px]"
                />
                <span className="leading-[100%] tracking-[-0.04em] text-Neutral900 text-5xl font-normal text-center mb-[20px]">
                  {/* {entries} */}
                  {prepareTasksAndTimeCounter(
                    activeFilter === "Total"
                      ? project?.total_entries
                      : timeEntryList?.data?.length
                  )}
                </span>
                <span className="leading-[14px] tracking-[0.02em] text-[10px] font-normal uppercase text-Neutral700 text-center opacity-90">
                  Time entries
                </span>
              </div>
              {/* time group */}
              <div className="w-100% max-w-[308px] flex flex-wrap gap-1">
                <div className="max-h-[68px] flex flex-col items-center justify-between rounded border-[0.5px] border-Neutral400 min-w-[100px] pt-4 pb-2 grow">
                  <span
                    className={`leading-[80%] tracking-[-0.04em] text-Neutral800 text-[32px] font-extrabold mb-[4px]`}
                  >
                    {/* {hours} */}
                    {prepareTasksAndTimeCounter(
                      activeFilter === "Total"
                        ? project?.total_time
                          ? project?.total_time?.split(":")[0].charAt(0) === "0"
                            ? project?.total_time?.split(":")[0].charAt(1)
                            : project?.total_time?.split(":")[0]
                          : 0
                        : displayTime(timeEntryList).split(":")[0].charAt(0) ===
                          "0"
                        ? displayTime(timeEntryList).split(":")[0].charAt(1)
                        : displayTime(timeEntryList).split(":")[0],
                      true
                    )}
                  </span>
                  <span className="leading-[14px] tracking-[0.02em] text-[10px] font-normal uppercase text-Neutral700 opacity-90">
                    hrs
                  </span>
                </div>

                <div className="max-h-[68px] flex flex-col items-center justify-between rounded border-[0.5px] border-Neutral400 min-w-[100px] pt-4 pb-2 grow">
                  <span
                    className={`leading-[80%] tracking-[-0.04em] text-Neutral600 text-[32px] font-extrabold mb-[4px]`}
                  >
                    {/* {minutes} */}
                    {prepareTasksAndTimeCounter(
                      activeFilter === "Total"
                        ? project?.total_time
                          ? project?.total_time?.split(":")[1].charAt(0) === "0"
                            ? project?.total_time?.split(":")[1].charAt(1)
                            : project?.total_time?.split(":")[1]
                          : 0
                        : displayTime(timeEntryList).split(":")[1].charAt(0) ===
                          "0"
                        ? displayTime(timeEntryList).split(":")[1].charAt(1)
                        : displayTime(timeEntryList).split(":")[1],
                      true
                    )}
                  </span>
                  <span className="leading-[14px] tracking-[0.02em] text-[10px] font-normal uppercase text-Neutral700 opacity-90">
                    mins
                  </span>
                </div>

                {/* members */}
                <div className="max-h-[68px] flex flex-col items-center justify-between rounded border-[0.5px] border-Neutral400 min-w-[204px] pt-3 pb-2 grow">
                  <TeamAvatarsList
                    users={project?.time_contributors}
                    className="mb-[6px]"
                    limit={5}
                  />
                  <span className="leading-[14px] tracking-[0.02em] text-[10px] font-normal uppercase text-Neutral700 opacity-90">
                    Time Contributors
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* controls */}
          <div className="flex flex-wrap justify-start items-start self-center justify-self-end gap-x-3 gap-y-[17px] w-[372px] py-4 mt-5">
            <button
              className="min-w-[180px] h-[48px] rounded flex justify-center items-center leading-[110%] tracking-[0.04em] text-xs text-Neutral000 bg-AccentRegular uppercase font-bold button-hover"
              onClick={() => {
                setIsTrackingEditModalOpen(true);
                setModalClientDetail(project);
              }}
            >
              Add Time Entry
            </button>
            <TrackerControl
              isRunning={isRunning}
              project={project}
              setIsStartTimerModalOpen={setIsStartTimerModalOpen}
            />
            {isStartTimerModalOpen && (
              <StartTimerModal
                isStartTimerModalOpen={isStartTimerModalOpen}
                setIsStartTimerModalOpen={setIsStartTimerModalOpen}
                selectedProject={project._id}
                selectedClient={project?.client?._id || project?.client}
              />
            )}
            <button
              className="w-full min-w-[372px] h-[48px] rounded flex justify-center items-center leading-[110%] tracking-[0.04em] text-xs text-AccentRegular border border-AccentRegular uppercase font-bold  transition duration-300 hover:bg-AccentLight"
              onClick={onSwitchModals}
            >
              <ResetIcon className="mr-2" color="#6D6DF2" /> Switch To Tasks
            </button>
          </div>
        </div>
        {/* time tracking table */}
        {isLoading ? (
          <div className="flex justify-center mt-10 mb-10">
            <img
              src={loader}
              alt="loader"
              className="animate-spin relative drop-shadow-Texts z-10 h-[30px]"
            />
          </div>
        ) : timeEntryList?.data?.length > 0 ? (
          <TimeTrackingTable
            events={events}
            setEvents={setTimeEntryList}
            timeEntryList={timeEntryList}
            setPage={setPage}
          />
        ) : (
          <div className="pt-[10px] px-[32px] flex flex-col justify-center items-center">
            <span className="text-Neutral700 font-normal text-sm leading-[24px] tracking-[0.02em]">
              There are currently no time entries for this project.
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default TimeTrackingModal;
