import React, { useEffect, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { useDropzone } from "react-dropzone";
import Add from "../../assets/Images/Add.svg";
import NewFolderIcon from "../Icons/FolderIcons/NewFolderIcon.js";
import AddEditDocumentModal from "../Modals/AddEditDocumentModal.jsx";
import EditDocumentModal from "../Modals/EditDocumentModal.jsx";
import AddEditAssetsModal from "../Modals/AddEditAssetsModal.jsx";
import EditAssetsModal from "../Modals/EditAssetsModal.jsx";

const baseStyle = {
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#EDEDF2",
};

const acceptStyle = {
  opacity: 1,
  borderColor: "#6D6DF2",
};

const rejectStyle = {
  borderColor: "#6D6DF2",
};

function DocumentBox({
  type,
  icon,
  title,
  folders,
  files,
  addDocument,
  openModal,
  RenderFolder,
  handleDropFile,
  RenderFile,
  handleDeleted,
  clientId,
  isNewDocumentModalOpen,
  setIsNewDocumentModalOpen,
  editData,
  setEditData,
  noDataContent,
  assetId,
}) {
  const [, drop] = useDrop({
    accept: "file",
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        handleDropFile(item._id);
      }
    },
  });

  const [dragFile, setDragFile] = useState(null);
  const [accountCencelled, setaccountCencelled] = useState(false);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    disabled: type === "invoices" || type === "contracts",
  });

  useEffect(() => {
    const tempDragFile = [];
    acceptedFiles.map((file) => {
      tempDragFile.push(file);
    });
    if (tempDragFile?.length) {
      setDragFile(tempDragFile);
      setIsNewDocumentModalOpen(true);
    }
  }, [acceptedFiles]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  if (!folders) return null;

  return (
    <>
      <div
        {...getRootProps({ style })}
        className={`${
          isDragAccept && "bg-[#fafafa]"
        } w-[388px] h-[264px] border border-Neutral300 p-6 rounded relative md:mb-[60px] mb-[85px]`}
      >
        <input {...getInputProps()} />
        {isDragAccept && (
          <p className="text-sm absolute bottom-[43%] left-[8%] text-normal text-Neutral800 cursor-pointer hover:text-AccentRegular duration-300 truncate">
            Drag 'n' drop some files here, or click to select files
          </p>
        )}
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div
              className={`w-8 h-8 rounded-full flex items-center justify-center ${
                ["documents", "media", "designs"].includes(type)
                  ? "bg-Red400"
                  : "bg-Blue400"
              }`}
            >
              <img className="w-6 h-6" src={icon} alt={title} />
            </div>
            <h4 className="font-bold text-sm text-Neutral900 ml-2">{title}</h4>
          </div>
          <div className="flex items-center justify-between gap-2">
            <button
              className="w-8 h-8 rounded-full flex items-center justify-center border border-Neutral300 hover:border-AccentRegular transition"
              onClick={openModal}
            >
              <NewFolderIcon />
            </button>
            <button
              className="w-8 h-8 rounded-full flex items-center justify-center border border-Neutral300 hover:border-AccentRegular transition"
              onClick={addDocument}
            >
              <img className="w-6 h-6" src={Add} alt="" />
            </button>
          </div>
        </div>
        <div
          className={`mt-4 h-[188px] overflow-y-auto scrollbar-w-4 -mr-3 pr-3 pb-5 ${
            isDragAccept && "opacity-30"
          }`}
        >
          <div ref={drop} className="w-full h-full">
            {Array.isArray(folders) && folders.length > 0 && (
              <>
                {folders.map((folder) => (
                  <RenderFolder
                    key={folder._id}
                    folder={folder}
                    onDropFile={handleDropFile}
                    files={files}
                    setaccountCencelled={setaccountCencelled}
                    assetId={assetId}
                  />
                ))}
              </>
            )}

            {Array.isArray(files) &&
              files.length > 0 &&
              files
                .filter(
                  (file) =>
                    !(folders || []).some((folder) =>
                      folder.files.includes(file._id)
                    )
                )
                .map((file) => (
                  <RenderFile
                    type={type}
                    key={file._id}
                    file={file}
                    assetId={assetId}
                    handleDeleted={handleDeleted}
                    setaccountCencelled={setaccountCencelled}
                  />
                ))}

            {(!Array.isArray(folders) || folders.length === 0) &&
            (!Array.isArray(files) || files.length === 0) ? (
              ["documents", "media", "designs"].includes(type) ? (
                <div className="absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full max-w-[208px]">
                  <img src={noDataContent} alt="" />
                </div>
              ) : (
                noDataContent
              )
            ) : null}
          </div>
        </div>
      </div>

      {["documents", "media", "designs"].includes(type) ? (
        <>
          <AddEditAssetsModal
            isModal={isNewDocumentModalOpen}
            setModal={setIsNewDocumentModalOpen}
            type={type}
            item={files}
            clientId={clientId}
            dragFile={dragFile}
            setDragFile={setDragFile}
          />
          <EditAssetsModal
            accountCencelled={accountCencelled}
            setaccountCencelled={setaccountCencelled}
            editData={editData}
            setEditdata={setEditData}
            type={type}
            item={files}
            clientId={clientId}
          />
        </>
      ) : (
        <>
          <AddEditDocumentModal
            isModal={isNewDocumentModalOpen}
            setModal={setIsNewDocumentModalOpen}
            type={type}
            clientId={clientId}
            item={files}
            dragFile={dragFile}
            setDragFile={setDragFile}
          />
          <EditDocumentModal
            accountCencelled={accountCencelled}
            setaccountCencelled={setaccountCencelled}
            editData={editData}
            setEditdata={setEditData}
            type={type}
            item={files}
            clientId={clientId}
          />
        </>
      )}
    </>
  );
}

export default DocumentBox;
