import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import clx from "classnames";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  DeleteNotification,
  MarkReadNotifications,
} from "../../../store/actions/notificationAction";
import Bag from "../../../assets/Images/Bag.svg";
import Question from "../../../assets/Images/Question.svg";
import CloseIcon from "../../../assets/Images/Closedark.svg";
import moment from "moment";
import { useRef } from "react";
import NotificationsEmptyState from "./NotificationsEmptyState";
import ReactTooltip from "react-tooltip";

const Notification = ({ notificationsList, className = "" }) => {
  const notificationRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newNotificatoin, setNewNotification] = useState(false);

  // const fetchMoreData = async () => {
  //   window.socket.emit("more_notification", { page: page });
  // };

  useEffect(() => {
    if (notificationsList) {
      const have_notification_dot = notificationsList.filter(
        (e) => !e?.is_read
      );
      if (have_notification_dot && have_notification_dot.length) {
        setNewNotification(true);
      } else {
        setNewNotification(false);
      }
    }
  }, [notificationsList]);

  const markReadNotification = async () => {
    dispatch(MarkReadNotifications(notificationsList));
    setNewNotification(false);
  };

  const deleteNotification = async (id) => {
    dispatch(DeleteNotification(id, notificationsList));
  };

  useEffect(() => {
    if (notificationRef.current) {
      notificationRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [notificationsList]);

  return (
    <Menu
      as="div"
      className={clx("relative inline-block text-left mx-[10px] ", className)}
    >
      <div
        className="flex items-center"
        data-tip="Notifications"
        data-for="notification"
      >
        <Menu.Button className="inline-flex justify-center w-full focus-visible:outline-none popup-btn relative p-1">
          <span
            className={`focus-visible:outline-none relative ${
              newNotificatoin ? "notification-teg" : null
            }`}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.4993 8.39967C17.4993 6.96759 16.9304 5.59416 15.9178 4.58153C14.9051 3.56889 13.5317 3 12.0996 3C10.6675 3 9.29412 3.56889 8.28149 4.58153C7.26885 5.59416 6.69996 6.96759 6.69996 8.39967C6.69996 14.6993 4.00012 16.4992 4.00012 16.4992H20.1991C20.1991 16.4992 17.4993 14.6993 17.4993 8.39967Z"
                stroke="#5A5A66"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.6565 20.0986C13.4983 20.3714 13.2712 20.5978 12.998 20.7552C12.7247 20.9126 12.415 20.9954 12.0996 20.9954C11.7843 20.9954 11.4745 20.9126 11.2013 20.7552C10.928 20.5978 10.7009 20.3714 10.5427 20.0986"
                stroke="#5A5A66"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </Menu.Button>
      </div>
      <ReactTooltip
        place="bottom"
        textColor="#F5F5F7"
        className="tooltip-font-size"
        backgroundColor="#292933"
        id="notification"
      />
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute top-[36px] right-[-8px] mt-2 w-[380px] rounded-lg dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none">
          <div className="py-5 px-6 flex justify-between">
            <h3 className="text-Neutral700 text-xs font-bold tracking-[0.08em] uppercase">
              notifications
            </h3>
            {notificationsList?.length ? (
              <button
                className="text-AccentRegular text-xs font-medium"
                onClick={markReadNotification}
              >
                Mark all as read
              </button>
            ) : null}
          </div>

          {notificationsList.length === 0 ? (
            <NotificationsEmptyState />
          ) : (
            <div>
              <div
                className="checkbox-main relative pt-2 mr-2 pb-8 h-[280px] overflow-y-auto"
                // onScroll={(e) => {
                //   if (
                //     e.target.scrollHeight ===
                //     Math.abs(e.target.scrollTop) + e.target.clientHeight
                //   ) {
                //     fetchMoreData();
                //   }
                // }}
              >
                <div className="px-6 pt-4">
                  {notificationsList?.map((item, index) => {
                    return (
                      <div
                        className="flex items-center justify-between mb-[28px]"
                        key={index}
                      >
                        <div className="flex items-center">
                          <div className="relative">
                            {item?.from ? (
                              item?.from ? (
                                item?.from?.profile &&
                                item?.from?.profile_path ? (
                                  <img
                                    className="h-[48px] w-[48px]"
                                    src={item?.from?.profile_path}
                                    alt=""
                                  />
                                ) : (
                                  <div
                                    className={`border border-Neutral300 p-5 h-[48px] w-[48px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700 focus-visible:outline-none`}
                                  >
                                    <span className=" text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey">
                                      {item?.from?.user_name[0]}
                                    </span>
                                  </div>
                                )
                              ) : null
                            ) : item?.client ? (
                              item?.client?.profile &&
                              item?.client?.profile_path ? (
                                <img
                                  className="h-[48px] w-[48px]"
                                  src={item?.client?.profile_path}
                                  alt=""
                                />
                              ) : (
                                <div
                                  className={`border border-Neutral300 p-5 h-[48px] w-[48px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700 focus-visible:outline-none`}
                                >
                                  <span className=" text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey">
                                    {item?.client?.client_name[0]}
                                  </span>
                                </div>
                              )
                            ) : null}

                            {item?.meeting ? (
                              <div
                                className={`border border-Neutral300 p-5 h-[48px] w-[48px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700 focus-visible:outline-none`}
                              >
                                <span className=" text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey">
                                  {item?.meeting?.attendee_name[0]}
                                </span>
                              </div>
                            ) : null}

                            {item?.client || item?.from ? (
                              item?.type === "signed_proposal" ||
                              "signed_contract" ? (
                                <span className="h-[24px] w-[24px] bg-Orange100 rounded-full flex items-center justify-center border-2 border-Neutral000 absolute top-[-8px] right-[-8px]">
                                  <img className="h-4 w-4" src={Bag} alt="" />
                                </span>
                              ) : (
                                <span className="h-[24px] w-[24px] bg-Blue100 rounded-full flex items-center justify-center border-2 border-Neutral000 absolute top-[-8px] right-[-8px]">
                                  <img
                                    className="h-4 w-4"
                                    src={Question}
                                    alt=""
                                  />
                                </span>
                              )
                            ) : null}
                          </div>
                          <div
                            className="ml-4 cursor-pointer"
                            onClick={() => {
                              if (
                                (item?.type === "signed_proposal" ||
                                  item?.type === "viewed_proposal") &&
                                item?.proposal
                              )
                                navigate(
                                  `/admin/clients/manage/${item?.client?._id}/proposal/${item?.proposal}`
                                );
                              if (
                                item?.type === "viewed_invoice" &&
                                item?.invoice
                              )
                                navigate(
                                  `/admin/clients/manage/${item?.client?._id}/invoice/${item?.invoice}`
                                );
                              if (
                                (item?.type === "signed_contract" ||
                                  item?.type === "viewed_contract") &&
                                item?.contract
                              )
                                navigate(
                                  `/admin/clients/manage/${item?.client?._id}/contract/${item?.contract}`
                                );
                              if (
                                item?.type === "questionnaire_complate" &&
                                item?.questionnaire
                              )
                                navigate(
                                  `/request-info/${item?.questionnaire}`
                                );
                              if (
                                (item?.type === "task_assign" && item?.task) ||
                                (item?.type === "task_tag" && item?.task) ||
                                (item?.type === "task_priority" &&
                                  item?.task) ||
                                (item?.type === "task_comment" &&
                                  item?.task &&
                                  item?.task_comment)
                              )
                                navigate(
                                  `/admin/clients/manage/${item?.client?._id}/project/${item?.task?.project}?cardId=${item?.task?._id}`
                                );
                              if (
                                item?.type === "reminder_lead_follow_up" &&
                                item?.lead
                              )
                                navigate(
                                  `/admin/leads?cardId=${item?.lead?._id}`
                                );

                              if (
                                item?.type === "confirmed_meeting" &&
                                item?.meeting
                              )
                                navigate(`/admin/meetings`);
                            }}
                          >
                            <h5 className="font-normal text-xs text-Neutral800">
                              <span className="font-bold text-Neutral900">
                                {item?.from
                                  ? item?.from?.user_name
                                    ? item?.from?.user_name
                                    : null
                                  : item?.client?.client_name
                                  ? item?.client?.client_name
                                  : null}
                                {item?.meeting
                                  ? item?.meeting?.attendee_name
                                  : null}{" "}
                              </span>{" "}
                              {item?.text}
                            </h5>
                            <p className="font-medium text-[10px] 5xl:text-sm  text-Neutral600">
                              {moment(item?.createdAt).fromNow()}
                            </p>
                          </div>
                        </div>
                        <div onClick={() => deleteNotification(item?._id)}>
                          <img
                            className="cursor-pointer opacity-50 hover:opacity-100 transition duration-300"
                            src={CloseIcon}
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div ref={notificationRef} />
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Notification;
