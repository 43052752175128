import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getMyTasksAction,
  getTasksCountByMemberAction,
  getUpcomingTaskCountAction,
} from "../../store/actions/dashboard-v2";
import BoltIcon from "./icons/BoltIcon";
import ChevronDown from "./icons/ChevronDown";
import TasksOverviewModal from "./modals/TasksOverviewModal";
import UpcomingTasksOverviewModal from "./modals/UpcomingTasksOverviewModal";
import YourTasksOverviewModal from "./modals/YourTasksOverviewModal";

function TaskSection() {
  const dispatch = useDispatch();
  const upcomingTaskCount = useSelector(
    (state) => state.dashboard.upcomingTaskCount
  );
  const tasksCount = useSelector((state) => state.dashboard.taskCount);
  const tasksCountByMember = useSelector(
    (state) => state.dashboard.tasksCountByMember
  );
  const yourTasks = useSelector((state) => state.dashboard.myTasks);

  const [upcomingTaskMonthFilter, setUpcomingTaskMonthFilter] =
    useState("month");
  const [tasksCountFilter, setTasksCountFilter] = useState("all");
  const [yourTasksMonthFilter, setYourTasksMonthFilter] = useState("all");

  // Loading states
  const [loading, setLoading] = useState(false);

  const [isTasksOverviewModalOpen, setIsTasksOverviewModalOpen] =
    useState(false);
  const [isYourTasksOverviewModalOpen, setIsYourTasksOverviewModalOpen] =
    useState(false);
  const [
    isUpcomingTasksOverviewModalOpen,
    setIsUpcomingTasksOverviewModalOpen,
  ] = useState(false);

  useEffect(() => {
    dispatch(getUpcomingTaskCountAction(upcomingTaskMonthFilter));
  }, [dispatch, upcomingTaskMonthFilter]);

  useEffect(() => {
    dispatch(getTasksCountByMemberAction(tasksCountFilter));
  }, [dispatch, tasksCountFilter]);

  useEffect(() => {
    dispatch(getMyTasksAction("all", yourTasksMonthFilter, setLoading));
  }, [dispatch, yourTasksMonthFilter]);

  return (
    <div className="flex flex-col gap-5 md:flex-row">
      <div
        className="flex-1 px-4 py-5 space-y-5 rounded-lg cursor-pointer bg-Neutral100 hover:bg-Neutral200"
        onClick={() => setIsTasksOverviewModalOpen(true)}
      >
        <div className="flex items-center justify-between">
          <h3 className="text-base text-Neutral900">All Tasks</h3>
          <span className="grid p-1 rounded-full bg-Blue400 place-items-center text-Neutral000">
            <BoltIcon className="size-5" />
          </span>
        </div>
        <div className="flex items-end justify-between">
          <h3 className="text-3xl font-light tracking-tighter md:text-6xl">
            {tasksCount.data?.allTasks}
          </h3>
          <span className="px-3 py-1 bg-green-200 rounded-xl text-Neutral900 text-[10px] tracking-wide font-semibold">
            +{tasksCount.data?.thisMonthAllTasks}
          </span>
        </div>
      </div>
      <div
        className="flex-1 px-4 py-5 space-y-5 rounded-lg cursor-pointer bg-Neutral100 hover:bg-Neutral200"
        onClick={() => setIsYourTasksOverviewModalOpen(true)}
      >
        <div className="flex items-center justify-between">
          <h3 className="text-base text-Neutral900">Your Tasks</h3>
          <span className="grid p-1 rounded-full bg-Blue400 place-items-center text-Neutral000">
            <BoltIcon className="size-5" />
          </span>
        </div>
        <div className="flex items-end justify-between">
          <h3 className="text-3xl font-light tracking-tighter md:text-6xl">
            {tasksCount.data?.yourTasks}
          </h3>
          <span className="px-3 py-1 bg-green-200 rounded-xl text-Neutral900 text-[10px] tracking-wide font-semibold">
            +{tasksCount.data?.thisMonthYourTasks}
          </span>
        </div>
      </div>
      <div
        className="flex-1 px-4 py-5 space-y-5 rounded-lg cursor-pointer bg-Neutral100 hover:bg-Neutral200"
        onClick={() => setIsUpcomingTasksOverviewModalOpen(true)}
      >
        <div className="flex items-center justify-between">
          <h3 className="text-base text-Neutral900">Upcoming Tasks</h3>
          <Menu as="div" className="relative">
            <Menu.Button
              className="inline-flex items-center gap-1 py-1 pl-2 pr-1 text-xs font-medium border border-Blue200 rounded-3xl text-Blue800"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {upcomingTaskMonthFilter === "month"
                ? "Monthly"
                : upcomingTaskMonthFilter === "week"
                ? "Weekly"
                : ""}
              <ChevronDown className="text-Blue800 size-4" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                as="div"
                className="absolute z-20 w-full p-1 mt-1 border divide-y rounded-md shadow-xl bg-Neutral000 border-Neutral300 divide-Blue100"
              >
                <Menu.Item
                  as="button"
                  type="button"
                  className="w-full p-1 text-sm text-left rounded text-Neutral800 hover:bg-Blue100"
                  onClick={(e) => {
                    e.stopPropagation();
                    setUpcomingTaskMonthFilter("week");
                  }}
                >
                  Weekly
                </Menu.Item>
                <Menu.Item
                  as="button"
                  type="button"
                  className="w-full p-1 text-sm text-left rounded text-Neutral800 hover:bg-Blue100"
                  onClick={(e) => {
                    e.stopPropagation();
                    setUpcomingTaskMonthFilter("month");
                  }}
                >
                  Monthly
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className="flex items-end justify-between">
          <h3 className="text-3xl font-light tracking-tighter md:text-6xl">
            {upcomingTaskCount.data?.upcomingTasks}
          </h3>
          <span className="px-3 py-1 bg-green-200 rounded-xl text-Neutral900 text-[10px] tracking-wide font-semibold">
            +{upcomingTaskCount.data?.thisDayUpcomingTasks}
          </span>
        </div>
      </div>

      <TasksOverviewModal
        isOpen={isTasksOverviewModalOpen}
        setOpen={setIsTasksOverviewModalOpen}
        data={tasksCountByMember.data}
        filter={tasksCountFilter}
        setFilter={setTasksCountFilter}
      />
      <YourTasksOverviewModal
        isOpen={isYourTasksOverviewModalOpen}
        setOpen={setIsYourTasksOverviewModalOpen}
        data={yourTasks.data}
        filter={yourTasksMonthFilter}
        setFilter={setYourTasksMonthFilter}
        loading={loading}
      />
      <UpcomingTasksOverviewModal
        isOpen={isUpcomingTasksOverviewModalOpen}
        setOpen={setIsUpcomingTasksOverviewModalOpen}
        priority="upcoming"
      />
    </div>
  );
}

export default TaskSection;
