import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CollabLoginAction } from "../../store/actions/collabAuthAction";
import SigninBg from "../../assets/Images/signin-bg-shap.png";
import Logo from "../../assets/Images/Logo.svg";
import PasswordIcon from "../../assets/Images/password.svg";
import PasswordShowIcon from "../../assets/Images/password-show.svg";
import ButtonLoader from "../Loaders/ButtonLoader";
import { Formik } from "formik";
import * as Yup from "yup";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Email is not valid").required("Email Required"),
  password: Yup.string()
    .required("Password Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\-])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

const ClientCollabSignin = () => {
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <>
      <section>
        <div className="flex">
          <div className="w-full md:h-screen h-full flex md:flex-row flex-col md:items-center justify-center relative px-3 overflow-y-auto">
            <div className="w-full max-w-[320px] 5xl:max-w-[400px] md:mt-0 mt-12 2xl:pt-0 md:pt-24 md:mx-0 mx-auto">
              <h1 className="mt-3 text-[32px] 5xl:text-[36px] font-bold mb-[8px] text-Neutral900">
              Collab On A Project<span className="text-AccentRegular">.</span>
              </h1>
              <div className="text-base text-Neutral700 mb-[32px]">
              Sign In to get started.
              </div>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  dispatch(
                    CollabLoginAction(
                      { ...values, email: values.email.toLowerCase() },
                      setSubmitting,
                      setErrors,
                    )
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-[24px]">
                      <label className="text-Neutral900 font-bold text-xs">
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 text-sm placeholder:text-sm 5xl:placeholder:text-lg leading-5 w-full focus-visible:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow transition duration-300 first-letter:${
                            errors.email && touched.email
                              ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                              : ""
                          }`}
                          type="email"
                          placeholder="skywalker@mail.com"
                          name="email"
                          onChange={(e) => {
                            setFieldValue(
                              "email",
                              e.target.value.toLowerCase()
                            );
                          }}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                      </div>
                      {errors.email && touched.email && (
                        <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                          {errors.email}
                        </p>
                      )}
                    </div>
                    <div className="mb-2">
                      <label className="text-Neutral900 font-bold text-xs">
                        Password
                      </label>
                      <div className="relative mt-2">
                        <input
                          className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 text-sm placeholder:text-sm 5xl:placeholder:text-lg leading-5 w-full focus-visible:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 focus:drop-shadow-Purpleboxshadow transition ease-in-out 
                        ${
                          errors.password && touched.password
                            ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                            : ""
                        }`}
                          type={passwordShown ? "text" : "password"}
                          placeholder="••••••••••••"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        <img
                          className="w-full absolute right-[12px] top-[8px] max-w-[32px] cursor-pointer"
                          src={passwordShown ? PasswordShowIcon : PasswordIcon}
                          alt="PasswordIcon"
                          onClick={togglePasswordVisiblity}
                        />
                      </div>
                      {errors.password && touched.password && (
                        <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none">
                          {errors.password}
                        </p>
                      )}
                    </div>
                    <Link
                      to="/client-collab/forgot-password"
                      className="text-xs text-AccentRegular font-medium focus-visible:outline-none"
                    >
                      Forgot your password?
                    </Link>
                    <div className="mt-[48px] 2xl:pb-0 md:pb-16 pb-10">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-4 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                      >
                        {isSubmitting ? (
                          <ButtonLoader className="z-10" />
                        ) : (
                          <span className="relative z-10">Sign In</span>
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
              <div className="lg:flex hidden justify-end mr-4 mb-4 absolute bottom-0 right-0">
                <p className=" text-Neutral800 text-xs font-medium">
                  Have a question? Email us at
                  <span className="text-AccentRegular"> support@clientmanager.io</span>
                </p>
              </div>
            </div>
           
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientCollabSignin;
