import axios from "axios";
import { toast } from "react-toastify";
import {
  GetProjectDetailAction,
  GetCollabProjectDetailAction,
} from "./projectAction";

export const GetTasksActions = (id, projectId, is_archived, setIsLoading) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/task/get-tasks-by-client?id=${id}&is_archived=${is_archived}&project_id=${projectId}`
      );
      if (setIsLoading) setIsLoading({ status: false, id: "" });
      return dispatch({
        type: "SET_TASKS",
        payload: {
          client: id,
          data: data.data,
          isLoading: false,
          is_archived: is_archived,
          project: projectId,
        },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "GET_TASKS_ERROR",
        payload: error,
      });
    }
  };
};

export const GetCollabTasksActions = (
  id,
  projectId,
  is_archived,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/task/get-tasks-by-client-for-collab?id=${projectId}`
      );
      if (setIsLoading) setIsLoading({ status: false, id: "" });
      return dispatch({
        type: "SET_TASKS",
        payload: {
          client: id,
          data: data.data,
          isLoading: false,
          is_archived: is_archived,
          project: projectId,
        },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "GET_TASKS_ERROR",
        payload: error,
      });
    }
  };
};

export const CreateTaskActions = (
  value,
  setTaskId,
  setCallApi,
  setUnsavedChanges,
  setNewTask
) => {
  return async (dispatch) => {
    try {
      setCallApi && setCallApi(true);
      const { data } = await axios.post("api/v1/task/create-task", value);
      setUnsavedChanges && setUnsavedChanges(false);
      if (data.data?.project) {
        if (value?.user_type === "client_user") {
          dispatch(GetCollabProjectDetailAction(data.data?.project));
        } else {
          dispatch(GetProjectDetailAction(data.data?.project));
        }
      }
      if (setTaskId && data.data?._id) {
        if (value?.user_type === "client_user") {
          dispatch(
            GetCollabTasksActions(value?.client_id, value?.project, false)
          );
        } else {
          dispatch(GetTasksActions(value?.client_id, value?.project, false));
        }

        setNewTask && setNewTask(false);
        setTaskId(data.data?._id);
      }
      setCallApi && setCallApi(false);
      return dispatch({
        type: "CREATE_TASK_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "CREATE_TASK_ERROR",
          payload: error,
        });
      } else {
        toast.error("Task has been not created")
        return dispatch({
          type: "CREATE_TASK_ERROR",
          payload: error,
        });
      }
    }
  };
};

let cancelToken;
export const UpdateTaskActions = (value, setCallApi) => {
  return async (dispatch) => {
    try {
      setCallApi && setCallApi(true);
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      cancelToken = axios.CancelToken.source();
      const { data } = await axios.put("api/v1/task/update-task", value, {
        cancelToken: cancelToken.token,
      });
      if (data.data?.project) {
        if (value?.user_type === "client_user") {
          dispatch(GetCollabProjectDetailAction(data.data?.project));
        } else {
          dispatch(GetProjectDetailAction(data.data?.project));
        }
      }
      setCallApi && setCallApi(false);
      return dispatch({
        type: "UPDATE_TASK_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        return dispatch({
          type: "UPDATE_TASK_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "UPDATE_TASK_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateTaskStatusActions = (
  value,
  update_store,
  old_data,
  data
) => {
  return async (dispatch) => {
    try {
      const { data: res } = await axios.put(
        `api/v1/task/update-task-status?id=${data?.id}&project_id=${data?.project}`,
        value
      );

      if (res?.data) {
        if (value?.user_type === "client_user") {
          dispatch(GetCollabProjectDetailAction(data?.project));
        } else {
          dispatch(GetProjectDetailAction(data?.project));
        }
      }
      return dispatch({
        type: "UPDATE_TASK_COLUMN_CHANGE_SUCCESS",
        payload: { data: update_store },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_TASK_ABORT",
        payload: { data: old_data },
      });
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "UPDATE_TASK_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "UPDATE_TASK_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteTaskAction = (value, handleClose) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/task/delete-task?id=${value?._id}`,
        value
      );
      if (data?.success) {
        if (value?.user_type === "client_user") {
          dispatch(GetCollabProjectDetailAction(value?.project));
        } else {
          dispatch(GetProjectDetailAction(value?.project));
        }

        handleClose();
      }
      return dispatch({
        type: "DELETE_TASK_SUCCESS",
        payload: { id: value?._id, project: value?.project },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_TASK_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_TASK_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const ArchiveTaskAction = (value, handleClose, setIsArchiveLoading) => {
  return async (dispatch) => {
    try {
      await axios.post(`api/v1/task/archive-task?id=${value?._id}`);
      setIsArchiveLoading(false);
      handleClose();
      return dispatch({
        type: "ARCHIVE_TASK_SUCCESS",
        payload: { id: value?._id, project: value?.project },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        setIsArchiveLoading(false);
        return dispatch({
          type: "ARCHIVE_TASK_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        setIsArchiveLoading(false);
        return dispatch({
          type: "ARCHIVE_TASK_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const GetTaskByDueDate = (start_date, end_date) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/task/get-task-by-due-date?start_date=${start_date}&end_date=${end_date}`
      );
      return dispatch({
        type: "SET_TASKS_BY_DUE_DATE",
        payload: {
          isLoading: false,
          start_date: start_date,
          end_date: end_date,
          data: data.data,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "EVENT_LIST_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "EVENT_LIST_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const addNewSubTask = (value, setIsNewSubtask, setNewSubtask) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("api/v1/task/add-sub-task", value);
      setIsNewSubtask(false);
      setNewSubtask({
        _id: Date.now(),
        name: "",
        is_completed: false,
        is_new: true,
      });
      return dispatch({
        type: "CREATE_SUB_TASK_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "CREATE_SUB_TASK_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "CREATE_SUB_TASK_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateSubTaskActions = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("api/v1/task/update-sub-task", value);
      return dispatch({
        type: "UPDATE_SUB_TASK_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        return dispatch({
          type: "UPDATE_SUB_TASK_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "UPDATE_SUB_TASK_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteSubTaskAction = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/task/remove-sub-task`, value);
      return dispatch({
        type: "DELETE_SUB_TASK_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_SUB_TASK_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_SUB_TASK_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const AddMediaToTaskAction = (
  value,
  setIsLoading,
  setIsModalOpen = null,
  user_type = null
) => {
  return async (dispatch) => {
    try {
      let headers = {
        "Content-Type": "multipart/form-data",
      };

      if (user_type === "client_user") {
        headers["user_type"] = "client_user";
      }

      const { data } = await axios.post(`api/v1/task/add-media`, value, {
        headers,
      });
      setIsLoading(false);
      if (setIsModalOpen) setIsModalOpen(false);
      return dispatch({
        type: "ADD_MEDIA_TO_TASK_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        setIsLoading(false);
        return dispatch({
          type: "ADD_MEDIA_TO_TASK_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
        return dispatch({
          type: "ADD_MEDIA_TO_TASK_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const DeleteMediaFromTaskAction = (value, setIsModalOpen) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/task/remove-media`, value);
      setIsModalOpen(false);
      return dispatch({
        type: "DELETE_MEDIA_FROM_TASK_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_MEDIA_FROM_TASK_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_MEDIA_FROM_TASK_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const deleteTaskCustomFieldAction = (
  id,
  customFieldId,
  projectId,
  isClientUser = false
) => {
  return async (dispatch) => {
    try {
      const body = {
        id,
        custom_field_id: customFieldId,
      };
      if (isClientUser) {
        body.user_type = "client_user";
      }
      const { data } = await axios.post(
        "api/v1/task/remove-custom-field",
        body
      );
      toast.success(data?.message);
      dispatch({
        type: "DELETE_TASK_CUSTOM_FIELD",
        payload: { id, customFieldId, projectId },
      });
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  };
};
