import React from "react";
import AddIcon from "../Icons/AddIcon";

function AddButton({ title = "assign", onClick = () => { }, disabled = false }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className="text-AccentRegular flex items-center justify-center text-xs rounded-lg py-2 px-3 h-8 tracking-[0.04em] border border-AccentMediumLight focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight font-bold hover:bg-AccentMediumLight transition duration-300 uppercase"
    >
      <AddIcon className="min-w-[16px] flex grow mr-2" width={16} height={16} />
      {title}
    </button>
  );
}

export default AddButton;
