import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import CloseBtn from '../../assets/Images/close-btn.svg';
import openBtn from '../../assets/Images/signature.svg';
import SignatureCanvas from 'react-signature-canvas';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { UpdateSignatureAction } from '../../store/actions/proposalAction';
import ImageUploading from 'react-images-uploading';
import AddImage from '../../assets/Images/Add-Image.svg';
import SignatureButtons from '../../assets/Images/signature-buttons.svg';
import { Link } from 'react-router-dom';
import moment from 'moment';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(41, 41, 51, 0.7)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '47px',
    background: 'none',
    borderRadius: '10px',
  },
};

function Signature({ setTextEditor, textEditor, visible, who, isEditable }) {
  const dispatch = useDispatch();
  const { clientId, proposalId } = useParams();
  const imageInputRef = useRef(null);
  const sigCanvas = useRef(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('Bob Buil');
  const [isChecked, setIsChecked] = useState(false);
  const [signatureData, setSignatureData] = useState(null);
  const [signatureType, setSignatureType] = useState('text');
  const [isSaving, setIsSaving] = useState(false);
  const [isblock, setIsBlock] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (textEditor?.client_signature?.currant_type && who === 'client') {
      setSignatureType(textEditor?.client_signature?.currant_type);
      setSignatureData(textEditor?.client_signature);
      setMessage(textEditor?.client_signature?.signature);
      setIsChecked(textEditor?.client_signature?.is_dotted);
    }
    if (textEditor?.user_signature?.currant_type && who === 'user') {
      setSignatureType(textEditor?.user_signature?.currant_type);
      setSignatureData(textEditor?.user_signature);
      setMessage(textEditor?.user_signature?.signature);
      setIsChecked(textEditor?.user_signature?.is_dotted);
    }
  }, [
    textEditor?.user_signature,
    textEditor?.client_signature,
    sigCanvas?.current,
  ]);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleSubmit = async () => {
    setIsSaving(true);
    const newUpdatedResponse = await dispatch(
      UpdateSignatureAction(
        signatureType,
        clientId,
        image,
        who,
        message,
        isChecked,
        signatureType === 'draw'
          ? sigCanvas.current.toDataURL('image/png')
          : null,
        setIsSaving,
        setIsOpen,
        proposalId
      )
    );
    setTextEditor({
      ...textEditor,
      user_signature: newUpdatedResponse.user_signature,
      client_signature: newUpdatedResponse.client_signature,
    });
  };

  const onChange = (file) => {
    if (file[0]?.file) {
      setImage(file[0]?.file);
    }
  };

  return (
    <div
      className="w-1/2 drop-shadow-none"
      id={`box-signature-${textEditor?._id}-${who}`}
    >
      <div className="">
        <div
          className={`mb-6 ${who === 'client' ? 'text-end' : 'invisible'} ${
            signatureData ? 'block' : 'hidden'
          }`}
        >
          <p className="underline font-extrabold text-[10px] text-AccentMediumDark inline-block rounded-lg px-2 py-1 source-noto-serif">
            Agreed and Accepted
          </p>
        </div>

        {signatureData && signatureData?.currant_type === 'text' && (
          <div>
            {isChecked ? (
              <div
                className={`w-full h-full mx-auto relative ${
                  isEditable ? '' : ''
                } ${signatureData ? 'block' : 'hidden'} ${
                  who === 'client' ? 'text-end' : ''
                }`}
              >
                <p
                  onClick={() => (isEditable ? openModal() : null)}
                  className="cursor-pointer text-[18px] source-noto-serif font-semibold text-Neutral800 inline-block w-[200px] mt-2 pt-2 border-t-[4px] border-dotted border-Neutral300"
                >
                  {message}
                </p>
              </div>
            ) : (
              <div
                className={` w-full h-full mx-auto relative ${
                  isEditable ? '' : ''
                } ${signatureData ? 'block' : 'hidden'} ${
                  who === 'client' ? 'text-end' : ''
                }`}
                onClick={() => (isEditable ? openModal() : null)}
              >
                <p className="source-noto-serif font-semibold text-sm text-Neutral800 cursor-pointer">
                  {who === 'client'
                    ? textEditor?.client?.contact_name
                    : textEditor?.client?.user?.user_name}
                </p>
                <p className="font-mr-dafoe text-[32px] text-Neutral800 inline-block mt-2 pt-2 border-t border-Neutral300 cursor-pointer">
                  {message}
                </p>
              </div>
            )}
          </div>
        )}
        {signatureData && signatureData?.currant_type === 'image' && (
          <div>
            <div
              className={`w-full h-full mx-auto relative ${
                isEditable ? '' : ''
              } ${signatureData ? 'block' : 'hidden'} ${
                who === 'client' ? 'text-end' : ''
              }`}
              onClick={() => (isEditable ? openModal() : null)}
            >
              <p className="source-noto-serif font-semibold text-sm text-Neutral800 cursor-pointer">
                {who === 'client'
                  ? textEditor?.client?.contact_name
                  : textEditor?.client?.user?.user_name}
              </p>
              <img
                className={`w-[100%] h-[150px] object-contain ${
                  isEditable ? 'cursor-pointer ' : ''
                }
                ${who === 'client' ? 'object-right' : 'object-left'}
                `}
                src={signatureData?.image?.url}
                alt=""
              />
            </div>
          </div>
        )}
        {signatureData && signatureData?.currant_type === 'draw' && (
          <div>
            <div
              className={` w-full h-full mx-auto relative ${
                isEditable ? '' : ''
              } ${signatureData ? 'block' : 'hidden'} ${
                who === 'client' ? 'text-end' : ''
              }`}
              onClick={() => (isEditable ? openModal() : null)}
            >
              <p className="source-noto-serif font-semibold text-sm text-Neutral800 cursor-pointer">
                {who === 'client'
                  ? textEditor?.client?.contact_name
                  : textEditor?.client?.user?.user_name}
              </p>
              <img
                className={`max-w-[320px] max-h-[150px] object-contain ${
                  isEditable ? 'cursor-pointer ' : ''
                }
                ${who === 'client' ? 'object-right' : 'object-left'}
                `}
                src={signatureData?.base_64}
                alt=""
              />
            </div>
          </div>
        )}

        <div
          className={`mt-5 ${who === 'client' ? 'text-end' : ''} ${
            signatureData?.date ? 'block' : 'hidden'
          }`}
        >
          <p className="text-Neutral700 text-sm font-bold  source-noto-serif">
            {' '}
            {moment(signatureData?.date).format('DD/MM/YYYY')}{' '}
          </p>
        </div>
      </div>
      <div className={`mx-auto signature-icon relative block max-w-[467px]`}>
        {!visible && who === 'user' && !signatureData ? (
          <>
            <div>
              <span
                className={`flex items-center rounded border-dashed border-[1.2px] text-sm w-fit p-3 bg-Neutral100 transition duration-300 ${
                  isEditable
                    ? 'text-Neutral800 border-Neutral500 hover:border-Neutral600 hover:bg-Neutral200 cursor-pointer'
                    : 'text-Neutral400 border-Neutral300'
                }`}
                onClick={() => (isEditable ? openModal() : null)}
              >
                {isEditable ? (
                  <>
                    <svg
                      className="mr-3"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.75 17.475L17.475 12.75L19.5 14.775L14.775 19.5L12.75 17.475Z"
                        stroke="#757580"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.8 13.425L15.7875 8.3625L6 6L8.3625 15.7875L13.425 16.8L16.8 13.425Z"
                        stroke="#757580"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 6L11.1205 11.1205"
                        stroke="#757580"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.0751 13.4246C12.8207 13.4246 13.4251 12.8202 13.4251 12.0746C13.4251 11.329 12.8207 10.7246 12.0751 10.7246C11.3295 10.7246 10.7251 11.329 10.7251 12.0746C10.7251 12.8202 11.3295 13.4246 12.0751 13.4246Z"
                        stroke="#757580"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="11.5"
                        stroke="#757580"
                      />
                    </svg>
                  </>
                ) : (
                  <></>
                )}
                Add Your Signature
              </span>
            </div>
          </>
        ) : (
          who === 'client' &&
          !signatureData && (
            <>
              <div
                className={`mx-auto signature-icon relative block max-w-[467px] flex justify-end ${
                  isEditable ? '' : ''
                }`}
              >
                <span
                  className={`flex items-center rounded border-dashed border-[1.2px] text-sm w-fit p-3 transition duration-300 ${
                    isEditable
                      ? 'text-Neutral800 border-Neutral500 hover:border-Neutral600 hover:bg-Neutral200 cursor-pointer'
                      : ' text-Neutral400 border-Neutral300 '
                  }`}
                  onClick={() => (isEditable ? openModal() : null)}
                >
                  {isEditable ? (
                    <>
                      <svg
                        className="mr-3"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.75 17.475L17.475 12.75L19.5 14.775L14.775 19.5L12.75 17.475Z"
                          stroke="#757580"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.8 13.425L15.7875 8.3625L6 6L8.3625 15.7875L13.425 16.8L16.8 13.425Z"
                          stroke="#757580"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 6L11.1205 11.1205"
                          stroke="#757580"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.0751 13.4246C12.8207 13.4246 13.4251 12.8202 13.4251 12.0746C13.4251 11.329 12.8207 10.7246 12.0751 10.7246C11.3295 10.7246 10.7251 11.329 10.7251 12.0746C10.7251 12.8202 11.3295 13.4246 12.0751 13.4246Z"
                          stroke="#757580"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="23"
                          height="23"
                          rx="11.5"
                          stroke="#757580"
                        />
                      </svg>
                    </>
                  ) : (
                    <></>
                  )}
                  Client’s signature
                </span>
              </div>
            </>
          )
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={300}
        onAfterOpen={() => {
          if (
            textEditor?.client_signature?.base_64 &&
            sigCanvas?.current &&
            who === 'client'
          ) {
            sigCanvas?.current?.fromDataURL(
              textEditor?.client_signature?.base_64,
              {
                width: 350,
                height: 150,
              }
            );
          }
          if (
            textEditor?.user_signature?.base_64 &&
            sigCanvas?.current &&
            who === 'user'
          ) {
            sigCanvas?.current?.fromDataURL(
              textEditor?.user_signature?.base_64,
              {
                width: 350,
                height: 150,
              }
            );
          }
        }}
        contentLabel="Signature Modal"
      >
        <div className="relative bg-Neutral000 rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-Neutral000 px-6 xs:px-12 pt-8 pb-10  rounded-[8px]">
            <h3 className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-2">
              ADD SIGNATURE
            </h3>
            <div className="flex">
              <span
                className={`w-1/2 text-center cursor-pointer text-Neutral700 border-b-[2px] ${
                  signatureType === 'text'
                    ? 'border-AccentRegular'
                    : 'border-Neutral300'
                } mx-2`}
                onClick={() => setSignatureType('text')}
              >
                Text
              </span>
              <span
                className={`w-1/2 text-center cursor-pointer text-Neutral700 border-b-[2px] ${
                  signatureType === 'image'
                    ? 'border-AccentRegular'
                    : 'border-Neutral300'
                } mx-2`}
                onClick={() => setSignatureType('image')}
              >
                Image
              </span>
              <span
                className={`w-1/2 text-center cursor-pointer text-Neutral700 border-b-[2px] ${
                  signatureType === 'draw'
                    ? 'border-AccentRegular'
                    : 'border-Neutral300'
                } mx-2`}
                onClick={() => setSignatureType('draw')}
              >
                Draw
              </span>
            </div>
            {signatureType === 'text' && (
              <>
                <div className="mt-6">
                  <label className="text-Neutral900 font-bold text-xs">
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-base leading-5 w-full h-12 focus:outline-none border-[1.5px] rounded-md border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                      type="text"
                      placeholder="Name"
                      onChange={handleChange}
                      value={message}
                    />
                  </div>
                </div>
                <div className="relative">
                  <p className="text-xs text-Neutral700 transition duration-300 font-medium pt-2">
                    Have an e-signature already?
                    <span className="relative pl-1 cursor-pointer inline-block">
                      <button className="text-AccentRegular cursor-pointer">
                        {' '}
                        Upload it here.
                      </button>
                      <input
                        type="file"
                        className="opacity-0 absolute top-0 left-0 w-full h-full"
                      />
                    </span>
                  </p>
                </div>
                <div className="mt-6 mb-1 h-[108px] w-full rounded flex items-center justify-center bg-Neutral200">
                  <p
                    className={`font-mr-dafoe text-[32px] text-Neutral800 ${
                      isChecked ? 'opacity-30' : ''
                    }`}
                  >
                    {message}
                  </p>
                </div>
                <div className="costom-checkbox flex items-center justify-start">
                  <input
                    type="checkbox"
                    id={'signature'}
                    checked={isChecked}
                    onChange={handleOnChange}
                  />
                  <label
                    className="text-Neutral700 text-xs font-normal block pt-3"
                    for={'signature'}
                  >
                    Use a dotted line instead of a signature.
                  </label>
                </div>
              </>
            )}
            {signatureType === 'image' && (
              <div>
                <ImageUploading
                  multiple={false}
                  onChange={onChange}
                  maxNumber={1}
                  dataURLKey="data_url"
                >
                  {({ onImageUpload, isDragging, dragProps, errors }) => (
                    <div
                      className="w-full h-full max-w-[790px] mx-auto mt-5"
                      {...dragProps}
                    >
                      <div className="builder-img-box bg-Neutral200 h-[150px] w-full flex items-center justify-center relative overlay-set">
                        <div className="upload__image-wrapper">
                          {errors?.maxNumber ? (
                            <span>
                              Number of selected images exceed maxNumber
                            </span>
                          ) : null}
                          {errors?.acceptType ? (
                            <span>Your selected file type is not allow</span>
                          ) : null}
                          {errors?.maxFileSize ? (
                            <span>Selected file size exceed maxFileSize</span>
                          ) : null}
                          {errors?.resolution ? (
                            <span>
                              Selected file is not match your desired resolution
                            </span>
                          ) : null}
                          <div className={``}>
                            {!image && !signatureData?.image?.url ? (
                              <label
                                ref={imageInputRef}
                                style={isDragging ? { color: 'red' } : null}
                                onClick={onImageUpload}
                                className="cursor-pointer text-Neutral000 bg-AccentRegular flex 5xl:w-[240px] w-[200px] 5xl:h-[40px] h-[50px] items-center justify-center text-xs rounded py-2 px-4 focus-visible:outline-purple font-semibold hover:bg-AccentMediumDark transition duration-300"
                                type="button"
                              >
                                Add Signature Image
                                <img className="ml-1" src={AddImage} alt="" />
                              </label>
                            ) : (
                              <div className="image-item">
                                <img
                                  className="absolute z-0 w-full top-0 left-0 h-[150px] object-cover"
                                  src={
                                    signatureData?.image?.url
                                      ? signatureData?.image?.url
                                      : URL.createObjectURL(image)
                                  }
                                  alt=""
                                />
                                <div
                                  className="absolute top-[5px] right-[4px] z-30 cursor-pointer drop-shadow-text`"
                                  onClick={() => {
                                    setImage();
                                    setSignatureData({
                                      ...signatureData,
                                      image: { key: null, url: null },
                                    });
                                  }}
                                >
                                  <img src={CloseBtn} alt="" />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </div>
            )}
            <div className={`${signatureType !== 'draw' ? 'hidden' : ''}`}>
              <div className="mt-5">
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 350,
                    height: 150,
                    className: 'sigCanvas border-[2px]',
                  }}
                />
                {!sigCanvas?.current?.isEmpty() ? (
                  <div className="flex justify-end pt-2">
                    <button
                      className="text-[10px] 5xl:text-sm py-1 px-2 font-semibold text-Red900 bg-Red100 rounded-2xl leading-tight"
                      onClick={() => sigCanvas?.current?.clear()}
                    >
                      clear
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mt-8">
              <button
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isSaving}
                className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
              >
                <span className="relative z-10 drop-shadow-Texts">
                  {isSaving ? (
                    <div className="py-2 flex justify-center items-center relative overflow-hidden z-10">
                      <div className="pr-2 white dot-falling"></div>
                    </div>
                  ) : (
                    'Save'
                  )}
                </span>
              </button>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => closeModal(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Signature;
