import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import Headphones from "../../../../../assets/Images/Headphones.svg";
import Isometric from "../../../../../assets/Images/Isometric-Stickers-Alarm-Clock.svg";
import urgent from "../../../../../assets/Images/urgent.svg";
import ThreeDots from "../../../../../assets/Images/three-dots.svg";
import { useDispatch } from "react-redux";
import { UpdateTaskActions } from "../../../../../store/actions/tasksAction";
import { UpdateServiceActions } from "../../../../../store/actions/serviceAction";

const status = [
  { status: "NOT STARTED", bg: "#E5675C", type: "not_started" },
  { status: "IN PROGRESS", bg: "#5CACE5", type: "in_progress" },
  { status: "COMPLETED", bg: "#59B347", type: "completed" },
];

const priority = [
  { priority: "NORMAL", image: Headphones },
  { priority: "URGENT", image: Isometric },
];

const StatusAndPriority = ({
  isNew,
  item,
  types,
  isVertical,
  user_type = null,
  access_level,
  project,
}) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState({
    itemStatus: item?.tag,
    itemPriority: item?.priority,
  });

  useEffect(() => {
    setEditable({
      itemStatus: item?.tag,
      itemPriority: item?.priority,
    });
  }, [item]);

  return (
    <div
      className={`${
        isVertical ? "" : "relative"
      } flex items-center justify-between w-full gap-2`}
    >
      <span
        className={
          isVertical
            ? "focus-visible:outline-none w-full flex align-start"
            : "focus-visible:outline-none"
        }
      >
        <div
          className={
            isVertical
              ? "flex flex-col-reverse gap-2 flex-wrap items-center w-full"
              : "flex flex-wrap items-center"
          }
        >
          {status.map((ele) => {
            return (
              editable?.itemStatus === ele.type && (
                <p className="bg-Neutral200 text-[10px] font-semibold flex items-center rounded-[16px] py-1 px-2">
                  <span
                    className={`h-2.5 w-2.5 text-Neutral900 rounded-full  bg-[${ele.bg}] inline-block mr-[4px]`}
                  ></span>
                  {ele.status}
                </p>
              )
            );
          })}
          {editable?.itemPriority && editable?.itemPriority !== "normal" && (
            <p className="bg-Neutral900 font-semibold py-1 flex items-center text-white text-[10px] rounded-[2px] px-2">
              <img src={urgent} className="mr-[4.75px]" alt="" />
              URGENT
            </p>
          )}
          {user_type === "client_user" && access_level === "view_only" && (
            <p className="bg-Neutral200 h-5 text-[10px] font-semibold flex items-center rounded-[16px] mr-1 px-[6px]">
              View Only
            </p>
          )}
          {!isVertical && (
            <img className="w-6 h-6 pl-1" src={ThreeDots} alt="" />
          )}
        </div>
      </span>

      {(user_type === null ||
        (user_type && access_level === "full_access")) && (
        <Menu as="div" className="relative flex text-left z-90">
          {!isNew && (
            <Menu.Button className="relative inline-flex w-full focus-visible:outline-none popup-btn">
              <img className="w-6 h-6" src={ThreeDots} alt="" />
            </Menu.Button>
          )}
          <Transition as={Fragment}>
            <Menu.Items
              className={`min-w-[332px] rounded-[0px_0px_4px_4px] origin-top-right z-50 absolute ${
                isVertical ? "top-[100%]" : "top-[52px]"
              } right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-5 px-4 `}
              onBlur={(e) => {
                if (
                  editable?.itemStatus !== item?.tag ||
                  editable?.itemPriority !== item?.priority
                ) {
                  if (types === "task") {
                    if (user_type) {
                      dispatch(
                        UpdateTaskActions({
                          ...item,
                          tag: editable?.itemStatus,
                          priority: editable?.itemPriority,
                          user_type: "client_user",
                        })
                      );
                    } else {
                      dispatch(
                        UpdateTaskActions({
                          ...item,
                          tag: editable?.itemStatus,
                          priority: editable?.itemPriority,
                          column:
                            project?.task_column?.filter((col) =>
                              ["To Do", "Doing", "Done"].includes(col?.name)
                            )?.length === 3
                              ? (editable?.itemStatus === "completed" &&
                                  "Done") ||
                                (editable?.itemStatus === "in_progress" &&
                                  "Doing") ||
                                (editable?.itemStatus === "not_started" &&
                                  "To Do")
                              : item?.column,
                        })
                      );
                    }
                  }
                  if (types === "service") {
                    dispatch(
                      UpdateServiceActions({
                        ...item,
                        tag: editable?.itemStatus,
                        priority: editable?.itemPriority,
                      })
                    );
                  }
                }
              }}
            >
              <div className="">
                <p className="text-Neutral600 text-[10px] font-semibold pb-[12px] pt-[16px] border-t border-Neutral200">
                  Select Task Status
                </p>
                <div className="flex flex-wrap items-center">
                  {status?.map((item, index) => {
                    return (
                      <p
                        className={`${
                          editable?.itemStatus === item?.type
                            ? "bg-Neutral200"
                            : "opacity-60"
                        }  h-5 text-[10px] font-semibold flex items-center rounded-[16px] mr-2 cursor-pointer pl-[6px] pr-2 hover:opacity-100`}
                        onClick={() =>
                          setEditable({ ...editable, itemStatus: item?.type })
                        }
                        key={index}
                      >
                        <span
                          className={`h-2 w-2 text-Neutral900 rounded-full bg-[${item.bg}] inline-block mr-[4px]`}
                        ></span>
                        {item?.status}
                      </p>
                    );
                  })}
                </div>
                <p className="text-Neutral600 font-semibold text-[10px] pb-2 pt-[20px]">
                  Toggle Task Priority
                </p>
                <div className="flex flex-wrap">
                  {priority?.map((element, index) => {
                    return (
                      <div
                        onClick={() => {
                          setEditable({
                            ...editable,
                            itemPriority: element.priority.toLowerCase(),
                          });
                        }}
                        className={`border border-inherit transition-all duration-300 cursor-pointer w-[72px] h-[72px] flex flex-col items-center justify-between overflow-hidden relative after:content-[''] after:w-full after:h-full after:absolute after:bg-Neutral200 priority-card ${
                          editable.itemPriority?.toUpperCase() !==
                          element?.priority
                            ? "after:opacity-70"
                            : "after:opacity-0 bg-white border border-[#EDEDF2] priority-active"
                        }`}
                        key={`1${index}`}
                      >
                        <img src={element?.image} alt="" className="mt-2" />
                        <p className="text-Neutral900 font-semibold text-[10px] mb-2">
                          {element?.priority}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
};

export default StatusAndPriority;
