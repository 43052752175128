import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import CloseBtn from "../../../../assets/Images/close-btn.svg";
import GreenRightTik from "../../../../assets/Images/green-right-tik.svg";
import EmailErrorIcon from "../../../../assets/Images/email-error-icon.svg";
import SmallSpinner from "../../../../assets/Images/Small-Spinner.svg";
import loader from "../../../../assets/Images/loader-icon.svg";
import { useNavigate, useLocation } from "react-router";
import Modal from "react-modal";
import {
  GetEmailNotExistanceAction,
  SendOtpAction,
} from "../../../../store/actions/authAction";
import CancelModelWarningAvatar from "../../../../assets/Images/cancel-model-warning-avatar.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const EmailInput = ({
  setSignInDetail,
  signInDetail,
  setIsEmailExist,
  isEmailExist,
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isEmailChecking, setIsEmailChecking] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenAppsumoModal, setIsOpenAppsumoModal] = useState(false);
  const [uuid, setUuid] = useState(null);

  const emailCheck = async (e) => {
    const email = e.target.value.toLowerCase();
    setSignInDetail({ ...signInDetail, email: email });
  };

  const emailSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter your correct email address.")
      .required("Email Required")
      .test(
        "Email Existence",
        "Looks like email is already registered with us, choose different email.",
        async (value) => {
          var re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (re.test(value)) {
            setIsEmailChecking(true);
            const ans = await GetEmailNotExistanceAction(value);
            setIsEmailChecking(false);
            if (ans && ans?.is_appsumo_user === false) {
              setIsEmailExist(false);
            } else if (ans && ans?.is_appsumo_user === true) {
              setIsOpenAppsumoModal(true);
              setUuid(ans?.uuid);
              // navigate(`/appsumo-signup?uuid=${ans?.uuid}`, {
              //   replace: true,
              // });
              setIsEmailExist(true);
            } else {
              setIsEmailExist(true);
            }
            return ans;
          } else {
            setIsEmailExist(true);
          }
        }
      ),
  });

  const handleContinue = async () => {
    setLoading(true);
    const value = {
      email: signInDetail?.email,
      name: signInDetail?.name,
    };
    await SendOtpAction(value);
    setLoading(false);
    setSignInDetail({ ...signInDetail, step: signInDetail.step + 1 });
    navigate("", {
      state: { ...signInDetail, step: signInDetail.step + 1 },
      replace: true,
    });
  };

  const handleGoBack = () => {
    setSignInDetail({ ...signInDetail, step: signInDetail.step - 1 });
    navigate("", {
      state: { ...signInDetail, step: signInDetail.step - 1 },
      replace: true,
    });
  };

  const disabled =
    signInDetail.email && state.email === signInDetail.email
      ? false
      : isEmailExist;

  return (
    <>
      <div
        className={`onboading-form-content w-full`}
      >
        
        <div class="mt-[52px]">
          <Formik
            initialValues={{ email: signInDetail?.email }}
            enableReinitialize
            validationSchema={emailSchema}
            validateOnBlur={false}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              /* and other goodies */
            }) => (
              <div>
                <div className="mb-[24px] relative">
                  <div className="mt-2">
                    <div className="relative passwordshow mt-2">
                      <input
                        className={`text-Neutral800 font-medium placeholder:text-Neutral500 p-6 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded-[8px] border-Neutral300 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition max-w-[434px] ${
                          errors.email && touched.email
                            ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow pr-12"
                            : ""
                        } ${
                          !isEmailExist
                            ? "border-Green400 focus:border-Green400 focus:drop-shadow-Validboxshadow"
                            : ""
                        } focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow  transition duration-300 ease-in-out`}
                        type="email"
                        placeholder="Eg. luke@skywalker.com"
                        name="email"
                        onChange={(e) => {
                          setFieldValue("email", e.target.value.toLowerCase());
                          emailCheck(e);
                        }}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email && !isEmailChecking && (
                        <img
                          className="w-full absolute right-3 top-[50%] -translate-y-[50%] max-w-[32px] cursor-pointer"
                          src={EmailErrorIcon}
                          alt="PasswordIcon"
                        />
                      )}
                      {!isEmailExist && !isEmailChecking && (
                        <img
                          className="w-[14px] absolute right-5 top-[50%] -translate-y-[50%] max-w-[32px] cursor-pointer"
                          src={GreenRightTik}
                          alt="PasswordIcon"
                        />
                      )}
                      {isEmailChecking && (
                        <img
                          className="w-[14px] absolute right-5 top-[35%] max-w-[32px] cursor-pointer motion-safe:animate-spin"
                          src={SmallSpinner}
                          alt="LoadingIcon"
                        />
                      )}
                    </div>
                  </div>
                  {errors.email && touched.email && (
                    <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                      {errors.email}
                    </p>
                  )}
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
      <div className="w-full lg:mt-[40px] mt-[24px] mb-3">
        <button
          type="button"
          className={`uppercase rounded-lg  ${
            disabled ? "text-Neutral500 bg-Neutral300" : "bg-AccentRegular text-Neutral000"
          } font-bold md:h-[46px] h-[60px]  p-[0px_24px] lg:mr-4 lg:w-auto w-full`}
          onClick={handleContinue}
          disabled={disabled}
        >
          {loading ? (
            <img src={loader} alt="loader" className="animate-spin" />
          ) : (
            "continue"
          )}
        </button>
        <button
          type="button"
          onClick={handleGoBack}
          disabled={signInDetail.step === 1}
          className="cursor-pointer uppercase transition duration-300 ease-in-out hover:bg-AccentMediumLight rounded-lg text-Neutral900 font-bold h-[46px] bg-Neutral300 p-[0px_24px] md:inline-block hidden"
        >
          go back
        </button>
        <button
                type='button'
                onClick={handleGoBack}
                disabled={signInDetail.step === 1}
                className='text-AccentRegular text-xs font-semibold inline-block md:hidden'
              >
                Back to Step
              </button>
      </div>

      <Modal
        isOpen={isOpenAppsumoModal}
        onRequestClose={() => {
          setIsOpenAppsumoModal(false);
        }}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[384px] sm:min-w-[384px]  max-w-[384px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white p-[24px_32px_32px_32px] rounded-[8px]">
            <div className="flex pb-2 border-b-[0.56px] border-Neutral200">
              <h6 className="uppercase font-semibold text-xs text-Neutral700 tracking-[0.04em]  w-full">
                IMPORTANT NOTICE
              </h6>
            </div>
            <div className="flex justify-between pt-[37px]">
              <div className="">
                <div className="text-center pb-8">
                  <img
                    src={CancelModelWarningAvatar}
                    alt=""
                    className="mx-auto pb-[27px]"
                  />
                  <p className="text-Neutral800 font-normal">
                    You have already purchased a deal from AppSumo click the
                    below button to sign up with the AppSumo deal.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <button
                onClick={() => {
                  navigate(`/appsumo-signup?uuid=${uuid}`, {
                    replace: true,
                  });
                  setIsOpenAppsumoModal(false);
                }}
                className="inlinr-block text-sm font-bold w-full h-12 flex items-center justify-center rounded-[4px] leading-[1.4] focus:outline-AccentMediumLight bg-Neutral300 text-Neutral800 hover:text-Neutral800 transition duration-300 ease-in-out hover:bg-Neutral400"
              >
                Go to AppSumo Signup
              </button>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => {
              setIsOpenAppsumoModal(false);
            }}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EmailInput;
