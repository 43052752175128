import { Menu, Transition } from "@headlessui/react";
import axios from "axios";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import ThreeDots from "../../assets/Images/three-dots.svg";
import {
  ChangeInvoiceStatusAction,
  GetInvoiceStatus,
} from "../../store/actions/invoicesAction";

const colors = ["Green", "Pink", "Blue", "Orange", "Neutral", "Red"];

const colorCodition = (index) => {
  if (index < 5) {
    return colors[index];
  } else {
    return colors[index % colors.length];
  }
};

const generateColorObject = (color) => ({
  bg: `bg-${color}400`,
  bgActive: `bg-${color}200`,
  text: `hover:text-${color}800`,
  textactive: `text-${color}800`,
  statehover: `hover:text-${color}200`,
});

function InvoiceStatus({ id, status, item }) {
  const dispatch = useDispatch();
  const [customInvoiceStatus, setCustomInvoiceStatus] = useState("");

  const invoiceStatusList = useSelector(
    (state) => state.invoice.getInvoiceStatus
  );

  const [statusButton, setStatusButton] = useState(false);
  const [selctedStatus, setSelectedStatus] = useState(
    invoiceStatusList.data.find((e) => e?.name === status) || {}
  );

  const handleStatusChange = (invoiceItem) => {
    setSelectedStatus(invoiceItem);

    let oldStatus = selctedStatus;

    const haveError = () => {
      setSelectedStatus(oldStatus);
    };

    if (invoiceItem?.status !== selctedStatus) {
      dispatch(
        ChangeInvoiceStatusAction({ id, status: invoiceItem._id }, haveError)
      );
    }
  };

  const handleCreateInvoiceStatus = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      // Check if the custom invoice status is not empty
      if (customInvoiceStatus.length === 0) return;

      // Check if custom invoice status is not already created
      const existingInvoiceStatus = invoiceStatusList.data.filter(
        (ele) =>
          ele.status === customInvoiceStatus.toLowerCase().replace(/\s/g, "_")
      );
      if (existingInvoiceStatus.length > 0) return;

      try {
        // Create a new invoice status
        const { data } = await axios.post(
          "api/v1/invoice-status/create-invoice-status",
          {
            name: customInvoiceStatus,
            color: generateColorObject(
              colorCodition(invoiceStatusList.data.length)
            ),
          }
        );

        toast.success(data?.message);

        // Select the newly created status after successful creation
        if (data?.data) {
          setSelectedStatus(data?.data);
          handleStatusChange(data?.data, true);
        }

        // Refetch the invoice statuses after creating a new one
        dispatch(GetInvoiceStatus());
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.errors[0].msg);
      } finally {
        setCustomInvoiceStatus("");
      }
    }
  };

  const handleDeleteStatus = async (event, item) => {
    event.stopPropagation();
    try {
      const { data } = await axios.post(
        "api/v1/invoice-status/delete-invoice-status",
        { id: item._id }
      );
      toast.success(data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.errors[0].msg);
    }

    // Revalidate the status state
    dispatch(GetInvoiceStatus());
  };

  return (
    <>
      <div className="relative flex items-center w-full text-left">
        <Menu
          as="div"
          className="relative inline-block text-left w-32 -ml-[16px] mt-2"
        >
          <Menu.Button>
            <div
              className={`flex items-center transition duration-300 ${
                statusButton ? "" : "opacity-100 w-fit"
              } `}
            >
              {/* {item?.is_admin && ( */}
              <img
                className="cursor-pointer h-fit"
                src={ThreeDots}
                alt=""
                onClick={() => setStatusButton(true)}
              />
              {/* )} */}
              <span
                className={`text-[10px] 5xl:text-sm py-1 px-4 font-semibold ${selctedStatus?.color?.textactive} ${selctedStatus?.color?.bgActive} rounded-2xl leading-tight capitalize`}
              >
                {selctedStatus?.name?.replace(/_/g, " ")}
              </span>
            </div>
          </Menu.Button>
          <Transition as={Fragment}>
            <Menu.Items className="box shadow-[0px_8px_24px_rgba(0,0,0,0.1)] z-50 absolute top-[35px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pt-3 rounded-[0px_0px_4px_4px]">
              <div className="bg-white">
                <input
                  type="text"
                  placeholder="Create Custom"
                  className="font-normal text-[10px] placeholder:text-Neutral600 text-Neutral700 focus:outline-none w-full mb-2 px-3"
                  onKeyUp={(e) => {
                    e.preventDefault();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.stopPropagation();
                    } else if (e.key === "Enter") {
                      handleCreateInvoiceStatus(e);
                    }
                  }}
                  value={customInvoiceStatus}
                  onChange={(e) => setCustomInvoiceStatus(e.target.value)}
                />
                <div className="px-2 pb-2">
                  {invoiceStatusList.data.map((item) => (
                    <div
                      key={item._id}
                      className={`transition-all duration-300 uppercase flex items-center justify-between w-full cursor-pointer whitespace-nowrap text-[10px] 5xl:text-sm py-2 font-semibold leading-tight rounded-[2px] text-Neutral800 group ${
                        item?.color?.text
                      } ${item?.color?.statehover} ${
                        selctedStatus?.name === item?.name
                          ? `${selctedStatus?.color?.bgActive} ${selctedStatus?.color?.textactive}`
                          : ""
                      }
						${
              !selctedStatus && invoiceStatusList.data.slice(1).length > 0
                ? `${invoiceStatusList.data[1].color?.bgActive} ${invoiceStatusList.data[1].color?.textactive}`
                : ""
            }`}
                      onClick={(e) => {
                        if (item.status === "viewed") return;
                        e.stopPropagation();
                        setStatusButton(false);
                        if (item?.name) {
                          handleStatusChange(item);
                        }
                      }}
                    >
                      <div className="flex items-center">
                        <span
                          className={`w-2 h-2 rounded-full block mr-2 ml-2 ${item?.color.bg} uppercase `}
                        ></span>
                        {item?.name?.replace(/_/g, " ")}
                      </div>
                      <button
                        className="text-transparent group-hover:text-Red500"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteStatus(e, item);
                        }}
                      >
                        <svg
                          className="mr-2"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.23071 5.04416H4.33157H13.1384"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.98209 5.04466V4.02244C5.98209 3.75133 6.09808 3.49132 6.30453 3.29962C6.51098 3.10791 6.79098 3.00021 7.08295 3.00021H9.28466C9.57662 3.00021 9.85663 3.10791 10.0631 3.29962C10.2695 3.49132 10.3855 3.75133 10.3855 4.02244V5.04466M12.0368 5.04466V12.2002C12.0368 12.4713 11.9208 12.7313 11.7144 12.923C11.5079 13.1147 11.2279 13.2224 10.9359 13.2224H5.43167C5.1397 13.2224 4.85969 13.1147 4.65324 12.923C4.44679 12.7313 4.33081 12.4713 4.33081 12.2002V5.04466H12.0368Z"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
}

export default InvoiceStatus;
