import React from "react";

function ResetIcon({ width = 21, className = "", color = "currentColor" }) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      color={color}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.23 6.43652V9.56152H13.105"
        stroke={color || "#E0526E"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.77087 14.77V11.645H7.89587"
        stroke={color || "#E0526E"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.07817 9.04027C6.34232 8.29381 6.79126 7.62642 7.3831 7.10039C7.97494 6.57435 8.69039 6.20682 9.46269 6.03207C10.235 5.85733 11.039 5.88107 11.7996 6.10109C12.5603 6.32111 13.2528 6.73023 13.8125 7.29027L16.2292 9.56111M4.77087 11.6444L7.18754 13.9153C7.74731 14.4753 8.43982 14.8844 9.20046 15.1045C9.96111 15.3245 10.7651 15.3482 11.5374 15.1735C12.3097 14.9987 13.0251 14.6312 13.617 14.1052C14.2088 13.5791 14.6578 12.9117 14.9219 12.1653"
        stroke={color || "#E0526E"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ResetIcon;
