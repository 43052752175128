import axios from "axios";
import React, { useEffect } from "react";
import ProposalFooterBlock from "./ProposalFooterBlock";

const MigrateProposalSignature = () => {
  const [proposals, setProposals] = React.useState([]);
  useEffect(() => {
    const getProposalsList = async () => {
      const { data } = await axios.get("/api/v1/proposal/get-all-proposals");
      // const { data } = await axios.get("/api/v1/contract/get-all-contracts");
      const tempProposals = data?.data?.filter(
        (item) =>
          item?.user_signature?.date !== null ||
          item?.client_signature?.date !== null
      );
      setProposals(tempProposals);
    };
    getProposalsList();
  }, []);

  return (
    <div>
      {proposals?.map((item) => {
        return (
          <div>
            <ProposalFooterBlock visible={true} textEditor={item} />
            <hr />
            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default MigrateProposalSignature;
