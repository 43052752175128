import React from "react";
import clx from "classnames";

import ShareIcon from "../../../Icons/ShareIcon";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function CardControls({
  projectId = null,
  className = "",
  clientId = null,
  project,
  setShareProjectModal,
}) {
  const clientDetail = useSelector((state) => state.client.clientDetail);
  return (
    <div className={clx("flex justify-stretch gap-x-2", className)}>
      <Link
        to={`/admin/clients/manage/${clientId}/project/${projectId}`}
        state={{ project, client: clientDetail?.data }}
        onClick={() => { }}
        className="flex justify-center items-center grow rounded bg-Neutral300 py-[16.5px] text-sm font-bold text-Neutral800 leading-[110%] transition duration-300 hover:bg-Neutral400"
      >
        Manage
      </Link>
      <button
        onClick={() => {
          setShareProjectModal(true);
        }}
        aria-label="Share"
        className="flex rounded border-Neutral300 border-[0.5px] p-3"
      >
        <ShareIcon />
      </button>
    </div>
  );
}

export default CardControls;
