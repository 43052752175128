import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Contracts1 from "../../assets/Images/Contracts-1.svg";
import ContractsArrow from "../../assets/Images/Contracts-Arrow.svg";
import ContractsLine from "../../assets/Images/Contracts-line.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import {
  deleteInvoice,
  deleteInvoiceSeries,
  getInvoiceListByClientId,
} from "../../store/actions/invoicesAction";
import ThreeDotsIcon from "../Icons/ThreeDotsIcon";
import MessageIcon from "../Icons/newicons/_MessageIcon";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const ViewInvoiceList = ({
  isOpen,
  setIsOpen,
  clientId,
  setSelectedData,
  isOpenEntityTemplateModal,
  openModal,
  isEmailModalOpen,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invoices = useSelector((state) => state.invoice.invoiceList);
  const [isLoading, setIsLoading] = useState({
    status: false,
    id: "",
  });

  useEffect(() => {
    if (clientId !== invoices?.client) {
      dispatch(getInvoiceListByClientId(clientId));
    }
  }, [clientId, dispatch]);

  const handleDeleteInvoice = (id) => {
    setIsLoading({
      status: true,
      id: id,
    });
    dispatch(deleteInvoice(id, setIsLoading));
  };

  const handleDeleteInvoiceSeries = (id) => {
    setIsLoading({
      status: true,
      id: id,
    });
    dispatch(deleteInvoiceSeries(id, setIsLoading));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all w-[80vw] sm:my-8 sm:min-w-[500px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-12 rounded-[8px]">
          <div>
            <div className="text-left">
              <div className="flex justify-between border-b border-Neutral200 w-full mb-8 pb-2">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold"
                  id="modal-title"
                >
                  Invoices
                </h3>
                {!invoices?.data?.length && (
                  <button
                    className="text-AccentRegular font-medium text-xs cursor-pointer duration-300 transition-all"
                    onClick={() => {
                      isOpenEntityTemplateModal();
                    }}
                  >
                    Add Invoice
                  </button>
                )}
              </div>
              <div
                className={`mt-4 min-h-[188px] scrollbar-w-4 -mr-3 pr-3 pb-5 ${
                  invoices?.data?.length > 10
                    ? "overflow-y-auto max-h-96"
                    : "overflow-hidde"
                }`}
              >
                {invoices?.data?.length ? (
                  invoices?.data?.map((invoice, index) => {
                    return (
                      <div
                        key={index}
                        className="flex items-center justify-between h-12 border-b border-Neutral300 w-full"
                      >
                        <h5 className="text-sm text-normal text-Neutral800">
                          {invoice?.invoiceName ? invoice?.invoiceName :
                        "INVOICE #" + invoice?.invoiceNumber}
                        </h5>
                        <Menu
                          as="div"
                          className="relative flex items-center text-left"
                        >
                          <div className="flex items-center">
                            <Menu.Button className="inline-flex justify-center w-full">
                              <ThreeDotsIcon />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="edit-menu-servies edit-delete-menu absolute z-[999] top-0 right-0 w-[100px] rounded-lg bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none ">
                              <form method="POST" action="#">
                                <Menu.Item>
                                  {({ active }) => (
                                    <div>
                                      <div
                                        className="w-[100px] text-left text-Neutral800 px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300"
                                        onClick={() => {
                                          if (invoice?.is_external_invoice) {
                                            window.location.href =
                                              invoice?.existing_file_upload
                                                ?.url ||
                                              invoice?.existing_file_url;
                                            return;
                                          }
                                          navigate(
                                            `/admin/clients/manage/${clientId}/invoice/${invoice?._id}`
                                          );
                                        }}
                                      >
                                        <svg
                                          className="mr-2"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1 7.84767C1 7.84767 3.42424 2.99968 7.66667 2.99968C11.9091 2.99968 14.3333 7.84767 14.3333 7.84767C14.3333 7.84767 11.9091 12.6957 7.66667 12.6957C3.42424 12.6957 1 7.84767 1 7.84767Z"
                                            stroke="#5A5A66"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M7.66657 9.66575C8.67073 9.66575 9.48475 8.8518 9.48475 7.84775C9.48475 6.8437 8.67073 6.02975 7.66657 6.02975C6.66242 6.02975 5.84839 6.8437 5.84839 7.84775C5.84839 8.8518 6.66242 9.66575 7.66657 9.66575Z"
                                            stroke="#5A5A66"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                        View
                                      </div>

                                      <div
                                        className="text-Neutral800 w-[100px] text-left px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300"
                                        onClick={() => {
                                          if (invoice?.is_external_invoice) {
                                            openModal(true, invoice);
                                            return;
                                          }
                                          navigate(
                                            `/admin/create-invoice/${clientId}/${invoice?._id}`
                                          );
                                        }}
                                      >
                                        <svg
                                          className="mr-2"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.77149 4.12352H4.06033C3.77911 4.12352 3.50941 4.23523 3.31056 4.43408C3.11171 4.63293 3 4.90263 3 5.18385V12.6062C3 12.8874 3.11171 13.1571 3.31056 13.3559C3.50941 13.5548 3.77911 13.6665 4.06033 13.6665H11.4827C11.7639 13.6665 12.0336 13.5548 12.2324 13.3559C12.4313 13.1571 12.543 12.8874 12.543 12.6062V8.89501"
                                            stroke="#5A5A66"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M11.7467 3.32908C11.9576 3.11817 12.2436 2.99968 12.5419 2.99968C12.8402 2.99968 13.1263 3.11817 13.3372 3.32908C13.5481 3.54 13.6666 3.82606 13.6666 4.12433C13.6666 4.42261 13.5481 4.70867 13.3372 4.91958L8.3006 9.95616L6.17993 10.4863L6.7101 8.36566L11.7467 3.32908Z"
                                            stroke="#5A5A66"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                        Edit
                                      </div>
                                      <div
                                        className="text-Neutral800 w-[100px] text-left px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300"
                                        onClick={() => {
                                          isEmailModalOpen(invoice?._id);
                                          setSelectedData(invoice);
                                        }}
                                      >
                                        <MessageIcon
                                          className="mr-2"
                                          width="16"
                                        />
                                        Email
                                      </div>
                                    </div>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Menu
                                      as="div"
                                      className=""
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <Menu.Button
                                        className="cursor-pointer duration-300 ease-in-out"
                                        type="button"
                                      >
                                        {isLoading?.status &&
                                        isLoading?.id === invoice?._id ? (
                                          <svg
                                            aria-hidden="true"
                                            class="inline w-4 h-4 mr-2 text-white animate-spin dark:text-white fill-AccentRegular"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                              fill="currentFill"
                                            />
                                          </svg>
                                        ) : (
                                          <span className="text-Neutral800 w-[100px] text-left px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300">
                                            <svg
                                              className="mr-2"
                                              width="16"
                                              height="16"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M3.23071 5.04416H4.33157H13.1384"
                                                stroke="#5A5A66"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M5.98209 5.04466V4.02244C5.98209 3.75133 6.09808 3.49132 6.30453 3.29962C6.51098 3.10791 6.79098 3.00021 7.08295 3.00021H9.28466C9.57662 3.00021 9.85663 3.10791 10.0631 3.29962C10.2695 3.49132 10.3855 3.75133 10.3855 4.02244V5.04466M12.0368 5.04466V12.2002C12.0368 12.4713 11.9208 12.7313 11.7144 12.923C11.5079 13.1147 11.2279 13.2224 10.9359 13.2224H5.43167C5.1397 13.2224 4.85969 13.1147 4.65324 12.923C4.44679 12.7313 4.33081 12.4713 4.33081 12.2002V5.04466H12.0368Z"
                                                stroke="#5A5A66"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                            Delete
                                          </span>
                                        )}
                                      </Menu.Button>
                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items className="focus:outline-none min-w-[384px] absolute right-[-20px] top-[20px] w-36 origin-top-right divide-y divide-Neutral200 bg-white ring-1 ring-black ring-opacity-5 drop-shadow-Timetracking">
                                          <div className="pt-6 pb-8 px-8">
                                            <Menu.Item>
                                              {(props) => {
                                                return (
                                                  <div>
                                                    <h5 className="text-Neutral700 text-xs font-semibold pb-2 border-b border-Neutral200">
                                                      Are you sure?
                                                    </h5>

                                                    <button
                                                      type="button"
                                                      onClick={(e) => {
                                                        handleDeleteInvoice(
                                                          invoice?._id
                                                        );
                                                        setIsOpen(false);
                                                      }}
                                                      className="text-Neutral000 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Red400 hover:bg-Red600 rounded-[4px] mt-8"
                                                    >
                                                      Yes, Delete Invoice
                                                    </button>

                                                    <Menu.Button
                                                      type="button"
                                                      className="text-Neutral800 hover:text-Neutral900 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Neutral300 hover:bg-Neutral400 rounded-[4px] mt-5"
                                                    >
                                                      No, I Still Want This
                                                      Invoice
                                                    </Menu.Button>
                                                  </div>
                                                );
                                              }}
                                            </Menu.Item>
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>
                                  )}
                                </Menu.Item>
                              </form>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    );
                  })
                ) : (
                  <div className="absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full max-w-[144px]">
                    <div className="relative">
                      <img
                        className="absolute -top-[8px] -left-[12px]"
                        src={Contracts1}
                        alt=""
                      />
                      <img
                        className="absolute -top-[86px] left-[45%]"
                        src={ContractsArrow}
                        alt=""
                      />
                      <img
                        className="absolute -bottom-[38px]"
                        src={ContractsLine}
                        alt=""
                      />
                      <h5 className="text-sm font-normal font-kalam text-center">
                        Add your first invoice!
                      </h5>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default ViewInvoiceList;
