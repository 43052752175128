import axios from "axios";
import { toast } from "react-toastify";

export const CreateTemplateActions = (
  type,
  value,
  setSubmitting,
  setErrors,
  navigate,
  setIsOpen
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/template/create-template",
        value
      );
      setSubmitting(false);
      setIsOpen(false);
      if (data?.data?._id) {
        if (type === "Questionnaire") {
          navigate(`/admin/questionnaire-builder/${data.data._id}`);
        } else if (type === "Proposal") {
          navigate(`/admin/proposal/template/${data.data._id}`);
        } else if (type === "Contract") {
          navigate(`/admin/contract/template/${data.data._id}`);
        } else if (type === "Invoice") {
          if (value?.billingType === "standard") {
            navigate(`/admin/invoice-creator/${data.data._id}`);
          } else {
            navigate(
              `/admin/invoice-creator/${data.data._id}?type=recurring&interval=${value?.interval}&repeats=${value?.repeats}&startDate=${value?.startDate}`
            );
          }
        }
      }
      return dispatch({
        type: "CREATE_TEMPLATE_SUCCESS",
        payload: { data: data.data, type: value.type },
      });
    } catch (error) {
      if (error?.response?.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
        return dispatch({
          type: "CREATE_TEMPLATE_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_TEMPLATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const getTemplateList = () => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(`api/v1/template/get-template-list`);
      return dispatch({
        type: "SET_TEMPLATE_LIST",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "TEMPLATE_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "TEMPLATE_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const deleteTemplateAction = (id, type, setIsLoaded, navigate) => {
  return async (dispatch) => {
    try {
      await axios.delete(
        `api/v1/template/delete-template?id=${id}&type=${type}`
      );
      if (navigate) navigate(`/admin/templates`);
      if (setIsLoaded)
        setIsLoaded({
          isLoading: false,
          id: null,
          type: null,
        });
      return dispatch({
        type: "DELETE_TEMPLATE_SUCCESS",
        payload: { id: id, type: type },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        if (setIsLoaded)
          setIsLoaded({
            isLoading: false,
            id: null,
            type: null,
          });
        return dispatch({
          type: "DELETE_TEMPLATE_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        if (setIsLoaded)
          setIsLoaded({
            isLoading: false,
            id: null,
            type: null,
          });
        return dispatch({
          type: "DELETE_TEMPLATE_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const CreateDuplicateTemplateActions = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/template/make-duplicate",
        value
      );
      return dispatch({
        type: "CREATE_TEMPLATE_SUCCESS",
        payload: { data: data.data, type: value.type },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "CREATE_TEMPLATE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_TEMPLATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const updateTemplateActions = (
  value,
  setSubmitting,
  setErrors,
  setIsOpen
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/template/update-template",
        value
      );
      setSubmitting(false);
      setIsOpen(false);
      return dispatch({
        type: "UPDATE_TEMPLATE_SUCCESS",
        payload: { data: data.data, type: value.type },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const SaveAsTemplateActions = (
  value,
  setIsLoadingSaveAsTemplate,
  reload
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/template/save-as-template",
        value
      );
      setIsLoadingSaveAsTemplate(false);
      if (value?.type === "Questionnaire" || value?.type === "Project") {
        reload();
      }
      return dispatch({
        type: "CREATE_TEMPLATE_SUCCESS",
        payload: { data: data.data, type: value.type },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setIsLoadingSaveAsTemplate(false);
        return dispatch({
          type: "CREATE_TEMPLATE_ERROR",
          payload: error,
        });
      } else {
        setIsLoadingSaveAsTemplate(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_TEMPLATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const saveUpdateTemplateActions = (value, setTemplateUpdate, reload = null) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/template/save-update-template",
        value
      );
      setTemplateUpdate(false);
      if (value?.type === "Project") {
        reload();
      }
      return dispatch({
        type: "UPDATE_TEMPLATE_SUCCESS",
        payload: { data: data.data, type: value.type },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setTemplateUpdate(false);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error,
        });
      } else {
        setTemplateUpdate(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const getTemplateById = ({ type, id }) => {
  return async (dispatch) => {
    try {
      const replacementUrl = `api/v1/template/get-template-by-id?type=${type}&${`${type.charAt(0).toLowerCase() + type.slice(1)
        }Id`}=${id}`;

      const { data } = await axios.get(
        `api/v1/template/get-template-by-id?type=${type}&${(type === "Proposal" && "proposalId") ||
        (type === "Contract" && "contractId") ||
        (type === "Questionnaire" && "questionnaireId") ||
        (type === "Invoice" && "invoiceId")
        }=${id}`
      );
      if (type === "Questionnaire") {
        return dispatch({
          type: "GET_QUESTIONNARIES_DATA",
          payload: { data: data.data },
        });
      }
      return data?.data;
    } catch (error) {
      toast.error(error?.response?.data.data?.errors[0]?.msg);
      if (error?.response?.status === 401) {
        toast.error(error?.message);
        return error;
      } else {
        return error;
      }
    }
  };
};

export const UpdateTemplate = (value, setUnsavedChanges) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `/api/v1/template/update-template-proposal-and-contract`,
        {
          contractId: value?.contractId,
          proposalId: value?.proposalId,
          title: value?.title,
          content: value?.data
            ? value?.data
            : `<div class='page'><p></p></div>`,
          type: value?.type,
        }
      );
      if (value?.questionnaireId) {
        dispatch(
          getTemplateById({
            type: value?.type,
            questionnaireId: value?.questionnaireId,
            proposalId: value?.proposalId,
            contractId: value?.contractId,
            invoiceId: value?.invoiceId,
          })
        );
      }
      setUnsavedChanges(false);
      return dispatch({
        type: "UPDATE_TEMPLATE_SUCCESS",
        payload: { data: data.data, type: value.type },
      });
    } catch (error) {
      if (error.status === 422) {
        toast.error(error?.response?.data?.errors[0]?.msg);
        let err = {};
        error?.response?.data?.errors?.forEach((element) => {
          err[element.param] = element.msg;
        });
        toast.error(err);
        setUnsavedChanges(false);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.errors?.msg);
        setUnsavedChanges(false);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error?.response?.errors?.msg,
        });
      }
    }
  };
};

export const UpdateInvoiceTemplate = (
  value,
  setSubmitting,
  navigate,
  currentStep,
  setCurrentStep,
  getInvoiceData
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `/api/v1/template/update-template-invoice`,
        value
      );
      if (getInvoiceData) {
        getInvoiceData({
          isLoading: false,
        });
      }
      dispatch({
        type: "UPDATE_TEMPLATE_SUCCESS",
        payload: { data: data.data, type: value.type },
      });
      setSubmitting(false);
      if (currentStep < 5) setCurrentStep(currentStep + 1);
      if (currentStep === 5) navigate(`/admin/templates`);
    } catch (error) {
      setSubmitting(false);
      if (error.status === 422) {
        toast.error(error?.response?.data?.errors[0]?.msg);
        let err = {};
        error?.response?.data?.errors?.forEach((element) => {
          err[element.param] = element.msg;
        });
        toast.error(err);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error,
        });
      } else if (error?.response?.status === 400) {
        if (error?.response?.data?.Error && error?.response?.data?.message) {
          toast.error(error?.response?.data?.Error);
          return dispatch({
            type: "UPDATE_TEMPLATE_ERROR",
            payload: error?.response?.data?.Error,
          });
        }
      } else {
        toast.error(error?.response?.errors?.msg);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error?.response?.errors?.msg,
        });
      }
    }
  };
};

export const UpdateQuestionTemplate = (value, setSubmitting, setErrors) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `/api/v1/template/update-template-questionnaire`,
        value
      );
      setSubmitting(false);
      dispatch(
        getTemplateById({
          type: value?.type,
          id: value?.questionnaireId,
        })
      );
      return dispatch({
        type: "UPDATE_TEMPLATE_SUCCESS",
        payload: { data: data.data, type: value.type },
      });
    } catch (error) {
      if (error.status === 422) {
        toast.error(error?.response?.data?.errors[0]?.msg);
        let err = {};
        error?.response?.data?.errors?.forEach((element) => {
          err[element.param] = element.msg;
        });
        toast.error(err);
        setSubmitting(false);
        setErrors(err);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.errors?.msg);
        setSubmitting(false);
        return dispatch({
          type: "UPDATE_TEMPLATE_ERROR",
          payload: error?.response?.errors?.msg,
        });
      }
    }
  };
};
export const CreateTemplateQuestionnaireQuestion = (
  value,
  setIsOpenQuestionnaire,
  setSubmitting
) => {
  return async (dispatch) => {
    try {
      let data = await axios.post(
        "api/v1/template/add-template-questionnaire-question",
        value
      );
      setIsOpenQuestionnaire(false);
      setSubmitting(false);
      dispatch(getTemplateById({ type: "Questionnaire", id: value?.id }));
      toast.success("Question created successfully");
      return data;
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]?.msg);
      if (error?.response?.status === 422) {
        let err = {};
        error?.response?.data?.errors?.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "CLIENT_CREATE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.errors[0]?.msg);
        return dispatch({
          type: "CREATE_QUESTION_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateTemplateQuestionnaireQuestion = (
  value,
  setSubmitting,
  setErrors
) => {
  return async () => {
    try {
      const { data } = await axios.put(
        `/api/v1/template/update-template-questionnaire-question`,
        value
      );
      toast.success("Question updated successfully");
      setSubmitting(false);
      return data;
    } catch (error) {
      if (error.status === 422) {
        let err = {};
        error?.response?.data?.errors?.forEach((element) => {
          err[element.param] = element.msg;
        });
        toast.error(err);
        setSubmitting(false);
        setErrors(err);
        return error;
      } else {
        toast.error(error?.response?.data?.errors[0]?.msg);
        setSubmitting(false);
        return error?.response?.data?.errors[0]?.msg;
      }
    }
  };
};

export const DeleteTemplateQuestionnaireQuestion = (
  question_id,
  tempQuestion_id,
  setLoading
) => {
  return async (dispatch) => {
    try {
      await axios.delete(
        `/api/v1/template/delete-template-questionnaire-question/${tempQuestion_id}`
      );
      setLoading(false);
      toast.success("Question Deleted successfully");
      dispatch(getTemplateById({ type: "Questionnaire", id: question_id }));
      return;
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]?.msg);
      if (error.status === 422) {
        let err = {};
        error?.response?.data?.errors?.forEach((element) => {
          err[element.param] = element.msg;
        });
        toast.error(err);
        return error;
      } else {
        return error?.response?.data?.errors[0]?.msg;
      }
    }
  };
};
