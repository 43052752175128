import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import Calender from "./Calender";
import AddPerson from "../../assets/Images/Add Person.svg";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import CreateClientModal from "../../components/Modals/CreateClientModal";
import DashboardCalender from "../../components/DashboardCalender";
import {
  GetClientAction,
  getFullClientList,
} from "../../store/actions/clientAction";
import { useDispatch, useSelector } from "react-redux";

// const customStyles = {
//   overlay: {
//     backgroundColor: 'rgba(41, 41, 51, 0.7)'
//   },
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     border: "none",
//     padding: "47px",
//     background: "none",
//     borderRadius: "10px"
//   },
// };

function OldDashboard() {
  const dispatch = useDispatch();
  const client = useSelector((state) => state.client.fullClientList);
  // const clientList = useSelector((state) => state.client.clientList);
  // console.log("clientLists", clientLists.data.data);
  const [selctedClient, setSelectedClint] = useState();
  const [createClientModal, setCreateClientModal] = useState(false);

  useEffect(() => {
    dispatch(GetClientAction({ page: 1, perPage: 10, search: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (!client?.data?.length) {
      dispatch(getFullClientList());
    }
  }, [dispatch]);

  return (
    <div>
      <AdminNavbar
        title="Dashboard"
        subtitle="View and manage your clients from here."
      />
      <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
        <div className="md:pb-0 pb-[100px] mb-[68px]">
          <div className="mt-2 md:mt-8">
            <h5 className="text-Neutral700 text-xs font-bold tracking-[0.08em] mb-5">
              CLIENTS
            </h5>
            <div>
              <ul className="flex items-center clientslist w-fit">
                <li
                  className="-mr-3 md:relative md:w-[72px] md:h-[72px] h-[60px] w-[60px] md:bottom-[unset] md:right-[unset] bottom-[84px] right-[32px] z-[10] fixed duration-300 "
                  onClick={() => setCreateClientModal(true)}
                >
                  <button className="focus-visible:outline-none">
                    <div className="user-icon-shadow md:w-[72px] md:h-[72px] h-[56px] w-[56px] rounded-full md:border-4 border-white bg-AccentRegular flex items-center justify-center focus-visible:outline-none hover:bg-AccentMediumDark duration-300 transition-all">
                      <img
                        className=" w-10 h-10"
                        src={AddPerson}
                        alt="UserButtons"
                      />
                    </div>
                  </button>
                </li>
                {!client?.data?.length && client.isLoading ? (
                  <>
                    <div className="rounded-full w-[72px] bg-[#f6f7f8] block h-[72px] bg-no-repeat skeleton-animation relative"></div>
                    <div className="rounded-full w-[72px] bg-[#f6f7f8] block h-[72px] bg-no-repeat skeleton-animation relative"></div>
                  </>
                ) : (
                  client?.data?.map((item, index) => (
                    <li
                      key={`20${index}`}
                      className={`client -mr-3 relative md:w-[72px] md:h-[72px] h-[56px] w-[56px] transition duration-300 ${index * 10
                        }`}
                      onClick={() => setSelectedClint(item._id)}
                    >
                      <Link
                        to={`/admin/clients/manage/${item._id}`}
                        className='focus-visible:outline-none before:contents"" before:absolute relative before:rounded-full before:top-[50%] before:transition-all 
                    before:duration-300 before:left-[50%] before:bg-AccentRegular before:opacity-0 client-hover translate before:-translate-x-2/4 before:-translate-y-2/4'
                      >
                        {!item.profile ? (
                          <div
                            className={`border border-Neutral300 md:w-[72px] md:h-[72px] h-[56px] w-[56px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700  focus-visible:outline-none ${selctedClient == item.id
                                ? "border-AccentRegular border-2"
                                : "border-4 border-white"
                              }`}
                          >
                            <span className=" text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey">
                              {item.client_name[0]}
                            </span>
                          </div>
                        ) : (
                          <img
                            className={`rounded-full w-full h-full focus-visible:outline-none ${selctedClient == item.id
                                ? "border-AccentRegular border-2"
                                : "border-4 border-white"
                              }`}
                            src={item.profile_path}
                            alt="UserButtons"
                          />
                        )}
                      </Link>
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
          <div className="mt-[36px] md:mt-8">
            <h5 className="text-Neutral700 text-xs font-bold tracking-[0.08em] mb-4 sm:mb-6">
              CALENDER
            </h5>
            <div>
              <DashboardCalender />
              {/* <Calender /> */}
            </div>
          </div>
        </div>
      </div>
      <CreateClientModal
        isOpen={createClientModal}
        setIsOpen={setCreateClientModal}
      />
    </div>
  );
}

export default OldDashboard;
