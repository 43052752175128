import PlusIcon from "./icons/PlusIcon";

function InfographicCard({
  title,
  content,
  totalCount,
  thisMonthCount,
  onClick = () => {},
  isDisabled = false,
}) {
  return (
    <div className="flex flex-col flex-1 gap-4 p-3 border rounded-xl hover:bg-Neutral200 bg-Neutral100 border-Neutral200">
      <div className="flex items-center justify-between">
        <h3 className="text-base text-Neutral900">{title}</h3>
        {!isDisabled && (
          <button
            className="p-1.5 rounded-full bg-Neutral900 text-Neutral000"
            onClick={onClick}
          >
            <PlusIcon className="size-4" />
          </button>
        )}
      </div>
      {content()}
      <div className="flex items-end justify-between">
        <div className="flex flex-col">
          <h3 className="text-2xl font-bold tracking-tighter text-Neutral800">
            {totalCount}
          </h3>
          <span className="text-xs">Total {title}</span>
        </div>
        <span className="px-3 py-1 text-xs font-semibold tracking-wide bg-Green200 rounded-xl text-Neutral900">
          {thisMonthCount}
        </span>
      </div>
    </div>
  );
}

export default InfographicCard;
