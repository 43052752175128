import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import FroalaBuilderComp from "../TextEditor/FroalaBuilderComp";
import {
  getContractById,
  getProposalById,
  getTemplateById,
} from "../../store/actions/templateAction";

const ProposalTemplateView = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const clientDetail = useSelector((state) => state.client.clientDetail);
  const [textEditor, setTextEditor] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getServerProposalConnent = async () => {
    if (params.type === "contract") {
      const contractData = await dispatch(
        getTemplateById({ type: "Contract", id: params.id })
      );
      if (contractData) {
        setTextEditor({
          ...contractData,
          text: contractData?.content,
        });
      }
    } else if (params.type === "proposal") {
      const proposalData = await dispatch(
        getTemplateById({ type: "Proposal", id: params.id })
      );
      if (proposalData) {
        setTextEditor({
          ...proposalData,
          text: proposalData?.content,
        });
      }
    }
  };
  useEffect(() => {
    getServerProposalConnent();
  }, [dispatch]);

  return (
    <>
      <FroalaBuilderComp
        clientDetail={clientDetail}
        textEditor={textEditor}
        type={params?.type?.toLowerCase()}
        isLoading={isLoading}
        setTextEditor={setTextEditor}
        setIsLoading={setIsLoading}
        handleReload={getServerProposalConnent}
        isTemplate={true}
      />
    </>
  );
};
export default ProposalTemplateView;
