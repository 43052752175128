import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart({ data }) {
  const chartRef = useRef(null);
  const thicknessMultiplier = useRef(0.85);
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    if (chartRef.current) {
      setChartWidth(chartRef.current.offsetWidth);
    }
    const handleResize = () => {
      if (chartRef.current) {
        setChartWidth(chartRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const barThickness =
    (chartWidth / data?.length) * thicknessMultiplier.current;

  const createGradient = (ctx, area) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, area.bottom);
    gradient.addColorStop(0, "#CEDEFD");
    gradient.addColorStop(0.25, "#A1BEF7");
    gradient.addColorStop(0.5, "#73A2FF");
    gradient.addColorStop(0.75, "#5281E0");
    gradient.addColorStop(1, "#406ABF");
    return gradient;
  };

  const chartData = {
    labels: data?.map((item) => {
      if (item?.week) return item.week;
      return item.month?.charAt(0);
    }),
    datasets: [
      {
        label: "Invoices Overview",
        data: data?.map((item) => item.total || item.total_hours),
        backgroundColor: "rgb(240, 245, 255)",
        borderRadius: 5,
        barThickness: barThickness,
        hoverBackgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          const gradient = createGradient(ctx, chartArea);
          if (!chartArea) {
            console.log("No chartArea found!");
            return "rgba(115, 162, 255, 0.4)";
          }
          return gradient;
        },
      },
    ],
    ...(data?.[0]?.total_amount && {
      tooltips: data?.map((item) => item.total_amount),
    }),
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => "",
          label: (context) => {
            if (chartData?.tooltips) {
              return `$${chartData.tooltips[context.dataIndex]}`;
            } else {
              return `$${context.formattedValue}`;
            }
          },
        },
        position: "nearest",
        backgroundColor: "rgb(2, 26, 75)",
        titleColor: "#fff",
        displayColors: false,
        padding: 6,
        yAlign: "bottom",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "rgb(240, 245, 255)",
          font: {
            size: 14,
            weight: "bold",
          },
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };

  return (
    <div ref={chartRef}>
      <Bar data={chartData} options={options} width="100%" height="100%" />
    </div>
  );
}

export default BarChart;
