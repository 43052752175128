import React from "react";

const DeleteIcon = ({ width = 16, color = "#5A5A66", className = "" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // className="mr-2 w-4 h-4"
      className={className}
    >
      <path
        d="M1 7.84767C1 7.84767 3.42424 2.99968 7.66667 2.99968C11.9091 2.99968 14.3333 7.84767 14.3333 7.84767C14.3333 7.84767 11.9091 12.6957 7.66667 12.6957C3.42424 12.6957 1 7.84767 1 7.84767Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.66657 9.66575C8.67073 9.66575 9.48475 8.8518 9.48475 7.84775C9.48475 6.8437 8.67073 6.02975 7.66657 6.02975C6.66242 6.02975 5.84839 6.8437 5.84839 7.84775C5.84839 8.8518 6.66242 9.66575 7.66657 9.66575Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
