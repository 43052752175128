import React, { Fragment, useEffect, useRef, useState } from "react";
import IndividualsPoster from "./Components/Svgs/IndividualsPoster";
import LargeTeamsPoster from "./Components/Svgs/LargeTeamsPoster";
import SmallTeamsPoster from "./Components/Svgs/SmallTeamsPoster";
import Plussign from "../../../assets/Images/plus.png";
import Plusico from "../../../assets/Images/Pluspurple.png";
import Plusorg from "../../../assets/Images/plusorange.png";
import BackNavArrow from "../../../assets/Images/back-nav-arrow.svg";
import dog from "../../../assets/Images/Big-Shoes-Animal.svg";
import { Menu, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { getBasicInfoUserAction } from "../../../store/actions/authAction";
import { GetPlanConfig } from "../../../store/actions/appConfigAction";
import PaymentSuccessAlert from "./Components//Alerts/PaymentSuccessAlert";
import PaymentErrorAlert from "./Components/Alerts/PaymentErrorAlert";
import { Link } from "react-router-dom";
import PageLoader from "../../../components/Loaders/PageLoader";

export const accountTypeWithPlan = [
  {
    name: "For Freelancer",
    account_type: "individual",
    user: "1",
    image: <IndividualsPoster />,
    border_color: "border-b-Blue400",
    user_lenth_text_class: "Individuals-card",
    text_color: "text-Blue400",
    card_wrapper_class: "Individuals",
    sign_color: "#5CACE5",
    list_class: "",
    plus_icon: Plussign,
    description:
      "Are you a solo freelancer? This plan is for you. Access to all features with no limitations.",
  },
  {
    name: "For Small Teams",
    account_type: "small_team",
    user: "2-10",
    image: <SmallTeamsPoster />,
    border_color: "border-b-AccentRegular",
    user_lenth_text_class: "small-teams-card",
    text_color: "text-AccentRegular",
    card_wrapper_class: "small-teams",
    sign_color: "#6D6DF2",
    list_class: "",
    plus_icon: Plusico,
    description:
      "Are you a small team? This plan is for you. Access to all features with no limitations.",
  },
  {
    name: "For Large Teams",
    account_type: "large_team",
    user: "11-50",
    image: <LargeTeamsPoster />,
    border_color: "border-b-Red400",
    user_lenth_text_class: "large-teams-card",
    text_color: "text-Red400",
    card_wrapper_class: "large-teams",
    sign_color: "#E5675C",
    list_class: "large-teams-list",
    plus_icon: Plusorg,
    description:
      "Are you a large team? This plan is for you. Access to all features with no limitations.",
  },
];

const ReactivePlan = () => {
  const planRef = useRef();
  const accountTypeRef = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const basicDetail = useSelector((state) => state.auth.basicInfo);
  const [signInDetail, setSignInDetail] = useState({
    accountType: null,
    plan: null,
  });
  const [dataLoading, setLoading] = useState(true);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  useEffect(() => {
    document.body.classList.add("home-page");
    return () => {
      document.body.classList.remove("home-page");
    };
  }, []);

  const Paddle = window.Paddle;

  // useEffect(() => {
  //   Paddle.Environment.set("sandbox");
  //   var vendor_id = 8883;
  //   Paddle.Setup({ vendor: vendor_id });
  // }, []);

  useEffect(() => {
    if (basicDetail?.selected_account_plan) {
      const tempAccount = accountTypeWithPlan.filter(
        (e) => e.account_type == basicDetail?.selected_account_plan
      );
      if (tempAccount && tempAccount?.length) {
        if (tempAccount?.[0]) {
        }
        setSignInDetail({
          plan: tempAccount?.[0][basicDetail?.selected_payment_type],
          accountType: tempAccount?.[0],
        });
      }
    }
  }, [basicDetail, accountTypeWithPlan]);

  useEffect(() => {
    if (signInDetail?.plan && signInDetail?.plan?.plan_id) {
      Paddle.Checkout.open({
        method: "inline",
        product: signInDetail?.plan?.plan_id,
        email: basicDetail?.email,
        allowQuantity: false,
        disableLogout: true,
        customData: {
          user: basicDetail?._id,
          plan: signInDetail?.plan?.plan_type,
          account: signInDetail?.accountType?.account_type,
          type: query.get("type"),
        },
        frameTarget: "payment-box",
        frameStyle:
          "width:100%; min-width:312px; background-color: transparent; border: none;",
        successCallback: (data, err) => {
          if (data) {
            if (query.get("type") === "reactive") {
              setSuccessAlert(true);
            } else {
              window.location.href = "https://www.clientmanager.io/thank-you";
            }
            return;
          }
          if (err) {
            setErrorAlert(true);
            return;
          }
        },
      });
    }
  }, [signInDetail, basicDetail]);

  const getData = async () => {
    const res = await dispatch(GetPlanConfig());
    const plansData = res.data.plans;
    accountTypeWithPlan.forEach((item) => {
      const plan = plansData.find((plan) => plan.type === item.account_type);
      item.life_time = plan.life_time;
      item.monthly = plan?.monthly;
      item.yearly = plan?.yearly;
    });
  };

  useEffect(() => {
    getData();
    if (id !== basicDetail?._id) {
      dispatch(getBasicInfoUserAction(id, setLoading, navigate));
    }
    return () => {
      console.log("Component did unmount");
    };
  }, [dispatch]);

  return (
    <>
      {dataLoading && <PageLoader />}
      <div className="pr-[32px] pt-[16px] flex justify-end items-center">
        <p className="Neutral700 text-xs font-medium mr-[24px]">
          Have an account?
        </p>
        <Link
          to="/signin"
          className="text-xs text-center inline-block text-AccentRegular font-[600] py-[7px] px-2 rounded-[4px] border-[1px] border-Neutral300 hover:border-AccentRegular focus-visible:outline focus-visible:outline-4 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
        >
          Sign In
        </Link>
      </div>
      <section
        className={`container m-auto min-h-[90vh] lg:flex items-center justify-center ${
          dataLoading ? "hidden" : ""
        }`}
      >
        <div className="flex-col h-full justify-center items-center flex onboading-form">
          <div className={`w-full px-6 md:px-0`}>
            <div className="md:flex w-full">
              <div className="ms:mr-20 mt-10">
                <p className="text-xs lg:text-base text-Neutral800 font-semibold flex justify-between items-baseline">
                  {query.get("type") && query.get("type") !== "complete" && (
                    <Link className="flex" to="/signin">
                      <img
                        className="mr-1 transition duration-300 ease-out"
                        src={BackNavArrow}
                        alt=""
                      />
                      Back
                    </Link>
                  )}
                  <img className="hidden md:block" src={dog} alt="" />
                </p>
                <h2 className="text-Neutral900 font-bold text-xl lg:text-[32px] pt-2 pb-1">
                  You’re almost there!
                </h2>
                <p className=" text-Neutral800 font-normal text-xs md:text-base">
                  Take a second to check that your details are <br /> correct
                  before proceeding.
                </p>
                <div class="mt-[44px]">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-Neutral600 text-[10px] font-medium uppercase">
                        email
                      </p>
                      <p className="font-medium text-sm text-Neutral900">
                        {basicDetail?.email}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-40 -ml-[16px] mt-5"
                    >
                      <Menu.Button
                        ref={accountTypeRef}
                        className="rounded-[4px] client-name bg-Neutral200 inline-flex w-full focus-visible:outline-none popup-btn relative px-[16px] pb-[10px] pt-[10px]"
                      >
                        <span className="focus-visible:outline-none pb-2 border-b border-Neutral200 w-full">
                          <div className="members-text text-left w-full">
                            <p className="text-Neutral600 text-[10px]">
                              Account type
                            </p>
                            <div className="flex flex-wrap text-[14px] items-center text-Neutral900 font-medium">
                              {signInDetail?.accountType?.name}
                              <svg
                                className="absolute right-[16px]"
                                width="11"
                                height="8"
                                viewBox="0 0 11 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                  fill="#5A5A66"
                                />
                              </svg>
                            </div>
                          </div>
                        </span>
                      </Menu.Button>
                      <Transition as={Fragment}>
                        <Menu.Items className="box shadow-[0px_30px_40px_rgba(0,0,0,0.2)] z-50 absolute top-[56px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 rounded-[0px_0px_4px_4px]">
                          <div className="bg-white">
                            <div>
                              {accountTypeWithPlan?.map((item) => {
                                if (
                                  item?.account_type !==
                                  signInDetail?.accountType?.account_type
                                ) {
                                  return (
                                    <div
                                      key={item?.account_type}
                                      className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                      onClick={() => {
                                        setSignInDetail({
                                          ...signInDetail,
                                          accountType: item,
                                          plan: item?.monthly,
                                        });
                                        setTimeout(() => {
                                          accountTypeRef?.current?.click();
                                        }, 0);
                                      }}
                                    >
                                      <p className="text-Neutral800 text-sm font-normal">
                                        {item?.name}
                                      </p>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-40 -ml-[16px] mt-5"
                    >
                      <Menu.Button
                        ref={planRef}
                        className="rounded-[4px] client-name bg-Neutral200 inline-flex w-full focus-visible:outline-none popup-btn relative px-[16px] pb-[10px] pt-[10px]"
                      >
                        <span className="focus-visible:outline-none pb-2 border-b border-Neutral200 w-full">
                          <div className="members-text text-left w-full">
                            <p className="text-Neutral600 text-[10px]">
                              Payment plan
                            </p>
                            {signInDetail?.plan?.price && (
                              <div className="flex flex-wrap text-[14px] items-center text-Neutral900 font-medium">
                                ${signInDetail?.plan?.price} -{" "}
                                {signInDetail?.plan?.plan_text}
                                <svg
                                  className="absolute right-[16px]"
                                  width="11"
                                  height="8"
                                  viewBox="0 0 11 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                    fill="#5A5A66"
                                  />
                                </svg>
                              </div>
                            )}
                          </div>
                        </span>
                      </Menu.Button>
                      <Transition as={Fragment}>
                        <Menu.Items className="box shadow-[0px_30px_40px_rgba(0,0,0,0.2)] z-50 absolute top-[56px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 rounded-[0px_0px_4px_4px]">
                          <div className="bg-white">
                            <div>
                              <div
                                className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                onClick={() => {
                                  setSignInDetail({
                                    ...signInDetail,
                                    plan: signInDetail?.accountType?.monthly,
                                  });
                                  setTimeout(() => {
                                    planRef?.current?.click();
                                  }, 0);
                                }}
                              >
                                <p className="text-Neutral800 text-sm font-normal">
                                  ${signInDetail?.accountType?.monthly?.price} -
                                  Monthly
                                </p>
                              </div>
                              <div
                                onClick={() => {
                                  setSignInDetail({
                                    ...signInDetail,
                                    plan: signInDetail?.accountType?.yearly,
                                  });
                                  setTimeout(() => {
                                    planRef?.current?.click();
                                  }, 0);
                                }}
                                className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                              >
                                <p className="text-Neutral800 text-sm font-normal">
                                  ${signInDetail?.accountType?.yearly?.price} -
                                  Yearly
                                </p>
                              </div>
                              {/* <div
                                onClick={() => {
                                  setSignInDetail({
                                    ...signInDetail,
                                    plan: signInDetail?.accountType?.life_time,
                                  });
                                  setTimeout(() => {
                                    planRef?.current?.click();
                                  }, 0);
                                }}
                                className='flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer'
                              >
                                <p className='text-Neutral800 text-sm font-normal'>
                                  ${signInDetail?.accountType?.life_time?.price}{' '}
                                  - Lifetime
                                </p>
                              </div> */}
                            </div>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  {signInDetail?.accountType?.name === "For Freelancer" &&
                    basicDetail?.selected_account_plan !== "individual" &&
                    query.get("type") === "reactive" && (
                      <div className="w-[230px] relative -top-4 ">
                        <span className="text-Red400 text-sm font-semibold">
                          This will result in the permanent loss of your plan
                          team members
                        </span>
                      </div>
                    )}
                  <div className="border-t border-Neutral400 flex justify-between items-center py-2">
                    <p className="text-Neutral900 text-sm font-semibold">
                      TOTAL
                    </p>
                    {signInDetail?.plan?.price && (
                      <p className="text-Neutral900 text-xl font-bold">
                        ${signInDetail?.plan?.price}{" "}
                        <span className="text-Neutral600 text-sm font-medium">
                          {signInDetail?.plan?.plan_type === "yearly" && "/ yr"}
                          {signInDetail?.plan?.plan_type === "monthly" &&
                            "/ mo"}
                          {/* {signInDetail?.plan?.plan_type === "life_time" && "Lifetime" } */}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="md:ml-20 mt-5 md:mt-0">
                <div className="w-full md:w-[470px] h-[562px] bg-[#ffffff] payment-box overflow-auto"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PaymentSuccessAlert
        modalIsOpen={successAlert}
        closeModal={setSuccessAlert}
      />
      <PaymentErrorAlert modalIsOpen={errorAlert} closeModal={setErrorAlert} />
      <div className="lg:flex hidden justify-end mr-8 mb-8">
        <p className=" text-Neutral800 text-xs font-medium">
          Have a question? Email us at{" "}
          <span className="text-AccentRegular">support@clientmanager.io</span>
        </p>
      </div>
    </>
  );
};

export default ReactivePlan;
