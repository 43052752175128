import React from 'react';
import success from "../../assets/Images/Success-icon.svg";
import profileImg from "../../assets/Images/profile-img.svg";
import alertClose from "../../assets/Images/alert-Close.svg";

function SuccessAlert({ titleDescription, description }) {
  return (
    <div className='w-[525px] h-[172px] flex pl-6 pt-6 pb-6 alert-shadow relative items-center bg-white '>
        <div className='h-[124px] min-w-[200px] rounded-lg overflow-hidden relative mr-6' >
            <img src={success} alt="" />
            <img className='absolute bottom-0 right-0' src={profileImg} alt="" />
        </div>
        <div className='' >
            <p className='text-Neutral900 font-bold text-sm ' > {titleDescription} </p>
            <p className='text-Neutral800 font-medium text-xs pt-4 pr-8' >{description} </p>
        </div>
        <img className='absolute w-6 h-6 top-[16px] right-[16px] cursor-pointer' src={alertClose} alt="" />
    </div>
  )
}

export default SuccessAlert;