import React, { useState } from "react";
import TopMenu from "../components/ClientCollab/TopMenu";
import ClientCollabProjects from "../components/ClientCollab/ClientCollabProjects";
import { useSelector } from "react-redux";

function ClientCollab() {
  const user = useSelector((state) => state?.collab_auth?.user);

  return (
    <>
      <div className={`relative flex`}>
        <TopMenu />
        <ClientCollabProjects />
      </div>
    </>
  );
}

export default ClientCollab;
