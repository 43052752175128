import React from "react";
import clx from "classnames";

function ProjectTitle({ text = "", className = "" }) {
  if (!text) {
    return null;
  }

  return (
    <h5 className={clx("text-base text-Neutral900 font-extrabold", className)}>
      {text}
    </h5>
  );
}

export default ProjectTitle;
