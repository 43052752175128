import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import ButtonLoader from "../Loaders/ButtonLoader";
import QuestionnaireFormPreviewTemplate from "../Modals/QuestionnaireFormPreviewTemplate";
import { updateQuestionnaireDefaultTextAction } from "../../store/actions/authAction";
import CloseBtn from "../../assets/Images/close-btn.svg";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";

const UpdateProfileSchema = Yup.object().shape({
  text_for_questionnaire: Yup.string().required(
    "Questionnaire Text is required"
  ),
});

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const QuestionnaireText = ({ logedinUser }) => {
  const dispatch = useDispatch();
  const [showPreview, setShowPreview] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [editedText, setEditedText] = useState(
    logedinUser?.text_for_questionnaire
  );

  return (
    <>
      <Formik
        initialValues={{
          ...logedinUser,
        }}
        enableReinitialize
        validationSchema={UpdateProfileSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          console.log(values);
          dispatch(
            updateQuestionnaireDefaultTextAction(
              values,
              setSubmitting,
              setErrors
            )
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            className="w-full 5xl:max-w-[500px] md:max-w-[408px] mr-[40px] mt-10"
            onSubmit={handleSubmit}
          >
            <div className="">
              <div
                className={`w-full 5xl:max-w-[500px] md:max-w-[408px] rounded pt-8 px-10 pb-10 border border-Neutral300  `}
              >
                <div className="flex items-center justify-between">
                  <h5 className="text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase flex items-center">
                    Questionnaire Text{" "}
                    <button
                      type="button"
                      onClick={() => setIsOpen(true)}
                      className="text-[#CCCCCC] text-[10px] font-semibold ml-2"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.3334 7.2042C7.5528 6.58051 7.98586 6.05459 8.55586 5.7196C9.12586 5.3846 9.79603 5.26215 10.4477 5.37392C11.0993 5.48569 11.6904 5.82448 12.1161 6.33028C12.5419 6.83608 12.775 7.47625 12.774 8.1374C12.774 10.0038 9.97437 10.937 9.97437 10.937V12.0003"
                          stroke="#9C9CA6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.0489 14.6702H10.0576"
                          stroke="#9C9CA6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="19"
                          height="19"
                          rx="9.5"
                          stroke="#C2C2CC"
                        />
                      </svg>
                    </button>
                  </h5>
                  <div
                    className={`font-bold text-[12px] transition duration-300 ease-in-out ${
                      values?.text_for_questionnaire === ""
                        ? "text-Neutral500"
                        : "text-AccentRegular hover:text-AccentMediumDark cursor-pointer"
                    }`}
                    disabled={values?.text_for_questionnaire === ""}
                    onClick={() => {
                      if (values?.text_for_questionnaire === "") return;
                      setShowPreview(true);
                    }}
                  >
                    Preview
                  </div>
                </div>

                <div className="mt-8">
                  <textarea
                    className="w-full p-4 text-sm border rounded border-Neutral300 focus:outline-none focus:ring-2 focus:ring-AccentRegular focus:border-transparent text-Neutral700"
                    name="text_for_questionnaire"
                    rows="4"
                    onChange={(e) => {
                      handleChange(e);
                      setEditedText(e.target.value);
                    }}
                    onBlur={handleBlur}
                    value={values.text_for_questionnaire}
                  ></textarea>
                  {errors.text_for_questionnaire &&
                    touched.text_for_questionnaire && (
                      <div className="mt-2 text-xs font-medium text-Red400 focus-visible:outline-none">
                        {errors.text_for_questionnaire}
                      </div>
                    )}
                </div>

                <button
                  className={`large-primary-btn text-sm rounded mt-8 h-12 5xl:w-[160px] w-[148px] focus:outline focus:outline-4 focus:outline-AccentLight focus-visible:outline-0 transition duration-300 ease-in-out ${
                    logedinUser?.text_for_questionnaire ===
                    values?.text_for_questionnaire
                      ? "disabled bg-Neutral300 text-Neutral500"
                      : "bg-AccentRegular drop-shadow-Texts text-Neutral000 hover:bg-AccentMediumDark button-hover"
                  }`}
                  type="submit"
                  disabled={
                    isSubmitting ||
                    logedinUser?.text_for_questionnaire ===
                      values?.text_for_questionnaire
                  }
                >
                  {isSubmitting ? (
                    <ButtonLoader className="z-10" />
                  ) : (
                    <span className="relative z-10 flex items-center justify-center font-[600]">
                      Update
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <QuestionnaireFormPreviewTemplate
        modalIsOpen={showPreview}
        setModal={setShowPreview}
        editedText={editedText}
      />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Archived client list Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[455px] sm:min-w-[455px]  max-w-[455px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white p-[24px_32px_24px_32px] rounded-[8px]">
            <h6 className="font-medium text-xs text-Neutral700 tracking-[0.04em] w-full">
              Add custom text above your client intake questionnaire forms that
              replaces our default text.
            </h6>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setIsOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QuestionnaireText;
