import copy from "copy-to-clipboard";
import html2pdf from "html2pdf.js";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Menu, Popover, Transition } from "@headlessui/react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import Linkicon from "../../assets/Images/Link Icon.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import ProposalFooterLogo from "../../assets/Images/proposal-footer-logo.svg";
import { PaypalIcon } from "../../components/CreateInvoiceForm/PaypalIcon";
import { StripeIcon } from "../../components/CreateInvoiceForm/StripeIcon";
import EditIcon from "../../components/Icons/EditIcon";
import SendIcon from "../../components/Icons/SendIcon";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import TagList from "../../components/Templates/TemplateInfo/components/TagList";
import { GetClientDetailAction } from "../../store/actions/clientAction";
import {
  PaypalOrderCreate,
  getInvoiceById,
  stripeOrderCreate,
  updateInvoice,
} from "../../store/actions/invoicesAction";
import { getTagColor } from "../../utils/getTagColor";
import LoadingIcon from "../../components/Icons/LoadingIcon";
import FollowUpEmailModal from "../../components/Modals/FollowUpEmailModal";

function Invoices() {
  const { clientId, invoiceId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const clientDetail = useSelector((state) => state.client.clientDetail);

  const [showCategoryTagsMenu, setShowCategoryTagsMenu] = useState(false);
  const [showInvoiceTitleMenu, setShowInvoiceTitleMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const logedinUser = useSelector((state) => state.auth.user);
  const userDateFormat = logedinUser?.date_format || "DD/MM/YYYY";
  const [linkIsOpen, setLinkIsOpen] = useState(false);
  const [copyStatus, setCopyStatus] = useState("Copy Link");
  const [copyPaymentLink, setcopyPaymentLink] = useState({
    paypal: "Copy",
    stripe: "Copy",
  });
  const invoice = useSelector((state) => state.invoice.invoice);
  const [newTitle, setNewTitle] = useState("");
  const [loadingPaymentLink, setLoadingPaymentLink] = useState();
  const [showEmailModal, setShowEmailModal] = useState(false);
  const modalColor = "Pink";

  const options = [
    { value: "Web Design", label: "Web Design" },
    { value: "App Development", label: "App Development" },
    { value: "SEO", label: "SEO" },
    { value: "Content Writing", label: "Content Writing" },
  ];

  const optionsWithColors = options.map((option, i) => {
    const { bgColor: optionColor, text: optionText } = getTagColor(
      modalColor,
      i,
      true
    );

    const { bgColor: labelColor, text: labelText } = getTagColor(
      modalColor,
      i,
      true
    );

    return {
      ...option,
      color: optionColor,
      text: optionText,
      labelColor,
      labelText,
    };
  });

  useEffect(() => {
    dispatch(getInvoiceById(clientId, invoiceId));
  }, [clientId, dispatch, invoiceId]);

  useEffect(() => {
    if (state) {
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { isLoading: false, isUpdating: false, data: state },
      });
    } else {
      if (clientId !== clientDetail.data._id) {
        dispatch(GetClientDetailAction(clientId, navigate));
      }
    }
  }, [clientId, dispatch, navigate]);

  const subtotal = invoice?.services?.reduce((acc, service) => {
    if (service?.serviceType === "Fixed") {
      return acc + Number(service?.servicePrice);
    } else {
      return acc + Number(service?.quantity) * Number(service?.servicePrice);
    }
  }, 0);

  function roundToTwoDecimals(value) {
    if (isNaN(value)) return 0;
    return Math.round(value * 100) / 100;
  }

  function formatDate(dateString) {
    if (!dateString) return "";
    return moment(dateString).format(userDateFormat);
  }

  const getDiscount = (subtotal, discount) => {
    const subtotalNum = Number(subtotal) || 0;
    const discountNum = Number(discount);
    if (isNaN(discountNum) || discountNum === 0 || isNaN(subtotalNum)) return 0;
    return roundToTwoDecimals((subtotalNum * discountNum) / 100);
  };

  const getVat = (subtotal, vatPercentage, discount) => {
    const subtotalNum = Number(subtotal) || 0;
    const vatPercentageNum = Number(vatPercentage);
    if (isNaN(vatPercentageNum) || vatPercentageNum === 0 || isNaN(subtotalNum))
      return 0;
    const discountedAmount = subtotalNum - getDiscount(subtotalNum, discount);
    return roundToTwoDecimals((discountedAmount * vatPercentageNum) / 100);
  };

  const getTotalDue = (subtotal, discount, vatPercentage, taxes, isVAT) => {
    const subTotalNum = Number(subtotal) || 0;
    const discountNum = Number(discount) || 0;
    const vatPercentageNum = Number(vatPercentage) || 0;

    const taxesValid = Array.isArray(taxes) && taxes.length > 0;

    if (isVAT) {
      return roundToTwoDecimals(
        subTotalNum -
          getDiscount(subTotalNum, discountNum) +
          getVat(subTotalNum, vatPercentageNum, discountNum)
      );
    } else if (taxesValid) {
      const totalTaxes = taxes.reduce((total, tax) => {
        const taxValueNum = Number(tax.taxValue) || 0;
        return total + getVat(subTotalNum, taxValueNum, discountNum);
      }, 0);
      return roundToTwoDecimals(
        subTotalNum - getDiscount(subTotalNum, discountNum) + totalTaxes
      );
    } else {
      return roundToTwoDecimals(
        subTotalNum - getDiscount(subTotalNum, discountNum)
      );
    }
  };

  const handleDownloadInvoice = () => {
    setIsLoading(true);
    const filename = `${invoice?.invoiceName}-${invoice?.invoiceNumber}-${invoice?.clientName}-${invoice?.invoiceDate}.pdf`;

    const contentWidthInInches = 620 / 96;
    const pageWidthInInches = 8.5;
    const sideMargin = (pageWidthInInches - contentWidthInInches) / 3;

    let elem = document.getElementById("invoice");
    var newElem = elem.cloneNode(true);

    const height = Math.ceil(elem.scrollHeight / 1000);

    newElem.setAttribute("id", "download-invoice");
    newElem.style.height = `${height * 1000}px`;

    // Select all #section but not select the last section.
    const sections = newElem.querySelectorAll("#section");
    const allButLast = Array.from(sections).slice(0, -1);
    allButLast.forEach((el) => {
      el.style.marginTop = "50px";
    });

    if (invoice?.paymentLink && invoice?.paymentMethod !== "Bank") {
      if (newElem.querySelectorAll("#paypalicon")?.length) {
        newElem.querySelectorAll("#paypalicon")[0].style.paddingTop = "1.25rem";
      }
    }

    newElem.querySelector("#cm_logo").style.marginTop = "10px";

    html2pdf()
      .from(newElem)
      .set({
        margin: [0.5, sideMargin, 0.5, sideMargin],
        filename: filename,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 4 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      })
      .toPdf()
      .get("pdf")
      .save(filename);
    setIsLoading(false);
  };
  const saveInvoiceTitle = () => {
    const newInvoice = {
      ...invoice,
      invoiceName: newTitle,
      client: clientId,
    };

    dispatch(updateInvoice(invoiceId, newInvoice, navigate));
    dispatch({
      type: "SET_INVOICE",
      payload: { data: { ...invoice, invoiceName: newTitle } },
    });
    setShowInvoiceTitleMenu(false);
  };

  if (isLoading) return <div>Loading...</div>;

  if (!invoice) {
    return null;
  }

  const handlePaypal = async () => {
    if (invoice?.paymentLink && invoice.paymentMethod === "Paypal") {
      copy(invoice?.paymentLink);
      setcopyPaymentLink({ paypal: "Copied" });
      setTimeout(() => {
        setcopyPaymentLink({ paypal: "Copy" });
      }, 1000);
    } else {
      dispatch(PaypalOrderCreate(invoiceId, setLoadingPaymentLink));
    }
  };

  const handleStipe = async () => {
    if (invoice?.paymentLink && invoice.paymentMethod === "Stripe") {
      copy(invoice?.paymentLink);
      setcopyPaymentLink({ stripe: "Copied" });
      setTimeout(() => {
        setcopyPaymentLink({ stripe: "Copy" });
      }, 1000);
    } else {
      dispatch(stripeOrderCreate(invoiceId, setLoadingPaymentLink));
    }
  };
  return (
    <>
      <AdminNavbar
        title="Create Invoice"
        subtitle="Build and send to your potential client to complete online."
      />

      <div
        className={`flex gap-4 items-start mt-6 mb-32 box-shadow-hidden md:px-6 2xl:px-12`}
      >
        <div className="flex flex-col w-full mb-5">
          <div className="flex flex-col items-start justify-between p-6 md:flex-row md:p-0">
            <div className="flex flex-col items-center justify-center w-full gap-3 pb-6 mr-3 md:pb-0 md:w-1/3">
              <div className="flex flex-col flex-wrap w-full gap-6">
                <div className="flex justify-between w-full gap-2 md:flex-col">
                  <h5 className="text-sm font-semibold text-Neutral500">
                    Client
                  </h5>

                  {/*  name*/}
                  <div className="flex ">
                    <div className="flex items-center justify-start gap-1 pr-3 font-medium bg-gray-100 rounded-full z-1">
                      {clientDetail?.data?.profile_path ? (
                        <img
                          className="w-6 h-6 rounded-full z-99"
                          src={clientDetail?.data?.profile_path}
                          alt=""
                        />
                      ) : (
                        <div className="flex items-center justify-center w-6 h-6 bg-gray-300 rounded-full z-99">
                          {clientDetail?.data?.client_name?.charAt(0)}
                        </div>
                      )}
                      <div className="px-3 text-xs font-semibold text-Neutral800">
                        {invoice?.clientName || "Client Name"}
                      </div>
                    </div>
                  </div>
                </div>
                {/*  name*/}

                {/*  title*/}
                <div className="flex justify-between w-full gap-2 md:flex-col">
                  <div className="flex items-center justify-between">
                    <h5 className="text-sm font-semibold text-Neutral500">
                      Invoice Title
                    </h5>

                    <div className="relative flex items-center gap-2">
                      {showInvoiceTitleMenu && (
                        <div className="absolute w-48 bg-white rounded-md shadow-lg top-2 right-1 ring-1 ring-black ring-opacity-5">
                          <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            <input
                              type="text"
                              className="w-full px-4 py-2"
                              name="newTitle"
                              placeholder="New Title"
                              value={newTitle}
                              onChange={(e) => {
                                setNewTitle(e.target.value);
                              }}
                            />
                            <button
                              className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                              type="button"
                              onClick={() => {
                                saveInvoiceTitle();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="px-2 text-xs font-semibold md:px-0 text-Neutral800">
                    {invoice.invoiceName || "Invoice Title"}
                  </div>
                </div>
                {/* title */}

                {/* category tags */}
                <div className="flex justify-between w-full gap-2 md:flex-col">
                  <div className="flex items-center justify-between">
                    <h5 className="text-sm font-semibold text-Neutral500">
                      Category Tags
                    </h5>

                    <div className="relative flex items-center gap-2">
                      {showCategoryTagsMenu && (
                        <div className="absolute w-48 bg-white rounded-md shadow-lg top-2 right-1 ring-1 ring-black ring-opacity-5">
                          <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            {optionsWithColors
                              ?.filter(
                                (option) =>
                                  !invoice?.categoryTags?.find(
                                    (tag) => tag?.name === option?.value
                                  )
                              )
                              ?.map((tag, i) => {
                                const { bgColor, text } = getTagColor(
                                  modalColor,
                                  i
                                );
                                return (
                                  <button
                                    className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                    role="menuitem"
                                    type="button"
                                    onClick={() => {
                                      const newInvoice = {
                                        ...invoice,
                                        categoryTags: [
                                          ...invoice.categoryTags,
                                          {
                                            name: tag?.value,
                                            color: {
                                              color: tag?.color,
                                              text: tag?.text,
                                              labelColor: tag?.labelColor,
                                              labelText: tag?.labelText,
                                            },
                                          },
                                        ],
                                        client: clientId,
                                      };

                                      dispatch(
                                        updateInvoice(
                                          invoiceId,
                                          newInvoice,
                                          navigate
                                        )
                                      );
                                      dispatch({
                                        type: "SET_INVOICE",
                                        payload: {
                                          data: {
                                            ...invoice,
                                            categoryTags: [
                                              ...invoice.categoryTags,
                                              {
                                                name: tag?.value,
                                                color: {
                                                  color: tag?.color,
                                                  text: tag?.text,
                                                  labelColor: tag?.labelColor,
                                                  labelText: tag?.labelText,
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      });

                                      setShowCategoryTagsMenu(false);
                                    }}
                                  >
                                    <div
                                      className={`bg-${bgColor} py-1 px-2 text-${text} rounded-lg text-[10px] uppercase font-medium tracking-[0.04em] mb-2`}
                                      key={i}
                                    >
                                      {tag?.value}
                                    </div>
                                  </button>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex px-2 py-1 text-xs font-semibold rounded text-Neutral800">
                    <TagList
                      color={"Pink"}
                      tags={invoice?.categoryTags?.map((tag) => tag?.name)}
                    />
                  </div>
                </div>
                {/* category tags */}

                <hr />

                <div className="flex flex-wrap w-full gap-2">
                  <div className="flex flex-wrap w-full gap-2">
                    <button
                      className="px-[19px] py-3 h-[48px] rounded flex justify-center items-center grow text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out bg-indigo-500 hover:bg-indigo-600 text-white"
                      type="button"
                      onClick={() => {
                        const link = `${window.location.origin}/public-invoice/${clientId}/invoice/${invoiceId}`;
                        copy(link);
                        setLinkIsOpen(true);
                        setCopyStatus("Copied");
                        setTimeout(() => {
                          setCopyStatus("Copy Link");
                        }, 1000);
                      }}
                    >
                      <spna>Copy Invoice Link</spna>
                    </button>

                    {/* <Menu
                      as="div"
                      className="relative inline-block text-left min-w-[2.5rem]"
                    >
                      <div className="flex items-center">
                        <Menu.Button className="inline-flex justify-center w-full focus-visible:outline-none">
                          <div className="flex items-center hover:cusor-pointer group transition-all duration-300 ease-in-out">
                            <button
                              disabled={loadingPaymentLink}
                              className="px-6 py-3 h-[48px] rounded flex justify-center items-center grow text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out bg-indigo-500 hover:bg-indigo-600 text-white"
                              type="button"
                            >
                              {loadingPaymentLink ? (
                                <LoadingIcon />
                              ) : (
                                <SendIcon width={18} color="#fff" />
                              )}
                              <p className="mx-1">Generate payment Link</p>
                            </button>
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-bottom-right absolute right-0 mt-0 w-fit rounded-md drop-shadow-Simplebutton bg-white focus:outline-none">
                          <div className="">
                            <Menu.Item>
                              <button
                                type="button"
                                onClick={() => handleStipe()}
                                className="5xl:w-[120px] text-left px-4 py-2 text-sm whitespace-nowrap flex items-center justify-center font-medium text-Neutral800 hover:bg-Neutral100"
                              >
                                <span className="ml-1">
                                  {invoice?.paymentLink &&
                                  invoice?.paymentMethod === "Stripe"
                                    ? copyPaymentLink.stripe
                                    : "Add "}{" "}
                                  stripe payment Link
                                </span>
                              </button>
                            </Menu.Item>
                            <Menu.Item>
                              <button
                                disabled={loadingPaymentLink}
                                className="5xl:w-[120px] text-left px-4 py-2 text-sm whitespace-nowrap flex items-center justify-center font-medium text-Neutral800 hover:bg-Neutral100"
                                type="button"
                                onClick={() => handlePaypal()}
                              >
                                {!invoice?.paymentLink && (
                                  <>
                                    {loadingPaymentLink && <LoadingIcon />}
                                  </>
                                )}
                                <p className="mx-1">
                                  {invoice?.paymentLink &&
                                  invoice?.paymentMethod === "Paypal"
                                    ? copyPaymentLink.paypal
                                    : "Add "}{" "}
                                  PayPal payment Link
                                </p>
                              </button>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu> */}
                    <Popover className="relative">
                      {({ open }) => (
                        <>
                          <Popover.Button>
                            <button
                              disabled={loadingPaymentLink}
                              className="px-6 py-3 h-[48px] rounded flex justify-center items-center grow text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out bg-indigo-500 hover:bg-indigo-600 text-white"
                              type="button"
                            >
                              {loadingPaymentLink ? (
                                <LoadingIcon />
                              ) : (
                                <SendIcon width={18} color="#fff" />
                              )}
                              <p className="mx-1">Generate payment Link</p>
                            </button>
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-full -translate-x-1/2 transform ">
                              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                                <div className="relative bg-white p-3">
                                  <button
                                    disabled={loadingPaymentLink}
                                    type="button"
                                    onClick={() => handleStipe()}
                                    className="5xl:w-[120px] text-left px-2 py-2 text-sm whitespace-nowrap flex items-center justify-center font-medium text-Neutral800 hover:bg-Neutral100 "
                                  >
                                    <span>
                                      {invoice?.paymentLink &&
                                      invoice?.paymentMethod === "Stripe"
                                        ? copyPaymentLink.stripe
                                        : "Add "}{" "}
                                      Stripe Payment Link
                                    </span>
                                  </button>
                                  <button
                                    disabled={loadingPaymentLink}
                                    className="5xl:w-[120px] text-left px-2 py-2 text-sm whitespace-nowrap flex items-center justify-center font-medium text-Neutral800 hover:bg-Neutral100"
                                    type="button"
                                    onClick={() => {
                                      console.log("asdasd");
                                      handlePaypal();
                                    }}
                                  >
                                    {!invoice?.paymentLink && (
                                      <>
                                        {loadingPaymentLink && <LoadingIcon />}
                                      </>
                                    )}
                                    <span>
                                      {invoice?.paymentLink &&
                                      invoice?.paymentMethod === "Paypal"
                                        ? copyPaymentLink.paypal
                                        : "Add "}{" "}
                                      PayPal Payment Link
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>
                  <div className="flex flex-wrap w-full gap-2">
                    <button
                      className="px-6	py-3 h-[48px] bg-Neutral200 rounded flex justify-center items-center grow text-AccentRegular text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out hover:bg-Neutral400"
                      type="button"
                      onClick={() => {
                        const link = `/admin/create-invoice/${clientId}/${invoiceId}`;
                        navigate(link);
                      }}
                    >
                      <EditIcon width={25} className="text-[#6D6DF2]" />
                    </button>
                    <button
                      className="px-6	py-3 h-[48px] bg-Neutral200 rounded flex justify-center items-center grow text-AccentRegular text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out hover:bg-Neutral400"
                      type="button"
                      onClick={handleDownloadInvoice}
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.25 15.168V18.7235C21.25 19.195 21.0627 19.6472 20.7293 19.9806C20.3959 20.314 19.9437 20.5013 19.4722 20.5013H7.02778C6.55628 20.5013 6.1041 20.314 5.7707 19.9806C5.4373 19.6472 5.25 19.195 5.25 18.7235V15.168"
                          stroke="#6D6DF2"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.80518 10.7217L13.2496 15.1661L17.6941 10.7217"
                          stroke="#6D6DF2"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.25 15.1662V4.49951"
                          stroke="#6D6DF2"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>{" "}
                    <button
                      onClick={() => setShowEmailModal(true)}
                      className="bg-AccentRegular text-Neutral000 flex justify-center items-center uppercase py-3 md:py-4 text-[10px] md:text-xs font-bold leading-4 text-center rounded min-w-[100px] md:min-w-[142px] w-full"
                    >
                      SEND AS EMAIL
                    </button>
                  </div>
                </div>
              </div>

              {linkIsOpen && (
                <CopyLinkModal
                  setCopyStatus={setCopyStatus}
                  closeModal={() => setLinkIsOpen(false)}
                  linkIsOpen={linkIsOpen}
                  url={`${window.location.origin}/public-invoice/${clientId}/invoice/${invoiceId}`}
                  setLinkIsOpen={setLinkIsOpen}
                  handleEmailModal={() => {
                    setShowEmailModal(true);
                    setLinkIsOpen(false);
                  }}
                />
              )}
            </div>
            {/* left */}

            {/* right */}
            {/* A4 */}
            <div
              className="flex flex-col gap-10 flex-grow w-full max-w-full md:max-w-[620px] bg-white border-[1px] border-Neutral300 shadow-2xl p-8 h-[750px] overflow-y-auto"
              id="invoice"
            >
              {/* SECTION 1 */}
              <div className="flex flex-row items-start justify-between">
                <h1 className="flex w-full text-2xl font-bold text-Neutral900">
                  <span>INVOICE #{invoice?.invoiceNumber}</span>
                </h1>

                <div className="flex flex-row items-start justify-between">
                  <div className="flex flex-col float-right gap-1 text-left">
                    {invoice?.logo && (
                      <img
                        src={invoice?.logo}
                        alt=""
                        className="self-end w-24"
                      />
                    )}

                    <h5 className="text-sm font-semibold uppercase text-Neutral800">
                      {invoice?.fullName}
                    </h5>
                    <div className="text-sm text-zinc-800">
                      {invoice?.companyName}
                    </div>
                    <div className="text-sm text-Neutral800">
                      {invoice?.email}
                    </div>
                    <div className="text-sm text-Neutral800">
                      {invoice.phoneNumber}
                    </div>
                    <div className="text-sm text-Neutral800">
                      {invoice.address}
                    </div>
                    <div className="text-sm text-Neutral800">{invoice.VAT}</div>
                  </div>
                </div>
              </div>
              {/* SECTION 1 */}

              {/* SECTION 2 */}
              <div
                className="flex flex-row items-center justify-between"
                id="section"
              >
                <div className="flex flex-col w-full gap-1">
                  <h5 className="text-sm font-semibold uppercase text-Neutral800">
                    Bill To
                  </h5>
                  <div className="text-sm font-semibold text-Neutral800">
                    {invoice?.clientCompanyName}
                  </div>
                  <div className="text-sm font-semibold text-Neutral800">
                    {invoice?.clientName}
                  </div>
                  <div className="text-sm text-Neutral800">
                    {invoice?.clientAddress}
                  </div>
                  <div className="text-sm text-Neutral800">
                    {invoice?.clientEmail}
                  </div>
                  <div className="text-sm text-Neutral800">
                    {invoice?.clientPhoneNumber}
                  </div>
                  <div className="text-sm text-Neutral800">
                    {invoice?.clientVAT}
                  </div>
                </div>
                <div className="flex flex-col w-full gap-1 text-right">
                  <h5 className="text-sm font-semibold uppercase text-Neutral800">
                    Invoice Details
                  </h5>
                  <div className="text-sm text-Neutral500">Invoice Date</div>
                  <div className="text-sm font-semibold text-Neutral800">
                    {formatDate(invoice?.invoiceDate)}
                  </div>
                  <div className="text-sm text-Neutral500">Due Date</div>
                  <div className="text-sm font-semibold text-Neutral800">
                    {formatDate(invoice?.dueDate)}
                  </div>
                </div>
              </div>
              {/* SECTION 2 */}

              {/* SECTION 3 */}
              {/*  */}
              <div className="flex flex-col gap-3">
                <div className="relative">
                  <table
                    className="w-full text-sm text-left text-gray-500"
                    id="section"
                  >
                    <thead className="text-xs font-normal text-gray-400">
                      <tr>
                        <th scope="col" className="py-2 w-2/5">
                          Description
                        </th>
                        {invoice?.services?.some(
                          (service) => service?.serviceType === "Hourly"
                        ) && (
                          <th scope="col" className="py-2 text-left w-1/5">
                            Hours
                          </th>
                        )}

                        <th scope="col" className="py-2 text-left w-1/5">
                          {invoice?.services?.some(
                            (service) => service?.serviceType === "Hourly"
                          ) && <>Price/HR</>}
                        </th>

                        <th scope="col" className="py-2 text-left w-1/5">
                          Subtotal
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice?.services?.map((service, i) => (
                        <tr className="bg-white border-b" key={i}>
                          <td
                            scope="row"
                            className="py-2 font-medium text-gray-900 break-all w-3/5 pr-4"
                          >
                            {service?.serviceName}
                          </td>
                          <td className="py-2 text-left">
                            {service?.serviceType !== "Fixed" && (
                              <>{service?.quantity}</>
                            )}
                          </td>
                          <td className="py-2 text-left">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: invoice?.currency || "USD",
                            }).format(service?.servicePrice)}
                          </td>
                          <td className="py-2 text-right">
                            {service.serviceType === "Fixed" ? (
                              <>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: invoice?.currency || "USD",
                                }).format(service?.servicePrice)}
                              </>
                            ) : (
                              <>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: invoice?.currency || "USD",
                                }).format(
                                  service?.quantity * service?.servicePrice
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                      <tr className="bg-white">
                        <th
                          scope="row"
                          className="py-2 font-medium text-gray-900 whitespace-nowrap"
                        ></th>
                        <td className="py-2 text-left"></td>
                        <td className="py-2 text-left">Subtotal</td>
                        <td className="py-2 text-right">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: invoice?.currency || "USD",
                          }).format(subtotal)}
                        </td>
                      </tr>
                      {invoice?.discount > 0 && (
                        <tr className="bg-white">
                          <th
                            scope="row"
                            className="py-2 font-medium text-gray-900 whitespace-nowrap"
                          ></th>
                          <td className="py-2 text-left"></td>
                          <td className="py-2 text-left">
                            Discount ({invoice?.discount}%)
                          </td>
                          <td className="py-2 text-right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: invoice?.currency || "USD",
                            }).format(getDiscount(subtotal, invoice?.discount))}
                          </td>
                        </tr>
                      )}
                      {(invoice?.is_VAT && (
                        <tr className="bg-white">
                          <th
                            scope="row"
                            className="py-2 font-medium text-gray-900 whitespace-nowrap"
                          ></th>
                          <td className="py-2 text-left"></td>
                          <td className="py-2 text-left">
                            VAT ({invoice?.vatPercentage}%)
                          </td>
                          <td className="py-2 text-right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: invoice?.currency || "USD",
                            }).format(
                              getVat(
                                subtotal,
                                invoice?.vatPercentage,
                                invoice?.discount
                              )
                            )}
                          </td>
                        </tr>
                      )) ||
                        invoice.taxes?.map((tax, i) => (
                          <tr className="bg-white">
                            <th
                              scope="row"
                              className="py-2 font-medium text-gray-900 whitespace-nowrap"
                            ></th>
                            <td className="py-2 text-left"></td>
                            <td className="py-2 text-left">
                              {tax.taxName} ({tax?.taxValue}%)
                            </td>
                            <td className="py-2 text-right">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: invoice?.currency || "USD",
                              }).format(
                                getVat(
                                  subtotal,
                                  tax?.taxValue,
                                  invoice?.discount
                                )
                              )}
                            </td>
                          </tr>
                        ))}
                      <tr className="bg-white border-b">
                        <th
                          scope="row"
                          className="py-2 font-medium text-gray-900 whitespace-nowrap"
                        ></th>
                        <td className="py-2 text-left"></td>
                        <td className="py-2 font-semibold text-left uppercase text-zinc-800">
                          Total Due
                        </td>
                        <td className="py-2 font-semibold text-right uppercase text-zinc-800">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: invoice?.currency || "USD",
                          }).format(
                            getTotalDue(
                              subtotal,
                              invoice?.discount,
                              invoice?.vatPercentage,
                              invoice?.taxes,
                              invoice?.is_VAT
                            )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* SECTION 3 */}

                {/* SECTION 4 */}
                <div id="section">
                  {invoice.paymentLink &&
                    invoice.paymentMethod === "Paypal" && (
                      <Link
                        to={invoice.paymentLink}
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center gap-2 cursor-pointer justify-end"
                      >
                        <span className="text-sm font-semibold underline text-zinc-800 ">
                          Click here to pay with
                        </span>
                        <span
                          className="text-sm font-semibold text-zinc-800"
                          id="paypalicon"
                        >
                          <PaypalIcon />
                        </span>
                      </Link>
                    )}
                  {invoice.paymentLink &&
                    invoice.paymentMethod === "Stripe" && (
                      <Link
                        to={invoice.paymentLink}
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center gap-2 cursor-pointer justify-end"
                      >
                        <span className="text-sm font-semibold underline text-zinc-800 ">
                          Click here to pay with
                        </span>
                        <span
                          className="text-sm font-semibold text-zinc-800"
                          id="paypalicon"
                        >
                          <StripeIcon />
                        </span>
                      </Link>
                    )}
                  {invoice.paymentMethod === "Bank" && invoice?.bankDetails && (
                    <div className="flex flex-col w-full gap-2 p-4 mt-3 border border-Neutral300 ">
                      <h5 className="text-sm font-semibold uppercase text-Neutral800">
                        Bank Details
                      </h5>
                      <div className="text-sm whitespace-pre-wrap text-Neutral700">
                        {invoice.bankDetails}
                      </div>
                    </div>
                  )}
                </div>
                {/* SECTION 4 */}
              </div>

              <div className="p-4 bg-Neutral000 w-full max-w-[788px] relativeCSS px-16 mx-auto mt-auto">
                <a
                  href="https://www.clientmanager.io/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-Neutral800 text-[8px] font-normal leading-[7px] flex items-center justify-center"
                >
                  <img src={ProposalFooterLogo} alt="logo" id="cm_logo" className="mr-[6px] " />
                  Made with
                  <span className="text-Neutral900 font-bold ml-[2px]">
                    {" "}
                    ClientManager
                  </span>
                </a>
              </div>
            </div>

            {/* A4 */}
            {/* right */}

            {/* <hr className="w-full h-[.5px] mb-6 mt-9" /> */}
          </div>
        </div>
        {/* invoice */}
        {/* invoice */}
        <div className="custom-wow-alert fixed bottom-[30px] right-[30px] z-300 w-[90px] text-[12px] text-Neutral800">
          Wow, you have a big screen 🙂
        </div>
      </div>
      <FollowUpEmailModal
        isOpen={showEmailModal}
        setIsOpen={setShowEmailModal}
        id={invoiceId}
        data={clientDetail?.data}
        type="Invoice"
        selectedData={invoice}
        IsDashboard="detailsPage"
      />
    </>
  );
}

export default Invoices;

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const CopyLinkModal = (props) => {
  const { setCopyStatus, closeModal, linkIsOpen, url, handleEmailModal } =
    props;

  return (
    <Modal
      isOpen={linkIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Copy Link Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
          <div>
            <div className="text-left">
              <div className="flex items-center justify-between w-full border-b border-Neutral200">
                <span className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2">
                  LINK COPIED
                </span>

                <img
                  src={Linkicon}
                  alt=""
                  className="pb-2 cursor-pointer"
                  onClick={() => {
                    copy(url);
                    setCopyStatus("Copied");
                    setTimeout(() => {
                      setCopyStatus("Copy Link");
                    }, 1000);
                  }}
                />
              </div>
              <div className="flex flex-col gap-6 items-center justify-center w-[354px] py-10">
                <svg
                  width="107"
                  height="92"
                  viewBox="0 0 107 92"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M52.4575 0.303001C76.8197 0.303001 106.147 25.6099 106.147 50.089C106.147 74.568 66.6155 91.2822 42.2533 91.2822C17.891 91.2822 0.78125 64.4887 0.78125 40.0097C0.781249 15.5307 28.0953 0.303001 52.4575 0.303001Z"
                    fill="#F0F0FF"
                  />
                  <path
                    d="M46.2012 51.3635C47.3858 52.9472 48.8971 54.2576 50.6327 55.2059C52.3683 56.1541 54.2875 56.718 56.2602 56.8592C58.2329 57.0005 60.2128 56.7159 62.0658 56.0247C63.9188 55.3335 65.6015 54.2518 66.9997 52.8531L75.275 44.5778C77.7874 41.9766 79.1775 38.4927 79.1461 34.8764C79.1147 31.2601 77.6642 27.8009 75.107 25.2437C72.5498 22.6866 69.0906 21.236 65.4743 21.2046C61.8581 21.1732 58.3741 22.5634 55.7729 25.0757L51.0284 29.7926"
                    stroke="#292933"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M59.5997 43.4831C58.4151 41.8994 56.9038 40.589 55.1682 39.6408C53.4326 38.6925 51.5134 38.1287 49.5407 37.9874C47.5681 37.8461 45.5881 38.1307 43.7351 38.822C41.8821 39.5132 40.1994 40.5948 38.8012 41.9935L30.5259 50.2688C28.0136 52.8701 26.6234 56.354 26.6548 59.9702C26.6862 63.5865 28.1367 67.0457 30.6939 69.6029C33.2511 72.1601 36.7103 73.6106 40.3266 73.642C43.9428 73.6734 47.4268 72.2833 50.028 69.7709L54.7449 65.054"
                    stroke="#292933"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="text-sm font-normal text-center text-Neutral800 w-80">
                  Send this copied link to your client for them to{" "}
                  <span className="font-semibold">view the invoice online</span>
                </span>
              </div>

              <div className="mt-8">
                <button
                  onClick={(e) => {
                    copy(url);
                    setCopyStatus("Copied");
                    setTimeout(() => {
                      setCopyStatus("Copy Link");
                    }, 1000);
                    closeModal();
                  }}
                  className="relative block w-full py-3 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out rounded btn button-hover form-primary-btn text-Neutral100 bg-AccentRegular drop-shadow-Texts focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight"
                >
                  <span className="relative z-10 drop-shadow-Texts">
                    Got it! I’ll send the link
                  </span>
                </button>

                <button
                  onClick={(e) => {
                    handleEmailModal();
                  }}
                  className="relative text-sm text-center block text-AccentRegular bg-Neutral100 font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out mt-4 border border-AccentRegular hover:bg-Neutral300"
                >
                  SEND AS EMAIL
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => closeModal()}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};
