import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { GetCollabClientDetailAction } from "../../../store/actions/clientAction";
import { DeleteServiceAction } from "../../../store/actions/serviceAction";
import {
  DeleteTaskAction,
  UpdateTaskActions,
} from "../../../store/actions/tasksAction";
import CloseIcon from "../../Icons/CloseIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import FullScreenModal from "./components/FullScreenModal/FullScreenModal";
import CollabMenuOfColumns from "./components/MenuOfColumns/CollabMenuOfColumns";
import CollabTaskMainContent from "./components/TaskMainContent/CollabTaskMainContent.jsx";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
    overflow: "hidden",
  },
  content: {
    width: "fit-content",
    height: "fit-content",
    top: "50%",
    right: 0,
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: 0,
    background: "none",
    borderRadius: "10px",
    overflow: "visible",
  },
};

const CollabTaskDetailModal = ({
  clientId,
  types,
  projectId,
  columnsName,
  column,
  project,
  setIsOpenTimer,
  setSelectedTask,
  user_type,
  access_level,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const cardId = params.get("cardId");
  const isNew = params.get("isNew");
  const [modalClient, setIsmodalClient] = useState(true);
  const [columnChange, setColumnChange] = useState(column);

  const user = useSelector((state) => state?.collab_auth?.user);
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const data = useSelector((state) => state.tasks.all_tasks);

  const [taskId, setTaskId] = useState(null);
  const [newTask, setNewTask] = useState(false);
  const [item, setItem] = useState(
    types === "task"
      ? data[projectId]?.find((ele) => ele?._id === taskId)
      : data[taskId]
  );

  useEffect(() => {
    setTaskId(cardId);
  }, [cardId]);

  useEffect(() => {
    if (!taskId && isNew) {
      setNewTask(true);
    }
  }, [taskId, isNew]);

  useEffect(() => {
    if (column) {
      setColumnChange(column);
    }
  }, [column]);

  useEffect(() => {
    if (clientId && clientDetail?.data?._id !== clientId) {
      dispatch(GetCollabClientDetailAction(clientId));
    }
  }, [clientId]);

  const handleClose = () => {
    setIsmodalClient(false);
    setTaskId(null);
    navigate({ search: "" });
  };

  useEffect(() => {
    if (data) {
      setItem(
        types === "task"
          ? data[projectId]?.find((ele) => ele?._id === taskId)
          : data[taskId]
      );
    }
  }, [data, taskId, isNew]);

  const handleClickColumnChange = (column) => {
    setColumnChange(column);
    if (types === "task") {
      dispatch(
        UpdateTaskActions({
          ...item,
          column: column.name,
          user_type: "client_user",
        })
      );
    }
  };

  const handleDeleteTask = (id) => {
    if (types === "task") {
      if (user_type) {
        dispatch(
          DeleteTaskAction(
            {
              _id: id,
              project: projectId,
              user_type: user_type,
            },
            handleClose
          )
        );
      } else {
        dispatch(
          DeleteTaskAction(
            {
              _id: id,
              project: projectId,
            },
            handleClose
          )
        );
      }
    }
    if (types === "service") {
      dispatch(DeleteServiceAction(id));
    }
  };

  useEffect(() => {
    window.history.replaceState({}, "", window.location.pathname);
  }, []);

  return (
    modalClient && (
      <Modal
        isOpen={modalClient}
        onRequestClose={handleClose}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Collab Task Detail Modal"
      >
        <div className="relative animate__fadeInUp w-full md:w-[75dvw]">
          <div>
            <CollabMenuOfColumns
              column={columnChange ? columnChange : { name: item?.column }}
              columnsName={columnsName}
              projectId={projectId}
              clientId
              project={project}
              handleClickColumnChange={handleClickColumnChange}
              clientDetail={clientDetail?.data}
              item={item}
              access_level={access_level}
            />
          </div>

          <CollabTaskMainContent
            clientDetail={clientDetail?.data}
            types={types}
            item={item}
            project={project}
            isNew={newTask}
            cardId={taskId}
            setIsOpenTimer={setIsOpenTimer}
            setSelectedTask={setSelectedTask}
            setTaskId={setTaskId}
            setNewTask={setNewTask}
            access_level={access_level}
          />

          <div className="absolute -top-[5px] right-0 z-10 flex items-center justify-end gap-3">
            <FullScreenModal
              clientDetail={clientDetail?.data}
              types={types}
              item={item}
              project={project}
            />
            {user?._id === item?.created_collab_user_by &&
            access_level === "full_access" ? (
              <div className="flex px-1 ">
                <Menu as="div" className="relative inline-block">
                  <div className="flex items-center">
                    <Menu.Button
                      className="relative inline-flex justify-center w-full focus-visible:outline-none"
                      disabled={newTask ? true : false}
                    >
                      <DeleteIcon
                        width={18}
                        color="currentColor"
                        className="text-[#E56780] hover:text-Neutral000 hover:bg-[#E56780] duration-300 transition-all rounded-[50%] h-[32px] w-[32px] flex items-center justify-center p-1 bg-[#FFF0F3]"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="z-10 origin-top-right absolute -top-[20px] right-[-100px] pt-4 pb-6 w-[384px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none"
                      onClick={(e) => {
                        if (e.target === e.currentTarget) {
                          e.stopPropagation();
                        }
                      }}
                    >
                      <div className="">
                        <p className="pb-2 text-xs font-semibold text-Neutral700">
                          Are you sure?
                        </p>
                      </div>
                      <div className="overflow-hidden border-0">
                        <h3
                          className="w-full py-4 mt-5 text-xs font-semibold text-center text-white duration-300 rounded cursor-pointer bg-Red400 hover:bg-Red600"
                          onClick={() => handleDeleteTask(item?._id)}
                        >
                          Yes, Delete Task
                        </h3>
                      </div>
                      <div className="overflow-hidden border-0 !border-transparent divide-0">
                        <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none">
                          <h3 className="w-full py-4 mt-3 text-xs font-semibold text-center duration-300 rounded cursor-pointer text-Neutral800 bg-Neutral200 hover:bg-Neutral500 hover:text-Neutral900">
                            No, I Still Want This Task
                          </h3>
                        </Menu.Button>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            ) : null}

            <button className="cursor-pointer " onClick={() => handleClose()}>
              <CloseIcon
                width={18}
                className="text-Neutral000 hover:text-Red400 hover:border-Red400 duration-300 transition-all rounded-[50%] border border-Neutral400 h-[32px] w-[32px] flex items-center justify-center p-1"
              />
            </button>
          </div>
        </div>
      </Modal>
    )
  );
};

export default CollabTaskDetailModal;