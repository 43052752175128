import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const MenuOfColumns = ({
  types,
  clientDetail,
  column,
  columnsName,
  handleClickColumnChange,
  item,
}) => {
  const titleDropDownRef = useRef(null);

  const [customTitle, setCustomTitle] = useState("");
  const [titleData, setTitleData] = useState(
    types === "task" ? clientDetail?.task_screen : clientDetail?.service_screen
  );

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setTitleData(
      types === "task"
        ? clientDetail?.task_screen
        : clientDetail?.service_screen
    );
  }, [clientDetail, types]);

  return (
    <Menu
      as="div"
      className={`relative rounded-t w-fit py-1.5 pl-4 pr-2 inline-block text-left z-20 bg-${
        column?.color
          ? column?.color
          : columnsName?.find((c) => c.name === column?.name)?.color
      }200`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{
        background: column?.color + "70",
      }}
    >
      <Menu.Button
        ref={titleDropDownRef}
        className={"flex items-center justify-center gap-2"}
        disabled={
          clientDetail?.is_admin ||
          clientDetail?.is_manager ||
          item?.created_by?._id === user?._id
            ? false
            : true
        }
      >
        {column?.name}
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7.5L10 12.5L5 7.5"
            stroke="#173B82"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-[9999] left-1 top-[calc(100%+4px)] pt-3 pb-2 min-w-40 px-2 rounded-lg Timetrackingdropdown bg-white divide-y divide-Neutral300 focus:outline-none border border-Neutral300">
          <div className="mb-3">
            <input
              className="w-full px-2 text-xs font-normal placeholder:text-Neutral600 text-Neutral700 focus:outline-none"
              placeholder="Select Task Column"
              value={customTitle}
              onKeyUp={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Enter") {
                  if (
                    titleData?.filter((t) => t === customTitle).length === 0
                  ) {
                    setTitleData([...titleData, customTitle]);
                    setCustomTitle("");
                    setTimeout(() => {
                      titleDropDownRef?.current?.click();
                    }, 0);
                  } else {
                    toast.error("Title already exists");
                  }
                }
              }}
              onChange={(e) => {
                setCustomTitle(e.target.value.toUpperCase());
              }}
            />
          </div>
          <div className="project-title-divide">
            <div className="overflow-hidden border-0 h-[100%] overflow-y-auto currancy-scrollbar scrollbar-w-4 relative flex flex-col gap-1">
              {columnsName?.map((items, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center px-2 py-1 duration-300 rounded-sm rounded cursor-pointer hover:bg-Neutral200 group "
                    onClick={(e) => {
                      // TODO: change column
                      handleClickColumnChange(items);

                      // setIsGeneral(items);
                      setTimeout(() => {
                        titleDropDownRef?.current?.click();
                        e.stopPropagation();
                      }, 0);

                      // dispatch(
                      //   ChangeColumnPosition(
                      //     {
                      //       id: clientId,
                      //       columns: items.name,
                      //       // type: types,
                      //     },
                      //     navigate
                      //   )
                      // );
                    }}
                  >
                    <p className="flex items-center w-full gap-2 font-normal rounded cursor-pointer text-Neutral800 group-hover:text-Neutral900 ">
                      <span
                        className={`w-2 h-2 rounded-full	bg-${items?.color}400`}
                      ></span>
                      <span className="text-xs font-medium ">{items.name}</span>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MenuOfColumns;
