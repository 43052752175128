import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import App from "./App";
import "./index.css";
import setupAxios from "./setupAxios";
import Store from "./store";
setupAxios(axios);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={Store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById("root"),
);
