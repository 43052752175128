import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import UploadImage from "../../components/Buttons/UploadImage";
import UploadPDF from "../../components/Buttons/UploadPDF";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import BgShape1 from "../../components/Icons/BgShape1";
import BgShape2 from "../../components/Icons/BgShape2";
import BgShape3 from "../../components/Icons/BgShape3";
import ResetIcon from "../../assets/Images/Reset.svg";
import TrashDark from "../../assets/Images/Trash-dark.svg";
import EditlightIcon from "../../assets/Images/Edit-white.svg";
import CheckmarkIcon from "../../assets/Images/Checkmark.svg";
import CheckmarkIcon16 from "../../assets/Images/Checkmark-16.svg";
import Modal from "react-modal";
import CloseBtn from "../../assets/Images/close-btn.svg";
import RoundLogo from "../../assets/Images/Round-logo-requestInfo.svg";
import boxshape1 from "../../assets/Images/requestInfo-shape-1.svg";
import LinkIcon from "../../assets/Images/Link.svg";
import { Formik } from "formik";
import * as Yup from "yup";

const RequestInfoSchema = Yup.object().shape({
  questionnaireTitle: Yup.string().required("Form Title Required"),
  questions: Yup.array().of(
    Yup.object().shape({
      question_title: Yup.string().required("Title required").nullable(),
      question: Yup.string().required("Question required").nullable(),
      isEdit: Yup.string(),
      clone_question_title: Yup.string().when("isEdit", {
        is: "edit",
        then: Yup.string().required("Title required").nullable(),
        otherwise: Yup.string().nullable(),
      }),
      clone_question: Yup.string().when("isEdit", {
        is: "edit",
        then: Yup.string().required("Question required").nullable(),
        otherwise: Yup.string().nullable(),
      }),
    })
  ),
});

const questionsList = [
  {
    id: 1,
    question_title: "General",
    question:
      "Please provide your contact details (email & phone), people responsible, address and website URL.",
    isEdit: false,
  },
  {
    id: 2,
    question_title: "What Does Your Business Sell?",
    question:
      "What does your business sell? And what are your most popular/profitable products or services?",
    isEdit: false,
  },
  {
    id: 3,
    question_title: "Target Market",
    question: "Who is your target market and ideal client/customer?.",
    isEdit: false,
  },
  {
    id: 4,
    question_title: "Brand Assets",
    question:
      "Please add any Brand Assets, like your Logo, Icons, Brand Guide, Images, Videos, or any other relevant assets here. Or you can WeTransfer these (up to 2GB free) to my email: myagency@myagency.com",
    isEdit: false,
  },
];

function RequestInfo() {
  const searchInput = useRef(null);

  function handleFocus() {
    searchInput.current.focus();
  }
  useEffect(() => {
    handleFocus();
  }, []);

  const [formQuestions, setformQuestions] = useState(questionsList);

  const changeFormStatus = (index, status) => {
    if (status === "edit") {
      let formClone = formQuestions;
      formClone[index].clone_question_title = formClone[index].question_title;
      formClone[index].clone_question = formClone[index].question;
      formClone[index].isEdit = status;
      setformQuestions([...formClone]);
      const ele = document.getElementById(`question_title_${index}`);
      if (ele) {
        ele.focus();
      }
      return;
    }
    if (status === "cancel") {
      let formClone = formQuestions;
      formClone[index].clone_question_title = null;
      formClone[index].clone_question = null;
      formClone[index].isEdit = false;
      setformQuestions([...formClone]);
      return;
    }
    if (status === "save") {
      let formClone = formQuestions;
      formClone[index].question_title = formClone[index].clone_question_title;
      formClone[index].question = formClone[index].clone_question;
      formClone[index].clone_question_title = null;
      formClone[index].clone_question = null;
      formClone[index].isEdit = false;
      setformQuestions([...formClone]);
      return;
    }
    if (status === "delete") {
      const updateData = [...formQuestions];
      updateData.splice(index, 1);
      setformQuestions([...updateData]);
      return;
    }
  };

  const changeFormValue = (index, field, value) => {
    let formClone = formQuestions;
    formClone[index][field] = value;
    setformQuestions([...formClone]);
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(41, 41, 51, 0.7)",
      overflowY: "auto",
    },
    content: {
      position: "unset",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      border: "none",
      padding: "47px",
      background: "none",
      borderRadius: "10px",
    },
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const clientList = [
    {
      id: 1,
      number: 1,
      title: "General",
      details:
        "Please provide your contact details (email & phone), people responsible, address and website URL.",
      placeholder: "Eg. clientname@mail.com...",
    },
    {
      id: 2,
      number: 2,
      title: "What Your Business Sells",
      details:
        "What does your business sell? And what are your most popular/profitable products or services?",
      placeholder: "Eg. Lots and lots of mangoes",
    },
    {
      id: 3,
      number: 3,
      title: "Target Market",
      details: "Who is your target market and ideal client/customer?",
      placeholder: "Eg. Lots and lots of mangoes",
    },
    {
      id: 4,
      number: 4,
      title: "Marketing & Advertising",
      details:
        "What marketing & advertising are you currently doing and how much are you spending each month?",
      placeholder: "",
    },
    {
      id: 5,
      number: 5,
      title: "Content",
      details:
        "Please provide us with any content for your About, Home, Services, Product, Contact, and Pricing pages. You can add content or a Google Doc URL below",
      placeholder: "",
    },
    {
      id: 6,
      number: 6,
      title: "Social Media",
      details:
        "If you have any social media profiles, like Twitter, Facebook, Instagram, YouTube, etc., please add them here.",
      placeholder: "",
    },
    {
      id: 7,
      number: 7,
      title: "Brand Assets",
      details:
        "Please add any Brand Assets, like your Logo, Icons, Brand Guide, Images, Videos, or any other relevant assets here. Or you can WeTransfer these (up to 2GB free) to my email:",
      placeholder: "Add any URLs or comments here…",
      email: "myagency@myagency.com",
      UploadImage: <UploadImage />,
      UploadPDF: <UploadPDF title="Upload PDF" />,
    },
    {
      id: 8,
      number: 8,
      title: "Website Inspiration",
      details:
        "Include any links from websites you like or what like from your competitors we could incorporate into this project.",
      placeholder: "",
    },
    {
      id: 9,
      number: 9,
      title: "Testimonials / Case Studies",
      details:
        "Please add any client testimonials or case studies to use as social proof.",
      placeholder: "",
      UploadImage: <UploadImage />,
    },
    {
      id: 10,
      number: 10,
      title: "Other Content",
      details:
        "Please add or link any other content, like a Privacy Policy, Terms & Conditions, FAQ, here.",
      placeholder: "",
    },
    {
      id: 11,
      number: 11,
      title: "Tracking Scripts",
      details:
        "Please add any tracking scripts from Google Analytics, Google Ads, Search Console, Facebook, or any other relevant tracking scripts here.",
      placeholder: "",
    },
    {
      id: 12,
      number: 12,
      title: "Passwords",
      details:
        "Please provide access to any password tool you use, like 1Password, for any relevant platform I/we may need through our email ",
      placeholder: "",
      email: "myagency@myagency.com",
      extdetails:
        "(recommended) or add the passwords below (please note that this is not the most secure option).",
    },
    {
      id: 13,
      number: 13,
      title: "Hosting",
      details:
        "Please provide access to your hosting provider through our email ",
      placeholder: "",
      email: "myagency@myagency.com",
      extdetails:
        "(recommended) or add your password and relevant details below.",
    },
    {
      id: 14,
      number: 14,
      title: "Anything Else?",
      details:
        "Is there anything else you’d like to add or let us about that was not mentioned?",
      placeholder: "",
    },
  ];

  const colorList = [
    { id: 1, color: "bg-Pink400" },
    { id: 2, color: "bg-Blue400" },
    { id: 3, color: "bg-Green400" },
    { id: 4, color: "bg-AccentRegular" },
    { id: 5, color: "bg-Neutral800" },
  ];

  const [selctedBox, setSelectedBox] = useState(2);
  const [colorBox, setColorBox] = useState(4);

  return (
    <>
      <div>
        <AdminNavbar
          link="/admin/Clients"
          title="Information Request Form Builder"
          backLink="Back to Clients"
          subtitle="Fill out the form and send to your potential client."
        />
        <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
          <Formik
            initialValues={{
              questionnaireTitle: "",
              questions: formQuestions,
              // userName: "",
            }}
            enableReinitialize
            validationSchema={RequestInfoSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              console.log("submit values", values);
              setSubmitting(true);
              // dispatch(
              //   SignupAction(values, setSubmitting, setErrors, navigate)
              // );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="pt-12 pb-6">
                  <div className="flex justify-between items-center">
                    <h5 className="text-Neutral700 text-xs font-semibold tracking-[0.08em]">
                      FORM TITLE
                    </h5>
                    <Link
                      to="#"
                      className="flex text-AccentDark bg-AccentLight font-semibold text-xs rounded w-fit py-2 px-4 hover:bg-AccentMediumLight focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight transition duration-300"
                    >
                      Reset
                      <img className="ml-1" src={ResetIcon} alt="" />
                    </Link>
                  </div>
                  <div className="w-full 5xl:max-w-[400px] max-w-[320px] mt-8 mb-2">
                    <input
                      type="text"
                      className={`text-Neutral800 font-medium placeholder:text-sm placeholder:text-Neutral500 5xl:placeholder:text-lg py-3 px-4 text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition
                        ${errors.questionnaireTitle &&
                          touched.questionnaireTitle
                          ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                          : ""
                        }`}
                      name="questionnaireTitle"
                      value={values.questionnaireTitle}
                      placeholder="Eg. Info Request"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      ref={searchInput}
                    />
                    {errors.questionnaireTitle &&
                      touched.questionnaireTitle && (
                        <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none">
                          {errors.questionnaireTitle}
                        </p>
                      )}
                    <p className="font-medium text-xs mt-2 text-Neutral700 ml-1">
                      This will be the header of the questionnaire.{" "}
                    </p>
                  </div>
                  <div className="pt-[80px]">
                    <h5 className="text-Neutral700 text-xs font-semibold tracking-[0.08em]">
                      FORM QUESTIONS
                    </h5>
                    <div className="mt-10 h-[480px]">
                      <div className="flex justify-between w-full max-w-[816px] flex-wrap">
                        {/* General */}

                        {/* step 1 */}
                        {formQuestions.map((event, index) => {
                          return (
                            <div
                              key={event.id}
                              className="w-full max-w-[388px] pt-8 px-8 pb-5 border border-Neutral300 rounded mb-10"
                            >
                              {!event.isEdit ? (
                                <>
                                  <h3 className="text-Neutral900 text-sm font-bold pt-3 mb-9">
                                    {event.question_title}
                                  </h3>
                                  <div className="form-overlay relative">
                                    <p className="text-Neutral800 text-sm font-normal 5xl:h-[100px] h-[88px] overflow-y-auto overflow-hidden mb-4 pb-[28px]">
                                      {event.question}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <input
                                    type="text"
                                    id={`question_title_${index}`}
                                    focus={event.isEdit}
                                    className="text-Neutral800 font-medium placeholder:text-sm 5xl:placeholder:text-lg placeholder:text-Neutral500 py-3 h-[48px] px-4 mb-2 text-sm w-full focus:outline-none border-[1.5px] rounded-md border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                    placeholder={`Eg.${event.clone_question_title}`}
                                    value={event.clone_question_title}
                                    name={`questions[${index}].clone_question_title`}
                                    onBlur={handleBlur}
                                    onChange={(e) =>
                                      changeFormValue(
                                        index,
                                        "clone_question_title",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.questions?.[index]
                                    ?.clone_question_title &&
                                    touched.questions?.[index]
                                      ?.clone_question_title && (
                                      <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none">
                                        {
                                          errors?.questions?.[index]
                                            ?.clone_question_title
                                        }
                                      </p>
                                    )}
                                  <textarea
                                    className="text-Neutral800 resize-none font-normal placeholder:text-sm  5xl:placeholder:text-lg placeholder:text-Neutral500 py-3 px-4 mb-2 5xl:h-[116px] h-[84px] overflow-y-auto text-sm w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                    placeholder={`Eg.${event.clone_question}`}
                                    value={event.clone_question}
                                    name={`questions[${index}].clone_question`}
                                    onBlur={handleBlur}
                                    onChange={(e) =>
                                      changeFormValue(
                                        index,
                                        "clone_question",
                                        e.target.value
                                      )
                                    }
                                  />
                                  {errors?.questions?.[index]?.clone_question &&
                                    touched.questions?.[index]
                                      ?.clone_question && (
                                      <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none">
                                        {
                                          errors?.questions?.[index]
                                            ?.clone_question
                                        }
                                      </p>
                                    )}
                                </>
                              )}
                              <div className="flex justify-between relative bottom-0">
                                <div className="flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] text-Neutral800">
                                  {event.id}
                                </div>
                                {!event.isEdit ? (
                                  <div className="flex items-center">
                                    <button
                                      className="text-AccentDark bg-AccentLight flex items-center justify-center text-xs rounded h-8 5xl:w-[100px] w-[80px] border border-Neutral300 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight font-semibold hover:bg-AccentMediumLight transition duration-300"
                                      type="button"
                                      onClick={() =>
                                        changeFormStatus(index, "delete")
                                      }
                                    >
                                      Delete
                                      <img
                                        className="ml-1"
                                        src={TrashDark}
                                        alt=""
                                      />
                                    </button>
                                    <button
                                      className="text-Neutral100 bg-AccentRegular flex items-center justify-center text-xs rounded ml-3 h-8 5xl:w-[100px]  w-[80px] focus-visible:outline-purple font-semibold hover:bg-AccentMediumDark transition duration-300"
                                      type="button"
                                      onClick={() =>
                                        changeFormStatus(index, "edit")
                                      }
                                    >
                                      Edit
                                      <img
                                        className="ml-1"
                                        src={EditlightIcon}
                                        alt=""
                                      />
                                    </button>
                                  </div>
                                ) : (
                                  <div className="flex items-center">
                                    <button
                                      className="text-AccentDark bg-AccentLight flex items-center justify-center text-xs rounded h-8 5xl:w-[100px] w-[80px] border border-Neutral300 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight font-semibold hover:bg-AccentMediumLight transition duration-300"
                                      type="button"
                                      onClick={() =>
                                        changeFormStatus(index, "cancel")
                                      }
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="btn button-hover large-primary-btn text-xs rounded ml-3 h-8 5xl:w-[100px] w-[80px] text-Neutral100 bg-AccentRegular drop-shadow-Texts focus:outline focus:outline-4 focus:outline-AccentLight focus-visible:outline-0 transition duration-300 ease-in-out"
                                      type="button"
                                      disabled={
                                        errors?.questions?.[index]
                                          ?.clone_question ||
                                        errors?.questions?.[index]
                                          ?.clone_question_title
                                      }
                                      onClick={() =>
                                        changeFormStatus(index, "save")
                                      }
                                    >
                                      <span className="relative drop-shadow-Texts z-10 flex items-center justify-center">
                                        Save
                                        <img
                                          className="ml-1"
                                          src={CheckmarkIcon16}
                                          alt=""
                                        />
                                      </span>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    // onClick={openModal}
                    className="btn button-hover large-primary-btn fixed bottom-[80px] right-[48px] text-sm text-center flex items-center justify-center h-[48px] text-Neutral100 bg-AccentRegular font-semibold w-full 5xl:max-w-[200px] max-w-[172px] rounded drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out"
                  >
                    <span className="relative drop-shadow-Texts z-10">
                      View Form Preview
                    </span>
                  </button>
                </div>
              </form>
            )}
          </Formik>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            closeTimeoutMS={300}
            contentLabel="View Form Preview Modal"
          >
            <div className="view-form-prev relative bg-white text-left transform transition-all sm:my-8 max-w-[604px] min-w-[604px] mx-auto sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
              <div className="bg-white px-[60px] pt-[60px] pb-[24px] rounded-[8px] relative overflow-hidden">
                <div
                  className={`absolute top-0 left-0 h-full bg-shap-main ${selctedBox === 2
                      ? "box-1"
                      : selctedBox === 3
                        ? "box-2"
                        : selctedBox === 4
                          ? "box-3"
                          : ""
                    } ${colorBox === 1
                      ? "color-1"
                      : colorBox === 2
                        ? "color-2"
                        : colorBox === 3
                          ? "color-3"
                          : colorBox === 4
                            ? "color-4"
                            : colorBox === 5
                              ? "color-5"
                              : ""
                    }`}
                >
                  <div className="bg-shape-1 opacity-0 transition duration-300 absolute top-0 left-0">
                    <BgShape1 />
                  </div>
                  <div className="bg-shape-2 opacity-0 transition duration-300 absolute top-0 left-0">
                    <BgShape2 />
                  </div>
                  <div className="bg-shape-3 opacity-0 transition duration-300 absolute top-0 left-0">
                    <BgShape3 />
                  </div>
                </div>
                <div>
                  <div className="border-b border-Neutral400">
                    <h2 className="text-black font-bold text-[20px] 5xl:text-[24px] mb-5">
                      Bob Builderino Information Request
                    </h2>
                    <p className="text-xs text-Neutral800 pb-10">
                      Please complete these steps as best as you can. Have any
                      questions? Email us at{" "}
                      <strong
                        className={`text-AccentRegular transition duration-300 ${colorBox === 1
                            ? "text-color-1"
                            : colorBox === 2
                              ? "text-color-2"
                              : colorBox === 3
                                ? "text-color-3"
                                : colorBox === 4
                                  ? "text-color-4"
                                  : colorBox === 5
                                    ? "text-color-5"
                                    : ""
                          }`}
                      >
                        myagency@myagency.com
                      </strong>{" "}
                      so that we can help you out.
                    </p>
                  </div>
                  {clientList.map((item) => (
                    <div className="pt-[48px]" key={item.id}>
                      <div className="flex justify-between items-center mb-4">
                        <h3 className="text-black font-bold text-base">
                          {item.title}
                        </h3>
                        <div
                          className={`flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] text-AccentRegular transition duration-300 ${colorBox === 1
                              ? "text-color-1"
                              : colorBox === 2
                                ? "text-color-2"
                                : colorBox === 3
                                  ? "text-color-3"
                                  : colorBox === 4
                                    ? "text-color-4"
                                    : colorBox === 5
                                      ? "text-color-5"
                                      : ""
                            }`}
                        >
                          {item.number}
                        </div>
                      </div>
                      <p className="text-xs text-Neutral800 mb-4">
                        {item.details}{" "}
                        <strong
                          className={`text-AccentRegular transition duration-300 ${colorBox === 1
                              ? "text-color-1"
                              : colorBox === 2
                                ? "text-color-2"
                                : colorBox === 3
                                  ? "text-color-3"
                                  : colorBox === 4
                                    ? "text-color-4"
                                    : colorBox === 5
                                      ? "text-color-5"
                                      : ""
                            }`}
                        >
                          {item.email}
                        </strong>{" "}
                        {item.extdetails}
                      </p>
                      <div className="flex justify-between">
                        <div className="w-full max-w-[50%] pr-2">
                          {item.UploadImage}
                        </div>
                        <div className="w-full max-w-[50%] pl-2">
                          {item.UploadPDF}
                        </div>
                      </div>
                      <textarea
                        className="mt-4 border border-Neutral400 w-full resize-none p-6 h-[168px] placeholder:text-sm 5xl:placeholder:text-lg placeholder:font-medium font-medium focus:outline-none rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                        placeholder={item.placeholder}
                      />
                    </div>
                  ))}
                  <div className="pt-[52px] flex items-center justify-center">
                    <img src={RoundLogo} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <div className={`${modalIsOpen ? "block" : "hidden"}`}>
            <div
              className="fixed right-12 cursor-pointer top-[62px] z-[999999]"
              onClick={() => closeModal(false)}
            >
              <img src={CloseBtn} alt="" />
            </div>
            <div className="w-full max-w-[176px] fixed right-[48px] bottom-[80px]  z-[999999]">
              <div className="w-full max-w-[176px] rounded p-2 flex flex-wrap drop-shadow-Raisedmodal bg-Neutral000">
                <div
                  className="p-1 w-full max-w-[50%] cursor-pointer"
                  onClick={() => setSelectedBox(1)}
                >
                  <img
                    className={`border boredr-Neutral100 rounded-sm transition duration-300 ${selctedBox == 1
                        ? "border-4 border-AccentRegular rounded-sm"
                        : ""
                      }`}
                    src={boxshape1}
                    alt=""
                  />
                </div>
                <div
                  className="p-1 w-full max-w-[50%] cursor-pointer"
                  onClick={() => setSelectedBox(2)}
                >
                  <div
                    className={`selctedbox box-1 ${selctedBox == 2
                        ? "border-4 border-AccentRegular rounded-sm"
                        : ""
                      } ${colorBox === 1
                        ? "color-1"
                        : colorBox === 2
                          ? "color-2"
                          : colorBox === 3
                            ? "color-3"
                            : colorBox === 4
                              ? "color-4"
                              : colorBox === 5
                                ? "color-5"
                                : ""
                      }`}
                  ></div>
                </div>
                <div
                  className="p-1 w-full max-w-[50%] cursor-pointer"
                  onClick={() => setSelectedBox(3)}
                >
                  <div
                    className={`selctedbox box-2 ${selctedBox == 3
                        ? "border-4 border-AccentRegular rounded-sm"
                        : ""
                      } ${colorBox === 1
                        ? "color-1"
                        : colorBox === 2
                          ? "color-2"
                          : colorBox === 3
                            ? "color-3"
                            : colorBox === 4
                              ? "color-4"
                              : colorBox === 5
                                ? "color-5"
                                : ""
                      }`}
                  ></div>
                </div>
                <div
                  className="p-1 w-full max-w-[50%] cursor-pointer"
                  onClick={() => setSelectedBox(4)}
                >
                  <div
                    className={`selctedbox box-3 ${selctedBox == 4
                        ? "border-4 border-AccentRegular rounded-sm"
                        : ""
                      } ${colorBox === 1
                        ? "color-1"
                        : colorBox === 2
                          ? "color-2"
                          : colorBox === 3
                            ? "color-3"
                            : colorBox === 4
                              ? "color-4"
                              : colorBox === 5
                                ? "color-5"
                                : ""
                      }`}
                  ></div>
                </div>
              </div>
              <div className="w-full max-w-[176px] rounded p-2 flex flex-wrap mt-6 mb-12 drop-shadow-Raisedmodal bg-Neutral000">
                {colorList.map((item) => (
                  <div
                    key={item.id}
                    className="p-1 w-full max-w-[20%] cursor-pointer"
                    onClick={() => setColorBox(item.id)}
                  >
                    <span
                      className={`w-6 h-6 rounded-full block transition duration-300 ${item.color}`}
                    >
                      {/* {colorBox == item.id ? <img src={CheckmarkIcon} alt="" /> : "" } */}
                      <img
                        className={`transition duration-300 ${colorBox == item.id ? "opacity-100" : "opacity-0"
                          }`}
                        src={CheckmarkIcon}
                        alt=""
                      />
                    </span>
                  </div>
                ))}
              </div>
              <Link
                to="#"
                className=" button-hover large-primary-btn text-sm text-center flex items-center justify-center h-[48px] text-Neutral100 bg-AccentRegular font-semibold w-full rounded focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out drop-shadow-Raisedmodal"
                type="button"
              >
                <span className="relative drop-shadow-Texts z-10 flex items-center">
                  Copy Link <img className="ml-1" src={LinkIcon} alt="" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RequestInfo;
