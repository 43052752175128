import React from "react";

const PaymentDetailLoader = () => {
  return (
    <div className="w-full 5xl:max-w-[500px] max-w-[408px] rounded border border-Neutral300 pt-8 px-10 pb-10 mt-10">
      <div className="w-full flex justify-between items-center mb-12">
        <h5 className="text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase">
          account
        </h5>
        <div className="w-[7rem]  mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
      </div>
      <div className="w-full flex justify-between items-center mb-8">
        <h5 className="w-2/6 mb-4 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></h5>
        <span className="w-2/6 mb-4 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
      </div>
      <div className="w-full flex justify-between pb-10">
        <h5 className="w-2/6 mb-4 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></h5>
        <span className="w-2/6 mb-4 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></span>
      </div>
      <div className="pb-6">
        <div className="w-full flex justify-between items-center ">
          <h5 className="w-2/6 mb-4 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></h5>
          <div className="inline-block w-2/6 mb-4 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative">
            <span className="h-[20px] leading-[23px]"></span>
          </div>
        </div>
        <p className="text-right w-full text-AccentRegular text-[10px] font-semibold"></p>
      </div>
      <div className="pb-6">
        <div className="w-full flex justify-between items-center ">
          <h5 className="text-Neutral600 font-medium text-[10px] uppercase tracking-[0.04em]"></h5>
          <div className="inline-block"></div>
        </div>
        <p className="text-right w-full text-AccentRegular text-[10px] font-semibold"></p>
      </div>
      <div className="w-full flex justify-between items-center pt-8">
        <h5 className="w-2/6 mb-4 rounded bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></h5>
        <div className="w-[7rem] h-7 mb-4 bg-[#f6f7f8] inline-block h-3 bg-no-repeat skeleton-animation relative"></div>
      </div>
    </div>
  );
};

export default PaymentDetailLoader;
