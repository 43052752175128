import React, { useEffect, useState, useCallback } from 'react';
import loader from '../../../../assets/Images/loader-icon.svg';
import { useNavigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import NameInput from '../Components/NameInput';
import CreatePasswordInput from '../Components/CreatePasswordInput';
import { useDispatch } from "react-redux";
import { CollabVerifyLicense } from "../../../../store/actions/collabAuthAction";

const ClientCollabSignup = () => {
  const { state, search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEmailExist, setIsEmailExist] = useState(true);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const[collabverification, setCollabVerification] = useState(false);
  const params = new URLSearchParams(search);
  const uuid = params.get("q");

  const [signInDetail, setSignInDetail] = useState({
    step: 1,
    email: '',
    name: '',
    password: '',
    user: null,
  });

  const [isContinueEnable, setIsContinueEnable] = useState(true);

  useEffect(() => {
    document.body.classList.add('home-page');
    return () => {
      document.body.classList.remove('home-page');
    };
  }, []);
  useEffect(() => {
    if (state) {
      setSignInDetail(state);
      if (state.email) {
        setIsEmailExist(true);
      }
    }
  }, [state]);

  const stepChange = async (isplus) => {
    const newChange = {
      ...signInDetail,
      step: isplus ? signInDetail.step + 1 : signInDetail.step - 1,
    };
    setIsContinueEnable(true);
    navigate('', {
      state: newChange,
      replace: true,
    });
    setSignInDetail(newChange);
  };

  useEffect(() => {
    if (signInDetail?.step === 1) {
      if (signInDetail?.email?.length) {
        setIsContinueEnable(false);
      }
    }
    if (signInDetail?.step === 2) {
      if (signInDetail?.name) {
        setIsContinueEnable(false);
      }
      else if (signInDetail?.name?.length === 0) {
        setIsContinueEnable(true);
      }
    }
  }, [signInDetail, isEmailExist]);

  useEffect(() => {
    const verification = async () => {
      if (uuid) {
        const res = await dispatch(CollabVerifyLicense({ uuid: uuid }, setCollabVerification, navigate));
       
        try {
          if (res?.user?.email) {
            setSignInDetail(prevState => ({
              ...prevState,
              email: res.user.email,
            }));
          }
        } catch (error) {
          console.error('Error setting email:', error);
        }
      }
    };
    verification();

  }, [uuid]);



  return (
    
    <>
      {collabverification && signInDetail?.email?.length !== 0 ? (<><div className='pr-[32px] pt-[16px] flex justify-end items-center'>
        <p className='Neutral700 text-xs font-medium mr-[24px]'>
          Have an account?
        </p>
        <Link
          to='/client-collab/signin'
          className='text-xs text-center inline-block text-AccentRegular font-[600] py-[7px] px-2 rounded-[4px] border-[1px] border-Neutral300 hover:border-AccentRegular focus-visible:outline focus-visible:outline-4 focus-visible:outline-AccentLight transition duration-300 ease-in-out'
        >
          Sign In
        </Link>
      </div>
      <section className='container flex m-auto min-h-[90vh] items-center justify-center'>
        <div className='flex-col justify-center  flex'>
          
        {signInDetail.step === 1 ? (
            <>
            <div>
            <p className="text-xs lg:text-base text-Neutral800 font-semibold">
              Step 1 of 3
            </p>
            <h2 className="text-Neutral900 font-bold text-xl lg:text-[32px] pt-2 pb-1">
            Confirm Your Email
            </h2>
          </div>
          <div className="relative flex p-3 mr-2 mt-[52px] mb-[24px] border-[1.5px] w-[320px] border-Neutral500 rounded-md focus:drop-shadow-Purpleboxshadow duration-300 transition">
            <input type='text' value={signInDetail?.email} disabled
            className="text-Neutral600 font-medium placeholder:text-Neutral500 pr-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none "
            
            />
            </div>
            </>
          ) : null}

          {signInDetail.step === 2 ? (
            <div className='onboading-form-content w-full'>
              <div>
                <p className='text-xs lg:text-base text-Neutral800 font-semibold'>
                  Step 2 of 3
                </p>
                <h2 className='text-Neutral900 font-bold text-xl lg:text-[32px] pt-2 pb-1'>
                  Enter your name
                </h2>
              </div>
              <div class='mt-[52px]'>
                <NameInput
                  setSignInDetail={setSignInDetail}
                  signInDetail={signInDetail}
                />
              </div>
            </div>
          ) : null}
          {signInDetail.step === 3 && (
            <CreatePasswordInput
              signInDetail={signInDetail}
              setSignInDetail={setSignInDetail}
              clientCollab={true}
            />
          )}

          {signInDetail?.step !== 3 ? (
            <div className='w-full lg:mt-[40px] 3xl:mt-[60px] mt-[24px] mb-3'>
              <button
                type='button'
                className={`uppercase rounded-lg  ${
                  isContinueEnable
                    ? 'text-Neutral500 bg-Neutral300'
                    : 'bg-AccentRegular text-Neutral000 working button-hover relative btn-no-data focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight cursor-pointer btn-no-data'
                } font-bold md:h-[46px] h-[60px]  p-[0px_24px] md:mr-4 md:w-auto w-full`}
                onClick={() => stepChange(true)}
                disabled={isContinueEnable}
              >
                {loading ? (
                  <img src={loader} alt='loader' className='animate-spin' />
                ) : (
                  <span class='relative z-10'>continue</span>
                )}
              </button>
              <button
                type='button'
                onClick={() => {
                  if (signInDetail.step >= 1) {
                    stepChange(false);
                  }
                }}
                disabled={signInDetail.step === 1}
                className={`${
                  signInDetail.step !== 1
                    ? 'button-hover btn-no-data hover:text-[#fff] md:inline-block hidden'
                    : 'hidden'
                } relative focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight cursor-pointer uppercase transition duration-300 ease-in-out rounded-lg text-Neutral900 font-bold h-[46px] bg-Neutral300 p-[0px_24px] `}
              >
                <span class='relative z-10'>go back</span>
              </button>
              <button
                type='button'
                onClick={() => {
                  if (signInDetail.step >= 1) {
                    stepChange(false);
                  }
                }}
                disabled={signInDetail.step === 1}
                className='text-AccentRegular text-xs font-semibold inline-block md:hidden'
              >
                Back to Step
              </button>
            </div>
          ) : null}
        </div>
      </section>
      <div className='flex justify-end pr-8'>
        <p className=' text-Neutral800 text-xs font-medium'>
          Have a question? Email us at{' '}
          <span className='text-AccentRegular'>support@clientmanager.io</span>
        </p>
      </div>
      </>): null}      
    </>
  );
};

export default ClientCollabSignup;
