import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import noClient from "../../assets/Images/time-tracking.svg";
import DashboardButton from "../../components/Buttons/DashboardButton";
import TimeTrackingLoader from "../../components/ContentLoaders/TimeTrackingLoader";
import ExportTimeEntryModal from "../../components/Modals/ExportTimeEntryModal";
import TrackingAddProjectModal from "../../components/Modals/TrackingAddProjectMoodal";
import TrackingAddTimeEntryModal from "../../components/Modals/TrackingAddTimeEntryModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import Pagination from "../../components/Pagination";
import TimeTrackingTextDetail from "../../components/Tracking/TimeTrackingTextDetail";
import { ClientListWithNoTimeTrackingProject } from "../../store/actions/clientAction";
import { GetProjectAction, GetProjectClientsList } from "../../store/actions/timeTrackingAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const Tracking = () => {
  const dispatch = useDispatch();
  const Newtimesentry = [{ id: 1, time: "000:00" }];
  const [page, setPage] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [exportModalIsOpen, setExportIsOpen] = useState(false);
  const [projectModel, setProjectModel] = useState(false);
  const [addNewTimeEntry, setAddNewTimeEntry] = useState(null);
  const [modalClientDetail, setModalClientDetail] = useState(null);
  const client = useSelector((state) => state.tracking.clientList);
  const myClient = useSelector((state) => state.client.clientListWithNoTimeTrackingProject);
  const trackingProjectList = useSelector((state) => state.tracking.trackingProjectList);

  console.log(client);

  useEffect(() => {
    if (myClient?.data?.length) {
      dispatch(ClientListWithNoTimeTrackingProject());
    }
  }, [dispatch, myClient?.data?.length]);

  useEffect(() => {
    dispatch(GetProjectAction({ page: page, perPage: 10 }));
  }, [dispatch, page]);

  function openModal() {
    setIsOpen(true);
  }
  function openExportModal() {
    setExportIsOpen(true);
  }
  function openProjectModal() {
    setProjectModel(true);
  }

  function closeProjectModal() {
    setProjectModel(false);
  }

  useEffect(() => {
    if (!client?.data?.length) {
      dispatch(GetProjectClientsList());
    }
  }, [dispatch, client?.data?.length]);

  const [editSaveEntry, setEditSaveEntry] = useState(null);
  const [updatedTimeData, setUpdatedTimeData] = useState(Newtimesentry);

  const handleSubmit = () => {
    const data = editSaveEntry;
    if (!data.time.length) {
      data.time = "000:00";
    } else {
      if (data.time.length < 3) {
        data.time = `${data.time}:00`;
      } else {
        if (data.time.includes(":")) {
          const split = data.time.split(":");
          const hours = split[0];
          const mins = split?.[1]?.length
            ? split?.[1]?.length > 1
              ? split[1]
              : `0${split[1]}`
            : "00";
          data.time = hours + ":" + mins;
        }
      }
    }
    handleSaveTime();
  };

  const handleSaveTime = () => {
    setUpdatedTimeData([...updatedTimeData]);
  };

  console.log("client", client);

  return (
    <>
      <AdminNavbar title="Time Tracking" subtitle="Manage the time spent on client projects." />
      <div className="w-full pt-0">
        <div className="md:mt-[32px] mt-[16px] text-right px-5 lg:px-12 5xl:px-[96px]">
          <div className="flex items-center justify-between">
            <div className="flex w-full">
              {client?.isLoading ? (
                <>
                  <div className="w-[9.375rem] bg-[#f6f7f8] inline-block h-12 bg-no-repeat skeleton-animation relative"></div>
                  <div className="w-[9.375rem] bg-[#f6f7f8] inline-block h-12 bg-no-repeat skeleton-animation relative ml-3"></div>
                </>
              ) : client && client?.data && client?.data?.length ? (
                <>
                  <DashboardButton title="Add Time Entry" onClick={openModal} />
                  <div className="ml-4">
                    <DashboardButton
                      title="Export as CSV"
                      onClick={openExportModal}
                      variant="secondary"
                    />
                  </div>
                </>
              ) : null}
              {/* {myClient?.isLoading ? (
                <div className="w-[8.375rem] bg-[#f6f7f8] ml-3 inline-block h-12 bg-no-repeat skeleton-animation relative"></div>
              ) : myClient && myClient?.data && myClient?.data?.length ? (
                <button
                  onClick={openProjectModal}
                  className="ml-6 hover:bg-AccentMediumLight text-sm text-center inline-block  text-AccentDark bg-AccentLight font-semibold w-full 5xl:max-w-[180px] max-w-[129px] py-3 rounded focus:outline-0 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out leading-6"
                >
                  <span className="relative z-10 drop-shadow-Texts">
                    Add Project
                  </span>
                </button>
              ) : null} */}
            </div>
          </div>
        </div>
        {trackingProjectList?.isLoading ? (
          <TimeTrackingLoader />
        ) : trackingProjectList?.data?.docs?.length ? (
          <div className="my-8">
            <h5 className="text-Neutral700 text-xs font-semibold mb-7 px-5 lg:px-12 5xl:px-[96px]">
              {trackingProjectList?.data?.totalDocs}{" "}
              {trackingProjectList?.data?.totalDocs === 1 ? "TRACKED PROJECT" : "TRACKED PROJECTS"}
            </h5>
            <div className="flex flex-col relative  overflow-x-scroll h-[calc(75vh-150px)] md:h-full w-full scrollbar-h-4 scrollbar-w-0">
              {trackingProjectList?.data?.docs?.map((item, index) => {
                return (
                  <TimeTrackingTextDetail
                    index={index}
                    item={item}
                    addNewTimeEntry={addNewTimeEntry}
                    setAddNewTimeEntry={setAddNewTimeEntry}
                    key={item?._id || index}
                    setIsOpenModal={setIsOpen}
                    setModalClientDetail={setModalClientDetail}
                  />
                );
              })}

              <div className="px-5 lg:px-12 5xl:px-[96px]  pagination-tracking">
                {trackingProjectList?.data?.docs ? (
                  <Pagination
                    currantPage={trackingProjectList.data.page}
                    totalData={trackingProjectList.data.totalDocs}
                    hasPrevPage={trackingProjectList.data.prevPage}
                    hasNextPage={trackingProjectList.data.nextPage}
                    fromRecord={trackingProjectList?.data?.pagingCounter}
                    outOfCounter1={trackingProjectList?.data?.docs?.length}
                    setPage={setPage}
                  />
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-[80px] flex justify-center">
            <div>
              <img src={noClient} alt="no-client" />
              <div className="text-center">
                <div className="flex justify-center ">
                  <h1 className="font-[900] text-[28px] mt-[32px] text-Neutral900">
                    Start Tracking Your Time
                  </h1>
                </div>
                <div className="flex justify-center mt-[12px]">
                  <p className="text-[14px] w-[295px] text-Neutral800 text-center">
                    Time well managed is good for you and good for clients. Begin the process now.
                  </p>
                </div>
                {myClient && myClient?.data && myClient?.data?.length ? (
                  <div className="mt-[24px] 2xl:pb-0 md:pb-16 pb-10 flex justify-center">
                    <button
                      type="submit"
                      onClick={client?.data?.length ? openModal : openProjectModal}
                      className="w-[295px] relative text-sm text-center block py-4 rounded overflow-hidden border border-Neutral900 relativ focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
                    >
                      <span className="relative text-Neutral900 font-[600] z-10 text-sm">
                        {myClient && myClient?.data && myClient?.data?.length ? (
                          <span>Add New Project</span>
                        ) : (
                          <span>Add Time Entry</span>
                        )}
                      </span>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
      <TrackingAddTimeEntryModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        setAddNewTimeEntry={setAddNewTimeEntry}
        modalClientDetail={modalClientDetail}
      />

      <ExportTimeEntryModal modalIsOpen={exportModalIsOpen} setIsOpen={setExportIsOpen} />

      <TrackingAddProjectModal
        projectModel={projectModel}
        closeProjectModal={closeProjectModal}
        customStyles={customStyles}
        submitHandle={handleSubmit}
        client={myClient}
      />
    </>
  );
};

export default Tracking;
