import React from "react";

function PauseIcon({ width = 24, className = "", color = "#42D6A6" }) {
  return (
    <svg
      className={className}
      width={width}
      height={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4H6V20H10V4Z"
        fill={color}
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 4H14V20H18V4Z"
        fill={color}
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default PauseIcon;
