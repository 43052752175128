import React from "react";
import Modal from "react-modal";
import CloseBtn from "../../../../../assets/Images/close-btn.svg";
import { Link } from "react-router-dom";
import BigShoesAvatar from "../../../../../assets/Images/Big Shoes - Avatar 1.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const PaymentSuccessAlert = ({ modalIsOpen, closeModal }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {window.location.reload(); closeModal(false);}}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
          <div className="flex items-center justify-between border-b border-Neutral200 w-full">
            <span className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2">
              PAYMENT FAILURE
            </span>
          </div>
          <div className="my-6 flex items-center justify-center">
            <svg
              width="152"
              height="109"
              viewBox="0 0 152 109"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M80.3293 108.641C108.811 108.641 143.098 78.658 143.098 49.6557C143.098 20.6533 96.8815 0.850586 68.3994 0.850586C39.9173 0.850586 19.9141 32.5951 19.9141 61.5974C19.9141 90.5997 51.8472 108.641 80.3293 108.641Z"
                fill="#FFF0F3"
              />
              <path
                d="M71.9683 94.288C70.4323 94.288 68.6403 93.296 66.5923 91.312C64.6083 89.264 63.6163 87.568 63.6163 86.224C63.6163 84.88 64.1603 83.568 65.2483 82.288C66.3363 80.944 67.4243 80.272 68.5123 80.272C69.6003 80.272 70.9123 81.84 72.4483 84.976C73.9843 88.048 74.7523 90.352 74.7523 91.888C74.7523 92.336 74.4323 92.848 73.7923 93.424C73.1523 94 72.5443 94.288 71.9683 94.288ZM75.4243 33.136L75.6163 35.632C75.6163 36.4 75.2003 36.976 74.3683 37.36C73.6003 37.68 72.8963 37.84 72.2563 37.84C71.6803 37.84 70.7843 37.104 69.5683 35.632C68.3523 34.16 67.7443 32.912 67.7443 31.888C67.7443 28.368 69.3123 25.232 72.4483 22.48C75.5843 19.728 79.2323 18.352 83.3923 18.352C87.5523 18.352 91.0723 19.664 93.9523 22.288C96.8963 24.912 98.3683 28.464 98.3683 32.944C98.3683 35.568 97.4723 38.32 95.6803 41.2C93.9523 44.08 92.0003 46.576 89.8243 48.688C83.4243 55.024 79.4243 60.08 77.8243 63.856C76.2883 67.632 75.2323 69.776 74.6562 70.288C74.0803 70.8 73.5683 71.056 73.1203 71.056C71.5203 71.056 70.7203 69.008 70.7203 64.912C70.7203 62.288 71.7123 59.472 73.6963 56.464C75.7443 53.456 77.9523 50.832 80.3203 48.592C82.7523 46.288 84.9603 43.76 86.9443 41.008C88.9923 38.192 90.0163 35.728 90.0163 33.616C90.0163 31.504 89.3123 29.84 87.9043 28.624C86.5603 27.344 84.8963 26.704 82.9123 26.704C80.9283 26.704 79.1683 27.312 77.6323 28.528C76.1603 29.744 75.4243 31.28 75.4243 33.136Z"
                fill="#BF4059"
              />
            </svg>
          </div>
          <div className="flex items-center justify-center w-[354px]">
            <span className="text-center text-sm font-normal text-Neutral800">
              There was a problem with your payment. Please close this message
              to try again.
            </span>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => {window.location.reload(); closeModal(false);}}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default PaymentSuccessAlert;
