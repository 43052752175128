import React from 'react';

const TaskPreview = ({
  width = 18,
  className = '',
  color = 'currentColor',
}) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 8H24V14"
          stroke="#EDEDF2"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 24H8V18"
          stroke="#EDEDF2"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24 8L18 14"
          stroke="#EDEDF2"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 24L14 18"
          stroke="#EDEDF2"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="0.5"
          y="0.5"
          width="31"
          height="31"
          rx="15.5"
          stroke="#DFDFE6"
        />
      </svg>
    </>
  );
};

export default TaskPreview;
