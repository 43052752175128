import React from "react";

function ThreeDotsIcon({ color = "#9C9CA6", width = 24, className = "" }) {
  return (
    <svg
      className={className}
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9287 12.499C12.2049 12.499 12.4287 12.2752 12.4287 11.999C12.4287 11.7229 12.2049 11.499 11.9287 11.499C11.6526 11.499 11.4287 11.7229 11.4287 11.999C11.4287 12.2752 11.6526 12.499 11.9287 12.499Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9287 8.99902C12.2049 8.99902 12.4287 8.77517 12.4287 8.49902C12.4287 8.22288 12.2049 7.99902 11.9287 7.99902C11.6526 7.99902 11.4287 8.22288 11.4287 8.49902C11.4287 8.77517 11.6526 8.99902 11.9287 8.99902Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9287 15.999C12.2049 15.999 12.4287 15.7752 12.4287 15.499C12.4287 15.2229 12.2049 14.999 11.9287 14.999C11.6526 14.999 11.4287 15.2229 11.4287 15.499C11.4287 15.7752 11.6526 15.999 11.9287 15.999Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ThreeDotsIcon;
