import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetCollabUserAction } from "../store/actions/collabAuthAction";
import { Navigate } from "react-router-dom";
import PageLoader from "../components/Loaders/PageLoader";

const CollabProtectedRoute = ({ children }) => {
    const dispatch = useDispatch();
    const have_token = localStorage.getItem("jwt_access_token");
    const auth = useSelector((state) => state.collab_auth);

    useEffect(() => {
        if (have_token && !auth?.user) {
            dispatch(GetCollabUserAction());
        }
    }, [dispatch]);

    if (!have_token) {
        return <Navigate to="/client-collab/signin" replace />;
    }

    if (auth?.userLoading && !auth?.user) {
        return <PageLoader />;
    }

    return !auth?.userLoading || have_token ? children : <PageLoader />;
};

export default CollabProtectedRoute;