import React from "react";

function AddClientIcon({ width = 24, color = "#6D6DF2", className = "" }) {
  return (
    <svg
      className={className}
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0909 19.727V18.0906C16.0909 17.2226 15.7461 16.3902 15.1323 15.7764C14.5186 15.1627 13.6862 14.8179 12.8182 14.8179H6.27273C5.40475 14.8179 4.57232 15.1627 3.95856 15.7764C3.3448 16.3902 3 17.2226 3 18.0906V19.727"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.54543 11.5452C11.3529 11.5452 12.8182 10.08 12.8182 8.27248C12.8182 6.46501 11.3529 4.99976 9.54543 4.99976C7.73795 4.99976 6.27271 6.46501 6.27271 8.27248C6.27271 10.08 7.73795 11.5452 9.54543 11.5452Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 19.727V18.0907C20.9994 17.3655 20.7581 16.6611 20.3138 16.088C19.8695 15.5149 19.2475 15.1056 18.5454 14.9243"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2727 5.10596C15.9767 5.2862 16.6006 5.69562 17.0462 6.26967C17.4918 6.84371 17.7337 7.54973 17.7337 8.27641C17.7337 9.0031 17.4918 9.70911 17.0462 10.2832C16.6006 10.8572 15.9767 11.2666 15.2727 11.4469"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AddClientIcon;
