import React from "react";

function InvoicesIcon({
  color = "Neutral800",
  width = 24,
  className = "text-[#5A5A66]",
}) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2222 4H5.77778C4.79594 4 4 4.79594 4 5.77778V18.2222C4 19.2041 4.79594 20 5.77778 20H18.2222C19.2041 20 20 19.2041 20 18.2222V5.77778C20 4.79594 19.2041 4 18.2222 4Z"
        stroke="#5A5A66"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 7V17"
        stroke="#5A5A66"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.7779 8.10999H10.6112C10.0955 8.10999 9.60092 8.31485 9.23626 8.6795C8.87161 9.04415 8.66675 9.53873 8.66675 10.0544C8.66675 10.5701 8.87161 11.0647 9.23626 11.4294C9.60092 11.794 10.0955 11.9989 10.6112 11.9989H13.389C13.9047 11.9989 14.3992 12.2037 14.7639 12.5684C15.1286 12.933 15.3334 13.4276 15.3334 13.9433C15.3334 14.459 15.1286 14.9536 14.7639 15.3182C14.3992 15.6829 13.9047 15.8878 13.389 15.8878H8.66675"
        stroke="#5A5A66"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default InvoicesIcon;
