import React from "react";
import clx from "classnames";

function TeamMemberAvatar({
  user = null,
  cropped = false,
  size = 48,
  className = "",
}) {
  if (!user?.profile) {
    const initials = `${user?.user_name[0]}`;
    // const initials = `${user?.first_name?.[0]}${user?.last_name?.[0]}`;
    return (
      <div
        className={clx(
          `w-[${size}px] h-[${size}px] rounded-[50%] border-[0.5px] flex justify-center items-center bg-Neutral200 `,
          cropped ? "cropped-avatar" : "",
          className
        )}
      >
        <span
          className={`block text-${
            size === 24 ? "xs" : "base"
          } font-semibold text-Neutral800`}
          title={`${user?.user_name}`}
          // title={`${user?.first_name} ${user?.last_name}`}
        >
          {initials}
        </span>
      </div>
    );
  }

  return (
    <img
      src={user?.profile_path}
      alt={`${user?.user_name} avatar`}
      // alt={`${user?.first_name} ${user?.last_name} avatar`}
      width={size}
      height={size}
      // title={`${user?.first_name} ${user?.last_name}`}
      title={`${user?.user_name}`}
      className={clx(
        "rounded-[50%] border-[0.5px] border-Neutral000",
        cropped ? "cropped-avatar" : "",
        className
      )}
    />
  );
}

export default TeamMemberAvatar;
