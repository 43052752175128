import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTimeTrackingAndDashboardCountAction } from "../../store/actions/dashboard-v2";
import Spinner from "../Spinner";
import TimeTrackItemBar from "./TimeTrackItemBar";
import ChevronDown from "./icons/ChevronDown";
import DetailedTrackedTimeModal from "./modals/DetailedTrackedTimeModal";

const filterOptionsMap = {
  all: "All Time",
  week: "This Week",
  month: "This Month",
};

function TrackedTime() {
  const dispatch = useDispatch();
  const trackedTimeAndDashboardCount = useSelector(
    (state) => state.dashboard.timeTrackingAndDashboardCount
  );

  const [trackedTimeFilter, setTrackedTimeFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [isTrackedTimeModalOpen, setIsTrackedTimeModalOpen] = useState(false);

  useEffect(() => {
    dispatch(
      getTimeTrackingAndDashboardCountAction(trackedTimeFilter, { setLoading })
    );
  }, [dispatch, trackedTimeFilter]);

  const data = trackedTimeAndDashboardCount.data || [];
  const maxTime = Math.max(...data.map((item) => item.total_hours), 1);

  return (
    <div className="flex flex-col gap-5 p-4 border rounded-2xl bg-Neutral100 border-Neutral200">
      {/* Chart Header */}
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-medium md:text-base text-Neutral900">
          Tracked Time (in Hours)
        </h3>
        <Menu as="div" className="relative">
          <Menu.Button className="inline-flex items-center gap-1 py-1.5 pl-3 pr-2 text-xs md:text-sm font-medium border-Blue200 rounded-3xl text-Blue800 border w-full justify-end">
            {filterOptionsMap[trackedTimeFilter]}
            {loading ? (
              <Spinner
                size={16}
                className="ml-1.5"
                spinnerBg="#CEDEFD"
                thumbColor="#406ABF"
              />
            ) : (
              <ChevronDown className="text-Blue800 size-4 md:size-5" />
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              as="div"
              className="absolute z-20 w-full p-1 mt-1 border divide-y rounded-md shadow-xl min-w-fit bg-Neutral000 border-Neutral300 divide-Blue100"
            >
              <Menu.Item
                as="button"
                type="button"
                className="w-full px-2 py-1.5 text-sm text-left rounded text-Neutral900 hover:bg-Blue100"
                onClick={() => setTrackedTimeFilter("all")}
              >
                All Time
              </Menu.Item>
              <Menu.Item
                as="button"
                type="button"
                className="w-full px-2 py-1.5 text-sm text-left rounded text-Neutral900 hover:bg-Blue100"
                onClick={() => setTrackedTimeFilter("week")}
              >
                This Week
              </Menu.Item>
              <Menu.Item
                as="button"
                type="button"
                className="w-full px-2 py-1.5 text-sm text-left rounded text-Neutral900 hover:bg-Blue100"
                onClick={() => setTrackedTimeFilter("month")}
              >
                This Month
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      {/* Chart Body */}
      <div className="flex flex-col gap-4">
        {data.slice(0, 5).map((item, index) => (
          <TimeTrackItemBar
            key={item?.member}
            item={item}
            index={index}
            maxTime={maxTime}
          />
        ))}
      </div>
      <button
        className="inline-flex items-center gap-1 px-3 py-1 ml-auto text-xs font-medium border md:px-4 md:text-sm border-Blue200 rounded-3xl text-Blue800"
        onClick={() => setIsTrackedTimeModalOpen(true)}
      >
        View All
      </button>

      <DetailedTrackedTimeModal
        isOpen={isTrackedTimeModalOpen}
        setOpen={setIsTrackedTimeModalOpen}
        data={data}
        filter={trackedTimeFilter}
        setFilter={setTrackedTimeFilter}
        loading={loading}
      />
    </div>
  );
}

export default TrackedTime;
