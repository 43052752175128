import { Field, ErrorMessage } from "formik";
import { useFormikContext } from "formik";
import { StripeIcon } from "./StripeIcon";
import { PaypalIcon } from "./PaypalIcon";
import { BankIcon } from "./BankIcon";
import ReactTooltip from "react-tooltip";
import ButtonLoader from "../Loaders/ButtonLoader";
import Project_Arrow from "../../assets/Images/Project_Arrow.svg";

const Icons = {
  Bank: <BankIcon />,
  Paypal: <PaypalIcon />,
  Stripe: <StripeIcon />,
};

function Step5({
  active,
  onPrevious,
  complete,
  setStep,
  values,
  isSubmitting,
  handlePaypal,
  handleStripe,
  invoice,
  user,
  loadingPaymentLink,
  setIsCreateInvoice,
}) {
  const { setFieldValue } = useFormikContext();
  return (
    <div
      className={`bg-neutral-50 p-4 rounded-lg w-full step flex ${
        active ? " flex-col items-start justify-start" : "h-14 items-center"
      }
     `}
    >
      <div
        className={`flex-col justify-center items-start gap-0.5 flex w-full ${
          !active && "cursor-pointer"
        }`}
        onClick={() => !active && setStep(5)}
      >
        <span className="text-xs font-bold tracking-wide flex gap-6 w-full items-center">
          <span
            className={`min-w-[40px] ${
              complete ? "text-emerald-400" : "text-indigo-500"
            } `}
          >
            STEP 5
          </span>
          {!active && (
            <span className="w-full flex justify-between items-center">
              <span className="text-zinc-500 text-xs font-bold">
                Payment Information
              </span>
              {!active && complete && (
                <span className="text-xs font-bold tracking-wide">
                  <span
                    className={`w-12 ${
                      complete ? "text-emerald-400" : "text-indigo-500"
                    }`}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="22" height="22" rx="11" fill="#42D6A6" />
                      <path
                        d="M16.6477 7.40332L9.20657 14.3613L5.82422 11.1986"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </span>
              )}
            </span>
          )}
        </span>
        <div
          className={`justify-start items-center gap-3 flex ${
            !active && "hidden"
          }`}
        >
          <div className="text-zinc-800 text-base font-bold tracking-wide">
            Payment Information
          </div>
        </div>
      </div>
      <div
        className={`justify-start items-start gap-6 flex flex-col mt-5 w-full ${
          !active && "hidden"
        }`}
      >
        <div className="justify-start items-start gap-6 flex flex-col w-full">
          {/* DISCOUNT */}
          <div className="w-full">
            <label
              htmlFor="discount"
              className="block mb-2 text-zinc-800 text-xs font-bold"
            >
              Discount %{" "}
              <span className="text-xs text-gray-400">(Optional)</span>
            </label>
            <Field
              name="discount"
              type="number"
              id="discount"
              className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-full md:w-80"
              placeholder="Enter percent discount"
              min={0}
              max={100}
              value={values.discount}
              onChange={(e) => {
                let newValue = e.target.value;
                if (newValue > 100) {
                  newValue = 100;
                } else if (newValue < 0) {
                  newValue = 0;
                }
                setFieldValue("discount", newValue);
              }}
            />
          </div>
          {/* DISCOUNT */}

          {/* PAYMENT METHOD */}

          <div className="flex flex-col w-full">
            <label htmlFor="paymentMethod" className="text-xs font-bold mb-2">
              Payment Method
            </label>
            <div className="flex gap-2.5 items-center py-2.5">
              <div className="flex flex-col items-center border rounded-lg w-full md:w-80 p-2.5 bg-white">
                <button
                  id="optionsButton"
                  data-dropdown-toggle="options"
                  className="flex items-center justify-between w-full text-gray-900 text-sm bg-transparent border-none outline-none"
                  type="button"
                  onClick={() => {
                    const options = document.getElementById("options");
                    options.classList.toggle("hidden");
                  }}
                >
                  <span className="sr-only">Open Payment Methods</span>
                  {/* <BankIcon /> */}
                  {Icons[values.paymentMethod]}
                  <svg
                    className="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                {/* <input name="discount" type="number" id="discount" class="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-80" placeholder="Enter percent discount" min="0" max="100" value="0"> */}
                <div
                  id="options"
                  className="z-10 hidden bg-white rounded-lg w-80 text-zinc-800 border-l border-r py-2.5"
                >
                  <ul
                    className="overflow-auto text-sm font-medium"
                    aria-labelledby="dropdownInformOptionsButton"
                  >
                    <li>
                      <button
                        type="button"
                        className="p-2 hover:bg-gray-100 w-full text-left flex items-center gap-2"
                        onClick={() => {
                          setFieldValue("paymentMethod", "Bank");
                          // setFieldValue("paymentLink", null);
                          document
                            .getElementById("options")
                            .classList.add("hidden");
                        }}
                      >
                        <BankIcon />
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="p-2 hover:bg-gray-100 w-full text-left flex items-center gap-2"
                        onClick={() => {
                          setFieldValue("paymentMethod", "Stripe");
                          setFieldValue("paymentLink", values.paymentLink);
                          setFieldValue("bankDetails", "");
                          document
                            .getElementById("options")
                            .classList.add("hidden");
                        }}
                      >
                        <StripeIcon />
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="p-2 hover:bg-gray-100 w-full text-left flex items-center gap-2"
                        onClick={() => {
                          setFieldValue("paymentMethod", "Paypal");
                          setFieldValue("paymentLink", values.paymentLink);
                          setFieldValue("bankDetails", "");
                          document
                            .getElementById("options")
                            .classList.add("hidden");
                        }}
                      >
                        <PaypalIcon />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              {/* {["Paypal", "Stripe"].includes(values.paymentMethod) && (
                <a
                  href={`https://www.${values.paymentMethod?.toLowerCase()}.com`}
                  target="_blank"
                  rel="noreferrer"
                  data-for="stripe"
                  data-tip={values.paymentMethod === 'Paypal' ? 'Integrate with paypal for automatic invoice creation or manually add a invice URL here' : `Go to the ${values.paymentMethod} website and create a special link for the amount of the entire invoice`}
                >
                  <QuestionMarkIcon />
                  <ReactTooltip
                    place="bottom"
                    textColor="#F5F5F7"
                    className="tooltip-font-size"
                    backgroundColor="#292933"
                    id="stripe"
                  />
                </a>
              )} */}
            </div>
            {/* {values?.paymentMethod !== "Bank" && (
              <div
                className="mt-1 text-neutral-500 text-xs font-medium w-80"
                id="paymentMethodDescription"
              > */}
            {/* Payment Link will be created automatic with invoice creation Or manually create a payment link from the paypal website and add here. */}
            {/*Integrate with paypal for automatic payment link creation or manually create a payment link from the paypal website and add here.*/}
            {/* The invoice will show a{" "}
                {values.paymentMethod === "Stripe" ? "Stripe" : "Paypal"}{" "}
                payment link for your client to pay from our site. */}
            {/* </div>
            )} */}
            <ErrorMessage name="paymentMethod">
              {(msg) => <div className="mt-2 text-sm text-red-600">{msg}</div>}
            </ErrorMessage>
          </div>

          {/* PAYMENT METHOD */}
          {/* BANK DETAILS */}
          <div
            className={`flex flex-col w-full ${
              values.paymentMethod === "Bank" ? "" : "hidden"
            }`}
          >
            <label
              htmlFor="bankDetails"
              className="block mb-2 text-zinc-800 text-xs font-bold"
            >
              Bank Details
            </label>
            <Field
              name="bankDetails"
              as="textarea"
              id="bankDetails"
              className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-full md:w-80 h-24"
              placeholder="Enter all bank details your client will need to complete the payment..."
              maxLength={1000}
            />
            {/* bottom right */}
            <div className="flex justify-end items-end text-xs text-gray-400">
              <span className="text-gray-500">
                {values.bankDetails?.length}/1000
              </span>
            </div>
            <ErrorMessage name="bankDetails">
              {(msg) => <div className="mt-2 text-sm text-red-600">{msg}</div>}
            </ErrorMessage>
          </div>
          {/* BANK DETAILS */}

          {/* LINK */}
          <div
            className={`flex  gap-6 ${
              values.paymentMethod === "Bank" && "hidden"
            }`}
          >
            <div className="items-center gap-6">
              <div
                className={`${
                  (!user?.payPalInfo && values.paymentMethod === "Paypal") ||
                  (!user?.stripeInfo && values.paymentMethod === "Stripe") ||
                  (values.paymentLink &&
                    ((values.paymentMethod === "Paypal" &&
                      values?.paymentLink?.includes("paypal")) ||
                      (values.paymentMethod === "Stripe" &&
                        values?.paymentLink?.includes("stripe"))))
                    ? "block"
                    : "hidden"
                }`}
              >
                <label
                  htmlFor="paymentLink"
                  className="block mb-2 text-zinc-800 text-xs font-bold"
                >
                  Payment Link
                </label>
                <Field
                  name="paymentLink"
                  type="text"
                  value={values.paymentLink}
                  disabled={
                    (values.paymentMethod === "Paypal" &&
                      values?.paymentLink?.includes("paypal")) ||
                    (values.paymentMethod === "Stripe" &&
                      values?.paymentLink?.includes("stripe"))
                  }
                  id="paymentLink"
                  className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-80"
                  placeholder="Generate Payment Link"
                />
                <ErrorMessage name="paymentLink">
                  {(msg) => (
                    <div className="mt-2 text-sm text-red-600">{msg}</div>
                  )}
                </ErrorMessage>
              </div>
              <div>
                {user?.payPalInfo &&
                  values.paymentMethod === "Paypal" &&
                  !invoice?.paymentLink?.includes("paypal") && (
                    <button
                      disabled={loadingPaymentLink}
                      className="self-end px-6 my-2 py-3 h-[42px] w-full rounded grow text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out text-AccentRegular border border-indigo-400 bg-white disabled:bg-neutral-300"
                      type="button"
                      onClick={() => handlePaypal()}
                    >
                      <p className="mx-1">
                        {loadingPaymentLink
                          ? "Generating..."
                          : "Generate Payment Link"}
                      </p>
                    </button>
                  )}
                {user?.stripeInfo &&
                  values.paymentMethod === "Stripe" &&
                  !invoice?.paymentLink?.includes("stripe") && (
                    <button
                      disabled={loadingPaymentLink}
                      className="self-end px-6 my-2 py-3 h-[42px] w-full rounded grow text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out text-AccentRegular border border-indigo-400 bg-white disabled:bg-neutral-300"
                      type="button"
                      onClick={() => handleStripe()}
                    >
                      <p className="mx-1">
                        {loadingPaymentLink
                          ? "Generating..."
                          : "Generate Payment Link"}
                      </p>
                    </button>
                  )}
              </div>
            </div>
            {!user?.payPalInfo && values.paymentMethod === "Paypal" && (
              <div className="flex bg-Neutral200 gap-5 p-2 items-center">
                <img className="w-12 h-12" src={Project_Arrow} alt="" />
                <p className="text-[12px] text-neutral-800 w-[450px]">
                  Manually create a payment link from the paypal website and add
                  here OR
                  <a
                    className="text-indigo-500"
                    target="_blank"
                    href={`${process.env.REACT_APP_PAYPAL_BASE_URL}/signin/authorize?flowEntry=static&client_id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&response_type=code&scope=openid profile&redirect_uri=${process.env.REACT_APP_PUBLIC_BASE_URL}`}
                  >
                    {" "}
                    Integrate with paypal for automatic payment link creation.
                  </a>
                </p>
              </div>
            )}
            {!user?.stripeInfo && values.paymentMethod === "Stripe" && (
              <div className="flex bg-Neutral200 gap-5 p-2 items-center">
                <img className="w-12 h-12" src={Project_Arrow} alt="" />
                <p className="text-[12px] text-neutral-800 w-[450px]">
                  Manually create a payment link from the stripe website and add
                  here OR
                  <a
                    className="text-indigo-500"
                    target="_blank"
                    href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${
                      process.env.REACT_APP_PUBLIC_STRIPE_OAUTH_CLIENT_ID
                    }&scope=read_write&state=${
                      Math.random() * 100
                    }&return_url=${process.env.REACT_APP_PUBLIC_BASE_URL}`}
                  >
                    {" "}
                    Integrate with stripe for automatic payment link creation.
                  </a>
                </p>
              </div>
            )}
            {/* {values.paymentMethod === "Stripe" && (
              <div className="flex bg-Neutral200 gap-5 p-2 items-center">
                <img className="w-12 h-12" src={Project_Arrow} alt="" />
                <p className="text-[12px] text-neutral-800 w-[450px]">
                  Go to the {values.paymentMethod} website and create a special
                  link for the amount of the entire invoice
                </p>
              </div>
            )} */}
          </div>
          {/* LINK */}
        </div>
        {/*  */}

        <div className="justify-start items-start gap-6 flex flex-col md:flex-row w-full">
          <button
            className={`w-full md:w-80 h-12 bg-indigo-500 text-neutral-50 rounded flex-col justify-center items-center gap-2 flex hover:bg-indigo-600`}
            type="submit"
            onClick={setIsCreateInvoice}
            disabled={isSubmitting}
          >
            <div className="text-center text-neutral-50 text-sm font-bold leading-none w-full">
              {isSubmitting ? <ButtonLoader /> : "Create Invoice"}
            </div>
          </button>
          <button
            type="button"
            className="px-6 h-12 bg-gray-100 rounded flex-col justify-center items-center gap-2 flex hover:bg-gray-200"
            onClick={onPrevious}
          >
            <div className="text-center text-indigo-500 text-sm font-bold leading-none">
              Previous
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Step5;

const QuestionMarkIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.3288 26.5078C22.3288 25.9652 22.3801 25.4958 22.4828 25.0998C22.6001 24.7038 22.7981 24.3298 23.0768 23.9778C23.3701 23.6112 23.7514 23.2372 24.2208 22.8558C24.7634 22.4158 25.1814 22.0492 25.4748 21.7558C25.7828 21.4478 25.9954 21.1545 26.1128 20.8758C26.2448 20.5825 26.3108 20.2305 26.3108 19.8198C26.3108 19.1892 26.0981 18.7052 25.6728 18.3678C25.2621 18.0305 24.6608 17.8618 23.8688 17.8618C23.1794 17.8618 22.5488 17.9572 21.9768 18.1478C21.4048 18.3238 20.8474 18.5438 20.3048 18.8078L19.4688 17.0478C20.0994 16.7105 20.7888 16.4318 21.5368 16.2118C22.2848 15.9918 23.1134 15.8818 24.0228 15.8818C25.4601 15.8818 26.5674 16.2338 27.3448 16.9378C28.1368 17.6418 28.5328 18.5805 28.5328 19.7538C28.5328 20.3845 28.4301 20.9345 28.2248 21.4038C28.0194 21.8585 27.7188 22.2765 27.3228 22.6578C26.9414 23.0392 26.4868 23.4498 25.9588 23.8898C25.4894 24.2712 25.1301 24.6012 24.8808 24.8798C24.6314 25.1585 24.4627 25.4372 24.3748 25.7158C24.2868 25.9798 24.2428 26.3098 24.2428 26.7058V27.1458H22.3288V26.5078ZM21.8668 30.5118C21.8668 29.9398 22.0061 29.5365 22.2848 29.3018C22.5781 29.0672 22.9448 28.9498 23.3848 28.9498C23.7954 28.9498 24.1474 29.0672 24.4408 29.3018C24.7341 29.5365 24.8808 29.9398 24.8808 30.5118C24.8808 31.0838 24.7341 31.4945 24.4408 31.7438C24.1474 31.9932 23.7954 32.1178 23.3848 32.1178C22.9448 32.1178 22.5781 31.9932 22.2848 31.7438C22.0061 31.4945 21.8668 31.0838 21.8668 30.5118Z"
      fill="url(#paint0_linear_7550_91357)"
    />
    <rect
      x="0.75"
      y="0.75"
      width="46.5"
      height="46.5"
      rx="23.25"
      stroke="#EDEDF2"
      strokeWidth="1.5"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7550_91357"
        x1="19.4687"
        y1="15.8818"
        x2="33.6689"
        y2="24.241"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6D6DF2" stopOpacity="0.8" />
        <stop offset="1" stopColor="#6D6DF2" />
      </linearGradient>
    </defs>
  </svg>
);
