import axios, { AxiosError } from "axios";
import { useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import CloseBtn from "../../assets/Images/close-btn.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
    overflow: "hidden",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: 0,
    background: "none",
    borderRadius: "10px",
    overflow: "visible",
  },
};

function ConfirmClientPortalActivationModal({ isOpen, setOpen, clientDetails }) {
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const handleClose = () => {
    setOpen(false);
  };

  const handleActivateClientPortal = async (e) => {
    setIsLoading(true);
    try {
      const clientInvitePayload = {
        client_id: clientDetails?._id,
        email: clientDetails?.email,
        status: true,
      };
      await axios.post(`/api/v1/invited-client-portal/invite-client-user`, [clientInvitePayload]);
      // handleClose(); // Close modal after successful activation of the portal
      // Redirect to the client portal dashboard - /user/<user_ID>/<client_ID>/home
      window.location.href = `https://portal.clientmanager.io/user/${user?._id}/${clientDetails?._id}/home`;
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err.response.data);
        toast.error(err.response.data?.message);
      } else {
        console.log(err);
        toast.error(err?.message);
      }
    } finally {
      // setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      closeTimeoutMS={300}
      style={customStyles}
      contentLabel="Client Portal"
    >
      <div className="relative rounded-lg sm:w-full animate__fadeInUp shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] md:max-w-md">
        <div className="px-8 py-8 overflow-hidden rounded-lg bg-Neutral000">
          <h3 className="font-medium text-Neutral800">
            Are you sure you want to activate client portal for{" "}
            <strong>{clientDetails?.client_name}</strong>?
          </h3>
          <ol className="py-5 my-5 text-sm text-Neutral700 border-y border-Neutral300">
            <li className="pb-2">
              1. Client will receive activation email, and can activate their account from URL
              present in email for client portal.
            </li>
            <li className="py-2">
              2. You will be redirected to portal login page, you can use same login as{" "}
              <strong className="font-medium text-Neutral900">Client Manager</strong>, and then view
              dashboards for all the activated clients portal.
            </li>
          </ol>
          <button
            className={`w-full py-2.5 font-semibold hover:bg-AccentMediumDark text-sm text-center rounded-md text-Neutral000 ${
              isLoading ? "bg-AccentMediumLight" : "bg-AccentRegular"
            }`}
            onClick={handleActivateClientPortal}
            disabled={isLoading}
          >
            {isLoading
              ? "You're being redirected to client portal shortly..."
              : "Activate Client Portal"}
          </button>
        </div>
        {/* Close modal button */}
        <button className="absolute -right-1 top-[-50px] z-30" onClick={() => handleClose()}>
          <img src={CloseBtn} alt="Close modal button" />
        </button>
      </div>
    </Modal>
  );
}

export default ConfirmClientPortalActivationModal;
