import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef } from "react";

export const ActionMenu = ({ actions }) => {
  const [open, setOpen] = React.useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <Menu as="div" className="relative inline-block text-left h-6" ref={ref}>
      <Menu.Button
        className="focus-visible:outline-none popup-btn relative w-full"
        onClick={() => setOpen(!open)}
      >
        <div className="text-center client-btn-hover cursor-pointer">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.9287 12.4985C12.2049 12.4985 12.4287 12.2747 12.4287 11.9985C12.4287 11.7224 12.2049 11.4985 11.9287 11.4985C11.6526 11.4985 11.4287 11.7224 11.4287 11.9985C11.4287 12.2747 11.6526 12.4985 11.9287 12.4985Z"
              fill="#9C9CA6"
              stroke="#9C9CA6"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9287 8.99878C12.2049 8.99878 12.4287 8.77492 12.4287 8.49878C12.4287 8.22264 12.2049 7.99878 11.9287 7.99878C11.6526 7.99878 11.4287 8.22264 11.4287 8.49878C11.4287 8.77492 11.6526 8.99878 11.9287 8.99878Z"
              fill="#9C9CA6"
              stroke="#9C9CA6"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9287 15.9985C12.2049 15.9985 12.4287 15.7747 12.4287 15.4985C12.4287 15.2224 12.2049 14.9985 11.9287 14.9985C11.6526 14.9985 11.4287 15.2224 11.4287 15.4985C11.4287 15.7747 11.6526 15.9985 11.9287 15.9985Z"
              fill="#9C9CA6"
              stroke="#9C9CA6"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </Menu.Button>
      <Transition
        show={open}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right z-20 absolute left-[10px] w-[108px] rounded-lg client-edite-view-shadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none top-0 cursor-pointer transition-all duration-300 ease-in-out flex flex-col">
          {actions.map((action, i) => (
            <Menu.Item>
              {(props) => {
                return (
                  <>
                    <p
                      className="flex px-4 hover:bg-Neutral200 py-2"
                      onClick={() => {
                        action.onClick();
                        setOpen?.(false);
                      }}
                    >
                      <img src={action.icon} alt="Icon" />

                      <span className="px-2 text-xs text-Neutral900">
                        {action.label}
                      </span>
                    </p>

                    {i >= 1 && i !== actions.length && (
                      <hr className="h-[0.5px] bg-Neutral200 " />
                    )}
                  </>
                );
              }}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
