import axios from "axios";
import { toast } from "react-toastify";

export const CollabLoginAction = (
  { email, password },
  setSubmitting,
  setErrors
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/client-user/login", {
        email,
        password,
      });
      if (data?.token) {
        await localStorage.setItem("jwt_access_token", data?.token);
        axios.defaults.headers["Authorization"] = `${data?.token}`;
      }
      setSubmitting(false);
      return dispatch({
        type: "COLLABLOGIN",
        payload: {
          isLogin: true,
          token: data?.token,
          user: data?.data,
          userLoading: false,
        },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
      } else if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "COLLAB_LOGIN_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        return dispatch({
          type: "COLLAB_LOGIN_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetCollabUserAction = () => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get("api/v1/client-user/get-profile");
      return dispatch({
        type: "COLLAB_SET_USER_DATA",
        payload: {
          data: data.data,
        },
      });
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        toast.error(error?.response?.data?.Data);
        dispatch(CollabLogoutAction());
        return dispatch({
          type: "GET_COLLAB_USER_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "GET_COLLAB_USER_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const CollabLogoutAction = () => {
  localStorage.removeItem("jwt_access_token");
  return {
    type: "COLLAB_LOGOUT",
  };
};

export const CollabVerifyLicense = (
  { uuid },
  setCollabVerification,
  navigate
) => {
  return async () => {
    try {
      let { data } = await axios.get(
        `api/v1/project-member/verify-invitation-token?token=${uuid}`
      );
      setCollabVerification(true);
      return data?.data;
    } catch (error) {
      if (error?.response?.status === 422) {
        let err = [];
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        navigate(`/client-collab/signin`);
      } else if (error?.response?.status === 401) {
        return toast.error(error?.response?.data?.message);
      } else {
        return toast.error(error);
      }
    }
  };
};

export const signUpCollab = async (value, navigate) => {
  try {
    let { data } = await axios.post("api/v1/client-user/signup", value);
    navigate(`/client-collab/signin`);
    return { data: data.data, error: null };
  } catch (error) {
    if (error.response.status === 422) {
      let err = [];
      error.response.data.errors.forEach((element) => {
        err.push(element.msg);
      });
      // toast.error(err);
      return { data: null, error: err };
    } else if (error.response.status === 401) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error);
    }
  }
};

export const UpdateCollabProfileAction = (values, setSubmitting, setErrors) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/client-user/update-profile",
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(data?.message);
      setSubmitting(false);
      dispatch({
        type: "COLLAB_SET_USER_DATA",
        payload: {
          data: data.data,
        },
      });
      return dispatch({
        type: "COLLAB_PROFILE_UPDATE",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
      } else {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "COLLAB_PROFILE_UPDATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetCollabEmailExistanceAction = async (email) => {
  try {
    let { data } = await axios.get(
      `api/v1/user/check-email-existance?email=${email}`
    );
    return data.data;
  } catch (error) {
    if (error.response.status === 422) {
      // toast.error(error?.response?.data?.message);
      return;
    } else {
      toast.error("Something went wrong while validating email");
      return;
    }
  }
};

export const ForgetCollabPasswordAction = (
  { email },
  setSubmitting,
  sethaveResponse,
  setResponseText
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/client-user/forgot-password?email=${email}`
      );
      // toast.success(data?.data);
      // navigate("/signin")
      if (data.success) {
        sethaveResponse(true);
        setResponseText(data.message);
      } else {
        sethaveResponse(false);
        setResponseText("Oops. Something went wrong. Please try again.");
      }
      setSubmitting(false);
      return dispatch({
        type: "COLLAB_FORGET_PASSWORD_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        sethaveResponse(false);
        setResponseText("Looks like email is not registered with us.");
        setSubmitting(false);
      } else {
        sethaveResponse(false);
        setResponseText("Oops. Something went wrong. Please try again.");
        setSubmitting(false);
      }
    }
  };
};

export const ResetCollabPasswordAction = (
  { token, password, confirm_password },
  setSubmitting,
  setErrors,
  navigate
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/client-user/forgot-reset-password",
        {
          token,
          password,
          confirm_password,
        }
      );
      toast.success(data?.message);
      setTimeout(() => {
        setSubmitting(false);
        navigate("/client-collab/signin");
      }, 1000);
      return dispatch({
        type: "COLLAB_FORGET_PASSWORD_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
      } else if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "SIGNUP_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        return dispatch({
          type: "SIGNUP_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateCollabPasswordAction = (
  values,
  setSubmitting,
  setErrors,
  resetForm
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/client-user/update-password",
        values
      );
      toast.success(data?.message);
      setSubmitting(false);
      resetForm({
        old_password: "",
        new_password: "",
        confirm_new_password: "",
      });
      return dispatch({
        type: "COLLAB_PASSWORD_UPDATE_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
      } else {
        toast.error(error?.response?.data?.message);
        setSubmitting(false);
        return dispatch({
          type: "COLLAB_PASSWORD_UPDATE_ERROR",
          payload: error,
        });
      }
    }
  };
};
