import { Menu, Transition } from "@headlessui/react";
import axios from "axios";
import clx from "classnames";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import ThreeDots from "../../assets/Images/three-dots.svg";
import {
  ChangeClientStatusAction,
  GetClientStatusList,
} from "../../store/actions/clientAction";

const colors = ["Green", "Pink", "Blue", "Orange", "Neutral"];

const colorCodition = (index) => {
  if (index < 5) {
    return colors[index];
  } else {
    return colors[index % colors.length];
  }
};

const generateColorObject = (color) => ({
  bg: `bg-${color}400`,
  bgActive: `bg-${color}200`,
  text: `hover:text-${color}800`,
  textactive: `text-${color}800`,
  statehover: `hover:text-${color}200`,
});

function Status({ id, status, item }) {
  const dispatch = useDispatch();

  const statusList = useSelector((state) => state.client.clientStatusList);

  const [customStatus, setCustomStatus] = useState("");
  const [statusButton, setStatusButton] = useState(false);
  const [selctedStatus, setSelectedStatus] = useState(
    statusList.data?.find((e) => e.name === status) || {}
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusChange = (newItem) => {
    setIsLoading(true);
    setSelectedStatus(newItem);

    let oldStatus = selctedStatus;

    const isSuccess = () => {
      setIsLoading(false);
    };

    const haveError = () => {
      setSelectedStatus(oldStatus);
      setIsLoading(false);
    };

    dispatch(
      ChangeClientStatusAction(
        { id, status: newItem._id },
        isSuccess,
        haveError
      )
    );
  };

  const handleCreateStatus = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      // Gaurd for empty value
      if (customStatus.length === 0) return;

      // Gaurd for not creating the existing status
      if (
        statusList.data.some(
          (ele) => ele.status === customStatus.toLowerCase().replace(/\s/g, "_")
        )
      ) {
        toast.warn("Status is already created");
        return;
      }

      try {
        const color = generateColorObject(
          colorCodition(statusList.data.length)
        );

        const { data } = await axios.post(
          "api/v1/client-status/create-client-status",
          {
            name: customStatus,
            color,
          }
        );
        toast.success(data?.message);
        // Select the newly created status after successful creation
        if (data?.data) {
          setSelectedStatus(data.data);
          handleStatusChange(data.data, true);
        }
        // Refetch the data after creating a new status
        dispatch(GetClientStatusList());
      } catch (error) {
        console.log(error);
        toast.error(error.data?.errors[0].msg);
      } finally {
        // Clear the input if there is any text on hitting
        setCustomStatus("");
      }
    }
  };

  const handleDeleteStatus = async (event, statusId) => {
    event.stopPropagation();
    // Delete the client status
    try {
      const { data } = await axios.post(
        "api/v1/client-status/delete-client-status",
        { id: statusId }
      );
      toast.success(data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.errors[0].msg);
    }

    // Revalidate the status state
    dispatch(GetClientStatusList());
  };

  return (
    <>
      <div className="flex items-center text-left whitespace-nowrap w-full max-w-[320px] relative">
        <Menu
          as="div"
          className="relative inline-block w-32 text-left 3xl:w-40"
        >
          <Menu.Button>
            <div
              className={clx(
                "flex items-center transition duration-300",
                statusButton && "opacity-100 w-fit"
              )}
            >
              <span
                className={clx(
                  `text-[10px] 5xl:text-sm py-[12px] min-w-[92px] px-[16px] uppercase font-semibold rounded-[30px] leading-tight`,
                  selctedStatus?.color?.textactive,
                  selctedStatus?.color?.bgActive
                )}
              >
                {selctedStatus?.name?.replace(/_/g, " ")}
              </span>
              {item?.is_admin && (
                <img
                  className="cursor-pointer h-fit"
                  src={ThreeDots}
                  alt=""
                  onClick={() => setStatusButton(true)}
                />
              )}
            </div>
          </Menu.Button>
          <Transition as={Fragment}>
            <Menu.Items className="box shadow-[0px_8px_24px_rgba(0,0,0,0.1)] z-50 absolute top-[35px] bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-[130px] pt-3 rounded-[0px_0px_4px_4px]">
              <div className="bg-white">
                {/* <p className="pb-2 ml-4 mr-4 text-xs font-semibold border-b text-Neutral700 border-Neutral200 ">
              Select Status
            </p> */}
                <input
                  type="text"
                  placeholder="Create Custom"
                  className="font-normal text-[10px] placeholder:text-Neutral600 text-Neutral700 focus:outline-none w-full mb-3 px-3"
                  onKeyUp={(e) => {
                    e.preventDefault();
                  }}
                  // onKeyDown={(e) => handleCreateStatus(e)}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.stopPropagation();
                    } else if (e.key === "Enter") {
                      handleCreateStatus(e);
                    }
                  }}
                  onChange={(e) => setCustomStatus(e.target.value)}
                />
                <div className="px-2 pb-2">
                  {statusList.data.map((item) => (
                    <div
                      key={item._id}
                      className={`transition-all duration-300 uppercase flex items-center justify-between w-full cursor-pointer whitespace-nowrap text-[10px] 5xl:text-sm py-2 font-semibold leading-tight rounded-[2px] text-Neutral800 group
						${item?.color?.text} ${item?.color?.statehover}
						${
              selctedStatus?.name === item?.name
                ? `${selctedStatus?.color?.bgActive} ${selctedStatus?.color?.textactive}`
                : ""
            }
						${
              !selctedStatus && statusList.data.slice(1).length > 0
                ? `${statusList.data[1].color?.bgActive} ${statusList.data[1].color?.textactive}`
                : ""
            }`}
                      onClick={() => {
                        setStatusButton(false);
                        if (item.name) {
                          handleStatusChange(item);
                        }
                      }}
                    >
                      <div className="flex items-center">
                        <span
                          className={`w-2 h-2 rounded-full block mr-2 ml-2 ${item?.color.bg} uppercase `}
                        ></span>
                        {item?.name?.replace(/_/g, " ")}
                      </div>
                      <button
                        className="text-transparent group-hover:text-Red500"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteStatus(e, item._id);
                        }}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.23096 5.04419H4.33181H13.1386"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.98234 5.04469V4.02247C5.98234 3.75136 6.09832 3.49135 6.30477 3.29965C6.51122 3.10794 6.79123 3.00024 7.08319 3.00024H9.2849C9.57687 3.00024 9.85687 3.10794 10.0633 3.29965C10.2698 3.49135 10.3858 3.75136 10.3858 4.02247V5.04469M12.037 5.04469V12.2002C12.037 12.4714 11.9211 12.7314 11.7146 12.9231C11.5082 13.1148 11.2281 13.2225 10.9362 13.2225H5.43191C5.13994 13.2225 4.85994 13.1148 4.65349 12.9231C4.44704 12.7314 4.33105 12.4714 4.33105 12.2002V5.04469H12.037Z"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
}

export default Status;
