import styled from '@emotion/styled';

export const StyleWrapper = styled.div`
  .fc-scrollgrid {
    border: 0px !important;
  }
  .fc-dayGridMonth-view {
    .fc-daygrid-day {
      height: 152px !important;
      max-height: 152px !important;
    }
  }

  .fc-col-header-cell-cushion {
    font-size: 12px !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    --tw-text-opacity: 1;
    font-weight: 600;
    font: bold;
    color: rgb(41 41 51 / var(--tw-text-opacity));
  }
  .fc-daygrid-day-frame {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;

    &:hover {
      .fc-daygrid-day-top {
        .fc-daygrid-day-number {
          .custom-day {
            .add-event-icon {
              height: 18px;
              visibility: visible !important;
              border-width: 1px;
              border-radius: 0.25rem;
              text: #dfdfe5;
            }
          }
        }
      }
    }

    .fc-daygrid-day-top {
      justify-content: end !important;
      .fc-daygrid-day-number {
        --tw-text-opacity: 1;
        color: rgb(41 41 51 / var(--tw-text-opacity));
        font-size: 12px !important;
        width: 100%;

        .custom-day {
          text-align: right;
          width: 100%;
          position: relative;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .fc-day-today {
    .fc-daygrid-day-frame {
      .fc-daygrid-day-top {
        justify-content: start !important;
        align-items: start;
        .fc-daygrid-day-number {
          display: flex;
          align-items: flex-start;
        }
      }
    }
  }

  .fc-day-other {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 247 / var(--tw-bg-opacity));
  }
  .fc-day-today {
    height: 26px;
    background-color: #ffffff !important;
    .fc-daygrid-day-top {
      display: block !important;
      .fc-daygrid-day-number {
        .custom-day {
          span {
            min-height: 24px !important;
            min-width: 24px !important;
            --tw-text-opacity: 1;
            color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
            background-color: rgb(
              109 109 242 / var(--tw-bg-opacity)
            ) !important;
            font-size: 12px !important;
            font-weight: 600 !important;
            border-radius: 9999px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border-color: #f5f5f5;
  }

  .fc .fc-timegrid-axis-cushion,
  .fc .fc-timegrid-slot-label-cushion {
    --tw-text-opacity: 1;
    color: rgb(117 117 128 / var(--tw-text-opacity));
    line-height: 1.25rem;
    font-size: 0.75rem;
    padding-right: 0.5rem;
  }

  .fc .fc-timegrid-slot {
    height: 55px;
  }

  .fc-timegrid-col-misc .custom-day  {
    display: none;
  }

  .fc-v-event {
    --tw-bg-opacity: 1;
    background-color: rgb(240 240 255 / var(--tw-bg-opacity));
    border: none;
    border-radius: 0.5rem;
    line-height: 1.25rem;
    font-size: 0.75rem;
    margin: 0.25rem;
    min-height: 40px;
  }

  .fc-v-event :hover {
    --tw-bg-opacity: 1;
    background-color: rgb(211 211 245 / var(--tw-bg-opacity));
    border-radius: 0.5rem;
  }

  .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
    color: red;
    --tw-bg-opacity: 1;
    background-color: rgb(109 109 242 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 600;
    padding: 0.2rem 0.75rem;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 9999px;
  }

  .fc-h-event {
    --tw-bg-opacity: 1;
    background-color: rgb(240 240 255 / var(--tw-bg-opacity));
    border: none;
    border-radius: 0.5rem;
    line-height: 1.25rem;
    font-size: 0.75rem;
    margin: 0.25rem;
  }

  .fc-daygrid-block-event :hover {
    --tw-bg-opacity: 1;
    background-color: rgb(211 211 245 / var(--tw-bg-opacity));
    border-radius: 0.5rem;
  }

  .fc-event-title {
    --tw-text-opacity: 1;
    color: rgb(102 31 102 / var(--tw-text-opacity));
    font-weight: 600;
    cursor: pointer;
    /* padding: 0.5rem; */
  }

  .fc-daygrid-event-dot {
    --tw-bg-opacity: 1;
    border-color: rgb(211 211 245 / var(--tw-bg-opacity));
  }

  .fc-daygrid-dot-event .fc-event-title {
    color: #292933 !important;
    font-weight: 400 !important;
    font-size: 10px !important;
  }

  .fc-daygrid-dot-event:hover,
  .fc-daygrid-dot-event.fc-event-mirror {
    background: none;
  }

  .fc-more-popover {
    max-height: 200px;
    overflow-y: scroll;
  }

  .fc-timegrid-more-link {
    top: auto !important;
    --tw-bg-opacity: 1;
    background-color: rgb(211 211 245 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    font-weight: 600;
    color: rgb(102 31 102 / var(--tw-text-opacity));
  }

  .fc-daygrid-more-link {
    top: auto !important;
    --tw-bg-opacity: 1;
    background-color: rgb(211 211 245 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    font-weight: 600;
    color: rgb(102 31 102 / var(--tw-text-opacity));
  }

  .fc-daygrid-block-event {
    .fc-event-title {
      padding: 0.5rem;
    }
  }

  .fc-daygrid-dot-event {
    .fc-event-title {
      padding: 0.2rem;
    }
  }

  .fc-popover-header {
    --tw-bg-opacity: 1;
    background-color: rgb(211 211 245 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    font-weight: 600;
    color: rgb(102 31 102 / var(--tw-text-opacity));
  }

  .fc-more-popover {
    .fc-timegrid-event {
      padding: 0.2rem;
    }
  }
`;
