import React, { useEffect, useRef, useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import clx from "classnames";
import CheckMarkIcon from "../../Icons/CheckMarkIcon";
import ChevronDownIcon from "../../Icons/ChevronDownIcon";
import data from "../../../mock/leads.json";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
	updateColumnNameAction,
	updateLeadSourceAction,
	updateProposalTitleAction,
} from "../../../store/actions/leadAction";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

const sources = data?.sources;
const leadTextItems = ["business_name", "budget", "proposal", "createdAt"];

function DetailsSection({ lead = null }) {
	const [source, setSource] = useState({
		_id: 1,
		name:
			sources.find((item) => item.value === lead?.source)?.name ||
			lead?.source.replace("_", " "),
	});
	const [isProposalTitleEdit, setIsProposalTitleEdit] = useState(null);
	const [newSource, setNewSource] = useState("");

	const sourceDropDownRef = useRef(null);
	const user = useSelector((state) => state.auth?.user);
	const dispatch = useDispatch();

	const changeColumn = (column) => {
		dispatch(
			updateColumnNameAction({
				id: lead?._id,
				name: column?.name,
			}),
		);
	};

	const handleChange = (data) => {
		if (lead?.client?._id) return;
		setSource(data);
		dispatch(
			updateLeadSourceAction({
				id: lead?._id,
				source: data?.value,
			}),
		);
	};

	const debounceTitle = useRef(
		debounce(async (data) => {
			dispatch(updateProposalTitleAction(data));
		}, 1000),
	).current;

	useEffect(() => {
		return () => {
			debounceTitle.cancel();
		};
	}, [debounceTitle]);

	const handleProposalTitleEdit = (e) => {
		const { value } = e.target;
		debounceTitle({
			lead: lead?._id,
			proposal: lead?.proposal?._id,
			title: value,
		});
	};

	const filters = user?.lead_column?.map((item, idx, arr) => {
		// Determine the current column index
		const currentColumnIndex = arr.findIndex((column) => column?.name === lead?.column);

		// Determine if the item is the last column
		const isLastColumn = idx === arr.length - 1;

		return (
			<div
				key={item?._id}
				className={clx(
					"h-[25px] flex items-center justify-center text-[8px] py-[8px] leading-[11px] font-medium grow cursor-pointer duration-250 transition-all min-w-[85px]",
					idx === 0 ? "rounded-l" : "",
					isLastColumn ? "rounded-r" : "",
					idx < currentColumnIndex
						? "text-AccentRegular bg-AccentLight hover:bg-AccentMediumLight  hover:text-AccentDark" // Previous columns
						: idx === currentColumnIndex
						? "text-AccentRegular bg-Neutral000 border border-AccentLight px-[8px]" // Current column
						: "text-Neutral700 bg-Neutral200 hover:bg-Neutral300 hover:text-Neutral800 px-[8px]", // Upcoming columns
				)}
				onClick={() => {
					if (lead?.client?._id) return;
					changeColumn(item);
					// If the last column is clicked, apply the tick mark and styling
					if (isLastColumn) {
						// Update the last column with the same styling as previous columns
						// Optionally, add any additional logic you need here
					}
				}}
				data-for="Lead Column"
				data-tip={item?.name}
			>
				{(idx < currentColumnIndex ||
					(isLastColumn && currentColumnIndex === arr.length - 1)) && (
					<CheckMarkIcon width={16} className="mr-1 text-AccentRegular shrink-0" />
				)}
				<span className="truncate">{item?.name}</span>
				<ReactTooltip
					place="bottom"
					textColor="#F5F5F7"
					className="p-5 tooltip-font-size"
					backgroundColor="#292933"
					id="Lead Column"
				/>
			</div>
		);
	});

	const blocksPaddingX =
		lead &&
		Object.keys(lead).reduce((acc, item) => {
			if (leadTextItems.includes(item) && lead[item]) {
				acc += 1;
			}
			return acc;
		}, 0) > 3
			? "px-5"
			: "px-8";

	return (
		<section className="w-full mb-2.5">
			<h3 className="pl-1 mb-2 text-base font-semibold leading-5 text-Neutral800">Details</h3>
			<div className="flex flex-col items-center justify-center w-full px-1 py-5 mb-4 border rounded-lg h-fit sm:flex-row sm:divide-x divide-Neutral400 border-Neutral400">
				<div className="flex w-full md:px-5 md:justify-around">
					<div
						className={`flex flex-col sm:items-center justify-center h-8 w-1/2 sm:w-fit mb-4 sm:mb-0 px-2 sm:${blocksPaddingX}`}
					>
						<span className="text-[10px] font-medium leading-4.5 text-Neutral600 mb-[2px] ">
							Company
						</span>
						<span className="block text-xs font-semibold leading-5 text-center break-words text-Neutral800 w-fit">
							{lead?.business_name ? `${lead?.business_name}` : "-"}
						</span>
					</div>

					<div
						className={`flex flex-col sm:items-center justify-center h-8 w-fit mb-4 sm:mb-0 px-2 sm:${blocksPaddingX}`}
					>
						<span className="text-[10px] font-medium leading-4.5 text-Neutral600 mb-[2px]">
							Budget
						</span>
						<span className="block text-xs font-semibold leading-5 text-center text-Neutral800 w-max">
							{lead?.budget
								? `${lead?.currency ? lead?.currency?.symbol : "$"} ${lead?.budget}`
								: "-"}
						</span>
					</div>
				</div>
				<div className="flex w-full">
					<div
						className={`flex flex-col sm:items-center justify-center h-8 w-1/2 sm:w-fit mb-4 sm:mb-0 px-2 sm:${blocksPaddingX}`}
					>
						<span className="text-[10px] font-medium leading-4.5 text-Neutral600 mb-[2px]">
							Proposal
						</span>{" "}
						{lead?.proposal ? (
							isProposalTitleEdit ? (
								<input
									type="text"
									className="block w-full text-xs font-semibold leading-5 text-center text-Neutral800"
									value={isProposalTitleEdit}
									onChange={(e) => setIsProposalTitleEdit(e.target.value)}
									onBlur={(e) => handleProposalTitleEdit(e)}
									autoFocus
								/>
							) : (
								<span
									className="block text-xs font-semibold leading-5 text-center text-Neutral800 w-fit"
									onClick={() => {
										if (lead?.client?._id) return;
										setIsProposalTitleEdit(lead?.proposal?.title);
									}}
								>
									{lead?.proposal?.title}
								</span>
							)
						) : (
							"-"
						)}
					</div>

					<div className="flex flex-col justify-center h-8 px-2 sm:items-center sm:px-8 w-max">
						<span className="text-[10px] font-medium leading-4.5 text-Neutral600 mb-[2px]">
							Date Added
						</span>{" "}
						<span className="block text-xs font-semibold leading-5 text-center text-Neutral800 w-max">
							{lead?.createdAt ? `${moment(lead?.createdAt).format("DD MMM YYYY")}` : "-"}
						</span>
					</div>
				</div>
			</div>
			<div className="w-full flex items-center justify-between gap-0.5 pb-0.5 scrollbar-h-4 overflow-x-auto mb-3">
				{filters}
			</div>
			<div className="relative flex items-end justify-start">
				<span className="text-[10px] font-medium leading-4.5 text-Neutral600 mr-0.5">
					Lead source:
				</span>
				<Menu
					as="div"
					className="flex items-center justify-start text-left"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className="flex items-end justify-start">
						<Menu.Button
							ref={sourceDropDownRef}
							className="text-[10px] font-medium leading-4.5 text-Neutral800 hover:text-AccentRegular flex items-center justify-start cursor-pointer rounded"
						>
							{source?.name}
							<ChevronDownIcon className="ml-0.5 pt-[1px]" color="#6D6DF2" />
						</Menu.Button>
					</div>
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<Menu.Items className="origin-top-right absolute z-[999] top-[18px] left-0 pt-4 pb-2 w-[216px] px-2 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none">
							<div className="">
								<input
									className="w-full px-2 mb-2 text-xs font-normal placeholder:text-Neutral600 text-Neutral700 focus:outline-none"
									placeholder="+Add New Category"
									value={newSource}
									onKeyUp={(e) => {
										e.stopPropagation();
									}}
									onKeyDown={(e) => {
										e.stopPropagation();
										if (e.key === "Enter") {
											if (
												sources.filter(
													(t) => t.name.toLowerCase() === newSource.toLowerCase(),
												).length === 0
											) {
												const preparedValue = newSource
													.toLowerCase()
													.replaceAll(" ", "_");
												sources.push({
													_id: newSource,
													value: preparedValue,
													name: newSource,
												});
												setSource(sources?.[sources.length - 1]);
												setNewSource("");
												setTimeout(() => {
													sourceDropDownRef?.current?.click();
												}, 0);
											} else {
												toast.error("Source already exists");
											}
										}
									}}
									onChange={(e) => {
										setNewSource(e.target.value);
									}}
								/>
							</div>
							<div className="project-title-divide">
								<div className="relative mb-2 overflow-hidden overflow-y-auto border-0 currancy-scrollbar scrollbar-w-4">
									<h4 className="text-Neutral600 font-semibold text-[10px] pt-3 ml-2 mr-5 border-t-[1px] border-Neutral200">
										Sources
									</h4>
									{sources
										.filter((source) =>
											source.name.toLowerCase().startsWith(newSource?.toLowerCase()),
										)
										.map((item, index) => {
											return (
												<div
													key={index}
													className="flex items-center p-2 mt-2 duration-300 rounded cursor-pointer hover:bg-Neutral200 group"
													onClick={(e) => {
														const preparedValue = item?.value
															?.toLowerCase()
															.replaceAll(" ", "_");
														handleChange(item);
														setNewSource("");
														setTimeout(() => {
															sourceDropDownRef?.current?.click();
															e.stopPropagation();
														}, 0);
													}}
												>
													<p className="w-full text-xs font-normal rounded cursor-pointer text-Neutral800 group-hover:text-Neutral900">
														{item?.name}
													</p>
												</div>
											);
										})}
								</div>
								{newSource && newSource !== "" && (
									<div
										className="bottom-0 flex items-center bg-AccentLight p-2 rounded duration-300 w-[200px] cursor-pointer"
										type="button"
										onClick={(e) => {
											if (
												sources.filter(
													(t) => t.name.toLowerCase() === newSource.toLowerCase(),
												).length === 0
											) {
												const preparedValue = newSource
													?.toLowerCase()
													.replaceAll(" ", "_");
												sources.push({
													_id: newSource,
													value: preparedValue,
													name: newSource,
												});
												handleChange(sources?.[sources.length - 1]);
												setNewSource("");
												setTimeout(() => {
													sourceDropDownRef?.current?.click();
												}, 0);
											} else {
												toast.error("Source already exists");
											}
										}}
									>
										<div className="flex items-center">
											<p className="text-xs font-normal rounded cursor-pointer text-AccentDark">
												Create
											</p>
											<p className="text-white ml-1.5 py-[6px] px-2 rounded font-normal text-xs bg-AccentRegular">
												{newSource}
											</p>
										</div>
									</div>
								)}
							</div>
						</Menu.Items>
					</Transition>
				</Menu>
			</div>
		</section>
	);
}

export default DetailsSection;
