import React from "react";

function EditIcon({
  color = "Neutral800",
  width = 16,
  className = "text-[#5A5A66]",
}) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.77149 4.12354H4.06033C3.77911 4.12354 3.50941 4.23525 3.31056 4.4341C3.11171 4.63295 3 4.90265 3 5.18387V12.6062C3 12.8874 3.11171 13.1571 3.31056 13.356C3.50941 13.5548 3.77911 13.6665 4.06033 13.6665H11.4827C11.7639 13.6665 12.0336 13.5548 12.2324 13.356C12.4313 13.1571 12.543 12.8874 12.543 12.6062V8.89503"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7464 3.32891C11.9573 3.118 12.2434 2.99951 12.5417 2.99951C12.84 2.99951 13.126 3.118 13.3369 3.32891C13.5478 3.53983 13.6663 3.82589 13.6663 4.12416C13.6663 4.42244 13.5478 4.7085 13.3369 4.91941L8.30035 9.95599L6.17969 10.4862L6.70985 8.36549L11.7464 3.32891Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EditIcon;
