import { Field } from "formik";
import ButtonLoader from "../Loaders/ButtonLoader";
import AddressAutocomplete from "./AddressAutocomplete";
import CustomErrorMessage from "./CustomErrorMessage";

function Step3({ active, onPrevious, complete, setStep, isSubmitting }) {
  return (
    <div
      className={`bg-neutral-50 p-4 rounded-lg w-full step flex h-auto ${
        active ? "flex-col items-start justify-start" : "h-14 items-center"
      }
      `}
    >
      <div
        className={`flex-col justify-center items-start gap-0.5 flex w-full ${
          !active && "cursor-pointer"
        }`}
        onClick={() => !active && setStep(3)}
      >
        <span className="text-xs font-bold tracking-wide flex gap-6 w-full items-center">
          <span
            className={`min-w-[40px] ${
              complete ? "text-emerald-400" : "text-indigo-500"
            } `}
          >
            STEP 3
          </span>
          {!active && (
            <span className="w-full flex justify-between items-center">
              <span className="text-zinc-500 text-xs font-bold">
                Your Information
              </span>
              {!active && complete && (
                <span className="text-xs font-bold tracking-wide">
                  <span
                    className={`w-12 ${
                      complete ? "text-emerald-400" : "text-indigo-500"
                    }`}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="22" height="22" rx="11" fill="#42D6A6" />
                      <path
                        d="M16.6477 7.40332L9.20657 14.3613L5.82422 11.1986"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </span>
              )}
            </span>
          )}
        </span>
        <div
          className={`justify-start items-center gap-3 flex ${
            !active && "hidden"
          }`}
        >
          <div className="text-zinc-800 text-base font-bold tracking-wide">
            Your Information
          </div>
        </div>
      </div>
      <div
        className={`justify-start items-start gap-6 flex flex-col mt-5 w-full ${
          !active && "hidden"
        }`}
      >
        <div className="justify-start items-start gap-6 flex flex-wrap w-full md:w-2/3">
          {/* EMAIL */}
          <div className="w-full">
            <label
              htmlFor="email"
              className="block mb-2 text-zinc-800 text-xs font-bold"
            >
              Email
            </label>
            <Field
              name="email"
              type="text"
              className="bg-white border border-gray-100 rounded p-3 text-neutral-500 text-sm font-medium outline-none w-full md:w-80"
              placeholder="Enter E-mail"
            />
            <CustomErrorMessage name="email" />
          </div>
          {/* EMAIL */}

          {/* FULL NAME */}
          <div className=" w-full">
            <label
              htmlFor="fullName"
              className="block mb-2 text-zinc-800 text-xs font-bold"
            >
              Enter Full Name
            </label>
            <Field
              name="fullName"
              type="text"
              className="bg-white border border-gray-100 rounded p-3 text-neutral-500 text-sm font-medium outline-none  w-full md:w-80"
              placeholder="Eg. John P."
            />
            <CustomErrorMessage name="fullName" />
          </div>
          {/* FULL NAME */}

          {/* COMPANY NAME */}
          <div className=" w-full">
            <label
              htmlFor="companyName"
              className="block mb-2 text-zinc-800 text-xs font-bold"
            >
              Company Name{" "}
              <span className="text-xs text-gray-400">(Optional)</span>
            </label>
            <Field
              name="companyName"
              type="text"
              className="bg-white border border-gray-100 rounded p-3 text-neutral-500 text-sm font-medium outline-none  w-full md:w-80"
              placeholder="Eg. John P."
            />
            <CustomErrorMessage name="companyName" />
          </div>
          {/* COMPANY NAME */}

          {/* ADDRESS */}
          <AddressAutocomplete label="Address" name="address" />
          {/* ADDRESS */}

          {/* PHONE NUMBER */}
          <div className=" w-full">
            <label
              htmlFor="phoneNumber"
              className="block mb-2 text-zinc-800 text-xs font-bold"
            >
              Phone Number{" "}
              <span className="text-xs text-gray-400">(Optional)</span>
            </label>
            <Field
              name="phoneNumber"
              type="text"
              className="bg-white border border-gray-100 rounded p-3 text-neutral-500 text-sm font-medium outline-none  w-full md:w-80"
              placeholder="Enter phone number"
            />
            <CustomErrorMessage name="phoneNumber" />
          </div>
          {/* PHONE NUMBER */}

          {/* VAT */}
          <div className=" w-full">
            <label
              htmlFor="VAT"
              className="block mb-2 text-zinc-800 text-xs font-bold"
            >
              VAT Number{" "}
              <span className="text-xs text-gray-400">(Optional)</span>
            </label>
            <Field
              name="VAT"
              type="text"
              className="bg-white border border-gray-100 rounded p-3 text-neutral-500 text-sm font-medium outline-none  w-full md:w-80"
              placeholder="Enter VAT Number"
            />
            <CustomErrorMessage name="VAT" />
          </div>
          {/* VAT */}

          {/*  */}
        </div>
        <div className="justify-start items-start gap-6 flex flex-col md:flex-row w-full">
          <button
            className={` w-full md:w-80 h-12 bg-indigo-500  rounded flex-col justify-center items-center gap-2 flex hover:bg-indigo-600 text-neutral-50`}
            disabled={isSubmitting}
            type="submit"
          >
            <div className="text-center text-neutral-50 text-sm font-bold leading-none w-full">
              {isSubmitting ? <ButtonLoader /> : "Next"}
            </div>
          </button>
          <button
            type="button"
            className="px-6 h-12 bg-gray-100 rounded flex-col justify-center items-center gap-2 flex hover:bg-gray-200"
            onClick={onPrevious}
          >
            <div className="text-center text-indigo-500 text-sm font-bold leading-none">
              Previous
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Step3;
