import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import DashboardButton from "../../components/Buttons/DashboardButton";
import Status from "../../components/Buttons/Status";
import ClientListLoader from "../../components/ContentLoaders/ClientListLoader";
import CalendarIcon from "../../components/Icons/CalendarIcon";
import AddImportModal from "../../components/Modals/AddImportModal";
import ArchivedClientsListModal from "../../components/Modals/ArchivedClientsListModal";
import CreateClientModal from "../../components/Modals/CreateClientModal";
import PreviewClientModal from "../../components/Modals/PreviewClientModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import NoClientComponent from "../../components/NoClient";
import NoClientData from "../../components/NoClient/NoClientData";
import NotDataFound from "../../components/NotDataProposal";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/Search";
import {
  GetClientAction,
  GetClientStatusList,
  ToggleFavouriteAction,
} from "../../store/actions/clientAction";
import useIsAdminAndPM from "../../hooks/useIsAdminAndPM";

function Clients(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdminOrPM = useIsAdminAndPM();
  const clientList = useSelector((state) => state.client.clientList);
  const authSelector = useSelector((state) => state.auth);
  const [createClientModal, setCreateClientModal] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [searchdata, setSearchData] = useState([]);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [sortColumn, setSortColumn] = useState("client_name");
  const [sort, setSort] = useState("asc");

  useEffect(() => {
    dispatch(
      GetClientAction({
        page: page,
        perPage: 20,
        search: searchValue,
        sortColumn: sortColumn,
        sortOrder: sort,
      })
    );

    dispatch(GetClientStatusList());
  }, [dispatch, page, searchValue]);

  function setModal(value) {
    setIsOpen(value);
  }

  const handleChangeSearch = (event) => {
    const value = event.target.value;
    setPage(1);
    setSearchValue(value);
  };

  useEffect(() => {
    setSearchData(clientList?.data?.docs);
  }, [clientList?.data?.docs]);

  async function csvJSON(string, event) {
    let lines = string.split("\n");

    // remove the before first character space
    let headers = lines[0]
      .split(",")
      .map((header) => header.toLowerCase().trim().replaceAll(" ", "_"));

    let jsonData = [];

    for (let i = 1; i < lines.length; i++) {
      let row = lines[i];
      if (!row) continue;

      let obj = {};
      let field = "";
      let isInsideQuotes = false;
      let charIndex = 0;
      let fieldIndex = 0;

      for (let char of row) {
        if (char === '"' && row[charIndex - 1] !== "\\") {
          isInsideQuotes = !isInsideQuotes;
        } else if (char === "," && !isInsideQuotes) {
          obj[headers[fieldIndex]] = field;
          field = "";
          fieldIndex++;
        } else {
          field += char;
        }
        charIndex++;
      }

      if (fieldIndex < headers.length) {
        obj[headers[fieldIndex]] = field;
      }

      if (fieldIndex !== headers.length - 1) {
        alert(
          "Please check the format of the file & Contact Person field is required"
        );
        if (event && event.target) {
          event.target.value = null;
        }
        throw new Error(
          "CSV format error: Field count does not match header count."
        );
      }

      // remove the before first character space
      obj = Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key,
          value.trim().replaceAll("", ""),
        ])
      );

      jsonData.push(obj);
    }

    return jsonData;
  }
  const DateInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <button
        className="example-custom-input xl:text-xs  text-[0.65rem] whitespace-nowrap font-bold color-Neutral100 text-Neutral700 border border-Neutral400 rounded-[20px] py-[6px] w-full px-[10px] focus-visible:outline-none focus:outline focus:outline-none transition duration-300 uppercase flex items-center justify-between hover:bg-Neutral200 hover-calendar flex-wrap"
        onClick={onClick}
        ref={ref}
      >
        <CalendarIcon
          className="mr-1 xl:mr-2"
          color="currentColor"
          width={20}
        />
        <span>{moment(value).format("DD MMM YYYY")}</span>
      </button>
    );
  });

  const handleToggleFavourite = async (
    clientId,
    currentFavouriteStatus,
    isAdmin
  ) => {
    if (!isAdmin) {
      console.warn("Only admins can toggle favorites.");
      return;
    }

    try {
      // Dispatch the Redux action to toggle the favorite status.
      dispatch(ToggleFavouriteAction(clientId, !currentFavouriteStatus));
      // Optional: Show a success toast or message here.
    } catch (error) {
      // Handle any errors that might occur, such as showing an error toast or message.
      console.error("Failed to toggle favorite:", error);
    }
  };

  return (
    <div>
      <AdminNavbar
        title="Client Dashboard"
        subtitle="View and manage your clients from here."
      />
      <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0 mb-[95px] md:mb-0 md:h-[75vh] ">
        <div className="mb-[68px]">
          <div className="md:mt-[32px] mt-[16px] text-right">
            <div className="flex items-center justify-between">
              <div className="flex w-full mr-2">
                {authSelector?.user?.invited === false ||
                (authSelector?.user?.owner &&
                  authSelector?.user?.invited === true) ||
                isAdminOrPM ? (
                  <>
                    <DashboardButton
                      title="Add Client"
                      onClick={() => setCreateClientModal(true)}
                    />
                    <div className="ml-4">
                      <DashboardButton
                        title="Import via CSV"
                        onClick={() => setOpenImportModal(true)}
                        variant="secondary"
                      />
                    </div>
                  </>
                ) : null}
                <input
                  type="file"
                  className="hidden"
                  id="import-clients"
                  accept=".csv"
                  onChange={async (e) => {
                    if (e.target.files && e.target.files[0]) {
                      const file = e.target.files[0];
                      const fileUrl = URL.createObjectURL(file);
                      const response = await fetch(fileUrl);
                      const text = await response.text();
                      const tempResult = await csvJSON(text, e);
                      if (openImportModal) setOpenImportModal(false);
                      setPreviewData(tempResult);
                      setIsPreviewModalOpen(true);
                      e.target.value = null;
                    }
                  }}
                ></input>
              </div>

              {clientList?.data?.docs?.length > 0 || searchValue !== "" ? (
                <SearchBar
                  searchValue={searchValue}
                  handleChangeSearch={handleChangeSearch}
                />
              ) : null}
            </div>

            {clientList?.data?.totalDocs ? (
              <button
                onClick={() => setModal(true)}
                className="text-xs text-AccentRegular font-medium inline-block mt-2 tracking-[0.04em] focus-visible:outline-none hover:text-AccentMediumDark"
              >
                View Archived Clients
              </button>
            ) : null}
            <div></div>
          </div>
          <div className="flex flex-col relative  overflow-x-scroll h-[calc(75vh-100px)] md:h-full md:overflow-x-visible w-full scrollbar-h-4 scrollbar-w-0">
            <div className="mt-[42px] items-center w-[850px] md:w-full justify-between px-8 flex">
              <h5 className="text-Neutral700 mr-2 w-[3%]"></h5>
              <button
                onClick={() => {
                  setSortColumn("client_name");
                  setSort(sort === "asc" ? "desc" : "asc");
                  dispatch(
                    GetClientAction({
                      page: page,
                      perPage: 10,
                      search: searchValue,
                      sortColumn: "client_name",
                      sortOrder: sort === "asc" ? "desc" : "asc",
                    })
                  );
                }}
                className="flex items-center pl-2 text-Neutral700 text-xs font-semibold focus-visible:outline-none focus:outline focus:outline-none transition duration-300 hover:text-AccentRegular w-[26%]"
              >
                {sortColumn === "client_name" &&
                  (sort === "asc" ? (
                    <svg
                      width="14"
                      height="14"
                      className="mr-1"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66667 10.8333L7 7.5L10.3333 10.8333"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.66667 6.5L7 3.16667L10.3333 6.5"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="14"
                      height="14"
                      className="mr-1"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66667 3.16669L7 6.50002L10.3333 3.16669"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.66667 7.5L7 10.8333L10.3333 7.5"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ))}
                {clientList?.data.totalDocs}{" "}
                {clientList?.data.totalDocs === 1 ? "CLIENT" : "CLIENTS"}
              </button>

              <button
                onClick={() => {
                  setSortColumn("email");
                  setSort(sort === "asc" ? "desc" : "asc");
                  dispatch(
                    GetClientAction({
                      page: page,
                      perPage: 10,
                      search: searchValue,
                      sortColumn: "email",
                      sortOrder: sort === "asc" ? "desc" : "asc",
                    })
                  );
                }}
                className="flex items-center pl-2 text-Neutral700 text-xs font-semibold focus-visible:outline-none focus:outline focus:outline-none transition duration-300 hover:text-AccentRegular w-[26%]"
              >
                {sortColumn === "email" &&
                  (sort === "asc" ? (
                    <svg
                      width="14"
                      height="14"
                      className="mr-1"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66667 10.8333L7 7.5L10.3333 10.8333"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.66667 6.5L7 3.16667L10.3333 6.5"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="14"
                      height="14"
                      className="mr-1"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66667 3.16669L7 6.50002L10.3333 3.16669"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.66667 7.5L7 10.8333L10.3333 7.5"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ))}
                EMAIL
              </button>
              <button
                onClick={() => {
                  setSortColumn("createdAt");
                  setSort(sort === "asc" ? "desc" : "asc");
                  dispatch(
                    GetClientAction({
                      page: page,
                      perPage: 10,
                      search: searchValue,
                      sortColumn: "createdAt",
                      sortOrder: sort === "asc" ? "desc" : "asc",
                    })
                  );
                }}
                className="flex items-center pl-2 text-Neutral700 text-xs font-semibold focus-visible:outline-none focus:outline focus:outline-none transition duration-300 hover:text-AccentRegular w-[21%] xl:w-[16%]"
              >
                {sortColumn === "createdAt" &&
                  (sort === "asc" ? (
                    <svg
                      width="14"
                      height="14"
                      className="mr-1"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66667 10.8333L7 7.5L10.3333 10.8333"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.66667 6.5L7 3.16667L10.3333 6.5"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="14"
                      height="14"
                      className="mr-1"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66667 3.16669L7 6.50002L10.3333 3.16669"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.66667 7.5L7 10.8333L10.3333 7.5"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ))}
                DATE ADDED
              </button>
              <button
                onClick={() => {
                  setSortColumn("PROJECTS");
                  setSort(sort === "asc" ? "desc" : "asc");
                  dispatch(
                    GetClientAction({
                      page: page,
                      perPage: 10,
                      search: searchValue,
                      sortColumn: "projects_count",
                      sortOrder: sort === "asc" ? "desc" : "asc",
                    })
                  );
                }}
                className="flex items-center pl-2 text-Neutral700 text-xs justify-center font-semibold focus-visible:outline-none focus:outline focus:outline-none transition duration-300 hover:text-AccentRegular w-[10%] xl:w-[15%]"
              >
                {sortColumn === "PROJECTS" &&
                  (sort === "asc" ? (
                    <svg
                      width="14"
                      height="14"
                      className="mr-1"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66667 10.8333L7 7.5L10.3333 10.8333"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.66667 6.5L7 3.16667L10.3333 6.5"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="14"
                      height="14"
                      className="mr-1"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66667 3.16669L7 6.50002L10.3333 3.16669"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.66667 7.5L7 10.8333L10.3333 7.5"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ))}
                PROJECTS
              </button>
              <button
                onClick={() => {
                  setSortColumn("status");
                  setSort(sort === "asc" ? "desc" : "asc");
                  dispatch(
                    GetClientAction({
                      page: page,
                      perPage: 10,
                      search: searchValue,
                      sortColumn: "status",
                      sortOrder: sort === "asc" ? "desc" : "asc",
                    })
                  );
                }}
                className="flex items-center pl-2 text-Neutral700 text-xs font-semibold focus-visible:outline-none focus:outline focus:outline-none transition duration-300 hover:text-AccentRegular w-[14%]"
              >
                {sortColumn === "status" &&
                  (sort === "asc" ? (
                    <svg
                      width="14"
                      height="14"
                      className="mr-1"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66667 10.8333L7 7.5L10.3333 10.8333"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.66667 6.5L7 3.16667L10.3333 6.5"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="14"
                      height="14"
                      className="mr-1"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.66667 3.16669L7 6.50002L10.3333 3.16669"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.66667 7.5L7 10.8333L10.3333 7.5"
                        stroke="#6D6DF2"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ))}
                STATUS
              </button>
            </div>

            {clientList.isLoading &&
            !clientList?.data?.totalDocs &&
            !searchdata ? (
              <ClientListLoader />
            ) : (
              <div className="mt-4 h-[70vh] md:h-full">
                {authSelector?.user?.invited === true &&
                  !clientList?.data?.totalDocs &&
                  !searchValue && <NoClientData />}

                {authSelector?.user?.invited === false &&
                  !clientList?.data?.totalDocs &&
                  !searchValue && <NoClientComponent />}

                {searchValue && clientList?.data.totalDocs === 0 && (
                  <NotDataFound />
                )}

                <div className="w-[850px] md:w-full mb-7">
                  {clientList?.data?.docs?.map((item, index) => (
                    <div
                      className="w-full mb-4"
                      key={index}
                      onClick={() =>
                        navigate({
                          pathname: `/admin/clients/manage/${item._id}`,
                          state: item,
                        })
                      }
                    >
                      <div className="w-full px-8 bg-white border cursor-pointer border-Neutral300 rounded-xl time-tracking-shadow">
                        <div className="flex items-center justify-between w-full">
                          {/* ... The single row layout for larger screens ... */}
                          <div
                            style={{ zIndex: 99 - (index + 5) }}
                            className={`relative flex items-center justify-between duration-300 w-full`}
                          >
                            <div
                              className="w-[3%] mr-2 text-left"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleToggleFavourite(
                                  item._id,
                                  item?.is_favourite,
                                  item?.is_admin
                                );
                              }}
                            >
                              {item?.is_favourite ? (
                                <svg
                                  width="20"
                                  height="18"
                                  viewBox="0 0 20 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10 1.075L12.575 6.29166L18.3334 7.13333L14.1667 11.1917L15.15 16.925L10 14.2167L4.85002 16.925L5.83335 11.1917L1.66669 7.13333L7.42502 6.29166L10 1.075Z"
                                    fill="#6D6DF2"
                                    stroke="#6D6DF2"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="20"
                                  height="18"
                                  viewBox="0 0 20 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10 1.075L12.575 6.29167L18.3334 7.13333L14.1667 11.1917L15.15 16.925L10 14.2167L4.85002 16.925L5.83335 11.1917L1.66669 7.13333L7.42502 6.29167L10 1.075Z"
                                    stroke="#9C9CA6"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              )}
                            </div>
                            <div className="w-[26%] py-5 flex items-center">
                              {item?.profile_path ? (
                                <img
                                  className="w-10 h-10 rounded min-w-10 5xl:h-16 5xl:w-16"
                                  src={item?.profile_path}
                                  alt=""
                                />
                              ) : (
                                <div
                                  className={`h-10 w-10 min-w-10 5xl:h-16 5xl:w-16 rounded items-center justify-center bg-Neutral400 text-Neutral700`}
                                >
                                  <span className=" text-base h-10 w-10 min-w-10 5xl:text-[24px] 5xl:h-16 5xl:w-16 flex items-center justify-center rounded-full font-bold border border-Miscgrey capitalize">
                                    {item.client_name[0]}
                                  </span>
                                </div>
                              )}
                              <div className="w-[75%] pl-2 relative 3xl:w-[85%]">
                                <h4
                                  data-tip={
                                    item.business_name
                                      ? item.business_name
                                      : item.contact_name
                                      ? item.contact_name
                                      : ""
                                  }
                                  className="text-sm font-semibold truncate text-Neutral900"
                                  data-for="clientName"
                                >
                                  {/* {item.client_name} */}
                                  {item?.business_name || item?.contact_name}
                                </h4>
                                <ReactTooltip id="clientName" effect="solid" />
                              </div>
                            </div>
                            <div className="w-[26%] pl-2 text-left">
                              <p
                                data-tip={item.email ? item.email : ""}
                                className="w-full text-sm font-medium truncate text-Neutral700 text-ellipsis"
                                data-for="clientEmail"
                              >
                                {item.email}
                              </p>
                              <ReactTooltip id="clientEmail" effect="solid" />
                            </div>
                            <div className="w-[21%] xl:w-[16%] pl-2  text-left ">
                              <p
                                className="text-sm font-medium text-Neutral700"
                                id="clientDate"
                              >
                                {item?.createdAt && (
                                  <DatePicker
                                    selected={moment(
                                      new Date(item?.createdAt),
                                      "DD/MM/YYYY"
                                    ).toDate()}
                                    customInput={
                                      <DateInput value={item?.createdAt} />
                                    }
                                    disabled={true}
                                  />
                                )}
                              </p>
                            </div>
                            <div className="w-[10%] xl:w-[15%] pl-2 text-Neutral800 text-center text-sm">
                              <span whitespace-nowrap>
                                {item?.projects_count}
                              </span>
                            </div>
                            <div
                              className="w-[14%] pl-2"
                              onClick={(e) => e.stopPropagation()}
                              key={item._id}
                            >
                              <Status
                                id={item._id}
                                status={item?.client_status?.name}
                                item={item}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {clientList?.data?.totalDocs ? (
                  <Pagination
                    currantPage={clientList.data.page}
                    totalData={clientList.data.totalDocs}
                    hasPrevPage={clientList.data.hasPrevPage}
                    hasNextPage={clientList.data.hasNextPage}
                    fromRecord={clientList.data.pagingCounter}
                    outOfCounter={clientList?.data?.docs?.length}
                    setPage={setPage}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </div>

          {/* {/ View Archived Clients Modal /} */}
          <ArchivedClientsListModal isOpen={modalIsOpen} setModal={setModal} />
          <CreateClientModal
            isOpen={createClientModal}
            setIsOpen={setCreateClientModal}
          />
        </div>
      </div>
      {isPreviewModalOpen && (
        <PreviewClientModal
          isPreviewModalOpen={isPreviewModalOpen}
          setIsPreviewModalOpen={setIsPreviewModalOpen}
          previewData={previewData}
          setPreviewData={setPreviewData}
        />
      )}
      {openImportModal && (
        <AddImportModal
          isOpen={openImportModal}
          setIsOpen={setOpenImportModal}
          type="client"
        />
      )}
    </div>
  );
}

export default Clients;
