import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  ForgetPasswordAction,
  GetEmailExistanceAction,
} from "../../store/actions/authAction";
import {
  ForgetCollabPasswordAction,
  GetCollabEmailExistanceAction,
} from "../../store/actions/collabAuthAction";
import Logo from "../../assets/Images/Logo.svg";
import SigninBg from "../../assets/Images/signin-bg-shap.png";
import GreenRightTik from "../../assets/Images/green-right-tik.svg";
import EmailErrorIcon from "../../assets/Images/email-error-icon.svg";
import BlueRightTik from "../../assets/Images/blue-right-tik.svg";
import SmallSpinner from "../../assets/Images/Small-Spinner.svg";

function ForgotPassword() {
  const dispatch = useDispatch();
  const [haveResponse, sethaveResponse] = useState(null);
  const [responseText, setResponseText] = useState(null);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [isChecking, SetIsChecking] = useState(false);
  const location = useLocation();
  const signInPath = location.pathname.startsWith("/client-collab");

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter your correct email address.")
      .required("Email Required")
      .test(
        "Email Existence",
        "Looks like email is not registered with us.",
        async (value) => {
          var re = /\S+@\S+\.\S+/;
          if (re.test(value)) {
            SetIsChecking(true);
            let ans;
            if (signInPath) {
              ans = await GetCollabEmailExistanceAction(value);
            } else {
              ans = await GetEmailExistanceAction(value);
            }            
            SetIsChecking(false);
            if (ans) {
              setIsEmailExist(true);
            } else {
              setIsEmailExist(false);
            }
            return ans;
          } else {
            setIsEmailExist(false);
          }
        }
      ),
  });
  return (
    <>
      {!signInPath ?
    (<section>
      <div className="flex">
        <div className="w-full max-w-[50%]  sm:h-screen relative md:block hidden">
          <div className="absolute top-[50%] w-full 5xl:max-w-[440px] max-w-[384px] left-[50%] -translate-x-1/2 -translate-y-1/2 ml:px-0 px-3">
            <div className="flex items-center">
              <img src={Logo} alt="logo" />
              <h2 className="5xl:text-[60px] lg:text-[56px] text-[40px] font-black w-full max-w-[260px] leading-[0.85] text-Neutral000 ml-4">
                Client Manager
              </h2>
            </div>
            <p className="text-Neutral100 5xl:text-[18px] text-[16px] mt-[12px]">
              The Simple Client Onboarding & Management Tool
            </p>
          </div>
          <img
            className="w-full object-cover h-screen"
            src={SigninBg}
            alt="background"
          />
        </div>
        <div className="w-full md:max-w-[50%] max-w-full md:h-screen h-full flex md:flex-row flex-col md:items-center justify-center relative px-3 overflow-y-auto">
          <div className="w-full max-w-[320px] 5xl:max-w-[400px] md:mt-0 mt-12 2xl:pt-0 md:pt-24 md:mx-0 mx-auto">
            <Link
              to="/signin"
              className="w-8 h-8 rounded-full border border-Neutral400 inline-block text-center hover:border-Neutral600 transition-all duration-300 ease-in-out back-btn-hover"
            >
              <svg
                className="mx-auto mt-2"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  className="transition-all duration-300 ease-in-out"
                  stroke="#9C9CA6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13 7H1"
                  className="transition-all duration-300 ease-in-out"
                  stroke="#9C9CA6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
            <h1 className="mt-3 text-[32px] 5xl:text-[36px] font-bold mb-4 text-Neutral900">
              Forgot Password?
            </h1>
            <p className="text-Neutral800 5xl:text-[16px] text-xs mb-7 ">
              Enter the email connected to your ClientManager account and we’ll
              send you a link to reset your password.
            </p>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={ForgotPasswordSchema}
              validateOnBlur={false}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(
                  ForgetPasswordAction(
                    values,
                    setSubmitting,
                    sethaveResponse,
                    setResponseText
                  )
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mb-[24px]">
                    <label className="text-Neutral900 font-bold text-xs">
                      Recovery Email
                    </label>
                    <div className="mt-2">
                      <div className="relative passwordshow mt-2">
                        <input
                          className={`placeholder:text-Neutral500 placeholder:font-normal py-3 pl-4 px-4 text-sm leading-5 w-full focus:outline-none text-Neutral800 font-medium border-[1.5px] rounded ${
                            errors.email && touched.email
                              ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow pr-12"
                              : ""
                          } ${
                            isEmailExist
                              ? "border-Green400 focus:border-Green400 focus:drop-shadow-Validboxshadow"
                              : ""
                          } focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow  transition duration-300 ease-in-out `}
                          type="email"
                          placeholder="Eg. luke@skywalker.com"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email && !isChecking && (
                          <img
                            className="w-full absolute right-3 top-[50%] -translate-y-[50%] max-w-[32px] cursor-pointer"
                            src={EmailErrorIcon}
                            alt="PasswordIcon"
                          />
                        )}
                        {isEmailExist && !isChecking && (
                          <img
                            className="w-[14px] absolute right-5 top-[50%] -translate-y-[50%] max-w-[32px] cursor-pointer"
                            src={GreenRightTik}
                            alt="PasswordIcon"
                          />
                        )}
                        {isChecking && (
                          <img
                            className="w-[14px] absolute right-5 top-[35%] max-w-[32px] cursor-pointer motion-safe:animate-spin"
                            src={SmallSpinner}
                            alt="LoadingIcon"
                          />
                        )}
                      </div>
                    </div>
                    {errors.email && touched.email && (
                      <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                        {errors.email}
                      </p>
                    )}
                  </div>

                  <div className="mt-10 2xl:pb-0 md:pb-16 pb-10">
                    <button
                      disabled={isSubmitting}
                      className={`relative text-sm text-center block text-Neutral100 ${
                        isSubmitting
                          ? "bg-Neutral300 focus-visible:outline-AccentLight text-Neutral500"
                          : "bg-AccentRegular text-Neutral100 form-primary-btn btn"
                      } font-bold w-full py-4 rounded drop-shadow-Texts overflow-hidden transition duration-300 ease-in-out`}
                    >
                      <span
                        className={`${
                          isSubmitting
                            ? "relative z-10 text-Neutral500"
                            : "text-Neutral100"
                        }`}
                      >
                        {isSubmitting ? (
                          <div className="flex justify-center">
                            <div className="pr-2 white dot-falling"></div>
                          </div>
                        ) : (
                          "Send Recovery Link"
                        )}
                      </span>
                    </button>
                  </div>
                  {haveResponse !== null && haveResponse && (
                    <p className="py-3 px-4 mt-2 rounded bg-AccentLight text-AccentMediumDark text-xs flex w-fit font-medium">
                      <img src={BlueRightTik} alt="" className="mr-3" />
                      {responseText}
                    </p>
                  )}

                  {haveResponse !== null && !haveResponse && responseText && (
                    <p className="py-3 px-4 mt-2 rounded bg-Red100 text-Red900 text-xs flex w-fit font-medium">
                      {responseText}
                    </p>
                  )}
                </form>
              )}
            </Formik>
          </div>
          <div className="md:absolute md:top-2 3xl:right-[7%] ml:right-8 right-[6%] md:mb-0 mb-10 w-full mx-auto max-w-[248px] 5xl:max-w-[300px]">
            <div className="4xl:flex ml:block flex  text-right items-center md:justify-end justify-center 5xl:mt-4">
              <p className="text-xs text-Neutral700 font-medium 4xl:mr-[24px] ml:mr-0 mr-[24px] mb-0">
                Don’t have an account?
              </p>
              <Link
                to="/signup"
                className="text-xs text-center inline-block text-AccentRegular font-[600] 4xl:mt-0 ml:mt-2 mt-0 py-1 px-2 rounded border-[1.5px] border-Neutral300 hover:border-AccentRegular focus-visible:outline focus-visible:outline-4 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>):(
      <section>
      <div className="flex">
        <div className="w-full max-w-[50%]  sm:h-screen relative md:block hidden">
          <div className="absolute top-[50%] w-full 5xl:max-w-[440px] max-w-[384px] left-[50%] -translate-x-1/2 -translate-y-1/2 ml:px-0 px-3">
            <div className="flex items-center">
              <img src={Logo} alt="logo" />
              <h2 className="5xl:text-[60px] lg:text-[56px] text-[40px] font-black w-full max-w-[260px] leading-[0.85] text-Neutral000 ml-4">
                Client Manager
              </h2>
            </div>
            <p className="text-Neutral100 5xl:text-[18px] text-[16px] mt-[12px]">
              The Simple Client Onboarding & Management Tool
            </p>
          </div>
          <img
            className="w-full object-cover h-screen"
            src={SigninBg}
            alt="background"
          />
        </div>
        <div className="w-full md:max-w-[50%] max-w-full md:h-screen h-full flex md:flex-row flex-col md:items-center justify-center relative px-3 overflow-y-auto">
          <div className="w-full max-w-[320px] 5xl:max-w-[400px] md:mt-0 mt-12 2xl:pt-0 md:pt-24 md:mx-0 mx-auto">
            <Link
              to="/client-collab/signin"
              className="w-8 h-8 rounded-full border border-Neutral400 inline-block text-center hover:border-Neutral600 transition-all duration-300 ease-in-out back-btn-hover"
            >
              <svg
                className="mx-auto mt-2"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  className="transition-all duration-300 ease-in-out"
                  stroke="#9C9CA6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13 7H1"
                  className="transition-all duration-300 ease-in-out"
                  stroke="#9C9CA6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
            <h1 className="mt-3 text-[32px] 5xl:text-[36px] font-bold mb-4 text-Neutral900">
              Forgot Password?
            </h1>
            <p className="text-Neutral800 5xl:text-[16px] text-xs mb-7 ">
              Enter the email connected to your ClientManager account and we’ll
              send you a link to reset your password.
            </p>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={ForgotPasswordSchema}
              validateOnBlur={false}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(
                  ForgetCollabPasswordAction(
                    values,
                    setSubmitting,
                    sethaveResponse,
                    setResponseText
                  )
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mb-[24px]">
                    <label className="text-Neutral900 font-bold text-xs">
                      Recovery Email
                    </label>
                    <div className="mt-2">
                      <div className="relative passwordshow mt-2">
                        <input
                          className={`placeholder:text-Neutral500 placeholder:font-normal py-3 pl-4 px-4 text-sm leading-5 w-full focus:outline-none text-Neutral800 font-medium border-[1.5px] rounded ${
                            errors.email && touched.email
                              ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow pr-12"
                              : ""
                          } ${
                            isEmailExist
                              ? "border-Green400 focus:border-Green400 focus:drop-shadow-Validboxshadow"
                              : ""
                          } focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow  transition duration-300 ease-in-out `}
                          type="email"
                          placeholder="Eg. luke@skywalker.com"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.email && touched.email && !isChecking && (
                          <img
                            className="w-full absolute right-3 top-[50%] -translate-y-[50%] max-w-[32px] cursor-pointer"
                            src={EmailErrorIcon}
                            alt="PasswordIcon"
                          />
                        )}
                        {isEmailExist && !isChecking && (
                          <img
                            className="w-[14px] absolute right-5 top-[50%] -translate-y-[50%] max-w-[32px] cursor-pointer"
                            src={GreenRightTik}
                            alt="PasswordIcon"
                          />
                        )}
                        {isChecking && (
                          <img
                            className="w-[14px] absolute right-5 top-[35%] max-w-[32px] cursor-pointer motion-safe:animate-spin"
                            src={SmallSpinner}
                            alt="LoadingIcon"
                          />
                        )}
                      </div>
                    </div>
                    {errors.email && touched.email && (
                      <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                        {errors.email}
                      </p>
                    )}
                  </div>

                  <div className="mt-10 2xl:pb-0 md:pb-16 pb-10">
                    <button
                      disabled={isSubmitting}
                      className={`relative text-sm text-center block text-Neutral100 ${
                        isSubmitting
                          ? "bg-Neutral300 focus-visible:outline-AccentLight text-Neutral500"
                          : "bg-AccentRegular text-Neutral100 form-primary-btn btn"
                      } font-bold w-full py-4 rounded drop-shadow-Texts overflow-hidden transition duration-300 ease-in-out`}
                    >
                      <span
                        className={`${
                          isSubmitting
                            ? "relative z-10 text-Neutral500"
                            : "text-Neutral100"
                        }`}
                      >
                        {isSubmitting ? (
                          <div className="flex justify-center">
                            <div className="pr-2 white dot-falling"></div>
                          </div>
                        ) : (
                          "Send Recovery Link"
                        )}
                      </span>
                    </button>
                  </div>
                  {haveResponse !== null && haveResponse && (
                    <p className="py-3 px-4 mt-2 rounded bg-AccentLight text-AccentMediumDark text-xs flex w-fit font-medium">
                      <img src={BlueRightTik} alt="" className="mr-3" />
                      {responseText}
                    </p>
                  )}

                  {haveResponse !== null && !haveResponse && responseText && (
                    <p className="py-3 px-4 mt-2 rounded bg-Red100 text-Red900 text-xs flex w-fit font-medium">
                      {responseText}
                    </p>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
    )}
    </>
  );
}

export default ForgotPassword;
