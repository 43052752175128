import moment from "moment";
import { useEffect, useState } from "react";
import Modal from "react-modal";

import DisplayImage from "../../assets/Images/Display-Image.png";
import CloseIcon from "../Icons/CloseIcon";

/** @type {Modal.Styles} */
const customStyles = {
	overlay: {
		backgroundColor: "rgba(41, 41, 51, 0.7)",
	},
	content: {
		inset: "50% auto auto 50%",
		padding: 0,
		transform: "translate(-50%, -50%)",
		background: "none",
		border: "none",
		overflow: "visible",
	},
};

const listItems = [
	"New Dashboard",
	"Stripe integration (for invoices)",
	"Due date on projects",
	"Project budget field",
	"Automated email follow-ups",
	"Simplified & expanded tasks",
	"Custom field in task board",
];

function NewContentAlertModal() {
	const [isModalOpen, setIsModalOpen] = useState(false);
	// Swap the values of both these variables below when you want to trigger the modal for new content updates.
	const currentContent = "newContent-2";
	const pastContent = "newContent-1";

	useEffect(() => {
		try {
			if (!localStorage.getItem(currentContent)) {
				setIsModalOpen(true);
				localStorage.setItem(currentContent, true);
			}

			localStorage.removeItem(pastContent);
		} catch (error) {
			console.error("Error accessing localStorage:", error);
		}
	}, []);

	const handleCloseModal = () => {
		try {
			localStorage.setItem(currentContent, true);
			setIsModalOpen(false);
		} catch (error) {
			console.error("Error setting localStorage:", error);
		}
	};

	return (
		<Modal
			isOpen={isModalOpen}
			onRequestClose={handleCloseModal}
			style={customStyles}
			closeTimeoutMS={300}
			contentLabel="New Content Alert Modal"
		>
			<div className="relative shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp min-w-96">
				<div className="px-8 py-6 rounded-md bg-Neutral000">
					<h2 className="pb-2 mb-4 font-bold tracking-wide text-center uppercase border-b text-Neutral800 border-Neutral300">
						What's new this month?
					</h2>

					<ul className="py-2 space-y-3.5 border-l border-Neutral300">
						{listItems.map((item, idx) => (
							<li
								key={idx}
								className="relative flex items-center -ml-1 text-sm font-medium text-Neutral800"
							>
								<span className="mr-4 shrink-0 rounded-full size-2 bg-AccentRegular ring-AccentMediumLight ring-[3px]" />
								{item}
							</li>
						))}
					</ul>
				</div>
				<button
					className="absolute right-0 p-1 border rounded-full text-Neutral100 -top-10 border-Neutral100"
					onClick={handleCloseModal}
				>
					<CloseIcon />
				</button>
			</div>
		</Modal>
	);
}

export default NewContentAlertModal;
