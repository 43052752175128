import React from "react";
import Checkmark from "../../assets/Images/Checkmark.svg";

function Filter(props) {
  const {
    filterBtn,
    setFilterBtn,
    addfilter,
    setAddFilter,
    members,
    categories,
  } = props;
  const hanldleChangeFilter = (name, value) => {
    if (addfilter?.[name].includes(value)) {
      const removedFilter = addfilter[name].filter((res) => res !== value);
      setAddFilter({
        ...addfilter,
        [name]: removedFilter,
      });
    } else {
      setAddFilter({
        ...addfilter,
        [name]: [...addfilter[name], value],
      });
    }
  };

  const renderClearClassNames = () => {
    if (addfilter) {
      for (var item in addfilter) {
        if (addfilter[item].length > 0) {
          return "!text-[#6D6DF2] text-Neutral500 text-xs";
        }
      }
    }
    return "text-Neutral500 text-xs";
  };

  return (
    <div
      className={`fixed bg-[#292933B2] top-0 z-[999] left-0 w-full h-screen flex transition-all ease-in-out ${
        filterBtn ? "duration-500" : "invisible"
      }`}
    >
      <div
        className={`w-[calc(100%-352px)] ${filterBtn ? "" : ""} `}
        onClick={() => setFilterBtn(false)}
      ></div>
      <div
        className={`h-screen overflow-scroll relative bg-Neutral100 w-fit transition-all duration-500 ease-in-out ${
          filterBtn ? " right-0 " : "-right-[100%]"
        }`}
      >
        <div className="text-Neutral700 p-8 w-[344px]">
          <div className="flex justify-between items-center">
            <span className="text-Neutral800 font-bold text-sm">FILTER</span>
            <button
              className={renderClearClassNames()}
              onClick={() =>
                setAddFilter({
                  urgentonly: [],
                  status: [],
                  assignedto: [],
                  date: [],
                  categories: [],
                })
              }
            >
              CLEAR
            </button>
          </div>
          <div className="flex justify-between items-center pt-12">
            <span className="text-Neutral700 font-medium text-xs">
              URGENT ONLY
            </span>
            <label
              htmlFor="toggleTwo"
              className="toggle-switch flex cursor-pointer select-none items-center"
            >
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggleTwo"
                  className="sr-only"
                  checked={addfilter?.urgentonly.includes("urgent")}
                  onChange={(e) => {
                    hanldleChangeFilter("urgentonly", "urgent");
                  }}
                />
                <div className="block h-[24px] switch w-[44px] rounded-full border-Neutral400 border-2 bg-Neutral100"></div>
                <div className="dot absolute left-0 top-0 h-6 w-6 rounded-full bg-Neutral800 transition shadow-[0px_1.5px_3px_1px_rgba(0,0,0,0.2)]"></div>
              </div>
            </label>
          </div>
          <div className="pt-9">
            <span className="text-Neutral500 text-xs font-semibold leading-[16.34px]">
              STATUS
            </span>
            <div className="flex items-center justify-between pt-5">
              <span className="text-Neutral700 text-xs font-medium block capitalize">
                not started
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  id="A3-yes"
                  checked={addfilter?.status.includes("not_started")}
                  onChange={(e) => {
                    hanldleChangeFilter("status", "not_started");
                  }}
                  name="A3-confirmation"
                  value="yes"
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-Neutral300 border-[1.5px] custom-chackbox rounded-md hover:bg-AccentLight hover:border-black w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between pt-[20px]">
              <span className="text-Neutral700 text-xs font-medium block capitalize">
                in progress
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  id="A3-yes"
                  checked={addfilter?.status.includes("in_progress")}
                  onChange={(e) => {
                    hanldleChangeFilter("status", "in_progress");
                  }}
                  name="A3-confirmation"
                  value="yes"
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-[1.5px] cursor-pointer hover:bg-AccentLight hover:border-AccentRegular custom-chackbox rounded-md border-Neutral300 w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between pt-[20px]">
              <span className="text-Neutral700 text-xs font-medium block capitalize">
                completed
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  id="A3-yes"
                  checked={addfilter?.status.includes("completed")}
                  onChange={(e) => {
                    hanldleChangeFilter("status", "completed");
                  }}
                  name="A3-confirmation"
                  value="yes"
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-[1.5px] cursor-pointer hover:bg-AccentLight hover:border-Accent/Regular custom-chackbox rounded-md border-Neutral300 w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-9 ">
            <span className="text-Neutral500 text-xs font-semibold">
              ASSIGNED TO
            </span>
            <div className="pt-5 flex flex-wrap">
              {members?.length ? (
                members.map((level, idx) => {
                  return (
                    <div key={idx}>
                      <button
                        checked={addfilter?.assignedto.includes(level?._id)}
                        className="flex items-center border-Neutral300 border rounded-[4px] mb-1 bg-Neutral100 py-[6px] px-2  hover:border-Neutral600 text-Neutral700 hover:text-Neutral800 cursor-pointer mr-2 transition duration-300 ease-in-out"
                        onClick={() => {
                          hanldleChangeFilter("assignedto", level?._id);
                        }}
                        style={{
                          backgroundColor: addfilter?.assignedto?.includes(
                            level?._id
                          )
                            ? "#5A5A66"
                            : "",
                          color: addfilter?.assignedto?.includes(level?._id)
                            ? "white"
                            : "",
                        }}
                      >
                        <img
                          src={level?.profile_path}
                          alt=""
                          className="mr-2 outline-[0.5px] outline-[rgba(0, 0, 0, 0.01)] rounded-full outline outline-1 outline-[#0000001a] overflow-hidden min-w-9 max-w-[36px] w-5"
                        />
                        <span className="text-xs  font-medium">
                          {level?.user_name}
                        </span>
                      </button>
                    </div>
                  );
                })
              ) : (
                <p className="text-Neutral700 text-xs font-medium italic">
                  No team members assigned.
                </p>
              )}
            </div>
          </div>
          <div className="pt-9 ">
            <span className="text-Neutral500 text-xs font-semibold">
              DUE DATE
            </span>
            <div className="flex items-center justify-between pt-6 relative">
              <span className="text-Neutral700 text-xs font-medium block capitalize">
                Within 7 Days
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  id="A3-yes"
                  checked={addfilter?.date.includes("Within 7 Days")}
                  onChange={(e) => {
                    hanldleChangeFilter("date", "Within 7 Days");
                  }}
                  name="A3-confirmation"
                  value="yes"
                  className="opacity-0 absolute cursor-pointer h-[22px] w-[22px] transition duration-300"
                />
                <div className="bg-white border-[1.5px] hover:bg-AccentLight hover:border-Accent/Regular custom-chackbox rounded-md border-Neutral300 w-[22px] h-[22px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between pt-[20px] relative">
              <span className="text-Neutral700 text-xs font-medium block capitalize">
                This Month
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  id="A3-yes"
                  checked={addfilter?.date.includes("This Month")}
                  onChange={(e) => {
                    hanldleChangeFilter("date", "This Month");
                  }}
                  name="A3-confirmation"
                  value="yes"
                  className="opacity-0 absolute cursor-pointer h-[22px] w-[22px] transition duration-300"
                />
                <div className="bg-white border-[1.5px] custom-chackbox rounded-md border-Neutral300 w-[22px] h-[22px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between pt-[20px] relative">
              <span className="text-Neutral700 text-xs font-medium block capitalize">
                After this month
              </span>
              <div className="filter-checkbox flex items-center">
                <input
                  type="checkbox"
                  id="A3-yes"
                  checked={addfilter?.date.includes("After this month")}
                  onChange={(e) => {
                    hanldleChangeFilter("date", "After this month");
                  }}
                  name="A3-confirmation"
                  value="yes"
                  className="opacity-0 absolute cursor-pointer h-[22px] w-[22px] transition duration-300 "
                />
                <div className="bg-white hover:bg-black border-[1.5px] custom-chackbox rounded-md border-Neutral300 hover:bottom-neutral900 w-[22px] h-[22px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <svg
                    className="fill-current hidden w-[10px] h-[10px] text-white pointer-events-none "
                    version="1.1"
                    viewBox="0 0 17 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <g
                        transform="translate(-9 -11)"
                        fill="#ffffff"
                        fillRule="nonzero"
                      >
                        <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-9">
            <span className="text-Neutral500 text-xs font-semibold">
              CATEGORY
            </span>
            <div className="pt-5 flex flex-wrap items-center">
              {categories?.map((level, idx) => {
                return (
                  <button
                    checked={addfilter?.categories.includes(level)}
                    className="flex items-center justify-center text-xs font-medium border-Neutral300 cursor-pointer border rounded-[4px] bg-Neutral100 py-1 px-2 mr-2 my-1 hover:border-Neutral600 text-Neutral700 hover:text-Neutral800 transition duration-300 ease-in-out"
                    key={`category-${idx}`}
                    onClick={() => {
                      hanldleChangeFilter("categories", level);
                    }}
                    style={{
                      backgroundColor: addfilter?.categories?.includes(level)
                        ? "#5A5A66"
                        : "",
                      color: addfilter?.categories?.includes(level)
                        ? "white"
                        : "",
                    }}
                  >
                    {level}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Filter;
