import React from "react";

function LeadsPipelineIcon({
  color = "Neutral800",
  width = 24,
  className = "text-[#5A5A66]",
}) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="#5A5A66"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
        stroke="#5A5A66"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        stroke="#5A5A66"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default LeadsPipelineIcon;
