import { Menu, Transition } from "@headlessui/react";
import axios from "axios";
import { arrayMoveImmutable } from "array-move";
import { Formik } from "formik";
import { debounce } from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import * as Yup from "yup";
import ReactTooltip from "react-tooltip";
import Add from "../../assets/Images/Add.svg";
import CheckmarkIcon16 from "../../assets/Images/Checkmark-16.svg";
import CloudLoaderIcon from "../../assets/Images/Cloud-loader.svg";
import CloundSuccess from "../../assets/Images/Cloud-success-green.svg";
import edit from "../../assets/Images/Edit-gray.svg";
import EditlightIcon from "../../assets/Images/Edit-white.svg";
import TrashDark from "../../assets/Images/Trash-dark.svg";
import DeleteQuestionsButton from "../../components/Buttons/DeleteQuestionsButton";
import ClientQuestionnaireLoader from "../../components/ContentLoaders/ClientQuestionnaireLoader";
import DeleteIcon from "../../components/Icons/DeleteIcon";
import { Select } from "../../components/Inputs/CreatableSelect";
import AddQuestionnaireModal from "../../components/Modals/AddQuestionnaireModal";
import QuestionnaireFormPreview from "../../components/Modals/QuestionnaireFormPreview";
import { MODAL_COLORS } from "../../components/Modals/Templates/NewTemplateModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import TagList from "../../components/Templates/TemplateInfo/components/TagList";
import {
  AddUserCategoryAction,
  RemoverUserCategoryAction,
} from "../../store/actions/authAction";
import { GetClientDetailAction } from "../../store/actions/clientAction";
import {
  GetQuestionnaireOfClient,
  UpdateQuestionOfQuestionnaire,
  UpdateQuestionnaireForm,
} from "../../store/actions/questionnaireAction";
import {
  SaveAsTemplateActions,
  getTemplateList,
  saveUpdateTemplateActions,
} from "../../store/actions/templateAction";
import { getTagColor } from "../../utils/getTagColor";
import FollowUpEmailModal from "../../components/Modals/FollowUpEmailModal";
import DuplicateIcon from "../../components/Icons/DuplicateIcon";
import DownloadIcon from "../../assets/Images/download-icon-gray.svg";
import DashboardButton from "../../components/Buttons/DashboardButton";
import JSZip from "jszip";
import { toast } from "react-toastify";

const Drag = SortableHandle(() => (
  <div className="absolute flex items-center justify-center w-6 h-6 drags-btn top-3 right-3">
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10C2.55228 10 3 9.55228 3 9C3 8.44772 2.55228 8 2 8C1.44772 8 1 8.44772 1 9C1 9.55228 1.44772 10 2 10Z"
        fill="#C2C2CC"
        stroke="#C2C2CC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z"
        fill="#C2C2CC"
        stroke="#C2C2CC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 17C2.55228 17 3 16.5523 3 16C3 15.4477 2.55228 15 2 15C1.44772 15 1 15.4477 1 16C1 16.5523 1.44772 17 2 17Z"
        fill="#C2C2CC"
        stroke="#C2C2CC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 10C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8C8.44772 8 8 8.44772 8 9C8 9.55228 8.44772 10 9 10Z"
        fill="#C2C2CC"
        stroke="#C2C2CC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3Z"
        fill="#C2C2CC"
        stroke="#C2C2CC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 17C9.55228 17 10 16.5523 10 16C10 15.4477 9.55228 15 9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17Z"
        fill="#C2C2CC"
        stroke="#C2C2CC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
));

const SortableItem = SortableElement(
  ({
    event,
    indexNo,
    handleBlur,
    changeFormValue,
    errors,
    touched,
    changeFormStatus,
    questionnaire,
    formQuestions,
    clientId,
    dispatch,
    setSubmitting,
    setErrors,
  }) => {
    const index = indexNo;
    return (
      <div
        key={event.questionId}
        className="flex flex-col justify-between w-full max-w-[388px] pt-8 px-8 pb-5 border border-Neutral300 rounded hover:translate-y-1 transition duration-300 ease-in-out relative"
      >
        <Drag />

        {!event.isEdit ? (
          <>
            <p className="text-Neutral600 font-bold text-[10px]">Title</p>
            <h3 className="pt-1 mb-4 text-sm font-bold text-Neutral900 ">
              {event.title}
            </h3>
            <div className="relative form-overlay">
              <p className="text-Neutral600 font-bold text-[10px]">
                Description
              </p>
              <p className="pt-1 mb-4 overflow-hidden overflow-y-auto text-sm font-normal text-Neutral800">
                {event.question}
              </p>
              {event?.type === "radio" || event?.type === "checkbox" ? null : (
                <>
                  <p className="text-Neutral600 font-bold text-[10px]">
                    Placeholder Text
                  </p>
                  <p className="pt-1 mb-6 overflow-hidden overflow-y-auto text-sm italic font-normal text-Neutral800">
                    {event?.placeholder}
                  </p>
                </>
              )}
              {event?.type === "radio" || event?.type === "checkbox" ? (
                <div className="flex flex-col h-[88px] overflow-y-auto overflow-hidden mb-4 pb-[28px]">
                  {event?.[`${event?.type}_options`]?.map((item, idx) => (
                    <div
                      key={idx}
                      className="flex items-center mb-2 text-sm font-normal text-Neutral800"
                    >
                      <input
                        type={event?.type}
                        id={`title_${index}_${idx}`}
                        className="mr-2"
                        name={`questions[${index}].answer`}
                      />
                      <label htmlFor={`title_${index}_${idx}`}>
                        {item?.name}
                      </label>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <p className="text-Neutral600 font-bold text-[10px]">Title</p>
            <input
              type="text"
              id={`title_${index}`}
              focus={event.isEdit}
              className="text-Neutral800 font-medium placeholder:text-sm 5xl:placeholder:text-lg placeholder:text-Neutral500 py-3 h-[48px] px-4 mb-2 text-sm w-full focus:outline-none border-[1.5px] rounded-md border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
              placeholder={`Eg.${event.title}`}
              value={event.clone_title}
              name={`questions[${index}].clone_title`}
              onBlur={handleBlur}
              onChange={(e) =>
                changeFormValue(index, "clone_title", e.target.value)
              }
            />
            {errors?.questions?.[index]?.clone_title &&
              touched.questions?.[index]?.clone_title && (
                <p className="mt-2 text-xs font-medium text-Red400 focus-visible:outline-none">
                  {errors?.questions?.[index]?.clone_title}
                </p>
              )}
            <p className="text-Neutral600 font-bold text-[10px]">Description</p>
            <textarea
              className="text-Neutral800 resize-none font-normal placeholder:text-sm  5xl:placeholder:text-lg placeholder:text-Neutral500 py-3 px-4 mb-2 5xl:h-[116px] h-[84px] overflow-y-auto text-sm w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
              placeholder={`${event.placeholder}`}
              value={event.clone_question}
              name={`questions[${index}].clone_question`}
              onBlur={handleBlur}
              onChange={(e) =>
                changeFormValue(index, "clone_question", e.target.value)
              }
            />
            {errors?.questions?.[index]?.clone_question &&
              touched.questions?.[index]?.clone_question && (
                <p className="mt-2 text-xs font-medium text-Red400 focus-visible:outline-none">
                  {errors?.questions?.[index]?.clone_question}
                </p>
              )}
            <p className="text-Neutral600 font-bold text-[10px]">
              Placeholder Text
            </p>
            <input
              type="text"
              id={`title_${index}`}
              focus={event.isEdit}
              className="text-Neutral800 italic font-medium placeholder:text-sm 5xl:placeholder:text-lg placeholder:text-Neutral500 py-3 h-[48px] px-4 mb-2 text-sm w-full focus:outline-none border-[1.5px] rounded-md border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
              placeholder={`Eg. clientname@mail.com...`}
              value={event.clone_placeholder}
              name={`questions[${index}].clone_placeholder`}
              onBlur={handleBlur}
              onChange={(e) =>
                changeFormValue(index, "clone_placeholder", e.target.value)
              }
            />
            {errors?.questions?.[index]?.clone_placeholder &&
              touched.questions?.[index]?.clone_placeholder && (
                <p className="mt-2 text-xs font-medium text-Red400 focus-visible:outline-none">
                  {errors?.questions?.[index]?.clone_placeholder}
                </p>
              )}
            <div className="flex items-center justify-between mb-4">
              <label className="text-xs font-bold text-Neutral900">
                Required
              </label>
              <label className="flex items-center cursor-pointer select-none toggle-switch">
                <div className="relative">
                  <input
                    type="checkbox"
                    id={`isRequired_${index}`}
                    name={`questions[${index}].clone_isRequired`}
                    className="sr-only"
                    checked={event.clone_isRequired}
                    onChange={(e) =>
                      changeFormValue(
                        index,
                        "clone_isRequired",
                        e.target.checked
                      )
                    }
                  />
                  <div className="block h-[20px] switch w-[40px] rounded-full border-Neutral400 border-2 bg-Neutral100"></div>
                  <div className="dot absolute left-0 top-0 h-5 w-5 rounded-full bg-Neutral800 transition shadow-[0px_1.5px_3px_1px_rgba(0,0,0,0.2)]"></div>
                </div>
              </label>
            </div>
            {event?.type === "radio" || event?.type === "checkbox" ? (
              <div className="flex flex-col h-[88px] overflow-y-auto overflow-hidden mb-4 pb-[28px] scrollbar-w-4">
                <label className="text-xs font-bold text-Neutral900">
                  {event?.type?.charAt(0).toUpperCase() + event?.type?.slice(1)}{" "}
                  Options
                </label>
                {event?.[`clone_${event?.type}_options`]?.map((item, idx) => (
                  <div key={idx} className="flex w-full">
                    <input
                      type="text"
                      className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition mb-2"
                      name={`questions[${index}].clone_${event?.type}_options[${idx}].name`}
                      value={item?.name}
                      onChange={(e) => {
                        const cloneOptions =
                          event?.[`clone_${event?.type}_options`];
                        cloneOptions[idx].name = e.target.value;
                        cloneOptions[idx].value = `${e.target.value}-${idx}`;
                        changeFormValue(
                          index,
                          `clone_${event?.type}_options`,
                          cloneOptions
                        );
                      }}
                    />
                    {errors?.questions?.[index]?.[
                      `clone_${event?.type}_options`
                    ] &&
                      touched.questions?.[index]?.[
                      `clone_${event?.type}_options`
                      ] && (
                        <p className="mt-2 text-xs font-medium text-Red400 focus-visible:outline-none">
                          {
                            errors?.questions?.[index]?.[
                            `clone_${event?.type}_options`
                            ]
                          }
                        </p>
                      )}
                    {/* delete button */}
                    <div title="delete option">
                      <button
                        className="w-full px-2 py-2 text-sm font-medium leading-5 transition duration-300 text-Neutral800 placeholder:text-Neutral500 placeholder:text-sm 5xl:placeholder:text-lg focus:outline-none focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow"
                        onClick={() => {
                          const cloneOptions =
                            event?.[`clone_${event?.type}_options`];
                          cloneOptions.splice(idx, 1);
                          changeFormValue(
                            index,
                            `clone_${event?.type}_options`,
                            cloneOptions
                          );
                        }}
                      >
                        <span className="relative z-10">
                          <DeleteIcon color="red" />
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
                {/* Add button */}
                <button
                  className="text-Neutral100 bg-AccentRegular flex items-center justify-center text-xs rounded h-8 5xl:w-[100px] w-[80px] focus-visible:outline-purple font-semibold hover:bg-AccentMediumDark transition duration-300 mx-auto py-2"
                  type="button"
                  onClick={() => {
                    const cloneOptions =
                      event?.[`clone_${event?.type}_options`];
                    cloneOptions.push({ name: "", value: "" });
                    changeFormValue(
                      index,
                      `clone_${event?.type}_options`,
                      cloneOptions
                    );
                  }}
                >
                  Add
                </button>
              </div>
            ) : null}
          </>
        )}
        <div className="relative bottom-0 flex justify-between">
          <div className="flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[16px] 5xl:text-[22px] text-Neutral800">
            {index + 1}
          </div>
          {!event.isEdit ? (
            <div className="flex items-center">
              {!event?._id ? (
                <button
                  className="text-AccentDark bg-AccentLight flex items-center justify-center text-xs rounded h-8 5xl:w-[100px] w-[80px] border border-Neutral300 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight font-semibold hover:bg-AccentMediumLight transition duration-300"
                  type="button"
                  onClick={() => changeFormStatus(index, "delete")}
                >
                  Delete
                  <img className="ml-1" src={TrashDark} alt="" />
                </button>
              ) : (
                <DeleteQuestionsButton
                  questionId={event?._id}
                  questionnaireID={questionnaire?.data?._id}
                />
              )}
              <button
                className="text-Neutral100 bg-AccentRegular flex items-center justify-center text-xs rounded ml-3 h-8 5xl:w-[100px] w-[80px] focus-visible:outline-purple font-semibold hover:bg-AccentMediumDark transition duration-300"
                type="button"
                onClick={() => changeFormStatus(index, "edit")}
              >
                Edit
                <img className="ml-1" src={EditlightIcon} alt="" />
              </button>
            </div>
          ) : (
            <div className="flex items-center">
              <button
                className="text-AccentDark bg-AccentLight flex items-center justify-center text-xs rounded h-8 5xl:w-[100px] w-[80px] border border-Neutral300 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight font-semibold hover:bg-AccentMediumLight transition duration-300"
                type="button"
                onClick={() => changeFormStatus(index, "cancel")}
              >
                Cancel
              </button>
              <button
                className="btn button-hover large-primary-btn text-xs rounded ml-3 h-8 5xl:w-[100px] w-[80px] text-Neutral100 bg-AccentRegular drop-shadow-Texts focus:outline focus:outline-4 focus:outline-AccentLight focus-visible:outline-0 transition duration-300 ease-in-out flex items-center justify-center"
                type="button"
                disabled={
                  errors?.questions?.[index]?.clone_question ||
                  errors?.questions?.[index]?.clone_placeholder ||
                  errors?.questions?.[index]?.clone_title ||
                  errors?.questions?.[index]?.clone_radio_options ||
                  errors?.questions?.[index]?.clone_checkbox_options
                }
                onClick={() => {
                  if (!event?._id) {
                    changeFormStatus(index, "save");
                  } else {
                    setSubmitting(true);
                    const findEditQuestion = formQuestions.find((ele) => {
                      if (ele?.type === "radio" || ele?.type === "checkbox") {
                        return (
                          ele?.clone_title &&
                          ele?.clone_question &&
                          ele?.[`clone_${ele?.type}_options`]?.length &&
                          ele?.[`clone_${ele?.type}_options`]?.length >= 2 &&
                          ele?.[`clone_${ele?.type}_options`]?.every(
                            (item) => item?.name && item?.value
                          )
                        );
                      } else {
                        return (
                          ele?.clone_title &&
                          ele?.clone_question &&
                          ele?.clone_placeholder
                        );
                      }
                    });
                    if (findEditQuestion) {
                      const value = {
                        title: findEditQuestion?.clone_title,
                        question: findEditQuestion?.clone_question,
                        placeholder: findEditQuestion?.clone_placeholder,
                        question_id: findEditQuestion?._id,
                        questionnaire_id: questionnaire?.data?._id,
                        client_id: clientId,
                        isRequired: findEditQuestion?.clone_isRequired,
                        radio_options: findEditQuestion?.clone_radio_options,
                        checkbox_options:
                          findEditQuestion?.clone_checkbox_options,
                      };
                      dispatch(
                        UpdateQuestionOfQuestionnaire(
                          value,
                          setSubmitting,
                          setErrors,
                          true
                        )
                      );
                    }
                  }
                }}
              >
                {/* <span className="relative flex items-center justify-center drop-shadow-Texts"> */}
                Save
                <img className="ml-1" src={CheckmarkIcon16} alt="" />
                {/* </span> */}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({
    formQuestions,
    handleBlur,
    changeFormValue,
    errors,
    touched,
    changeFormStatus,
    questionnaire,
    clientId,
    dispatch,
    setSubmitting,
    setErrors,
    setIsOpenQuestionnaire,
  }) => {
    return (
      <ul className="flex flex-wrap justify-start items-center w-[100%] gap-10 mb-20 md:mb-10">
        {formQuestions.map((event, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              indexNo={index}
              event={event}
              handleBlur={handleBlur}
              changeFormValue={changeFormValue}
              errors={errors}
              touched={touched}
              changeFormStatus={changeFormStatus}
              questionnaire={questionnaire}
              formQuestions={formQuestions}
              clientId={clientId}
              dispatch={dispatch}
              setSubmitting={setSubmitting}
              setErrors={setErrors}
            />
          );
        })}
        <div
          className="flex flex-col justify-center items-center w-full max-w-[388px] h-[308px] pt-8 px-8 pb-5 border border-AccentRegular rounded  border-dashed cursor-pointer hover:border-AccentMediumLight transition duration-300 ease-in-out relative"
          onClick={() => setIsOpenQuestionnaire(true)}
        >
          <img className="w-[24px]" src={Add} alt="" />
        </div>
      </ul>
    );
  }
);

const RequestInfoSchema = Yup.object().shape({
  title: Yup.string().required("Form Title Required"),
  category_tags: Yup.array().required("Category Tags Required"),
  questions: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Title required").nullable(),
      question: Yup.string().required("Question required").nullable(),
      isEdit: Yup.string(),
      clone_title: Yup.string().when("isEdit", {
        is: "edit",
        then: Yup.string().required("Title required").nullable(),
        otherwise: Yup.string().nullable(),
      }),
      clone_question: Yup.string().when("isEdit", {
        is: "edit",
        then: Yup.string().required("Question required").nullable(),
        otherwise: Yup.string().nullable(),
      }),
      clone_placeholder: Yup.string().when("isEdit", {
        is: "edit",
        then: Yup.string().required("Placeholder required").nullable(),
        otherwise: Yup.string().nullable(),
      }),
      clone_radio_options: Yup.array().when("isEdit", {
        is: "edit",
        then: Yup.array().when("type", {
          is: "radio",
          then: Yup.array()
            .min(2, "Minimum 2 options required")
            .of(
              Yup.object().shape({
                name: Yup.string().required("Name required").nullable(),
                value: Yup.string().required("Value required").nullable(),
              })
            ),
        }),
      }),
      clone_checkbox_options: Yup.array().when("isEdit", {
        is: "edit",
        then: Yup.array().when("type", {
          is: "checkbox",
          then: Yup.array()
            .min(2, "Minimum 2 options required")
            .of(
              Yup.object().shape({
                name: Yup.string().required("Name required").nullable(),
                value: Yup.string().required("Value required").nullable(),
              })
            ),
          otherwise: Yup.array().nullable(),
        }),
        otherwise: Yup.array().nullable(),
      }),
    })
  ),
});

const questionsList = [
  {
    id: 1,
    title: "What does your business do?",
    placeholder: "Eg. We sell import-grade electronic products",
    question: "Your 5-second elevator pitch.",
    isEdit: false,
    type: "text",
  },
  {
    id: 2,
    title:
      "What are your most popular services or products? How many do you sell each month?",
    placeholder: "",
    question: "Our headphone range. ~120 pairs each month.",
    isEdit: false,
    type: "text",
  },
  {
    id: 3,
    title: "How much is an average client/customer worth to your business?",
    placeholder: "",
    question: "$3000 since most are returning customers",
    type: "text",
    isEdit: false,
  },
  {
    id: 4,
    title: "What is your main goal for a website visitor?",
    placeholder:
      "Eg. To place an order, schedule a call, get their email, etc.",
    question: "Add as many goals as you like, by importance.",
    type: "text",
    isEdit: false,
  },
  {
    id: 5,
    title: "What makes you unique from your competitors?",
    placeholder: "Eg. Price, services, experience, etc.?",
    question: "Be as descriptive as you need to be here.",
    type: "text",
    isEdit: false,
  },
  {
    id: 6,
    title: "How do you currently acquire new customers/clients?",
    placeholder: "Eg. Facebook, Google Ads, Instagram, SEO, etc.",
    question:
      "What type of marketing are you doing and how much are you currently paying per month?, Eg. Facebook, Google Ads, Instagram, SEO, etc.",
    type: "text",
    isEdit: false,
  },
  {
    id: 7,
    title:
      "Do you have a logo, images and/or a brand identity we could use for website/marketing purposes?",
    placeholder: "Eg. Lots and lots of mango images",
    question:
      "Upload each file with an appropriate file name. This helps us keep things organised and streamlined to avoid interrupting you :) ",
    type: "files",
    isEdit: false,
  },
  {
    id: 8,
    title: "Profile Image",
    placeholder: "",
    question:
      "Upload a picture that represents you or your business. This helps us to build a more complete profile.",
    type: "profile",
    isEdit: false,
  },
  {
    id: 9,
    title:
      "Do you currently have website hosting (including a domain) or do you need us to provide this for you?",
    placeholder:
      "Eg. I have a domain name but no hosting. Yes, please provide me with hosting.",
    question:
      "I have a domain name but no hosting. Yes, please provide me with hosting.",
    type: "text",
    isEdit: false,
  },
  {
    id: 10,
    title: "When would you like this project completed?",
    placeholder: "Eg. Middle of next month",
    question:
      "You don’t have to provide a specific date. Weekly or monthly range works too. This helps us understand the realistic scope for your project.",
    type: "text",
    isEdit: false,
  },
  {
    id: 11,
    title: "Please provide necessary passwords to platforms.",
    placeholder: "This is my Hosting/Analytics/WordPress/FTP/etc login",
    question:
      "Providing a URL to a Google Doc with your passwords is recommended",
    type: "text",
    isEdit: false,
  },
  {
    id: 12,
    title: "Is there any competitor website you like?",
    placeholder: "The layout, colours and design",
    question: "What do you like about it?",
    type: "text",
    isEdit: false,
  },
  {
    id: 13,
    title: "Is there anything else you’d like us to know about?",
    placeholder: "This is my Hosting/Analytics/WordPress/FTP/etc login",
    question: "Please include XYZ",
    type: "text",
    isEdit: false,
  },
];

function Questionnaire() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId, questionnaireId } = useParams();
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const user = useSelector((state) => state.auth.user);
  const questionnaire = useSelector(
    (state) => state.questionnaire.questionnaire
  );

  const templateList = useSelector((state) => state.template.template);
  const [formQuestions, setFormQuestions] = useState(questionsList);
  const [answered, setAnswered] = useState([]);
  const [unAnswered, setUnAnswered] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isOpenQuestionnaire, setIsOpenQuestionnaire] = useState(false);
  const [isTab, setIsTab] = useState(true);
  const [isLoadingSaveAsTemplate, setIsLoadingSaveAsTemplate] = useState(false);
  const [templateUpdate, setTemplateUpdate] = useState(false);
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);
  const [categoryTags, setCategoryTags] = useState([]);
  const [copyAnswerTooltipText, setCopyAnswerTooltipText] =
    useState("Copy Answer");
  const modalColor = MODAL_COLORS["Questionnaire"];

  useEffect(() => {
    if (!templateList?.questionnaire?.length) {
      dispatch(getTemplateList());
    }
  }, []);

  useEffect(() => {
    dispatch(GetQuestionnaireOfClient(questionnaireId));
  }, [dispatch]);

  useEffect(() => {
    if (questionnaire?.data?._id) {
      const DBQuestions = questionnaire.data.questions.map((item) => ({
        _id: item._id,
        placeholder: item.placeholder,
        title: item.title,
        type: item.type,
        question: item.question,
        isEdit: false,
        isRequired: item.is_required,
        radio_options: item.radio_options,
        checkbox_options: item.checkbox_options,
      }));
      setFormQuestions(DBQuestions);
    } else {
      setFormQuestions(questionsList);
    }
  }, [questionnaire]);

  //updated this, think we had a problem with the questionnaire not updating due to it being a shallow copy
  const changeFormStatus = (index, status) => {
    setFormQuestions(
      formQuestions.map((question, idx) => {
        if (idx === index) {
          switch (status) {
            case "edit":
              return { ...question, isEdit: true, ...cloneForEdit(question) };
            case "cancel":
              return { ...question, isEdit: false, ...resetCloneProperties() };
            case "save":
              return {
                ...question,
                isEdit: false,
                ...saveClonedProperties(question),
              };
            default:
              return question;
          }
        }
        return question;
      })
    );
    if (status === "delete") {
      setFormQuestions(formQuestions.filter((_, idx) => idx !== index));
    }
  };

  const cloneForEdit = (question) => ({
    clone_title: question.title,
    clone_question: question.question,
    clone_placeholder: question.placeholder,
    clone_isRequired: question.isRequired,
    clone_radio_options: question.radio_options,
    clone_checkbox_options: question.checkbox_options,
  });

  const resetCloneProperties = () => ({
    clone_title: null,
    clone_question: null,
    clone_placeholder: null,
    clone_isRequired: null,
    clone_radio_options: [],
    clone_checkbox_options: [],
  });

  const saveClonedProperties = (question) => ({
    title: question.clone_title,
    question: question.clone_question,
    placeholder: question.clone_placeholder,
    isRequired: question.clone_isRequired,
    radio_options: question.clone_radio_options,
    checkbox_options: question.clone_checkbox_options,
  });

  const changeFormValue = (index, field, value) => {
    setFormQuestions(
      formQuestions.map((question, idx) => {
        if (idx === index) {
          return { ...question, [field]: value };
        }
        return question;
      })
    );
  };

  function setModal(value) {
    setIsOpen(value);
  }

  const fileNameExtract = (filename) => {
    let slicedList = filename.split("/");
    return slicedList[slicedList.length - 1];
  };

  const extractFolderName = (fileUrl) => {
    let slicedList = fileUrl.split("/");
    return slicedList[slicedList.length - 2];
  };

  const [navBarText, setNavbarText] = useState("Back");
  useEffect(() => {
    if (clientId !== clientDetail.data._id) {
      dispatch(GetClientDetailAction(clientId, navigate));
    }
  }, [clientId, dispatch, navigate]);

  useEffect(() => {
    if (clientId !== clientDetail.data._id) {
      // setNavbarText('Back');
      setNavbarText("Back to Clients");
    } else {
      setNavbarText(
        `Back to ${clientDetail?.data?.business_name || clientDetail?.data?.contact_name
        } Dashboard`
      );
    }
  }, [clientDetail]);

  useEffect(() => {
    // questionnaire?.data.questions?.length
    if (questionnaire?.data?.questions) {
      const answeredQuestions = questionnaire?.data?.questions.filter(
        (item) => item?.answer
      );
      setAnswered(answeredQuestions);
    }
    if (questionnaire?.data?.questions) {
      const unansweredQuestions = questionnaire?.data?.questions.filter(
        (item) => !item?.answer
      );
      setUnAnswered(unansweredQuestions);
    }
  }, [questionnaire?.data?.questions, isTab]);

  const onSortEnd = ({ oldIndex, newIndex }, setSubmitting, setErrors) => {
    setSubmitting(true);
    const changeOrder = arrayMoveImmutable(formQuestions, oldIndex, newIndex);
    setFormQuestions(changeOrder);
    let newPayload = {
      id: questionnaire?.data?._id,
      title: questionnaire?.data?.title,
      client_id: clientId,
      questions: changeOrder,
      questionnaire: [],
      category: questionnaire?.data?.category,
    };
    dispatch(
      UpdateQuestionnaireForm(
        newPayload,
        setSubmitting,
        setErrors,
        false,
        setIsEditCategory
      )
    );
  };

  const handlePreview = () => {
    dispatch(GetQuestionnaireOfClient(questionnaireId, setModal));
  };

  const handleSaveAsTemplate = (questionnaireId) => {
    setIsLoadingSaveAsTemplate(true);
    const relaoadQuestionnaire = () => {
      dispatch(GetQuestionnaireOfClient(questionnaireId));
    };
    dispatch(
      SaveAsTemplateActions(
        { type: "Questionnaire", questionnaireId },
        setIsLoadingSaveAsTemplate,
        relaoadQuestionnaire
      )
    );
  };

  const handleUpdateTemplate = (questionnaireId, templateQuestionnaireId) => {
    setTemplateUpdate(true);
    dispatch(
      saveUpdateTemplateActions(
        {
          type: "Questionnaire",
          questionnaireId,
          templateQuestionnaireId,
        },
        setTemplateUpdate
      )
    );
  };

  useEffect(() => {
    let tags = [];

    if (user?.category?.length) {
      const userTags = user?.category?.map((item) => {
        return { value: item, label: item };
      });
      tags = [...tags, ...userTags];
    }

    tags = tags?.reduce((acc, current) => {
      const x = acc.find(
        (item) => item?.value?.toLowerCase() === current?.value?.toLowerCase()
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const optionsWithColors = tags.map((option, i) => {
      const { bgColor: optionColor, text: optionText } = getTagColor(
        modalColor,
        i,
        true
      );

      const { bgColor: labelColor, text: labelText } = getTagColor(
        modalColor,
        i,
        true
      );
      return {
        ...option,
        color: optionColor,
        text: optionText,
        labelColor,
        labelText,
      };
    });

    setCategoryTags(optionsWithColors);
  }, [user]);

  const handleRemove = async (data) => {
    const removedCategory = await dispatch(RemoverUserCategoryAction(data));

    if (removedCategory?.data) {
      setCategoryTags(categoryTags.filter((e) => e !== data));
    }
  };

  const handleCreateNewTag = async (inputValue) => {
    await dispatch(AddUserCategoryAction(inputValue));
  };

  const handleCopyAnswer = (answer) => {
    navigator.clipboard.writeText(answer);

    setCopyAnswerTooltipText("Copied Answer!");

    setTimeout(() => {
      setCopyAnswerTooltipText("Copy Answer");
    }, 3000);
  };

  const handleExportCSV = () => {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(answered[0]);
    const formattedHeader = header.map((h) => `"${h.replace(/"/g, '""')}"`);

    const csv = [
      formattedHeader.join(","),
      ...answered.map((row) =>
        header
          .map((fieldName) => {
            const value = row[fieldName];
            if (typeof value === "object") {
              return `"${JSON.stringify(value, replacer).replace(/"/g, '""')}"`;
            }
            return `"${String(value).replace(/"/g, '""')}"`;
          })
          .join(",")
      ),
    ].join("\r\n");

    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.setAttribute("href", url);

    a.setAttribute("download", `answers.csv`);
    a.click();
  };

  const getBase64Image = async (url) => {
    const data = await axios(url);
    return data.data.data;
  };

  const handleImageDownload = async (e, url, fileName, folder) => {
    e.stopPropagation();
    e.preventDefault();

    if (url.startsWith("http")) {
      try {
        const blobUrl = await getBase64Image(
          `/api/v1/proposal/get-proposals-documents?filename=${folder}/${fileName}`
        );

        const a = document.createElement("a");
        a.download = fileName;
        a.href = blobUrl;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        toast.error("Image download failed");
      }
    }
  };

  const handleSingleFileDownload = async (e, url, fileName) => {
    e.stopPropagation();
    e.preventDefault();

    if (url.startsWith("http")) {
      try {
        const response = await fetch(url, {
          method: "GET",
          mode: "cors",
          cache: "default",
        });

        if (!response.ok) throw new Error("Network response was not ok.");

        const blob = await response.blob();

        const blobUrl = window.URL.createObjectURL(blob);

        const a = document.createElement("a");

        a.download = fileName;
        a.href = blobUrl;

        document.body.appendChild(a);
        a.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(blobUrl);
          document.body.removeChild(a);
        }, 100);
      } catch (err) {
        toast.error("File download failed");
      }
    }
  };

  const handleMultiplePDFDownload = async (item) => {
    try {
      const zip = new JSZip();

      const filePromises = item.files_path.map(async (url, index) => {
        const fileName = fileNameExtract(item.files[index]);

        try {
          const response = await fetch(url, {
            method: "GET",
            mode: "cors",
          });

          if (!response.ok) {
            throw new Error(
              `Failed to fetch ${fileName}: Network response was not ok`
            );
          }

          const blob = await response.blob();
          return { blob, fileName };
        } catch (err) {
          return null;
        }
      });

      const files = await Promise.all(filePromises);

      files.forEach((file) => {
        if (file) zip.file(file.fileName, file.blob);
      });

      zip.generateAsync({ type: "blob" }).then((content) => {
        const blobUrl = window.URL.createObjectURL(content);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = "downloaded_files.zip";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
      });
    } catch (err) {
      toast.error("File download failed");
    }
  };

  return (
    <>
      <div>
        <AdminNavbar
          link={
            navBarText === "Back to Clients"
              ? "/admin/clients"
              : `/admin/clients/manage/${clientId}`
          }
          title="Questionnaire Builder"
          backLink={navBarText}
          subtitle="Build and send to your potential client to complete online."
        />

        {questionnaire?.isLoading ? (
          <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
            <ClientQuestionnaireLoader />
          </div>
        ) : questionnaire?.data?.answered_questoins ? (
          <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
            <div className="pt-[20px] max-w-[387px] 5xl:max-w-[446px] px-8 pb-8 mt-8 border border-Neutral400 rounded-[4px]">
              <p className="text-AccentMediumDark text-xs flex items-center pb-[17px]  relative after:contents-[''] after:w-3 after:h-3 after:bg-Neutral300 after:absolute glow-animation after:rounded-full after:left-[-2px] after:duration-300">
                <span className="z-10 inline-block w-2 h-2 mr-2 rounded-full bg-questionnaire"></span>{" "}
                Last updated{" "}
                <b className="ml-[3px]">
                  {moment(questionnaire?.data?.updatedAt).fromNow()}
                </b>
              </p>
              <h2 className="source-code-pro text-[28px] font-[900] text-Neutral900">
                {questionnaire?.data?.answered_questoins
                  ? questionnaire?.data?.answered_questoins
                  : "!"}
                /
                {questionnaire?.data?.total_questoins
                  ? questionnaire?.data?.total_questoins
                  : "!"}
              </h2>
              <p className="pt-2 text-Neutral800 uppercase text-xs font-[500] tracking-[0.08em]">
                Questions answered
              </p>
            </div>
            <div className="mt-16">
              <div className="w-full flex justify-between items-center max-w-[870px] 5xl:max-w-[940px] pr-12 mb-8 mt-[34px]">
                <div className="bg-white border rounded-full cursor-pointer text-Neutral500 flex 5xl:max-w-[240px] max-w-[192px] w-full h-[32px] 5xl:h-12">
                  <div
                    onClick={() => setIsTab(true)}
                    className={`transition duration-300 flex items-center justify-center w-full 5xl:max-w-[121px] max-w-[96px] min-w-[96px] 5xl:min-w-[100px] text-center rounded-full leading-4 transform text-xs ease-in-out mt-[-1px] mb-[-1px] ml-[-1px] ${isTab
                      ? "checkmarkicon text-Neutral100 mt-[-1px] mb-[-1px] ml-[-1px] drop-shadow-Texts font-semibold"
                      : ""
                      }`}
                  >
                    ANSWERED
                  </div>
                  <div
                    onClick={() => setIsTab(false)}
                    className={`transition duration-300 flex items-center justify-center w-full 5xl:max-w-[121px] max-w-[96px] min-w-[96px] 5xl:min-w-[100px] text-center rounded-full transform text-xs ease-in-out ${!isTab
                      ? "checkmarkicon text-Neutral100 mt-[-1px] mb-[-1px] ml-[-1px] drop-shadow-Texts font-semibold"
                      : ""
                      }`}
                  >
                    PENDING
                  </div>
                </div>

                <DashboardButton
                  title="Export Answers As CSV"
                  onClick={handleExportCSV}
                  variant="secondary"
                  limitWidth={false}
                />
              </div>

              <div className="flex max-w-[870px]  5xl:max-w-[940px] flex-wrap">
                {isTab && answered && (
                  <>
                    {answered?.length ? (
                      answered.map((item, index) => {
                        return (
                          <div
                            key={item._id + index}
                            className="w-full max-w-[387px] 5xl:max-w-[446px] px-5 py-6 border border-Neutral400 rounded-[4px] mb-10 max-h-[400px] flex flex-col mr-12"
                          >
                            <div className="flex items-start justify-between">
                              <p className="pr-2 font-[700] text-sm text-Neutral900">
                                {item?.title}
                              </p>
                              <div
                                className="flex items-center justify-start cursor-pointer"
                                key={
                                  copyAnswerTooltipText === "Copied Answer!"
                                    ? "copied-answer"
                                    : "copy-answer"
                                }
                              >
                                <div className="flex items-center justify-center w-[28px] min-w-[28px] font-medium h-[28px] border border-Neutral400 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] text-Neutral800">
                                  {index + 1}
                                </div>
                                <div
                                  className={`ml-3 ${copyAnswerTooltipText === "Copied Answer!"
                                    ? "text-Green900 "
                                    : ""
                                    }`}
                                  data-for="copyAnswerTooltip"
                                  data-tip={
                                    item.type === "text"
                                      ? copyAnswerTooltipText
                                      : "Download Files"
                                  }
                                  {...(item.type === "text" && {
                                    onClick: () =>
                                      handleCopyAnswer(item.answer),
                                  })}
                                >
                                  {item.type === "text" ? (
                                    <DuplicateIcon />
                                  ) : item.type === "files" ? (
                                    <div
                                      onClick={() =>
                                        handleMultiplePDFDownload(item)
                                      }
                                    >
                                      <svg
                                        width="13"
                                        height="13"
                                        viewBox="0 0 13 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M11.6666 8.11206V10.4824C11.6666 10.7968 11.5417 11.0982 11.3194 11.3205C11.0972 11.5427 10.7957 11.6676 10.4814 11.6676H2.1851C1.87077 11.6676 1.56931 11.5427 1.34705 11.3205C1.12478 11.0982 0.999916 10.7968 0.999916 10.4824V8.11206"
                                          stroke="#5A5A66"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M3.37 5.14795L6.33296 8.11091L9.29592 5.14795"
                                          stroke="#5A5A66"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M6.33325 8.11087V0.999756"
                                          stroke="#5A5A66"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>

                                <ReactTooltip
                                  place="top"
                                  id="copyAnswerTooltip"
                                  effect="solid"
                                />
                              </div>
                            </div>
                            <p className="font-[400] text-sm text-Neutral800 py-4">
                              {item?.question}
                            </p>
                            {item.type === "text" ? (
                              <p className="flex-1 overflow-y-auto text-sm italic leading-6 text-Neutral700 questionnaire-text">
                                {item?.answer}
                              </p>
                            ) : null}
                            {item.type === "profile" || item?.type === "image"
                              ? item.files_path.map((fileItem, innerIndex) => {
                                const file = item.files[innerIndex];

                                const fileName = fileNameExtract(file);
                                const folder = extractFolderName(file);

                                return (
                                  <a
                                    key={innerIndex}
                                    href={fileItem}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="border-[1px] rounded p-2 px-3 border-Neutral400 hover:border-Neutral800 border-dashed flex justify-between w-[300px]"
                                  >
                                    <span className="flex items-center">
                                      <img
                                        src={fileItem}
                                        alt=""
                                        height={150}
                                        width={100}
                                      />
                                      <p
                                        className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden`}
                                      >
                                        {fileName}
                                      </p>
                                      <img
                                        src={DownloadIcon}
                                        alt="Download"
                                        className="self-start"
                                        onClick={(e) =>
                                          handleImageDownload(
                                            e,
                                            fileItem,
                                            fileName,
                                            folder
                                          )
                                        }
                                      />
                                    </span>
                                  </a>
                                );
                              })
                              : null}
                            {item.type === "files" || item?.type === "file"
                              ? item.files_path.map((fileItem, innerIndex) => {
                                const fileName = fileNameExtract(
                                  item.files[innerIndex]
                                );

                                return (
                                  <>
                                    <p className=" uppercase text-[10px] font-semibold text-Neutral800 pb-2 ">
                                      Media
                                    </p>
                                    <a
                                      key={innerIndex}
                                      href={fileItem}
                                      rel="noreferrer"
                                      target="_blank"
                                      className="bg-Neutral100 border-[1px] rounded-[4.8px] p-2 px-3 border-Neutral400 hover:border-Neutral800 border-dashed flex justify-between w-[300px] transition-all duration-300"
                                    >
                                      <span className="flex items-center justify-between w-full">
                                        <p
                                          className={`text-Neutral600 ml-1 capitalize text-xs font-medium text-ellipsis overflow-hidden`}
                                        >
                                          {fileName}
                                        </p>
                                        <img
                                          src={DownloadIcon}
                                          alt="Download"
                                          onClick={(e) =>
                                            handleSingleFileDownload(
                                              e,
                                              fileItem,
                                              fileName
                                            )
                                          }
                                        />
                                      </span>
                                    </a>
                                  </>
                                );
                              })
                              : null}
                            {item.type === "checkbox" ||
                              item.type === "radio" ? (
                              <div className="flex flex-col overflow-y-auto overflow-hidden mb-4 pb-[28px]">
                                {item?.[`${item.type}_options`]?.map(
                                  (option, innerIndex) => {
                                    return (
                                      <div
                                        key={innerIndex}
                                        className="flex items-center mb-2 text-sm font-normal text-Neutral800"
                                      >
                                        <input
                                          type={item.type}
                                          className="mr-2"
                                          checked={option.is_selected}
                                          disabled
                                        />
                                        <p className="capitalize">
                                          {option.value}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            ) : null}
                          </div>
                        );
                      })
                    ) : (
                      <p className="text-sm font-normal text-Neutral800">
                        Client does not answered any questions yet.
                      </p>
                    )}
                  </>
                )}
                {!isTab && unAnswered && (
                  <>
                    {unAnswered?.length ? (
                      unAnswered.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="pt-[45px] max-w-[387px] 5xl:max-w-[446px] px-8 pb-[45px] border border-Neutral400 rounded-[4px] w-[100%] mb-10 mr-10"
                          >
                            <div className="flex items-center justify-between">
                              <p className="pr-2 font-[700] text-sm text-Neutral900">
                                {item?.title}
                              </p>
                              <div className="flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] text-Neutral800">
                                {index + 1}
                              </div>
                            </div>
                            <p className="font-[400] text-sm text-Neutral800 pt-7 pb-7 min-h-[124px]">
                              {item?.question}
                            </p>
                            {item.type === "radio" ||
                              item.type === "checkbox" ? (
                              <div className="flex flex-col overflow-y-auto overflow-hidden mb-4 pb-[28px]">
                                {item?.[`${item.type}_options`]?.map(
                                  (option, innerIndex) => {
                                    return (
                                      <div
                                        key={innerIndex}
                                        className="flex items-center mb-2 text-sm font-normal text-Neutral800"
                                      >
                                        <input
                                          type={item.type}
                                          className="mr-2"
                                          checked={option.is_selected}
                                          disabled
                                        />
                                        <p className="capitalize">
                                          {option.value}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            ) : null}
                          </div>
                        );
                      })
                    ) : (
                      <p className="pl-1 text-sm font-normal text-Neutral800">
                        No pending questions.
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
            <Formik
              initialValues={{
                title: questionnaire?.data?.title
                  ? questionnaire?.data?.title
                  : "",
                client_id: clientId,
                questions: formQuestions,
                category_tags: questionnaire?.data?.category
                  ? questionnaire?.data?.category?.map((option, i) => {
                    const { bgColor: optionColor, text: optionText } =
                      getTagColor(modalColor, i, true);

                    const { bgColor: labelColor, text: labelText } =
                      getTagColor(modalColor, i, true);
                    return {
                      value: option?.name,
                      label: option?.name,
                      color: optionColor,
                      text: optionText,
                      labelColor,
                      labelText,
                    };
                  })
                  : [],
              }}
              enableReinitialize
              validationSchema={RequestInfoSchema}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                let newPayload = {
                  id: questionnaire?.data?._id,
                  title: values.title,
                  client_id: values.client_id,
                  questions: [],
                  questionnaire: [],
                  category: values?.category_tags?.map((item) => {
                    return {
                      name: item.value,
                      color: {
                        color: item.color,
                        text: item.text,
                        labelColor: item.labelColor,
                        labelText: item.labelText,
                      },
                    };
                  }),
                };
                values.questions.map((item) => {
                  let tempQuestion = {
                    question: item.question,
                    title: item.title,
                    type: item.type,
                    placeholder: item.placeholder,
                    _id: item._id,
                  };
                  newPayload.questions.push(tempQuestion);
                });
                setSubmitting(true);
                if (questionnaire?.data?._id) {
                  dispatch(
                    UpdateQuestionnaireForm(
                      newPayload,
                      setSubmitting,
                      setErrors,
                      true,
                      setIsEditCategory
                    )
                  );
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
                setErrors,
                setSubmitting,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="pt-4 pb-6 md:pt-12">
                    <div className="flex items-center justify-between">
                      <h5 className="text-Neutral700 text-xs font-semibold tracking-[0.08em]">
                        FORM TITLE
                      </h5>
                    </div>
                    <div className="flex flex-col mt-2 mb-2 sm:mt-4 sm:flex-row">
                      <div className="space-y-7">
                        <div className="w-full 5xl:max-w-[400px] max-w-[320px] mb-6 sm:mb-0">
                          <input
                            type="text"
                            className={`text-Neutral800 font-medium placeholder:text-sm placeholder:text-Neutral500 5xl:placeholder:text-lg py-3 px-4 text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition
                        ${errors.title && touched.title
                                ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                                : ""
                              }`}
                            name="title"
                            value={values.title}
                            placeholder="Eg. Info Request"
                            onChange={handleChange}
                            onBlur={debounce((e) => {
                              handleBlur(e);
                              if (
                                e.target.value !== questionnaire?.data?.title &&
                                e.target.value !== ""
                              ) {
                                handleSubmit();
                              }
                            }, 1000)}
                          />
                          {errors.title && touched.title && (
                            <p className="mt-2 text-xs font-medium text-Red400 focus-visible:outline-none">
                              {errors.title}
                            </p>
                          )}
                          <p className="mt-2 ml-1 text-xs font-medium text-Neutral700">
                            This will be the header of the questionnaire.{" "}
                          </p>
                        </div>

                        <div className="!mb-5">
                          <div className="flex justify-between items-center max-w-[135px]">
                            <h5 className="text-Neutral700 text-xs font-semibold tracking-[0.08em] uppercase">
                              category tags
                            </h5>

                            <Menu
                              as="div"
                              className="relative flex items-center justify-center"
                            >
                              <Menu.Button className="relative w-full focus-visible:outline-none popup-btn">
                                <button
                                  type="button"
                                  className="flex items-center justify-center text-center cursor-pointer client-btn-hover"
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.9287 12.4988C12.2049 12.4988 12.4287 12.2749 12.4287 11.9988C12.4287 11.7226 12.2049 11.4988 11.9287 11.4988C11.6526 11.4988 11.4287 11.7226 11.4287 11.9988C11.4287 12.2749 11.6526 12.4988 11.9287 12.4988Z"
                                      fill="#9C9CA6"
                                      stroke="#9C9CA6"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M11.9286 8.9989C12.2047 8.9989 12.4286 8.77504 12.4286 8.4989C12.4286 8.22276 12.2047 7.9989 11.9286 7.9989C11.6524 7.9989 11.4286 8.22276 11.4286 8.4989C11.4286 8.77504 11.6524 8.9989 11.9286 8.9989Z"
                                      fill="#9C9CA6"
                                      stroke="#9C9CA6"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M11.9287 15.9988C12.2049 15.9988 12.4287 15.7749 12.4287 15.4988C12.4287 15.2226 12.2049 14.9988 11.9287 14.9988C11.6526 14.9988 11.4287 15.2226 11.4287 15.4988C11.4287 15.7749 11.6526 15.9988 11.9287 15.9988Z"
                                      fill="#9C9CA6"
                                      stroke="#9C9CA6"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                              </Menu.Button>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="origin-top-right z-20 absolute -right-[3px] w-[100px] rounded-lg client-edite-view-shadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none px-2 top-0 pt-2 pb-2 hover:bg-Neutral200 cursor-pointer transition-all duration-300 ease-in-out">
                                  <div
                                    className="mx-2"
                                    onClick={() => setIsEditCategory(true)}
                                  >
                                    <p className="flex">
                                      <img src={edit} alt="" />{" "}
                                      <span className="px-2 text-xs text-Neutral900">
                                        Edit
                                      </span>
                                    </p>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                          <div className="max-w-[300px] pt-[6px]">
                            {isEditCategory ? (
                              <div className="mt-2">
                                <Select
                                  id="category_tags"
                                  name="category_tags"
                                  isMulti
                                  isSearchable
                                  isClearable={false}
                                  options={categoryTags}
                                  handleCreateNewTag={handleCreateNewTag}
                                  handleRemove={handleRemove}
                                  value={values.category_tags}
                                  handleBlur={debounce((e) => {
                                    handleBlur(e);
                                    if (
                                      JSON.stringify(
                                        values.category_tags?.map(
                                          (ele) => ele?.value
                                        )
                                      ) !==
                                      JSON.stringify(
                                        questionnaire?.data?.category.map(
                                          (ele) => ele?.name
                                        )
                                      )
                                    ) {
                                      handleSubmit();
                                    }
                                    setIsEditCategory(false);
                                  }, 1000)}
                                  {...{ setFieldValue, modalColor }}
                                />
                                {errors.category_tags &&
                                  touched.category_tags && (
                                    <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                      {errors.category_tags}
                                    </p>
                                  )}
                              </div>
                            ) : (
                              <TagList
                                color={"Blue"}
                                tags={values?.category_tags?.map(
                                  (ele) => ele?.value
                                )}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-[234px] flex flex-col gap-2 ml-0 sm:ml-4">
                        {questionnaire?.data?.title &&
                          !questionnaire?.data?.template_to && (
                            <button
                              type="button"
                              onClick={() =>
                                handleSaveAsTemplate(questionnaire?.data?._id)
                              }
                              disabled={isLoadingSaveAsTemplate}
                              className="button-hover large-primary-btn px-6 py-3 w-full h-[48px] bg-AccentRegular rounded flex items-center justify-center gap-2 drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out"
                            >
                              {isLoadingSaveAsTemplate ? (
                                <div className="flex justify-center">
                                  <div className="pr-2 white dot-falling"></div>
                                </div>
                              ) : (
                                <>
                                  <svg
                                    width="25"
                                    height="24"
                                    viewBox="0 0 25 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M19.5 21H5.5C4.96957 21 4.46086 20.7893 4.08579 20.4142C3.71071 20.0391 3.5 19.5304 3.5 19V5C3.5 4.46957 3.71071 3.96086 4.08579 3.58579C4.46086 3.21071 4.96957 3 5.5 3H16.5L21.5 8V19C21.5 19.5304 21.2893 20.0391 20.9142 20.4142C20.5391 20.7893 20.0304 21 19.5 21Z"
                                      stroke="white"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M17.5 21V13H7.5V21"
                                      stroke="white"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M7.5 3V8H15.5"
                                      stroke="white"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <span className="text-Neutral000 text-[12px] text-center font-bold">
                                    SAVE AS TEMPLATE
                                  </span>
                                </>
                              )}
                            </button>
                          )}
                        {!templateUpdate ? (
                          questionnaire?.data?.template_to && (
                            <button
                              type="button"
                              onClick={() =>
                                handleUpdateTemplate(
                                  questionnaire?.data?._id,
                                  questionnaire?.data?.template_to
                                )
                              }
                              className="text-AccentRegular text-[12px] font-bold"
                            >
                              Click to Update Template
                            </button>
                          )
                        ) : (
                          <button
                            type="button"
                            className="text-Green500 text-[12px] font-bold flex items-center"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.5 9L10.2812 15.75L7 12.6818"
                                stroke="#34B288"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Saved as Template
                          </button>
                        )}

                        <div className="flex w-full gap-2">
                          <button
                            type="button"
                            onClick={(e) => {
                              resetForm();
                              setTimeout(() => {
                                setFormQuestions([...questionsList]);
                              }, 200);
                            }}
                            className="px-6	py-3 h-[48px] bg-Neutral200 rounded flex justify-center items-center grow text-AccentRegular text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out hover:bg-Neutral400"
                          >
                            RESET
                          </button>
                          <button
                            type="button"
                            onClick={() => handlePreview()}
                            className="px-6	py-3 h-[48px] bg-Neutral200 rounded flex justify-center items-center grow text-AccentRegular text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out hover:bg-Neutral400"
                          >
                            VIEW
                          </button>
                        </div>

                        <button
                          type="button"
                          onClick={() => setIsOpenEmailModal(true)}
                          className="bg-AccentRegular text-Neutral000 flex justify-center items-center uppercase py-3 md:py-4 text-[10px] md:text-xs font-bold leading-4 text-center rounded min-w-[100px] md:min-w-[142px] w-full"
                        >
                          SEND AS EMAIL
                        </button>

                        <div className="w-full">
                          {isSubmitting ? (
                            <span className="text-[#757580] text-xs font-bold leading-4 ml-2.5 flex items-center">
                              <img
                                src={CloudLoaderIcon}
                                alt=""
                                className="mr-1 w-[35px]"
                              />
                              Saving...
                            </span>
                          ) : (
                            <span className="text-Green500 text-xs font-bold leading-4 ml-2.5 flex items-center">
                              <img
                                src={CloundSuccess}
                                alt=""
                                className="mr-1 w-[35px]"
                              />
                              Saved
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="pt-[60px]">
                      <h5 className="text-Neutral700 text-xs font-semibold tracking-[0.08em]">
                        FORM QUESTIONS
                      </h5>
                      <div className="mt-6 h-[480px]">
                        <div className="flex justify-between w-full max-w-[816px] flex-wrap">
                          {/* General */}

                          {/* step 1 */}
                          {formQuestions?.length ? (
                            <SortableList
                              formQuestions={formQuestions}
                              onSortEnd={({ oldIndex, newIndex }) => {
                                onSortEnd(
                                  { oldIndex, newIndex },
                                  setSubmitting,
                                  setErrors
                                );
                              }}
                              handleBlur={handleBlur}
                              changeFormValue={changeFormValue}
                              errors={errors}
                              touched={touched}
                              changeFormStatus={changeFormStatus}
                              questionnaire={questionnaire}
                              clientId={clientId}
                              dispatch={dispatch}
                              setSubmitting={setSubmitting}
                              setErrors={setErrors}
                              axis="xy"
                              useDragHandle
                              helperClass="questionnaire-wrapper drag-btn"
                              setIsOpenQuestionnaire={setIsOpenQuestionnaire}
                            />
                          ) : (
                            <div className="flex flex-col justify-center items-center w-full max-w-[388px] h-[308px] pt-8 px-8 pb-5 border border-AccentRegular rounded mb-10 border-dashed	cursor-pointer">
                              <img
                                className="w-[24px]"
                                src={Add}
                                alt=""
                                onClick={() => setIsOpenQuestionnaire(true)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>

            <QuestionnaireFormPreview
              modalIsOpen={modalIsOpen}
              setModal={setModal}
              clientDetail={clientDetail?.data}
            />
            <AddQuestionnaireModal
              isOpenQuestionnaire={isOpenQuestionnaire}
              setIsOpenQuestionnaire={setIsOpenQuestionnaire}
              questionnaire={questionnaire}
              formQuestions={formQuestions}
              setFormQuestions={setFormQuestions}
            />
            <FollowUpEmailModal
              isOpen={isOpenEmailModal}
              setIsOpen={setIsOpenEmailModal}
              type="Questionnaire"
              data={clientDetail?.data}
              id={questionnaire?.data?._id}
              selectedData={questionnaire?.data}
              IsDashboard="detailsPage"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Questionnaire;
