import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Assets from "../../../assets/Images/Asset.svg";
import BagWhite from "../../../assets/Images/Bag-white.svg";
import Folder from "../../../assets/Images/Folder.svg";
import QuestionWhite from "../../../assets/Images/Question-white.svg";
import InvoiceWhite from "../../../assets/Images/Invoice-white.svg";
import { useNewTemplateModal } from "../../../hooks/useNewTemplateModal";
import {
  GetProposalList,
  GetProposalListByClientId,
} from "../../../store/actions/proposalAction";
import { getInvoiceListByClientId } from "../../../store/actions/invoicesAction";
import ClientAssetsManagementLoader from "../../ContentLoaders/ClientAssetsManagementLoader";
import { NewEntityTemplateModal } from "../../Modals/Templates/NewEntityTemplateModal";
import NewTemplateModal from "../../Modals/Templates/NewTemplateModal";
import { SelectTemplate } from "../../Modals/Templates/SelectTemplate";
import UploadExistingProposalModal from "../../Modals/UploadExistingProposalModal";
import UploadExistingProposalSpecificClientModal from "../../Modals/UploadExistingProposalSpecificClientModal";

import UploadExistingInvoiceModal from "../../Modals/UploadExistingInvoiceModal";
import UploadExistingInvoiceSpecificClientModal from "../../Modals/UploadExistingInvoiceSpecificClientModal";

import ViewProposalList from "../../Modals/ViewProposalList";
import ViewQuestionnaireList from "../../Modals/ViewQuestionnaireList";
import SectionTitle from "../../common/typography/SectionTitle";
import Clipboard from "../../../assets/Images/Clipboard.svg";
import OpenProposalModal from "../../Modals/OpenProposalModal";
import OpenInvoiceModal from "../../Modals/OpenInvoiceModal";
import ViewInvoiceList from "../../Modals/ViewInvoiceList";
import { getFullClientList } from "../../../store/actions/clientAction";
import FollowUpEmailModal from "../../Modals/FollowUpEmailModal";

function AssetsAndManagementSection() {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const [openProposalModal, setOpenProposalModal] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [isOpenProposalModal, setIsOpenProposalModal] = useState(false);
  const [isOpenInvoiceModal, setIsOpenInvoiceModal] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [viewProposalListIsOpen, setViewProposalListIsOpen] = useState(false);
  const [viewQuestionnaireListIsOpen, setViewQuestionnaireListIsOpen] =
    useState(false);
  const [viewInvoiceListIsOpen, setViewInvoiceListIsOpen] = useState(false);
  const [
    uploadExistingProposalModalIsOpen,
    setUploadExistingProposalModalIsOpen,
  ] = useState(false);
  const [
    uploadExistingInvoiceModalIsOpen,
    setUploadExistingInvoiceModalIsOpen,
  ] = useState(false);
  const [proposal, setProposal] = useState("");
  const [invoice, setInvoice] = useState("");
  const [createBuildInvoiceModalIsOpen, setCreateBuildInvoiceModalIsOpen] =
    useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [seletctedEmailId, setSeletctedEmailId] = useState("");
  const [newTemplateModalIsOpen, setNewTemplateModalIsOpen] = useState(false);
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const allClients = useSelector((state) => state.client.fullClientList);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    if (!allClients?.data?.length) {
      dispatch(getFullClientList());
    }
  }, [dispatch]);

  const {
    handleOpenSelectTemplateModal,
    templateModalIsOpen,
    selectTemplateModalIsOpen,
    setTemplateModalIsOpen,
    setSelectTemplateModalIsOpen,
  } = useNewTemplateModal();

  const getProposalList = async () => {
    dispatch(GetProposalListByClientId(clientId));
  };

  useEffect(() => {
    const container = document.querySelector(".scrollable");

    const checkScrollPosition = () => {
      if (container) {
        setAtStart(container.scrollLeft === 0);
        setAtEnd(
          container.scrollLeft >=
            container.scrollWidth - container.offsetWidth - 10
        );
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      checkScrollPosition();
    });

    if (container) {
      container.addEventListener("scroll", checkScrollPosition);
      resizeObserver.observe(container);
      checkScrollPosition(); // Initial check
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", checkScrollPosition);
      }
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <section className="mb-16">
      <div className="flex items-center mb-5 px-2">
        <SectionTitle text="Assets & Client Management" />

        {/* <TwoArrowsControls
          scrollLeft={scrollLeft}
          scrollRight={scrollRight}
          atStart={atStart}
          atEnd={atEnd}
        /> */}
      </div>
      {/* overflow-visible */}
      {clientDetail?.isLoading ? (
        <ClientAssetsManagementLoader />
      ) : (
        <div className="flex w-full py-4 flex-wrap flex-col md:flex-row justify-between">
          {/* ASSETS */}
          <div className="w-full md:w-1/2 lg:w-[calc(33.33%_-_16px)] px-[22px] py-6 rounded shadow-[0_4px_15px_rgba(0,0,0,0.08)] block relative mb-4">
            <div className="px-2 mb-7">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-Neutral900 uppercase text-sm font-bold tracking-[0.02em] leading-[19px]">
                  Assets
                </h3>
                <div className="w-6 h-6 rounded-full bg-Neutral800 flex items-center justify-center">
                  <img
                    className="w-[18px] h-[18px] block"
                    src={Assets}
                    alt=""
                  />
                </div>
              </div>
              <p className="text-Neutral800 text-sm font-normal leading-[150%] min-h-[63px]">
                Everything you need for your client's project including brand
                guides, images, design files, and passwords.
              </p>
            </div>
            <Link
              to="assets"
              className="w-full flex justify-center items-center rounded bg-Neutral300 py-3 text-xs font-bold text-Neutral800 leading-[140%] tracking-[0.04em] uppercase"
            >
              Manage
            </Link>
          </div>

          {/* DOCUMENTS */}
          <div className="w-full md:w-1/2 lg:w-[calc(33.33%_-_16px)] px-[22px] py-6 rounded shadow-[0_4px_15px_rgba(0,0,0,0.08)] block relative mb-4">
            <div className="px-2 mb-7">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-Neutral900 uppercase text-sm font-bold tracking-[0.02em] leading-[19px]">
                  Documents
                </h3>
                <div className="w-6 h-6 rounded-full bg-Neutral800 flex items-center justify-center">
                  <img
                    className="w-[18px] h-[18px] block"
                    src={Folder}
                    alt="Documents"
                  />
                </div>
              </div>
              <p className="text-Neutral800 text-sm font-normal leading-[150%] min-h-[63px]">
                All the client admin files including reports, invoices,
                contracts, and any other documentation you may need.
              </p>
            </div>
            <Link
              to="documents"
              className="w-full flex justify-center items-center rounded bg-Neutral300 py-3 text-xs font-bold text-Neutral800 leading-[140%] tracking-[0.04em] uppercase"
            >
              Manage
            </Link>
          </div>

          {/* PROPOSAL */}
          {clientDetail?.data?.is_admin ||
          clientDetail?.data?.is_manager === true ? (
            <div className="w-full md:w-1/2 lg:w-[calc(33.33%_-_16px)] px-[22px] py-6 rounded shadow-[0_4px_15px_rgba(0,0,0,0.08)] block relative mb-4">
              <div className="px-2 mb-7">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-Neutral900 uppercase text-sm font-bold tracking-[0.02em] leading-[19px]">
                    Proposals
                  </h3>
                  <div className="w-6 h-6 rounded-full bg-Neutral800 flex items-center justify-center">
                    <img
                      className="w-[18px] h-[18px] block"
                      src={BagWhite}
                      alt="Proposals"
                    />
                  </div>
                </div>
                <p className="text-Neutral800 text-sm font-normal leading-[150%] min-h-[63px]">
                  Customize a proposal and get it digitally signed via a URL.
                </p>
              </div>
              <div className="flex justify-center items-center gap-x-2 mb-0">
                <button
                  onClick={() => {
                    setOpenProposalModal(true);
                    setModalType("Proposal");
                  }}
                  className="w-full flex justify-center items-center rounded bg-Neutral800 text-Neutral000  py-3 text-xs font-bold leading-[140%] tracking-[0.04em] uppercase"
                >
                  Create
                </button>
                <button
                  onClick={() => {
                    setViewProposalListIsOpen(true);
                  }}
                  className="w-full flex justify-center items-center rounded bg-Neutral300 py-3 text-xs font-bold text-Neutral800 leading-[140%] tracking-[0.04em] uppercase"
                >
                  View
                </button>
              </div>
            </div>
          ) : null}

          {/* QUESTIONNAIRE */}
          {clientDetail?.data?.is_admin ||
          clientDetail?.data?.is_manager === true ? (
            <div className="w-full md:w-1/2 lg:w-[calc(33.33%_-_16px)] px-[22px] py-6 rounded shadow-[0_4px_15px_rgba(0,0,0,0.08)] block relative mb-4">
              <div className="px-2 mb-7">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-Neutral900 uppercase text-sm font-bold tracking-[0.02em] leading-[19px]">
                    Questionnaire
                  </h3>
                  <div className="w-6 h-6 rounded-full bg-Neutral800 flex items-center justify-center">
                    <img
                      className="w-[18px] h-[18px] block"
                      src={QuestionWhite}
                      alt="Questionnaire"
                    />
                  </div>
                </div>
                <p className="text-Neutral800 text-sm font-normal leading-[150%] min-h-[63px]">
                  Customize a URL to get all the information you need from a
                  client.
                </p>
              </div>
              <div className="flex justify-center items-center gap-x-2">
                <button
                  onClick={() => {
                    setModalType("Questionnaire");
                    setTemplateModalIsOpen(true);
                  }}
                  className="w-full flex justify-center items-center rounded bg-Neutral800 text-Neutral000  py-3 text-xs font-bold leading-[140%] tracking-[0.04em] uppercase"
                >
                  Create
                </button>
                <button
                  onClick={() => {
                    setViewQuestionnaireListIsOpen(true);
                  }}
                  className="w-full flex justify-center items-center rounded bg-Neutral300 py-3 text-xs font-bold text-Neutral800 leading-[140%] tracking-[0.04em] uppercase"
                >
                  View
                </button>
              </div>
            </div>
          ) : null}

          {/* INVOICES */}
          {clientDetail?.data?.is_admin ||
          clientDetail?.data?.is_manager === true ? (
            <div className="w-full md:w-1/2 lg:w-[calc(33.33%_-_16px)] px-[22px] py-6 rounded shadow-[0_4px_15px_rgba(0,0,0,0.08)] block relative mb-4">
              <div className="px-2 mb-7">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-Neutral900 uppercase text-sm font-bold tracking-[0.02em] leading-[19px]">
                    Invoices
                  </h3>
                  <div className="w-6 h-6 rounded-full bg-Neutral800 flex items-center justify-center">
                    <img
                      className="w-[18px] h-[18px] block"
                      src={InvoiceWhite}
                      alt="Invoices"
                    />
                  </div>
                </div>
                <p className="text-Neutral800 text-sm font-normal leading-[150%] min-h-[63px]">
                  Create and send invoices to your clients.
                </p>
              </div>
              <div className="flex justify-center items-center gap-x-2">
                <button
                  onClick={() => {
                    setModalType("Invoice");
                    setOpenInvoiceModal(true);
                    // setTemplateModalIsOpen(true);
                    //need to show pre creation invoice modal to choose create or upload
                  }}
                  className="w-full flex justify-center items-center rounded bg-Neutral800 text-Neutral000  py-3 text-xs font-bold leading-[140%] tracking-[0.04em] uppercase"
                >
                  Create
                </button>
                <button
                  onClick={() => {
                    setViewInvoiceListIsOpen(true);
                  }}
                  className="w-full flex justify-center items-center rounded bg-Neutral300 py-3 text-xs font-bold text-Neutral800 leading-[140%] tracking-[0.04em] uppercase"
                >
                  View
                </button>
              </div>
            </div>
          ) : null}

          {/* SERVICES */}
          <div className="w-full md:w-1/2 lg:w-[calc(33.33%_-_16px)] px-[22px] py-6 rounded shadow-[0_4px_15px_rgba(0,0,0,0.08)] block relative mb-4">
            <div className="px-2 mb-7">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-Neutral900 uppercase text-sm font-bold tracking-[0.02em] leading-[19px]">
                  SERVICES
                </h3>
                <div className="w-6 h-6 rounded-full bg-Neutral800 flex items-center justify-center">
                  <img
                    className="w-[18px] h-[18px] block"
                    src={Clipboard}
                    alt="SERVICES"
                  />
                </div>
              </div>
              <p className="text-Neutral800 text-sm font-normal leading-[150%] min-h-[63px]">
                Add your once-off and monthly services here for easy reference.
              </p>
            </div>
            <Link
              to="services"
              className="w-full flex justify-center items-center rounded bg-Neutral300 py-3 text-xs font-bold text-Neutral800 leading-[140%] tracking-[0.04em] uppercase"
            >
              Manage
            </Link>
          </div>
        </div>
      )}

      {/* MODALS */}
      {openProposalModal && (
        <OpenProposalModal
          isOpen={openProposalModal}
          setModal={setOpenProposalModal}
          client={clientDetail?.data?._id}
          isOpenProposalModal={() => {
            setIsOpenProposalModal(true);
            setOpenProposalModal(false);
          }}
          isOpenBuildProposalModal={() => {
            setTemplateModalIsOpen(true);
            setOpenProposalModal(false);
          }}
        />
      )}

      {openInvoiceModal && (
        <OpenInvoiceModal
          isOpen={openInvoiceModal}
          setModal={setOpenInvoiceModal}
          client={clientDetail?.data?._id}
          isOpenInvoiceModal={() => {
            setIsOpenInvoiceModal(true);
            setOpenInvoiceModal(false);
          }}
          isOpenBuildInvoiceModal={() => {
            setTemplateModalIsOpen(true);
            setOpenInvoiceModal(false);
          }}
        />
      )}

      <UploadExistingProposalSpecificClientModal
        modalIsOpen={isOpenProposalModal}
        closeModal={() => setIsOpenProposalModal(false)}
        client={clientId}
        getProposalList={GetProposalList}
      />

      <UploadExistingInvoiceSpecificClientModal
        modalIsOpen={isOpenInvoiceModal}
        closeModal={() => setIsOpenInvoiceModal(false)}
        client={clientId}
        is_reload={false}
      />

      <NewEntityTemplateModal
        isOpen={templateModalIsOpen}
        setIsOpen={setTemplateModalIsOpen}
        type={modalType}
        clientId={clientId}
        onCustomTemplateClick={handleOpenSelectTemplateModal}
        openBuildInvoiceModal={() => {
          setTemplateModalIsOpen(false);
          setCreateBuildInvoiceModalIsOpen(true);
          setIsInvoiceModalOpen(true);
        }}
      />
      <SelectTemplate
        type={modalType}
        isOpen={selectTemplateModalIsOpen}
        setIsOpen={setSelectTemplateModalIsOpen}
        clientId={clientId}
        openNewTemplateModal={() => {
          setNewTemplateModalIsOpen(true);
          setSelectTemplateModalIsOpen(false);
        }}
      />

      {newTemplateModalIsOpen && (
        <NewTemplateModal
          isOpen={newTemplateModalIsOpen}
          setIsOpen={setNewTemplateModalIsOpen}
          type={modalType}
        />
      )}

      <ViewQuestionnaireList
        isOpen={viewQuestionnaireListIsOpen}
        setIsOpen={setViewQuestionnaireListIsOpen}
        clientId={clientId}
        setSelectedData={setSelectedData}
        isOpenEntityTemplateModal={() => {
          setViewQuestionnaireListIsOpen(false);
          setModalType("Questionnaire");
          setTemplateModalIsOpen(true);
        }}
        isEmailModalOpen={(id) => {
          setViewQuestionnaireListIsOpen(false);
          setModalType("Questionnaire");
          setSeletctedEmailId(id);
          setIsEmailModalOpen(true);
        }}
      />

      {viewInvoiceListIsOpen && (
        <ViewInvoiceList
          isOpen={viewInvoiceListIsOpen}
          setIsOpen={setViewInvoiceListIsOpen}
          clientId={clientId}
          setSelectedData={setSelectedData}
          isOpenEntityTemplateModal={() => {
            setViewInvoiceListIsOpen(false);
            setModalType("Invoice");
            setIsInvoiceModalOpen(true);
            setCreateBuildInvoiceModalIsOpen(true);
          }}
          openModal={(isopen, invoicedata) => {
            setViewInvoiceListIsOpen(false);
            setUploadExistingInvoiceModalIsOpen(isopen);
            setInvoice(invoicedata);
          }}
          isEmailModalOpen={(id) => {
            setViewInvoiceListIsOpen(false);
            setModalType("Invoice");
            setSeletctedEmailId(id);
            setIsEmailModalOpen(true);
          }}
        />
      )}

      {viewProposalListIsOpen && (
        <ViewProposalList
          isOpen={viewProposalListIsOpen}
          setIsOpen={setViewProposalListIsOpen}
          clientId={clientId}
          setSelectedData={setSelectedData}
          openModal={(isopen, proposaldata) => {
            setViewProposalListIsOpen(false);
            setUploadExistingProposalModalIsOpen(isopen);
            setProposal(proposaldata);
          }}
          isEmailModalOpen={(id) => {
            setViewProposalListIsOpen(false);
            setModalType("Proposal");
            setSeletctedEmailId(id);
            setIsEmailModalOpen(true);
          }}
        />
      )}

      {isEmailModalOpen && (
        <FollowUpEmailModal
          isOpen={isEmailModalOpen}
          setIsOpen={setIsEmailModalOpen}
          data={clientDetail?.data}
          type={modalType}
          id={seletctedEmailId}
          selectedData={selectedData}
        />
      )}

      {uploadExistingProposalModalIsOpen && (
        <UploadExistingProposalModal
          modalIsOpen={uploadExistingProposalModalIsOpen}
          closeModal={() => setUploadExistingProposalModalIsOpen(false)}
          client={allClients}
          getProposalList={getProposalList}
          proposal={proposal}
          isDisabledClient={true}
        />
      )}

      {uploadExistingInvoiceModalIsOpen && (
        <UploadExistingInvoiceModal
          modalIsOpen={uploadExistingInvoiceModalIsOpen}
          closeModal={() => setUploadExistingInvoiceModalIsOpen(false)}
          client={allClients}
          getInvoiceList={getInvoiceListByClientId}
          invoice={invoice}
          isDisabledClient={true}
        />
      )}

      {isInvoiceModalOpen && (
        <>
          <h3 className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8 uppercase">
            Invoice Type
          </h3>
          {/* <NewInvoiceModal
            isOpen={isInvoiceModalOpen}
            setIsOpen={(e) => {
              setIsInvoiceModalOpen(e);
            }}
            clientId={clientId}
          /> */}
          <NewTemplateModal
            isOpen={isInvoiceModalOpen}
            setIsOpen={setIsInvoiceModalOpen}
            type={modalType}
            isCreateInvoice={true}
            clientId={clientId}
          />
        </>
      )}
    </section>
  );
}

export default AssetsAndManagementSection;
