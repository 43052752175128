import React from "react";
import Modal from "react-modal";
import { useState } from "react";
import TaskPreview from "../../../../Icons/TaskPreview";
import AdminNavbar from "../../../../Navbars/AdminNavbar";
import TaskMainContent from "../TaskMainContent";
import Sidebar from "../../../../Sidebar/Sidebar";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
    overflowX: "hidden",
    width: "100vw",
    margin: "0",
  },
  content: {
    border: "none",
    padding: 0,
    background: "none",
    borderRadius: "10px",
    overflow: "visible",
    position: "absolute",
    left: "0px",
    top: "0px",
  },
};

const data = {
  address: "",
  admins: ["64cb4b6ca6e5a2abe04302fa"],
  archived_at: null,
  category: ["SEO", "MARKETING", "UX"],
  client_name: "Chubaka",
  contact_name: "Chubaka",
  createdAt: "2023-08-09T17:50:45.295Z",
  currency: { name: null, short_name: null, symbol: null },
  email: "Chubaka@mail.com",
  is_active: true,
  is_admin: true,
  is_archived: false,
  is_manager: false,
  is_member: false,
  manager: [],
  members: [],
  phone_number: "+34 567 89 03 12",
  profile: null,
  profile_path: null,
  second_contact_name: "",
  second_email: "",
  second_phone_number: "",
  service_column: [{}, {}],
  service_screen: ["ONCE", "MONTHLY"],
  status: "in_progress",
  task_column: [{}, {}, {}, {}],
  task_screen: ["GENERAL", "MONTHLY", "YEARLY"],
  updatedAt: "2023-08-24T10:41:38.443Z",
  user: "64cb4b6ca6e5a2abe04302fa",
  vat_number: "",
  website_one: "",
  website_two: "",
  _id: "64d3d1f59a42b5c58695e8cc",
};

const FullScreenModal = ({ clientDetail, types, item, project }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="w-8 h-8 hidden md:flex">
      <button onClick={() => setIsModalOpen(true)}>
        <TaskPreview
          width={18}
          className="text-Neutral000 hover:text-Red400 hover:border-Red400 duration-300 transition-all rounded-[50%] border border-Neutral400 h-[32px] w-[32px] flex items-center justify-center p-1"
        />
      </button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="add link modal"
        style={customStyles}
      >
        <div className="bg-white absolute w-screen min-h-screen">
          <Sidebar
          // onMouseEnter={setIssliderHover}
          // onMouseLeave={setIssliderHover}
          />

          <div
            className={`main-content relative md:ml-60 5xl:ml-[300px] bg-Neutral000 transition duration-300  md:pb-0 w-full`}
          >
            <AdminNavbar
              link="/admin/clients/"
              title="Task Dashboard"
              backLink="Get an overview of all client tasks"
            />

            <TaskMainContent
              types={types}
              clientDetail={clientDetail}
              isFullSize={true}
              // TODO: its hardcode (delete after):
              item={item}
              project={project}
            />

            <button
              onClick={() => setIsModalOpen(false)}
              className="w-fit absolute top-[25px] left-[48px] z-10 flex items-center justify-end gap-5 mb-5"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.5 16C31.5 24.5604 24.5604 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16C0.5 7.43959 7.43959 0.5 16 0.5C24.5604 0.5 31.5 7.43959 31.5 16Z"
                  stroke="#292933"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 10L10 16L16 22"
                  stroke="#292933"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 16H10"
                  stroke="#292933"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FullScreenModal;
