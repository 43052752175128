import { Menu, Transition } from "@headlessui/react";
import moment from "moment";
import React, { Fragment, forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import StripeIcon from "../../assets/Images/stripe-logo.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import ThreeDots from "../../assets/Images/three-dots.svg";
import DashboardButton from "../../components/Buttons/DashboardButton";
import FrequencyButton from "../../components/Buttons/FrequencyButton";
import InvoiceStatus from "../../components/Buttons/InvoiceStatus";
import InvoiceDashboardLoader from "../../components/ContentLoaders/InvoiceDashboardLoader";
import EmptyInvoice from "../../components/EmptyInvoice";
import CalendarIcon from "../../components/Icons/CalendarIcon";
import OpenInvoiceModal from "../../components/Modals/OpenInvoiceModal";
import { NewEntityTemplateModal } from "../../components/Modals/Templates/NewEntityTemplateModal";
import NewTemplateModal from "../../components/Modals/Templates/NewTemplateModal";
import { SelectTemplate } from "../../components/Modals/Templates/SelectTemplate";
import UploadExistingInvoiceModal from "../../components/Modals/UploadExistingInvoiceModal";
import UploadExistingInvoiceSpecificClientModal from "../../components/Modals/UploadExistingInvoiceSpecificClientModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import NotDataFound from "../../components/NotDataProposal";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/Search";
import ClientSelect from "../../components/comman/Client/ClientSelect";
import InvoiceFilter from "../../components/filter/InvoiceFilter";
import { getFullClientList } from "../../store/actions/clientAction";
import {
  GetInvoiceStatus,
  LoginWithPaypal,
  LoginWithStripe,
  deleteInvoice,
  getInvoiceList,
  updateInvoice,
} from "../../store/actions/invoicesAction";
import { getTemplateList } from "../../store/actions/templateAction";
import axios from "axios";
import Buffer from "lodash";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import LoadingIcon from "../../components/Icons/LoadingIcon";
import InvoiceConnectionBtn from "../../components/Buttons/InvoiceConnectionBtn";
import MessageIcon from "../../components/Icons/newicons/_MessageIcon";
import FollowUpEmailModal from "../../components/Modals/FollowUpEmailModal";

const customStyles = {
	overlay: {
		backgroundColor: "rgba(41, 41, 51, 0.7)",
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		border: "none",
		padding: "47px",
		background: "none",
		borderRadius: "10px",
	},
};

const InvoiceDashboard = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const cardId = params.get("code");

	const [searchParams, setSearchParams] = useSearchParams();
	const [invoices, setInvoices] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [page, setPage] = useState(1);
	const [sortColumn, setSortColumn] = useState("createdAt");
	const [sort, setSort] = useState("desc");
	const [isLoading, setIsLoading] = useState({
		status: false,
		id: "",
	});
	const [loadingPaymentLink, setLoadingPaymentLink] = useState(false);
	const [loadingStripeLink, setLoadingStripeLink] = useState(false);
	const [isOpenCreateFlowModal, setIsOpenCreateFlowModal] = useState(false);
	const [isOpenSelectTemplateModal, setIsOpenSelectTemplateModal] = useState(false);
	const [createBuildInvoiceModalIsOpen, setCreateBuildInvoiceModalIsOpen] = useState(false);
	const [isOpenNewTemplateModal, setIsOpenNewTemplateModal] = useState(false);
	const [selectClientModalIsOpen, setSelectClientModalIsOpen] = useState(false);
	const [selectedClient, setSelectedClient] = useState(null);
	const [filterBtn, setFilterBtn] = useState(false);
	const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
	const [addfilter, setAddFilter] = useState({
		status: [],
		client: [],
		createdDate: [
			{
				from: "",
				to: "",
			},
		],
		value: [
			{
				from: "",
				to: "",
			},
		],
	});
	const user = useSelector((state) => state.auth.user);
	const authSelector = useSelector((state) => state.auth);
	const invoiceList = useSelector((state) => state.invoice.invoiceList);
	const allClients = useSelector((state) => state.client.fullClientList);
	const templateList = useSelector((state) => state.template.template);
	const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
	const [isOpenInvoiceModal, setIsOpenInvoiceModal] = useState(false);

	const [uploadExistingInvoiceModalIsOpen, setUploadExistingInvoiceModalIsOpen] = useState(false);
	const [invoice, setInvoice] = useState("");

	useEffect(() => {
		if (!allClients?.data?.length) dispatch(getFullClientList());
	}, []);

	useEffect(() => {
		if (!templateList?.["invoice"]?.length) {
			dispatch(getTemplateList());
		}

		dispatch(GetInvoiceStatus());
	}, []);

	useEffect(() => {
		setInvoices(invoiceList?.data?.docs);
	}, [invoiceList?.data?.docs]);

  console.log("user", user);
  useEffect(() => {
    const filterData = invoiceList?.data?.docs?.filter(
      (value) =>
        (addfilter?.status?.length
          ? addfilter?.status?.includes(value?.invoice_status?._id)
          : true) &&
        (addfilter?.client?.length
          ? addfilter?.client?.includes(value?.client?._id)
          : true) &&
        (addfilter?.createdDate?.[0]?.from && addfilter?.createdDate?.[0]?.to
          ? moment(value?.dueDate, "DD/MM/YYYY").isBetween(
              moment(addfilter?.createdDate[0]?.from).subtract(1, "days"),
              moment(addfilter?.createdDate[0]?.to).add(1, "days")
            )
          : true) &&
        (addfilter?.value?.[0]?.from && addfilter?.value?.[0]?.to
          ? Number(value?.totalDue) >= Number(addfilter?.value[0]?.from) &&
            Number(value?.totalDue) <= Number(addfilter?.value[0]?.to)
          : true)
    );

		setInvoices(filterData);
	}, [addfilter]);

	const handleOpenCreateInvoiceModal = () => {
		setSelectClientModalIsOpen(false);
		setOpenInvoiceModal(true);
	};

	const handleChangeSearch = (e) => {
		setSearchValue(e.target.value);
	};

  const fetchStripeInfo = async (code) => {
    dispatch(
      LoginWithStripe(
        code,
        searchParams,
        setSearchParams,
        user,
        setLoadingStripeLink
      )
    );
  };

	const fetchPaypalInfo = async (code) => {
		dispatch(LoginWithPaypal(code, searchParams, setSearchParams, user, setLoadingPaymentLink));
	};

	useEffect(() => {
		if (cardId && params.size === 3) {
			fetchStripeInfo(cardId);
		} else if (cardId && params.size === 2) {
			fetchPaypalInfo(cardId);
		}
	}, [cardId]);

	useEffect(() => {
		dispatch(
			getInvoiceList({
				page: page,
				perPage: 10,
				search: searchValue,
				sortColumn: sortColumn,
				sortType: sort,
			}),
		);
	}, [dispatch, page, searchValue, sortColumn, sort]);

	const handleDeleteInvoice = async (id) => {
		setIsLoading({
			status: true,
			id: id,
		});

		dispatch(deleteInvoice(id, setIsLoading));
	};

	const DateInput = forwardRef(({ value, onClick }, ref) => {
		return (
			<button
				className="example-custom-input text-xs whitespace-nowrap font-bold color-Neutral100 text-Neutral700 border border-Neutral400 rounded-[20px] py-[6px] w-full px-[6px] focus-visible:outline-none focus:outline focus:outline-none transition duration-300 uppercase flex items-center justify-between hover:border-none hover:bg-Neutral200 hover-calendar flex-wrap"
				onClick={onClick}
				ref={ref}
			>
				<CalendarIcon className="mr-[1.5px]" color="currentColor" width={20} />
				<span className="text-[10px] xl:text-xs">{moment(value).format("DD MMM YYYY")}</span>
			</button>
		);
	});

	return (
		<>
			<AdminNavbar title="Invoices Dashboard" subtitle="View all your client invoices here" />

			<div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0 mb-[95px] md:mb-0 md:h-[75vh] ">
				<div className="mb-[68px]">
					<div className="md:mt-[32px] mt-[16px] text-right">
						<div className="flex flex-wrap items-center justify-between gap-3 sm:flex-nowrap">
							<div className="flex w-full">
								{authSelector?.user?.invited === false ||
								(authSelector?.user?.owner && authSelector?.user?.invited === true) ? (
									<div className="flex items-center w-full gap-4">
										<DashboardButton
											title="Create Invoice"
											onClick={() => setSelectClientModalIsOpen(true)}
											customStyles="flex-1 sm:flex-none"
											limitWidth={false}
										/>
									 <InvoiceConnectionBtn
                      onClick={() => {
                        if (window) {
                          const url = `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${
                            process.env.REACT_APP_PUBLIC_STRIPE_OAUTH_CLIENT_ID
                          }&scope=read_write&state=${
                            Math.random() * 100
                          }&return_url=${
                            process.env.REACT_APP_PUBLIC_BASE_URL
                          }`;
                          window.document.location.href = url;
                        }
                      }}
                      isCheck={user?.stripeInfo}
                      text={loadingStripeLink ? "Connecting..." : "Connect"}
                      logo={StripeIcon}
                      type="stripe"
                    />
										<InvoiceConnectionBtn
											onClick={() => {
												if (window) {
													const url = `${process.env.REACT_APP_PAYPAL_BASE_URL}/signin/authorize?flowEntry=static&client_id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&response_type=code&scope=openid profile email&redirect_uri=${process.env.REACT_APP_PUBLIC_BASE_URL}`;
													window.document.location.href = url;
												}
											}}
											isCheck={user.payPalInfo}
											text={loadingPaymentLink ? "Connecting..." : "Connect"}
											// logo={<PaypalIcon />}
										/>
									</div>
								) : null}
							</div>

							<SearchBar
								searchValue={searchValue}
								handleChangeSearch={handleChangeSearch}
								isFilter={true}
								setFilterBtn={setFilterBtn}
							/>
						</div>
					</div>
					<div className="flex flex-col relative overflow-x-scroll h-[calc(64vh-100px)] md:h-full md:overflow-x-visible scrollbar-h-4 scrollbar-w-0">
						<div className="mt-[42px] flex items-center w-[850px] md:w-full justify-between mb-8 px-[15px] space-x-0.5">
							<h5 className="text-Neutral700 text-xs font-semibold w-full min-w-[20%]">
								{invoiceList?.data?.totalDocs} Invoices
							</h5>

							{/* Add asending and decending icon */}
							<button
								onClick={() => {
									setSortColumn("invoiceNumber");
									setSort(sort === "asc" ? "desc" : "asc");
								}}
								className="flex items-center text-Neutral700 text-xs font-semibold w-full focus-visible:outline-none focus:outline focus:outline-none transition duration-300 hover:text-AccentRegular min-w-[8%]"
							>
								{sortColumn === "invoiceNumber" &&
									(sort === "asc" ? (
										<svg
											width="14"
											height="14"
											className="mr-1"
											viewBox="0 0 14 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M3.66667 10.8333L7 7.5L10.3333 10.8333"
												stroke="currentColor"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M3.66667 6.5L7 3.16667L10.3333 6.5"
												stroke="currentColor"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									) : (
										<svg
											width="14"
											height="14"
											className="mr-1"
											viewBox="0 0 14 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M3.66667 3.16669L7 6.50002L10.3333 3.16669"
												stroke="currentColor"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M3.66667 7.5L7 10.8333L10.3333 7.5"
												stroke="currentColor"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									))}
								Invoice #
							</button>
							<button
								onClick={() => {
									setSortColumn("totalDue");
									setSort(sort === "asc" ? "desc" : "asc");
								}}
								className="flex items-center text-Neutral700 text-xs font-semibold w-full focus-visible:outline-none focus:outline focus:outline-none transition duration-300 hover:text-AccentRegular min-w-[14%]"
							>
								{sortColumn === "totalDue" &&
									(sort === "asc" ? (
										<svg
											width="14"
											height="14"
											className="mr-1"
											viewBox="0 0 14 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M3.66667 10.8333L7 7.5L10.3333 10.8333"
												stroke="currentColor"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M3.66667 6.5L7 3.16667L10.3333 6.5"
												stroke="currentColor"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									) : (
										<svg
											width="14"
											height="14"
											className="mr-1"
											viewBox="0 0 14 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M3.66667 3.16669L7 6.50002L10.3333 3.16669"
												stroke="currentColor"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M3.66667 7.5L7 10.8333L10.3333 7.5"
												stroke="currentColor"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									))}
								Value
							</button>
							<h5 className="text-Neutral700 text-xs font-semibold w-full min-w-[10%]">
								Status
							</h5>
							<button
								onClick={() => {
									setSortColumn("createdAt");
									setSort(sort === "asc" ? "desc" : "asc");
								}}
								className="flex items-center text-Neutral700 text-xs font-semibold w-full focus-visible:outline-none focus:outline focus:outline-none transition duration-300 hover:text-AccentRegular min-w-[15%]"
							>
								{sortColumn === "createdAt" &&
									(sort === "asc" ? (
										<svg
											width="14"
											height="14"
											className="mr-1"
											viewBox="0 0 14 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M3.66667 10.8333L7 7.5L10.3333 10.8333"
												stroke="currentColor"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M3.66667 6.5L7 3.16667L10.3333 6.5"
												stroke="currentColor"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									) : (
										<svg
											width="14"
											height="14"
											className="mr-1"
											viewBox="0 0 14 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M3.66667 3.16669L7 6.50002L10.3333 3.16669"
												stroke="currentColor"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M3.66667 7.5L7 10.8333L10.3333 7.5"
												stroke="currentColor"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									))}
								Date Created
							</button>
							<h5 className="text-Neutral700 text-xs font-semibold w-full min-w-[15%]">
								Due Date
							</h5>
							<h5 className="text-Neutral700 text-xs font-semibold w-full min-w-[13%]">
								Frequency
							</h5>
							<h5 className="text-Neutral700 text-xs font-semibold w-full min-w-[4%]"></h5>
						</div>

            {invoiceList?.isLoading &&
            !invoiceList?.data?.docs?.length &&
            !searchValue ? (
              <InvoiceDashboardLoader />
            ) : (
              <>
                {!invoiceList?.data?.docs?.length && !searchValue && (
                  <EmptyInvoice
                    setSelectClientModalIsOpen={setSelectClientModalIsOpen}
                  />
                )}

                {searchValue && !invoiceList?.data?.docs?.length && (
                  <NotDataFound />
                )}
                {invoices?.map((invoice) => (
                  <div
                    className="mt-4 border border-Neutral200 rounded-[12px] py-[18px] px-[15px] w-[850px] md:w-full transition duration-300 flex items-center justify-between time-tracking-shadow cursor-pointer space-x-0.5"
                    key={invoice?._id}
                    onClick={() => {
                      if (invoice?.is_external_invoice) {
                        if (invoice?.existing_file_upload?.url) {
                          window.open(
                            invoice?.existing_file_upload?.url,
                            "_blank"
                          );
                        }

                        if (invoice?.existing_file_url) {
                          window.open(invoice?.existing_file_url, "_blank");
                        }
                        return;
                      } else {
                        navigate(
                          `/admin/clients/manage/${invoice?.client?._id}/invoice/${invoice?._id}`
                        );
                      }
                    }}
                  >
                    <div className="flex items-center w-full min-w-[20%] flex-wrap">
                      {invoice?.client?.profile_path &&
                      invoice?.client?.profile ? (
                        <img
                          className="w-[40px] h-[40px] rounded-full mr-[12px]"
                          src={invoice?.client?.profile_path}
                          alt="profile"
                        />
                      ) : (
                        <div className="w-[40px] h-[40px] rounded-full bg-Neutral300 flex items-center justify-center mr-[12px]">
                          <h5 className="text-Neutral900 text-base font-semibold uppercase">
                            {invoice?.client?.clientName ||
                              invoice?.client?.client_name[0]}
                          </h5>
                        </div>
                      )}

											<h5 className="text-base font-semibold text-Neutral900">
												{invoice?.client?.client_name}
											</h5>
										</div>
										<h5 className="text-Neutral700 text-sm font-semibold w-full min-w-[8%]">
											{invoice?.invoiceNumber}
										</h5>
										<h5 className="text-Neutral800 text-base font-extrabold w-full flex items-start min-w-[14%]">
											{new Intl.NumberFormat("en-US", {
												style: "currency",
												currency: invoice?.currency || "USD",
											}).format(invoice?.totalDue)}
										</h5>
										<h5
											className="text-Neutral700 text-sm font-semibold w-full min-w-[10%]"
											onClick={(e) => e.stopPropagation()}
										>
											<InvoiceStatus
												id={invoice?._id}
												status={invoice?.invoice_status?.name}
												item={invoice}
											/>
										</h5>
										<h5 className="text-Neutral700 text-sm font-semibold w-full min-w-[15%]">
											{invoice?.createdAt && (
												<DatePicker
													selected={moment(
														new Date(invoice?.createdAt),
														"DD/MM/YYYY",
													).toDate()}
													customInput={<DateInput value={invoice?.createdAt} />}
													disabled={true}
													onChange={(date) =>
														dispatch(
															updateInvoice(invoice?._id, {
																...invoice,
																createdAt: moment(date).format("DD/MM/YYYY"),
																client: invoice?.client?._id,
															}),
														)
													}
												/>
											)}
										</h5>
										<h5 className="text-Neutral700 text-sm font-semibold w-full min-w-[15%]">
											{invoice?.dueDate && (
												<DatePicker
													selected={moment(invoice?.dueDate).toDate()}
													customInput={<DateInput value={invoice?.dueDate} />}
													disabled={true}
													onChange={(date) =>
														dispatch(
															updateInvoice(invoice?._id, {
																...invoice,
																dueDate: moment(date).toDate(),
																client: invoice?.client?._id,
															}),
														)
													}
													width="80%"
												/>
											)}
										</h5>
										<h5 className="text-Neutral700 text-sm font-semibold w-full text-center min-w-[13%]">
											<FrequencyButton
												frequency={
													invoice?.is_external_invoice ? "1" : invoice?.interval
												}
												invoice={invoice}
											/>
										</h5>
										<h5
											className="w-full text-sm font-semibold text-right text-Neutral700"
											onClick={(e) => e.stopPropagation()}
										>
											<Menu as="div" className="relative inline-block">
												<div className="flex items-center">
													<Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none popup-btn">
														<span className="focus-visible:outline-none">
															<img
																className="cursor-pointer"
																src={ThreeDots}
																alt=""
															/>
														</span>
													</Menu.Button>
												</div>
												<Transition
													as={Fragment}
													enter="transition ease-out duration-100"
													enterFrom="transform opacity-0 scale-95"
													enterTo="transform opacity-100 scale-100"
													leave="transition ease-in duration-75"
													leaveFrom="transform opacity-100 scale-100"
													leaveTo="transform opacity-0 scale-95"
												>
													<Menu.Items className="origin-top-right absolute bottom-0 right-[10px] rounded-[4px] Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none innermenu-main z-[999]">
														<div
															className=""
															onClick={(e) => {
																e.stopPropagation();
																setInvoice(invoice);
																setIsEmailModalOpen(true);
															}}
														>
															<div className="relative w-full focus-visible:outline-none popup-btn">
																<span className="focus-visible:outline-none">
																	<div className="py-[11px] px-[15px] flex justify-between edit-icon group duration-300 download hover:bg-Neutral200 overflow-hidden rounded-b-[4px] w-full">
																		<h3 className="flex text-xs text-Neutral800 group-hover:text-AccentRegular">
																			<MessageIcon className="mr-2" width={16} />
																			Email
																		</h3>
																	</div>
																</span>
															</div>
														</div>

														{/* edit */}
														<div
															className=""
															onClick={(e) => {
																e.stopPropagation();
																if (invoice?.is_external_invoice) {
																	setUploadExistingInvoiceModalIsOpen(true);
																	setInvoice(invoice);
																	return;
																}
																navigate(
																	`/admin/create-invoice/${invoice?.client._id}/${invoice?._id}`,
																);
															}}
														>
															<div className="relative w-full focus-visible:outline-none popup-btn">
																<span className="focus-visible:outline-none">
																	<div className="py-[11px] px-[15px] flex justify-between edit-icon group duration-300 download hover:bg-Neutral200 overflow-hidden rounded-b-[4px] w-full">
																		<h3 className="flex text-xs text-Neutral800 group-hover:text-AccentRegular">
																			<svg
																				className="mr-2"
																				width="16"
																				height="16"
																				viewBox="0 0 16 16"
																				fill="none"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<path
																					d="M7.77149 4.12354H4.06033C3.77911 4.12354 3.50941 4.23525 3.31056 4.4341C3.11171 4.63295 3 4.90265 3 5.18387V12.6062C3 12.8874 3.11171 13.1571 3.31056 13.356C3.50941 13.5548 3.77911 13.6665 4.06033 13.6665H11.4827C11.7639 13.6665 12.0336 13.5548 12.2324 13.356C12.4313 13.1571 12.543 12.8874 12.543 12.6062V8.89503"
																					stroke="#292933"
																					strokeLinecap="round"
																					strokeLinejoin="round"
																				/>
																				<path
																					d="M11.7469 3.32916C11.9578 3.11825 12.2439 2.99976 12.5422 2.99976C12.8404 2.99976 13.1265 3.11825 13.3374 3.32916C13.5483 3.54007 13.6668 3.82613 13.6668 4.12441C13.6668 4.42268 13.5483 4.70874 13.3374 4.91966L8.30084 9.95623L6.18018 10.4864L6.71034 8.36574L11.7469 3.32916Z"
																					stroke="#292933"
																					strokeLinecap="round"
																					strokeLinejoin="round"
																				/>
																			</svg>
																			Edit
																		</h3>
																	</div>
																</span>
															</div>
														</div>
														{/* edit */}
														{/* delete */}
														<Menu
															as="div"
															className=""
															onClick={(e) => {
																e.stopPropagation();
															}}
														>
															<Menu.Button className="duration-300 ease-in-out cursor-pointer">
																{isLoading?.status &&
																isLoading?.id === invoice?._id ? (
																	<svg
																		aria-hidden="true"
																		className="inline w-4 h-4 mr-2 text-white animate-spin dark:text-white fill-AccentRegular"
																		viewBox="0 0 100 101"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
																			fill="currentColor"
																		/>
																		<path
																			d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
																			fill="currentFill"
																		/>
																	</svg>
																) : (
																	<span className="text-Neutral800 w-[100px] text-left px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300">
																		<svg
																			className="mr-2"
																			width="16"
																			height="16"
																			viewBox="0 0 16 16"
																			fill="none"
																			xmlns="http://www.w3.org/2000/svg"
																		>
																			<path
																				d="M3.23071 5.04416H4.33157H13.1384"
																				stroke="#5A5A66"
																				stroke-linecap="round"
																				stroke-linejoin="round"
																			/>
																			<path
																				d="M5.98209 5.04466V4.02244C5.98209 3.75133 6.09808 3.49132 6.30453 3.29962C6.51098 3.10791 6.79098 3.00021 7.08295 3.00021H9.28466C9.57662 3.00021 9.85663 3.10791 10.0631 3.29962C10.2695 3.49132 10.3855 3.75133 10.3855 4.02244V5.04466M12.0368 5.04466V12.2002C12.0368 12.4713 11.9208 12.7313 11.7144 12.923C11.5079 13.1147 11.2279 13.2224 10.9359 13.2224H5.43167C5.1397 13.2224 4.85969 13.1147 4.65324 12.923C4.44679 12.7313 4.33081 12.4713 4.33081 12.2002V5.04466H12.0368Z"
																				stroke="#5A5A66"
																				stroke-linecap="round"
																				stroke-linejoin="round"
																			/>
																		</svg>
																		Delete
																	</span>
																)}
															</Menu.Button>
															<Transition
																as={Fragment}
																enter="transition ease-out duration-100"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95"
															>
																<Menu.Items className="focus:outline-none min-w-[384px] absolute right-[-20px] top-[20px] w-36 origin-top-right divide-y divide-Neutral200 bg-white ring-1 ring-black ring-opacity-5 drop-shadow-Timetracking">
																	<div className="px-8 pt-6 pb-8">
																		<Menu.Item>
																			{(props) => {
																				return (
																					<div>
																						<h5 className="pb-2 text-xs font-semibold border-b text-Neutral700 border-Neutral200">
																							Are you sure?
																						</h5>

																						<button
																							onClick={() => {
																								handleDeleteInvoice(
																									invoice?._id,
																								);
																							}}
																							className="text-Neutral000 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Red400 hover:bg-Red600 rounded-[4px] mt-8"
																						>
																							Yes, Delete Invoice
																						</button>

																						<Menu.Button
																							type="button"
																							className="text-Neutral800 hover:text-Neutral900 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Neutral300 hover:bg-Neutral400 rounded-[4px] mt-5"
																						>
																							No, I Still Want This Invoice
																						</Menu.Button>
																					</div>
																				);
																			}}
																		</Menu.Item>
																	</div>
																</Menu.Items>
															</Transition>
														</Menu>
														{/* delete */}
													</Menu.Items>
												</Transition>
											</Menu>
										</h5>
									</div>
								))}
								{invoiceList?.data?.totalDocs ? (
									<Pagination
										currantPage={invoiceList.data.page}
										totalData={invoiceList.data.totalDocs}
										hasPrevPage={invoiceList.data.hasPrevPage}
										hasNextPage={invoiceList.data.hasNextPage}
										fromRecord={invoiceList.data.pagingCounter}
										outOfCounter={invoiceList?.data?.docs?.length}
										setPage={setPage}
										type="Invoices"
									/>
								) : null}
							</>
						)}
					</div>
				</div>
			</div>

			<Modal
				isOpen={selectClientModalIsOpen}
				onRequestClose={() => setSelectClientModalIsOpen(false)}
				style={customStyles}
				closeTimeoutMS={300}
				contentLabel="View Form Preview Modal"
			>
				<div className="relative bg-white rounded-lg text-left transform transition-all w-[80vw] sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
					<div className="bg-white px-6 xs:px-12 pt-8 pb-12 rounded-[8px]">
						<div>
							<div className="text-left">
								<h3
									className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
									id="modal-title"
								>
									SELECT CLIENT
								</h3>
								<div className="mb-8">
									<label className="block mb-2 text-xs font-bold text-Neutral900">
										Client
									</label>
									<ClientSelect
										allClients={allClients}
										onChange={(e) => {
											setSelectedClient(e);
										}}
									/>
								</div>
								<button
									className={`btn button-hover large-primary-btn text-sm text-center inline-block text-Neutral100 bg-AccentRegular font-semibold w-full min-w-[148px]  p-3 rounded drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out ${
										!selectedClient && "pointer-events-none"
									}`}
									onClick={() => {
										handleOpenCreateInvoiceModal();
									}}
								>
									<span className="relative z-10 drop-shadow-Texts">
										Continue to Invoice
									</span>
								</button>
							</div>
						</div>
					</div>
					<div
						className="absolute right-0 top-[-50px] z-30 cursor-pointer"
						onClick={() => setSelectClientModalIsOpen(false)}
					>
						<img src={CloseBtn} alt="" />
					</div>
				</div>
			</Modal>

			<NewEntityTemplateModal
				isOpen={isOpenCreateFlowModal}
				setIsOpen={setIsOpenCreateFlowModal}
				type="Invoice"
				clientId={selectedClient?._id}
				onCustomTemplateClick={() => {
					setIsOpenCreateFlowModal(false);
					setIsOpenSelectTemplateModal(true);
				}}
				openBuildInvoiceModal={() => {
					setIsOpenCreateFlowModal(false);
					setCreateBuildInvoiceModalIsOpen(true);
					setIsOpenNewTemplateModal(true);
				}}
			/>

			<SelectTemplate
				type="Invoice"
				isOpen={isOpenSelectTemplateModal}
				setIsOpen={setIsOpenSelectTemplateModal}
				clientId={selectedClient?._id}
				openNewTemplateModal={() => {
					setIsOpenSelectTemplateModal(false);
					setIsOpenNewTemplateModal(true);
				}}
			/>

			<NewTemplateModal
				type="Invoice"
				isOpen={isOpenNewTemplateModal}
				setIsOpen={setIsOpenNewTemplateModal}
				isCreateInvoice={createBuildInvoiceModalIsOpen}
				clientId={selectedClient?._id}
			/>

			<InvoiceFilter
				filterBtn={filterBtn}
				setFilterBtn={setFilterBtn}
				addfilter={addfilter}
				setAddFilter={setAddFilter}
			/>

			{openInvoiceModal && (
				<OpenInvoiceModal
					isOpen={openInvoiceModal}
					setModal={setOpenInvoiceModal}
					client={selectedClient?._id}
					isOpenInvoiceModal={() => {
						setIsOpenInvoiceModal(true);
						setOpenInvoiceModal(false);
					}}
					isOpenBuildInvoiceModal={() => {
						setIsOpenCreateFlowModal(true);
						setOpenInvoiceModal(false);
					}}
				/>
			)}

			{uploadExistingInvoiceModalIsOpen && (
				<UploadExistingInvoiceModal
					modalIsOpen={uploadExistingInvoiceModalIsOpen}
					closeModal={() => setUploadExistingInvoiceModalIsOpen(false)}
					client={allClients}
					getInvoiceList={getInvoiceList}
					invoice={invoice}
					isDisabledClient={false}
				/>
			)}

			<UploadExistingInvoiceSpecificClientModal
				modalIsOpen={isOpenInvoiceModal}
				closeModal={() => setIsOpenInvoiceModal(false)}
				client={selectedClient?._id}
				is_reload={true}
			/>

			{isEmailModalOpen && (
				<FollowUpEmailModal
					isOpen={isEmailModalOpen}
					setIsOpen={setIsEmailModalOpen}
					data={invoice.client}
					type="Invoice"
					id={invoice._id}
					selectedData={invoice}
					IsDashboard="dash"
				/>
			)}
		</>
	);
};

export default InvoiceDashboard;
