import React from "react";
import AddIconButton from "../../Buttons/AddIconButton";
import ActionMenuButton from "../../Buttons/ActionMenuButton";
import TeamMemberAvatar from "../avatars/TeamMemberAvatar";
import { useSelector } from "react-redux";

function TeamMemberCard({ member = null, onEdit, onDelete, isEdit = false }) {
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const user = useSelector((state) => state?.auth?.user);
  if (!member) {
    return (
      <li className="list-none">
        <AddIconButton />
      </li>
    );
  }
  return (
    <li className="px-1 pr-4 py-1 flex list-none rounded-[30px] border flex items-center justify-between text-Neutral900 w-fit relative">
      <div className="flex justify-between items-center mr-2">
        <div className="flex justify-between items-center mr-8">
          <TeamMemberAvatar user={member?.user} className="mr-4" />
          <p className="mb-0 text-xs font-medium">{member?.user?.user_name}</p>
        </div>
        {(user?.role !== "general" || user?._id === member?.user?._id) && (
          <div className="flex items-center rounded-[6px] px-2 py-[6px] ml-0 bg-Neutral200">
            <span className="inline-block font-semibold text-xs leading-[100%] mr-1">
              {member?.detail?.currency?.symbol ||
                clientDetail?.data?.currency?.symbol ||
                "$"}{" "}
              {member?.detail?.rate}
            </span>
            <span className="inline-block text-Neutral600 text-[8px] font-bold pt-[1px]">
              / Hr
            </span>
          </div>
        )}
      </div>
      {isEdit && clientDetail?.data?.is_admin === true && (
        <ActionMenuButton
          onEdit={onEdit}
          onDelete={onDelete}
          clientDetail={clientDetail}
          memberDetail={member}
        />
      )}
    </li>
  );
}

export default TeamMemberCard;
