import React, { useEffect } from "react";
import SignatureModal from "../../components/Modals/SignatureModal";
import axios from "axios";

const ProposalFooterBlock = ({ visible, setTextEditor, textEditor }) => {
  useEffect(() => {
    const getProposalsList = async () => {
      const userHtmlContent = document.getElementById(
        `box-signature-${textEditor?._id}-user`
      );
      let usersignature = `<div class="w-1/2 drop-shadow-none" id="user-signature">${userHtmlContent.innerHTML}</div>`;
      const clientHtmlContent = document.getElementById(
        `box-signature-${textEditor?._id}-client`
      );
      let clientHtml = `<div class="w-1/2 drop-shadow-none" id="client-signature">${clientHtmlContent.innerHTML}</div>`;
      let tempData = {
        ...textEditor,
      };
      if (
        (textEditor?.user_signature?.currant_type === "text" &&
          textEditor?.user_signature?.signature) ||
        (textEditor?.user_signature?.currant_type === "draw" &&
          textEditor?.user_signature?.base_64) ||
        (textEditor?.user_signature?.currant_type === "image" &&
          textEditor?.user_signature?.image?.url)
      ) {
        tempData = {
          ...tempData,
          user_signature: {
            ...tempData?.user_signature,
            html: usersignature,
          },
        };
      }
      if (
        (textEditor?.client_signature?.currant_type === "text" &&
          textEditor?.client_signature?.signature) ||
        (textEditor?.client_signature?.currant_type === "draw" &&
          textEditor?.client_signature?.base_64) ||
        (textEditor?.client_signature?.currant_type === "image" &&
          textEditor?.client_signature?.image?.url)
      ) {
        tempData = {
          ...tempData,
          client_signature: {
            ...tempData?.client_signature,
            html: clientHtml,
          },
        };
      }
      //Conract upload signature
      // const { data } = await axios.post(
      //   "/api/v1/contract/migrate-signature",
      //   tempData
      // );

      //proposal upload signature
      // const { data } = await axios.post(
      //   "/api/v1/proposal/migrate-signature",
      //   tempData
      // );
      // console.log("data", data);
    };
    setTimeout(() => {
      getProposalsList();
    }, 2000);
  }, [textEditor]);

  return (
    <>
      <div className="flex w-full max-w-[788px] relativeCSS px-16 mx-auto -mt-1 justify-between items-center bg-white">
        <SignatureModal
          setTextEditor={setTextEditor}
          textEditor={textEditor}
          visible={visible}
          isEditable={true}
          who="user"
        />
        <SignatureModal
          setTextEditor={setTextEditor}
          textEditor={textEditor}
          visible={visible}
          isEditable={false}
          who="client"
        />
      </div>
    </>
  );
};

export default ProposalFooterBlock;
