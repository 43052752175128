import { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import CloseBtn from "../../../assets/Images/close-btn.svg";
import { initContractContent } from "../../../store/actions/documentsAction";
import { initInvoice } from "../../../store/actions/invoicesAction";
import { InitialContent } from "../../../store/actions/proposalAction";
import { initQuestionnaire } from "../../../store/actions/questionnaireAction";
import { getTemplateList } from "../../../store/actions/templateAction";
import { customStyles } from "../../../utils/modalStyles";

export const NewEntityTemplateModal = ({
  isOpen,
  setIsOpen,
  type,
  clientId = null,
  onCustomTemplateClick,
  onDefaultTemplateClick = null,
  id,
  openBuildInvoiceModal,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const templateList = useSelector((state) => state.template.template);

  useEffect(() => {
    if (!templateList?.[type?.toLowerCase()]?.length && isOpen) {
      dispatch(getTemplateList());
    }
  }, [dispatch, isOpen, templateList, type]);

  const handleDefault = (type) => {
    if (type === "Proposal") {
      dispatch(
        InitialContent(
          {
            [clientId && "client_id"]: clientId,
            [id && "lead"]: id,
            type: "default",
            is_lead: id ? true : false,
          },
          setIsOpen,
          navigate
        )
      );
    }
    if (type === "Contract") {
      dispatch(
        initContractContent(
          {
            client_id: clientId,
            type: "default",
          },
          setIsOpen,
          navigate
        )
      );
    }
    if (type === "Questionnaire") {
      dispatch(
        initQuestionnaire(
          {
            [clientId && "client_id"]: clientId,
            [id && "lead"]: id,
            type: "default",
            is_lead: id ? true : false,
          },
          setIsOpen,
          navigate
        )
      );
    }
    if (type === "Invoice") {
      dispatch(
        initInvoice(
          {
            client_id: clientId,
            type: "default",
          },
          setIsOpen,
          navigate
        )
      );
    }

    if (type === "Project") {
      onDefaultTemplateClick();
    }
  };

  const handleBuildFromScratch = (type) => {
    if (type === "Proposal") {
      dispatch(
        InitialContent(
          {
            // client_id: clientId,
            [clientId && "client_id"]: clientId,
            [id && "lead"]: id,
            type: "build",
            is_lead: id ? true : false,
          },
          setIsOpen,
          navigate
        )
      );
    }
    if (type === "Contract") {
      dispatch(
        initContractContent(
          {
            client_id: clientId,
            type: "build",
          },
          setIsOpen,
          navigate
        )
      );
    }
    if (type === "Questionnaire") {
      dispatch(
        initQuestionnaire(
          {
            [clientId && "client_id"]: clientId,
            [id && "lead"]: id,
            type: "build",
            is_lead: id ? true : false,
          },
          setIsOpen,
          navigate
        )
      );
    }
    if (type === "Invoice") {
      openBuildInvoiceModal();
      // dispatch(
      //   initInvoice(
      //     {
      //       client_id: clientId,
      //       type: "build",
      //     },
      //     setIsOpen,
      //     navigate
      //   )
      // );
    }

    if (type === "Project") {
      onCustomTemplateClick();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="New Template Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <div>
              <div className="text-left">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8 uppercase"
                  id="modal-title"
                >
                  Create {type}
                </h3>
              </div>
            </div>

            <div className="mt-10">
              <div className="flex flex-col gap-3 min-w-[60vw] sm:min-w-[320px]">
                {type !== "Invoice" && (
                  <button
                    onClick={() => {
                      handleDefault(type);
                    }}
                    className="w-full py-4 text-sm font-bold tracking-wider text-center capitalize rounded text-Neutral000 bg-AccentRegular hover:bg-AccentMediumDark"
                  >
                    {type === "Project"
                      ? `CREATE FROM SCRATCH`
                      : `Use Default Template`}
                  </button>
                )}
                {type !== "Project" && (
                  <button
                    onClick={() => {
                      onCustomTemplateClick();
                    }}
                    className="w-full py-4 text-sm font-bold tracking-wider text-center capitalize rounded bg-AccentRegular text-Neutral000 hover:bg-AccentMediumDark"
                  >
                    Custom Template
                  </button>
                )}

                <button
                  className="w-full py-4 text-sm font-bold tracking-wider text-center capitalize rounded bg-AccentLight border-AccentLight hover:bg-Neutral300"
                  onClick={() => handleBuildFromScratch(type)}
                >
                  {/* <img className="mr-3" src={BlockSvg} alt="block icon" /> */}
                  <span className="text-sm font-bold capitalize text-AccentRegular ">
                    {type === "Project"
                      ? `USE A TEMPLATE`
                      : `Build from Scratch`}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setIsOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
      {/* <SelectTemplate
        isOpen={openTemplate}
        setIsOpen={setOpenTemplate}
        type={type}
        clientId={clientId}
      /> */}
    </>
  );
};
