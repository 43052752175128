import React, { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import closePreviewIcon from "../../assets/Images/preview.svg";
import AppEditor from "../FroalaEditor/AppEditor";
import { debounce } from "lodash";
import CloundSuccess from "../../assets/Images/Cloud-success-green.svg";
import CloudLoaderIcon from "../../assets/Images/Cloud-loader.svg";
import copy from "copy-to-clipboard";
import CopyLinkModal from "../Modals/CopyLinkModal";
import { SaveContent, SaveTitle } from "../../store/actions/proposalAction";
import { SaveContractContent, SaveContractTitle } from "../../store/actions/documentsAction";
import { useDispatch } from "react-redux";
import {
  SaveAsTemplateActions,
  UpdateTemplate,
  saveUpdateTemplateActions,
} from "../../store/actions/templateAction";
import { TemplateForm } from "../Templates/TemplateForm/TemplateForm";
import html2pdf from "html2pdf.js";
import { useSelector } from "react-redux";
import syncImages from "../../utils/syncImage";
import ButtonLoader from "../Loaders/ButtonLoader";
import FollowUpEmailModal from "../Modals/FollowUpEmailModal";

const FroalaBuilderComp = (props) => {
  const {
    clientDetail,
    textEditor,
    type,
    isLoading,
    setIsLoading,
    setTextEditor,
    handleReload,
    lead,
    isTemplate,
  } = props;
  const params = useParams();
  const urlType = params.type;

  const user = useSelector((state) => state.auth.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const editor = useRef();
  const dispatch = useDispatch();
  const preview = searchParams.get("preview");
  const { proposalId, contractId } = useParams();
  const [mode, setMode] = useState(preview === "true" ? "preview" : "normal");
  const [isBoxVisible, setIsBoxVisible] = useState(
    preview === "true" ? true : false
  );
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [linkIsOpen, setLinkIsOpen] = useState(false);
  const [copyStatus, setCopyStatus] = useState("Copy Link");
  const [isLoadingSaveAsTemplate, setIsLoadingSaveAsTemplate] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const handleDownloadPDF = async () => {
    setDownloadLoader(true);
    if (document.getElementById("download-content")) {
      document.getElementById("download-content").remove();
    }
    const newElement = document.createElement("div");
    newElement.id = "download-content";
    newElement.style.display = "none";
    newElement.appendChild(
      document.getElementById("froala-editor-view").cloneNode(true)
    );
    document.getElementById("proposal-editor").firstChild.before(newElement);

    const element = document.querySelector(
      "#download-content #froala-editor-view"
    );

    const customBullets = [];

    element.querySelectorAll(".page").forEach((el) => {
      el.style.height = "100%";
      el.style.minHeight = "27cm";
    });

    element.querySelectorAll(".page-break").forEach((el) => {
      el.style.height = "0px";
      el.style.margin = "0px";
      el.style.padding = "0px";
    });

    element.querySelectorAll(".page-break-label").forEach((el) => {
      el.style.display = "none";
    });

    element.querySelectorAll("ul").forEach((ul) => {
      ul.style.listStyleType = "none"; // remove default bullets
    });

    element.querySelectorAll("ul li").forEach((el) => {
      const bullet = document.createElement("span");
      bullet.style.fontSize = "20px";
      bullet.textContent = "• ";
      el.insertBefore(bullet, el.firstChild);
      customBullets.push(bullet); // keep track of custom bullets
    });

    const fileName = `${type.toLowerCase()}_${
      textEditor?.client?.user?.user_name || textEditor?.lead?.user?.user_name
    }_${textEditor?.client?.contact_name || textEditor?.lead?.name}_${
      new Date().toISOString().split("T")[0]
    }.pdf`;

    await syncImages();

    html2pdf(element, {
      margin: 0,
      filename: fileName,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 4, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      // pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    });

    setTimeout(() => {
      element.querySelectorAll(".page-break").forEach((el) => {
        el.style.height = "auto";
        el.style.margin = "15px";
        el.style.padding = "5px 0";
      });

      element.querySelectorAll(".page-break-label").forEach((el) => {
        el.style.display = "block";
      });

      element.querySelectorAll("ul").forEach((ul) => {
        ul.style.listStyleType = "disc"; // revert to original state
      });

      customBullets.forEach((bullet) => {
        bullet.parentNode.removeChild(bullet); // remove custom bullets
      });
    }, 1000);
    setDownloadLoader(false);
  };

  useEffect(() => {
    setIsBoxVisible(preview === "true" ? true : false);
    setMode(preview === "true" ? "preview" : "normal");
  }, [preview]);

  const toggleBox = () => {
    setSearchParams({ preview: !isBoxVisible });
    setMode(isBoxVisible ? "normal" : "preview");
  };

  const handleSaveTemplate = async (id) => {
    setUnsavedChanges(true);
    await dispatch(
      SaveAsTemplateActions(
        {
          type: type === "proposal" ? "Proposal" : "Contract",
          [(type === "proposal" && "proposalId") ||
          (type === "contract" && "contractId")]: id,
        },
        setUnsavedChanges
      )
    );
    handleReload();
  };

  const handleUpdateTemplate = async (id, templateId) => {
    setUnsavedChanges(true);
    await dispatch(
      saveUpdateTemplateActions(
        {
          type: type === "proposal" ? "Proposal" : "Contract",
          [(type === "proposal" && "proposalId") ||
          (type === "contract" && "contractId")]: id,
          [(type === "proposal" && "templateProposalId") ||
          (type === "contract" && "templateContractId")]: templateId,
        },
        setUnsavedChanges
      )
    );
  };

  const renderSaveAsTemplateButton = (id) => (
    <button
      onClick={() => handleSaveTemplate(id)}
      disabled={isLoadingSaveAsTemplate}
      className="text-AccentRegular bg-Neutral000 border border-1 border-AccentRegular flex justify-center items-center uppercase py-3 md:py-4 text-[10px] md:text-xs font-bold leading-4 text-center w-full rounded min-w-[140px]"
    >
      {isLoadingSaveAsTemplate ? (
        <div className="flex justify-center">
          <div className="pr-2 white dot-falling"></div>
        </div>
      ) : (
        "SAVE AS TEMPLATE"
      )}
    </button>
  );

  const renderSavedAsTemplate = () => (
    <div
      // The onClick here is only for demo purposes/testing. Should be removed once backend is integrated
      onClick={() => setUnsavedChanges(true)}
      className="flex items-center gap-y-2.5 min-w-[140px]"
    >
      <svg
        width="13"
        height="9"
        viewBox="0 0 13 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 1L4.28125 7.75L1 4.68182"
          stroke="#34B288"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <p className="text-Green500 text-xs font-semibold leading-4 ml-2.5">
        Saved as template
      </p>
    </div>
  );

  const renderUpdateTemplateButton = (id, templateId) => (
    <p
      onClick={() => handleUpdateTemplate(id, templateId)}
      className="uppercase text-AccentRegular cursor-pointer font-bold text-[10px] leading-[13.6px] tracking-wider"
    >
      Click to update template
    </p>
  );

  const debounceTitle = useRef(
    debounce(async (value) => {
      setTextEditor((prev) => ({ ...prev, title: value?.title }));
      setUnsavedChanges(true);
      if (urlType) {
        const type = urlType[0].toUpperCase() + urlType.slice(1);
        if (urlType == "proposal") {
          await dispatch(
            UpdateTemplate(
              {
                proposalId: params.id,
                data: value?.content,
                type,
                title: value?.title,
              },
              setUnsavedChanges
            )
          );
        } else if (urlType == "contract") {
          await dispatch(
            UpdateTemplate(
              {
                contractId: params.id,
                data: value?.content,
                type,
                title: value?.title,
              },
              setUnsavedChanges
            )
          );
        }
      } else {
        if (type === "proposal") {
          await dispatch(
            SaveTitle(
              {
                title: value?.title,
                client_id: value?.client_id,
                lead_id: value?.lead_id,
                proposal_id: proposalId,
              },
              setUnsavedChanges
            )
          );
        }
        if (type === "contract") {
          await dispatch(
            SaveContractTitle(
              {
                title: value?.title,
                client_id: value?.client_id,
                contract_id: contractId,
              },
              setUnsavedChanges
            )
          );
        }
      }
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debounceTitle.cancel();
    };
  }, [debounceTitle]);

  const handleTitleChange = (e) => {
    const { value } = e.target;
    debounceTitle({
      title: value,
      content: textEditor?.text,
      client_id: clientDetail?.data?._id,
      lead_id: lead?._id,
    });
  };

  const handleChangeCategory = async (categories) => {
    const categoryTags = categories?.map((category) => ({
      name: category?.value,
      color: {
        color: category?.color,
        text: category?.text,
        labelColor: category?.labelColor,
        labelText: category?.labelText,
      },
    }));
    setTextEditor((prev) => ({ ...prev, category: categoryTags }));
    if (urlType) {
      const type = urlType[0].toUpperCase() + urlType.slice(1);
      if (urlType == "proposal") {
        const value = {
          proposalId: params.id,
          type,
          title: textEditor?.title,
        };
        await dispatch(UpdateTemplate(value, setUnsavedChanges));
      } else if (urlType == "contract") {
        const value = {
          contractId: params.id,
          type,
          title: textEditor?.title,
        };
        await dispatch(UpdateTemplate(value, setUnsavedChanges));
      }
    } else {
      if (type === "proposal") {
        await dispatch(
          SaveContent(
            {
              content: textEditor?.text,
              client_id: clientDetail?.data?._id,
              lead_id: lead?._id,
              proposal_id: proposalId,
              categories: categoryTags,
            },
            setUnsavedChanges
          )
        );
      }
      if (type === "contract") {
        await dispatch(
          SaveContractContent(
            {
              content: textEditor?.text,
              client_id: clientDetail?.data?._id,
              contract_id: contractId,
              categories: categoryTags,
            },
            setUnsavedChanges
          )
        );
      }
    }
  };

  return (
    <div
      className={`Proposal-Builder flex flex-col xl:flex-row xl:gap-4 items-start mt-1 md:mt-6 mb-20 md:mb-32 ${
        isBoxVisible ? "preview" : "preview-disable"
      } box-shadow-hidden px-6 md:px-12`}
      id="proposal-editor"
    >
      <div className="flex flex-col mb-5 w-[100%] 5xl:absolute xl:max-w-[220px] 2xl:max-w-[240px]">
        <div className="flex items-center justify-between xl:justify-start flex-wrap gap-4 xl:gap-1">
          <div className="flex flex-row xl:flex-col xl:items-start justify-between gap-8 mb-1 md:mb-8">
            {isLoading ? (
              <div>
                <div>
                  <div className="mt-2">
                    <div className="flex items-center gap-y-4 mb-2">
                      <h6 className="w-[80px] h-[20px] bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></h6>

                      <span className="w-[10px] ml-[8px] h-[20px] bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></span>
                    </div>
                    <h4 className="w-[80px] h-[20px] bg-[#f6f7f8] inline-block bg-no-repeat skeleton-animation relative"></h4>
                  </div>
                </div>
                <div></div>
              </div>
            ) : (
              <>
                {!isBoxVisible && (
                  <TemplateForm
                    type={
                      (type === "proposal" && "Proposal") ||
                      (type === "contract" && "Contract")
                    }
                    handleTitleChange={handleTitleChange}
                    textEditor={textEditor}
                    handleChangeCategory={handleChangeCategory}
                    inRow
                  />
                )}
              </>
            )}
          </div>

          {/* <div className="w-fit min-w-[290px] flex xl:flex-col xl:min-w-[140px] 2xl:min-w-[200px] 5xl:min-w-[240px] gap-3 items-center justify-center"> */}
          <div className="w-full  max-w-[237px] flex xl:flex-col gap-3 items-center justify-center">
            {!isBoxVisible && (
              <div className="flex flex-col gap-2 w-full">
                <button
                  onClick={toggleBox}
                  className={`bg-AccentRegular text-Neutral000 flex justify-center items-center uppercase py-3 md:py-4 text-[10px] md:text-xs font-bold leading-4 text-center rounded min-w-[100px] md:min-w-[142px] w-full ${
                    isBoxVisible ? "hidden" : "flex"
                  }`}
                >
                  VIEW PREVIEW
                </button>

                <div className="flex gap-2 w-full">
                  {!urlType && (
                    <>
                      <button
                        className="px-6	py-3 h-[48px] bg-Neutral200 rounded flex justify-center items-center grow text-AccentRegular text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out hover:bg-Neutral400"
                        type="button"
                        onClick={() => {
                          setLinkIsOpen(true);
                          if (user?.payment?.status === "active") {
                            const link = `${
                              window.location.origin
                            }/public-${type}/${
                              clientDetail?.data?._id || lead?._id
                            }/${lead?._id ? "lead-" : ""}${type}/${
                              proposalId || contractId
                            }`;
                            copy(link);
                            setCopyStatus("Copied");
                            setTimeout(() => {
                              setCopyStatus("Copy Link");
                            }, 1000);
                          }
                        }}
                      >
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.9773 7.4541H17.7045C18.3015 7.4541 18.8925 7.57167 19.444 7.8001C19.9955 8.02853 20.4966 8.36335 20.9187 8.78543C21.3408 9.20752 21.6756 9.70861 21.904 10.2601C22.1324 10.8116 22.25 11.4026 22.25 11.9996C22.25 12.5965 22.1324 13.1875 21.904 13.739C21.6756 14.2905 21.3408 14.7916 20.9187 15.2137C20.4966 15.6358 19.9955 15.9706 19.444 16.199C18.8925 16.4274 18.3015 16.545 17.7045 16.545H14.9773M9.52273 16.545H6.79545C6.19854 16.545 5.60746 16.4274 5.05598 16.199C4.5045 15.9706 4.00342 15.6358 3.58133 15.2137C2.72889 14.3612 2.25 13.2051 2.25 11.9996C2.25 10.794 2.72889 9.63787 3.58133 8.78543C4.43377 7.933 5.58993 7.4541 6.79545 7.4541H9.52273"
                            stroke="#6D6DF2"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8.61328 12H15.886"
                            stroke="#6D6DF2"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>

                      <button
                        className="px-6	py-3 h-[48px] bg-Neutral200 rounded grow text-AccentRegular text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out hover:bg-Neutral400 relative"
                        type="button"
                        disabled={downloadLoader}
                        onClick={handleDownloadPDF}
                      >
                        {downloadLoader ? (
                          <ButtonLoader />
                        ) : (
                          <div className="flex items-center justify-center">
                            <svg
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.25 15.168V18.7235C21.25 19.195 21.0627 19.6472 20.7293 19.9806C20.3959 20.314 19.9437 20.5013 19.4722 20.5013H7.02778C6.55628 20.5013 6.1041 20.314 5.7707 19.9806C5.4373 19.6472 5.25 19.195 5.25 18.7235V15.168"
                                stroke="#6D6DF2"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.80518 10.7217L13.2496 15.1661L17.6941 10.7217"
                                stroke="#6D6DF2"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M13.25 15.1662V4.49951"
                                stroke="#6D6DF2"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        )}
                      </button>
                    </>
                  )}
                </div>

                {/* In a template, the client's information doesn't exist, so the mail can't be sent, so this button will be hidden, if it's a template */}
                <button
                  onClick={() => setIsEmailModalOpen(true)}
                  className={`bg-AccentRegular text-Neutral000 flex justify-center items-center uppercase py-3 md:py-4 text-[10px] md:text-xs font-bold leading-4 text-center rounded min-w-[100px] md:min-w-[142px] w-full ${
                    isTemplate ? "hidden" : "block"
                  }`}
                >
                  SEND AS EMAIL
                </button>

                {/* TODO: check old code, when need add functionality */}
                {!textEditor?.template_to
                  ? textEditor?.title &&
                    renderSaveAsTemplateButton(textEditor?._id)
                  : unsavedChanges
                  ? renderSavedAsTemplate()
                  : renderUpdateTemplateButton(
                      textEditor?._id,
                      textEditor?.template_to
                    )}
              </div>
            )}

            {/* set loader */}
            <div className="w-full">
              {unsavedChanges ? (
                <span className="text-[#757580] text-xs font-semibold leading-4 ml-2.5 flex items-center">
                  <img src={CloudLoaderIcon} alt="" className="mr-1 w-[35px]" />
                  Saving...
                </span>
              ) : (
                <span className="text-Green500 text-xs font-semibold leading-4 ml-2.5 flex items-center">
                  <img src={CloundSuccess} alt="" className="mr-1 w-[35px]" />
                  Saved
                </span>
              )}
            </div>

            {linkIsOpen && (
              <CopyLinkModal
                setCopyStatus={setCopyStatus}
                clientDetail={clientDetail}
                linkIsOpen={linkIsOpen}
                setLinkIsOpen={setLinkIsOpen}
                type={type}
                id={proposalId || contractId}
                lead={lead}
                handleEmailModal={() => {
                  setIsEmailModalOpen(true);
                  setLinkIsOpen(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <AppEditor
        mode={mode}
        model={textEditor?.text}
        type={type}
        editor={editor}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        textEditor={textEditor}
        setTextEditor={setTextEditor}
        setUnsavedChanges={setUnsavedChanges}
        clientDetail={clientDetail}
      />
      <div className="custom-wow-alert fixed bottom-[30px] right-[30px] z-300 w-[90px] text-[12px] text-Neutral800">
        Wow, you have a big screen 🙂
      </div>
      {/* <AppEditor mode="ghost" model={model} setEditorRef={setEditorRef} setHandleDownload={setHandleDownload} /> */}

      <button
        onClick={toggleBox}
        className={`max-w-[176px] text-sm font-medium cursor-pointer h-12 5xl:h-14 text-Neutral800  bg-white items-center justify-center  rounded py-2 px-4 border-[1.5px] border-Neutral300 focus-visible:outline-none transition duration-300 w-full ${
          isBoxVisible ? "flex" : "hidden"
        }`}
      >
        Close Preview{" "}
        <img className="w-3 h-3 ml-3" src={closePreviewIcon} alt="" />
      </button>

      <FollowUpEmailModal
        isOpen={isEmailModalOpen}
        setIsOpen={setIsEmailModalOpen}
        type={type === "proposal" ? "Proposal" : "Contract"}
        id={proposalId || contractId}
        data={clientDetail?.data}
        selectedData={textEditor}
        IsDashboard="froala"
        setTextEditor={setTextEditor}
      />
    </div>
  );
};

export default FroalaBuilderComp;
