import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const CollabMenuOfColumns = ({
  types,
  clientDetail,
  column,
  columnsName,
  handleClickColumnChange,
  item,
  access_level
}) => {
  const titleDropDownref = useRef(null);
  const [customeTitle, setCustomeTitle] = useState("");
  const [titledata, setTitleData] = useState( clientDetail?.task_screen 
  );
  const user = useSelector((state) => state?.collab_auth?.user);
  
  useEffect(() => {
    setTitleData(clientDetail?.task_screen
    );
  }, [clientDetail]);

  return (
    <Menu
      as="div"
      className={`rounded-t	w-fit py-1.5 pl-4 pr-2 bg-${
        column?.color
          ? column?.color
          : columnsName?.find((c) => c.name === column?.name)?.color
      }200 inline-block text-left z-20 relative`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{
        background: column?.color + "70",
      }}
    >
      <Menu.Button
        ref={titleDropDownref}
        className={"flex items-center justify-center gap-2 "}
        disabled={
            access_level !== "full_access"
        }
      >
        {column?.name}
        {( access_level === "full_access" &&
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7.5L10 12.5L5 7.5"
            stroke="#173B82"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute z-[999] top-[100%] -left-4 pt-3 pb-2 w-[216px] px-2 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none">
          <div className="">
            <input
              className="font-normal text-xs placeholder:text-Neutral600 text-Neutral700 px-2 focus:outline-none w-full mb-3"
              placeholder="Select Task Column"
              value={customeTitle}
              onKeyUp={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Enter") {
                  if (
                    titledata?.filter((t) => t === customeTitle).length === 0
                  ) {
                    setTitleData([...titledata, customeTitle]);
                    setCustomeTitle("");
                    setTimeout(() => {
                      titleDropDownref?.current?.click();
                    }, 0);
                  } else {
                    toast.error("Title already exists");
                  }
                }
              }}
              onChange={(e) => {
                setCustomeTitle(e.target.value.toUpperCase());
              }}
            />
          </div>
          <div className="project-title-divide">
            <div className="overflow-hidden border-0 h-[100%] overflow-y-auto currancy-scrollbar scrollbar-w-4 relative flex flex-col gap-1">
              {columnsName.map((items, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center hover:bg-Neutral200 rounded cursor-pointer group duration-300 py-1 px-2 rounded-sm	"
                    onClick={(e) => {
                      // TODO: change column
                      handleClickColumnChange(items);

                      // setIsGeneral(items);
                      setTimeout(() => {
                        titleDropDownref?.current?.click();
                        e.stopPropagation();
                      }, 0);

                      // dispatch(
                      //   ChangeColumnPosition(
                      //     {
                      //       id: clientId,
                      //       columns: items.name,
                      //       // type: types,
                      //     },
                      //     navigate
                      //   )
                      // );
                    }}
                  >
                    <p className="text-Neutral800 group-hover:text-Neutral900 font-normal w-full rounded cursor-pointer flex gap-2 items-center ">
                      <span
                        className={`w-2 h-2 rounded-full	bg-${items?.color}400`}
                      ></span>
                      <span className="font-medium	text-xs	">{items.name}</span>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default CollabMenuOfColumns;
