import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { GetProjectDetailAction } from "./projectAction";
import { StopTimer } from "./timerAction";

export const CreateNewProjectActions = (
  value,
  setSubmitting,
  setErrors,
  closeProjectModal
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/time-tracking/create-tracking-project",
        value
      );
      setSubmitting(false);
      closeProjectModal();
      await dispatch(GetProjectClientsList());
      await dispatch(GetProjectAction({ page: 1, perPage: 10 }));
      return dispatch({
        type: "CREATE_NEW_PROJECT_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
        closeProjectModal();
        return dispatch({
          type: "CREATE_NEW_PROJECT_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        closeProjectModal();
        return dispatch({
          type: "CREATE_NEW_PROJECT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const AddTimeEntryActions = (
  value,
  setSubmitting,
  setErrors,
  setIsOpen,
  setTempType,
  setAddNewTimeEntry,
  setTimeEntryList,
  setProjectId
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/time-tracking/create-time-entry",
        value
      );
      if (value?.type === "timer") {
        await dispatch(StopTimer());
        await dispatch({
          type: "RELOAD_TIME_ENTRY_SUCCESS",
        });
      }
      await setSubmitting(false);
      await setIsOpen(false);
      if (setProjectId) await setProjectId(null);
      if (setAddNewTimeEntry) setAddNewTimeEntry(value?.project);
      if (setTempType) {
        await setTempType({
          client: "",
          title: "",
          category: [],
          date: moment().format("YYYY-MM-DD"),
          time: "",
          project: "",
        });
      }
      // await dispatch(GetProjectAction({ page: 1, perPage: 10 }));
      await dispatch(GetProjectDetailAction(value?.project));
      const timeEntryList = await GetTimeEntryAction({
        id: value?.project,
        page: 1,
        perPage: 10,
      });
      setTimeEntryList && setTimeEntryList(timeEntryList);
      return dispatch({
        type: "CREATE_TIME_ENTRY_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      console.log("error", error);
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
        return dispatch({
          type: "CREATE_TIME_ENTRY_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        return dispatch({
          type: "CREATE_TIME_ENTRY_ERROR",
          payload: error,
        });
      }
    }
    // }
  };
};

export const EditTimeEntryActions = (
  value,
  setSubmitting,
  setErrors,
  setIsOpen,
  setTempType,
  setTimeEntryList
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/time-tracking/edit-time-entry",
        value
      );
      setIsOpen(false);
      setSubmitting(false);
      await setTempType({
        client: "",
        title: "",
        category: [],
        date: moment().format("YYYY-MM-DD"),
        time: "",
        project: "",
      });
      await dispatch(GetProjectDetailAction(value?.project));
      const timeEntryList = await GetTimeEntryAction({
        id: value?.project,
        page: 1,
        perPage: 10,
      });
      setTimeEntryList(timeEntryList);
      await dispatch(GetProjectAction({ page: 1, perPage: 10 }));
      return dispatch({
        type: "UPDATE_TIME_ENTRY_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error?.response?.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
        return dispatch({
          type: "UPDATE_TIME_ENTRY_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        return dispatch({
          type: "UPDATE_TIME_ENTRY_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetTimeEntryAction = async ({ id, page, perPage }) => {
  try {
    let { data } = await axios.get(
      `api/v1/time-tracking/get-time-entry/${id}?page=${page}&limit=${perPage}`
    );
    return data.data;
  } catch (error) {
    if (error.response.status === 422) {
      toast.error("Looks like Client ID is not valid");
      return;
    } else {
      toast.error(error.response.data.message);
      return;
    }
  }
};

export const GetProjectTimeEntryAction = async (id, values) => {
  try {
    let { data } = await axios.get(
      `api/v1/time-tracking/get-project-time-entry/${id}?start_date=${values?.start_date}&end_date=${values?.end_date}&page=${values?.page}&limit=${values?.perPage}`
    );
    return data.data;
  } catch (error) {
    if (error.response.status === 422) {
      toast.error("Looks like Client ID is not valid");
      return;
    } else {
      toast.error(error.response.data.message);
      return;
    }
  }
};

export const GetProjectAction = ({ page, perPage }) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/time-tracking/get-project-list?page=${page}&limit=${perPage}`
      );
      return dispatch({
        type: "SET_TRACKING_PROJECT",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        toast.error("Looks like Client ID is not valid");
        return dispatch({
          type: "SET_TRACKING_PROJECT_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "SET_TRACKING_PROJECT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetProjectClientsList = () => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/time-tracking/get-project-client-list`
      );
      return dispatch({
        type: "SET_PROJECT_CLIENT_LIST",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        return dispatch({
          type: "SET_PROJECT_CLIENT_LIST_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "SET_PROJECT_CLIENT_LIST_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateProjectTitle = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/time-tracking/update-project-title",
        value
      );
      return dispatch({
        type: "UPDATE_TITLE_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "UPDATE_TITLE_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "UPDATE_TITLE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteProject = (id) => {
  return async (dispatch) => {
    try {
      await axios.get(`api/v1/time-tracking/delete-project/${id}`);
      return dispatch({
        type: "DELETE_PROJECT_SUCCESS",
        payload: { id: id },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_PROJECT_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_PROJECT_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const DeleteTimeEntry = (
  id,
  setTimeEntryList,
  project,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      await axios.get(`api/v1/time-tracking/delete-time-entry/${id}`);
      await dispatch(GetProjectDetailAction(project));
      const timeEntryList = await GetTimeEntryAction({
        id: project,
        page: 1,
        perPage: 10,
      });
      setTimeEntryList && setTimeEntryList(timeEntryList);
      setIsLoading(false);
      await dispatch(GetProjectAction({ page: 1, perPage: 10 }));

      return dispatch({
        type: "DELETE_TIME_ENTRY_SUCCESS",
        payload: { id: id },
      });
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_TIME_ENTRY_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_TIME_ENTRY_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const exportTimeEntryInCSV = (value, setisSubmitting, setIsOpen) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        "api/v1/time-tracking/export-time-entry-csv",
        {
          // type: value.duration,
          start_date: value.startDate,
          end_date: value.endDate,
        }
      );

      // convert into csv
      const items = data?.data;
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]);

      const csv = [
        header.join(","),
        ...items.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");

      // Download csv file
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      a.setAttribute("href", url);

      a.setAttribute("download", `export-CSV.csv`);
      a.click();

      setisSubmitting(false);
      setIsOpen(false);
      return dispatch({
        type: "EXPORT_TIME_ENTRY_SUCCESS",
        payload: { data: csv },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "EXPORT_TIME_ENTRY_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "EXPORT_TIME_ENTRY_ERROR",
          payload: { error },
        });
      }
    }
  };
};
