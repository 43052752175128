import { Switch } from "@headlessui/react";

const SlideSwitch = ({
  checked,
  onChange,
  size = 14,
  enabledColor = "bg-AccentRegular",
  disabledColor = "bg-AccentMediumLight",
  knobSize = 10,
  padding = 2,
}) => {
  const width = size * 1.93;
  const knobTranslate = width - knobSize - padding;

  return (
    <div className="flex items-center">
      <Switch
        checked={checked}
        onChange={onChange}
        style={{ height: `${size}px`, width: `${width}px` }}
        className={`${checked ? enabledColor : disabledColor}
          relative inline-flex shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none items-center`}
      >
        <span className="sr-only">
          Toggle between simplified view and normal view
        </span>
        <span
          aria-hidden="true"
          className={`pointer-events-none inline-block transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          style={{
            height: `${knobSize}px`,
            width: `${knobSize}px`,
            transform: checked
              ? `translateX(${knobTranslate}px)`
              : `translateX(${padding}px)`,
          }}
        />
      </Switch>
    </div>
  );
};

export default SlideSwitch;
