import React from "react";
import Modal from "react-modal";
import CloseBtn from "../../assets/Images/close-btn.svg";
import BgShape1 from "../../components/Icons/BgShape1";
import BgShape2 from "../../components/Icons/BgShape2";
import BgShape3 from "../../components/Icons/BgShape3";
import RoundLogo from "../../assets/Images/Round-logo-requestInfo.svg";
import UploadFiles from "../Inputs/UploadFiles";
import ImgIcon from "../../assets/Images/img-icon.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
    overflowY: "auto",
  },
  content: {
    position: "unset",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const questionsList = [
  {
    id: 1,
    title: "What does your business do?",
    placeholder: "Eg. We sell import-grade electronic products",
    question: "Your 5-second elevator pitch.",
    isEdit: false,
    type: "text",
  },
  {
    id: 2,
    title:
      "What are your most popular services or products? How many do you sell each month?",
    placeholder: "",
    question: "Our headphone range. ~120 pairs each month.",
    isEdit: false,
    type: "text",
  },
  {
    id: 3,
    title: "How much is an average client/customer worth to your business?",
    placeholder: "",
    question: "$3000 since most are returning customers",
    type: "text",
    isEdit: false,
  },
  {
    id: 4,
    title: "What is your main goal for a website visitor?",
    placeholder:
      "Eg. To place an order, schedule a call, get their email, etc.",
    question: "Add as many goals as you like, by importance.",
    type: "text",
    isEdit: false,
  },
  {
    id: 5,
    title: "What makes you unique from your competitors?",
    placeholder: "Eg. Price, services, experience, etc.?",
    question: "Be as descriptive as you need to be here.",
    type: "text",
    isEdit: false,
  },
  {
    id: 6,
    title: "How do you currently acquire new customers/clients?",
    placeholder: "Eg. Facebook, Google Ads, Instagram, SEO, etc.",
    question:
      "What type of marketing are you doing and how much are you currently paying per month?, Eg. Facebook, Google Ads, Instagram, SEO, etc.",
    type: "text",
    isEdit: false,
  },
  {
    id: 7,
    title:
      "Do you have a logo, images and/or a brand identity we could use for website/marketing purposes?",
    placeholder: "Eg. Lots and lots of mango images",
    question:
      "Upload each file with an appropriate file name. This helps us keep things organised and streamlined to avoid interrupting you :) ",
    type: "files",
    isEdit: false,
  },
  {
    id: 8,
    title: "Profile Image",
    placeholder: "",
    question:
      "Upload a picture that represents you or your business. This helps us to build a more complete profile.",
    type: "profile",
    isEdit: false,
  },
  {
    id: 9,
    title:
      "Do you currently have website hosting (including a domain) or do you need us to provide this for you?",
    placeholder:
      "Eg. I have a domain name but no hosting. Yes, please provide me with hosting.",
    question:
      "I have a domain name but no hosting. Yes, please provide me with hosting.",
    type: "text",
    isEdit: false,
  },
  {
    id: 10,
    title: "When would you like this project completed?",
    placeholder: "Eg. Middle of next month",
    question:
      "You don’t have to provide a specific date. Weekly or monthly range works too. This helps us understand the realistic scope for your project.",
    type: "text",
    isEdit: false,
  },
  {
    id: 11,
    title: "Please provide necessary passwords to platforms.",
    placeholder: "This is my Hosting/Analytics/WordPress/FTP/etc login",
    question:
      "Providing a URL to a Google Doc with your passwords is recommended",
    type: "text",
    isEdit: false,
  },
  {
    id: 12,
    title: "Is there any competitor website you like?",
    placeholder: "The layout, colours and design",
    question: "What do you like about it?",
    type: "text",
    isEdit: false,
  },
  {
    id: 13,
    title: "Is there anything else you’d like us to know about?",
    placeholder: "This is my Hosting/Analytics/WordPress/FTP/etc login",
    question: "Please include XYZ",
    type: "text",
    isEdit: false,
  },
];

function QuestionnaireFormPreviewTemplate({
  modalIsOpen,
  setModal,
  editedText,
}) {
  const formData = questionsList;

  var re =
    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi;

  const replaceEmail = (text) => {
    const onlyEmail = text.match(re);
    let newText = text;
    if (onlyEmail) {
      onlyEmail.forEach((email) => {
        newText = newText.replace(
          email,
          `<strong class="text-AccentRegular transition duration-300 text-color-4">${email}</strong>`
        );
      });
      return newText;
    }
    return text;
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={setModal}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="View Form Preview Modal"
      >
        <div className="view-form-prev relative bg-white text-left transform transition-all sm:my-8 max-w-[604px] min-w-[604px] mx-auto sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-[60px] pt-[60px] pb-[24px] rounded-[8px] relative overflow-hidden">
            <div
              className={`absolute top-0 left-0 h-full bg-shap-main box-1 color-4`}
            >
              <div className="bg-shape-1 opacity-0 transition duration-300 absolute top-0 left-0">
                <BgShape1 />
              </div>
              <div className="bg-shape-2 opacity-0 transition duration-300 absolute top-0 left-0">
                <BgShape2 />
              </div>
              <div className="bg-shape-3 opacity-0 transition duration-300 absolute top-0 left-0">
                <BgShape3 />
              </div>
            </div>
            <div>
              <div className="border-b border-Neutral400">
                <h2 className="text-black font-bold text-[20px] 5xl:text-[24px] mb-5">
                  Preview Form
                </h2>

                <p
                  className="text-xs text-Neutral800 pb-10"
                  dangerouslySetInnerHTML={{
                    __html: replaceEmail(editedText),
                  }}
                ></p>
              </div>
              {formData?.map((item, index) => (
                <div className="pt-[48px]" key={item._id}>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-black font-bold text-base">
                      {item.title}
                    </h3>
                    <div
                      className={`flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] text-AccentRegular transition duration-300 text-color-4`}
                    >
                      {index + 1}
                    </div>
                  </div>
                  <p className="text-xs text-Neutral800 mb-4">
                    {item.question}{" "}
                    {item?.is_required && (
                      <strong
                        className={`text-AccentRegular transition duration-300 text-color-4`}
                      >
                        *
                      </strong>
                    )}
                    <strong
                      className={`text-AccentRegular transition duration-300 text-color-4`}
                    >
                      {/* {item.email} */}
                    </strong>{" "}
                    {/* {item.extdetails} */}
                  </p>
                  {(item.type === "files" || item?.type === "file") && (
                    <div className="flex justify-between">
                      <div className="w-full max-w-[50%] pr-2">
                        <UploadFiles
                          label={
                            (item?.type === "files" && "Upload Files") ||
                            (item?.type === "file" && "Upload File")
                          }
                          id={
                            (item?.type === "files" && "files") ||
                            (item?.type === "file" && "file")
                          }
                          name={
                            (item?.type === "files" && "files") ||
                            (item?.type === "file" && "file")
                          }
                          icon={ImgIcon}
                        />
                      </div>
                    </div>
                  )}
                  {item.type === "profile" && (
                    <div className="flex justify-between">
                      <div className="w-full max-w-[50%] pr-2">
                        <UploadFiles
                          label="Upload Profile"
                          id="profile"
                          name="profile"
                          icon={ImgIcon}
                        />
                      </div>
                      <div className="w-full max-w-[50%] pr-2"></div>
                    </div>
                  )}
                  {item.type === "text" && (
                    <textarea
                      className="mt-4 border border-Neutral400 w-full resize-none p-6 h-[168px] placeholder:text-sm 5xl:placeholder:text-lg placeholder:font-medium font-medium focus:outline-none rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                      placeholder={item.placeholder}
                    />
                  )}
                </div>
              ))}
              <div className="pt-[52px] flex items-center justify-center">
                <img src={RoundLogo} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className={`${modalIsOpen ? "block" : "hidden"}`}>
        <div
          className="fixed right-12 cursor-pointer top-[62px] z-[999999]"
          onClick={() => setModal(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </>
  );
}

export default QuestionnaireFormPreviewTemplate;
