import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import * as Yup from "yup";
import Step1 from "../../components/CreateInvoiceForm/Step1";
import Step2 from "../../components/CreateInvoiceForm/Step2";
import Step3 from "../../components/CreateInvoiceForm/Step3";
import Step4 from "../../components/CreateInvoiceForm/Step4";
import Step5 from "../../components/CreateInvoiceForm/Step5";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import { GetClientDetailAction } from "../../store/actions/clientAction";
import {
	PaypalOrderCreate,
	PaypalOrderUpdate,
	createInvoice,
	getInvoiceById,
	stripeOrderCreate,
	updateInvoice,
} from "../../store/actions/invoicesAction";
import { getTagColor } from "../../utils/getTagColor";
import InvoiceLoader from "../../components/ContentLoaders/InvoiceLoader";
import EditInvoiceAndInvoiceTemplateModal from "../../components/Modals/EditInvoiceAndInvoiceTemplateModal";
import moment from "moment";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const allRequiredFields = [
	"invoiceName",
	"categoryTags",
	"clientName",
	"clientEmail",
	"clientCompanyName",
	"clientPhoneNumber",
	"clientAddress",
	"email",
	"fullName",
	"companyName",
	"address",
	"phoneNumber",
	"invoiceNumber",
	"currency",
	"invoiceDate",
	"dueDate",
	"services",
	"taxes",
	"paymentMethod",
	"bankDetails",
	"billingType",
];

const validationSchema = Yup.object().shape({
	// step
	step: Yup.number(),

	//template details
	invoiceName: Yup.string().when("step", {
		is: (val) => val === 1,
		then: Yup.string().required("Invoice Name is Required").nullable(),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	categoryTags: Yup.array().when("step", {
		is: (val) => val === 1,
		then: Yup.array().min(1, "Category Tags is Required"),
		otherwise: Yup.array().notRequired(),
	}),
	//client details
	clientName: Yup.string().when("step", {
		is: (val) => val === 2,
		then: Yup.string().required("Client Name is Required").nullable(),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	clientEmail: Yup.string().when("step", {
		is: (val) => val === 2,
		then: Yup.string().nullable().email("Client Email is Invalid"),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	clientCompanyName: Yup.string().when("step", {
		is: (val) => val === 2,
		then: Yup.string().nullable(),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	clientPhoneNumber: Yup.string().when("step", {
		is: (val) => val === 2,
		then: Yup.string(),
	}),
	clientAddress: Yup.string().when("step", {
		is: (val) => val === 2,
		then: Yup.string().nullable(),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	clientLogo: Yup.string(),
	clientVAT: Yup.string(),
	//your details
	email: Yup.string().when("step", {
		is: (val) => val === 3,
		then: Yup.string().required("Email is Required").nullable(),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	fullName: Yup.string().when("step", {
		is: (val) => val === 3,
		then: Yup.string().required("Full Name is Required").nullable(),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	companyName: Yup.string().when("step", {
		is: (val) => val === 3,
		then: Yup.string().nullable(),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	address: Yup.string().when("step", {
		is: (val) => val === 3,
		then: Yup.string().nullable(),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	phoneNumber: Yup.string().when("step", {
		is: (val) => val === 3,
		then: Yup.string(),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	VAT: Yup.string(),
	//invoice details
	invoiceNumber: Yup.string().when("step", {
		is: (val) => val === 4,
		then: Yup.string().required("Invoice Number is Required"),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	currency: Yup.string().when("step", {
		is: (val) => val === 4,
		then: Yup.string().required("Currency is Required"),
		otherwise: Yup.string().notRequired(),
	}),
	invoiceDate: Yup.string().when("step", {
		is: (val) => val === 4,
		then: Yup.string().required("Invoice Date is Required"),
		otherwise: Yup.string().notRequired(),
	}),
	dueDate: Yup.string().when("step", {
		is: (val) => val === 4,
		then: Yup.string()
			.required("Due Date is Required")
			.when("invoiceDate", {
				is: (val) => val !== "",
				then: Yup.string().test(
					"is-greater",
					"Due Date must be greater than Invoice Date",
					function (value) {
						const { invoiceDate } = this.parent;
						const invoiceDateObj = new Date(invoiceDate).getTime();
						const dueDateObj = new Date(value).getTime();
						return dueDateObj > invoiceDateObj;
					},
				),
			}),
		otherwise: Yup.string().notRequired(),
	}),
	vatPercentage: Yup.number().when("step", {
		is: (val) => val === 4,
		then: Yup.number().when("is_VAT", {
			is: (val) => val === true,
			then: Yup.number()
				.transform((value) => (Number.isNaN(value) ? null : value))
				.nullable(),
			otherwise: Yup.number().notRequired().nullable(),
		}),
		otherwise: Yup.number().notRequired().nullable(),
	}),
	is_VAT: Yup.boolean(),
	taxes: Yup.array().when("step", {
		is: (val) => val === 4,
		then: Yup.array().when("is_VAT", {
			is: (val) => val === false,
			then: Yup.array().of(
				Yup.object().shape({
					taxName: Yup.string().required("Tax Name is Required"),
					taxValue: Yup.number()
						.required("Tax Value is Required")
						.min(0, "Tax Value must be greater than 0")
						.nullable(),
				}),
			),
			otherwise: Yup.array().notRequired(),
		}),
		otherwise: Yup.array().notRequired(),
	}),
	services: Yup.array().when("step", {
		is: (val) => val === 4,
		then: Yup.array()
			.min(1, "Minimum 1 Service is Required")
			.of(
				Yup.object().shape({
					serviceType: Yup.string()
						.required("Service Type is Required")
						.oneOf(["Hourly", "Fixed"]),
					serviceName: Yup.string().required("Service Name is Required"),
					servicePrice: Yup.number()
						.required("Service Price is Required")
						.min(1, "Service Price must be greater than 0")
						.nullable(), //price per hour
					quantity: Yup.number().when("serviceType", {
						is: (val) => val === "Hourly",
						then: Yup.number()
							.required("Quantity is Required")
							.min(1, "Quantity must be greater than 0")
							.nullable(), //hours spent
						otherwise: Yup.number().notRequired().nullable(),
					}),
				}),
			),
		otherwise: Yup.array().notRequired(),
	}),
	//payment details
	discount: Yup.number().nullable(),
	paymentMethod: Yup.string().when("step", {
		is: (val) => val === 5,
		then: Yup.string().required("Payment Method is Required").nullable(),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	bankDetails: Yup.string().when("step", {
		is: (val) => val === 5,
		then: Yup.string().when("paymentMethod", {
			is: (val) => val === "Bank",
			then: Yup.string().required("Bank Details is Required").nullable(),
			otherwise: Yup.string().notRequired().nullable(),
		}),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	paymentLink: Yup.string()
		.url("Invalid URL")
		.when("paymentMethod", {
			is: (val) => val === "Paypal" || val === "Stripe",
			then: Yup.string().url("Invalid URL").required("Payment Link is Required").nullable(),
			otherwise: Yup.string().notRequired().nullable(),
		}),
	billingType: Yup.string().required("Required"),
	interval: Yup.string().when("billingType", {
		is: (val) => val === "recurring",
		then: Yup.string().required("Required"),
		otherwise: Yup.string().notRequired(),
	}),
	repeats: Yup.number().when("billingType", {
		is: (val) => val === "recurring",
		then: Yup.number().required("Required"),
		otherwise: Yup.number().notRequired(),
	}),
	startDate: Yup.string().when("billingType", {
		is: (val) => val === "recurring",
		then: Yup.string().required("Required").nullable(),
		otherwise: Yup.string().notRequired().nullable(),
	}),
	status: Yup.string().required("Required"), //draft, template, sent, paid, overdue, cancelled
});

const getEndDate = (startDate, interval, repeats, userDateFormat) => {
	const date = new Date(startDate);

	repeats = Number(repeats); // Ensure repeats is a number

	if (interval === "weekly") {
		date.setDate(date.getDate() + repeats * 7);
	}
	if (interval === "bimonthly") {
		const totalMonths = Math.floor(repeats / 2);
		date.setMonth(date.getMonth() + totalMonths);
		if (repeats % 2 === 0) {
			date.setDate(16);
		} else {
			date.setDate(1);
		}
	}
	if (interval === "monthly") {
		date.setMonth(date.getMonth() + repeats);
	}
	if (interval === "yearly") {
		date.setFullYear(date.getFullYear() + repeats);
	}

	return moment(date).format(userDateFormat);
};

const isStepComplete = (step, values) => {
	if (step === 1) {
		return values.invoiceName !== "" && values.categoryTags !== "";
	} else if (step === 2) {
		return (
			values.clientName !== "" &&
			values.clientEmail !== "" &&
			values.clientCompanyName !== "" &&
			values.clientAddress !== ""
		);
	} else if (step === 3) {
		return (
			values.email !== "" &&
			values.fullName !== "" &&
			values.companyName !== "" &&
			values.address !== "" &&
			values.VAT !== ""
		);
	} else if (step === 4) {
		return (
			values.invoiceNumber !== "" &&
			values.currency !== "" &&
			values.invoiceDate !== "" &&
			values.dueDate !== "" &&
			values.vatPercentage !== "" &&
			values.services.length > 0 &&
			values.services[0].serviceName !== "" &&
			values.categoryTags.length > 4
		);
	} else if (step === 5) {
		if (values.discount === "") return false;
		if (values.paymentMethod === "") return false;
		if (values.paymentMethod === "Paypal" || values.paymentMethod === "Stripe") {
			if (!values.paymentLink || values.paymentLink === "") return false;
		} else {
			if (values.bankDetails === "") return false;
		}
		if (!isStepComplete(4, values)) return false;
		return true;
	}
};

function CreateInvoice() {
	const { clientId, invoiceId } = useParams();
	const ref = useRef();
	const query = new URLSearchParams(window.location.search);
	const invoiceType = query.get("type");
	const invoiceInterval = query.get("interval");
	const invoiceRepeats = query.get("repeats");
	const invoiceStartDate = query.get("startDate");

	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const cardId = params.get("code");
	const [searchParams, setSearchParams] = useSearchParams();

	const [initialValues, setInitialValues] = useState({
		invoiceName: "",
		categoryTags: "",
		clientName: "",
		clientEmail: "",
		clientCompanyName: "",
		clientPhoneNumber: "",
		clientAddress: "",
		clientLogo: "",
		clientVAT: "",
		email: "",
		fullName: "",
		companyName: "",
		address: "",
		phoneNumber: "",
		VAT: "",
		invoiceNumber: "",
		currency: "",
		invoiceDate: new Date(),
		dueDate: new Date().setDate(new Date().getDate() + 10),
		vatPercentage: null,
		is_VAT: true,
		taxes: [
			{
				taxName: "",
				taxValue: null,
			},
		],
		services: [],
		discount: null,
		paymentMethod: "Bank",
		paymentLink: null,
		bankDetails: "",
		billingType: "standard",
		interval: 1,
		repeats: 1,
		startDate: "22/09/2023",
		status: "draft",
	});

	const isTemplate = window.location.pathname.includes("invoice-creator");
	const [type, setType] = useState(invoiceType || "standard");
	const [interval, setInterval] = useState(invoiceInterval || "weekly");
	const [repeats, setRepeats] = useState(invoiceRepeats || 1);
	const [startDate, setStartDate] = useState(invoiceStartDate || new Date().toLocaleDateString());
	const [endDate, setEndDate] = useState();
	const [selectedDueDate, setSelectedDueDate] = useState(
		isTemplate
			? moment(initialValues.dueDate, "DD/MM/YYYY").toDate()
			: moment(initialValues.dueDate).toDate(),
	);
	const [isOpenTemplateModal, setIsOpenTemplateModal] = useState(false);

	const dispatch = useDispatch();
	const location = useLocation();
	const { state } = location;
	const navigate = useNavigate();
	const clientDetail = useSelector((state) => state.client.clientDetail);

	const [currentStep, setCurrentStep] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingStripeLink, setLoadingStripeLink] = useState(false);
	const [loadingPaymentLink, setLoadingPaymentLink] = useState();

	const invoice = useSelector((state) => state.invoice.invoice);
	const user = useSelector((state) => state.auth.user);
	const logedinUser = useSelector((state) => state.auth.user);
	const userDateFormat = logedinUser?.date_format;

	const [showRecurringBannerMenu, setShowRecurringBannerMenu] = useState(false);
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
	const [isCreateInvoice, setIsCreateInvoice] = useState(false);
	const modalColor = "Pink";

	useEffect(() => {
		if (!clientId) return;

		if (state) {
			dispatch({
				type: "SET_CLIENT_DETAIL",
				payload: { isLoading: false, isUpdating: false, data: state },
			});
		} else {
			if (clientId !== clientDetail.data._id) {
				dispatch(GetClientDetailAction(clientId, navigate));
			}
		}
	}, [clientId, dispatch, navigate]);

	useEffect(() => {
		if (invoiceId) {
			setIsLoading(true);
			dispatch(getInvoiceById(clientId, invoiceId, setIsLoading));
		}
	}, [dispatch, invoiceId]);

	useEffect(() => {
		if (invoiceId && invoice) {
			setInitialValues({
				...invoice,
				categoryTags: invoice.categoryTags?.length
					? invoice?.categoryTags?.map((item, i) => {
							const { bgColor: optionColor, text: optionText } = getTagColor(
								modalColor,
								i,
								true,
							);

							const { bgColor: labelColor, text: labelText } = getTagColor(
								modalColor,
								i,
								true,
							);

							return {
								value: item?.name,
								label: item?.name,
								color: optionColor,
								text: optionText,
								labelColor,
								labelText,
							};
					  })
					: [],
				invoiceDate:
					invoice.invoiceDate !== "" && invoice.invoiceDate
						? invoice.invoiceDate
						: initialValues?.invoiceDate,
				dueDate: invoice.dueDate !== "" && invoice.dueDate ? invoice.dueDate : selectedDueDate,
				paymentMethod: invoice.paymentMethod || "Bank",
				paymentLink: invoice.paymentLink,
				bankDetails: invoice.bankDetails,
				taxes: invoice.taxes?.length ? invoice.taxes : initialValues?.taxes,
				services: invoice.services?.length
					? invoice.services.map((item) => {
							return {
								serviceType: item.serviceType,
								serviceName: item.serviceName,
								servicePrice: item.servicePrice,
								quantity: item.quantity,
							};
					  })
					: [],
				vatPercentage: invoice.vatPercentage === 0 ? null : invoice.vatPercentage || null,
				discount: invoice.discount === 0 ? null : invoice.discount || null,
				invoiceNumber: invoice.invoiceNumber || "",
				billingType: invoice.billingType || "standard",
				status: invoice.status || "draft",
				currency: invoice.currency || "USD",
			});
			if (invoice.billingType === "recurring") {
				setType(invoice.billingType);
				setInterval(invoice.interval);
				setRepeats(invoice.repeats);
				setStartDate(moment(invoice?.startDate).format("DD/MM/YYYY"));
				if (invoice?.endDate) {
					const date = new Date(invoice?.endDate);
					setEndDate(moment(invoice?.endDate).format("DD/MM/YYYY"))
				  }
			} else {
				setType("standard");
				setInterval("weekly");
				setRepeats(1);
				setStartDate(new Date().toLocaleDateString());
			}
			if (invoice.status === "sent") {
				setIsCreateInvoice(true);
			}
		}
	}, [invoice, invoiceId]);

	function roundToTwoDecimals(value) {
		if (isNaN(value)) return 0;
		return Math.round(value * 100) / 100;
	}

	const getDiscount = (subtotal, discount) => {
		const subtotalNum = Number(subtotal) || 0;
		const discountNum = Number(discount);
		if (isNaN(discountNum) || discountNum === 0 || isNaN(subtotalNum)) return 0;
		return roundToTwoDecimals((subtotalNum * discountNum) / 100);
	};

	const getVat = (subtotal, vatPercentage, discount) => {
		const subtotalNum = Number(subtotal) || 0;
		const vatPercentageNum = Number(vatPercentage);
		if (isNaN(vatPercentageNum) || vatPercentageNum === 0 || isNaN(subtotalNum)) return 0;
		const discountedAmount = subtotalNum - getDiscount(subtotalNum, discount);
		return roundToTwoDecimals((discountedAmount * vatPercentageNum) / 100);
	};

	const getTotalDue = (invoice, discount, vatPercentage, taxes, isVAT) => {
		const subtotal = invoice?.services?.reduce((acc, service) => {
			if (service?.serviceType === "Fixed") {
				return acc + Number(service?.servicePrice);
			} else {
				return acc + Number(service?.quantity) * Number(service?.servicePrice);
			}
		}, 0);
		const subTotalNum = Number(subtotal) || 0;
		const discountNum = Number(discount) || 0;
		const vatPercentageNum = Number(vatPercentage) || 0;

		const taxesValid = Array.isArray(taxes) && taxes.length > 0;

		if (isVAT) {
			return roundToTwoDecimals(
				subTotalNum -
					getDiscount(subTotalNum, discountNum) +
					getVat(subTotalNum, vatPercentageNum, discountNum),
			);
		} else if (taxesValid) {
			const totalTaxes = taxes.reduce((total, tax) => {
				const taxValueNum = Number(tax.taxValue) || 0;
				return total + getVat(subTotalNum, taxValueNum, discountNum);
			}, 0);
			return roundToTwoDecimals(
				subTotalNum - getDiscount(subTotalNum, discountNum) + totalTaxes,
			);
		} else {
			return roundToTwoDecimals(subTotalNum - getDiscount(subTotalNum, discountNum));
		}
	};

	const handlePaypal = async () => {
		const paymentLink = await dispatch(PaypalOrderCreate(invoiceId, setLoadingPaymentLink));
		return paymentLink;
	};

	const handleStripe = async () => {
		const paymentLink = await dispatch(stripeOrderCreate(invoiceId, setLoadingPaymentLink));
		return paymentLink;
	};

	const updatePaypalOrder = async () => {
		const orderId = invoice?.paymentLink.split("?")[1].split("=")[1];
		const linkUpdate = await dispatch(PaypalOrderUpdate(invoiceId, orderId));
	};

	const fetchStripeInfo = async (code) => {
		try {
			setLoadingStripeLink(true);
			const result = await axios.post("api/v1/stripe/token", {
				grant_type: "authorization_code",
				code: code,
			});
			searchParams.delete("code");
			searchParams.delete("scope");
			searchParams.delete("state");
			setSearchParams(searchParams);
			setLoadingStripeLink(false);

			dispatch({
				type: "SET_USER_DATA",
				payload: {
					data: { ...user, stripeInfo: result?.data.oauth },
				},
			});
		} catch (error) {
			setLoadingStripeLink(false);
			toast.error("Error with stripe!");
		}
	};

	const fetchPaypalInfo = async (code) => {
		try {
			setLoadingPaymentLink(true);
			const result = await axios.post("api/v1/paypal/token", {
				code: code,
			});
			searchParams.delete("code");
			searchParams.delete("scope");
			setSearchParams(searchParams);
			setLoadingPaymentLink(false);

			dispatch({
				type: "SET_USER_DATA",
				payload: {
					data: { ...user, payPalInfo: result?.data },
				},
			});
		} catch (error) {
			setLoadingPaymentLink(false);
			toast.error(error?.response?.statusText);
			return error;
		}
	};

	useEffect(() => {
		if (cardId && params.size === 3) {
			fetchStripeInfo(cardId);
		} else if (cardId && params.size === 2) {
			fetchPaypalInfo(cardId);
		}
	}, [cardId]);

	const handleSubmitFunction = (values, { setErrors, setSubmitting, setTouched }) => {
		setTouched({
			invoiceName: true,
			categoryTags: true,
		});
		delete values.step;
		setSubmitting(true);

		if (currentStep === 5 && isCreateInvoice) {
			values.status = "sent";
		}

		const payload = {
			...values,
			categoryTags: values.categoryTags.map((item) => {
				return {
					name: item.value,
					color: {
						color: item.color,
						text: item.text,
						labelColor: item.labelColor,
						labelText: item.labelText,
					},
				};
			}),
		};
		if (invoiceId) {
			if (currentStep === 4) {
				const totalDue = getTotalDue(
					payload,
					payload.discount,
					payload.vatPercentage,
					payload.taxes,
					payload.is_VAT,
				);
				payload.totalDue = totalDue;
				// if (values.services) {
				//   handlePaypal();
				// }
			}
			if (currentStep === 5 && initialValues?.discount !== payload?.discount) {
				const totalDue = getTotalDue(
					payload,
					payload.discount,
					payload.vatPercentage,
					payload.taxes,
					payload.is_VAT,
				);
				payload.totalDue = totalDue;
			}

			const checkRequiredFields = allRequiredFields.every((field) => {
				if (payload[field] === "") {
					return false;
				}
				if (field === "services") {
					if (payload[field].length === 0) {
						return false;
					}
				}
				return true;
			});

			if (checkRequiredFields) {
				payload.status = "unsent";
			}

			payload.client = clientId;
			payload.vatPercentage = payload?.is_VAT ? payload?.vatPercentage : 0;
			payload.taxes = payload?.is_VAT ? [] : payload?.taxes;
			payload.bankDetails = payload?.paymentMethod === "Bank" ? payload?.bankDetails : "";
			payload.paymentLink =
				payload?.paymentMethod === "Paypal" || payload?.paymentMethod === "Stripe"
					? payload?.paymentLink
					: null;

			dispatch(
				updateInvoice(
					invoiceId,
					payload,
					navigate,
					setErrors,
					setSubmitting,
					currentStep,
					setCurrentStep,
					handlePaypal,
					handleStripe
				),
			);
			return;
		}
		payload.client_id = clientId;
		// dispatch(createInvoice(payload, navigate, setErrors, setSubmitting));
	};

	const handleSetStep = (step) => {
		setCurrentStep(step);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setShowRecurringBannerMenu(false);
				setShowDeleteConfirmationModal(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);

	return (
		<>
			<AdminNavbar
				title="Create Invoice"
				subtitle="Create, bulid and send a new invoice to your client"
			/>
			{type === "recurring" && (
				<div className="px-8 py-4">
					<div className="flex items-center w-full gap-2 px-8 py-4 bg-indigo-100 rounded-full">
						<span className="flex items-center w-full gap-2 text-sm font-medium text-gray-700">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M18.875 7.12402V10.874H15.125"
									stroke="#5281E0"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M5.125 17.124V13.374H8.875"
									stroke="#5281E0"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M6.69375 10.2485C7.01073 9.35276 7.54946 8.5519 8.25967 7.92066C8.96988 7.28942 9.82842 6.84837 10.7552 6.63868C11.6819 6.42899 12.6467 6.45748 13.5595 6.7215C14.4723 6.98553 15.3033 7.47647 15.975 8.14852L18.875 10.8735M5.125 13.3735L8.025 16.0985C8.69672 16.7706 9.52773 17.2615 10.4405 17.5255C11.3533 17.7896 12.3181 17.8181 13.2448 17.6084C14.1716 17.3987 15.0301 16.9576 15.7403 16.3264C16.4505 15.6951 16.9893 14.8943 17.3062 13.9985"
									stroke="#5281E0"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<span>
								Recurs <span className="font-semibold">{interval}</span>, ends{" "}
								<span className="font-semibold">
									{interval === 'indefinitely' ? endDate : getEndDate(startDate, interval, repeats, userDateFormat)}
								</span>
							</span>
						</span>
						{/* <button
              className="cursor-pointer"
              onClick={() => {
                setShowRecurringBannerMenu(!showRecurringBannerMenu);
                setShowDeleteConfirmationModal(false);
              }}
            >
              <svg
                width="2"
                height="10"
                viewBox="0 0 2 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="1" cy="1" r="1" fill="grey" />
                <circle cx="1" cy="5" r="1" fill="grey" />
                <circle cx="1" cy="9" r="1" fill="grey" />
              </svg>
            </button>
            <div className="relative flex items-center gap-2" ref={ref}>
              {showRecurringBannerMenu && (
                <div className="absolute w-48 bg-white rounded-md shadow-lg top-2 right-1 ring-1 ring-black ring-opacity-5">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <button
                      className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      onClick={() => {
                        setShowRecurringBannerMenu(false);
                        setIsOpenTemplateModal(true);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      onClick={() => {
                        setShowRecurringBannerMenu(false);
                        setShowDeleteConfirmationModal(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
              {showDeleteConfirmationModal && (
                <div className="focus:outline-none min-w-[384px] absolute right-[-20px] top-[20px] w-36 origin-top-right divide-y divide-Neutral200 bg-white ring-1 ring-black ring-opacity-5 drop-shadow-Timetracking">
                  <div
                    className="px-8 pt-6 pb-8"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <h5 className="pb-2 text-xs font-semibold border-b text-Neutral700 border-Neutral200">
                      Are you sure?
                    </h5>
                    <button
                      className="text-Neutral000 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Red400 hover:bg-Red600 rounded-[4px] mt-8"
                      role="menuitem"
                      onClick={() => {
                        dispatch(deleteInvoice(invoiceId, false, navigate));
                      }}
                    >
                      Yes, Delete Invoice
                    </button>
                    <button
                      className="text-Neutral800 hover:text-Neutral900 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Neutral300 hover:bg-Neutral400 rounded-[4px] mt-5"
                      role="menuitem"
                      onClick={() => {
                        setShowDeleteConfirmationModal(false);
                      }}
                    >
                      No, I still want this Invoice
                    </button>
                  </div>
                </div>
              )}
            </div> */}
					</div>
				</div>
			)}
			{isLoading ? (
				<InvoiceLoader />
			) : (
				<Formik
					initialValues={{ ...initialValues, step: currentStep }}
					enableReinitialize
					validationSchema={validationSchema}
					onSubmit={handleSubmitFunction}
				>
					{({ values, errors, isSubmitting, setFieldValue, handleSubmit }) => {
						return (
							<form
								className="flex flex-col items-start justify-start w-full px-8 py-4 gap-4 overflow-y-auto h-[70vh] overflow-x-hidden md:overflow-y-hidden md:h-auto"
								onSubmit={handleSubmit}
							>
								<div className="w-full">
									<Step1
										active={currentStep === 1}
										complete={values.invoiceName !== "" && values.categoryTags !== ""}
										setStep={handleSetStep}
										values={values}
										setFieldValue={setFieldValue}
										isSubmitting={isSubmitting}
										errors={errors}
									/>
									<Step2
										active={currentStep === 2}
										complete={
											values.clientName !== "" &&
											values.clientEmail !== "" &&
											values.clientCompanyName !== "" &&
											values.clientAddress !== ""
										}
										onPrevious={() => setCurrentStep(1)}
										setStep={handleSetStep}
										isSubmitting={isSubmitting}
									/>
									<Step3
										active={currentStep === 3}
										complete={
											values.email !== "" &&
											values.fullName !== "" &&
											values.companyName !== "" &&
											values.address !== ""
											//  &&
											// values.VAT !== ""
										}
										onPrevious={() => setCurrentStep(2)}
										setStep={handleSetStep}
										isSubmitting={isSubmitting}
									/>
									<Step4
										active={currentStep === 4}
										setFieldValue={setFieldValue}
										values={values}
										onPrevious={() => setCurrentStep(3)}
										setStep={handleSetStep}
										complete={
											values.invoiceNumber !== "" &&
											values.currency !== "" &&
											values.invoiceDate !== "" &&
											values.dueDate !== "" &&
											values.vatPercentage !== "" &&
											values.services?.length > 0 &&
											values.services[0].serviceName !== "" &&
											values.email !== "" &&
											values.fullName !== "" &&
											values.companyName !== "" &&
											values.address !== ""
										}
										isSubmitting={isSubmitting}
										errors={errors}
										selectedDueDate={selectedDueDate}
										setSelectedDueDate={setSelectedDueDate}
										loadingPaymentLink={loadingPaymentLink}
									/>
									<Step5
										active={currentStep === 5}
										complete={isStepComplete(5, values)}
										onPrevious={() => setCurrentStep(4)}
										setStep={handleSetStep}
										values={values}
										isSubmitting={isSubmitting}
										handlePaypal={handlePaypal}
										handleStripe={handleStripe}
										invoice={invoice}
										user={user}
										loadingPaymentLink={loadingPaymentLink}
										setIsCreateInvoice={() => setIsCreateInvoice(true)}
									/>
								</div>
							</form>
						);
					}}
				</Formik>
			)}
			<EditInvoiceAndInvoiceTemplateModal
				isOpen={isOpenTemplateModal}
				setIsOpen={setIsOpenTemplateModal}
				type="Invoice"
				editData={initialValues}
				isCreateInvoice={true}
			/>
		</>
	);
}

export default CreateInvoice;
