import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";

import ClientsBlack from "../../assets/Images/Clients-black.svg";
import DashboardBlack from "../../assets/Images/Dashboard-black.svg";
import Logo from "../../assets/Images/Logo.svg";
import MoreBlack from "../../assets/Images/More-black.svg";
import RoundLogo from "../../assets/Images/Round-logo.svg";
import SignOut from "../../assets/Images/Sign-Out.svg";
import TasksBlack from "../../assets/Images/Tasks-black.svg";
import SettingsIcon from "../../components/Icons/SettingsIcon";
import TimeTrackingIcon from "../../components/Icons/newicons/_TimeTrackingIcon";
import DashboardIcon from "../Icons/newicons/DashboardIcon";
import ClientsIcon from "../Icons/newicons/_ClientsIcon";
import InvoicesIcon from "../Icons/newicons/_InvoicesIcon";
import LeadsPipelineIcon from "../Icons/newicons/_LeadsPipelineIcon";
import MeetingsIcon from "../Icons/newicons/_MeetingsIcon";
import MessageIcon from "../Icons/newicons/_MessageIcon";
import PortalIcon from "../Icons/newicons/_PortalIcon";
import ProposalsIcon from "../Icons/newicons/_ProposalsIcon";
import ReuseableTemplatesIcon from "../Icons/newicons/_ReuseableTemplatesIcon";
import TasksIcon from "../Icons/newicons/_TasksIcon";
import NewAndUpcoming from "../NewAndUpcoming/NewAndUpcoming";
import PortalRedirectModal from "../Modals/PortalRedirectModal";
import { LogoutAction } from "../../store/actions";
import { useDispatch } from "react-redux";

const menuList = [
	{
		id: 1,
		url: "/admin/dashboard",
		image: <DashboardIcon color="currentColor" />,
		title: "Dashboard",
		role: ["master_admin", "admin", "project_manager", "general"],
	},
	{
		id: 3,
		url: "/admin/clients",
		image: <ClientsIcon color="currentColor" />,
		title: "Clients",
		role: ["master_admin", "admin", "project_manager", "general"],
	},
	{
		id: 9,
		url: "/admin/meetings",
		image: <MeetingsIcon color="currentColor" />,
		title: "Meetings",
		role: ["master_admin", "admin", "project_manager", "general"],
	},
	{
		id: 2,
		url: "/admin/tasks",
		image: <TasksIcon color="currentColor" />,
		title: "Projects & Tasks",
		role: ["master_admin", "admin", "project_manager", "general"],
	},
	{
		id: 4,
		url: "/admin/proposals",
		image: <ProposalsIcon color="currentColor" />,
		title: "Proposals",
		role: ["master_admin", "admin", "project_manager"],
	},
	{
		id: 5,
		url: "/admin/tracking",
		image: <TimeTrackingIcon color="currentColor" />,
		title: "Time Tracking",
		role: ["master_admin", "admin", "project_manager", "general"],
	},
	{
		id: 6,
		url: "/admin/leads",
		image: <LeadsPipelineIcon color="currentColor" />,
		title: "Leads Pipeline",
		role: ["master_admin", "admin", "project_manager"],
	},
	{
		id: 7,
		url: "/admin/invoices",
		image: <InvoicesIcon color="currentColor" />,
		title: "Invoices",
		role: ["master_admin", "admin"],
	},
	{
		id: 10,
		url: "/admin/client-portal", // "https://portal.clientmanager.io/"
		image: <PortalIcon className="text-[#5A5A66]" />,
		title: "Portal",
		role: ["master_admin", "admin"],
	},
];

const mobilemenuList = [
	{
		id: 1,
		url: "/admin/dashboard",
		image: DashboardBlack,
		darkimage: DashboardBlack,
		title: "Dashboard",
	},
	{
		id: 2,
		url: "/admin/tasks",
		image: TasksBlack,
		darkimage: TasksBlack,
		title: "Projects & Tasks",
	},
	{
		id: 3,
		url: "/admin/clients",
		image: ClientsBlack,
		darkimage: ClientsBlack,
		title: "Clients",
	},
	{ id: 4, url: "#", image: MoreBlack, darkimage: MoreBlack, title: "More" },
];

function Sidebar({ onMouseEnter, onMouseLeave }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	
	const authSelector = useSelector((state) => state.auth);
	const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
	const [selctedMenu, setSelctedMenu] = useState(null);
	const [isPortalModalOpen, setIsPortalModalOpen] = useState(false);
	const url = `${window.location.origin}/tickets/Xn8k7vz5e4TcP7UVIqOVK?user_id=${authSelector?.user?._id}&email=${authSelector?.user?.email}&username=${authSelector?.user?.user_name}`;
	const handleMoreClick = () => {
		setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
	};

	const handleMenuItemClick = (id) => {
		setSelctedMenu(id);
		setIsHamburgerMenuOpen(false);
	};

	const handleCloseMenu = () => {
		setIsHamburgerMenuOpen(false);
	};

	// Function to get the menu items that are not in the mobile menu
	const getOtherMenuItems = () => {
		return menuList
			?.filter((item) => item?.role?.includes(authSelector?.user?.role))
			.filter(
				(menuItem) =>
					!mobilemenuList.some((mobileMenuItem) => mobileMenuItem.title === menuItem.title),
			);
	};

	useEffect(() => {
		if (!authSelector.authToken) {
			navigate("/signin");
		}
	}, [authSelector.authToken, navigate]);

  const handleLogout = () => {
    dispatch(LogoutAction());
  };

	return (
		<div
			onMouseEnter={() => onMouseEnter(true)}
			onMouseLeave={() => onMouseLeave(false)}
			className="w-fit"
		>
			<nav className="sidebar-main bg-Neutral100 md:left-0 fixed lg:top-0 lg:bottom-0 lg:overflow-y-auto overflow-hidden 5xl:w-[300px] lg:w-[240px] h-screen pt-6 pb-4 px-3 5xl:px-[2rem] border-r border-Neutral300 flex justify-between flex-col z-[100]">
				<div>
					<div className="flex items-center px-3">
						<img className="w-[24px] site-logo" src={Logo} alt="" />
						<Link to="/" className="focus-visible:outline-none">
							<h4 className="ml-1 text-sm font-black medium-screen-hover whitespace-nowrap focus-visible:outline-none site-title">
								ClientManager
							</h4>
						</Link>
					</div>

					<div className="mt-5 menulist">
						{menuList
							?.filter((item) => item?.role?.includes(authSelector?.user?.role))
							.map((item, index) => (
								<div className="menuitems" key={`1${index}`}>
									{index === 0 && <h4 className="menu-title-delimiter">Overview</h4>}
									{index === 1 && (
										<h4 className="menu-title-delimiter whitespace-nowrap min-w-[123px]">
											Project Management
										</h4>
									)}
									<NavLink
										onClick={(e) => {
											if (item.title === "Portal") {
												// e.preventDefault();
												// setIsPortalModalOpen(true);
											} else {
												setSelctedMenu(item.id);
											}
										}}
										to={item?.url}
										className={`navbar flex items-center px-3 py-[6px] rounded-lg focus-visible:outline-none transition duration-300`}
									>
										<div className="min-w-[24px] h-[24px] flex items-center justify-center">
											{item?.image}
										</div>
										<h3
											className={`min-w-[84px] text-xs ml-3 medium-screen-hover transition duration-300 ${
												selctedMenu === item.id
													? "text-AccentDark font-semibold"
													: "text-Neutral900 font-medium"
											}`}
										>
											{item?.title}
										</h3>
										{item?.title === "Portal" && (
											<div className="text-[10px] text-AccentLight w-fit px-2 rounded bg-AccentRegular tracking-wide">
												NEW
											</div>
										)}
									</NavLink>
								</div>
							))}
					</div>

					<PortalRedirectModal isOpen={isPortalModalOpen} setOpen={setIsPortalModalOpen} />

					{authSelector?.user?.role === "master_admin" ||
					authSelector?.user?.role === "admin" ? (
						<div className="menuitems">
							<h4 className="menu-title-delimiter">TEMPLATES</h4>

							<NavLink
								onClick={() => setSelctedMenu(8)}
								to={"/admin/templates"}
								className={`navbar flex items-center px-3 py-[6px] rounded-lg focus-visible:outline-none transition duration-300`}
							>
								<ReuseableTemplatesIcon color="currentColor" />

								<h3
									className={`min-w-[80px] text-xs ml-3 medium-screen-hover transition duration-300 ${
										selctedMenu == 8
											? "text-AccentDark font-semibold"
											: "text-Neutral900 font-medium"
									}`}
								>
									Reusable Templates
								</h3>
							</NavLink>
						</div>
					) : null}
					<div className="menuitems">
						<h4 className="menu-title-delimiter">SUPPORT</h4>
						<a
							href={url}
							target="_blank"
							rel="noreferrer"
							className={
								"text-Neutral900 font-medium rounded-xl text-xs px-3 py-[6px] w-full flex justify-start items-center hover:text-AccentRegular transition-all duration-300"
							}
						>
							<MessageIcon className="min-w-[24px] w-6 h-6 navbar-icon" />
							<h3 className="min-w-[84px] navbar-title ml-3">Contact Support</h3>
						</a>
						{/* <a
              href="https://facebook.com/groups/clientmanagerdotio"
              target="_blank"
              className={
                "text-Neutral900 font-medium rounded-xl text-xs px-3 py-[6px] w-full flex justify-start items-center hover:text-AccentRegular transition-all duration-300 mb-[3px]"
              }
            >
              <HeartIcon className="min-w-[24px] w-6 h-6 navbar-icon" />
              <h3 className="min-w-[84px] navbar-title ml-3">Community</h3>
            </a> */}
					</div>
				</div>

				<div className="flex flex-col items-center justify-between">
					<NewAndUpcoming />
				</div>
			</nav>

			{/* mobile screen */}
			<div className="mobile-menubar fixed bottom-0 md:hidden block z-[999] w-full bg-Neutral000 pb-2">
				<div className="flex items-center justify-around">
					{mobilemenuList.map((item, index) => (
						<div className="menuitems" key={index}>
							<Link
								onClick={() => {
									setSelctedMenu(item.id);
									if (item.title === "More") {
										handleMoreClick();
									}
								}}
								to={item.url}
								className={`flex items-center flex-col p-2 rounded-lg ${
									selctedMenu == item.id ? "selected" : " "
								}`}
							>
								<div className="w-[24px] h-[24px] flex items-center justify-center">
									<img
										src={`${selctedMenu == item.id ? item.darkimage : item.image}`}
										alt=""
									/>
								</div>
								<h3
									className={`text-xs mt-1 ${
										selctedMenu == item.id
											? "text-Neutral900 font-semibold"
											: "text-Neutral700 font-medium"
									}`}
								>
									{item.title}
								</h3>
							</Link>
						</div>
					))}
					{/* Hamburger menu/dropdown */}
					{isHamburgerMenuOpen && (
						<div className="absolute inset-0 z-50" onClick={handleCloseMenu}>
							<div className="absolute bottom-full mb-2 right-0 transform  w-[50%] max-w-[200px] max-h-[50vh] overflow-y-auto bg-white p-4 rounded-lg shadow-lg">
								{getOtherMenuItems().map((item, index) => (
									<div
										key={index}
										className="menu-item"
										onClick={() => handleMenuItemClick(item.id)}
									>
										{" "}
										<Link
											onClick={() => setSelctedMenu(item.title)}
											to={item.url}
											className={`flex items-center p-2 rounded-lg ${
												selctedMenu === item.title ? "selected" : " "
											}`}
										>
											<div className="w-[24px] h-[24px] flex items-center justify-center">
												{item?.image}
											</div>
											<h3
												className={`text-xs ml-2 ${
													selctedMenu === item.title
														? "text-AccentDark font-semibold"
														: "text-Neutral700 font-medium"
												}`}
											>
												{item.title}
											</h3>
										</Link>
									</div>
								))}
								{authSelector?.user?.role === "master_admin" ||
								authSelector?.user?.role === "admin" ? (
									<div className="menuitems">
										<h4 className="menu-title-delimiter">TEMPLATES</h4>

										<NavLink
											onClick={() => setSelctedMenu(8)}
											to={"/admin/templates"}
											className={`navbar flex items-center px-3 py-[6px] rounded-lg focus-visible:outline-none transition duration-300`}
										>
											<ReuseableTemplatesIcon color="currentColor" />

											<h3
												className={`min-w-[80px] text-xs ml-3 transition duration-300 ${
													selctedMenu == 8
														? "text-AccentDark font-semibold"
														: "text-Neutral700 font-medium"
												}`}
											>
												Reusable Templates
											</h3>
										</NavLink>
									</div>
								) : null}
								<div className="menuitems">
									<h4 className="menu-title-delimiter">SUPPORT</h4>
									<a
										href={url}
										target="_blank"
										className={
											"text-Neutral700 font-medium rounded-xl text-xs px-3 py-[6px] w-full flex justify-start items-center hover:text-AccentRegular transition-all duration-300"
										}
									>
										<MessageIcon className="min-w-[24px] w-6 h-6 navbar-icon" />
										<h3 className="min-w-[84px] navbar-title ml-3">Contact Support</h3>
									</a>
									{/* <a
                    href="https://facebook.com/groups/clientmanagerdotio"
                    target="_blank"
                    className={
                      "text-Neutral700 font-medium rounded-xl text-xs px-3 py-[6px] w-full flex justify-start items-center hover:text-AccentRegular transition-all duration-300 mb-[3px]"
                    }
                  >
                    <HeartIcon className="min-w-[24px] w-6 h-6 navbar-icon" />
                    <h3 className="min-w-[84px] navbar-title ml-3">
                      Community
                    </h3>
                  </a> */}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="mobile-top-menubar fixed md:hidden block z-[999] w-full bg-Neutral000 py-4 px-5 drop-shadow-Mobileheader">
				<div className="flex items-center justify-between">
					<img src={RoundLogo} alt="" />
					<div className="flex items-center">
						<Menu as="div" className="relative inline-block text-left">
							<div className="flex items-center">
								<Menu.Button className="inline-flex justify-center w-full">
									{authSelector?.user?.profile ? (
										<img
											className="w-8 h-8 rounded-full group-hover:bg-indigo-500 bg-blend-overlay group-hover:bg-opacity-50"
											src={authSelector?.user?.profile_path}
											alt=""
										/>
									) : (
										<div
											className={`border border-Neutral300 w-8 h-8 flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700  focus-visible:outline-none group-hover:bg-indigo-500 bg-blend-overlay group-hover:bg-opacity-50`}
										>
											<span className=" text-[16px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey uppercase">
												{authSelector?.user?.user_name?.[0]}
											</span>
										</div>
									)}
								</Menu.Button>
							</div>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Menu.Items className="absolute right-0 mt-2 origin-top-right bg-white rounded-md w-fit drop-shadow-Simplebutton focus:outline-none">
									<div className="">
										<form method="POST" action="#">
											<Menu.Item>
												{({ active }) => (
													<Link to="/admin/settings">
														<button
															type="button"
															className="5xl:w-[120px] text-left px-4 py-2 text-sm whitespace-nowrap flex items-center justify-center font-medium text-Neutral800 hover:bg-Neutral100"
														>
															<SettingsIcon />
															<span className="ml-1"> Settings</span>{" "}
														</button>
													</Link>
												)}
											</Menu.Item>
											<Menu.Item>
												{({ active }) => (
													<button
														type="button"
														onClick={handleLogout}
														className="5xl:w-[120px] text-left px-4 py-2 text-sm whitespace-nowrap flex items-center justify-center font-medium text-Neutral800"
													>
														<img className="ml-1" src={SignOut} alt="" />
														Sign Out
													</button>
												)}
											</Menu.Item>
										</form>
									</div>
								</Menu.Items>
							</Transition>
						</Menu>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Sidebar;
