import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  ChangeProposalStatusViewedAction,
  GetLeadProposalAction,
  GetPublicContentByClientId,
} from "../../store/actions/proposalAction";
import Download from "../../assets/Images/Download-white.svg";
import DarkNotification from "../../assets/Images/Notification-dark.svg";
import PublicFroalaBuilderComp from "../PublicTextEditor/PublicFroalaBuilderComp";
import html2pdf from "html2pdf.js";
import ButtonLoader from "../Loaders/ButtonLoader";
import syncImages from "../../utils/syncImage";

const PublicProposalBuilder = () => {
  const { clientId, proposalId, leadId } = useParams();
  const editor = useRef();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [textEditor, setTextEditor] = useState({
    images: { url: null, key: null },
    text: null,
    is_dotted: false,
    signature: "",
  });
  const [downloadLoader, setDownloadLoader] = useState(false);

  const handleDownloadPDF = async () => {
    setDownloadLoader(true);
    if (document.getElementById("download-content")) {
      document.getElementById("download-content").remove();
    }
    const newElement = document.createElement("div");
    newElement.id = "download-content";
    newElement.style.display = "none";
    newElement.appendChild(
      document.getElementById("froala-editor-view").cloneNode(true)
    );
    document
      .getElementById("public-proposal-editor")
      .firstChild.before(newElement);

    const element = document.querySelector(
      "#download-content #froala-editor-view"
    );

    const customBullets = [];

    element.querySelectorAll(".page").forEach((el) => {
      el.style.height = "100%";
      el.style.minHeight = "27cm";
    });

    element.querySelectorAll(".page-break").forEach((el) => {
      el.style.height = "0px";
      el.style.margin = "0px";
      el.style.padding = "0px";
    });

    element.querySelectorAll(".page-break-label").forEach((el) => {
      el.style.display = "none";
    });

    element.querySelectorAll("ul").forEach((ul) => {
      ul.style.listStyleType = "none"; // remove default bullets
    });

    element.querySelectorAll("ul li").forEach((el) => {
      const bullet = document.createElement("span");
      bullet.style.fontSize = "20px";
      bullet.textContent = "• ";
      el.insertBefore(bullet, el.firstChild);
      customBullets.push(bullet); // keep track of custom bullets
    });

    const fileName = `proposal_${
      textEditor?.client?.user?.user_name || textEditor?.lead?.user?.user_name
    }_${textEditor?.client?.contact_name || textEditor?.lead?.name}_${
      new Date().toISOString().split("T")[0]
    }.pdf`;

    await syncImages();
    html2pdf(element, {
      margin: 0,
      filename: fileName,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 4, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    });
    setDownloadLoader(false);
    setTimeout(() => {
      element.querySelectorAll(".page-break").forEach((el) => {
        el.style.height = "auto";
        el.style.margin = "15px";
        el.style.padding = "5px 0";
      });

      element.querySelectorAll(".page-break-label").forEach((el) => {
        el.style.display = "block";
      });

      element.querySelectorAll("ul").forEach((ul) => {
        ul.style.listStyleType = "disc"; // revert to original state
      });

      customBullets.forEach((bullet) => {
        bullet.parentNode.removeChild(bullet); // remove custom bullets
      });
    }, 1000);
  };

  useEffect(() => {
    const getServerProposalConnent = async () => {
      setIsLoading(true);
      let serverContent = null;
      if (leadId) {
        serverContent = await dispatch(
          GetLeadProposalAction(leadId, proposalId)
        );
      }
      if (clientId) {
        serverContent = await dispatch(
          GetPublicContentByClientId(clientId, proposalId)
        );
        const token = localStorage.getItem("jwt_access_token");
        if (
          !serverContent?.data?.status &&
          serverContent?.data?._id &&
          !token
        ) {
          await dispatch(
            ChangeProposalStatusViewedAction({
              status: "viewed",
              proposal_id: proposalId,
            })
          );
          serverContent = {
            ...serverContent,
            data: { ...serverContent?.data, status: "viewed" },
          };
        }
      }
      if (serverContent?.data) {
        let proposalContent = serverContent?.data;
        setTextEditor({
          images: {
            url: proposalContent?.content?.cover?.url,
            key: proposalContent?.content?.cover?.key,
          },
          text: proposalContent?.content,
          user_signature: proposalContent?.user_signature,
          client_signature: proposalContent?.client_signature,
          client: proposalContent?.client,
          lead: proposalContent?.lead,
        });
      }
      setIsLoading(false);
    };
    getServerProposalConnent();
  }, [proposalId, dispatch]);
  return (
    <div className="flex flex-col mt-[80px]">
      <div className="flex flex-col justify-end align-center mb-[40px]">
        <div className="border border-gray-300 flex items-center p-6 rounded w-[400px] md:w-[790px] mx-auto text-sm md:text-base">
          <img
            src={DarkNotification}
            alt="Notification"
            className="mr-5 w-8 h-8"
          />
          <div className="flex flex-col items-start justify-center">
            <div>Please sign this proposal when you're ready.</div>
            <div>
              We'll get notified automatically when you sign this proposal.
            </div>
          </div>
        </div>
        <div className="flex justify-center align-center">
          <div className="button-hover text-white font-bold text-sm right-11 w-[400px] md:w-[790px] 5xl:h-14 h-12 bg-AccentRegular items-center justify-center rounded mt-[16px] py-2 px-4 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight transition duration-300">
            {downloadLoader ? (
              <ButtonLoader />
            ) : (
              <button
                className="flex items-center justify-center w-full h-full"
                type="button"
                onClick={handleDownloadPDF}
              >
                <span>Download PDF</span>
                <img className="ml-1" src={Download} alt="" />
              </button>
            )}
          </div>
        </div>
      </div>
      <PublicFroalaBuilderComp
        textEditor={textEditor}
        editor={editor}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setTextEditor={setTextEditor}
        type="proposal"
      />
    </div>
  );
};

export default PublicProposalBuilder;
