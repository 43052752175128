const initialState = {
  clientList: {
    isLoading: true,
    data: {
      data: [],
      totalRecord: 0,
      perPage: 10,
      totalPage: 0,
      currentPage: 0,
      fromRecord: 0,
      hasPrevPage: false,
      hasNextPage: false,
    },
  },
  clientDetail: {
    isLoading: true,
    isUpdating: false,
    data: {},
  },
  archivedClientList: {
    isLoading: true,
    data: [],
  },
  fullClientList: {
    isLoading: true,
    data: [],
  },
  myFullClientList: {
    isLoading: true,
    data: [],
  },
  clientListWithNoProposal: {
    isLoading: true,
    data: [],
  },
  clientListWithNoTimeTrackingProject: {
    isLoading: true,
    data: [],
  },
  clientStatusList: {
    isLoading: true,
    data: [],
  },
  invitedClientList: {
    isLoading: true,
    data: [],
  },
  clientPortalClientDetails: {
    isLoading: true,
    data: [],
  },
};

const clientReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_CLIENTS_LIST":
      return {
        ...state,
        clientList: {
          ...state.clientList,
          ...payload,
        },
      };
    case "UPDATE_CLIENT_STATUS":
      let listOfClient = state.clientList.data?.docs;
      const indexOfUpdateRecord = listOfClient?.findIndex((e) => e._id === payload.data._id);
      if (indexOfUpdateRecord !== -1) {
        listOfClient[indexOfUpdateRecord] = payload.data;
      } else {
        listOfClient = payload.data;
      }
      return {
        ...state,
        clientList: {
          ...state.clientList,
          data: { docs: listOfClient, ...state.clientList.data },
        },
      };
    case "UPDATE_CLIENT_FAVOURITE":
      const updatedClients = state.clientList.data?.docs.map((client) => {
        if (client._id === payload.clientId) {
          return { ...client, is_favourite: payload.favourite };
        }
        return client;
      });

      return {
        ...state,
        clientList: {
          ...state.clientList,
          data: {
            ...state.clientList.data,
            docs: updatedClients,
          },
        },
      };

    case "SET_CLIENT_DETAIL":
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          ...payload,
        },
      };
    case "UPDATE_CLIENT_DETAIL":
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          ...payload,
        },
      };
    case "SET_ARCHIVED_CLIENTS_LIST":
      return {
        ...state,
        archivedClientList: {
          ...state.archivedClientList,
          ...payload,
        },
      };
    case "SET_FULL_CLIENTS_LIST":
      return {
        ...state,
        fullClientList: {
          ...state.fullClientList,
          ...payload,
        },
      };
    case "SET_CLIENTS_LIST_WITH_NO_PROPOSAL":
      return {
        ...state,
        clientListWithNoProposal: {
          ...state.clientListWithNoProposal,
          ...payload,
        },
      };
    case "SET_CLIENTS_LIST_WITH_NO_TIME_TRACKING_PROJECT":
      return {
        ...state,
        clientListWithNoTimeTrackingProject: {
          ...state.clientListWithNoTimeTrackingProject,
          ...payload,
        },
      };
    case "SET_MY_FULL_CLIENTS_LIST":
      return {
        ...state,
        myFullClientList: {
          ...state.myFullClientList,
          ...payload,
        },
      };
    case "ASSIGN_MEMBER_UPDATE_SUCCESS":
      // let tempMember = state.clientDetail.data;
      // tempMember = { ...tempMember, ...payload.data };
      // return {
      //   ...state,
      //   clientDetail: {
      //     ...state.clientDetail,
      //     data: tempMember,
      //   },
      // };
      break;
    case "CREATE_CATEGORY_SUCCESS":
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          data: payload.data,
        },
      };
    case "CREATE_COLUMN_SUCCESS":
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          data: payload.data.client,
        },
      };
    case "UPDATE_COLUMN_POSITION_SUCCESS":
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          data: payload.data,
        },
      };

    case "CREATE_SCREEN_SUCCESS":
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          data: payload.data,
        },
      };
    case "REMOVE_COLUMN_SUCCESS":
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          data: payload.data,
        },
      };
    case "REMOVE_CATEGORY_SUCCESS":
      return {
        ...state,
        clientDetail: {
          ...state.clientDetail,
          data: payload.data,
        },
      };
    case "GET_CLIENT_STATUS_LIST":
      return {
        ...state,
        clientStatusList: {
          ...state.clientStatusList,
          data: payload.data,
        },
      };
    case "UPDATE_CLIENT_STATUS_LIST":
      return {
        ...state,
        clientStatusList: {
          ...state.clientStatusList,
          data: [...state.clientStatusList.data, payload.data],
        },
      };
    case "GET_CLIENT_PORTAL_CLIENT_DETAILS":
      return {
        ...state,
        clientPortalClientDetails: {
          ...state.clientPortalClientDetails,
          data: payload.data,
        },
      };
    case "SET_INVITED_CLIENTS_LIST":
      return {
        ...state,
        invitedClientList: {
          ...state.invitedClientList,
          ...payload,
        },
      };
    case "UPDATE_CLIENT_ACCESS_LEVEL":
      console.log("payload", payload);
      const updatedInvitedClients = state.invitedClientList.data.map((client) => {
        if (client._id === payload?.data?._id) {
          return { ...client, access_level: payload?.data?.access_level };
        }
        return client;
      });
      console.log("updatedInvitedClients", updatedInvitedClients);
      return {
        ...state,
        invitedClientList: {
          ...state.invitedClientList,
          data: updatedInvitedClients,
        },
      };
    default:
      return state;
  }
};

export default clientReducer;
