import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Close from "../../assets/Images/Close-gray.svg";
import Redirect from "../../assets/Images/Redirect.svg";
import CloseBlueIcon from "../../assets/Images/close-blue-icon.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import DeletIcon from "../../assets/Images/delet-icon.svg";
import { DeleteAssetsAction, UpdateAssetsActions } from "../../store/actions/assetsAction";
import UploadAssetAndDocumentFiles from "../Buttons/UploadAssetAndDocumentFiles";

const EditAssetSchema = Yup.object().shape({
	title: Yup.string().required("Title Subject Required"),
	file: Yup.array().when("file_type", {
		is: "file",
		then: Yup.array().min(1).required("You must select at list one file").nullable(),
	}),
	url: Yup.array().when("file_type", {
		is: "url",
		then: Yup.lazy((val) =>
			Array.isArray(val) && val?.length > 0
				? Yup.array().of(
						Yup.string()
							.required("Empty url not required")
							.matches(
								/^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
								"Enter correct url!",
							)
							.nullable(),
				  )
				: Yup.array().min(1, "You must enter at list one url"),
		),
	}),
});

const accountStyles = {
	overlay: {
		backgroundColor: "rgba(41, 41, 51, 0.4)",
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		border: "none",
		padding: "47px",
		background: "none",
		borderRadius: "10px",
	},
};

const EditAssetsModal = ({
	accountCencelled,
	setaccountCencelled,
	editData,
	setEditdata,
	type,
	item,
	clientId,
}) => {
	const inputArr = [""];
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [arr, setArr] = useState(inputArr);
	const [label, setlabel] = useState("File");

	useEffect(() => {
		if (editData?.url?.length) {
			setArr(editData?.url);
		}
	}, [editData]);

	const addInput = () => {
		setArr((s) => {
			return [...s, ""];
		});
	};

	const handleChangeeffect = (e) => {
		if (e.target.value.length >= 1) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	};

	const handledeleted = (id, asset_1d) => {
		setLoading(true);
		let formData = { item_id: id, assets_id: asset_1d };
		dispatch(DeleteAssetsAction(formData, type, setLoading, setaccountCencelled));
	};
	return (
		<Modal
			isOpen={accountCencelled}
			onRequestClose={() => {
				setaccountCencelled(false);
				setEditdata(null);
				setArr(inputArr);
			}}
			style={accountStyles}
			closeTimeoutMS={300}
			contentLabel="Example Modal"
		>
			<div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
				<div className="bg-white px-6 xs:px-12 py-8 rounded-[8px]">
					<Formik
						initialValues={{
							id: editData?._id,
							title: editData?.title,
							file: editData?.file,
							url: editData?.url,
							client_id: clientId,
							assets_id: editData?.assets_id || editData?.document_id,
							type,
							file_type: editData?.file?.length ? "file" : "url",
							remove_file: null,
						}}
						enableReinitialize
						validationSchema={EditAssetSchema}
						onSubmit={async (values, { setSubmitting, setErrors }) => {
							setSubmitting(true);
							values = {
								...values,
								file: values?.file_type === "file" ? values?.file : [],
								url:
									values?.file_type === "url"
										? values?.url.filter((ele) => ele !== "")
										: [],
								remove_file:
									values?.file_type === "url"
										? values?.file?.length
											? editData?.file
											: null
										: editData?.file?.filter((ele) => !values?.file.includes(ele)),
							};
							const closeModal = () => {
								setaccountCencelled(false);
								setIsActive(false);
								setEditdata(null);
								setArr(inputArr);
							};
							await dispatch(
								UpdateAssetsActions(values, type, setSubmitting, setErrors, closeModal),
							);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlue,
							handleBlur,
							handleSubmit,
							setFieldValue,
							isSubmitting,
							handleReset,
						}) => (
							<form onSubmit={handleSubmit}>
								<div className="flex flex-col gap-5">
									<div className="flex pb-2 border-b border-Neutral200">
										<h6 className="uppercase font-semibold text-xs text-Neutral700 tracking-[0.04em]  w-full">
											Edit Asset
										</h6>
									</div>

									<div className="flex flex-col gap-4">
										<div className="space-y-2">
											<label className="text-xs font-bold text-Neutral900">Name</label>
											<input
												className={`duration-300 transition-all w-full py-[15px] h-12 px-4 placeholder:text-sm text-sm placeholder:text-Neutral500 text-Neutral800 resize-none font-medium placeholder:font-medium block focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow`}
												type="text"
												placeholder="Eg. Brand Guide"
												name="title"
												onChange={(e) => {
													handleChange(e);
													handleChangeeffect(e);
												}}
												onBlur={handleBlur}
												value={values.title}
											/>
											{errors.title && touched.title && (
												<p className="text-xs font-medium text-Red400 focus-visible:outline-none">
													{errors.title}
												</p>
											)}
										</div>

										<div>
											{values.file_type === "file" ? (
												<div className="space-y-2">
													<label className="text-xs font-bold text-Neutral900">
														FILE
													</label>
													<UploadAssetAndDocumentFiles
														title="Upload your file"
														value={values}
														error={errors}
														touched={touched}
														handleBlue={handleBlue}
														setFieldValue={setFieldValue}
														accept="image/*,.pdf,.doc,.docx,.xlsx,.svg,.csv"
														item={item}
														handleChangeeffect={handleChangeeffect}
														setIsActive={setIsActive}
														multipal={true}
													/>
												</div>
											) : (
												<div className="space-y-2">
													<label className="text-xs font-bold text-Neutral900">
														URL
													</label>
													<div className="flex flex-col gap-2">
														{arr?.map((item, i) => {
															return (
																<div className="flex flex-col gap-4">
																	<div className="flex items-center">
																		<div
																			className={`w-full flex px-3 justify-between font-medium border-[1.5px] rounded border-Neutral300 focus-within:border-AccentRegular ${
																				touched?.url && errors?.url
																					? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
																					: ""
																			}`}
																		>
																			<input
																				onChange={(e) => {
																					e.preventDefault();
																					const index = e.target.id;
																					setArr((s) => {
																						const newArr = s.slice();
																						newArr[index] = e.target.value;
																						setFieldValue("url", newArr);
																						return newArr;
																					});
																					setIsActive(true);
																				}}
																				value={item}
																				id={i}
																				type="text"
																				name="url"
																				onBlur={handleBlue}
																				placeholder="Eg. drive.google.com/drive..."
																				className="items-center w-full py-3 text-base leading-5 transition duration-300 bg-transparent placeholder:text-Neutral500 placeholder:text-sm 5xl:placeholder:text-lg focus:outline-none"
																			/>
																			<img
																				className={`cursor-pointer ${
																					arr?.length === 1 &&
																					arr[0] === "" &&
																					"hidden"
																				}`}
																				src={Close}
																				alt=""
																				name="file"
																				onClick={() => {
																					const notRemovedFile = arr.filter(
																						(url) => url !== item,
																					);
																					setArr(
																						notRemovedFile?.length
																							? notRemovedFile
																							: inputArr,
																					);
																					setFieldValue(`url`, notRemovedFile);
																					setIsActive(true);
																				}}
																			/>
																		</div>
																		{editData?.url?.find(
																			(ele) => ele === item,
																		) && (
																			<a
																				target="_blank"
																				href={item}
																				rel="noreferrer"
																				className="text-AccentRegular flex items-center justify-end text-xs rounded h-8 w-[80px] font-semibold"
																			>
																				view
																				<img
																					className="w-[20px] mr-[10px]"
																					src={Redirect}
																					alt=""
																				/>
																			</a>
																		)}
																	</div>
																	{arr?.length === i + 1 && (
																		<div className="flex justify-center">
																			<button
																				onClick={addInput}
																				className="p-2 px-3 text-[30px] leading-5 w-full focus-visible:outline-none border-[1px] rounded text-AccentRegular border-Neutral400 hover:border-AccentRegular transition duration-300 border-dashed inline-block cursor-pointer"
																			>
																				+
																			</button>
																		</div>
																	)}
																</div>
															);
														})}
														{errors.url && touched.url && (
															<p className="text-xs font-medium text-Red400 focus-visible:outline-none">
																{errors.url}
															</p>
														)}
													</div>
												</div>
											)}
										</div>
									</div>

									<div>
										{values.file_type !== "file" ? (
											<p
												className={`text-xs text-Neutral700 transition duration-300 font-medium`}
											>
												Have asset already?&nbsp;
												<button
													type="button"
													className="text-Red400"
													name="file_type"
													onClick={() => {
														setFieldValue("file_type", "file");
														setlabel("File");
													}}
												>
													Upload it here.
												</button>
											</p>
										) : (
											<p
												className={`text-xs text-Neutral700 transition duration-300 font-medium`}
											>
												Have an existing asset URL?&nbsp;
												<button
													type="button"
													className="text-Red400"
													name="file_type"
													onClick={() => {
														setFieldValue("file_type", "url");
														setlabel("URL");
													}}
												>
													Paste it here.
												</button>
											</p>
										)}
									</div>

									<div className="flex justify-between">
										<button
											type="submit"
											disabled={isSubmitting}
											className={`relative modal-discount-text text-sm font-bold w-[219px] h-12 flex items-center justify-center rounded-[4px] leading-[1.4] focus:outline-AccentMediumLight bg-Neutral300 transition duration-300 ease-in-out ${
												isActive
													? "bg-AccentRegular text-Neutral100 invoice-changes hover:text-Neutral100 duration-300"
													: "text-Neutral500"
											} `}
										>
											{isSubmitting ? (
												<span className="relative z-10">
													<svg
														className="w-5 h-5 m-auto text-white animate-spin"
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
													>
														<circle
															className="opacity-25"
															cx="12"
															cy="12"
															r="10"
															stroke="currentColor"
															strokeWidth="4"
														></circle>
														<path
															className="opacity-75"
															fill="currentColor"
															d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
														></path>
													</svg>
												</span>
											) : (
												"Save Changes"
											)}
										</button>
										<button
											type="button"
											className={` modal-discount-text inlinr-block text-sm font-bold w-[88px] h-12 flex items-center justify-center rounded-[4px] leading-[1.4] focus:outline-AccentMediumLight  hover:text-Neutral800 transition duration-300 ease-in-out ${
												isActive
													? "bg-AccentLight hover:bg-AccentMediumLight"
													: "bg-Red400 hover:bg-Red600"
											} `}
											onClick={() => {
												if (isActive) {
													handleReset();
													setArr(editData?.url?.length ? editData?.url : inputArr);
													setIsActive(false);
												} else {
													handledeleted(values?.id, values?.assets_id);
												}
											}}
										>
											{loading ? (
												<span className="relative z-10">
													<svg
														className="w-5 h-5 m-auto text-white animate-spin"
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
													>
														<circle
															className="opacity-25"
															cx="12"
															cy="12"
															r="10"
															stroke="currentColor"
															strokeWidth="4"
														></circle>
														<path
															className="opacity-75"
															fill="currentColor"
															d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
														></path>
													</svg>
												</span>
											) : (
												<img
													className={`${isActive ? "hidden" : "block"} `}
													src={DeletIcon}
													alt=""
												/>
											)}

											<img
												className={`${isActive ? "block" : "hidden"} `}
												src={CloseBlueIcon}
												alt=""
											/>
										</button>
									</div>
								</div>
							</form>
						)}
					</Formik>
				</div>
				<div
					className="absolute right-0 top-[-50px] z-30 cursor-pointer"
					onClick={() => {
						setaccountCencelled(false);
						setArr(inputArr);
					}}
				>
					<img src={CloseBtn} alt="" />
				</div>
			</div>
		</Modal>
	);
};

export default EditAssetsModal;
