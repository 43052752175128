import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Logo from "../../assets/Images/Logo.svg";

const TicketForm = () => {
  const aaa = useLocation();
  const searchParams = new URLSearchParams(aaa.search);
  const user_id = searchParams.get("user_id");
  const email = searchParams.get("email");
  const username = searchParams.get("username");
  return (
    <div className="bg-Neutral000 h-screen">
      <div className="md:sticky w-full z-[99] mt-[60px] md:mt-0 top-0 admin-navbar bg-Neutral000 left-0 pl-0 md:pl[240px]">
        <div className="flex justify-between items-center px-5 lg:px-12 5xl:px-[96px] border-[#EDEDF2] md:border-b bg-Neutral000 py-[20px]">
          <div className="flex items-center px-3">
            <img className="w-[24px] site-logo" src={Logo} alt="" />
            <Link to="/" className="focus-visible:outline-none">
              <h4 className="ml-1 text-sm font-black medium-screen-hover whitespace-nowrap focus-visible:outline-none site-title">
                ClientManager
              </h4>
            </Link>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-5 lg:px-[250px] 5xl:px-[96px] pt-[30px] h-full">
        <div className="md:px-5 lg:px-12 5xl:px-[96px] pb-[20px]">
          <div className="flex justify-between items-center">
            <h1 className="text-[20px] md:font-semibold tracking-[0.08em] md:tracking-normal font-bold text-Neutral900">
              Open Support Tickets
            </h1>
            <ab-create-ticket
              ticketFormID="Xn8k7vz5e4TcP7UVIqOVK"
              userID={user_id}
              email={email}
              username={username}
            >
              <button
                type="button"
                className="text-Neutral100 bg-AccentRegular hover:bg-AccentMediumDark button-hover max-w-[130px] w-[100px] md:w-full min-w-[6rem] h-10 sm:min-w-[8rem] sm:h-12 large-primary-btn text-xs sm:text-sm text-center flex items-center justify-center relative font-semibold py-2 px-[15px] rounded focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
              >
                Create Ticket
              </button>
            </ab-create-ticket>
          </div>

          <ab-tickets-table
            ticketFormID="Xn8k7vz5e4TcP7UVIqOVK"
            userID={user_id}
            status="OPEN,ASSIGNED,NEED_MORE_INFO"
          ></ab-tickets-table>
        </div>

        <div className="md:px-5 lg:px-12 5xl:px-[96px] pb-[20px]">
          <h1 className="text-[20px] md:font-semibold tracking-[0.08em] md:tracking-normal font-bold text-Neutral900">
            Recent Support Tickets
          </h1>
          <ab-tickets-table
            ticketFormID="Xn8k7vz5e4TcP7UVIqOVK"
            userID={user_id}
            status="RESOLVED"
          ></ab-tickets-table>
        </div>
      </div>

      {/* create footer and show screen bottom */}
      <div className="md:sticky w-full z-[99] bottom-0 bg-Neutral000 left-0 pl-0 md:pl[240px]">
        <div className="flex justify-between items-center px-5 lg:px-12 5xl:px-[96px] border-[#EDEDF2] md:border-t bg-Neutral000 py-[20px]">
          <div className="flex items-center px-3">
            <span className="text-sm text-Neutral600">
              © 2023 ClientManager
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketForm;
