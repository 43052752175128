import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { UpdateTaskActions } from "../../../../../store/actions/tasksAction";
import { UpdateServiceActions } from "../../../../../store/actions/serviceAction";
import "react-datepicker/dist/react-datepicker.css";
import MenuIcon from "../Icons/MenuIcon";

const DueDateNew = ({ isNew, item, user, types, clientDetail, user_type = null, access_level }) => {
  const dispatch = useDispatch();
  const [showDatepicer, setShowDatepicer] = useState(!!item?.date);
  const [startDate, setStartDate] = useState(
    item?.date ? new Date(item?.date) : item?.date
  );

  useEffect(() => {
    if (item?.date) {
      setStartDate(new Date(item?.date));
      setShowDatepicer(true);
    }
  }, [item?.date]);

  const DateInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <button
        className={`example-custom-input text-xs whitespace-nowrap font-bold color-Neutral100 text-Neutral100 px-0`}
        onClick={onClick}
        ref={ref}
      >
        {!value
          ? "Select date..."
          : moment(value).format("ddd, D MMM YYYY").toUpperCase()}
      </button>
    );
  });

  const onDateChange = async (date) => {
    setStartDate(date);
    if (item?.date !== date) {
      if (types === "task") {
        if(user_type) {
          await dispatch(UpdateTaskActions({ ...item, date, user_type: 'client_user' }));
        }else {
          await dispatch(UpdateTaskActions({ ...item, date }));
        }
      }
      if (types === "service") {
        await dispatch(UpdateServiceActions({ ...item, date }));
      }
    }
  };

  return (
    <div className={`relative ${user_type ? "w-full" : "w-full sm:w-[236px]" }  bg-Neutral900 rounded-lg	py-[12px] pl-[20px] pr-[8px]  flex flex-col  gap-[16px] `}>
      <div
        className={`flex items-center text-Neutral100 justify-between ${(user_type && access_level === 'full_access') || !user_type ? "cursor-pointer" : ''}`}
        onClick={() => {
          if((user_type && access_level === 'full_access') || !user_type) {
            if (!showDatepicer) {
              setShowDatepicer(true);
            } else {
              // TODO: delete item?.date
              setShowDatepicer(false);
            }
          }
        }}
      >
        <div className="flex gap-2 items-center">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.1852 3.7041H4.81486C3.99666 3.7041 3.33337 4.36738 3.33337 5.18558V15.556C3.33337 16.3742 3.99666 17.0374 4.81486 17.0374H15.1852C16.0034 17.0374 16.6667 16.3742 16.6667 15.556V5.18558C16.6667 4.36738 16.0034 3.7041 15.1852 3.7041Z"
              stroke="#FCFCFC"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.963 2.22266V5.18562"
              stroke="#FCFCFC"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.03711 2.22266V5.18562"
              stroke="#FCFCFC"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3.33337 8.14844H16.6667"
              stroke="#FCFCFC"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.6667 11.25H10.8334"
              stroke="#FCFCFC"
              stroke-linecap="round"
            />
            <path
              d="M14.1667 11.25H13.3334"
              stroke="#FCFCFC"
              stroke-linecap="round"
            />
            <path
              d="M11.6667 13.75H10.8334"
              stroke="#FCFCFC"
              stroke-linecap="round"
            />
            <path
              d="M14.1667 13.75H13.3334"
              stroke="#FCFCFC"
              stroke-linecap="round"
            />
            <path
              d="M9.16671 11.25H8.33337"
              stroke="#FCFCFC"
              stroke-linecap="round"
            />
            <path
              d="M9.16671 13.75H8.33337"
              stroke="#FCFCFC"
              stroke-linecap="round"
            />
            <path
              d="M6.66671 11.25H5.83337"
              stroke="#FCFCFC"
              stroke-linecap="round"
            />
            <path
              d="M6.66671 13.75H5.83337"
              stroke="#FCFCFC"
              stroke-linecap="round"
            />
          </svg>
          <p className="text-Neutral600 text-[10px] font-semibold  text-Neutral100">
            DUE DATE
          </p>
        </div>

        <MenuIcon />
      </div>

      {showDatepicer && (
        <div className="relative color-Neutral100 rounded-[4px] text-sm  w-full max-w-[524px] focus:outline-none ">
          {( user_type ? (<DatePicker
            selected={startDate}
            customInput={
              <DateInput
                value={
                  !startDate
                    ? "Select date..."
                    : moment(startDate).format("ddd, D MMM YYYY")
                }
              />
            }
            disabled={
              isNew
                ? isNew
                : access_level !== 'full_access'
            }
            onChange={onDateChange}
          />):(
          <DatePicker
            selected={startDate}
            customInput={
              <DateInput
                value={
                  !startDate
                    ? "Select date..."
                    : moment(startDate).format("ddd, D MMM YYYY")
                }
              />
            }
            disabled={
              isNew
                ? isNew
                : clientDetail?.is_admin ||
                  clientDetail?.is_manager ||
                  item?.created_by?._id === user?._id
                ? false
                : true
            }
            onChange={onDateChange}
          />)
          )}
        </div>
      )}
    </div>
  );
};

export default DueDateNew;
