import { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";

import { Portal } from "./Portal";
import ClockIcon2 from "./Icons/ClockIcon2";

const TimePicker = ({ value, onChange, className = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPositioned, setIsPositioned] = useState(false);

  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();

  const [selectedHour, setSelectedHour] = useState("00");
  const [selectedMinute, setSelectedMinute] = useState("00");

  const hourRef = useRef(null);
  const minuteRef = useRef(null);
  const timePickerRef = useRef(null);

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    { placement: "bottom-end" }
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        timePickerRef.current &&
        !timePickerRef.current.contains(event.target) &&
        popperElement &&
        !popperElement.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [timePickerRef, popperElement]);

  useEffect(() => {
    if (isOpen) {
      update?.().then(() => setIsPositioned(true));
      if (hourRef.current) {
        const selectedHourElement = hourRef.current.querySelector(`.selected`);
        if (selectedHourElement) {
          selectedHourElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
      if (minuteRef.current) {
        const selectedMinuteElement =
          minuteRef.current.querySelector(`.selected`);
        if (selectedMinuteElement) {
          selectedMinuteElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    } else {
      setIsPositioned(false);
    }
  }, [isOpen, update]);

  // Set default values when value changes
  useEffect(() => {
    if (value) {
      const [hour, minute] = value.split(":");
      setSelectedHour(hour);
      setSelectedMinute(minute);
    }
  }, [value]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    update?.();
  };

  const handleHourClick = (hour) => setSelectedHour(hour);
  const handleMinuteClick = (minute) => setSelectedMinute(minute);

  const handleSave = () => {
    const selectedTime = `${selectedHour}:${selectedMinute}`;
    onChange(selectedTime);
    setIsOpen(false);
  };

  return (
    <div ref={setReferenceElement} className={`${className}`}>
      <div className="relative" ref={timePickerRef}>
        <div className="flex items-center gap-2 p-2 border rounded border-Neutral300 focus-within:border-AccentRegular focus-within:border-2">
          <input
            type="text"
            className="w-full text-sm font-medium text-Neutral800 focus:outline-none"
            readOnly
            value={`${selectedHour}:${selectedMinute}`}
            onClick={toggleDropdown}
            placeholder="Select time"
          />
          <ClockIcon2 className="text-Neutral700" />
        </div>

        {isOpen && (
          <Portal>
            <div
              ref={setPopperElement}
              className={`absolute right-0 bg-white z-[9999] border rounded shadow-lg p-2 mb-2 ${
                isPositioned ? "opacity-100" : "opacity-0"
              }`}
              style={styles.popper}
              {...attributes.popper}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex space-x-2">
                {/* Hours selection */}
                <div
                  ref={hourRef}
                  className="h-48 overflow-y-auto hide-scrollbar"
                >
                  <ul>
                    {[...Array(24).keys()].map((i) => {
                      const hour = i.toString().padStart(2, "0");
                      return (
                        <li
                          key={i}
                          className={`px-3 py-1 text-sm rounded cursor-pointer text-center transition ${
                            hour === selectedHour
                              ? "bg-AccentRegular text-Neutral100 selected"
                              : "hover:text-AccentRegular hover:bg-AccentLight text-Neutral800"
                          }`}
                          onClick={() => handleHourClick(hour)}
                        >
                          {hour}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                {/* Minutes selection */}
                <div
                  ref={minuteRef}
                  className="h-48 overflow-y-auto hide-scrollbar"
                >
                  <ul>
                    {[...Array(12).keys()].map((i) => {
                      const minute = (i * 5).toString().padStart(2, "0");
                      return (
                        <li
                          key={i}
                          className={`px-3 py-1 text-sm rounded cursor-pointer text-center transition ${
                            minute === selectedMinute
                              ? "bg-AccentRegular text-Neutral100 selected"
                              : "hover:text-AccentRegular hover:bg-AccentLight text-Neutral800"
                          }`}
                          onClick={() => handleMinuteClick(minute)}
                        >
                          {minute}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <button
                className="w-full py-2 mt-3 text-sm font-medium rounded bg-AccentLight hover:text-AccentLight text-AccentRegular hover:bg-AccentRegular"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </Portal>
        )}
      </div>
    </div>
  );
};

export default TimePicker;
