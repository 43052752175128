import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateLeadAction } from "../../../store/actions/leadAction";
import { Menu } from "@headlessui/react";
import clx from "classnames";
import ClockIcon from "../../Icons/ClockIcon";
import MoreIcon from "../../Icons/MoreIcon";
import PhoneIcon from "../../Icons/PhoneIcon";
import MailIcon from "../../Icons/MailIcon";
import FilledStarIcon from "../../Icons/newicons/FilledStarIcon";
import UnfilledStarIcon from "../../Icons/newicons/UnfilledStarIcon";
import ProposalsIcon from "../../Icons/ProposalsIcon";
import LeadsActionMenu from "../../Buttons/LeadsActionMenu";
import moment from "moment";
import ReactTooltip from "react-tooltip";

function LeadsCard({
	lead = null,
	className = "",
	openLeadModal = () => {},
	onDeleteLead = () => {},
}) {
	// State to manage hover effect
	const [hoverRating, setHoverRating] = useState(0);

	// The maximum rating value
	const maxRating = 3;
	const dispatch = useDispatch();
	const logedinUser = useSelector((state) => state.auth.user);
	const userDateFormat = logedinUser?.date_format;

	const handleRatingChange = (rating) => {
		const formData = new FormData();
		formData.append("name", lead.name);
		formData.append("email", lead.email);
		formData.append("phone_number", lead.phone_number);
		formData.append("budget", lead.budget);
		formData.append("business_name", lead.business_name);
		formData.append("website", lead.website);
		formData.append("profile", lead.profile);
		formData.append("id", lead?._id);
		formData.append("rating", rating);
		formData.append("currency", JSON.stringify(lead.currency));
		formData.append("description", JSON.stringify(lead?.description));
		dispatch(updateLeadAction(formData));
	};

	// Function to render stars based on rating
	const renderStars = () => {
		let stars = [];
		for (let i = 1; i <= maxRating; i++) {
			stars.push(
				<div
					key={i}
					className="cursor-pointer"
					onMouseEnter={() => setHoverRating(i)}
					onClick={(e) => {
						e.stopPropagation();
						handleRatingChange(i);
					}}
				>
					{i <= (hoverRating || lead?.rating) ? <FilledStarIcon /> : <UnfilledStarIcon />}
				</div>,
			);
		}
		return stars;
	};

	return (
		<div
			className={clx(
				"flex flex-col items-start justify-center p-4 pr-2 mx-w-[294px] border border-Neutral300 rounded-[6px] bg-Neutral000 z-1",
				className,
			)}
			onClick={() => openLeadModal(lead)}
		>
			<div className="w-[100%] flex items-start justify-between mb-4">
				<div className="flex items-start justify-between">
					{!lead?.profile ? (
						<div
							className={`border mr-2 border-Neutral300 md:w-[36px] md:h-[36px] h-[36px] w-[36px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700  focus-visible:outline-none group-hover:bg-indigo-500 bg-blend-overlay group-hover:bg-opacity-50`}
						>
							<span className="flex items-center justify-center font-bold uppercase border rounded-full  border-Miscgrey">
								{lead?.name?.charAt(0)}
							</span>
						</div>
					) : (
						<img
							src={lead?.profile_path}
							alt={`${lead?.name?.charAt(0)}`}
							width="36"
							height="36"
							className="w-[36px] h-[36px] rounded-full mr-2"
						/>
					)}
					<div>
						<p className="block mb-[6px] text-Neutral900 text-xs font-semibold leading-[16px]">
							{lead?.name}
						</p>
						<p className="flex items-center mb-[6px] text-Neutral600 text-[10px] font-semibold leading-[14px]">
							<ClockIcon className="inline-block mr-1" width={14} />
							{moment(lead?.createdAt).format(userDateFormat)}
						</p>
					</div>
				</div>
				<Menu as="div" className="relative inline-block">
					<div className="flex items-center justify-center">
						<Menu.Button className="relative inline-flex items-center justify-center w-full transition-all focus-visible:outline-none text-Neutral600 hover:text-Neutral800 duration-250">
							<span className="focus-visible:outline-none ">
								<MoreIcon className="mt-[-4px]" />
							</span>
						</Menu.Button>
					</div>
					<LeadsActionMenu leadId={lead?._id} onDeleteLead={onDeleteLead} />
				</Menu>
			</div>
			<div className="flex items-end justify-between w-full">
				<div className="flex flex-col gap-2 text-Neutral700 text-[10px] leading-[14px] font-medium">
					{lead?.business_name && (
						<p className="flex items-center justify-start">
							<ProposalsIcon
								className="inline-block mr-2"
								currentColor="#9C9CA6"
								width={14}
							/>
							{lead?.business_name}
						</p>
					)}
					{lead?.phone_number && (
						<p className="flex items-center justify-start">
							<PhoneIcon className="inline-block mr-2" width={14} />
							{lead?.phone_number}
						</p>
					)}
					{lead?.email && (
						<p className="flex items-center justify-start">
							<MailIcon className="inline-block mr-2" width={14} />
							{lead?.email}
						</p>
					)}
				</div>
				{/* Star rating container */}
				<div
					className="flex"
					onMouseLeave={() => setHoverRating(0)}
					data-for="Lead Rating"
					data-tip="Lead Rating"
				>
					<ReactTooltip
						place="bottom"
						textColor="#F5F5F7"
						className="tooltip-font-size"
						backgroundColor="#292933"
						id="Lead Rating"
					/>
					{renderStars()}
				</div>
			</div>
		</div>
	);
}

export default LeadsCard;
