import React, { useEffect, useState, useCallback } from "react";
import loader from "../../../assets/Images/loader-icon.svg";
import { useNavigate, useLocation } from "react-router";
import { verifyOtpAction } from "../../../store/actions/authAction";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import AccountSelect from "./Components/AccountSelect";
import PlanTypeSelect from "./Components/PlanTypeSelect";
import NameInput from "./Components/NameInput";
import EmailContainer from "./Components/EmailInput";
import CreatePasswordInput from "./Components/CreatePasswordInput";
import OtpInput from "./Components/OtpInput";

const Signup = () => {
  const { state, search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const [isEmailExist, setIsEmailExist] = useState(true);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isVerify, SetIsVerify] = useState(false);
  const [plan, setPlan] = useState(params.get("plan") || null);

  const [signInDetail, setSignInDetail] = useState({
    step: 1,
    // accountType: "",
    // plan: "",
    email: "",
    name: "",
    verify: "",
    password: "",
    user: null,
    is_marketing: false,
  });

  const [isContinueEnable, setIsContinueEnable] = useState(true);

  useEffect(() => {
    if (params.get("plan")) setPlan(params.get("plan"));
  }, []);

  useEffect(() => {
    document.body.classList.add("home-page");
    return () => {
      document.body.classList.remove("home-page");
    };
  }, []);
  useEffect(() => {
    if (state) {
      setSignInDetail(state);
      if (state.email) {
        setIsEmailExist(true);
      }
    }
  }, [state]);

  const stepChange = async (isplus) => {
    const newChange = {
      ...signInDetail,
      step: isplus ? signInDetail.step + 1 : signInDetail.step - 1,
    };

    if (signInDetail.step === 5) {
    }
    setIsContinueEnable(true);
    navigate("", {
      state: newChange,
      replace: true,
    });
    setSignInDetail(newChange);
  };

  useEffect(() => {
    // if (signInDetail?.step === 1) {
    //   if (signInDetail?.accountType !== "") {
    //     setIsContinueEnable(false);
    //   } else {
    //     setIsContinueEnable(true);
    //   }
    // }

    // if (signInDetail?.step === 2) {
    //   if (signInDetail?.plan) {
    //     setIsContinueEnable(false);
    //   } else if (!signInDetail?.plan) {
    //     setIsContinueEnable(true);
    //   }
    // }

    if (signInDetail?.step === 1) {
      if (signInDetail?.name) {
        setIsContinueEnable(false);
      } else if (signInDetail?.name?.length === 0) {
        setIsContinueEnable(true);
      }
    }

    if (signInDetail?.step === 3) {
      // setErrors({...errors, otp: ""})
      if (signInDetail?.verify && signInDetail?.verify?.trim().length === 4) {
        const value = { email: signInDetail?.email, otp: signInDetail?.verify };
        if (!isVerify) {
          verifyOtpAction(value, SetIsVerify, setIsContinueEnable, setErrors);
        } else {
          setIsContinueEnable(false);
        }
      } else if (signInDetail?.verify?.trim().length < 4) {
        setIsContinueEnable(true);
      }
    }
  }, [signInDetail, isEmailExist]);

  const [timer, setTimer] = useState(30);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    if (signInDetail?.step === 3) {
      timer > 0 && setTimeout(timeOutCallback, 1000);
    }
  }, [timer, timeOutCallback, signInDetail]);

  return (
    <>
      <div className="pr-[32px] pt-[16px] flex justify-end items-center">
        <p className="Neutral700 text-xs font-medium mr-[24px]">
          Have an account?
        </p>
        <Link
          to="/signin"
          className="text-xs text-center inline-block text-AccentRegular font-[600] py-[7px] px-2 rounded-[4px] border-[1px] border-Neutral300 hover:border-AccentRegular focus-visible:outline focus-visible:outline-4 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
        >
          Sign In
        </Link>
      </div>
      <section className="container m-auto min-h-[90vh] lg:flex items-center justify-center">
        <div className="flex-col h-full justify-center items-center flex onboading-form">
          {/* {signInDetail.step === 1 ? (
            <div className="bg-Neutral200 md:pb-4 pb-0 w-full">
              <div className="accountcontainer">
                <p className="text-xs lg:text-base text-Neutral800 font-semibold">
                  Step 1 of 6
                </p>
                <h2 className="text-Neutral900 font-bold text-xl lg:text-[32px] pt-2 pb-1 mb-6 sm:mb-12">
                  Select Account Type
                </h2>
                <AccountSelect
                  setSignInDetail={setSignInDetail}
                  signInDetail={signInDetail}
                  plan={plan}
                />
              </div>
            </div>
          ) : null}
          {signInDetail.step === 2 ? (
            <div className="onboading-form-content w-full px-6 md:px-0 md:ml-[30px]">
              <div>
                <p className="text-xs lg:text-base text-Neutral800 font-semibold">
                  Step 2 of 6
                </p>
                <h2 className="text-Neutral900 font-bold text-xl lg:text-[32px] pt-2 pb-1">
                  Select Payment Plan
                </h2>
                <p className=" text-Neutral800 font-normal text-xs md:text-base pt-[2px] lg:pt-1">
                  {signInDetail?.accountType?.name
                    ? `${signInDetail?.accountType?.name} Plan`
                    : ""}
                </p>
              </div>
              <div class="mt-[52px]">
                <PlanTypeSelect
                  setSignInDetail={setSignInDetail}
                  signInDetail={signInDetail}
                />
              </div>
            </div>
          ) : null} */}

          {signInDetail.step === 1 ? (
            <div className="onboading-form-content w-full px-6 md:px-0 md:ml-[30px]">
              <div>
                <p className="text-xs lg:text-base text-Neutral800 font-semibold">
                  Step 1 of 4
                </p>
                <h2 className="text-Neutral900 font-bold text-xl lg:text-[32px] pt-2 pb-1">
                  Enter your name
                </h2>
                <p className=" text-Neutral800 font-normal text-xs md:text-base pt-[2px] lg:pt-1">
                  A first name is fine, you can always update it later.
                </p>
              </div>
              <div class="mt-[52px]">
                <NameInput
                  setSignInDetail={setSignInDetail}
                  signInDetail={signInDetail}
                />
              </div>
            </div>
          ) : null}
          {signInDetail.step === 2 ? (
            <>
              <div>
                <p className="text-xs lg:text-base text-Neutral800 font-semibold">
                  Step 2 of 4
                </p>
                <h2 className="text-Neutral900 font-bold text-xl lg:text-[32px] pt-2 pb-1">
                  Enter your email
                </h2>
                <p className=" text-Neutral800 font-normal text-xs md:text-base pt-[2px] lg:pt-1">
                  You’ll use it to access your ClientManager account.
                </p>
              </div>
              <EmailContainer
                setSignInDetail={setSignInDetail}
                signInDetail={signInDetail}
                setIsEmailExist={setIsEmailExist}
                isEmailExist={isEmailExist}
              />
            </>
          ) : null}
          {signInDetail.step === 3 ? (
            <div className="onboading-form-content w-full px-6 md:px-0">
              <div>
                <p className="text-xs lg:text-base text-Neutral800 font-semibold">
                  Step 3 of 4
                </p>
                <h2 className="text-Neutral900 font-bold text-xl lg:text-[32px] pt-2 pb-1">
                  Verify your identity
                </h2>
                <p className=" text-Neutral800 font-normal text-xs md:text-base pt-[2px] lg:pt-1">
                  Check your email. We’ve sent you a one-time code.
                </p>
              </div>
              <div class="mt-[40px] md:flex pb-3">
                <OtpInput
                  errors={errors}
                  setSignInDetail={setSignInDetail}
                  signInDetail={signInDetail}
                />
              </div>
              {/* <p className="text-Neutral800 font-normal text-sm">
              Didn’t receive code?{" "}
              <span className="italic">Please wait {timer}s</span>
            </p> */}
            </div>
          ) : null}
          {signInDetail.step === 4 && (
            <CreatePasswordInput
              signInDetail={signInDetail}
              setSignInDetail={setSignInDetail}
              plan={plan}
            />
          )}

          {signInDetail?.step !== 2 && signInDetail?.step !== 4 ? (
            <div className="w-full lg:mt-[40px] 3xl:mt-[60px] mt-[24px] lg:px-4 px-3 mb-3">
              <button
                type="button"
                className={`uppercase rounded-lg  ${
                  isContinueEnable
                    ? "text-Neutral500 bg-Neutral300"
                    : "bg-AccentRegular text-Neutral000 working button-hover relative btn-no-data focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight cursor-pointer btn-no-data"
                } font-bold md:h-[46px] h-[60px]  p-[0px_24px] lg:mr-4 lg:w-auto w-full`}
                onClick={() => stepChange(true)}
                disabled={isContinueEnable}
              >
                {loading ? (
                  <img src={loader} alt="loader" className="animate-spin" />
                ) : (
                  <span class="relative z-10">continue</span>
                )}
              </button>
              <button
                type="button"
                onClick={() => {
                  if (signInDetail?.is_marketing && signInDetail.step === 2) {
                    navigate(-1);
                  }
                  if (signInDetail.step >= 1) {
                    stepChange(false);
                  }
                }}
                disabled={signInDetail.step === 1}
                className={`${
                  signInDetail.step !== 1
                    ? "button-hover btn-no-data hover:text-[#fff] text-Neutral900"
                    : "text-Neutral000"
                } relative focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight cursor-pointer uppercase transition duration-300 ease-in-out rounded-lg  font-bold h-[46px] bg-Neutral300 p-[0px_24px] lg:inline-block hidden`}
              >
                <span class="relative z-10">go back</span>
              </button>
              <button
                type="button"
                onClick={() => {
                  if (signInDetail?.is_marketing && signInDetail.step === 2) {
                    navigate(-1);
                  }
                  if (signInDetail.step >= 1) {
                    stepChange(false);
                  }
                }}
                disabled={signInDetail.step === 1}
                className="text-AccentRegular text-xs font-semibold md:hidden"
              >
                Back to Step
              </button>
            </div>
          ) : null}
        </div>
      </section>
      <div className="lg:flex hidden justify-end mr-8 mb-8">
        <p className=" text-Neutral800 text-xs font-medium">
          Have a question? Email us at{" "}
          <span className="text-AccentRegular">support@clientmanager.io</span>
        </p>
      </div>
    </>
  );
};

export default Signup;
