import { GoogleOAuthProvider } from "@react-oauth/google";
import { createContext, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import "./App.css";
import "./assets/css/custom.css";
import ClientRequestInfo from "./components/Client/ClientRequestInfo";
import ClientCollabProjectDashboard from "./components/ClientCollab/ClientCollabProjectDashboard";
import ContractBuilder from "./components/Contract Builder";
import LeadBuilder from "./components/Lead Builder";
import ClientCollabSignin from "./components/Login/ClientCollabSignin";
import MultiPurposePayment from "./components/Login/MultiPurposePayment";
import Signup from "./components/Login/Signup";
import AppsumoSignup from "./components/Login/Signup/AppsumoSignup";
import ClientCollabSignup from "./components/Login/Signup/ClientCollabSignup";
import UpgradePlan from "./components/Login/UpgradePlan";
import UpgradeToUpsellPlan from "./components/Login/UpgradeToUpsellPlan";
import CreateAccount from "./components/Login/create-account";
import ForgotPassword from "./components/Login/forgot-password";
import MemberInvation from "./components/Login/member-invation";
import Profileverify from "./components/Login/profile-verify";
import ResetPassword from "./components/Login/reset-password";
import Signin from "./components/Login/signin";
import MigrateContract from "./components/Migrate/MigrateContract";
import MigrateProposal from "./components/Migrate/MigrateProposal";
import MigrateProposalSignature from "./components/Migrate/MigrateProposalSignature";
import ProposalBuilder from "./components/Proposal Builder/index";
import PublicContractBuilder from "./components/Public Contract Builder";
import PublicInvoiceBuilder from "./components/Public Invoice Builder";
import PublicProposalBuilder from "./components/Public Proposal Builder";
import Questionnaire from "./components/Questionnaire/Questionnaire";
import Admin from "./layouts/Admin";
import ClientCollab from "./layouts/ClientCollab";
import CollabGuestGuard from "./middleware/CollabGuestGuard";
import CollabProtectedRoute from "./middleware/CollabProtectedRoute";
import GuestGuard from "./middleware/GuestGuard";
import ProtectedRoute from "./middleware/ProtectedRoute";
import theme from "./theme";
import Animation from "./views/admin/Animation";
import BobBuilderino from "./views/admin/BobBuilderino";
import ClientDocuments from "./views/admin/ClientDocuments";
import Clients from "./views/admin/Clients";
import ClientsAssets from "./views/admin/ClientsAssets";
import ClientsServices from "./views/admin/ClientsServices";
import ClientsTasks from "./views/admin/ClientsTasks";
import ClientsTasksOld from "./views/admin/ClientsTasksOld";
import CreateInvoice from "./views/admin/CreateInvoice";
import Dashboard from "./views/admin/Dashboard";
import InvoiceBuilder from "./views/admin/InvoiceBuilder";
import InvoiceDashboard from "./views/admin/InvoiceDashboard";
import Invoices from "./views/admin/Invoices";
import LeadQuestionnaireCreate from "./views/admin/LeadQuestionnaireCreate";
import Leads from "./views/admin/Leads";
import Meeting from "./views/admin/Meeting";
import NewTaskPage from "./views/admin/NewTaskPage";
import OldDashboard from "./views/admin/OldDashboard";
import Project from "./views/admin/Project";
import ProjectDashboard from "./views/admin/ProjectDashboard";
import ProposalTemplate from "./views/admin/ProposalTemplate";
import Proposals from "./views/admin/Proposals";
import QuestionnaireCreate from "./views/admin/Questionnaire";
import QuestionnaireBuild from "./views/admin/QuestionnaireBuild";
import RequestInfo from "./views/admin/RequestInfo";
import ScheduleMeeting from "./views/admin/ScheduleMeeting";
import Settings from "./views/admin/Settings";
import Tasks from "./views/admin/Tasks";
import TemplateBuilder from "./views/admin/TemplatesBuilder";
import TemplateDashboard from "./views/admin/TemplatesDashboard";
import TicketForm from "./views/admin/TicketForm";
import Tracking from "./views/admin/Tracking";
import ClientPortal from "./views/admin/ClientPortal";
import Dashboard2 from "./views/admin/Dashboard2";

export const TailwindThemeContext = createContext(theme);

function App() {
  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_API_URL, {
      auth: { token: localStorage.getItem("jwt_access_token") },
      forceNew: true,
    });
    newSocket.emit("join");
    window.socket = newSocket;
  }, [window.socket]);

  return (
    <div className="App">
      <TailwindThemeContext.Provider value={theme}>
        <GoogleOAuthProvider clientId="860737785330-69p10ng9sdf6ed7r82kaet42ulrdp7bb.apps.googleusercontent.com">
          <BrowserRouter>
            <Routes>
              <Route
                path="/signup"
                element={
                  <GuestGuard>
                    <Signup />
                  </GuestGuard>
                }
              />
              <Route
                path="/appsumo-signup"
                element={
                  <GuestGuard>
                    <AppsumoSignup />
                  </GuestGuard>
                }
              />
              <Route
                path="/client-collab/signup"
                element={
                  <CollabGuestGuard>
                    <ClientCollabSignup />
                  </CollabGuestGuard>
                }
              />
              <Route
                path="/client-collab/signin"
                element={
                  <CollabGuestGuard>
                    <ClientCollabSignin />
                  </CollabGuestGuard>
                }
              />
              <Route
                path="/client-collab/forgot-password"
                element={
                  <CollabGuestGuard>
                    <ForgotPassword />
                  </CollabGuestGuard>
                }
              />
              <Route
                path="/client-collab/reset-password"
                element={
                  <CollabGuestGuard>
                    <ResetPassword />
                  </CollabGuestGuard>
                }
              />
              <Route
                path="/signin"
                element={
                  <GuestGuard>
                    <Signin />
                  </GuestGuard>
                }
              />
              <Route
                path="/create-account"
                element={
                  <GuestGuard>
                    <CreateAccount />
                  </GuestGuard>
                }
              />
              <Route path="/member-invitation" element={<MemberInvation />} />
              <Route
                path="/forgot-password"
                element={
                  <GuestGuard>
                    <ForgotPassword />
                  </GuestGuard>
                }
              />
              <Route
                path="/reset-password"
                element={
                  <GuestGuard>
                    <ResetPassword />
                  </GuestGuard>
                }
              />
              <Route
                path="/profile-verify"
                element={
                  <GuestGuard>
                    <Profileverify />
                  </GuestGuard>
                }
              />
              <Route
                path="public-contract/:clientId/contract/:contractId"
                element={<PublicContractBuilder />}
              />
              <Route path="tickets/:ticketId" element={<TicketForm />} />
              <Route
                path="/upgrade-plan"
                element={
                  <ProtectedRoute>
                    <UpgradePlan />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/upgrade-to-discounted-plan"
                element={
                  <ProtectedRoute>
                    <UpgradeToUpsellPlan />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reactive/:id"
                element={
                  <GuestGuard>
                    <MultiPurposePayment />
                  </GuestGuard>
                }
              />
              <Route
                path="/complete/:id"
                element={
                  <GuestGuard>
                    <MultiPurposePayment />
                  </GuestGuard>
                }
              />
              <Route path="/migrate-proposal" element={<MigrateProposal />} />
              <Route path="/migrate-contract" element={<MigrateContract />} />
              <Route
                path="/migrate-proposal-signature"
                element={<MigrateProposalSignature />}
              />
              <Route
                path="public-proposal/:clientId/proposal/:proposalId"
                element={<PublicProposalBuilder />}
              />
              <Route
                path="public-invoice/:clientId/invoice/:invoiceId"
                element={<PublicInvoiceBuilder />}
              />
              <Route
                path="public-proposal/:leadId/lead-proposal/:proposalId"
                element={<PublicProposalBuilder />}
              />
              <Route
                path="/"
                element={<Navigate replace to="admin/dashboard" />}
              />
              <Route
                path="/client-collab"
                element={<Navigate replace to="/client-collab/all-projects" />}
              />
              <Route
                path="/client-collab/all-projects"
                element={
                  <CollabProtectedRoute>
                    <ClientCollab />
                  </CollabProtectedRoute>
                }
              />
              <Route
                path="/client-collab/settings"
                element={
                  <CollabProtectedRoute>
                    <Settings />
                  </CollabProtectedRoute>
                }
              />

              <Route
                path="/client-collab/project/:projectId"
                element={
                  <CollabProtectedRoute>
                    <ClientCollabProjectDashboard />
                  </CollabProtectedRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <Admin />
                  </ProtectedRoute>
                }
              >
                <Route path="task-page" element={<NewTaskPage />} />
                <Route path="old-dashboard" element={<OldDashboard />} />
                <Route path="dashboard" element={<Dashboard2 />} />
                <Route path="dashboard-v2" element={<Dashboard />} />
                <Route path="animation" element={<Animation />} />
                <Route path="clients" element={<Clients />} />
                <Route path="tasks" element={<Tasks />} />
                <Route path="proposals" element={<Proposals />} />
                <Route path="templates" element={<TemplateDashboard />} />
                <Route path="client-portal" element={<ClientPortal />} />
                <Route path="templates/builder" element={<TemplateBuilder />} />
                <Route
                  path=":type/template/:id"
                  element={<ProposalTemplate />}
                />
                <Route
                  path="questionnaire-builder/:id"
                  element={<QuestionnaireBuild />}
                />
                {/* <Route path=":type-builder/:id" element={<CreateInvoice />} /> */}
                {/*  */}
                <Route path="/admin/invoices" element={<InvoiceDashboard />} />
                <Route
                  path="/admin/create-invoice"
                  element={<CreateInvoice />}
                />
                <Route path="/admin/meetings" element={<Meeting />} />
                <Route
                  path="/admin/create-invoice/:clientId"
                  element={<CreateInvoice />}
                />
                <Route
                  path="/admin/create-invoice/:clientId/:invoiceId"
                  element={<CreateInvoice />}
                />
                <Route
                  path="/admin/invoice-creator/:invoiceId"
                  element={<InvoiceBuilder />}
                />
                {/* <Route path="/admin/invoices/:invoiceId" element={<Invoices />} /> */}
                {/*  */}
                <Route
                  path="proposals/builder/:clientId"
                  element={<ProposalBuilder />}
                />
                <Route path="tracking" element={<Tracking />} />
                <Route path="leads" element={<Leads />} />
                <Route path="settings" element={<Settings />} />
                <Route
                  path="clients/bobbuilderino/questionnaire"
                  element={<Questionnaire />}
                />
                <Route path="clients/requestInfo" element={<RequestInfo />} />
                <Route
                  path="clients/bobbuilderino"
                  element={<BobBuilderino />}
                />
                {/* <Route
                path="clients/manage/:clientId"
                element={<ManageClient />}
              /> */}
                <Route
                  path="clients/manage/:clientId"
                  element={<ProjectDashboard />}
                />
                <Route
                  path="clients/manage/:clientId/questionnaire/:questionnaireId"
                  element={<QuestionnaireCreate />}
                />
                <Route
                  path="leads/manage/:leadId/questionnaire/:questionnaireId"
                  element={<LeadQuestionnaireCreate />}
                />
                <Route
                  path="/admin/clients/manage/:clientId/invoice/:invoiceId"
                  element={<Invoices />}
                />
                <Route
                  path="clients/manage/:clientId/tasks"
                  element={<ClientsTasks />}
                />
                <Route
                  path="clients/manage/:clientId/tasks-old"
                  element={<ClientsTasksOld />}
                />
                <Route
                  path="clients/manage/:clientId/documents"
                  element={<ClientDocuments />}
                />
                <Route
                  path="clients/manage/:clientId/assets"
                  element={<ClientsAssets />}
                />
                <Route
                  path="clients/manage/:clientId/services"
                  element={<ClientsServices />}
                />
                <Route
                  path="clients/manage/:clientId/proposal"
                  element={<ProposalBuilder />}
                />
                <Route
                  path="clients/manage/:clientId/proposal/:proposalId"
                  element={<ProposalBuilder />}
                />
                <Route
                  path="leads/manage/:leadId/proposal/:proposalId"
                  element={<LeadBuilder />}
                />
                <Route
                  path="clients/manage/:clientId/contract/:contractId"
                  element={<ContractBuilder />}
                />
                <Route
                  path="clients/bobbuilderino/documents"
                  element={<ClientDocuments />}
                />
                <Route
                  path="clients/bobbuilderino/tasks"
                  element={<ClientsTasksOld />}
                />
                <Route
                  path="clients/bobbuilderino/assets"
                  element={<ClientsAssets />}
                />
                <Route
                  path="clients/bobbuilderino/services"
                  element={<ClientsServices />}
                />
                <Route
                  path="clients/manage/:clientId/project/:projectId"
                  element={<Project />}
                />
              </Route>
              <Route
                path="/request-info/:key"
                element={<ClientRequestInfo />}
              />
              <Route
                path="/schedule-meeting/:username/:scheduleSlug"
                element={<ScheduleMeeting />}
              />
            </Routes>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </TailwindThemeContext.Provider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        className={"z-100"}
      />
    </div>
  );
}

export default App;
