import React from "react";

import PlayIcon from "../../../Icons/PlayIcon";

function TrackerControl({
  isRunning = false,
  project,
  setIsStartTimerModalOpen,
  isSmall = false,
}) {
  const handleStarTimer = () => {
    setIsStartTimerModalOpen(true);
  };

  const handleStopTimer = () => {
    setIsStartTimerModalOpen(true);
  };

  const onClick = () => (!isRunning ? handleStarTimer() : handleStopTimer());

  if (isSmall) {
    return !isRunning ? (
      <div
        className="w-10 h-10 rounded-full flex justify-center items-center bg-indigo-500 transition duration-300 hover:bg-indigo-600 border-4 border-indigo-100 cursor-pointer"
        onClick={() => onClick()}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 4L20 12L8 20V4Z"
            fill="none"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ) : (
      <button
        className="w-10 h-10 rounded-full flex justify-center items-center bg-Red500 transition duration-300 hover:bg-red-600 border-4 border-red-100 cursor-pointer relative before:content-[''] before:w-[12px] before:h-[12px] before:bg-gray-100 before:border-2 before:border-gray-100 before:rounded-[1px]"
        onClick={() => onClick()}
      ></button>
    );
  }

  return !isRunning ? (
    <button
      className="min-w-[180px] h-[48px] rounded flex justify-center items-center leading-[110%] tracking-[0.04em] text-xs text-Green500 bg-Neutral200 uppercase font-bold transition duration-300 hover:bg-Neutral400"
      onClick={() => onClick()}
    >
      <PlayIcon className="mr-2" /> Start Timer
    </button>
  ) : (
    <button
      className="min-w-[180px] h-[48px] rounded flex justify-center items-center leading-[110%] tracking-[0.04em] text-xs text-Red500 bg-Red100 uppercase font-bold relative before:content-[''] before:mr-3 before:w-[16px] before:h-[16px] before:bg-Red500 before:rounded-[1px] transition duration-300 hover:bg-Red200"
      onClick={() => onClick()}
    >
      Stop Timer
    </button>
  );
}

export default TrackerControl;
