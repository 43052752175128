import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { Menu } from "@headlessui/react";
import clx from "classnames";

import ThreeDotsIcon from "../Icons/ThreeDotsIcon";

import EditIcon from "../Icons/EditIcon";
import DeleteIcon from "../Icons/DeleteIcon";
import DuplicateIcon from "../Icons/DuplicateIcon";

function ProjectActionMenuButton({
  className = "",
  onEdit,
  onDelete,
  clientDetail,
  onDuplicate,
}) {
  return (
    <Menu
      as="div"
      className={clx("relative flex items-center text-left", className)}
    >
      <div className="flex items-center">
        <Menu.Button className="inline-flex justify-center w-full">
          <ThreeDotsIcon />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="edit-menu-servies edit-delete-menu absolute z-[999] top-0 left-0 w-[120px] rounded-lg bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none ">
          <Menu.Item>
            <div>
              <div
                className="w-[120px] text-left text-Neutral800 px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300"
                onClick={() => onEdit()}
              >
                <EditIcon className="mr-2" />
                Edit
              </div>
              {clientDetail?.data?.is_admin === true ? (
                <Menu>
                  {({ open }) => (
                    <>
                      <Menu.Button
                        className={clx(
                          "text-Neutral800 w-[120px] text-left px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300",
                          {
                            "bg-Neutral200": open,
                          }
                        )}
                      >
                        <DeleteIcon className="mr-2" color="#5A5A66" />
                        Delete
                      </Menu.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute -top-[60px] right-[-10px] pt-4 pb-6 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none z-10">
                          <p className="text-Neutral800 text-left px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300">
                            Are you sure?
                          </p>

                          <button
                            className="border-0 overflow-hidden w-[100%] mt-5"
                            onClick={() => onDelete()}
                          >
                            <h3 className="text-white text-xs  w-full bg-Red400 font-semibold py-4 rounded text-center cursor-pointer hover:bg-Red600 duration-300">
                              Yes, Delete it
                            </h3>
                          </button>
                          <Menu.Button className="overflow-hidden border-0 !border-transparent divide-0 w-[100%]">
                            <h3 className="text-Neutral800 bg-Neutral200 font-semibold text-xs mt-3 w-full py-4 rounded text-center cursor-pointer hover:bg-Neutral500 duration-300 hover:text-Neutral900">
                              No, Keep it
                            </h3>
                          </Menu.Button>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              ) : null}
              <div
                className="w-[120px] text-left text-Neutral800 px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center cursor-pointer font-semibold hover:bg-Neutral200 transition duration-300"
                onClick={() => onDuplicate()}
              >
                <DuplicateIcon className="mr-2" />
                Duplicate
              </div>
            </div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default ProjectActionMenuButton;
