import React from "react";

function CalendarIcon({ width = 24, color = "#9C9CA6", className = "" }) {
  return (
    <svg className={className}
      width={width}
      height={width} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7222 4.44434H6.27778C5.29594 4.44434 4.5 5.24027 4.5 6.22211V18.6666C4.5 19.6484 5.29594 20.4443 6.27778 20.4443H18.7222C19.7041 20.4443 20.5 19.6484 20.5 18.6666V6.22211C20.5 5.24027 19.7041 4.44434 18.7222 4.44434Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.0555 2.66699V6.22255" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.94446 2.66699V6.22255" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.5 9.77832H20.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.5 13.5H13.5" stroke={color} stroke-width="1.5" stroke-linecap="round" />
      <path d="M17.5 13.5H16.5" stroke={color} stroke-width="1.5" stroke-linecap="round" />
      <path d="M14.5 16.5H13.5" stroke={color} stroke-width="1.5" stroke-linecap="round" />
      <path d="M17.5 16.5H16.5" stroke={color} stroke-width="1.5" stroke-linecap="round" />
      <path d="M11.5 13.5H10.5" stroke={color} stroke-width="1.5" stroke-linecap="round" />
      <path d="M11.5 16.5H10.5" stroke={color} stroke-width="1.5" stroke-linecap="round" />
      <path d="M8.5 13.5H7.5" stroke={color} stroke-width="1.5" stroke-linecap="round" />
      <path d="M8.5 16.5H7.5" stroke={color} stroke-width="1.5" stroke-linecap="round" />
    </svg>
  );
}

export default CalendarIcon;
