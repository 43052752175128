const initialState = {
  isLogin: false,
  authToken: localStorage.getItem("jwt_access_token"),
  userLoading: true,
  user: null,
  accountDetail: {
    response: null,
    isLoading: true,
  },
  basicInfo: null,
  refrence: [],
};
const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOGIN":
      return {
        ...state,
        isLogin: payload.isLogin,
        authToken: payload.token,
      };
    case "LOGOUT":
      return {
        ...state,
        isLogin: false,
        authToken: null,
        user: null,
      };
    case "SET_USER_DATA": {
      return {
        ...state,
        user: payload.data,
        userLoading: false,
      };
    }

    case "ACCOUNT_DETAILS":
      return {
        ...state,
        accountDetail: payload,
      };
    case "CLOSE_WELCOME_MODAL":
      return {
        ...state,
        user: { ...state.user, seen_welcome_modal: false },
      };
    case "GET_BASIC_USER_INFO":
      return {
        ...state,
        basicInfo: payload.data,
      };
    case "CREATE_LEAD_COLUMN_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
        },
      };
    case "UPDATE_LEAD_COLUMN_POSITION_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
        },
      };
    case "REMOVE_LEAD_COLUMN_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
        },
      };
    case "CREATE_PREWELCOME_DATA_SUCCESS":
      return {
        ...state,
        refrence: payload.data,
        user: {
          ...state.user,
          ...payload.user,
        },
      };
    case "ADD_UPDATE_GOOGLE_CALENDAR":
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
        },
      };
    case "UPDATE_QUESTIONNAIRE_DEFAULT_TEXT":
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
        },
      };
    case "UPDATE_SUBDOMAIN_SUCCESS_BY_USER":
      return {
        ...state,
        user: {
          ...state.user,
          subDomain: payload.data,
        },
      };
    default:
      return state;
  }
};

export default authReducer;
