import { Field } from "formik";
import moment from "moment";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { currencies } from "../../assets/json/currencies";
import CalendarIcon from "../Icons/CalendarIcon";
import ButtonLoader from "../Loaders/ButtonLoader";
import CustomErrorMessage from "./CustomErrorMessage";
import { useSelector } from "react-redux";

function Step4({
	active,
	onPrevious,
	complete,
	setStep,
	values,
	setFieldValue,
	isSubmitting,
	errors,
	selectedDueDate,
	setSelectedDueDate,
	loadingPaymentLink,
}) {
	const isTemplate = window.location.pathname.includes("invoice-creator");
	const logedinUser = useSelector((state) => state.auth.user);
	const userDateFormat = logedinUser?.date_format;

	const DateInput = forwardRef(({ value, onClick }, ref) => {
		return (
			<button
				className="bg-white border border-gray-100 rounded p-3 text-neutral-500 text-sm font-medium outline-none w-[210px] md:w-80 flex justify-between items-center"
				onClick={onClick}
				ref={ref}
				type="button"
			>
				<span>{moment(value).format(userDateFormat)}</span>
			</button>
		);
	});
	return (
		<div
			className={`bg-neutral-50 p-4 rounded-lg w-full step flex ${
				active ? "flex-col items-start justify-start" : "h-14 items-center"
			}
      `}
		>
			<div
				className={`flex-col justify-center items-start gap-0.5 flex w-full ${
					!active && "cursor-pointer"
				}`}
				onClick={() => !active && setStep(4)}
			>
				<span className="flex items-center w-full gap-6 text-xs font-bold tracking-wide">
					<span
						className={`min-w-[40px] ${complete ? "text-emerald-400" : "text-indigo-500"} `}
					>
						STEP 4
					</span>
					{!active && (
						<span className="flex items-center justify-between w-full">
							<span className="text-xs font-bold text-zinc-500">Invoice Details</span>
							{!active && complete && (
								<span className="text-xs font-bold tracking-wide">
									<span
										className={`w-12 ${
											complete ? "text-emerald-400" : "text-indigo-500"
										}`}
									>
										<svg
											width="22"
											height="22"
											viewBox="0 0 22 22"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<rect width="22" height="22" rx="11" fill="#42D6A6" />
											<path
												d="M16.6477 7.40332L9.20657 14.3613L5.82422 11.1986"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</span>
								</span>
							)}
						</span>
					)}
				</span>
				<div className={`justify-start items-center gap-3 flex ${!active && "hidden"}`}>
					<div className="text-base font-bold tracking-wide text-zinc-800">
						Invoice Details
					</div>
				</div>
			</div>
			<div
				className={`justify-start items-start gap-6 flex flex-col mt-5 w-full ${
					!active && "hidden"
				}`}
			>
				<div className="flex flex-wrap items-start justify-start w-full gap-6 md:w-2/3">
					{/* INVOICE NUMBER */}
					<div>
						<label
							htmlFor="invoiceNumber"
							className="block mb-2 text-xs font-bold text-zinc-800"
						>
							Invoice Number
						</label>
						<Field
							name="invoiceNumber"
							type="number"
							className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500 md:w-80"
							placeholder="Enter the Invoice Number"
						/>
						<CustomErrorMessage name="invoiceNumber" />
					</div>
					{/* INVOICE NUMBER */}

					{/* CHOOSE CURRENCY */}
					<div>
						<label htmlFor="currency" className="block mb-2 text-xs font-bold text-zinc-800">
							Choose Currency
						</label>
						<div className="relative">
							<Field
								name="currency"
								as="select"
								id="currency"
								className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500 md:w-80"
								value={values.currency || currencies[0].code}
							>
								{currencies
									?.sort((a, b) => a.code.localeCompare(b.code))
									.map((currency) => (
										<option
											className="text-zinc-800"
											value={currency.code}
											key={currency.code}
										>
											{currency.code} ({currency.symbol})
										</option>
									))}
							</Field>
							<span
								style={{
									right: "10px",
									top: "50%",
									transform: "translateY(-50%)",
								}}
								className="absolute pointer-events-none"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7 10L12 15L17 10"
										stroke="#6D6DF2"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
						</div>

						<CustomErrorMessage name="currency" />
					</div>

					{/* CHOOSE CURRENCY */}

					{/* INVOICE DATE */}
					<div>
						<label
							htmlFor="invoiceDate"
							className="block mb-2 text-xs font-bold text-zinc-800"
						>
							Invoice Date
						</label>
						<div className="relative">
							<DatePicker
								selected={
									isTemplate
										? moment(values?.invoiceDate, "DD/MM/YYYY").toDate()
										: moment(values?.invoiceDate).toDate()
								}
								onChange={(date) =>
									setFieldValue(
										"invoiceDate",
										isTemplate
											? moment(date).format("DD/MM/YYYY")
											: moment(date).toDate(),
									)
								}
								customInput={<DateInput value={values?.invoiceDate} />}
							/>
							<span
								style={{
									right: "10px",
									top: "50%",
									transform: "translateY(-50%)",
								}}
								className="absolute pointer-events-none"
							>
								<CalendarIcon className="w-6 h-6 text-indigo-500" />
							</span>
						</div>
						<CustomErrorMessage name="invoiceDate" />
					</div>
					{/* INVOICE DATE */}

					{/* DUE DATE */}
					<div>
						<label htmlFor="dueDate" className="block mb-2 text-xs font-bold text-zinc-800">
							Due Date
						</label>
						<div className="relative">
							<DatePicker
								selected={isTemplate
								? moment(values?.dueDate, "DD/MM/YYYY").toDate()
								: moment(values?.dueDate).toDate()}
								onChange={(date) => {
									setSelectedDueDate(
										isTemplate
											? moment(date, "DD/MM/YYYY").toDate()
											: moment(date).toDate(),
									);
									setFieldValue("dueDate", isTemplate 
										? moment(date).format("DD/MM/YYYY") 
										: moment(date).toDate());
								}}
								customInput={<DateInput value={values?.dueDate} />}
							/>
							<span
								style={{
									right: "10px",
									top: "50%",
									transform: "translateY(-50%)",
								}}
								className="absolute pointer-events-none"
							>
								<CalendarIcon className="w-6 h-6 text-indigo-500" />
							</span>
						</div>
						<CustomErrorMessage name="dueDate" />
					</div>

					{/* DUE DATE */}

					{/* VAT PERCENT */}
					<div className="flex w-full gap-10 md:w-2/3">
						<div className="w-full">
							<label
								htmlFor="vatPercentage"
								className="flex flex-col-reverse items-start gap-2 mb-1 text-xs font-bold md:flex-row text-zinc-800 md:items-center"
							>
								<div>
									{values.is_VAT ? "VAT" : "Custom Tax"} %{/* VAT Percentage */}
									{/* VAT Percentage */}
									<span className="text-xs text-gray-400"> (Optional)</span>
								</div>
								<div className="flex items-center justify-between gap-2 md:ml-4">
									<h5
										className={`text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase ${
											values.is_VAT && "text-indigo-500"
										}`}
									>
										VAT
									</h5>
									<label className="flex items-center cursor-pointer select-none toggle-switch">
										<div className="relative">
											<input
												type="checkbox"
												className="sr-only"
												checked={!values.is_VAT}
												onChange={() => {
													setFieldValue("is_VAT", !values.is_VAT);
												}}
											/>
											<div className="block h-[20px] switch w-[40px] rounded-full border-Neutral400 border-2 bg-Neutral100"></div>
											<div className="dot absolute left-0 top-0 h-5 w-5 rounded-full bg-Neutral800 transition shadow-[0px_1.5px_3px_1px_rgba(0,0,0,0.2)]"></div>
										</div>
									</label>
									<h5
										className={`text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase ${
											!values.is_VAT && "text-indigo-500"
										}`}
									>
										Custom Tax
									</h5>
								</div>
							</label>
							<Field
								name="vatPercentage"
								type="number"
								className={`bg-white border border-gray-100 rounded p-3 text-neutral-500 text-sm font-medium outline-none w-full md:w-80 ${
									!values.is_VAT && "cursor-not-allowed opacity-50"
								}`}
								placeholder="VAT Percentage"
								min="0"
								max="100"
								onBlur={(e) => {
									if (e.target.value === "") {
										setFieldValue("vatPercentage", 0);
									}
									if (e.target.value > 100) {
										setFieldValue("vatPercentage", 100);
									}
									if (e.target.value < 0) {
										setFieldValue("vatPercentage", 0);
									}
								}}
							/>
							<CustomErrorMessage name="vatPercentage" />
						</div>
					</div>
					{/* VAT PERCENT */}
				</div>

				{/* CUSTOM TAX */}
				{!values.is_VAT && (
					<div className="flex flex-col items-start justify-start gap-6">
						<h2 className="px-1 font-bold text-zinc-800">Custom Tax</h2>
						{values.taxes?.map((tax, index) => (
							<div
								className="flex flex-col items-start justify-start gap-6"
								key={`tax-${index}`}
							>
								<div className="flex items-center gap-6">
									{/* TAX NAME */}
									<div>
										<label
											htmlFor={`taxes[${index.toString()}].taxName`}
											className="block mb-2 text-xs font-bold text-zinc-800"
										>
											Tax Name
										</label>
										<Field
											name={`taxes[${index.toString()}].taxName`}
											type="text"
											value={values.taxes?.[index]?.taxName || ""}
											className="p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500 w-80"
											placeholder="Eg. VAT/GST"
										/>
										<CustomErrorMessage name={`taxes[${index.toString()}].taxName`} />
									</div>
									{/* TAX NAME */}

									{/* TAX VALUE */}

									<div>
										<label
											htmlFor={`taxes[${index.toString()}].taxValue`}
											className="block mb-2 text-xs font-bold text-zinc-800"
										>
											Value %
										</label>
										<Field
											name={`taxes[${index.toString()}].taxValue`}
											type="number"
											placeholder={0}
											min={0}
											value={values.taxes?.[index]?.taxValue || ""}
											id={`taxes[${index.toString()}].taxValue`}
											className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-80"
										/>
										<CustomErrorMessage name={`taxes[${index.toString()}].taxValue`} />
									</div>

									{values.taxes?.length > 1 && (
										<button
											className="flex flex-col items-center justify-center w-10 h-10 gap-2 font-semibold text-indigo-500 border border-gray-200 rounded-full hover:bg-red-400 hover:text-white"
											onClick={() =>
												setFieldValue(
													"taxes",
													values.taxes.filter((tax, i) => i !== index),
												)
											}
											type="button"
										>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M18 6L6 18"
													stroke="url(#paint0_linear_7548_90924)"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M6 6L18 18"
													stroke="url(#paint1_linear_7548_90924)"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<defs>
													<linearGradient
														id="paint0_linear_7548_90924"
														x1="6"
														y1="6"
														x2="17.9868"
														y2="18.6395"
														gradientUnits="userSpaceOnUse"
													>
														<stop stopColor="#6D6DF2" stopOpacity="0.8" />
														<stop offset="1" stopColor="#6D6DF2" />
													</linearGradient>
													<linearGradient
														id="paint1_linear_7548_90924"
														x1="6"
														y1="6"
														x2="17.9868"
														y2="18.6395"
														gradientUnits="userSpaceOnUse"
													>
														<stop stopColor="#6D6DF2" stopOpacity="0.8" />
														<stop offset="1" stopColor="#6D6DF2" />
													</linearGradient>
												</defs>
											</svg>
										</button>
									)}

									{/* TAX VALUE */}
								</div>
							</div>
						))}
						<button
							className={`px-6 h-12 bg-gray-100 rounded flex-col justify-center items-center gap-2 flex hover:bg-gray-200 text-indigo-500 font-semibold ${
								values.taxes?.length >= 3 && "cursor-not-allowed opacity-50"
							}`}
							onClick={() => {
								setFieldValue("taxes", [
									...values.taxes,

									{
										taxName: "",
										taxValue: null,
									},
								]);
							}}
							type="button"
							disabled={values.taxes?.length >= 3}
						>
							Add Tax
						</button>
					</div>
				)}
				{/* CUSTOM TAX */}

				{/* SERVICES */}
				<div className="flex flex-col items-start justify-start gap-6">
					{values.services?.map((service, index) => (
						<div className="flex flex-col items-start justify-start gap-6" key={index}>
							<h2 className="px-1 font-bold text-zinc-800">Service #{index + 1}</h2>

							{/* SERVICE TYPE */}
							<div>
								<label
									htmlFor={`services[${index}].serviceType`}
									className="block mb-2 text-xs font-bold text-zinc-800"
								>
									Service Type
								</label>
								<div className="relative">
									<Field
										name={`services[${index}].serviceType`}
										as="select"
										className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-80"
										value={values.services?.[index]?.serviceType || ""}
									>
										<option className="text-zinc-800" value="" disabled>
											Select Service Type
										</option>
										<option className="text-zinc-800" value="Hourly">
											Hourly
										</option>
										<option className="text-zinc-800" value="Fixed">
											Fixed
										</option>
									</Field>
									<span
										style={{
											right: "10px",
											top: "50%",
											transform: "translateY(-50%)",
										}}
										className="absolute pointer-events-none"
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M7 10L12 15L17 10"
												stroke="#6D6DF2"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</span>
								</div>

								<CustomErrorMessage name={`services[${index}].serviceType`} />
								{(msg) => <div className="mt-2 text-sm text-red-600">{msg}</div>}
							</div>
							{/* SERVICE TYPE */}
							{values.services?.[index]?.serviceType && (
								<div className="flex flex-col items-center gap-6 md:flex-row">
									{/* SERVICE NAME */}
									<div>
										<label
											htmlFor={`services[${index}].serviceName`}
											className="block mb-2 text-xs font-bold text-zinc-800"
										>
											Service Name
										</label>
										<Field
											name={`services[${index}].serviceName`}
											type="text"
											className="p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500 w-80"
											placeholder="Eg. Creating Design / Marketing"
										/>
										<CustomErrorMessage name={`services[${index}].serviceName`} />
									</div>
									{/* SERVICE NAME */}

									{/* SERVICE PRICE */}

									<div>
										<label
											htmlFor={`services[${index}].servicePrice`}
											className="block mb-2 text-xs font-bold text-zinc-800"
										>
											Price{" "}
											{values.services?.[index]?.serviceType !== "Fixed" && "Per Hour"}
										</label>
										<Field
											name={`services[${index}].servicePrice`}
											placeholder={0}
											type="number"
											id={`services[${index}].servicePrice`}
											className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-80"
										/>
										<CustomErrorMessage name={`services[${index}].servicePrice`} />
									</div>

									{/* HOURS SPENT */}
									{values.services?.[index]?.serviceType === "Hourly" && (
										<div>
											<label
												htmlFor={`services[${index}].quantity`}
												className="block mb-2 text-xs font-bold text-zinc-800"
											>
												Hours Spent
											</label>
											<Field
												name={`services[${index}].quantity`}
												placeholder={0}
												type="number"
												id={`services[${index}].quantity`}
												className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-50"
											/>
											<CustomErrorMessage name={`services[${index}].quantity`} />
										</div>
									)}

									{values.services?.length > 1 && (
										<button
											className="flex flex-col items-center justify-center w-10 h-10 gap-2 font-semibold text-indigo-500 border border-gray-200 rounded-full hover:bg-red-400 hover:text-white"
											onClick={() =>
												setFieldValue(
													"services",
													values.services.filter((service, i) => i !== index),
												)
											}
											type="button"
										>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M18 6L6 18"
													stroke="url(#paint0_linear_7548_90924)"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M6 6L18 18"
													stroke="url(#paint1_linear_7548_90924)"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<defs>
													<linearGradient
														id="paint0_linear_7548_90924"
														x1="6"
														y1="6"
														x2="17.9868"
														y2="18.6395"
														gradientUnits="userSpaceOnUse"
													>
														<stop stopColor="#6D6DF2" stopOpacity="0.8" />
														<stop offset="1" stopColor="#6D6DF2" />
													</linearGradient>
													<linearGradient
														id="paint1_linear_7548_90924"
														x1="6"
														y1="6"
														x2="17.9868"
														y2="18.6395"
														gradientUnits="userSpaceOnUse"
													>
														<stop stopColor="#6D6DF2" stopOpacity="0.8" />
														<stop offset="1" stopColor="#6D6DF2" />
													</linearGradient>
												</defs>
											</svg>
										</button>
									)}

									{/* SERVICE PRICE */}
								</div>
							)}
						</div>
					))}
					<button
						className="flex flex-col items-center justify-center h-12 gap-2 px-6 font-semibold text-indigo-500 bg-gray-100 rounded hover:bg-gray-200"
						onClick={() => {
							setFieldValue("services", [
								...values.services,
								{
									serviceType: "",
									serviceName: "",
									servicePrice: null,
									quantity: null,
								},
							]);
						}}
						type="button"
					>
						Add Service
					</button>
					{typeof errors?.services === "string" && <CustomErrorMessage name="services" />}
				</div>
				{/* SERVICES */}
				{/*  */}

				<div className="flex flex-col items-start justify-start w-full gap-6 md:flex-row">
					<button
						className={`w-full md:w-80 h-12 bg-indigo-500  rounded flex-col justify-center items-center gap-2 flex hover:bg-indigo-600 text-neutral-50`}
						disabled={isSubmitting}
						type="submit"
					>
						<div className="w-full text-sm font-bold leading-none text-center text-neutral-50">
							{isSubmitting || loadingPaymentLink ? <ButtonLoader /> : "Next"}
						</div>
					</button>
					<button
						type="button"
						className="flex flex-col items-center justify-center h-12 gap-2 px-6 bg-gray-100 rounded hover:bg-gray-200"
						onClick={onPrevious}
					>
						<div className="text-sm font-bold leading-none text-center text-indigo-500">
							Previous
						</div>
					</button>
				</div>
			</div>
		</div>
	);
}

export default Step4;
