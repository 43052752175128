import { Menu, Transition } from "@headlessui/react";
import copy from "copy-to-clipboard";
import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ReactTooltip from "react-tooltip";

import LinkCopied from "../../assets/Images/CheckmarkAccent.svg";
import Contracts1 from "../../assets/Images/Contracts-1.svg";
import ContractsArrow from "../../assets/Images/Contracts-Arrow.svg";
import ContractsLine from "../../assets/Images/Contracts-line.svg";
import LinkIcon from "../../assets/Images/LinkAccent.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import {
  DeleteQuestionnaire,
  GetQuestionnaireOfClientId,
} from "../../store/actions/questionnaireAction";
import EyeIcon from "../Icons/EyeIcon";
import TrashIcon from "../Icons/TrashIcon";
import MessageIcon from "../Icons/newicons/_MessageIcon";

/** @type {import("react-modal").Styles} */
const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const ViewQuestionnaireList = ({
  isOpen,
  setIsOpen,
  clientId,
  setSelectedData,
  isOpenEntityTemplateModal,
  isEmailModalOpen,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const questionnaires = useSelector((state) => state.questionnaire.questionnaires);
  const [isLoading, setIsLoading] = useState({
    status: false,
    id: "",
  });
  const [copiedStatuses, setCopiedStatuses] = useState({});

  useEffect(() => {
    if (clientId !== questionnaires?.client) {
      dispatch(GetQuestionnaireOfClientId(clientId));
    }
  }, [clientId, dispatch]);

  const handleDeleteQuestionnaire = (id) => {
    setIsLoading({
      status: true,
      id: id,
    });
    dispatch(DeleteQuestionnaire(id, setIsLoading));
  };

  const handleCopyQuestionnaireURL = (id) => {
    copy(`${window.location.origin}/request-info/${id}`);
    // Set the copy status for the specific questionnaire to true
    setCopiedStatuses((prevStatuses) => ({
      ...prevStatuses,
      [id]: true,
    }));

    // Reset the status back to false after some time if you want to show the copied icon temporarily
    setTimeout(() => {
      setCopiedStatuses((prevStatuses) => ({
        ...prevStatuses,
        [id]: false,
      }));
    }, 2000);
  };

  // Target keys - total_questoins, answered_questoins

  console.log(questionnaires.data);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all w-[80vw] sm:my-8 sm:min-w-[500px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-12 rounded-[8px]">
          <div>
            <div className="text-left">
              <div className="flex justify-between w-full pb-2 mb-8 border-b border-Neutral200">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold"
                  id="modal-title"
                >
                  Questionnaire
                </h3>
                {!questionnaires?.data?.length && (
                  <button
                    className="text-xs font-medium transition-all duration-300 cursor-pointer text-AccentRegular"
                    onClick={() => {
                      isOpenEntityTemplateModal();
                    }}
                  >
                    Add Questionnaire
                  </button>
                )}
              </div>
              <div className="mt-4 h-[188px] overflow-y-auto overflow-hidden scrollbar-w-4 -mr-3 pr-3 pb-5">
                {questionnaires?.data?.length ? (
                  questionnaires?.data?.map((questionnaire, index) => {
                    return (
                      <div
                        key={index}
                        className="flex items-center justify-between w-full h-12 border-b border-Neutral300"
                      >
                        <h5
                          data-tip={`${questionnaire?.title}`}
                          data-for="questionnaireTitle"
                          className="text-sm text-normal text-Neutral800 w-[25ch] text-ellipsis"
                        >
                          {questionnaire?.title}
                        </h5>

                        <ReactTooltip
                          textColor="#F5F5F7"
                          // className="tooltip-font-size"
                          backgroundColor="#292933"
                          id="questionnaireTitle"
                        />

                        <div
                          data-tip={`${questionnaire.answered_questoins} Questions Answered`}
                          data-for="questionsAnswered"
                          className="flex items-center text-xs font-semibold tracking-wider cursor-pointer text-AccentRegular"
                        >
                          {questionnaire.answered_questoins}/{questionnaire.total_questoins}
                        </div>

                        <ReactTooltip
                          textColor="#F5F5F7"
                          // className="tooltip-font-size"
                          backgroundColor="#292933"
                          id="questionsAnswered"
                        />

                        <div className="flex items-center justify-between gap-1">
                          <span
                            className="flex items-center justify-end h-8 text-xs font-semibold rounded cursor-pointer text-AccentRegular"
                            onClick={() => {
                              isEmailModalOpen(questionnaire?._id);
                              setSelectedData(questionnaire);
                            }}
                          >
                            <MessageIcon width={16} color="#6D6DF2" />
                          </span>
                          {copiedStatuses[questionnaire._id] ? (
                            <span
                              className="flex items-center justify-end h-8 mr-1 text-xs font-semibold rounded cursor-pointer text-AccentRegular"
                              title="Link Copied"
                              key={questionnaire?._id}
                            >
                              <img src={LinkCopied} alt="" />
                            </span>
                          ) : (
                            <span
                              className="flex items-center justify-end h-8 mr-1 text-xs font-semibold rounded cursor-pointer text-AccentRegular"
                              onClick={() => {
                                handleCopyQuestionnaireURL(questionnaire?._id);
                              }}
                              title="Copy Link"
                              key={questionnaire?._id}
                            >
                              <img src={LinkIcon} alt="" />
                            </span>
                          )}
                          <span
                            className="flex items-center justify-end h-8 text-xs font-semibold rounded cursor-pointer text-AccentRegular"
                            onClick={() => {
                              navigate(
                                `/admin/clients/manage/${clientId}/questionnaire/${questionnaire?._id}`
                              );
                            }}
                          >
                            <EyeIcon width={16} />
                          </span>
                          <Menu
                            as="div"
                            className="pt-[1px]"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Menu.Button className="p-1 duration-300 ease-in-out cursor-pointer text-AccentRegular hover:text-AccentMediumDark">
                              {isLoading?.status && isLoading?.id === questionnaire?._id ? (
                                <svg
                                  aria-hidden="true"
                                  class="inline w-4 h-4 mr-2 text-white animate-spin dark:text-white fill-AccentRegular"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                              ) : (
                                <TrashIcon width={14} height={16} className="cursor-pointer" />
                              )}
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="focus:outline-none min-w-[384px] absolute right-[-20px] top-[20px] w-36 origin-top-right divide-y divide-Neutral200 bg-white ring-1 ring-black ring-opacity-5 drop-shadow-Timetracking">
                                <div className="px-8 pt-6 pb-8">
                                  <Menu.Item>
                                    {({ close }) => (
                                      <div>
                                        <h5 className="pb-2 text-xs font-semibold border-b text-Neutral700 border-Neutral200">
                                          Are you sure?
                                        </h5>

                                        <button
                                          onClick={() => {
                                            handleDeleteQuestionnaire(questionnaire?._id);
                                          }}
                                          className="text-Neutral000 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Red400 hover:bg-Red600 rounded-[4px] mt-8"
                                        >
                                          Yes, Delete Questionnaire
                                        </button>

                                        <button
                                          onClick={close}
                                          className="text-Neutral800 hover:text-Neutral900 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Neutral300 hover:bg-Neutral400 rounded-[4px] mt-5"
                                        >
                                          No, I Still Want This Questionnaire
                                        </button>
                                      </div>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full max-w-[144px]">
                    <div className="relative">
                      <img className="absolute -top-[8px] -left-[12px]" src={Contracts1} alt="" />
                      <img
                        className="absolute -top-[86px] left-[45%]"
                        src={ContractsArrow}
                        alt=""
                      />
                      <img className="absolute -bottom-[38px]" src={ContractsLine} alt="" />
                      <h5 className="text-sm font-normal text-center font-kalam">
                        Add your first questionnaire!
                      </h5>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default ViewQuestionnaireList;
