import React, { Fragment } from 'react';
import { Menu, Transition, Disclosure } from '@headlessui/react';

import TrashWhite from '../../assets/Images/Trash-white.svg';
import SelectDownArrow from '../Icons/SelectDownArrow';

function DangerZone({
  clientDetail = {},
  isProject = false,
  handleArchiving = () => {},
  handleDeleting = () => {},
  archiving = false,
  deleting = false,
}) {
  return (
    <Disclosure>
      {({ open }) => (
        <div className='px-6 py-5 mb-[30px] border border-Red400 rounded w-full 6xl:max-w-[1060px] 5xl:max-w-[900px] 2xl:max-w-[630px] max-w-[610px]'>
          <Disclosure.Button
            className={`w-full flex justify-between items-center text-Red400 hover:text-Red600 transition duration-300 ${
              open ? 'pb-3 border-b-[0.5px] border-Neutral200' : ''
            }`}
          >
            <h5 className='text-xs font-semibold tracking-[0.04em] uppercase'>
              Danger Zone
            </h5>

            <SelectDownArrow
              className={`${open ? 'rotate-180' : ''}`}
              color='currentColor'
            />
          </Disclosure.Button>
          <Disclosure.Panel className='pt-8 pb-3 w-full '>
            {!clientDetail?.data?.is_archived ? (
              <div className='flex items-center justify-between mb-8'>
                <div>
                  <h4 className='text-Neutral900 text-sm font-bold tracking-[0.04em] mb-2'>
                    Archive {isProject ? 'Project' : 'Client'}
                  </h4>
                  <p className='text-Neutral800 text-sm font-normal tracking-[0.04em] w-full 6xl:max-w-[600px] max-w-[408px]'>
                    Mark this {isProject ? 'project' : 'client'} as archived. You will still be able to
                    access their information.
                  </p>
                </div>
                <button
                  className='inline-block text-sm font-semibold w-[140px] h-12 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-Red400 focus:border-0 focus:outline focus:outline-Red100 bg-Neutral000 text-Red400 transition duration-300 ease-in-out'
                  onClick={() => handleArchiving(false)}
                  disabled={archiving}
                >
                  {' '}
                  Archive{' '}
                </button>
              </div>
            ) : null}
            {clientDetail?.data?.is_archived ? (
              <div className='flex items-center justify-between mb-8'>
                <div>
                  <h4 className='text-Neutral900 text-sm font-bold tracking-[0.04em] mb-2'>
                    Archived {isProject ? 'Project' : 'Client'}
                  </h4>
                  <p className='text-Neutral800 text-sm font-normal tracking-[0.04em] w-full 6xl:max-w-[600px] max-w-[408px]'>
                    This {isProject ? 'Project' : 'Client'} is Mark as archived. You can still access their
                    information.
                  </p>
                </div>
                <button
                  className='inlinr-block text-sm font-semibold w-[140px] h-12 text-Neutral800 bg-Neutral300 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:bg-[#C2C2CC] transition-all focus:border-0 focus:outline focus:outline-Red100  duration-300 ease-in-out'
                  onClick={() => handleArchiving(true)}
                  disabled={archiving}
                >
                  {' '}
                  Restore{' '}
                </button>
              </div>
            ) : null}
            <div className='flex items-center justify-between'>
              <div>
                <h4 className='text-Neutral900 text-sm font-bold tracking-[0.04em] mb-2'>
                  Delete {isProject ? 'Project' : 'Client'}
                </h4>
                <p className='text-Neutral800 text-sm font-normal tracking-[0.04em] w-full 6xl:max-w-[600px] max-w-[408px]'>
                  Permanently remove {isProject ? 'project' : 'client'} and all their information.
                </p>
              </div>

              <Menu as='div' className='relative inline-block'>
                <div className='flex items-center'>
                  <Menu.Button
                    disabled={deleting}
                    className='text-sm font-semibold w-[140px] h-12 flex items-center justify-center rounded leading-[1.4] hover:bg-Red600 focus:border-0 focus:outline focus:outline-Red100 bg-Red400 text-Neutral100 transition duration-300 ease-in-out'
                  >
                    <span className='relative'>Delete</span>
                    <img className='w-6 h-6 ml-1' src={TrashWhite} alt='' />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items
                    className='origin-top-right absolute -top-[80px] right-[-20px] pt-4 pb-6 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none'
                    onClick={(e) => {
                      // if (e.target === e.currentTarget) {
                      e.stopPropagation();
                      // }
                    }}
                  >
                    <div className=''>
                      <p className='font-semibold text-xs text-Neutral700 pb-2'>
                        Are you sure?
                      </p>
                    </div>
                    <div
                      className='border-0 overflow-hidden'
                      onClick={() => handleDeleting()}
                    >
                      <h3 className='text-white text-xs mt-5 w-full bg-Red400 font-semibold py-4 rounded text-center cursor-pointer hover:bg-Red600 duration-300'>
                        Yes, Delete
                      </h3>
                    </div>
                    <div className='overflow-hidden border-0 !border-transparent divide-0'>
                      <h3 className='text-Neutral800 bg-Neutral200 font-semibold text-xs mt-3 w-full py-4 rounded text-center cursor-pointer hover:bg-Neutral500 duration-300 hover:text-Neutral900'>
                        No, Please Keep It
                      </h3>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
}

export default DangerZone;
