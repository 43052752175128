import { Form, Formik } from "formik";
import React, { useState } from "react";
import Modal from "react-modal";
import { UploadExistingPurposal } from "../../store/actions/proposalAction";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import UploadPDF from "../Buttons/UploadPDF";
import CloseBtn from "../../assets/Images/close-btn.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const validationSchema = Yup.object().shape({
  client: Yup.string().required("Client is required"),
  type: Yup.string(),
  file: Yup.string().when("type", {
    is: "file",
    then: Yup.string().required("Select File").nullable(),
  }),
  url: Yup.string().when("type", {
    is: "url",
    then: Yup.string()
      .matches(
        /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
        "Enter correct url!"
      )
      .required("Url is required")
      .nullable(),
  }),
});

const UploadExistingProposalSpecificClientModal = ({
  modalIsOpen,
  closeModal,
  client,
  getProposalList,
}) => {
  const dispatch = useDispatch();
  const [uploadHere, setUploadHere] = useState(false);
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-12 rounded-[8px]">
          <div>
            <Formik
              initialValues={{
                client: client,
                file: "",
                url: "",
                type: "file",
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                const formData = new FormData();
                formData.append("file", values.file);
                formData.append("client", values.client);
                formData.append("type", values.type);
                formData.append("url", values.url);
                await dispatch(
                  UploadExistingPurposal(
                    formData,
                    false,
                    setSubmitting,
                    closeModal
                  )
                );
              }}
            >
              {({
                touched,
                errors,
                values,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="text-left">
                    <h3
                      className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                      id="modal-title"
                    >
                      UPLOAD EXISTING PROPOSAL
                    </h3>
                    {/* <div className="mb-6">
                      <label className="text-Neutral900 font-bold text-xs">
                        Client
                      </label>
                      <div className="mt-2">
                        <div className="relative">
                          <span className="absolute pointer-events-none z-[999] top-[50%] translate-y-[-50%] right-[16px]">
                            <svg
                              width="11"
                              height="8"
                              viewBox="0 0 11 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                fill="#5A5A66"
                              ></path>
                            </svg>
                          </span>
                          <select
                            id="countries"
                            className={`relative text-Neutral800 bg-Neutral200 font-medium placeholder:text-Neutral500 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded border-transparent hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition ${
                              touched.client && errors.client
                                ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                                : ""
                            } `}
                            name="client"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.client}
                          >
                            <option value="" disabled selected>
                              Select Client...
                            </option>
                            {client.data?.map((item, i) => {
                              return (
                                <option value={item?._id} key={`12${i}`}>
                                  {item?.client_name}
                                </option>
                              );
                            })}
                          </select>
                          {errors.client && touched.client && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors.client}{" "}
                            </p>
                          )}
                        </div>
                      </div>
                    </div> */}
                    <div className="mb-[60px]">
                      <label className="text-Neutral900  font-bold text-xs">
                        Upload Proposal
                      </label>
                      <div className="mt-2">
                        <div
                          className={`w-full ${
                            values.type == "url"
                              ? "opacity-0 w-0 h-0 invisible"
                              : "opacity-100 w-full h-fit"
                          } `}
                        >
                          <UploadPDF
                            title="Upload your proposal PDF."
                            value={values}
                            error={errors}
                            touched={touched}
                            handleBlue={handleBlur}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <div
                          className={`w-full transition duration-300 ${
                            values.type == "file"
                              ? "opacity-0 w-0 h-0 invisible"
                              : "opacity-100 w-full h-fit"
                          } `}
                        >
                          <input
                            className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-base leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition ${
                              touched?.url && errors?.url
                                ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                                : ""
                            }`}
                            type="text"
                            name="url"
                            placeholder="Eg.  drive.google.com/drive..."
                            value={values?.url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.url && touched.url && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors.url}{" "}
                            </p>
                          )}
                        </div>
                      </div>
                      <p
                        className={`text-xs text-Neutral700 transition duration-300 font-medium pt-5 ${
                          !uploadHere
                            ? "opacity-0 w-0 h-0 invisible"
                            : "opacity-100 w-fit h-fit"
                        }`}
                      >
                        Have a PDF proposal already?{" "}
                        <button
                          type="button"
                          className="text-AccentRegular"
                          name="type"
                          onClick={() => {
                            setFieldValue("type", "file");
                            setFieldValue("url", "");
                            setUploadHere(false);
                          }}
                        >
                          {" "}
                          Upload it here.
                        </button>
                      </p>
                      <p
                        className={`text-xs text-Neutral700 transition duration-300 font-medium ${
                          uploadHere
                            ? "opacity-0 w-0 h-0 invisible"
                            : "opacity-100 w-fit h-fit"
                        }`}
                      >
                        Have an existing proposal URL?{" "}
                        <button
                          type="button"
                          className="text-AccentRegular"
                          name="type"
                          onClick={() => {
                            setFieldValue("type", "url");
                            setFieldValue("file", "");
                            setUploadHere(true);
                          }}
                        >
                          Paste it here.
                        </button>
                      </p>
                    </div>
                    <button
                      className="btn button-hover large-primary-btn text-sm text-center inline-block text-Neutral100 bg-AccentRegular font-semibold w-full py-3 rounded drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span className="relative z-10 drop-shadow-Texts">
                          <svg
                            className="animate-spin m-auto h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </span>
                      ) : (
                        <span className="relative drop-shadow-Texts z-10">
                          Save
                        </span>
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => closeModal(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default UploadExistingProposalSpecificClientModal;
