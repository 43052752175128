import React, { useState } from "react";
import Project_Arrow from "../../../assets/Images/Project_Arrow.svg";
import { useEffect } from "react";

function SaveAsTemplateSection({
  clientDetail = {},
  handleSavingTemplate = () => { },
  handleUpdatingTemplate = () => { },
  isLoading = false,
}) {
  const [templateName, setTemplateName] = useState("");

  useEffect(() => {
    setTemplateName(clientDetail?.data?.template_to?.title);
  }, [clientDetail]);

  return (
    <div className="mb-[64px] rounded w-full 6xl:max-w-[1060px] 5xl:max-w-[900px] 2xl:max-w-[800px] max-w-[782px]">
      <h5 className="text-[12px] text-Neutral700 mb-[20px] font-semibold tracking-[0.04em] uppercase">
        {clientDetail?.data?.template_to?._id
          ? `Update Template`
          : `Save As New Template`}
      </h5>

      <div className="flex mb-5 justify-between flex-col md:flex-row">
        <div className="flex flex-col justify-between  mr-[46px]">
          <div className="mb-3 w-[320px]">
            <input
              type="text"
              placeholder="Eg. My Custom Project Template"
              className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </div>
          <button
            className="w-[320px] h-[48px] mb-5 rounded flex justify-center items-center leading-[110%] tracking-[0.04em] text-[12px] text-Neutral000 bg-AccentRegular disabled:bg-AccentLight uppercase font-bold button-hover"
            onClick={() =>
              clientDetail?.data?.template_to
                ? handleUpdatingTemplate(
                  templateName,
                  clientDetail?.data?.template_to?._id
                )
                : handleSavingTemplate(templateName)
            }
            disabled={
              !templateName ||
              templateName === clientDetail?.data?.template_to?.title
            }
          >
            {isLoading ? (
              <span className="relative z-10 drop-shadow-Texts">
                <svg
                  className="animate-spin m-auto h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            ) : clientDetail?.data?.template_to?._id ? (
              `UPDATE TEMPLATE`
            ) : (
              `SAVE AS TEMPLATE`
            )}
          </button>
        </div>
        <div className="flex bg-Neutral200 gap-5 p-5 items-center">
          <img className="w-12 h-12" src={Project_Arrow} alt="" />
          <p className="text-[12px] text-neutral-800">
            This will update existing or create a new reusable template using
            essential data from this project. Find your template on the{" "}
            <a className="text-indigo-500" href="/admin/templates">
              Templates{" "}
            </a>
            screen...
          </p>
        </div>
      </div>
    </div>
  );
}

export default SaveAsTemplateSection;
