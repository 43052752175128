import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import BoltIcon from "./icons/BoltIcon";
import ClockIcon from "./icons/ClockIcon";
import CubeIcon from "./icons/CubeIcon";
import PeopleIcon from "./icons/PeopleIcon";

const StatCard = ({ value, label, Icon, redirectTo = "" }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col justify-between p-2 space-y-2 border rounded-lg cursor-pointer border-Neutral200 bg-Neutral100 hover:bg-Neutral200"
      onClick={() => navigate(redirectTo)}
    >
      <div className="flex items-center justify-between">
        <h4 className="text-[20px] font-bold text-Neutral800">{value}</h4>
        <Icon className="size-5 text-AccentRegular" />
      </div>
      <p className="font-semibold text-[10px] text-Neutral500">{label}</p>
    </div>
  );
};

function Stats() {
  const dashboardCount = useSelector((state) => state.dashboard.dashboardCount);

  return (
    <div className="grid flex-1 grid-cols-2 gap-2">
      <StatCard
        value={dashboardCount.data?.clients}
        label="Clients"
        Icon={PeopleIcon}
        redirectTo="/admin/clients"
      />
      <StatCard
        value={dashboardCount.data?.projects}
        label="Projects"
        Icon={CubeIcon}
        redirectTo="/admin/tasks"
      />
      <StatCard
        value={dashboardCount.data?.incompleteTasks}
        label="Incomplete Tasks"
        Icon={BoltIcon}
        redirectTo="/admin/tasks"
      />
      <StatCard
        value={dashboardCount.data?.total_hours}
        label="Monthly Hours"
        Icon={ClockIcon}
        redirectTo="/admin/tracking"
      />
    </div>
  );
}

export default Stats;
