import React, { Fragment } from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import CloseBtn from "../../assets/Images/Close.svg";
import { getTagColor } from "../../utils/getTagColor";
import SelectDownArrow from "../Icons/SelectDownArrow";

const classNames = {
  control: (state) =>
    `!border-Neutral300 !border-[1.5px] !cursor-pointer max-h-[15vh] overflow-y-auto ${
      state.isFocused ? "!border-b-0" : "hover:!border-Neutral800"
    }`,
  option: (state) =>
    `hover:!bg-Blue100 !cursor-pointer !px-3 !rounded-sm ${
      state.isFocused ? "!bg-Blue100" : ""
    }`,
  placeholder: (state) => "!text-Neutral500 text-sm font-medium",
  IndicatorsContainer: (state) => "!item-start",
  dropdownIndicator: (state) =>
    `!mr-2 ${state.isFocused ? "self-start !pt-4" : ""}`,
  valueContainer: (state) => "!px-4 !py-1.5 !rounded",
  menu: (state) =>
    "!mt-0 !rounded-t-none w-full overflow-x-hidden !border-t-0 !max-h-[25vh]",
  multiValueLabel: ({ data }) =>
    `bg-${data.labelColor} text-${data.labelText} rounded-l-md py-[6px] px-2 uppercase leading-[14px] text-[10px] font-medium`,
  multiValueRemove: ({ data }) =>
    `bg-${data.labelColor} text-${data.labelText} !rounded-r-md !rounded-l-none !pl-0`,
};

const styles = {
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    fontFamily: "Noto Sans",
  }),
  multiValueLabel: (baseStyles, state) => ({
    fontFamily: "Noto Sans",
  }),
  multiValue: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: "none",
    margin: 0,
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    display: "flex",
    maxWidth: "250px",
    flexWrap: "wrap",
    columnGap: "8px",
    rowGap: "6px",
  }),
  multiValueRemove: (baseStyles, state) => ({
    ...baseStyles,
    ":hover": {},
  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    zIndex: 9999, // Ensure the menu is above other elements
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectDownArrow className="mr-4" />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <img src={CloseBtn} className="w-4 h-4" alt="Close Icon" />
    </components.MultiValueRemove>
  );
};

const Option = (props) => {
  const { handleRemove } = props.selectProps.selectProps;
  return (
    <components.Option {...props}>
      <div className="flex items-center group">
        {props.data.color && (
          <div className={`bg-${props.data.color} rounded-full w-2 h-2 mr-2`} />
        )}
        <p className="flex-grow text-sm font-medium uppercase text-Neutral800">
          {props.data.label}
        </p>
        <button
          className="text-transparent group-hover:text-Red500"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleRemove(props.value);
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.23096 5.04419H4.33181H13.1386"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.98234 5.04469V4.02247C5.98234 3.75136 6.09832 3.49135 6.30477 3.29965C6.51122 3.10794 6.79123 3.00024 7.08319 3.00024H9.2849C9.57687 3.00024 9.85687 3.10794 10.0633 3.29965C10.2698 3.49135 10.3858 3.75136 10.3858 4.02247V5.04469M12.037 5.04469V12.2002C12.037 12.4714 11.9211 12.7314 11.7146 12.9231C11.5082 13.1148 11.2281 13.2225 10.9362 13.2225H5.43191C5.13994 13.2225 4.85994 13.1148 4.65349 12.9231C4.44704 12.7314 4.33105 12.4714 4.33105 12.2002V5.04469H12.037Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </components.Option>
  );
};

const MenuList = (props) => {
  return (
    <Fragment>
      <p className="text-xs font-semibold leading-[14px] text-Neutral600 ml-4 my-3">
        + Add Category Tags
      </p>
      {props.children}
    </Fragment>
  );
};

const ValueContainer = (props) => {
  const selectedOptions = props.getValue();

  const modalColor = props.selectProps.selectProps.modalColor;

  const optionsWithColors = selectedOptions.map((option, i) => {
    const { bgColor: optionColor, text: optionText } = getTagColor(
      modalColor,
      i,
      true
    );

    const { bgColor: labelColor, text: labelText } = getTagColor(
      modalColor,
      i,
      true
    );

    return {
      ...option,
      color: optionColor,
      text: optionText,
      labelColor,
      labelText,
      __isNew__: false,
    };
  });

  if (selectedOptions.some((option) => option.__isNew__)) {
    props.setValue(optionsWithColors);
  }

  return (
    <components.ValueContainer {...props}>
      {props.children}
    </components.ValueContainer>
  );
};

const CreateOption = ({ customProps, input, value, handleCreateNewTag }) => {
  const { bgColor, text } = getTagColor(
    customProps.modalColor,
    value.length + 1
  );

  return (
    <p className="py-1 capitalize" onClick={() => handleCreateNewTag(input)}>
      Create{" "}
      <span
        className={`bg-${bgColor} text-${text} py-1 px-2 rounded ml-2 uppercase`}
      >
        {input}
      </span>
    </p>
  );
};

export const Select = ({
  id,
  name,
  value,
  options,
  isMulti,
  handleBlur,
  isSearchable,
  isClearable = true,
  setFieldValue,
  placeholder = "+Add New Category",
  handleCreateNewTag = () => {},
  handleRemove,
  ...customProps
}) => {
  // Handler for creating a new option (tag)
  const onCreateOption = (inputValue) => {
    // Call the custom handleCreateNewTag function with the new tag value
    handleCreateNewTag(inputValue);

    const colorIndex = value.length; // Assuming 'value' holds the current selected options
    const { bgColor: optionColor, text: optionText } = getTagColor(
      customProps.modalColor,
      colorIndex,
      true
    );

    const { bgColor: labelColor, text: labelText } = getTagColor(
      customProps.modalColor,
      colorIndex,
      true
    );

    // Create a new option object with the required properties
    const newOption = {
      label: inputValue,
      value: inputValue,
      color: optionColor, // These properties are used for custom styling
      text: optionText,
      labelColor: labelColor,
      labelText: labelText,
      __isNew__: true, // Optionally mark the option as new if needed
    };

    // Update the selected options
    const newValue = isMulti ? [...value, newOption] : newOption;
    setFieldValue(name, newValue);
    // Optionally, update the select's value state here if needed
    // For example, you might want to add the new tag to the selected options
  };

  return (
    <CreatableSelect
      id={id}
      name={name}
      styles={styles}
      options={options}
      classNames={classNames}
      placeholder={placeholder}
      isMulti={isMulti}
      isSearchable={isSearchable}
      isClearable={isClearable}
      onBlur={handleBlur}
      value={value}
      onChange={(selectedOption) => setFieldValue(name, selectedOption)}
      onCreateOption={onCreateOption}
      formatCreateLabel={(input) => (
        <CreateOption {...{ customProps, input, value, handleCreateNewTag }} />
      )}
      components={{
        DropdownIndicator,
        Option,
        MenuList,
        ValueContainer,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
        MultiValueRemove,
      }}
      selectProps={{ ...customProps, handleRemove }}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      menuPlacement="auto"
    />
  );
};
