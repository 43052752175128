import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";
import { useDispatch } from "react-redux";
import { UpdateCurrencyAction } from "../../store/actions/authAction";
import { currencyList } from "../../assets/json/currencyList";

const Currency = ({ logedinUser }) => {
  const dispatch = useDispatch();
  const currencyref = useRef(null);

  return (
    <div className="w-full 5xl:max-w-[500px] md:max-w-[408px] rounded border border-Neutral300 py-8 px-10">
      <div className="flex justify-between items-center">
        <h5 className="text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase">
          Default Currency
        </h5>
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <Menu.Button
                ref={currencyref}
                className="focus-visible:outline-none relative h-[36px] p-[4px_8px] rounded-[4px]"
              >
                <div
                  className={`flex items-center text-Neutral000 ${
                    open ? "bg-Neutral400" : "bg-Neutral800"
                  } rounded-[14px] px-[12px] py-1`}
                >
                  <span className="whitespace-nowrap text-sm font-medium overflow-hidden mr-1">
                    {logedinUser && logedinUser?.currency?.symbol
                      ? `${logedinUser?.currency?.symbol}`
                      : "$"}
                  </span>
                  {open ? (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 7.50003L6 4.50003L9 7.50003"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 4.49997L6 7.49997L3 4.49997"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right max-h-[241px] z-[999999] absolute top-[42px] right-[4px] rounded-[8px] dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 focus:outline-none overflow-auto cursor-pointer w-[120px] p-2">
                  {currencyList
                    ?.sort((a, b) => a?.short_name?.localeCompare(b?.short_name))
                    .map((item) => {
                      return (
                        <div
                          className={`hover:bg-Neutral200 currency-hover rounded-[4px] py-2 px-2 text-Neutral800 ${
                            (logedinUser?.currency?.short_name || "USD") ===
                            item?.short_name
                              ? "bg-Neutral800 text-white hover:bg-Neutral900 hover:text-white"
                              : ""
                          }`}
                          key={item?.name}
                          onClick={() => {
                            dispatch(UpdateCurrencyAction(item));
                            setTimeout(() => {
                              currencyref?.current?.click();
                            }, 0);
                          }}
                        >
                          <p className="font-normal text-xs">
                            {item?.short_name} - {item?.symbol}
                          </p>
                        </div>
                      );
                    })}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
};

export default Currency;
