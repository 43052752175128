import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import * as Yup from "yup";
import Step1 from "../../components/CreateInvoiceForm/Step1";
import Step2 from "../../components/CreateInvoiceForm/Step2";
import Step3 from "../../components/CreateInvoiceForm/Step3";
import Step4 from "../../components/CreateInvoiceForm/Step4";
import Step5 from "../../components/CreateInvoiceForm/Step5";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import {
  UpdateInvoiceTemplate,
  deleteTemplateAction,
  getTemplateById,
} from "../../store/actions/templateAction";
import { getTagColor } from "../../utils/getTagColor";
import InvoiceLoader from "../../components/ContentLoaders/InvoiceLoader";
import EditInvoiceAndInvoiceTemplateModal from "../../components/Modals/EditInvoiceAndInvoiceTemplateModal";
import moment from "moment";

const allRequiredFields = [
  "invoiceName",
  "categoryTags",
  "clientName",
  "clientEmail",
  "clientCompanyName",
  "clientPhoneNumber",
  "clientAddress",
  "email",
  "fullName",
  "companyName",
  "address",
  "phoneNumber",
  "invoiceNumber",
  "currency",
  "invoiceDate",
  "dueDate",
  "services",
  "paymentMethod",
  "bankDetails",
  "billingType",
];

const validationSchema = Yup.object().shape({
  // step
  step: Yup.number(),

  //template details
  invoiceName: Yup.string(),
  categoryTags: Yup.array(),
  //client details
  clientName: Yup.string(),
  clientEmail: Yup.string(),
  clientCompanyName: Yup.string(),
  clientPhoneNumber: Yup.string(),
  clientAddress: Yup.string(),
  clientLogo: Yup.string(),
  clientVAT: Yup.string(),
  //your details
  email: Yup.string(),
  fullName: Yup.string(),
  companyName: Yup.string(),
  address: Yup.string(),
  phoneNumber: Yup.string(),
  VAT: Yup.string(),
  //invoice details
  invoiceNumber: Yup.string(),
  currency: Yup.string(),
  invoiceDate: Yup.string(),
  dueDate: Yup.string(),
  vatPercentage: Yup.number(),
  is_VAT: Yup.boolean(),
  services: Yup.array().of(
    Yup.object().shape({
      serviceType: Yup.string(),
      serviceName: Yup.string(),
      servicePrice: Yup.number(),
      quantity: Yup.number().nullable(),
    })
  ),
  //payment details
  discount: Yup.number(),
  paymentMethod: Yup.string(),
  bankDetails: Yup.string(),
  paymentLink: Yup.string(),
  billingType: Yup.string(),
  interval: Yup.string(),
  repeats: Yup.number(),
  startDate: Yup.string(),
  status: Yup.string(), //draft, template, sent, paid, overdue, cancelled
});

const getEndDate = (startDate, interval, repeats, userDateFormat) => {
  const date = new Date(startDate);

  repeats = Number(repeats);

  if (interval === "weekly") {
    date.setDate(date.getDate() + repeats * 7);
  }
  if (interval === "bimonthly") {
    const totalMonths = Math.floor(repeats / 2);
    date.setMonth(date.getMonth() + totalMonths);
    if (repeats % 2 === 0) {
      date.setDate(16);
    } else {
      date.setDate(1);
    }
  }
  if (interval === "monthly") {
    date.setMonth(date.getMonth() + repeats);
  }
  if (interval === "yearly") {
    date.setFullYear(date.getFullYear() + repeats);
  }

  return moment(date).format(userDateFormat);
};

const isStepComplete = (values) => {
  if (values.invoiceName !== "") {
    return true;
  }
};

function InvoiceBuilder() {
  const { invoiceId } = useParams();
  const ref = useRef();
  const query = new URLSearchParams(window.location.search);
  const invoiceType = query.get("type");
  const invoiceInterval = query.get("interval");
  const invoiceRepeats = query.get("repeats");
  const invoiceStartDate = query.get("startDate");

  /*  */
  const [initialValues, setInitialValues] = useState({
    title: "",
    invoiceName: "",
    categoryTags: "",
    clientName: "",
    clientEmail: "",
    clientCompanyName: "",
    clientPhoneNumber: "",
    clientAddress: "",
    clientLogo: "",
    clientVAT: "",
    email: "",
    fullName: "",
    companyName: "",
    address: "",
    phoneNumber: "",
    VAT: "",
    invoiceNumber: "0001",
    currency: "USD",
    invoiceDate: moment().format("DD/MM/YYYY"),
    dueDate: moment().add(10, "days").format("DD/MM/YYYY"),
    vatPercentage: 0,
    is_VAT: true,
    services: [],
    discount: 0,
    paymentMethod: "Bank",
    bankDetails: "",
    billingType: "standard",
    interval: 1,
    repeats: 1,
    startDate: "22/09/2021",
    status: "draft",
  });

  const isTemplate = window.location.pathname.includes("invoice-creator");
  const [selectedDueDate, setSelectedDueDate] = useState(
    isTemplate
      ? moment(initialValues.dueDate, "DD/MM/YYYY").toDate()
      : moment(initialValues.dueDate).toDate()
  );
  const [type, setType] = useState(invoiceType || "standard");
  const [interval, setInterval] = useState(invoiceInterval || "weekly");
  const [repeats, setRepeats] = useState(invoiceRepeats || 1);
  const [startDate, setStartDate] = useState(
    invoiceStartDate || new Date().toLocaleDateString()
  );
  const [endDate, setEndDate] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenTemplateModal, setIsOpenTemplateModal] = useState(false);

  const invoice = useSelector((state) => state.invoice.invoice);
  const logedinUser = useSelector((state) => state.auth.user);
  const userDateFormat = logedinUser?.date_format;

  const [showRecurringBannerMenu, setShowRecurringBannerMenu] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const modalColor = "Pink";

  const commonCategoryTags = (categoryTags) => {
    return categoryTags?.map((item, i) => {
      const { bgColor: optionColor, text: optionText } = getTagColor(
        modalColor,
        i,
        true
      );

      const { bgColor: labelColor, text: labelText } = getTagColor(
        modalColor,
        i,
        true
      );

      return {
        value: item?.name,
        label: item?.name,
        color: optionColor,
        text: optionText,
        labelColor,
        labelText,
      };
    });
  };
  const getInvoiceData = async ({ isLoading = false }) => {
    if (invoiceId) {
      setIsLoading(isLoading);
      const invoiceData = await dispatch(
        getTemplateById({ type: "Invoice", id: invoiceId })
      );
      if (invoiceData) {
        setType(invoiceData.billingType);
        setInterval(invoiceData.interval);
        setRepeats(invoiceData.repeats);
        setStartDate(moment(invoiceData.startDate).format("DD/MM/YYYY"));
        if (invoiceData?.endDate) {
          setEndDate(moment(invoiceData?.endDate).format("DD/MM/YYYY"))
        }

        // if (typeof invoiceData.categoryTags === "string") {
        //   setInitialValues({
        //     ...initialValues,
        //     categoryTags: invoiceData.categoryTags?.length
        //       ? commonCategoryTags(invoiceData?.categoryTags)
        //       : [],
        //   });
        // }
        setInitialValues({
          ...initialValues,
          ...invoiceData,
          invoiceName: invoiceData.invoiceName,
          categoryTags: invoiceData.categoryTags?.length
            ? commonCategoryTags(invoiceData?.categoryTags)
            : [],
          services: invoiceData?.services?.length
            ? invoiceData.services.map((service) => ({
                ...service,
                servicePrice: Number(service.servicePrice),
              }))
            : [],
          invoiceDate:
            invoiceData.invoiceDate !== "" && invoiceData.invoiceDate
              ? moment(invoiceData.invoiceDate, "DD/MM/YYYY").format(
                  "DD/MM/YYYY"
                )
              : initialValues?.invoiceDate,
          dueDate:
            invoiceData.dueDate !== "" && invoiceData.dueDate
              ? moment(invoiceData.dueDate, "DD/MM/YYYY").format("DD/MM/YYYY")
              : initialValues?.dueDate,
        });
        setSelectedDueDate(
          invoiceData.dueDate !== "" && invoiceData.dueDate
          ? moment(invoiceData.dueDate, "DD/MM/YYYY").toDate()
          : moment(initialValues.dueDate).toDate()
        );
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getInvoiceData({
      isLoading: true,
    });
  }, [dispatch, invoiceId]);

  useEffect(() => {
    if (invoiceId && invoice) {
      setInitialValues({
        invoiceName: invoice.invoiceName,
        categoryTags: invoice.categoryTags?.length
          ? commonCategoryTags(invoice?.categoryTags)
          : [],
        clientName: invoice.clientName,
        clientEmail: invoice.clientEmail,
        clientCompanyName: invoice.clientCompanyName,
        clientPhoneNumber: invoice.clientPhoneNumber,
        clientAddress: invoice.clientAddress,
        clientLogo: invoice.clientLogo,
        clientVAT: invoice.clientVAT,
        email: invoice.email,
        fullName: invoice.fullName,
        companyName: invoice.companyName,
        address: invoice.address,
        phoneNumber: invoice.phoneNumber,
        VAT: invoice.VAT,
        invoiceNumber: invoice.invoiceNumber,
        currency: invoice.currency,
        invoiceDate:
          invoice.invoiceDate !== "" && invoice.invoiceDate
            ? moment(invoice.invoiceDate, "DD/MM/YYYY").format("DD/MM/YYYY")
            : initialValues?.invoiceDate,
        dueDate:
          invoice.dueDate !== "" && invoice.dueDate
            ? moment(invoice.dueDate, "DD/MM/YYYY").format("DD/MM/YYYY")
            : initialValues?.dueDate,
        vatPercentage: invoice.vatPercentage,
        is_VAT: invoice.is_VAT,
        services: invoice.services,
        discount: invoice.discount,
        paymentMethod: invoice.paymentMethod,
        bankDetails: invoice.bankDetails,
        billingType: invoice.billingType,
        interval: invoice.interval,
        repeats: invoice.repeats,
        startDate: invoice.startDate,
        endDate: invoice.endDate,
        status: invoice.status,
      });
      setSelectedDueDate(
        invoice.dueDate !== "" && invoice.dueDate
        ? moment(invoice.dueDate, "DD/MM/YYYY").toDate()
        : moment(initialValues.dueDate).toDate()
      );
    }
  }, [dispatch, invoice]);

  const getDiscount = (subtotal, discount) => {
    if (discount === 0) return discount;
    return (subtotal * discount) / 100;
  };

  const getVat = (subtotal, vatPercentage, discount) => {
    if (parseInt(vatPercentage) === 0) return 0;

    const discountedAmount = subtotal - getDiscount(subtotal, discount);
    return (discountedAmount * parseInt(vatPercentage)) / 100;
  };

  const getTotalDue = (invoice, discount, vatPercentage) => {
    const subtotal = invoice?.services?.reduce((acc, service) => {
      if (service?.isFixedService) {
        return acc + service?.servicePrice;
      }
      return acc + (service?.quantity || 0) * (service?.servicePrice || 0);
    }, 0);
    return (
      subtotal -
      getDiscount(subtotal, discount) +
      getVat(subtotal, vatPercentage, discount)
    );
  };

  const handleSubmitInvoice = (values, { setSubmitting }) => {
    delete values.step;
    if (invoiceId) {
      setSubmitting(true);

      let tempData = {
        ...values,
        categoryTags: values.categoryTags.map((item) => {
          return {
            name: item.value,
            color: {
              color: item.color,
              text: item.text,
              labelColor: item.labelColor,
              labelText: item.labelText,
            },
          };
        }),
      };
      if (currentStep === 4) {
        const totalDue = getTotalDue(
          values,
          values.discount,
          values.vatPercentage
        );
        tempData = {
          ...tempData,
          totalDue,
        };
      }
      if (currentStep === 5 && initialValues.discount !== values.discount) {
        const totalDue = getTotalDue(
          values,
          values.discount,
          values.vatPercentage
        );
        tempData = {
          ...tempData,
          totalDue,
        };
      }

      const checkRequiredFields = allRequiredFields.every((field) => {
        if (tempData[field] === "") {
          return false;
        }
        if (field === "services") {
          if (tempData[field].length === 0) {
            return false;
          }
        }
        return true;
      });

      if (checkRequiredFields) {
        // tempData.status = "unsent";
      }

      dispatch(
        UpdateInvoiceTemplate(
          {
            invoiceId: invoiceId,
            content: tempData,
            type: "Invoice",
            title: values.invoiceName,
          },
          setSubmitting,
          navigate,
          currentStep,
          setCurrentStep,
          getInvoiceData
        )
      );
      return;
    }
  };

  const handleSetStep = (step) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowRecurringBannerMenu(false);
        setShowDeleteConfirmationModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <AdminNavbar
        title="Create Invoice Template"
        subtitle="Build and send to your potential client to complete online."
      />
      {type === "recurring" && (
        <div className="px-8 py-4">
          <div className="flex items-center gap-2 w-full rounded-full px-8 py-4 bg-indigo-100">
            <span className="text-sm font-medium text-gray-700 flex items-center gap-2 w-full">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.875 7.12402V10.874H15.125"
                  stroke="#5281E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.125 17.124V13.374H8.875"
                  stroke="#5281E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.69375 10.2485C7.01073 9.35276 7.54946 8.5519 8.25967 7.92066C8.96988 7.28942 9.82842 6.84837 10.7552 6.63868C11.6819 6.42899 12.6467 6.45748 13.5595 6.7215C14.4723 6.98553 15.3033 7.47647 15.975 8.14852L18.875 10.8735M5.125 13.3735L8.025 16.0985C8.69672 16.7706 9.52773 17.2615 10.4405 17.5255C11.3533 17.7896 12.3181 17.8181 13.2448 17.6084C14.1716 17.3987 15.0301 16.9576 15.7403 16.3264C16.4505 15.6951 16.9893 14.8943 17.3062 13.9985"
                  stroke="#5281E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>
								Recurs <span className="font-semibold">{interval}</span>,
									ends{" "}
								<span className="font-semibold">
									{interval === 'indefinitely' ? endDate : getEndDate(startDate, interval, repeats, userDateFormat)}
								</span>
							</span>
            </span>
            {/* <button
              className="cursor-pointer"
              onClick={() => {
                setShowRecurringBannerMenu(!showRecurringBannerMenu);
              }}
            >
              <svg
                width="2"
                height="10"
                viewBox="0 0 2 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="1" cy="1" r="1" fill="grey" />
                <circle cx="1" cy="5" r="1" fill="grey" />
                <circle cx="1" cy="9" r="1" fill="grey" />
              </svg>
            </button>
            <div className="flex items-center gap-2 relative" ref={ref}>
              {showRecurringBannerMenu && (
                <div className="absolute top-2 right-1  w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <button
                      className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      onClick={() => {
                        setShowRecurringBannerMenu(false);
                        setIsOpenTemplateModal(true);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                      onClick={() => {
                        setShowRecurringBannerMenu(false);
                        setShowDeleteConfirmationModal(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
              {showDeleteConfirmationModal && (
                <div className="focus:outline-none min-w-[384px] absolute right-[-20px] top-[20px] w-36 origin-top-right divide-y divide-Neutral200 bg-white ring-1 ring-black ring-opacity-5 drop-shadow-Timetracking">
                  <div
                    className="pt-6 pb-8 px-8"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <h5 className="text-Neutral700 text-xs font-semibold pb-2 border-b border-Neutral200">
                      Are you sure?
                    </h5>
                    <button
                      className="text-Neutral000 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Red400 hover:bg-Red600 rounded-[4px] mt-8"
                      role="menuitem"
                      onClick={() => {
                        dispatch(
                          deleteTemplateAction(
                            invoiceId,
                            "Invoice",
                            false,
                            navigate
                          )
                        );
                      }}
                    >
                      Yes, Delete Invoice
                    </button>
                    <button
                      className="text-Neutral800 hover:text-Neutral900 w-full pl-2 pr-4 py-4 text-center text-sm cursor-pointer font-bold transition duration-300 bg-Neutral300 hover:bg-Neutral400 rounded-[4px] mt-5"
                      role="menuitem"
                      onClick={() => {
                        setShowDeleteConfirmationModal(false);
                      }}
                    >
                      No, I still want this Invoice
                    </button>
                  </div>
                </div>
              )}
            </div> */}
          </div>
        </div>
      )}
      {isLoading ? (
        <InvoiceLoader />
      ) : (
        <Formik
          initialValues={{
            ...initialValues,
            step: currentStep,
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmitInvoice}
        >
          {({
            values,
            isSubmitting,
            setFieldValue,
            handleSubmit,
            setSubmitting,
          }) => (
            <form
              className="flex flex-col items-start justify-start w-full px-8 py-4 gap-4 overflow-y-auto h-[60vh] md:overflow-y-hidden md:h-full"
              onSubmit={handleSubmit}
            >
              <Step1
                active={currentStep === 1}
                complete={values.invoiceName !== ""}
                setStep={handleSetStep}
                values={values}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
              />
              <Step2
                active={currentStep === 2}
                complete={
                  values.clientName !== "" &&
                  values.clientEmail !== "" &&
                  values.clientCompanyName !== "" &&
                  values.clientPhoneNumber !== "" &&
                  values.clientAddress !== ""
                }
                onPrevious={() => setCurrentStep(1)}
                setStep={handleSetStep}
                isSubmitting={isSubmitting}
              />
              <Step3
                active={currentStep === 3}
                complete={
                  values.email !== "" &&
                  values.fullName !== "" &&
                  values.companyName !== "" &&
                  values.address !== ""
                }
                onPrevious={() => setCurrentStep(2)}
                setStep={handleSetStep}
                isSubmitting={isSubmitting}
              />
              <Step4
                active={currentStep === 4}
                setFieldValue={setFieldValue}
                values={values}
                onPrevious={() => setCurrentStep(3)}
                setStep={handleSetStep}
                complete={
                  values.invoiceNumber !== "" &&
                  values.currency !== "" &&
                  values.invoiceDate !== "" &&
                  values.dueDate !== "" &&
                  values.vatPercentage !== "" &&
                  values.services?.length > 0
                }
                isSubmitting={isSubmitting}
                selectedDueDate={selectedDueDate}
                setSelectedDueDate={setSelectedDueDate}
              />

              <Step5
                active={currentStep === 5}
                complete={isStepComplete(values)}
                onPrevious={() => setCurrentStep(4)}
                setStep={handleSetStep}
                values={values}
                isSubmitting={isSubmitting}
              />
            </form>
          )}
        </Formik>
      )}
      <EditInvoiceAndInvoiceTemplateModal
        isOpen={isOpenTemplateModal}
        setIsOpen={setIsOpenTemplateModal}
        type="Invoice"
        editData={initialValues}
        getInvoiceData={getInvoiceData}
      />
    </>
  );
}

export default InvoiceBuilder;
