import { Menu, Tab, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getMyTasksAction,
  getUpcomingTaskCountAction,
} from "../../store/actions/dashboard-v2";
import CreateClientModal from "../Modals/CreateClientModal";
import NewProjectModal from "../Modals/ProjectModal/NewProjectModal";
import ChartArea from "./ChartArea";
import InfographicCard from "./InfographicCard";
import BoltIcon from "./icons/BoltIcon";
import ChevronDown from "./icons/ChevronDown";
import UpcomingTasksOverviewModal from "./modals/UpcomingTasksOverviewModal";
import YourTasksOverviewModal from "./modals/YourTasksOverviewModal";

function OverviewContentForTeamMembers() {
  const dispatch = useDispatch();
  const dashboardCount = useSelector((state) => state.dashboard.dashboardCount);
  const tasksCount = useSelector((state) => state.dashboard.taskCount);
  const yourTasks = useSelector((state) => state.dashboard.myTasks);
  const upcomingTaskCount = useSelector(
    (state) => state.dashboard.upcomingTaskCount
  );

  // Loading states
  const [loading, setLoading] = useState(false);

  // Filter states
  const [upcomingTaskFilter, setUpcomingTaskFilter] = useState("month");
  const [yourTasksMonthFilter, setYourTasksMonthFilter] = useState("all");
  const [priorityFilter, setPriorityFilter] = useState("urgent");

  // Modal visibility states
  const [isYourTasksOverviewModalOpen, setIsYourTasksOverviewModalOpen] =
    useState(false);
  const [
    isUpcomingTasksOverviewModalOpen,
    setIsUpcomingTasksOverviewModalOpen,
  ] = useState(false);
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getUpcomingTaskCountAction(upcomingTaskFilter));
  }, [dispatch, upcomingTaskFilter]);

  useEffect(() => {
    dispatch(getMyTasksAction("all", yourTasksMonthFilter, setLoading));
  }, [dispatch, yourTasksMonthFilter]);

  return (
    <Tab.Panel className="flex flex-col">
      <section className="flex flex-col items-start gap-3 mb-16 md:mb-0 lg:flex-row">
        <div className="flex flex-col flex-1 gap-3 3xs:flex-row">
          <div className="flex flex-col flex-1 gap-5">
            <div
              className="flex-1 px-4 py-5 space-y-5 rounded-lg bg-Neutral100 hover:bg-Neutral200"
              onClick={() => setIsYourTasksOverviewModalOpen(true)}
            >
              <div className="flex items-center justify-between">
                <h3 className="text-base text-Neutral900">Your Tasks</h3>
                <span className="grid p-1 rounded-full bg-Blue400 place-items-center text-Neutral000">
                  <BoltIcon className="size-5" />
                </span>
              </div>
              <div className="flex items-end justify-between">
                <h3 className="text-3xl font-light tracking-tighter md:text-6xl">
                  {tasksCount.data?.yourTasks}
                </h3>
                <span className="px-3 py-1 bg-green-200 rounded-xl text-Neutral900 text-[10px] tracking-wide font-semibold">
                  +{tasksCount.data?.thisMonthYourTasks}
                </span>
              </div>
            </div>
            <div
              className="flex-1 px-4 py-5 space-y-5 rounded-lg bg-Neutral100 hover:bg-Neutral200"
              onClick={() => {
                setPriorityFilter("urgent");
                setIsUpcomingTasksOverviewModalOpen(true);
              }}
            >
              <div className="flex items-center justify-between">
                <h3 className="text-base text-Neutral900">Urgent</h3>
                <span className="grid p-1 rounded-full bg-Blue400 place-items-center text-Neutral000">
                  <BoltIcon className="size-5" />
                </span>
              </div>
              <div className="flex items-end justify-between">
                <h3 className="text-3xl font-light tracking-tighter md:text-6xl">
                  {tasksCount.data?.urgentTasks}
                </h3>
                <span className="px-3 py-1 bg-green-200 rounded-xl text-Neutral900 text-[10px] tracking-wide font-semibold">
                  +{tasksCount.data?.thisMonthUrgentTasks}
                </span>
              </div>
            </div>
            <div
              className="flex-1 px-4 py-5 space-y-5 rounded-lg bg-Neutral100 hover:bg-Neutral200"
              onClick={() => {
                setPriorityFilter("upcoming");
                setIsUpcomingTasksOverviewModalOpen(true);
              }}
            >
              <div className="flex items-center justify-between lg:gap-7">
                <h3 className="text-base text-Neutral900">Upcoming</h3>
                <Menu as="div" className="relative">
                  <Menu.Button
                    className="inline-flex items-center gap-1 py-1 pl-2 pr-1 text-xs font-medium border border-Blue200 rounded-3xl text-Blue800"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {upcomingTaskFilter === "month"
                      ? "Monthly"
                      : upcomingTaskFilter === "week"
                      ? "Weekly"
                      : ""}
                    <ChevronDown className="text-Blue800 size-4" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      as="div"
                      className="absolute z-20 w-full p-1 mt-1 border divide-y rounded-md shadow-xl bg-Neutral000 border-Neutral300 divide-Blue100"
                    >
                      <Menu.Item
                        as="button"
                        type="button"
                        className="w-full p-1 text-sm text-left rounded text-Neutral800 hover:bg-Blue100"
                        onClick={(e) => {
                          e.stopPropagation();
                          setUpcomingTaskFilter("week");
                        }}
                      >
                        Weekly
                      </Menu.Item>
                      <Menu.Item
                        as="button"
                        type="button"
                        className="w-full p-1 text-sm text-left rounded text-Neutral800 hover:bg-Blue100"
                        onClick={(e) => {
                          e.stopPropagation();
                          setUpcomingTaskFilter("month");
                        }}
                      >
                        Monthly
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="flex items-end justify-between">
                <h3 className="text-3xl font-light tracking-tighter md:text-6xl">
                  {upcomingTaskCount.data?.upcomingTasks}
                </h3>
                <span className="px-3 py-1 bg-green-200 rounded-xl text-Neutral900 text-[10px] tracking-wide font-semibold">
                  +{upcomingTaskCount.data?.thisDayUpcomingTasks}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-1 gap-3">
            <InfographicCard
              title="Clients"
              totalCount={dashboardCount.data?.clients}
              thisMonthCount={`+${dashboardCount.data?.thisMonthClients}`}
              content={() => (
                <p className="mb-auto text-sm text-Neutral700">
                  You added{" "}
                  <span className="font-semibold text-Neutral900">
                    {dashboardCount.data?.thisMonthClients}
                  </span>{" "}
                  new clients this month.
                </p>
              )}
              isDisabled
            />

            <InfographicCard
              title="Projects"
              totalCount={dashboardCount.data?.projects}
              thisMonthCount={`+${dashboardCount.data?.thisMonthProjects}`}
              content={() => (
                <p className="mb-auto text-sm text-Neutral700">
                  You added{" "}
                  <span className="font-semibold text-Neutral900">
                    {dashboardCount.data?.thisMonthProjects}
                  </span>{" "}
                  new projects for{" "}
                  <span className="font-semibold text-Neutral900">
                    {dashboardCount.data?.thisMonthClients} client(s)
                  </span>{" "}
                  this month and assigned it to{" "}
                  <span className="font-semibold text-Neutral900">
                    {dashboardCount.data?.thisMonthTeams} team members
                  </span>
                </p>
              )}
              onClick={() => setIsNewProjectModalOpen(true)}
            />
          </div>
        </div>

        <div className="flex flex-1 w-full">
          <ChartArea isAdmin={false} />
        </div>
      </section>

      <YourTasksOverviewModal
        isOpen={isYourTasksOverviewModalOpen}
        setOpen={setIsYourTasksOverviewModalOpen}
        data={yourTasks.data}
        filter={yourTasksMonthFilter}
        setFilter={setYourTasksMonthFilter}
        loading={loading}
      />
      <UpcomingTasksOverviewModal
        isOpen={isUpcomingTasksOverviewModalOpen}
        setOpen={setIsUpcomingTasksOverviewModalOpen}
        title={
          priorityFilter === "urgent" ? "Urgent Overview" : "Upcoming Overview"
        }
        priority={priorityFilter}
      />
      <NewProjectModal
        isOpen={isNewProjectModalOpen}
        setIsOpen={setIsNewProjectModalOpen}
      />
    </Tab.Panel>
  );
}

export default OverviewContentForTeamMembers;
