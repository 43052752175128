import React from "react";

const SelectDownArrow = ({
  color = "#5A5A66",
  width = 11,
  height = 8,
  className = "",
  rounded = false,
}) => {
  if (rounded) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.324856 1.93821C-0.206125 1.28469 0.258936 0.307617 1.10097 0.307617H8.89903C9.74106 0.307617 10.2061 1.2847 9.67514 1.93821L5.77611 6.73702C5.37592 7.22956 4.62408 7.22956 4.22389 6.73702L0.324856 1.93821Z"
          fill={color}
        />
      </svg>
    );
  }
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
        fill={color}
      />
    </svg>
  );
};

export default SelectDownArrow;
