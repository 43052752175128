import React, { useState } from "react";
import SummaryPerson from "../../../assets/Images/meeting-schedule.svg";
import copy from "copy-to-clipboard";

const Summary = ({ meetingUrl, isEditMode }) => {
  const [copyStatus, setCopyStatus] = useState("Copy Link");

  const handleCopyToClipboard = () => {
    // Construct the full URL
    const fullUrl = `${window.location.origin}/schedule-meeting${meetingUrl}`;

    // Use the copy-to-clipboard library to copy the text
    const success = copy(fullUrl);

    if (success) {
      console.log("Meeting URL copied to clipboard");
      setCopyStatus("Copied");
    } else {
      console.error("Failed to copy the meeting URL");
    }
    setCopyStatus("Copied");
    setTimeout(() => {
      setCopyStatus("Copy Link");
    }, 1000);
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-lg max-w-[450px] text-left">
      <h3 className="text-xs text-Neutral700 font-semibold mb-10 border-b border-Neutral200 pb-2">
        SCHEDULE {!isEditMode ? "CREATED" : "UPDATED"}
      </h3>
      <img
        src={SummaryPerson}
        alt="Schedule created illustration"
        className="mx-auto mb-4"
      />
      <p className="text-sm text-Neutral800 mb-10 text-center">
        Schedule ready! Send link to client for them to book a slot with you.
      </p>
      <div className="border-[1.5px] border-neutral-300 rounded p-3 mb-4 flex justify-between items-center">
        <p className="text-sm text-Neutral800 break-all">{meetingUrl}</p>
        <button
          onClick={handleCopyToClipboard}
          className="bg-AccentRegular text-Neutral100 p-2 rounded transition duration-300 text-sm ml-4 font-bold"
        >
          {copyStatus}
        </button>
      </div>
    </div>
  );
};

export default Summary;
