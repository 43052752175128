import React from "react";
import Modal from "react-modal";
import CloseBtn from "../../assets/Images/close-btn.svg";

function NewFolderModal({
  isOpen,
  onRequestClose,
  customStyles,
  isNew,
  addNewFolder,
  updateFolder,
  dispatch,
  folder,
  closeImage,
  newFolderType,
  newFolder,
  setNewFolder,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel={isNew ? "New Folder" : "Edit Folder"}
    >
      <div className="relative bg-Neutral000 rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-Neutral000 px-6 xs:px-12 pt-8 pb-12  rounded-[8px]">
          <div>
            <div className="text-left">
              <div>
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-6 uppercase"
                  id="New Folder"
                >
                  {isNew ? `New ${newFolderType} Folder` : "Edit Folder"}
                </h3>
                <label className="text-Neutral900 font-bold text-xs mb-4">
                  Name
                </label>
                <input
                  type="text"
                  name="folder"
                  className="mt-2 w-full px-4 py-2 rounded border border-Neutral300 focus:outline-none focus:border-AccentRegular transition"
                  placeholder="Eg. Brand Guide"
                  autoComplete="off"
                  value={newFolder}
                  onChange={(e) => setNewFolder(e.target.value)}
                />

                <button
                  onClick={async () => {
                    if (isNew) {
                      console.log("add");
                      addNewFolder(newFolder, newFolderType);
                    } else {
                      const updatedFolder = {
                        ...folder,
                        name: newFolder,
                        updatedAt: new Date(),
                      };

                      await dispatch(updateFolder(updatedFolder));
                    }

                    setNewFolder("");
                    onRequestClose(false);
                  }}
                  className="text-sm bg-AccentRegular text-Neutral100 h-12 flex items-center justify-center leading-[1.2] mt-6 rounded font-bold w-full hover:border-AccentRegular focus:border-0 focus:outline focus:outline-4 focus:outline-AccentMediumLight transition duration-300 ease-in-out hover:bg-AccentMediumDark"
                >
                  {isNew ? "Add" : "Update"} Folder
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => onRequestClose(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default NewFolderModal;
