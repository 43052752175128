function DashboardButton({
  title = "assign",
  onClick = () => {},
  disabled = false,
  variant = "primary",
  customStyles = "",
  limitWidth = true,
}) {
  const primaryStyles =
    "text-Neutral100 bg-AccentRegular hover:bg-AccentMediumDark";
  const secondaryStyles =
    "text-AccentRegular bg-Neutral200 hover:bg-Neutral300 focus:text-white";

  const buttonStyles = variant === "primary" ? primaryStyles : secondaryStyles;
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${buttonStyles} button-hover ${
        limitWidth ? "max-w-[130px] w-[100px] md:w-full" : "inline-block"
      } min-w-[6rem] h-10 sm:min-w-[9rem] sm:h-11 large-primary-btn text-xs sm:text-sm text-center flex items-center justify-center relative font-semibold py-2 px-[15px] rounded focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out ${customStyles}`}
    >
      {title}
    </button>
  );
}

export default DashboardButton;
