import { Field, FieldArray, Form, Formik } from "formik";
import { useRef, useState } from "react";
import * as Yup from "yup";

import AddIcon from "../../Icons/AddIcon";
import TimePicker from "../../TimePicker";
import Dropdown from "./Dropdown";

// Validation Schema
const StepTwoValidationSchema = Yup.object().shape({
  time_slot: Yup.string().required("Time slot is required"),
  availability: Yup.array().of(
    Yup.object().shape({
      day: Yup.string().required("Day is required"),
      time: Yup.string().required("Time is required"),
    })
  ),
  unavailability: Yup.array().of(
    Yup.object().shape({
      start_date: Yup.date().required("Start date is required"),
      start_time: Yup.string().required("Start time is required"),
      end_date: Yup.date()
        .required("End date is required")
        .when(
          "start_date",
          (start_date, schema) =>
            start_date &&
            schema.min(start_date, "End date cannot be before start date")
        ),
      end_time: Yup.string()
        .required("End time is required")
        .when(
          ["start_date", "end_date", "start_time"],
          (start_date, end_date, start_time, schema) =>
            end_date && start_date === end_date
              ? schema.min(
                  start_time,
                  "End time cannot be before start time on the same day"
                )
              : schema
        ),
    })
  ),
  is_unavailable: Yup.boolean(),
});

// Dummy options based on Figma images
const daysOptions = [
  { title: "Monday - Sunday", color: "#FF0000" },
  { title: "Every Weekday", color: "#FF0000" },
  { title: "Weekends Only", color: "#FF0000" },
  { title: "Monday", color: "#FF0000" },
  { title: "Tuesday", color: "#FF0000" },
  { title: "Wednesday", color: "#FF0000" },
  { title: "Thursday", color: "#FF0000" },
  { title: "Friday", color: "#FF0000" },
];

let timesOptions = [
  { title: "All Day (08:00-18:00)", color: "#0000FF" },
  { title: "Morning (08:00-12:00)", color: "#0000FF" },
  { title: "Afternoon (12:00-18:00)", color: "#0000FF" },
  { title: "Night (18:00-22:00)", color: "#0000FF" },
];

const timeSlotOptions = [
  { title: "15 Minutes", value: 15, color: "#0000FF" },
  { title: "30 Minutes", value: 30, color: "#0000FF" },
  { title: "60 Minutes", value: 60, color: "#0000FF" },
  { title: "90 Minutes", value: 90, color: "#0000FF" },
  { title: "120 Minutes", value: 120, color: "#0000FF" },
];

const StepTwoForm = ({ onSubmit, formData, setFormData, isEditMode }) => {
  // The initialValues now need to be set from formData for Step Two
  const initialValues = {
    time_slot: formData.time_slot || timeSlotOptions[0].title, // default to the first option
    availability:
      formData.availability.length > 0
        ? formData.availability
        : [{ day: "", time: "" }],
    is_unavailable: formData.unavailability.length > 0 ? true : false,
    unavailability: formData.unavailability || [
      { start_date: "", end_date: "", start_time: "", end_time: "" },
    ],
    timezone:
      formData.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  // Function to collect all selected day and time values to determine disabled options
  const collectSelectedDays = (availability) => {
    return availability.map((a) => a.day);
  };

  const selectedDays = collectSelectedDays(formData.availability);

  // State to track custom time input visibility for each availability row
  const [customTimeToggles, setCustomTimeToggles] = useState({});

  // State to hold dynamic times options including custom ones
  const [dynamicTimesOptions, setDynamicTimesOptions] = useState(timesOptions);

  // Function to handle saving custom time
  const handleAddCustomTime = (index, fromTime, toTime, setFieldValue) => {
    // Create a label for the custom time
    const customTimeLabel = `Custom (${fromTime}-${toTime})`;
    // Add the new custom time to the dynamicTimesOptions state
    setDynamicTimesOptions((prevOptions) => [
      ...prevOptions,
      { title: customTimeLabel, color: "#0000FF" }, // Change the color as required
    ]);
    // Set the custom time as the selected time for the current availability entry
    setFieldValue(`availability[${index}].time`, customTimeLabel);
    // Close the custom time input
    handleCustomTimeToggle(index, false);
  };

  // Function to handle the custom time toggle
  const handleCustomTimeToggle = (index, isOpen) => {
    setCustomTimeToggles((prev) => ({ ...prev, [index]: isOpen }));
  };

  const parentRef = useRef(null);

  const formatDateForDisplay = (dateString) => {
    console.log(dateString);
    // Convert the ISO date string to Date object and format it to dd/MM/yyyy
    return dateString?.split("T")[0];
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={StepTwoValidationSchema}
      onSubmit={(values, errors, actions) => {
        setFormData((prevData) => ({ ...prevData, ...values })); // Update the parent state with new values
        onSubmit(); // Proceed to next step
        actions.setSubmitting(false);
      }}
    >
      {({ values, setFieldValue, handleChange, errors, touched }) => (
        <Form className="bg-white p-8 rounded-lg shadow-lg w-[95vw] md:w-[500px]">
          <div className="max-h-[70dvh] overflow-y-auto scrollbar-hide">
            <div className="flex justify-between ">
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-1"
                id="modal-title"
              >
                {!isEditMode ? "CREATE" : "EDIT"} MEETING SCHEDULE
              </h3>
              <h3 className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 mb-8">
                2/3
              </h3>
            </div>

            <div ref={parentRef}>
              <div className="flex justify-between">
                <div className="flex items-center justify-between w-full">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-xs font-bold text-Neutral900"
                  >
                    Time Slot
                  </label>
                  <span className="text-xs italic text-Neutral700">
                    per meeting
                  </span>
                </div>
              </div>

              <Dropdown
                options={timeSlotOptions}
                value={values.time_slot}
                onChange={(selectedTitle) => {
                  // Find the corresponding time slot object
                  const selectedTimeSlot = timeSlotOptions.find(
                    (option) => option.title === selectedTitle
                  );
                  setFieldValue("time_slot", selectedTimeSlot.title);
                }}
                placeholder="Select time slot"
                fieldName="time_slot"
                parentRef={parentRef}
              />
            </div>

            <FieldArray
              name="availability"
              render={({ push }) => (
                <div>
                  <label
                    htmlFor="title"
                    className="block pt-4 mb-2 text-xs font-bold text-Neutral900"
                  >
                    Availability
                  </label>
                  <div
                    ref={parentRef}
                    className="max-h-[250px] overflow-auto scrollbar-w-4 relative"
                  >
                    {values.availability.map((availability, index) => (
                      <div key={index} className="flex gap-2 mb-4">
                        {/* Dropdown component for day field */}
                        <div className="flex flex-col w-1/2">
                          <Dropdown
                            options={daysOptions}
                            parentRef={parentRef}
                            value={availability.day}
                            onChange={(selected) =>
                              setFieldValue(
                                `availability[${index}].day`,
                                selected
                              )
                            }
                            placeholder="Select day"
                            fieldName={`availability[${index}].day`}
                            selectedDays={selectedDays}
                            category="day"
                            index={index}
                            error={
                              touched.availability &&
                              touched.availability[index]?.day &&
                              errors.availability &&
                              errors.availability[index]?.day
                            }
                          />
                          {touched.availability &&
                            touched.availability[index]?.day &&
                            errors.availability &&
                            errors.availability[index]?.day && (
                              <div className="text-xs text-red-500">
                                {errors.availability[index].day}
                              </div>
                            )}
                        </div>
                        <div className="flex flex-col w-1/2">
                          <Dropdown
                            options={dynamicTimesOptions}
                            parentRef={parentRef}
                            value={availability.time}
                            onChange={(selected) =>
                              setFieldValue(
                                `availability[${index}].time`,
                                selected
                              )
                            }
                            placeholder="Select time"
                            fieldName={`availability[${index}].time`}
                            isCustomTimeOpen={customTimeToggles[index]}
                            handleCustomTimeToggle={(isOpen) =>
                              handleCustomTimeToggle(index, isOpen)
                            }
                            handleAddCustomTime={(fromTime, toTime) =>
                              handleAddCustomTime(
                                index,
                                fromTime,
                                toTime,
                                setFieldValue
                              )
                            }
                            category="time"
                            index={index}
                            error={
                              touched.availability &&
                              touched.availability[index]?.time &&
                              errors.availability &&
                              errors.availability[index]?.time
                            }
                            placement="bottom-end"
                          />
                          {touched.availability &&
                            touched.availability[index]?.time &&
                            errors.availability &&
                            errors.availability[index]?.time && (
                              <div className="text-xs text-red-500">
                                {errors.availability[index].time}
                              </div>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex items-center justify-between py-2 border-b border-Neutral200">
                    <button
                      type="button"
                      className="flex items-center text-xs font-semibold transition duration-300 rounded text-AccentRegular"
                      onClick={() => push({ day: "", time: "" })}
                    >
                      <AddIcon className="mr-2" width={12} height={12} />
                      <span>ADD</span>
                    </button>
                    {values.availability.length > 1 && (
                      <button
                        type="button"
                        className="text-xs font-semibold text-red-400 uppercase transition duration-300"
                        onClick={() => {
                          // Reset all but the first availability
                          const firstAvailability = values.availability[0];
                          setFieldValue("availability", [firstAvailability]);
                        }}
                      >
                        Reset
                      </button>
                    )}
                  </div>
                </div>
              )}
            />

            <div className="flex items-center pt-5 pb-3">
              <Field
                type="checkbox"
                name="is_unavailable"
                className="mr-2"
                onChange={handleChange}
              />
              <label
                htmlFor="is_unavailable"
                className="text-xs text-Neutral700"
              >
                Add unavailable dates
              </label>
            </div>

            <FieldArray
              name="unavailability"
              render={({ insert }) =>
                values.is_unavailable && (
                  <div className="my-1">
                    <div className="">
                      {values?.unavailability?.map((slot, index) => (
                        <div key={index} className="flex flex-col gap-3 mb-4">
                          <div className="flex items-center gap-4">
                            <span className="text-xs text-center text-Green700 bg-green-100 rounded-2xl min-w-14 px-3 py-[6px]">
                              Start
                            </span>
                            <div>
                              <Field
                                type="date"
                                name={`unavailability.${index}.start_date`}
                                value={formatDateForDisplay(
                                  values.unavailability[index].start_date
                                )}
                                className={`border ${
                                  errors.unavailability?.[index]?.start_date &&
                                  touched.unavailability?.[index]?.start_date
                                    ? "border-red-500"
                                    : "border-Neutral300"
                                } rounded p-2 text-Neutral800 text-sm font-medium w-full`}
                              />
                              {errors.unavailability?.[index]?.start_date &&
                                touched.unavailability?.[index]?.start_date && (
                                  <div className="mt-1 text-xs text-red-500">
                                    {errors.unavailability[index].start_date}
                                  </div>
                                )}
                            </div>
                            <div className="w-20">
                              <TimePicker
                                value={values.unavailability[index].start_time}
                                onChange={(time) =>
                                  setFieldValue(
                                    `unavailability.${index}.start_time`,
                                    time
                                  )
                                }
                              />
                              {errors.unavailability?.[index]?.start_time &&
                                touched.unavailability?.[index]?.start_time && (
                                  <div className="mt-1 text-xs text-red-500">
                                    {errors.unavailability[index].start_time}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="flex items-center gap-4">
                            <span className="text-xs text-Red700 text-center min-w-14 bg-red-100 rounded-2xl px-3 py-[6px]">
                              End
                            </span>
                            <div>
                              <Field
                                type="date"
                                name={`unavailability.${index}.end_date`}
                                value={formatDateForDisplay(
                                  values.unavailability[index].end_date
                                )}
                                className={`border ${
                                  errors.unavailability?.[index]?.end_date &&
                                  touched.unavailability?.[index]?.end_date
                                    ? "border-red-500"
                                    : "border-Neutral300"
                                } rounded p-2 text-Neutral800 text-sm font-medium w-full col-span-2`}
                              />
                              {errors.unavailability?.[index]?.end_date &&
                                touched.unavailability?.[index]?.end_date && (
                                  <div className="mt-1 text-xs text-red-500">
                                    {errors.unavailability[index].end_date}
                                  </div>
                                )}
                            </div>
                            <div className="w-20">
                              <TimePicker
                                value={values.unavailability[index].end_time}
                                onChange={(time) =>
                                  setFieldValue(
                                    `unavailability.${index}.end_time`,
                                    time
                                  )
                                }
                              />
                              {errors.unavailability?.[index]?.end_time &&
                                touched.unavailability?.[index]?.end_time && (
                                  <div className="mt-1 text-xs text-red-500">
                                    {errors.unavailability[index].end_time}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="flex items-center justify-between py-3 border-t border-Neutral200">
                      <button
                        type="button"
                        className="flex items-center text-xs font-semibold transition duration-300 rounded text-AccentRegular"
                        onClick={() =>
                          insert(values.unavailability.length, {
                            start_date: "",
                            end_date: "",
                            start_time: "",
                            end_time: "",
                          })
                        }
                      >
                        + ADD
                      </button>
                      {values.unavailability.length > 1 && (
                        <button
                          type="button"
                          className="text-xs font-semibold text-red-400 uppercase transition duration-300"
                          onClick={() => {
                            // Reset all but the first availability
                            const firstUnAvailability =
                              values.unavailability[0];
                            setFieldValue("unavailability", [
                              firstUnAvailability,
                            ]);
                          }}
                        >
                          Reset
                        </button>
                      )}
                    </div>
                  </div>
                )
              }
            />
          </div>

          <button
            type="submit"
            className="w-full p-3 text-sm transition duration-300 rounded text-Neutral100 bg-AccentRegular"
          >
            Next
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default StepTwoForm;
