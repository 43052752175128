import { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import { Menu, Transition } from "@headlessui/react";
import CloseBtn from "../../../assets/Images/close-btn.svg";
import DeleteIcon from "../../../components/Icons/DeleteIcon";
import {
  CreateMeetingScheduleAction,
  DeleteMeetingScheduleAction,
  GetMeetingsListAction,
  UpdateMeetingScheduleAction,
} from "../../../store/actions/meetingAction";
import StepOneForm from "./StepOneForm";
import StepThreeForm from "./StepThreeForm";
import StepTwoForm from "./StepTwoForm";
import Summary from "./Summary";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "50px",
    background: "none",
    borderRadius: "10px",
    overflow: "none",
  },
};

const SchedulerModal = ({
  meetingModalIsOpen,
  setMeetingModalIsOpen,
  initialData = null,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const isEditMode = initialData !== null;
  const dispatch = useDispatch();

  const [stepOneData, setStepOneData] = useState({ title: "" });

  const [stepTwoData, setStepTwoData] = useState({
    time_slot: "",
    availability: [],
    unavailability: [],
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const [stepThreeData, setStepThreeData] = useState({
    platform: "",
    link: "",
    phone_number: "",
    custom_url: "",
  });

  const [meetingUrl, setMeetingUrl] = useState("");

  useEffect(() => {
    // When the modal is open
    if (meetingModalIsOpen) {
      // Add a class to the body to disable scrolling
      document.body.style.overflow = "hidden";
    }
    // When the component unmounts or the modal is closed
    return () => {
      // Remove the class from the body to re-enable scrolling
      document.body.style.overflow = "unset";
    };
  }, [meetingModalIsOpen]);

  useEffect(() => {
    setStepOneData(initialData?.stepOneData || { title: "" });
    setStepTwoData(
      initialData?.stepTwoData || {
        time_slot: "",
        availability: [],
        unavailability: [],
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
    );
    setStepThreeData(
      initialData?.stepThreeData || {
        platform: "",
        link: "",
        phone_number: "",
        custom_url: "",
      }
    );
  }, [initialData]);

  useEffect(() => {
    // If we're on the last step, prepare to dispatch final data
    if (currentStep === 4) {
      const finalData = {
        ...stepOneData,
        ...stepTwoData,
        ...stepThreeData,
        // ... include other steps data here if necessary
      };

      // A function that performs the dispatch when called
      const performDispatch = () => {
        mockDispatch(finalData);
        //setMeetingModalIsOpen(false); // Close modal after submission
      };

      // Check if stepThreeData is ready to be dispatched
      if (stepThreeData.platform) {
        performDispatch();
      }
    }
  }, [currentStep, stepOneData, stepTwoData, stepThreeData]);

  const resetAllData = () => {
    setStepOneData({ title: "" });

    setStepTwoData({
      time_slot: "",
      availability: [],
      unavailability: [],
      timezone: "",
    });

    setStepThreeData({
      platform: "",
      link: "",
      phone_number: "",
      custom_url: "",
    });

    // Reset other states if there are more steps
    setCurrentStep(1); // Reset to the first step
  };

  const mockDispatch = (finalData) => {
    if (isEditMode) {
      // Call update API
      const payload = {
        id: initialData?.id,
        title: finalData?.title,
        availability: finalData?.availability,
        is_unavailable: finalData?.is_unavailable,
        unavailability: finalData?.unavailability,
        platform: finalData?.platform,
        link: finalData?.meetingLink,
        custom_url: finalData?.custom_url,
        phone_number: finalData?.phone_number,
        time_slot: finalData?.time_slot,
        timezone: finalData?.timezone,
      };
      dispatch(UpdateMeetingScheduleAction(payload))
        .then((response) => {
          // Check if the dispatch was successful and if the response contains data
          if (response?.payload?.data?.vanity_path) {
            setMeetingUrl(response.payload.data.vanity_path); // Set the meeting URL from the response
            setCurrentStep(5); // Proceed to the summary step
          }
        })
        .catch((error) => {
          console.error("Failed to edit meeting schedule:", error);
          // Handle the error case
        });
    } else {
      dispatch(CreateMeetingScheduleAction(finalData))
        .then((response) => {
          // Check if the dispatch was successful and if the response contains data
          if (response?.payload?.data?.vanity_path) {
            setMeetingUrl(response.payload.data.vanity_path); // Set the meeting URL from the response
            setCurrentStep(5); // Proceed to the summary step
          }
          dispatch(GetMeetingsListAction());
        })
        .catch((error) => {
          console.error("Failed to create meeting schedule:", error);
          // Handle the error case
        });
    }
    //resetAllData();
  };

  const handleDeleteMeeting = () => {
    dispatch(DeleteMeetingScheduleAction(initialData.id));
    setMeetingModalIsOpen(false);
  };

  const handleSubmit = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleClose = () => {
    resetAllData();
    // You may want to reset the current step or perform other clean-up actions here
    setMeetingModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={meetingModalIsOpen}
      onRequestClose={handleClose}
      style={customStyles}
    >
      {currentStep === 1 && (
        <StepOneForm
          onSubmit={handleSubmit}
          formData={stepOneData}
          setFormData={setStepOneData}
          isEditMode={isEditMode}
        />
      )}
      {currentStep === 2 && (
        <StepTwoForm
          onSubmit={handleSubmit}
          formData={stepTwoData}
          setFormData={setStepTwoData}
          isEditMode={isEditMode}
        />
      )}
      {(currentStep === 3 || currentStep === 4) && (
        <StepThreeForm
          onSubmit={handleSubmit}
          formData={stepThreeData}
          setFormData={setStepThreeData}
          isEditMode={isEditMode}
          currentStep={currentStep}
        />
      )}
      {currentStep === 5 && (
        <Summary
          meetingUrl={meetingUrl}
          onClose={handleClose}
          isEditMode={isEditMode}
        />
      )}{" "}
      {/* Use the Summary component for the final step */}
      {isEditMode && (
        <Menu
          as="div"
          className="absolute right-[100px] top-0 z-40 cursor-pointer"
        >
          <div className="flex items-center">
            <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none">
              <DeleteIcon
                width={18}
                color="currentColor"
                className="text-[#E56780] hover:text-Neutral000 hover:bg-[#E56780] duration-300 transition-all rounded-[50%] h-[32px] w-[32px] flex items-center justify-center p-1 bg-[#FFF0F3]"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className="z-40 origin-top-right absolute -top-[20px] right-[-100px] pt-4 pb-6 w-[384px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none"
              onClick={(e) => {
                if (e.target === e.currentTarget) {
                  e.stopPropagation();
                }
              }}
            >
              <div>
                <p className="pb-2 text-xs font-semibold text-Neutral700">
                  Are you sure?
                </p>
              </div>
              <div className="overflow-hidden border-0">
                <h3
                  className="w-full py-4 mt-5 text-xs font-semibold text-center text-white duration-300 rounded cursor-pointer bg-Red400 hover:bg-Red600"
                  onClick={() => handleDeleteMeeting()}
                >
                  Yes, Delete Meeting Schedule
                </h3>
              </div>
              <div className="overflow-hidden border-0 !border-transparent divide-0">
                <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none">
                  <h3 className="w-full py-4 mt-3 text-xs font-semibold text-center duration-300 rounded cursor-pointer text-Neutral800 bg-Neutral200 hover:bg-Neutral500 hover:text-Neutral900">
                    No, I Still Want To Keep This Meeting Schedule
                  </h3>
                </Menu.Button>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
      <div
        className="absolute right-[50px] top-0 z-30 cursor-pointer"
        onClick={() => handleClose()}
      >
        <img src={CloseBtn} alt="" />
      </div>
    </Modal>
  );
};

export default SchedulerModal;
