import React from "react";

const DeleteIcon = ({ width = 16, color = "#5A5A66", className = "" }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.23071 5.04416H4.33157H13.1384"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.98209 5.04466V4.02244C5.98209 3.75133 6.09808 3.49132 6.30453 3.29962C6.51098 3.10791 6.79098 3.00021 7.08295 3.00021H9.28466C9.57662 3.00021 9.85663 3.10791 10.0631 3.29962C10.2695 3.49132 10.3855 3.75133 10.3855 4.02244V5.04466M12.0368 5.04466V12.2002C12.0368 12.4713 11.9208 12.7313 11.7144 12.923C11.5079 13.1147 11.2279 13.2224 10.9359 13.2224H5.43167C5.1397 13.2224 4.85969 13.1147 4.65324 12.923C4.44679 12.7313 4.33081 12.4713 4.33081 12.2002V5.04466H12.0368Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
