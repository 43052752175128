import React, { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";

import Close from "../../assets/Images/Close-gray.svg";
import ImgIcon from "../../assets/Images/img-icon.svg";

const baseStyle = {
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#EDEDF2",
};

const acceptStyle = {
  opacity: 1,
  borderColor: "#6D6DF2",
};

const rejectStyle = {
  borderColor: "#6D6DF2",
};

const UploadAssetAndDocumentFiles = (props) => {
  const {
    value,
    error,
    touched,
    handleBlue,
    setFieldValue,
    contract,
    editData,
    item,
    handleChangeeffect,
    accept,
    multipal,
    setIsActive,
  } = props;
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
  });

  useEffect(() => {
    const tempDragFile = [];
    acceptedFiles.map((file) => {
      tempDragFile.push(file);
    });
    if (tempDragFile?.length) {
      setFieldValue("file", tempDragFile);
      if (setIsActive) setIsActive(true);
    }
  }, [acceptedFiles]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <div className="relative max-h-[35vh] overflow-y-auto overflow-x-hidden">
        <div>
          {value?.file?.length ? (
            value?.file?.map((item) => (
              <label
                htmlFor="uploadlogo"
                className="placeholder:text-Neutral500 mb-2 p-2 px-3 text-sm leading-5 w-full focus-visible:outline-none border-[1px] rounded border-Neutral400 hover:border-Neutral800 bg-Neutral100 transition duration-300 border-dashed inline-block cursor-pointer"
              >
                <span
                  className="flex items-center "
                  style={{
                    justifyContent: value?.file?.length && "space-between",
                  }}
                >
                  {!value?.file?.length && <img src={ImgIcon} alt="" />}
                  <a
                    target="_blank"
                    href={item?.url}
                    className="max-w-[90%]"
                    rel="noreferrer"
                  >
                    <p className="ml-2 text-xs font-medium truncate text-Neutral600 ">
                      {item?.file?.originalname || item?.name || item?.key}
                      {/* {value?.file ? value?.file?.name || value?.file : props.title} */}
                    </p>
                  </a>
                  <div className="flex">
                    {value?.file?.length ? (
                      <>
                        <img
                          className="cursor-pointer"
                          src={Close}
                          alt=""
                          name="file"
                          onClick={() => {
                            const notRemovedFile = value.file.filter(
                              (file) =>
                                file?.name !== item?.name ||
                                file?.key !== item?.key
                            );
                            const removedFile = value.file.filter(
                              (file) => file?.key === item?.key
                            );
                            setFieldValue(`file`, Array.from(notRemovedFile));
                            setFieldValue(`remove_file`, removedFile);
                            if (setIsActive) setIsActive(true);
                          }}
                        />
                      </>
                    ) : null}
                  </div>
                </span>{" "}
              </label>
            ))
          ) : (
            <label
              {...getRootProps({ style })}
              htmlFor="uploadlogo"
              className="placeholder:text-Neutral500 p-2 px-3 text-sm leading-5 w-full focus-visible:outline-none border-[1px] rounded border-Neutral400 hover:border-Neutral800 bg-Neutral100 transition duration-300 border-dashed inline-block cursor-pointer"
            >
              <input {...getInputProps()} />
              <span className="flex items-center">
                {!value?.file?.length && <img src={ImgIcon} alt="" />}
                <a target="_blank" href={contract?.existing_file_upload?.url}>
                  <p className="ml-2 font-medium text-Neutral600">
                    {props.title}
                    {/* {value?.file ? value?.file?.name || value?.file : props.title} */}
                  </p>
                </a>
              </span>{" "}
            </label>
          )}
        </div>
        {!value?.file?.length && (
          <input
            id="uploadlogo"
            className={`hidden ${
              touched?.file && error?.file
                ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                : ""
            }`}
            type="file"
            accept={accept || ".pdf"}
            name="file"
            placeholder="Eg. very_good_password"
            onChange={(e) => {
              setFieldValue("file", Array.from(e.target.files));
              setFieldValue(`title`, e.target.files[0].name);
              if (e.target.files.length && setIsActive) setIsActive(true);
              if (e.target.files && handleChangeeffect) {
                handleChangeeffect(e);
              }
            }}
            multiple={multipal || false}
            onBlur={handleBlue}
          />
        )}
        {touched?.file && error?.file && (
          <p className="mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
            {touched?.file && error?.file}{" "}
          </p>
        )}
      </div>
    </>
  );
};

export default UploadAssetAndDocumentFiles;
