import React, { useState, useEffect } from 'react';
import { GetClientDetailAction } from '../../store/actions/clientAction';
import AdminNavbar from '../../components/Navbars/AdminNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import TaskListLoader from '../../components/ContentLoaders/TaskListLoader';
import CommanTaskListingNew from '../../components/comman/CommanTaskListingNew';
import { GetTasksActions } from '../../store/actions/tasksAction';

const ClientsTasksNew = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [taskByClient, setTaskByClient] = useState([]);

  const { clientId } = useParams();
  const allTaskList = useSelector((state) => state.tasks.all_tasks);
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const [navBarText, setNavbarText] = useState('Back');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      Object.values(allTaskList).filter((ele) => ele.client._id === clientId)
        .length === 0
    ) {
      setIsLoading(true);
      dispatch(GetTasksActions(clientId, false, setIsLoading));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (state) {
      dispatch({
        type: 'SET_CLIENT_DETAIL',
        payload: {
          isLoading: false,
          isUpdating: false,
          data: state?._id ? state : state.client,
        },
      });
    } else {
      if (clientId !== clientDetail.data._id) {
        dispatch(GetClientDetailAction(clientId, navigate));
      }
    }
  }, [clientId, dispatch, navigate]);

  useEffect(() => {
    if (clientId !== clientDetail.data._id) {
      setNavbarText('Back');
    } else {
      setNavbarText(`Back to ${clientDetail?.data?.client_name} Dashboard`);
    }
  }, [clientDetail]);

  useEffect(() => {
    if (Object.keys(allTaskList).length) {
      setTaskByClient(
        Object.values(allTaskList)?.filter((ele) => ele.client._id === clientId)
      );
    }
  }, [allTaskList]);

  return (
    <>
      <AdminNavbar
        link={`/admin/clients/manage/${clientId}`}
        title={'Task'}
        backLink={navBarText}
      />
      {isLoading ? (
        <TaskListLoader />
      ) : (
        <div className="mt-[140px] px-12">
          <CommanTaskListingNew
            clientId={clientId}
            allTaskList={taskByClient}
            clientDetail={clientDetail?.data}
            types="task"
            isAll={false}
          />
        </div>
      )}
    </>
  );
};

export default ClientsTasksNew;
