import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { Formik } from "formik";
import { gapi } from "gapi-script";
import React from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { AddUpdateGoogleCalendarAction } from "../../store/actions/authAction";

const calendarSchema = Yup.object().shape({
	// Add id of google calendar is proper format validation
	google_calendar: Yup.string().required("Google Calendar ID is required"),
});

const customStyles = {
	overlay: {
		backgroundColor: "rgba(41, 41, 51, 0.7)",
	},
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		border: "none",
		padding: "47px",
		background: "none",
		borderRadius: "10px",
	},
};

const GoogleCalendarModal = (props) => {
	const { isOpen, setIsOpen, setGoogleCalendarDetailsModal } = props;
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);

	const login = useGoogleLogin({
		onSuccess: (codeResponse) => console.log({ codeResponse }),
		flow: "auth-code",
	});
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={() => setIsOpen(false)}
			style={customStyles}
			closeTimeoutMS={300}
			contentLabel="Google Calendar Modal"
		>
			<div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full max-w-[500px] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
				<div className="text-[10px] text-center text-Neutral200 bg-gray-400 leading-[18px] py-1 px-2 rounded-t-lg w-full">
					Notice: We’re awaiting Google verification (ETA July) but you can still connect your
					account
				</div>
				<div className="bg-white px-6 xs:px-12 pt-5 pb-10 rounded-[8px]">
					<div className="flex flex-col justify-between w-full border-b border-Neutral200">
						<span className="text-xs text-AccentRegular leading-[18px] font-semibold	pb-3 border-b border-Neutral200">
							Integrate Your Google Calendar
						</span>
						<span className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-3 pt-2">
							All events added on your Google calendar will show in your ClientManager
							calendar, and all events added here, will also show on your Google Calendar.
						</span>
						<span className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-3">
							In short: they both sync together
						</span>
						<span
							className="text-xs text-AccentRegular leading-[18px] font-semibold	pb-3 underline cursor-pointer"
							onClick={() => setGoogleCalendarDetailsModal(true)}
						>
							How to set it up in 6 quick steps
						</span>
					</div>
					<GoogleLogin
						clientId="860737785330-69p10ng9sdf6ed7r82kaet42ulrdp7bb.apps.googleusercontent.com"
						shape="rectangular"
						onSuccess={(credentialResponse) => {
							const config = {
								client_id:
									"860737785330-69p10ng9sdf6ed7r82kaet42ulrdp7bb.apps.googleusercontent.com",
								scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
								prompt: "consent",
								immediate: false,
							};

							gapi.auth.authorize(config, function (response) {
								if (response && !response.error) {
									console.log("Access Token:", response.access_token);

									gapi.client.setToken({ access_token: response.access_token });
								} else {
									console.error("Failed to get an access token:", response.error);
								}
							});
						}}
						onError={() => {
							console.log("Login Failed");
						}}
					/>
					<Formik
						initialValues={{
							google_calendar: user?.google_calendar_ids?.[0] || "",
						}}
						enableReinitialize
						validationSchema={calendarSchema}
						onSubmit={(values, { setSubmitting, setErrors }) => {
							dispatch(
								AddUpdateGoogleCalendarAction(
									{
										google_calendar: values.google_calendar,
										old_google_calendar:
											user?.google_calendar_ids?.length > 0
												? user?.google_calendar_ids?.[0]
												: null,
									},
									setIsOpen,
									setSubmitting,
									setErrors,
								),
							);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							isValid,
						}) => (
							<form onSubmit={handleSubmit}>
								<div className="flex flex-col">
									<div className="flex flex-col pt-6">
										<label
											htmlFor="google_calendar"
											className="pb-2 text-sm font-semibold text-Neutral700"
										>
											Google Calendar ID
										</label>
										<div className="relative">
											<input
												type="text"
												name="google_calendar"
												placeholder="Ex. oBuMqDqFoun015tc2uVDuiYAMRTqRTO"
												id="google_calendar"
												className="border border-Neutral400 rounded-[8px] text-sm text-Neutral700 font-normal p-3 focus:outline-none focus:border-Primary w-full"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.google_calendar}
											/>
										</div>
										{errors.google_calendar && touched.google_calendar && (
											<div className="mt-1 text-xs text-red-600">
												{errors.google_calendar}
											</div>
										)}
									</div>
									<div className="flex flex-col w-full gap-3 pt-6">
										<button
											type="submit"
											disabled={isSubmitting || !isValid}
											className="button-hover large-primary-btn text-xs text-center inline-block text-Neutral000 relative bg-AccentRegular font-semibold py-3 px-6 w-full rounded focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
										>
											{isSubmitting ? "Syncing..." : "Sync My Google Calendar"}
										</button>
									</div>
								</div>
							</form>
						)}
					</Formik>
				</div>
				<div
					className="absolute right-0 top-[-50px] z-30 cursor-pointer"
					onClick={() => setIsOpen(false)}
				>
					<img src={CloseBtn} alt="" />
				</div>
			</div>
		</Modal>
	);
};

export default GoogleCalendarModal;
