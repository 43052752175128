import React from "react";

function MailIcon({ width = 24, className = "" }) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.77083 2.9165H10.9375C11.499 2.9165 11.9583 3.37588 11.9583 3.93734V10.0623C11.9583 10.6238 11.499 11.0832 10.9375 11.0832H2.77083C2.20937 11.0832 1.75 10.6238 1.75 10.0623V3.93734C1.75 3.37588 2.20937 2.9165 2.77083 2.9165Z"
        stroke="#9C9CA6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9583 3.9375L6.85417 7.51042L1.75 3.9375"
        stroke="#9C9CA6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default MailIcon;
