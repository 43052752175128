import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Close from "../../../assets/Images/Close-gray.svg";
import CloseBtn from "../../../assets/Images/close-btn.svg";
import ImgIcon from "../../../assets/Images/img-icon.svg";
import {
  AddUserCategoryAction,
  RemoverUserCategoryAction,
} from "../../../store/actions/authAction";
import { CreateProjectAction, UpdateProjectAction } from "../../../store/actions/projectAction";
import { getTagColor } from "../../../utils/getTagColor";
import { customStyles } from "../../../utils/modalStyles";
import { Select } from "../../Inputs/CreatableSelect";

const NewTemplateSchema = Yup.object().shape({
  name: Yup.string().required("Project Name Required"),
  category_tags: Yup.array().min(1, "Category Tag Required"),
});

function CreateProjectModal({ isOpen, setIsOpen, editProject }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const user = useSelector((state) => state.auth.user);
  const modalColor = "Orange";
  const [categoryTags, setCategoryTags] = useState([]);

  useEffect(() => {
    let uniqueCategory = [];

    if (user?.category?.length) {
      const userCategory = user?.category?.map((item) => {
        return { value: item, label: item };
      });
      uniqueCategory = [...userCategory, ...uniqueCategory];
    }

    uniqueCategory = uniqueCategory.reduce((acc, current) => {
      const x = acc.find((item) => item?.value?.toLowerCase() === current?.value?.toLowerCase());
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const optionsWithColors = uniqueCategory.map((option, i) => {
      const { bgColor: optionColor, text: optionText } = getTagColor(modalColor, i, true);

      const { bgColor: labelColor, text: labelText } = getTagColor(modalColor, i, true);

      return {
        ...option,
        color: optionColor,
        text: optionText,
        labelColor,
        labelText,
      };
    });
    setCategoryTags(optionsWithColors);
  }, [user]);

  const handleRemove = async (data) => {
    const removedCategory = await dispatch(RemoverUserCategoryAction(data));

    if (removedCategory?.data) {
      setCategoryTags(categoryTags.filter((e) => e !== data));
    }
  };

  const handleCreateNewTag = async (inputValue) => {
    await dispatch(AddUserCategoryAction(inputValue));
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="New Template Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <div>
              <div className="text-left">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8 uppercase"
                  id="modal-title"
                >
                  {editProject?.id ? "Edit Project" : "New Project"}
                </h3>
                <Formik
                  initialValues={{
                    name: editProject?.title ? editProject?.title : "",
                    category_tags: editProject?.category?.length
                      ? editProject?.category?.map((item, i) => {
                          const { bgColor: optionColor, text: optionText } = getTagColor(
                            modalColor,
                            i,
                            true
                          );

                          const { bgColor: labelColor, text: labelText } = getTagColor(
                            modalColor,
                            i,
                            true
                          );

                          return {
                            value: item,
                            label: item,
                            color: optionColor,
                            text: optionText,
                            labelColor,
                            labelText,
                          };
                        })
                      : [],
                    profile: editProject?.profile ? editProject?.profile : null,
                    profile_path: editProject?.profile_path ? editProject?.profile_path : null,
                  }}
                  enableReinitialize
                  validationSchema={NewTemplateSchema}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    if (editProject && editProject?._id) {
                      const formData = new FormData();
                      formData.append("title", values.name);
                      values?.category_tags?.forEach((item, index) =>
                        formData.append(`category[${index}]`, item.value)
                      );
                      formData.append("profile", values.profile);
                      formData.append("id", editProject?._id);
                      formData.append("description", editProject?.description);
                      dispatch(UpdateProjectAction(formData, setSubmitting, setErrors, setIsOpen));
                    } else {
                      const formData = new FormData();
                      formData.append("title", values.name);
                      formData.append("client", clientId);
                      values?.category_tags?.forEach((item, index) =>
                        formData.append(`category[${index}]`, item.value)
                      );

                      formData.append("profile", values.profile);
                      dispatch(
                        CreateProjectAction(formData, setSubmitting, setErrors, setIsOpen, navigate)
                      );
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldError,
                    setFieldValue,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <div className="mb-6">
                          <label className="text-Neutral900 font-bold text-xs">Name</label>
                          <div className="mt-2">
                            <input
                              className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                              type="text"
                              placeholder={`Eg. Web Design`}
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />

                            {errors.name && touched.name && (
                              <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                {errors.name}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="mb-6">
                          <label className="text-Neutral900 font-bold text-xs">Category Tags</label>
                          <div className="mt-2">
                            <Select
                              id="category_tags"
                              name="category_tags"
                              isMulti
                              isSearchable
                              isClearable={false}
                              options={categoryTags}
                              value={values.category_tags}
                              onBlur={handleBlur}
                              handleCreateNewTag={handleCreateNewTag}
                              handleRemove={handleRemove}
                              {...{ setFieldValue, modalColor }}
                            />

                            {errors.category_tags && touched.category_tags && (
                              <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                {errors.category_tags}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col justify-end min-h-[78px]">
                          <div className="flex items-center justify-between">
                            <span className="text-Neutral900 font-bold text-xs">Project Image</span>
                            <span className="text-xs font-medium italic text-Neutral700">
                              Optional
                            </span>
                          </div>
                          <div className="relative mt-2">
                            <label
                              htmlFor={values?.profile ? null : "uploadlogo"}
                              className={`placeholder:text-Neutral500 p-2 px-3 text-sm leading-5 w-full focus-visible:outline-none border-[1px] rounded border-Neutral400 hover:border-Neutral800 bg-Neutral100 transition duration-300 border-dashed flex justify-between ${
                                !values?.profile && "cursor-pointer"
                              }`}
                            >
                              {values?.profile_path ? (
                                <span className="flex items-center">
                                  <img
                                    src={values?.profile_path}
                                    alt=""
                                    height={values?.profile && 150}
                                    width={values?.profile && 100}
                                  />
                                  <p
                                    className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden ${
                                      values?.profile && "w-[160px]"
                                    }`}
                                  >
                                    {values?.profile
                                      ? values?.profile
                                      : "Upload your photo or company logo."}
                                  </p>
                                </span>
                              ) : (
                                <span className="flex items-center">
                                  <img
                                    src={
                                      values?.profile
                                        ? URL.createObjectURL(values?.profile)
                                        : ImgIcon
                                    }
                                    alt=""
                                    height={values?.profile && 150}
                                    width={values?.profile && 100}
                                  />
                                  <p
                                    className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden ${
                                      values?.profile && "w-[160px]"
                                    }`}
                                  >
                                    {values?.profile?.name
                                      ? values?.profile?.name
                                      : "Upload your photo or company logo."}
                                  </p>
                                </span>
                              )}
                              {values?.profile ? (
                                <img
                                  className="cursor-pointer"
                                  src={Close}
                                  alt=""
                                  onClick={() => {
                                    setFieldValue(`profile`, null);
                                    setFieldValue(`profile_path`, null);
                                  }}
                                />
                              ) : null}
                            </label>
                            <input
                              id="uploadlogo"
                              className="hidden"
                              type="file"
                              name="profile"
                              accept="image/x-png,image/jpeg"
                              multiple={false}
                              onChange={(e) => {
                                setFieldValue(`profile`, e.target.files[0]);
                                setFieldValue(`profile_path`, null);
                              }}
                            />
                          </div>
                        </div>

                        <div className="mt-8">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                          >
                            {isSubmitting ? (
                              <span className="relative">
                                <svg
                                  className="animate-spin m-auto h-5 w-5 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              </span>
                            ) : (
                              <span className="relative drop-shadow-Texts">Save</span>
                            )}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setIsOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CreateProjectModal;
