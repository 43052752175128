import { useState } from "react";
import { useSelector } from "react-redux";

import normalizeNames from "../../utils/normalizeNames";
import AddLeadModal from "../Modals/AddLeadModal";
import CreateClientModal from "../Modals/CreateClientModal";
import CreateMemberModal from "../Modals/CreateMemberModal";
import NewProjectModal from "../Modals/ProjectModal/NewProjectModal";
import { NewEntityTemplateModal } from "../Modals/Templates/NewEntityTemplateModal";
import InfographicCard from "./InfographicCard";

function Infographic() {
  const dashboardCount = useSelector((state) => state.dashboard.dashboardCount);

  const [isLeadsModalOpen, setIsLeadsModalOpen] = useState(false);
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
  const [isProjectTemplateModalOpen, setIsProjectTemplateModalOpen] =
    useState(false);
  // const [isSelectTemplateModalOpen, setIsSelectTemplateModalOpen] = useState(false)
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);

  const handleOpenClientModal = () => {
    setIsProjectTemplateModalOpen(false);
    // setSelectClientModalIsOpen(true);
  };

  const handleOpenDefaultTemplateModal = () => {
    setIsProjectTemplateModalOpen(false);
    setIsNewProjectModalOpen(true);
  };

  return (
    <section className="flex flex-col justify-between gap-3 mb-14 md:flex-row md:mb-0">
      {/* Clients Cards */}
      <InfographicCard
        title="Clients"
        totalCount={dashboardCount.data?.clients}
        thisMonthCount={`+${dashboardCount.data?.thisMonthClients}`}
        content={() => (
          <p className="mb-auto text-sm text-Neutral700">
            You added{" "}
            <span className="font-semibold text-Neutral900">
              {dashboardCount.data?.thisMonthClients}
            </span>{" "}
            new clients this month:{" "}
            <span className="font-semibold text-Neutral900">
              {dashboardCount.data?.thisMonthClientsName}
            </span>
            .
          </p>
        )}
        onClick={() => setIsAddClientModalOpen(true)}
      />

      {/* Projects Cards */}
      <InfographicCard
        title="Projects"
        totalCount={dashboardCount.data?.projects}
        thisMonthCount={`+${dashboardCount.data?.thisMonthProjects}`}
        content={() => (
          <p className="mb-auto text-sm text-Neutral700">
            You added{" "}
            <span className="font-semibold text-Neutral900">
              {dashboardCount.data?.thisMonthProjects}
            </span>{" "}
            new projects for{" "}
            <span className="font-semibold text-Neutral900">
              {dashboardCount.data?.thisMonthClients} client(s)
            </span>{" "}
            this month and assigned it to{" "}
            <span className="font-semibold text-Neutral900">
              {dashboardCount.data?.thisMonthTeams} team members
            </span>
          </p>
        )}
        onClick={() => setIsNewProjectModalOpen(true)}
      />

      {/* Leads Card */}
      <InfographicCard
        title="Leads"
        totalCount={dashboardCount.data?.leads}
        thisMonthCount={`+${dashboardCount.data?.thisMonthLeads}`}
        content={() => (
          <p className="mb-auto text-sm text-Neutral700">
            Your team is pursuing{" "}
            <span className="font-semibold text-Neutral900">
              {dashboardCount.data?.thisMonthLeads}
            </span>{" "}
            new leads this month worth{" "}
            <span className="font-semibold text-Neutral900">
              ${dashboardCount.data?.thisMonthLeadsBugdet}
            </span>
          </p>
        )}
        onClick={() => setIsLeadsModalOpen(true)}
      />

      {/* Teams Card */}
      <InfographicCard
        title="Team Members"
        totalCount={dashboardCount.data?.teams}
        thisMonthCount={`+${dashboardCount.data?.thisMonthTeams}`}
        content={() => (
          <p className="mb-auto text-sm text-Neutral700">
            You added{" "}
            <span className="font-semibold text-Neutral900">
              {dashboardCount.data?.thisMonthTeams}
            </span>{" "}
            member(s) from your team this month:{" "}
            <span className="font-semibold text-Neutral900">
              {/* E.g. ['John', 'Doe', 'Sally'] -> 'John, Doe and Sally' */}
              {normalizeNames(dashboardCount.data?.thisMonthTeamsName)}
            </span>
          </p>
        )}
        onClick={() => setIsAddMemberModalOpen(true)}
      />

      <AddLeadModal isOpen={isLeadsModalOpen} setIsOpen={setIsLeadsModalOpen} />
      <CreateClientModal
        isOpen={isAddClientModalOpen}
        setIsOpen={setIsAddClientModalOpen}
      />
      <NewEntityTemplateModal
        type="Project"
        isOpen={isProjectTemplateModalOpen}
        setIsOpen={setIsProjectTemplateModalOpen}
        onCustomTemplateClick={handleOpenClientModal}
        onDefaultTemplateClick={handleOpenDefaultTemplateModal}
      />
      {/* <SelectTemplate
        type="Project"
        isOpen={isSelectTemplateModalOpen}
        setIsOpen={setIsSelectTemplateModalOpen}
        clientId={selectClient?._id}
      /> */}
      <NewProjectModal
        isOpen={isNewProjectModalOpen}
        setIsOpen={setIsNewProjectModalOpen}
      />
      <CreateMemberModal
        isOpen={isAddMemberModalOpen}
        setModal={setIsAddMemberModalOpen}
      />
    </section>
  );
}

export default Infographic;
