import axios from "axios";
import { toast } from "react-toastify";

export const CreateMemberAction = (
  { email, user_name, role, assigned_clients, to_all_clients },
  setSubmitting,
  setErrors,
  setModal,
  navigate,
  setLoading
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/team/invite-member", {
        email,
        user_name,
        role,
        assigned_clients,
        to_all_clients,
      });
      dispatch(getMemberList());
      setLoading(false);
      setModal(false);
      setSubmitting(false);
      toast.success("Member created successfully");
      //   if (data?.data?._id) {
      //     navigate(`/admin/clients/manage/${data?.data?._id}`);
      //   }
      return dispatch({
        type: "MEMBER_CREATE_SUCCESS",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setLoading(false);
        setErrors(err);
        setSubmitting(false);
        return dispatch({
          type: "MEMBER_CREATE_ERROR",
          payload: error,
        });
      } else {
        setLoading(false);
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "MEMBER_CREATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const resendInviteAction = (email, setIsLoading) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/team/resend-invitation`, email);
      setIsLoading(false);
      toast.success('Invitation Resent Succesfully')
      return dispatch({
        type: "RESEND_INVITE_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "RESEND_INVITE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "RESEND_INVITE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const modifyMemberClients = (value) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/team/member-assign-client`, value);
      return dispatch({
        type: "MODIFY_MEMBER_CLIENTS_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "MODIFY_MEMBER_CLIENTS_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "MODIFY_MEMBER_CLIENTS_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const getMemberList = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_MEMBERS_LIST",
        payload: { isLoading: true },
      });
      let { data } = await axios.get(`api/v1/team/get-member-list`);
      return dispatch({
        type: "SET_MEMBERS_LIST",
        payload: { isLoading: false, data: data.data, spot: data.spot },
      });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "MEMBERS_LIST_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "MEMBERS_LIST_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const removeteammemberAction = (id, setisloding) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/team/remove-team-member`, id);
      setisloding(false);
      return dispatch({
        type: "REMOVE_TEAM_MEMBER_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "REMOVE_TEAM_MEMBER_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "REMOVE_TEAM_MEMBER_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const updateTeamMemberRoleAction = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/team/update-team-member-role`,
        id
      );
      dispatch(getMemberList());
      return dispatch({
        type: "UPDATE_TEAM_MEMBER_ROLE_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error?.response?.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "UPDATE_TEAM_MEMBER_ROLE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "UPDATE_TEAM_MEMBER_ROLE_ERROR",
          payload: error,
        });
      }
    }
  };
};
