import React from "react";

const ButtonLoader = () => {
  return (
    <div className="py-2 flex justify-center items-center relative overflow-hidden z-10">
      <div className="pr-2 white dot-falling"></div>
    </div>
  );
};
export default ButtonLoader;
