import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  invitedClientAction,
  updateClientAccessLevel,
} from "../../store/actions/clientAction";

const ViewEditPermission = () => {
  const dispatch = useDispatch();

  const invitedClient = useSelector((state) => state.client.invitedClientList);

  useEffect(() => {
    dispatch(invitedClientAction());
  }, [dispatch]);

  return (
    <div className="w-full 5xl:max-w-[500px] max-w-[408px] rounded border border-Neutral300 py-8 px-10">
      <div className="space-y-1">
        <h5 className="text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase">
          DEFAULT CLIENT PERMISSIONS
        </h5>
        <p className="text-xs font-medium text-Neutral700">
          For documents, assets, and projects.
        </p>
      </div>

      <div className="overflow-x-auto max-h-[200px] mb-4">
        {invitedClient?.data?.map((item) => (
          <div
            key={item._id}
            className="flex items-center justify-between w-full py-3 border-b border-Neutral300"
          >
            <div className="flex items-center">
              {item?.client?.profile ? (
                <img
                  src={item?.client?.profile_path}
                  alt="profile"
                  className="w-8 h-8 rounded-full"
                />
              ) : (
                <div className="w-8 h-8 rounded-full bg-Neutral300">
                  <p className="mt-2 text-xs text-center text-Neutral500">
                    {item?.client?.client_name.charAt(0)}
                  </p>
                </div>
              )}
              <p className="ml-4 text-xs text-Neutral800">{item?.email}</p>
            </div>
            <div>
              <div className="relative mt-2">
                <Menu
                  as="div"
                  className="relative inline-block w-full text-left"
                >
                  <Menu.Button className="rounded-[4px] border-[0.5px] border-Neutral300 inline-flex w-full focus-visible:outline-none popup-btn relative px-[12px] pb-[10px] pt-[10px]">
                    <span className="focus-visible:outline-none">
                      <div className="members-text flex flex-wrap text-[10px] items-center uppercase justify-between text-Neutral800 font-medium">
                        <p className="text-Neutral800 text-[10px] font-medium uppercase">
                          {item?.access_level === "full_access"
                            ? "Edit Access"
                            : "View Only"}
                        </p>
                        <svg
                          className="ml-1"
                          width="9"
                          height="6"
                          viewBox="0 0 11 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                            fill="#5A5A66"
                          />
                        </svg>
                      </div>
                    </span>
                  </Menu.Button>
                  <Transition as={Fragment}>
                    <Menu.Items className="box z-50 absolute right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-[130px] pb-4 px-[12px] rounded-[0px_0px_4px_4px]">
                      <div className="bg-white">
                        <p className="text-Neutral600 text-[10px] font-semibold pt-[9px] pb-[12px] border-t border-Neutral200"></p>
                        <div>
                          <div
                            className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 cursor-pointer"
                            onClick={() => {
                              dispatch(
                                updateClientAccessLevel({
                                  invited_client_id: item._id,
                                  access_level: "full_access",
                                })
                              );
                            }}
                          >
                            <p className="text-Neutral800 text-[10px] uppercase font-normal">
                              Edit Access
                            </p>
                          </div>
                          <div
                            className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 cursor-pointer"
                            onClick={() => {
                              dispatch(
                                updateClientAccessLevel({
                                  invited_client_id: item._id,
                                  access_level: "view_only",
                                })
                              );
                            }}
                          >
                            <p className="text-Neutral800 text-[10px] uppercase font-normal">
                              View Only
                            </p>
                          </div>
                        </div>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewEditPermission;
