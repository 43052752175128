import React, { Fragment, useState, useRef, forwardRef } from "react";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { Menu, Transition } from "@headlessui/react";
import SelectDownArrow from "../Icons/SelectDownArrow";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "react-time-picker-input/dist/components/TimeInput.css";
import { useEffect } from "react";
import { EditTimeEntryActions } from "../../store/actions/timeTrackingAction";
import threeDots from "../../assets/Images/three-dots-modals.svg";
import addTagClose from "../../assets/Images/modal-close-icon.svg";
import addTagMinus from "../../assets/Images/addTag-minus.svg";
import pluseIcon from "../../assets/Images/pluse-icon.svg";
import CalendarIcon from "../Icons/CalendarIcon";
import { getFullClientList } from "../../store/actions/clientAction";
import ClientSelect from "../comman/Client/ClientSelect";
import {
  RemoverUserCategoryAction,
  AddUserCategoryAction,
} from "../../store/actions/authAction";

const colors = ["Green400", "Orange400", "Red400", "Blue400", "Pink400"];

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const TrackingEditTimeEntryModal = (props) => {
  const addNewTagInput = useRef();
  const { isOpen, setIsOpen, editTimeEntry, setTimeEntryList } = props;
  const dispatch = useDispatch();
  const assignTimeValidation = Yup.object().shape({
    client: Yup.string().required("Choose client name"),
    title: Yup.string().required("Choose client title"),
    category: Yup.array().min(1, "add 1 category").required("required"),
    date: Yup.string().required("Enter tracking Date"),
    hours: Yup.string().required("Enter tracking Hours"),
    minutes: Yup.string().required("Enter tracking Minutes"),
    project: Yup.string().required("Choose project name"),
  });
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  const [tempType, setTempType] = useState({
    client: "",
    title: "",
    color: randomColor,
    category: [],
    date: moment().format("YYYY-MM-DD"),
    hours: "",
    minutes: "",
    project: "",
  });
  const [selected, setSelected] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [removeClass, setIsRemoveClass] = useState(false);
  const [newTag, setNewTag] = useState(null);
  const [addTag, setIsAddTag] = useState(false);
  const logedinUser = useSelector((state) => state.auth.user);
  const allClients = useSelector((state) => state.client.fullClientList);
  const userDateFormat = logedinUser?.date_format;

  useEffect(() => {
    if (!allClients?.data?.length && isOpen) {
      dispatch(getFullClientList());
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    setTempType({
      client: editTimeEntry?.client,
      title: editTimeEntry?.title,
      color: randomColor,
      category: editTimeEntry?.category,
      date: moment(editTimeEntry?.date).format("YYYY-MM-DD"),
      hours: editTimeEntry?.time?.split(":").slice()[0],
      minutes: editTimeEntry?.time?.split(":").slice(-1)[0],
      project: editTimeEntry?.project,
      id: editTimeEntry?._id,
      user: editTimeEntry?.user?._id,
    });
    const clientData = allClients?.data?.find(
      (value) => value?._id === editTimeEntry?.client
    );
    setSelectedClient(clientData?.projects);
    const tempProject = clientData?.projects?.find(
      (value) => value?._id === editTimeEntry?.project
    );
    let tempCategory = [];

    if (tempProject?.category?.length) {
      tempCategory = [...tempProject?.category, ...tempCategory];
    }

    if (clientData?.category?.length) {
      tempCategory = [...clientData?.category, ...tempCategory];
    }

    if (logedinUser?.category?.length) {
      tempCategory = [...logedinUser?.category, ...tempCategory];
    }

    tempCategory = tempCategory.reduce((acc, current) => {
      const x = acc.find(
        (item) => item?.toLowerCase() === current?.toLowerCase()
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    setSelected({
      ...selected,
      ...tempProject,
      category: [...tempCategory],
    });
  }, [editTimeEntry, allClients]);

  const handleRemoveCategory = async (data) => {
    const removedCategory = await dispatch(RemoverUserCategoryAction(data));

    if (removedCategory?.data) {
      setSelected({
        ...selected,
        category: selected?.category.filter((e) => e !== data),
      });
    }
  };

  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="relative">
      <input
        type="text"
        className={`cursor-pointer caret-transparent example-custom-input whitespace-nowrap ${
          value ? "font-bold text-Neutral900" : "text-Neutral600"
        } text-Neutral800 h-[48px] w-full font-medium font-feature-settings pl-[17.5px] pr-[46.5px] placeholder:text-sm text-sm leading-5 5xl:max-w-[180px] w-[148px] focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition add-new-event-time-color`}
        value={!value ? "Select date" : moment(value).format(userDateFormat)}
        onClick={onClick}
        readOnly
        ref={ref}
      />
      <CalendarIcon className="absolute top-[50%] right-[12px] translate-y-[-50%] pointer-events-none" />
    </div>
  ));

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-Neutral000 rounded-lg text-left transform transition-all sm:mt-8 sm:min-w-[416px] sm:w-full sm:max-w-[500px] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-Neutral000 px-6 md:px-12 py-8 rounded-[8px] max-h-[90vh] w-[85vw] md:w-full overflow-y-auto overflow-x-hidden">
            <div>
              <div className="pb-2 text-xs font-semibold border-b text-Neutral700 border-Neutral200">
                {" "}
                EDIT TIME ENTRY{" "}
              </div>
              <Formik
                initialValues={tempType}
                enableReinitialize
                validationSchema={assignTimeValidation}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  const data = {
                    ...values,
                    time: `${values?.hours}:${values?.minutes}`,
                  };
                  setSubmitting(true);
                  dispatch(
                    EditTimeEntryActions(
                      data,
                      setSubmitting,
                      setErrors,
                      setIsOpen,
                      setTempType,
                      setTimeEntryList
                    )
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form>
                    <div className="mt-5">
                      <label
                        for="countries"
                        className="inline-block mb-2 text-xs font-bold text-Neutral900"
                      >
                        Client
                      </label>
                      <ClientSelect
                        allClients={allClients}
                        onChange={(e) => {
                          setFieldValue("client", e?._id);
                          setFieldValue("project", "");
                        }}
                        onBlur={handleBlur}
                        isDisabled={values?.client}
                        value={values?.client}
                      />
                    </div>
                    <div className="mt-5">
                      <label
                        for="countries"
                        className="inline-block w-full mb-2 text-xs font-bold text-left text-Neutral900"
                      >
                        Project
                      </label>
                      <div className="relative">
                        <span className="absolute pointer-events-none top-[50%] translate-y-[-50%] right-[16px]">
                          <SelectDownArrow />
                        </span>
                        <select
                          id="countries"
                          onChange={(e) => {
                            const tempProject = JSON.parse(e.target.value);
                            setFieldValue("project", tempProject?._id);
                          }}
                          disabled={values?.project}
                          onBlur={handleBlur}
                          name="project"
                          className="text-Neutral800 bg-Neutral200 font-medium placeholder:text-Neutral500 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded border-transparent hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition"
                        >
                          <option value="" disabled selected defaultValue>
                            Select Project...
                          </option>
                          {selectedClient?.map((item, i) => {
                            return (
                              <option
                                selected={item?._id === values?.project}
                                value={JSON.stringify(item)}
                                key={`${item?._id + i}`}
                              >
                                {item?.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="">
                      <div className="mt-5">
                        <label
                          for="countries"
                          className="inline-block w-full mb-2 text-xs font-bold text-left text-Neutral900"
                        >
                          Title
                        </label>
                        <div className="relative">
                          <input
                            value={values.title}
                            type="text"
                            name="title"
                            className="relative text-Neutral800 placeholder:font-medium px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow appearance-none duration-300 transition border-Neutral300"
                            placeholder="Eg. Brand Building"
                            onChange={handleChange}
                          />
                        </div>
                        {errors?.title && touched?.title && (
                          <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                            {errors?.title}
                          </p>
                        )}
                      </div>
                    </div>
                    <Menu
                      as="div"
                      className="relative inline-block w-full text-left"
                    >
                      {({ open }) => (
                        <>
                          <div className="">
                            <Menu.Button className="relative w-full focus-visible:outline-none popup-btn">
                              <div className="mt-6">
                                <label
                                  for="countries"
                                  className="inline-block w-full mb-2 text-xs font-bold text-left text-Neutral900"
                                >
                                  Categories
                                </label>
                                <div className="relative flex flex-wrap">
                                  {!tempType?.category?.length && (
                                    <>
                                      <span className="absolute pointer-events-none top-[50%] translate-y-[-50%] right-[16px]">
                                        <SelectDownArrow />
                                      </span>
                                      <input
                                        id="countries"
                                        onChange={handleChange}
                                        name="category"
                                        disabled
                                        className="relative text-Neutral500 font-medium placeholder:text-Neutral800 px-4 placeholder:text-xs 5xl:placeholder:text-lg text-xs leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition border-Neutral300 bg-Neutral200 "
                                        placeholder="NONE"
                                      />
                                    </>
                                  )}
                                  {tempType?.category?.length === 0
                                    ? true
                                    : " " && (
                                        <div className="relative flex flex-wrap items-center text-Neutral500 font-medium placeholder:text-Neutral500 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full min-h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition border-Neutral300 bg-Neutral200">
                                          <span className="absolute pointer-events-none top-[50%] translate-y-[-50%] right-[16px]">
                                            <SelectDownArrow />
                                          </span>
                                          {tempType?.category?.map(
                                            (ele, index) => {
                                              return (
                                                <span
                                                  key={index}
                                                  className="text-[12px] 5xl:text-xs 5xl:min-w-[40px] min-w-[32px] w-full max-w-fit flex items-center 5xl:min-h-[24px] min-h-[18px] max-h-[30px] mt-[0px] mr-2 mb-0 font-medium rounded-[6px] cursor-pointer text-Neutral800 border border-Neutral400 relative"
                                                >
                                                  <div className="px-2 py-1 tracking-wider uppercase">
                                                    {ele}
                                                  </div>
                                                </span>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}
                                </div>
                                {errors?.category && touched?.category && (
                                  <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                    {errors?.category}
                                  </p>
                                )}
                              </div>
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right max-h-[25vh] overflow-y-auto z-[99] mb-2 absolute top-[40px] left-0 mt-2 rounded-lg dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none px-4 pt-3 pb-1">
                              <div>
                                <p className="text-xs font-semibold text-Neutral600">
                                  Selected Category Tags
                                </p>
                                <div className="flex flex-wrap pt-1">
                                  {tempType?.category?.map((ele, index) => {
                                    return (
                                      <span
                                        key={index}
                                        className="text-[12px] 5xl:text-xs 5xl:min-w-[40px] min-w-[32px] w-full max-w-fit flex items-center 5xl:min-h-[24px] min-h-[18px] mr-2 mb-1 font-semibold rounded-[6px] cursor-pointer text-Neutral900 border border-Neutral400 relative"
                                      >
                                        <div
                                          className="px-2 py-1 tracking-wider uppercase"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            const removedCategories =
                                              tempType?.category?.filter(
                                                (element) => element !== ele
                                              );
                                            setTempType({
                                              ...values,
                                              category: [...removedCategories],
                                            });
                                          }}
                                        >
                                          {ele}
                                        </div>
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                              <div>
                                <div className="pt-1 mr-2 ">
                                  <div className="flex items-center justify-between">
                                    <p
                                      onClick={() => setIsRemoveClass(true)}
                                      className={`${
                                        removeClass ? "hidden" : ""
                                      }text-Neutral600 font-semibold text-[10px] py-2`}
                                    >
                                      + Add Category Tags
                                    </p>
                                    <div className="flex items-center justify-center w-4 h-4 cursor-pointer">
                                      <img
                                        onClick={() => setIsRemoveClass(true)}
                                        className={`${
                                          removeClass ? "hidden" : ""
                                        }`}
                                        src={threeDots}
                                        alt=""
                                      />
                                      <img
                                        onClick={() => {
                                          setIsRemoveClass(false);
                                          setIsAddTag(false);
                                        }}
                                        className={`${
                                          removeClass ? "" : "hidden"
                                        }`}
                                        src={addTagClose}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-wrap pt-1">
                                    {removeClass ? (
                                      <span className="mr-[10px] mt-2 cursor-pointer border border-dashed border-Neutral600 h-[25px] rounded-[6px] box-border">
                                        <p
                                          onClick={() => {
                                            addNewTagInput?.current?.focus();
                                            setIsAddTag(true);
                                          }}
                                          className={`py-1 px-2 flex text-AccentRegular font-medium text-xs ${
                                            addTag ? "hidden" : ""
                                          }`}
                                        >
                                          <img
                                            src={pluseIcon}
                                            className="mr-1"
                                            alt=""
                                          />{" "}
                                          ADD TAG{" "}
                                        </p>
                                        <input
                                          ref={addNewTagInput}
                                          type="text"
                                          className={`focus:outline-none px-1 h-[20px] uppercase ${
                                            addTag ? "" : "hidden"
                                          }`}
                                          size="5"
                                          autoFocus
                                          onChange={(e) =>
                                            setNewTag(
                                              e.target.value.toUpperCase()
                                            )
                                          }
                                          onBlur={async () => {
                                            if (!newTag || newTag === "") {
                                              await setNewTag(null);
                                              setIsAddTag(false);
                                            } else {
                                              setSelected({
                                                ...selected,
                                                category: [
                                                  newTag,
                                                  ...selected?.category,
                                                ],
                                              });
                                              setIsAddTag(false);
                                              await dispatch(
                                                AddUserCategoryAction(newTag)
                                              );
                                              await setNewTag(null);
                                            }
                                          }}
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {selected?.category?.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className={`flex uppercase text-xs ${
                                            tempType?.category?.includes(item)
                                              ? "text-Neutral900"
                                              : "text-Neutral700"
                                          } px-2 py-1 border border-Neutral400 relative cursor-pointer rounded-[6px] mr-[10px] mt-2 hover:text-Neutral800 hover:border-Neutral600 duration-300`}
                                        >
                                          <span
                                            onClick={(e) => {
                                              const tempCheck =
                                                tempType?.category?.includes(
                                                  item
                                                );
                                              if (!tempCheck) {
                                                setTempType({
                                                  ...values,
                                                  category: [
                                                    ...tempType?.category,
                                                    item,
                                                  ],
                                                });
                                              } else {
                                                e.stopPropagation();
                                                const removedCategories =
                                                  tempType?.category?.filter(
                                                    (element) =>
                                                      element !== item
                                                  );
                                                setTempType({
                                                  ...values,
                                                  category: [
                                                    ...removedCategories,
                                                  ],
                                                });
                                              }
                                            }}
                                          >
                                            {item}
                                          </span>
                                          <img
                                            className={`absolute -top-2 -right-2 ${
                                              removeClass && item !== "NONE"
                                                ? ""
                                                : "hidden"
                                            }`}
                                            src={addTagMinus}
                                            alt=""
                                            onClick={() => {
                                              handleRemoveCategory(item);
                                            }}
                                          />
                                        </div>
                                      );
                                    })}{" "}
                                  </div>
                                </div>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                    <div className="mt-6">
                      <label className="text-xs font-bold text-Neutral900">
                        Duration
                      </label>

                      <div className="mt-2 add-event-time-date flex">
                        <div
                          className="min-w-fit max-w-fit text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings py-4 h-[48px] placeholder:text-sm text-sm leading-5 focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular time-inputs"
                          tabIndex="1"
                        >
                          <div className="flex">
                            <input
                              type="number"
                              className="number-input outline-none w-[22px] ml-[17px] text-Neutral800 placeholder:font-medium placeholder:text-sm 5xl:placeholder:text-lg"
                              min="0"
                              max="24"
                              maxLength="2"
                              name="hours"
                              value={values.hours}
                              onChange={(e) => {
                                if (Number(e.target.value) > 25) {
                                  setFieldValue("hours", 24);
                                } else {
                                  setFieldValue(
                                    "hours",
                                    ("0" + e.target.value).slice(-2)
                                  );
                                }
                              }}
                              placeholder="00"
                            />
                            :
                            <input
                              type="number"
                              className="number-input outline-none w-[22px] mr-[17px] ml-[5px] text-Neutral800 placeholder:font-medium placeholder:text-sm 5xl:placeholder:text-lg"
                              min="0"
                              max="59"
                              placeholder="00"
                              maxLength="2"
                              value={values.minutes}
                              name="minutes"
                              onChange={(e) => {
                                if (Number(e.target.value) > 59) {
                                  setFieldValue("minutes", 59);
                                } else {
                                  setFieldValue(
                                    "minutes",
                                    ("0" + e.target.value).slice(-2)
                                  );
                                }
                              }}
                            />
                          </div>
                          {errors?.hours && touched?.hours && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors?.hours}
                            </p>
                          )}
                          <br />
                          {errors?.minutes && touched?.minutes && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors?.minutes}
                            </p>
                          )}
                        </div>
                        <div className="ml-[8px] w-full">
                          <DatePicker
                            selected={new Date(values?.date)}
                            customInput={
                              <DateInput
                                value={new Date(values?.date).toISOString()}
                              />
                            }
                            onChange={(date) => {
                              handleChange({
                                type: "date",
                                target: {
                                  name: "date",
                                  value: date,
                                },
                              });
                            }}
                          />
                          {errors?.date && touched?.date && (
                            <p className="mt-1 ml-[30px] text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors?.date}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-10">
                      <button
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                      >
                        {isSubmitting ? (
                          <span className="relative z-10 drop-shadow-Texts">
                            <svg
                              className="animate-spin m-auto h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </span>
                        ) : (
                          <span className="relative z-10">Save</span>
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setIsOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TrackingEditTimeEntryModal;
