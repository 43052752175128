import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";
import { useDispatch } from "react-redux";
import { UpdateDateFormatAction } from "../../store/actions/authAction";

const DateFormat = ({ logedinUser }) => {
    const dispatch = useDispatch();
    const dateformatref = useRef(null);

    return (
        <div className="w-full 5xl:max-w-[500px] max-w-[408px] rounded border border-Neutral300 py-8 px-10">
            <div className="flex justify-between items-center">
                <h5 className="text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase">
                    Date Format
                </h5>
                <Menu as="div" className="relative inline-block text-left">
                    {({ open }) => (
                        <>
                            <Menu.Button
                                ref={dateformatref}
                                className="focus-visible:outline-none relative h-[36px] p-[4px_8px] rounded-[4px]"
                            >
                                <div
                                    className={`flex items-center text-Neutral800 border-[1px] border-Neutral300 hover:border-Neutral500 ${open ? "bg-Neutral400" : ''
                                        } px-[12px] rounded-[4px] py-1`}
                                >
                                    <span className="whitespace-nowrap text-sm font-medium overflow-hidden mr-1">
                                        {logedinUser && logedinUser?.date_format
                                            ? `${logedinUser?.date_format}`
                                            : "DD/MM/YYYY"}
                                    </span>
                                    {open ? (
                                        <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M3 7.50003L6 4.50003L9 7.50003"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9 4.49997L6 7.49997L3 4.49997"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    )}
                                </div>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="origin-top-right max-h-[241px] z-[999999] absolute right-[8px] rounded-[8px] dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 focus:outline-none overflow-auto cursor-pointer py-2 px-3 w-[92%]">
                                    <div className="bg-white">
                                        <p className="text-Neutral600 text-[12px] font-semibold pt-[9px] pb-[12px] border-t border-Neutral200">
                                            Select date format
                                        </p>
                                        <div>
                                            <div
                                                className="flex items-center rounded-[2px] py-[6px] pl-[14px] hover:bg-Neutral200 cursor-pointer"
                                                onClick={() => {
                                                    dispatch(UpdateDateFormatAction('DD/MM/YYYY'));
                                                    setTimeout(() => {
                                                        dateformatref?.current?.click();
                                                    }, 0);
                                                }}
                                            >
                                                <p className="text-Neutral800 text-[12px] uppercase font-normal">
                                                    DD/MM/YYYY
                                                </p>
                                            </div>
                                            <div
                                                className="flex items-center rounded-[2px] py-[6px] pl-[14px] hover:bg-Neutral200 cursor-pointer"
                                                onClick={() => {
                                                    dispatch(UpdateDateFormatAction('MM/DD/YYYY'));
                                                    setTimeout(() => {
                                                        dateformatref?.current?.click();
                                                    }, 0);
                                                }}
                                            >
                                                <p className="text-Neutral800 text-[12px] uppercase font-normal">
                                                    MM/DD/YYYY
                                                </p>
                                            </div>
                                            <div
                                                className="flex items-center rounded-[2px] py-[6px] pl-[14px] hover:bg-Neutral200 cursor-pointer"
                                                onClick={() => {
                                                    dispatch(UpdateDateFormatAction('YYYY/MM/DD'));
                                                    setTimeout(() => {
                                                        dateformatref?.current?.click();
                                                    }, 0);
                                                }}
                                            >
                                                <p className="text-Neutral800 text-[12px] uppercase font-normal">
                                                    YYYY/MM/DD
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </>
                    )}
                </Menu>
            </div>
        </div>
    );
};

export default DateFormat;
