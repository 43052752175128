import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { InitialContent } from "../../store/actions/proposalAction";
import { initContractContent } from "../../store/actions/documentsAction";
import { initQuestionnaire } from "../../store/actions/questionnaireAction";
import { useNavigate } from "react-router";
import { initInvoice } from "../../store/actions/invoicesAction";
import { initProject } from "../../store/actions/projectAction";
import ClientSelect from "../comman/Client/ClientSelect";
import { getFullClientList } from "../../store/actions/clientAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const SelectClientModal = ({
  setIsOpenSelectClientModal,
  isOpenSelectClientModal,
  type,
  editData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectClient, setSelectClient] = useState(null);
  const [errors, setErrors] = useState({});
  const allClients = useSelector((state) => state.client.fullClientList);

  useEffect(() => {
    if (allClients?.data?.length === 0 && isOpenSelectClientModal) {
      dispatch(getFullClientList());
    }
  }, [dispatch, isOpenSelectClientModal]);

  const handleCustom = () => {
    if (type === "Proposal") {
      dispatch(
        InitialContent(
          {
            client_id: selectClient?._id,
            type: "custom",
            template_id: editData?._id,
            is_lead: false,
          },
          setIsOpenSelectClientModal,
          navigate,
          setErrors
        )
      );
    }
    if (type === "Contract") {
      dispatch(
        initContractContent(
          {
            client_id: selectClient?._id,
            type: "custom",
            template_id: editData?._id,
          },
          setIsOpenSelectClientModal,
          navigate,
          setErrors
        )
      );
    }
    if (type === "Questionnaire") {
      dispatch(
        initQuestionnaire(
          {
            client_id: selectClient?._id,
            type: "custom",
            template_id: editData?._id,
            is_lead: false,
          },
          setIsOpenSelectClientModal,
          navigate,
          setErrors
        )
      );
    }
    if (type === "Invoice") {
      console.log("creating invoice");
      dispatch(
        initInvoice(
          {
            client_id: selectClient?._id,
            type: "custom",
            template_id: editData?._id,
          },
          setIsOpenSelectClientModal,
          navigate,
          setErrors
        )
      );
    }
    if (type === "Project") {
      dispatch(
        initProject(
          {
            client_id: selectClient?._id,
            type: "custom",
            template_id: editData?._id,
          },
          setIsOpenSelectClientModal,
          navigate,
          setErrors
        )
      );
    }
    setSelectClient(null);
  };

  return (
    <Modal
      isOpen={isOpenSelectClientModal}
      onRequestClose={() => setIsOpenSelectClientModal(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all w-[80vw] sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-12 rounded-[8px]">
          <div>
            <div className="text-left">
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                id="modal-title"
              >
                SELECT CLIENT
              </h3>
              <div className="mb-8">
                <label className="text-Neutral900 font-bold text-xs mb-2 block">
                  Client
                </label>
                <ClientSelect
                  allClients={allClients}
                  onChange={(e) => {
                    setErrors({});
                    setSelectClient(e);
                  }}
                />
                {Object.values(errors)[0] && (
                  <p className="text-xs text-red-500 mb-4 mt-2">
                    {Object.values(errors)[0]}
                  </p>
                )}
              </div>
              <button
                className={`btn button-hover large-primary-btn text-sm text-center inline-block text-Neutral100 bg-AccentRegular font-semibold w-full min-w-[148px]  5xl:max-w-[172px] py-3 rounded drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out ${
                  !selectClient && "pointer-events-none"
                }`}
                disabled={!selectClient}
                onClick={() => {
                  if (selectClient) {
                    handleCustom();
                  }
                }}
              >
                <span className="relative drop-shadow-Texts z-10">
                  Go To Builder
                </span>
              </button>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsOpenSelectClientModal(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default SelectClientModal;
