import { Field } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTagColor } from "../../utils/getTagColor";
import { Select } from "../Inputs/CreatableSelect";
import ButtonLoader from "../Loaders/ButtonLoader";
import CustomErrorMessage from "./CustomErrorMessage";
import {
  RemoverUserCategoryAction,
  AddUserCategoryAction,
} from "../../store/actions/authAction";

function Step1({
  active,
  complete,
  setStep,
  values,
  setFieldValue,
  isSubmitting,
}) {
  const modalColor = "Pink";
  const user = useSelector((state) => state.auth.user);
  const [categoryTags, setCategoryTags] = useState([]);
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    let uniqueCategory = [];

    if (user?.category?.length) {
      const userCategory = user?.category?.map((item) => {
        return { value: item, label: item };
      });
      uniqueCategory = [...userCategory, ...uniqueCategory];
    }

    uniqueCategory = uniqueCategory.reduce((acc, current) => {
      const x = acc.find(
        (item) => item?.value?.toLowerCase() === current?.value?.toLowerCase()
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const optionsWithColors = uniqueCategory.map((option, i) => {
      const { bgColor: optionColor, text: optionText } = getTagColor(
        modalColor,
        i,
        true
      );

      const { bgColor: labelColor, text: labelText } = getTagColor(
        modalColor,
        i,
        true
      );

      return {
        ...option,
        color: optionColor,
        text: optionText,
        labelColor,
        labelText,
      };
    });
    setCategoryTags(optionsWithColors);
  }, [user, clientDetail]);

  const handleRemove = async (data) => {
    const removedCategory = await dispatch(RemoverUserCategoryAction(data));

    if (removedCategory?.data) {
      setCategoryTags(categoryTags.filter((e) => e !== data));
    }
  };

  const handleCreateNewTag = async (inputValue) => {
    await dispatch(AddUserCategoryAction(inputValue));
  };

  return (
    <div
      className={`bg-neutral-50 p-4 rounded-lg w-full step flex ${
        active
          ? "h-full flex-col items-start justify-start"
          : "h-14 items-center"
      }
      `}
    >
      <div
        className={`flex-col justify-center items-start gap-0.5 flex w-full ${
          !active && "cursor-pointer"
        }`}
        onClick={() => !active && setStep(1)}
      >
        <span className="flex items-center w-full gap-6 text-xs font-bold tracking-wide">
          <span
            className={`min-w-[40px] ${
              complete ? "text-emerald-400" : "text-indigo-500"
            } `}
          >
            STEP 1
          </span>
          {!active && (
            <span className="flex items-center justify-between w-full">
              <span className="text-xs font-bold text-zinc-500">
                Invoice Information
              </span>
              {!active && complete && (
                <span className="text-xs font-bold tracking-wide">
                  <span
                    className={`w-12 ${
                      complete ? "text-emerald-400" : "text-indigo-500"
                    }`}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="22" height="22" rx="11" fill="#42D6A6" />
                      <path
                        d="M16.6477 7.40332L9.20657 14.3613L5.82422 11.1986"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </span>
              )}
            </span>
          )}
        </span>
        <div
          className={`justify-start items-center gap-3 flex ${
            !active && "hidden"
          }`}
        >
          <div className="text-base font-bold tracking-wide text-zinc-800">
            Invoice Information
          </div>
        </div>
      </div>
      <div
        className={`justify-start items-start gap-6 flex flex-col mt-5 ${
          !active && "hidden"
        }`}
      >
        <div className="flex flex-col items-start justify-start gap-6 md:flex-row">
          {/* INVOICE NAME */}
          <div className="w-full">
            <label
              htmlFor="invoiceName"
              className="block mb-2 text-xs font-bold text-zinc-800"
            >
              Invoice Name
            </label>
            <Field
              name="invoiceName"
              type="text"
              id="invoiceName"
              className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded-lg outline-none text-neutral-500 md:w-80"
              placeholder="Eg. Web Design Proposal"
            />
            <CustomErrorMessage name="invoiceName" />
          </div>
          {/* INVOICE NAME */}

          {/* CATEGORY TAGS */}
          <div className="w-full">
            <label
              htmlFor="categoryTags"
              className="block mb-2 text-xs font-bold text-zinc-800"
            >
              Category Tags
            </label>
            <Select
              id="categoryTags"
              name="categoryTags"
              isMulti
              isSearchable
              isClearable={true}
              options={categoryTags}
              value={values?.categoryTags}
              handleCreateNewTag={handleCreateNewTag}
              handleRemove={handleRemove}
              {...{ setFieldValue, modalColor }}
            />
            <CustomErrorMessage name="categoryTags" />
          </div>
          {/* CATEGORY TAGS */}
        </div>
        <div className="flex items-start justify-start w-full gap-6">
          <button
            className={`w-full md:w-80 h-12 bg-indigo-500  rounded flex-col justify-center items-center gap-2 flex hover:bg-indigo-600 text-neutral-50`}
            disabled={isSubmitting}
            type="submit"
          >
            <div className="w-full text-sm font-bold leading-none text-center">
              {isSubmitting ? <ButtonLoader /> : "Next"}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Step1;
