import React, { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import Close from "../../assets/Images/Close-gray.svg";
import { useDropzone } from "react-dropzone";
import { debounce } from "lodash";

const baseStyle = {
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#EDEDF2",
};

const acceptStyle = {
  opacity: 1,
  borderColor: "#07bc0c",
};

const rejectStyle = {
  borderColor: "#e74c3c",
};

const UploadQuestionnaireFiles = ({
  label,
  id,
  name,
  icon,
  handleChange,
  value,
  type,
  accept,
  multiple,
  files,
  files_path,
  handleSubmit,
}) => {
  const handleFileSelect = (e) => {
    if (type === "profile" || type === "image") {
      if (e.target.files && e.target.files.length) {
        let temp = Array.from(e.target.files);
        if (temp) {
          let onlySmallFiles = temp.filter((file) => file.size <= 1024 * 1024);
          handleChange(onlySmallFiles);

          if (e.target.files.length > onlySmallFiles.length) {
            toast.error(
              `${
                type === "profile" ? "Profile Image" : "Image"
              } size should be less then 1MB`
            );
          } else {
            handleSubmit(onlySmallFiles);
          }
        } else {
          handleSubmit([]);
        }
      } else {
        handleSubmit([]);
      }
    }
    if (type === "files" || type === "file") {
      if (e.target.files && e.target.files.length) {
        let temp = Array.from(e.target.files);
        if (temp) {
          let onlySmallFiles = temp.filter(
            (file) => file.size <= 1024 * 1024 * 25
          );
          handleChange(onlySmallFiles);
          if (e.target.files.length > onlySmallFiles.length) {
            toast.error("File size should be less then 25MB");
          } else {
            handleSubmit(onlySmallFiles);
          }
        } else {
          handleSubmit([]);
        }
      } else {
        handleSubmit([]);
      }
    }
  };

  const removeFile = (fileIndex) => {
    const newList = value.filter((e, index) => index !== fileIndex);
    handleChange(newList);
  };

  const fileNameExtract = (filename) => {
    let slicedList = filename.split("/");
    return slicedList[slicedList.length - 1];
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    accept:
      type === "profile" || type === "image" ? { "image/*": [accept] } : accept,
    multiple: multiple,
  });

  useEffect(() => {
    const tempDragFile = [];
    acceptedFiles.map((file) => {
      tempDragFile.push(file);
    });
    if (tempDragFile?.length) {
      const e = { target: { files: tempDragFile } };
      handleFileSelect(e);
    }
  }, [acceptedFiles]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <div className="relative">
        {value && value.length && files && !files.length ? (
          <>
            {type === "profile" || type === "image" ? (
              <div className="border-[1px] rounded p-2 px-3 border-Neutral400 hover:border-Neutral800 border-dashed flex justify-between w-[300px]">
                <span className="flex items-center">
                  <img
                    src={URL.createObjectURL(value[0])}
                    alt=""
                    height={150}
                    width={100}
                  />
                  <p
                    className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden`}
                  >
                    {value[0].name}
                  </p>
                </span>
                <img
                  className="cursor-pointer"
                  src={Close}
                  alt=""
                  onClick={() => handleChange(undefined)}
                />
              </div>
            ) : null}
            {(type === "files" || type === "file") && value
              ? value.length &&
                value.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="border-[1px] rounded p-2 px-3 m-1 border-Neutral400 hover:border-Neutral800 border-dashed flex justify-between w-[300px]"
                    >
                      <span className="flex items-center">
                        {/* <img
                        src={URL.createObjectURL(value[0])}
                        alt=""
                        height={150}
                        width={100}
                      /> */}
                        <p
                          className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden`}
                        >
                          {item.name}
                        </p>
                      </span>
                      <img
                        className="cursor-pointer"
                        src={Close}
                        alt=""
                        onClick={() => removeFile(index)}
                      />
                    </div>
                  );
                })
              : null}
          </>
        ) : null}
        {(!value || !value.length) && files && !files.length ? (
          <>
            <label
              htmlFor={id}
              {...getRootProps({ style })}
              className={`${style} placeholder:text-Neutral500 p-2 px-3 text-sm leading-5 w-full focus-visible:outline-none border-[1px] rounded border-Neutral400 bg-Neutral100 transition duration-300 border-dashed inline-block cursor-pointer`}
            >
              <input {...getInputProps()} />
              <span className="flex items-center">
                <img src={icon} alt="" />
                <p className="text-Neutral600 ml-2 text-xs font-medium">
                  {label}
                </p>
              </span>
            </label>
            <input
              id={id}
              className="hidden"
              name={name}
              type="file"
              multiple={multiple}
              accept={accept}
              placeholder="Eg. very_good_password"
              onChange={handleFileSelect}
            />
          </>
        ) : null}
        {!value && files && files.length ? (
          <>
            {type === "profile" || type === "image"
              ? files_path.map((item, index) => {
                  return (
                    <a
                      key={index}
                      href={item}
                      target="_blank"
                      className="border-[1px] rounded p-2 px-3 border-Neutral400 hover:border-Neutral800 border-dashed flex justify-between w-[300px]"
                    >
                      <span className="flex items-center">
                        <img src={item} alt="" height={150} width={100} />
                        <p
                          className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden`}
                        >
                          {fileNameExtract(files[index])}
                        </p>
                      </span>
                    </a>
                  );
                })
              : null}
            {(type === "files" || type === "file") &&
              files_path.map((item, index) => {
                return (
                  <a
                    key={index}
                    href={item}
                    target="_blank"
                    className="border-[1px] rounded p-2 px-3 m-1 border-Neutral400 hover:border-Neutral800 border-dashed flex justify-between w-[300px]"
                  >
                    <span className="flex items-center">
                      <p
                        className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden`}
                      >
                        {fileNameExtract(files[index])}
                      </p>
                    </span>
                    {/* <img
                      className="cursor-pointer"
                      src={Close}
                      alt=""
                      // onClick={() => removeFile(index)}
                    /> */}
                  </a>
                );
              })}
          </>
        ) : null}
      </div>
    </>
  );
};

export default UploadQuestionnaireFiles;
