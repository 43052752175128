import React, { useState, useEffect } from "react";
import { GetServicesActions } from "../../store/actions/serviceAction";
import { GetClientDetailAction } from "../../store/actions/clientAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import TaskListLoader from "../../components/ContentLoaders/TaskListLoader";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import CommanTaskListingNew from "../../components/comman/CommanTaskListingNew";

const ClientsServices = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { clientId } = useParams();
  const serviceList = useSelector((state) => state.service.all_services);
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const [navBarText, setNavbarText] = useState("Back");
  const [isLoading, setIsLoading] = useState(false);
  const [serviceByClient, setServiceByClient] = useState([]);

  useEffect(() => {
    if (
      Object.values(serviceList).filter((ele) => ele.client._id === clientId)
        .length === 0
    ) {
      setIsLoading(true);
      dispatch(GetServicesActions(clientId, false, setIsLoading));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (state) {
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: {
          isLoading: false,
          isUpdating: false,
          data: state?._id ? state : state.client,
        },
      });
    } else {
      if (clientId !== clientDetail.data._id) {
        dispatch(GetClientDetailAction(clientId, navigate));
      }
    }
  }, [clientId, dispatch, navigate]);

  useEffect(() => {
    if (clientId !== clientDetail.data._id) {
      setNavbarText("Back");
    } else {
      setNavbarText(
        `Back to ${clientDetail?.data?.business_name || clientDetail?.data?.contact_name
        } Dashboard`
      );
    }
  }, [clientDetail]);

  useEffect(() => {
    if (Object.keys(serviceList).length) {
      setServiceByClient(
        Object.values(serviceList)?.filter((ele) => ele.client._id === clientId)
      );
    }
  }, [serviceList]);

  return (
    <>
      <AdminNavbar
        link={`/admin/clients/manage/${clientId}`}
        title={"Service"}
        backLink={navBarText}
      />
      {isLoading ? (
        <TaskListLoader />
      ) : (
        <div className="mt-5 md:mt-[40px] px-5 lg:px-12">
          <CommanTaskListingNew
            clientId={clientId}
            allTaskList={serviceByClient}
            clientDetail={clientDetail?.data}
            types="service"
            isAll={false}
          />{" "}
        </div>
      )}
    </>
  );
};

export default ClientsServices;
