import React, { useState, Fragment, useRef } from "react";
import Modal from "react-modal";
import CloseBtn from "../../../assets/Images/close-btn.svg";
import { Menu, Transition } from "@headlessui/react";
import {
  ShareProjectAction,
  ShareProjectRemoveAccessAction,
  ShareProjectChangeAccessAction,
} from "../../../store/actions/projectAction";
import { useDispatch } from "react-redux";

// Dummy data for current access
const currentAccessData = [
  {
    name: "Gretchen Siphron",
    email: "siphon_gretchen@mail.com",
    accessType: "Full Access",
  },
  { name: "Kierra Press", email: "kp@mail.com", accessType: "View Only" },
  {
    name: "Ahmad Saris",
    email: "ahmadsaris@mail.com",
    accessType: "View Only",
  },
  // Add more as needed...
];

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
    overflow: "none",
  },
};

const ShareProjectModal = ({ isOpen, setIsOpen, project }) => {
  const [email, setEmail] = useState("");
  const [access, setAccess] = useState("view_only");
  const accessref = useRef(null);
  const currentAccessref = useRef(null);
  const [sharing, setSharing] = useState(false);
  const dispatch = useDispatch();
  const [detailModalIsOpen, setDetailModalIsOpen] = useState(false);

  const shareProject = async () => {
    setSharing(true);
    await dispatch(
      ShareProjectAction(
        {
          email: email,
          access_level: access,
          first_name: "",
          project: project?._id,
          client: project?.client?._id,
        },
        setIsOpen
      )
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp max-w-[500px]">
          {/* Close Button */}

          <div
            className="absolute right-0 top-[-40px] z-30 cursor-pointer"
            onClick={() => setIsOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>

          <div className="p-6 overflow-y-auto overflow-x-hidden">
            <h3 className="text-xl font-semibold text-gray-900">
              Share {project?.title}
            </h3>
            <h6 className="font-medium text-xs text-Neutral700 tracking-[0.04em] pt-2 mb-6">
              Share this project with a client or contractor and collab together on tasks to get things done (without the fluff).
            </h6>
            <div className="mb-8">
              <div className="flex items-center">
                <div className="relative flex p-3 mr-2 border-[1.5px] w-[320px] border-Neutral300 rounded-md hover:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition">
                  <input
                    className="text-Neutral800 font-medium placeholder:text-Neutral500 pr-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none "
                    type="text"
                    placeholder="Add email..."
                    name="contact_person_job_title"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <Menu as="div" className="relative flex text-left">
                    {({ open }) => (
                      <>
                        <Menu.Button
                          ref={accessref}
                          className="focus-visible:outline-none relative rounded-[4px]"
                        >
                          <div
                            className={`flex items-center text-Neutral600   px-[12px] rounded-[4px]`}
                          >
                            <span className="whitespace-nowrap text-[12px] font-medium overflow-hidden mr-1">
                              {access === "view_only"
                                ? "View Access"
                                : "Edit Access"}
                            </span>
                            {open ? (
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3 7.50003L6 4.50003L9 7.50003"
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 4.49997L6 7.49997L3 4.49997"
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right max-h-[241px] z-[2] fixed rounded-[4px] dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 focus:outline-none overflow-auto cursor-pointer p-2 w-[105px]">
                            <div className="bg-white">
                              <div>
                                <div
                                  className="flex items-center rounded-[2px]   hover:bg-Neutral200 cursor-pointer"
                                  onClick={() => {
                                    setAccess("view_only");
                                    accessref?.current?.click();
                                  }}
                                >
                                  <p className="text-Neutral900 text-[12px] p-[8px] font-medium">
                                    View Access
                                  </p>
                                </div>
                                <div
                                  className="flex items-center rounded-[2px]  p-[8px] hover:bg-Neutral200 cursor-pointer"
                                  onClick={() => {
                                    setAccess("full_access");
                                    accessref?.current?.click();
                                  }}
                                >
                                  <p className="text-Neutral900 text-[12px]  font-medium">
                                    Edit Access
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                  <button
                    type="button"
                    onClick={() => setDetailModalIsOpen(true)}
                    className="text-[#060505] text-[10px] font-semibold ml-2 focus-visible:outline-none"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.3334 7.2042C7.5528 6.58051 7.98586 6.05459 8.55586 5.7196C9.12586 5.3846 9.79603 5.26215 10.4477 5.37392C11.0993 5.48569 11.6904 5.82448 12.1161 6.33028C12.5419 6.83608 12.775 7.47625 12.774 8.1374C12.774 10.0038 9.97437 10.937 9.97437 10.937V12.0003"
                        stroke="#9C9CA6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.0489 14.6702H10.0576"
                        stroke="#9C9CA6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="19"
                        height="19"
                        rx="9.5"
                        stroke="#C2C2CC"
                      />
                    </svg>
                  </button>
                </div>
                <button
                  type="button"
                  className="text-[14px] inline-flex items-center p-3 border border-transparent text-sm leading-4 font-medium rounded-md text-Neutral100 bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                  onClick={shareProject}
                  disabled={sharing}
                >
                  {sharing ? "Inviting " : "Invite"}
                </button>
              </div>
            </div>


            {project?.project_members?.length > 0 ? (
              <div className="border-t border-gray-200 pt-4 ">
                <h4 className="text-[12px] font-medium text-Neutral500 uppercase mb-2">
                  Current Access
                </h4>
                {project?.project_members?.map((user, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center mb-2"
                  >
                    <ul className="mt-2">
                      {/* Map through accessList here */}
                      <li className="flex items-center justify-between py-2">
                        <div className="flex gap-3 w-[300px]">
                          <span className="text-[12px] font-bold text-Neutral800">
                            {user?.first_name}
                          </span>
                          <span className="text-[12px] text-Neutral600">
                            {user?.email}
                          </span>
                        </div>
                        <Menu
                          as="div"
                          className="absolute right-[10px] inline-block text-left"
                        >
                          {({ open }) => (
                            <>
                              <Menu.Button
                                ref={currentAccessref}
                                className="focus-visible:outline-none relative rounded-[4px]"
                              >
                                <div
                                  className={`flex items-center text-Neutral600 z-1  px-[12px] rounded-[4px]`}
                                >
                                  <span className="whitespace-nowrap text-[12px] font-medium overflow-hidden mr-1">
                                    {user?.access_level === "view_only"
                                      ? "View Access"
                                      : "Edit Access"}
                                  </span>
                                  {open ? (
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3 7.50003L6 4.50003L9 7.50003"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9 4.49997L6 7.49997L3 4.49997"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </Menu.Button>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="origin-top-right max-h-[241px] z-[99999] absolute right-[8px] rounded-[4px] dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 focus:outline-none overflow-auto cursor-pointer p-2 w-full">
                                  <div className="bg-white">
                                    <div>
                                      <div
                                        className="flex items-center rounded-[2px]   hover:bg-Neutral200 cursor-pointer"
                                        onClick={() => {
                                          dispatch(
                                            ShareProjectChangeAccessAction({
                                              access_level: "view_only",
                                              project: project?._id,
                                              project_member_id: user?._id,
                                            })
                                          );
                                          setTimeout(() => {
                                            currentAccessref?.current?.click();
                                          }, 0);
                                        }}
                                      >
                                        <p className="text-Neutral900 text-[12px] p-[8px] font-medium">
                                          View Access
                                        </p>
                                      </div>
                                      <div
                                        className="flex items-center rounded-[2px]  p-[8px] hover:bg-Neutral200 cursor-pointer"
                                        onClick={() => {
                                          dispatch(
                                            ShareProjectChangeAccessAction({
                                              access_level: "full_access",
                                              project: project?._id,
                                              project_member_id: user?._id,
                                            })
                                          );
                                          setTimeout(() => {
                                            currentAccessref?.current?.click();
                                          }, 0);
                                        }}
                                      >
                                        <p className="text-Neutral900 text-[12px]  font-medium">
                                          Edit Access
                                        </p>
                                      </div>
                                      <div
                                        className="flex items-center rounded-[2px]  p-[8px] hover:bg-[#FFF0F3] cursor-pointer"
                                        onClick={() => {
                                          dispatch(
                                            ShareProjectRemoveAccessAction({
                                              id: project?._id,
                                              member_id: user?._id,
                                            })
                                          );
                                          setTimeout(() => {
                                            currentAccessref?.current?.click();
                                          }, 0);
                                        }}
                                      >
                                        <p className="text-[#E0526E] text-[12px]  font-medium">
                                          Remove
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu>
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            ) : null}


          </div>
        </div>

      </Modal>
      <Modal
        isOpen={detailModalIsOpen}
        onRequestClose={() => setDetailModalIsOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Archived client list Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[455px] sm:min-w-[455px]  max-w-[455px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white p-[24px_32px_30px_32px] rounded-[8px]">
            <h6 className="uppercase font-medium text-xs text-Neutral700 tracking-[0.04em] pb-2 border-b-[0.56px] border-Neutral300 w-full">
              how it works
            </h6>
            <div className="flex justify-between pt-6 pb-5 border-b-[0.5px] border-Neutral300 gap-3">
              <div className="w-full">
                <h3 className="text-Neutral900 font-bold text-xs uppercase">
                  EDIT ACCESS
                </h3>
              </div>
              <div className="md:min-w-[225px]">
                <p className="text-right text-xs font-normal">
                  User can add and edit tasks (but not delete your own).
                </p>
              </div>
            </div>
            <div className="flex justify-between pt-6 pb-5 border-b-[0.5px] border-Neutral300 gap-3">
              <div className="w-full">
                <h3 className="text-Neutral900 font-bold text-xs uppercase">
                  VIEW ACCESS
                </h3>
              </div>
              <div className="md:min-w-[225px]">
                <p className="text-right text-xs font-normal">
                  User can only view tasks and project information.

                </p>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setDetailModalIsOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShareProjectModal;
