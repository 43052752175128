import { useDispatch } from "react-redux";
import Modal from "react-modal";
import SelectDownArrow from "../Icons/SelectDownArrow";
import { exportTimeEntryInCSV } from "../../store/actions/timeTrackingAction";

import CloseBtn from "../../assets/Images/close-btn.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import ButtonLoader from "../Loaders/ButtonLoader";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

// const durations = [
//   { label: "Daily", value: "daily" },
//   { label: "Weekly", value: "weekly" },
//   {
//     label: "Monthly",
//     value: "monthly",
//   },
//   {
//     label: "Custom",
//     value: "custom",
//   },
// ];

const ExportTimeEntryModal = ({ modalIsOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const [startData, setStartData] = useState();

  const assignTimeValidation = Yup.object().shape({
    // duration: Yup.string().required("Choose duration"),
    startDate: Yup.string().required("Select Start Date"),
    endDate: Yup.string().required("Select End Date"),
  });

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        setIsOpen(false);
        setStartData({});
      }}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Example Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-Neutral000 px-6 xs:px-12 pt-8 pb-10  rounded-[8px]">
          <div>
            <div className="text-Neutral700 text-xs font-semibold pb-2 border-b border-Neutral200">
              Export Duration
            </div>
            <Formik
              initialValues={{
                // duration: "",
                startDate: "",
                endDate: "",
              }}
              enableReinitialize
              validationSchema={assignTimeValidation}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                dispatch(exportTimeEntryInCSV(values, setSubmitting,setIsOpen));
                setStartData({});
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form>
                  {/* <div className="mt-8">
                    <label className="text-Neutral900 font-bold text-xs mb-2 inline-block">
                      Choose Duration
                    </label>
                    <div className="relative">
                      <span className="absolute pointer-events-none z-[9] top-[50%] translate-y-[-50%] right-[16px]">
                        <SelectDownArrow />
                      </span>
                      <select
                        onChange={(e) => {
                          handleChange(e);
                          setCustomHandel(e.target.value);
                        }}
                        onBlur={handleBlur}
                        name="duration"
                        className="text-Neutral800 bg-Neutral200 font-medium placeholder:text-Neutral500 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded border-transparent hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition"
                      >
                        <option disabled selected>
                          Select Duration
                        </option>
                        {durations?.map((item, i) => {
                          return (
                            <option value={item?.value} key={i}>
                              {item?.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {errors?.duration && touched?.duration && (
                      <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                        {errors?.duration}
                      </p>
                    )}
                  </div> */}
                  {/* {customHandel === "custom" && (
                    <> */}
                  <div className="mt-5">
                    <label
                      for="date"
                      className="text-Neutral900 font-bold text-xs mb-2 inline-block w-full text-left"
                    >
                      Start Date
                    </label>
                    <div className="relative">
                      <input
                        value={values.startDate}
                        type="date"
                        name="startDate"
                        onChange={(e) => {
                          handleChange(e);
                          setStartData(e.target.value);
                        }}
                        onBlur={handleBlur}
                        className="relative text-Neutral800 placeholder:font-medium px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow appearance-none duration-300 transition border-Neutral300"
                        placeholder="Eg. Brand Building"
                      />
                    </div>
                    {errors?.startDate && touched?.startDate && (
                      <p className="mt-1  text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                        {errors?.startDate}
                      </p>
                    )}
                  </div>
                  <div className="mt-5 ">
                    <label
                      for="date"
                      className="text-Neutral900 font-bold text-xs mb-2 inline-block w-full text-left"
                    >
                      End Date
                    </label>
                    <div className="relative">
                      <input
                        value={values.endDate}
                        type="date"
                        name="endDate"
                        min={startData}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="relative text-Neutral800 placeholder:font-medium px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow appearance-none duration-300 transition border-Neutral300"
                        placeholder="Eg. Brand Building"
                      />
                    </div>
                    {errors?.endDate && touched?.endDate && (
                      <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                        {errors?.endDate}
                      </p>
                    )}
                  </div>
                  {/* </>
                  )} */}

                  <div className="mt-10">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                    >
                      {isSubmitting ? (
                        <ButtonLoader />
                      ) : (
                        <span className="relative z-10">Export</span>
                      )}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => {
            setIsOpen(false);
            setStartData({});
          }}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};
export default ExportTimeEntryModal;
