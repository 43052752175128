export default function CMLogo() {
  return (
    <svg
      width="102"
      height="102"
      viewBox="0 0 102 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="101.112" height="101.112" rx="16" fill="#6D6DF2" />
      <g filter="url(#filter0_d_55_798)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.9642 21.0643C46.9642 19.071 48.5802 17.455 50.5735 17.455C52.5669 17.455 54.1828 19.071 54.1828 21.0643V35.1969C61.0799 36.8268 66.2124 43.0232 66.2124 50.4183C66.2124 57.8152 61.0774 64.0128 54.1777 65.6409L54.1777 79.295C54.1777 81.2884 52.5618 82.9043 50.5684 82.9043C48.5751 82.9043 46.9591 81.2884 46.9591 79.295L46.9591 65.6392C40.0631 64.0085 34.9318 57.8126 34.9318 50.4183C34.9318 43.0221 40.0656 36.825 46.9642 35.1962V21.0643Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_55_798"
          x="30.3276"
          y="14.0019"
          width="40.4889"
          height="74.6576"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.15104" />
          <feGaussianBlur stdDeviation="2.30209" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_55_798"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_55_798"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
