import React, { Fragment, useEffect, useRef, useState } from "react";
import CloseBtn from "../../assets/Images/close-btn.svg";
import Modal from "react-modal";
import { Menu, Transition } from "@headlessui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  AssignTeamMemberClientAction,
  AssignTeamMemberUpdateClientAction,
} from "../../store/actions/clientAction";
import { useNavigate } from "react-router-dom";
import TeamMemberDetailsModal from "./TeamMembersDetailsModal";
import ReactTooltip from "react-tooltip";
import { currencyList } from "../../assets/json/currencyList";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const AssignTeamMemberModal = ({
  setEditNotIsOpen,
  editIsOpen,
  member,
  clientId,
  editMember,
  setCreateMemberModal,
  clientDetail,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nameref = useRef(null);
  const roleref = useRef(null);
  const currencyref = useRef(null);

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [projectManagerEnable, setProjectManagerEnable] = useState(false);

  const assignMemberValidation = Yup.object().shape({
    member: Yup.object().required("Choose Member name"),
    role: Yup.string().required("Choose Member role"),
    currency_required: Yup.boolean(),
    currency: Yup.object().when("currency_required", {
      is: true,
      then: Yup.object().required("Currency required"),
      otherwise: Yup.object().notRequired().nullable(),
    }),
    rate: Yup.string(),
  });

  const detailOfClient = useSelector((state) => state.client.clientDetail);

  useEffect(() => {
    if (detailOfClient) {
      const getProjectManager = detailOfClient?.data?.members?.find(
        (item) => item?.detail?.role === "project_manager"
      );
      setProjectManagerEnable(Boolean(getProjectManager));
    }
  }, [detailOfClient]);
  console.log("editMember", editMember);
  return (
    <>
      <Modal
        isOpen={editIsOpen}
        onRequestClose={setEditNotIsOpen}
        closeTimeoutMS={300}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <div>
              <div className="text-left">
                <div>
                  <h3
                    className="flex text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                    id="modal-title"
                  >
                    <span>ASSIGN TEAM MEMBER</span>
                    <button
                      onClick={() => setInfoModalOpen(true)}
                      className="text-[#060505] text-[10px] font-semibold ml-2"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.3334 7.2042C7.5528 6.58051 7.98586 6.05459 8.55586 5.7196C9.12586 5.3846 9.79603 5.26215 10.4477 5.37392C11.0993 5.48569 11.6904 5.82448 12.1161 6.33028C12.5419 6.83608 12.775 7.47625 12.774 8.1374C12.774 10.0038 9.97437 10.937 9.97437 10.937V12.0003"
                          stroke="#9C9CA6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.0489 14.6702H10.0576"
                          stroke="#9C9CA6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="19"
                          height="19"
                          rx="9.5"
                          stroke="#C2C2CC"
                        />
                      </svg>
                    </button>
                  </h3>
                </div>
                <Formik
                  initialValues={{
                    member: editMember?.user ? editMember?.user : "",
                    role: editMember?.detail?.role
                      ? editMember?.detail?.role === "general"
                        ? "General"
                        : editMember?.detail?.role === "admin"
                        ? "Admin"
                        : "Project Manager"
                      : "",
                    rate: editMember?.detail?.rate
                      ? editMember?.detail?.rate
                      : "",
                    currency: editMember?.detail?.currency?.name
                      ? editMember?.detail?.currency
                      : clientDetail?.currency?.name
                      ? clientDetail?.currency
                      : currencyList[0],
                    currency_required: editMember?.detail?.currency?.name
                      ? false
                      : clientDetail?.currency?.name
                      ? false
                      : true,
                  }}
                  enableReinitialize
                  validationSchema={assignMemberValidation}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    // if (!values?.member?.verified_at) {
                    //   setErrors({
                    //     member: `If you're wanting to assign ${
                    //       values?.member?.member?.user_name ||
                    //       values?.member?.user_name
                    //     }, they have to first accept the invite link.`,
                    //   });
                    //   setSubmitting(false);
                    //   return;
                    // }
                    values = {
                      client: clientId,
                      member: !editMember
                        ? values?.member?.member?._id || values?.member?._id
                        : editMember?.user?._id,
                      role:
                        editMember?.detail?.role === "admin"
                          ? "admin"
                          : values?.role === "General"
                          ? "general"
                          : "project_manager",
                      currency: values?.currency,
                      rate: values?.rate,
                    };
                    if (editMember) {
                      dispatch(
                        AssignTeamMemberUpdateClientAction(
                          values,
                          setSubmitting,
                          setErrors,
                          setEditNotIsOpen,
                          navigate
                        )
                      );
                    } else {
                      dispatch(
                        AssignTeamMemberClientAction(
                          values,
                          setSubmitting,
                          setErrors,
                          setEditNotIsOpen,
                          navigate
                        )
                      );
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {console.log("valuesvalues", values)}
                      <div className="mb-8">
                        <label className="text-Neutral900 font-bold text-xs">
                          Name
                        </label>
                        <div className="mt-2  z-[100] relative">
                          <Menu
                            as="div"
                            className="relative inline-block text-left w-full"
                          >
                            <Menu.Button
                              disabled={editMember?.user?.user_name}
                              ref={nameref}
                              className="rounded-[4px] client-name bg-Neutral200 inline-flex w-full focus-visible:outline-none popup-btn relative px-[16px] pb-[10px] pt-[10px]"
                            >
                              <span className="focus-visible:outline-none">
                                <div className="members-text flex flex-wrap text-[14px] items-center text-Neutral800 font-medium">
                                  {values?.member
                                    ? values?.member?.member?.user_name ||
                                      values?.member?.user_name
                                    : "Select Member..."}
                                  <svg
                                    className="absolute right-[16px]"
                                    width="11"
                                    height="8"
                                    viewBox="0 0 11 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                      fill="#5A5A66"
                                    />
                                  </svg>
                                </div>
                              </span>
                            </Menu.Button>
                            {errors?.member && (
                              <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                {errors?.member}
                              </p>
                            )}
                            <Transition as={Fragment}>
                              <Menu.Items className="box shadow-[0px_30px_40px_rgba(0,0,0,0.2)] z-50 absolute top-[41px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 rounded-[0px_0px_4px_4px]">
                                <div className="bg-white">
                                  <p className="text-Neutral600 text-[10px] font-semibold pt-[9px] pb-[12px] border-t border-Neutral200">
                                    TEAM MEMBER
                                  </p>
                                  <div>
                                    {member?.data?.map((item) => {
                                      return (
                                        <div
                                          key={item?._id}
                                          onClick={() => {
                                            setFieldValue("member", item);
                                            setTimeout(() => {
                                              nameref?.current?.click();
                                            }, 0);
                                          }}
                                          onBlur={handleBlur}
                                          className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                        >
                                          <p className="text-Neutral800 text-sm font-normal">
                                            {item?.member?.user_name ||
                                              item?.user_name}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                      <div className="mb-8">
                        <label className="text-Neutral900 font-bold text-xs">
                          Role
                        </label>
                        <div className="mt-2 z-[99] relative">
                          <Menu
                            as="div"
                            className="relative inline-block text-left w-full"
                          >
                            <Menu.Button
                              ref={roleref}
                              className="rounded-[4px] client-name bg-Neutral200 inline-flex w-full focus-visible:outline-none popup-btn relative px-[16px] pb-[10px] pt-[10px]"
                              disabled={editMember?.detail?.role === "admin"}
                            >
                              <span className="focus-visible:outline-none">
                                <div className="members-text flex flex-wrap text-[14px] items-center text-Neutral800 font-medium">
                                  {values?.role
                                    ? values?.role
                                    : "Select role..."}
                                  <svg
                                    className="absolute right-[16px]"
                                    width="11"
                                    height="8"
                                    viewBox="0 0 11 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                      fill="#5A5A66"
                                    />
                                  </svg>
                                </div>
                              </span>
                            </Menu.Button>
                            {errors?.role && touched?.role && (
                              <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                {errors?.role}
                              </p>
                            )}
                            <Transition as={Fragment}>
                              <Menu.Items className="box shadow-[0px_30px_40px_rgba(0,0,0,0.2)] z-50 absolute top-[41px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 rounded-[0px_0px_4px_4px]">
                                <div className="bg-white">
                                  <p className="text-Neutral600 text-[10px] font-semibold pt-[9px] pb-[12px] border-t border-Neutral200">
                                    Choose Role
                                  </p>
                                  <div>
                                    <div
                                      className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                      onClick={() => {
                                        setFieldValue("role", "General");
                                        setTimeout(() => {
                                          roleref?.current?.click();
                                        }, 0);
                                      }}
                                      onBlur={handleBlur}
                                    >
                                      <p className="text-Neutral800 text-sm font-normal">
                                        General
                                      </p>
                                    </div>
                                    <div
                                      className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                      onClick={() => {
                                        if (!projectManagerEnable) {
                                          setFieldValue(
                                            "role",
                                            "Project Manager"
                                          );
                                          setTimeout(() => {
                                            roleref?.current?.click();
                                          }, 0);
                                        }
                                      }}
                                      data-for="project_manager"
                                      data-tip={
                                        projectManagerEnable
                                          ? "A Project Manager has already been assigned."
                                          : ""
                                      }
                                      onBlur={handleBlur}
                                      disabled={projectManagerEnable}
                                    >
                                      <p
                                        className={`${
                                          projectManagerEnable
                                            ? "text-Neutral500"
                                            : "text-Neutral800"
                                        } text-sm font-normal`}
                                      >
                                        Project Manager
                                      </p>
                                      <ReactTooltip
                                        place="bottom"
                                        textColor="#F5F5F7"
                                        className="tooltip-font-size"
                                        backgroundColor="#292933"
                                        id="project_manager"
                                      />
                                    </div>
                                    {editMember?.detail?.role === "admin" ? (
                                      <div
                                        className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                        onClick={() => {
                                          setFieldValue("role", "admin");
                                          setTimeout(() => {
                                            roleref?.current?.click();
                                          }, 0);
                                        }}
                                        onBlur={handleBlur}
                                      >
                                        <p className="text-Neutral800 text-sm font-normal">
                                          Admin
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                      <div className="mb-6 relative">
                        <label className="text-Neutral900 font-bold text-xs flex items-center justify-between">
                          Hourly Rate
                          <span className="tetx-Neutral700 italic text-xs font-medium">
                            Optional
                          </span>
                        </label>
                        <div className="mt-2 relative z-50">
                          <input
                            className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition pr-[100px]"
                            type="number"
                            inputMode="decimal"
                            placeholder="50"
                            value={values?.rate}
                            name="rate"
                            onChange={(e) => {
                              if (!isNaN(Number(e.target.value))) {
                                handleChange(e);
                              }
                            }}
                          />
                          <div className="absolute right-0 top-[50%] -translate-y-2/4">
                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <Menu.Button
                                ref={currencyref}
                                className={`${
                                  !clientDetail?.currency?.name && "hourly-rate"
                                } focus-visible:outline-none popup-btn relative w-[89px] h-[36px] p-[4px_8px] mr-2 rounded-[4px] hover:bg-Neutral300`}
                              >
                                <div
                                  className={`flex items-center text-Neutral700 text-sm font-medium justify-between ${
                                    !clientDetail?.currency?.name &&
                                    "justify-center"
                                  }`}
                                >
                                  <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                                    {values?.currency
                                      ? `${values?.currency?.short_name} - ${values?.currency?.symbol}`
                                      : "USD - $"}
                                  </span>
                                  {!clientDetail?.currency?.name && (
                                    <svg
                                      width="11"
                                      height="8"
                                      viewBox="0 0 11 8"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                        fill="#5A5A66"
                                      ></path>
                                    </svg>
                                  )}
                                </div>
                              </Menu.Button>
                              {/* {!clientDetail?.currency?.name && ( */}
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="origin-top-right max-h-[241px] z-[999999] absolute top-[42px] right-[4px] rounded-[8px] dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 focus:outline-none overflow-auto cursor-pointer w-[180px] p-2">
                                  {currencyList
                                    ?.sort((a, b) =>
                                      a?.short_name?.localeCompare(
                                        b?.short_name
                                      )
                                    )
                                    ?.map((item) => {
                                      return (
                                        <div
                                          className="hover:bg-Neutral200 currency-hover rounded-[4px] py-2 px-2"
                                          key={item?.name}
                                          onClick={() => {
                                            setFieldValue("currency", item);
                                            setTimeout(() => {
                                              currencyref?.current?.click();
                                            }, 0);
                                          }}
                                        >
                                          <p className="text-Neutral800 font-normal text-xs group-hover:text-text-Neutral900 transition-all duration-300">
                                            {item?.name}
                                          </p>
                                          <p className="text-Neutral600 font-normal text-xs">
                                            {item?.short_name} - {item?.symbol}
                                          </p>
                                        </div>
                                      );
                                    })}
                                </Menu.Items>
                              </Transition>
                              {/* )} */}
                            </Menu>
                          </div>
                        </div>
                        {clientDetail?.currency?.name ? (
                          <p className="text-Neutral700 text-xs font-medium mt-2">
                            This will only be visible to you as Admin.
                          </p>
                        ) : (
                          <p className="text-Neutral700 text-xs font-medium mt-2 md:w-[293px]">
                            Currency selection is final and will be applied to
                            all team members on your plan. Choose carefully.
                          </p>
                        )}
                        {errors?.rate && touched?.rate && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                            {errors?.rate}
                          </p>
                        )}
                        {errors?.currency && touched?.currency && (
                          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                            {errors?.currency}
                          </p>
                        )}
                      </div>
                      <div className="mt-8 2xl:pb-0 md:pb-10 pb-10 mb-5">
                        <button
                          disabled={isSubmitting}
                          className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral000 hover:bg-AccentFMediumDark bg-AccentRegular font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                        >
                          {isSubmitting ? (
                            <span className="relative z-10 drop-shadow-Texts">
                              <svg
                                className="animate-spin m-auto h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </span>
                          ) : (
                            <span className="relative drop-shadow-Texts z-10">
                              Assign Team Member
                            </span>
                          )}
                        </button>
                      </div>{" "}
                    </form>
                  )}
                </Formik>
                <p className="text-Neutral700 text-xs font-medium mt-2">
                  Not what you’re looking for?
                </p>
                <p
                  className="text-AccentRegular text-xs font-medium cursor-pointer"
                  onClick={() => {
                    setCreateMemberModal(true);
                    setEditNotIsOpen(false);
                  }}
                >
                  Add team member to account plan first.
                </p>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setEditNotIsOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
      <TeamMemberDetailsModal
        modalIsOpen={infoModalOpen}
        setIsOpen={setInfoModalOpen}
      />
    </>
  );
};

export default AssignTeamMemberModal;
