const DeleteIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.23071 5.04395H4.33157H13.1384"
        stroke="#5A5A66"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.98209 5.04444V4.02222C5.98209 3.75111 6.09808 3.49111 6.30453 3.2994C6.51098 3.1077 6.79098 3 7.08295 3H9.28466C9.57662 3 9.85663 3.1077 10.0631 3.2994C10.2695 3.49111 10.3855 3.75111 10.3855 4.02222V5.04444M12.0368 5.04444V12.2C12.0368 12.4711 11.9208 12.7311 11.7144 12.9228C11.5079 13.1145 11.2279 13.2222 10.9359 13.2222H5.43167C5.1397 13.2222 4.85969 13.1145 4.65324 12.9228C4.44679 12.7311 4.33081 12.4711 4.33081 12.2V5.04444H12.0368Z"
        stroke="#5A5A66"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default DeleteIcon;
