import copy from "copy-to-clipboard";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import LinkCopied from "../../assets/Images/CheckmarkAccent.svg";
import LinkIcon from "../../assets/Images/LinkAccent.svg";
import { DeleteMeetingAction } from "../../store/actions/meetingAction";
import CalendarIcon from "../Icons/CalendarIcon";
import ClockIcon from "../Icons/ClockIcon";
import TimeTrackingIcon from "../Icons/newicons/_TimeTrackingIcon";
import CancelMeetingModal from "../Modals/CancelMeetingModal";

function MeetingCard({ parentMeetingId, meetingDetails }) {
  const { _id, attendee_name, date, time_slot, time, link, attendee_email } = meetingDetails;
  const [copyStatus, setCopyStatus] = useState("Copy Meeting Link");
  const [openCancelMeetingModal, setOpenCancelMeetingModal] = useState(false);
  const dispatch = useDispatch();

  console.log(meetingDetails);

  const handleDeleteMeeting = async (e) => {
    try {
      dispatch(DeleteMeetingAction(parentMeetingId, _id, setOpenCancelMeetingModal));
    } catch (error) {
      console.log("Error deleting meeting:", error);
    }
  };

  return (
    <>
      <div
        style={{ boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.08)" }}
        className="flex flex-col p-4 space-y-5 bg-white border rounded-xl"
      >
        <div className="flex justify-between">
          <div className="flex items-center space-x-3">
            <img
              src="https://clientmanager-documents-images.s3.amazonaws.com/profile/1672084410937.jpeg"
              alt="Profile"
              className="object-contain border rounded-full size-9 bg-Neutral200"
            />
            <div className="flex flex-col gap-0.5">
              <p className="font-medium">{attendee_name}</p>
              <p className="text-xs text-Neutral600">{attendee_email}</p>
            </div>
          </div>
          {link && (
            <span
              className="flex items-center justify-end h-8 mr-1 text-xs font-semibold rounded cursor-pointer text-AccentRegular"
              onClick={() => {
                setCopyStatus("Copied");
                copy(link);
                setTimeout(() => {
                  setCopyStatus("Copy Meeting Link");
                }, 1500);
              }}
              title={copyStatus}
              key={_id}
            >
              {copyStatus === "Copied" ? (
                <img src={LinkCopied} alt="" />
              ) : (
                <img src={LinkIcon} alt="" />
              )}
            </span>
          )}
        </div>

        <div className="flex items-center space-x-2">
          <div className="flex items-center justify-center flex-1 py-2 space-x-1 text-xs bg-gray-100 rounded">
            <CalendarIcon width={16} color="#6D6DF2" />
            <span>{moment(date).format("DD MMM")}</span>
          </div>
          <div className="flex items-center justify-center flex-1 py-2 space-x-1 text-xs bg-gray-100 rounded">
            <ClockIcon width={16} color="#6D6DF2" />
            <span>{time}</span>
          </div>
          <div className="flex items-center justify-center flex-1 py-2 space-x-1 text-xs bg-gray-100 rounded">
            <TimeTrackingIcon width={16} color="#6D6DF2" />
            <span>{time_slot.replace(/minutes?/i, "min")}</span>
          </div>
        </div>

        <button
          className="w-full text-xs py-3 border rounded-[4px] border-AccentMediumLight text-AccentRegular hover:text-red-600 hover:border-red-100"
          onClick={() => setOpenCancelMeetingModal(true)}
        >
          CANCEL MEETING
        </button>
      </div>

      <CancelMeetingModal
        meetingId={_id}
        isOpen={openCancelMeetingModal}
        setIsOpen={setOpenCancelMeetingModal}
        handleClick={handleDeleteMeeting}
      />
    </>
  );
}

export default MeetingCard;
