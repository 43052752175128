import React, { useRef, useState, Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { Menu, Transition } from "@headlessui/react";
import Checkmark from "../../assets/Images/Checkmark.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { useDispatch } from "react-redux";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  CreateMemberAction,
  getMemberList,
} from "../../store/actions/memberAction";
import BigShoesAvatar from "../../assets/Images/Big Shoes - Avatar 1.svg";
import TeamMemberDetailsModal from "./TeamMembersDetailsModal";
import { useSelector } from "react-redux";
import { getFullClientList } from "../../store/actions/clientAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const CreateMemberSchema = Yup.object().shape({
  client_name: Yup.string().required("Name Required"),
  email: Yup.string().email("Email is not valid").required("Email Required"),
  role: Yup.string().required("Choose Member role"),
  clients: Yup.array().when("assign_all_client", {
    is: false,
    then: Yup.array().min(1, "You must select at list one client"),
  }),
});

function CreateMemberModal({ isOpen, setModal }) {
  const roleref = useRef(null);
  const member = useSelector((state) => state.member.members);
  const auth = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const clientList = useSelector((state) => state.client.fullClientList);

  useEffect(() => {
    if (!clientList?.data?.length && isOpen) {
      dispatch(getFullClientList());
    }
    if (!member?.data?.length) {
      dispatch(getMemberList());
    }
  }, [dispatch, isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setModal(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <div>
              <div className="text-left">
                <div>
                  <h3
                    className="flex justify-between text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                    id="modal-title"
                  >
                    <div className="flex">
                      <span> ADD TEAM MEMBER </span>
                    </div>
                    <div
                      className={`text-Neutral700 text-xs font-medium
                    ${
                      auth?.payment?.payment_gateway === "appsumo" &&
                      auth?.payment?.selected_account_plan === "large_team"
                        ? "hidden"
                        : "block"
                    }
                    `}
                    >
                      <span className="text-AccentRegular font-bold">
                        {member?.spot}
                      </span>{" "}
                      Spots left
                    </div>
                  </h3>
                </div>
                {member?.spot > 0 ? (
                  <Formik
                    initialValues={{
                      email: "",
                      client_name: "",
                      phone_number: "",
                      role: "General",
                      clients: [],
                      assign_all_client: true,
                    }}
                    enableReinitialize
                    validationSchema={CreateMemberSchema}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                      setLoading(true);
                      setSubmitting(true);
                      const data = {
                        email: values?.email,
                        user_name: values?.client_name,
                        role:
                          (values?.role === "General" && "general") ||
                          (values?.role === "Project Manager" &&
                            "project_manager") ||
                          (values?.role === "Admin" && "admin"),
                        assigned_clients: values?.clients,
                        to_all_clients: values?.assign_all_client,
                      };
                      dispatch(
                        CreateMemberAction(
                          data,
                          setSubmitting,
                          setErrors,
                          setModal,
                          navigate,
                          setLoading
                        )
                      );
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="mb-6">
                          <label className="text-Neutral900 font-bold text-xs">
                            Name
                          </label>
                          <div className="mt-2">
                            <input
                              className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                              type="text"
                              placeholder="Eg. Luke Skywalker"
                              name="client_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.client_name}
                            />
                            {errors.client_name && touched.client_name && (
                              <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                {errors.client_name}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* <div className="mb-6">
                        <label className="text-Neutral900 font-bold text-xs">
                          Contact Person
                        </label>
                        <div className="mt-2">
                          <input
                            className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                            type="text"
                            placeholder="Vicky Builderino"
                            name="contact_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.contact_name}
                          />
                          {errors.contact_name && touched.contact_name && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors.contact_name}
                            </p>
                          )}
                        </div>
                      </div> */}
                        <div className="mb-6">
                          <label className="text-Neutral900 font-bold text-xs">
                            Email
                          </label>
                          <div className="mt-2">
                            <input
                              className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                              type="email"
                              placeholder="Eg. lukeskywalker@gmail.com"
                              name="email"
                              onChange={(e) => {
                                setFieldValue(
                                  "email",
                                  e.target.value.toLowerCase()
                                );
                              }}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            {errors.email && touched.email && (
                              <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                {errors.email}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-6">
                          <div className="flex items-center">
                            <label className="text-Neutral900 font-bold text-xs">
                              Default Role{" "}
                            </label>
                            <button
                              type="button"
                              onClick={() => setInfoModalOpen(true)}
                              className="text-[#060505] text-[10px] font-semibold ml-2 focus-visible:outline-none"
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.3334 7.2042C7.5528 6.58051 7.98586 6.05459 8.55586 5.7196C9.12586 5.3846 9.79603 5.26215 10.4477 5.37392C11.0993 5.48569 11.6904 5.82448 12.1161 6.33028C12.5419 6.83608 12.775 7.47625 12.774 8.1374C12.774 10.0038 9.97437 10.937 9.97437 10.937V12.0003"
                                  stroke="#9C9CA6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10.0489 14.6702H10.0576"
                                  stroke="#9C9CA6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <rect
                                  x="0.5"
                                  y="0.5"
                                  width="19"
                                  height="19"
                                  rx="9.5"
                                  stroke="#C2C2CC"
                                />
                              </svg>
                            </button>
                          </div>
                          <div className="mt-2 z-[99] relative">
                            <Menu
                              as="div"
                              className="relative inline-block text-left w-full"
                            >
                              <Menu.Button
                                ref={roleref}
                                className="rounded-[4px] client-name bg-Neutral200 inline-flex w-full focus-visible:outline-none popup-btn relative px-[16px] pb-[10px] pt-[10px]"
                              >
                                <span className="focus-visible:outline-none">
                                  <div className="members-text flex flex-wrap text-[14px] items-center text-Neutral800 font-medium">
                                    {values?.role
                                      ? values?.role
                                      : "Select role..."}
                                    <svg
                                      className="absolute right-[16px]"
                                      width="11"
                                      height="8"
                                      viewBox="0 0 11 8"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                        fill="#5A5A66"
                                      />
                                    </svg>
                                  </div>
                                </span>
                              </Menu.Button>
                              {errors?.role && touched?.role && (
                                <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                  {errors?.role}
                                </p>
                              )}
                              <Transition as={Fragment}>
                                <Menu.Items className="box shadow-[0px_30px_40px_rgba(0,0,0,0.2)] z-50 absolute top-[41px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 rounded-[0px_0px_4px_4px]">
                                  <div className="bg-white">
                                    <p className="text-Neutral600 text-[10px] font-semibold pt-[9px] pb-[12px] border-t border-Neutral200">
                                      Choose Role
                                    </p>
                                    <div>
                                      <div
                                        className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                        onClick={() => {
                                          setFieldValue("role", "Admin");
                                          setFieldValue("clients", []);
                                          setFieldValue(
                                            "assign_all_client",
                                            true
                                          );
                                          setTimeout(() => {
                                            roleref?.current?.click();
                                          }, 0);
                                        }}
                                        onBlur={handleBlur}
                                      >
                                        <p className="text-Neutral800 text-sm font-normal">
                                          Admin
                                        </p>
                                      </div>
                                      <div
                                        className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                        onClick={() => {
                                          setFieldValue("role", "General");
                                          setTimeout(() => {
                                            roleref?.current?.click();
                                          }, 0);
                                        }}
                                        onBlur={handleBlur}
                                      >
                                        <p className="text-Neutral800 text-sm font-normal">
                                          General
                                        </p>
                                      </div>
                                      <div
                                        className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                        onClick={() => {
                                          setFieldValue(
                                            "role",
                                            "Project Manager"
                                          );
                                          setTimeout(() => {
                                            roleref?.current?.click();
                                          }, 0);
                                        }}
                                        onBlur={handleBlur}
                                      >
                                        <p className="text-Neutral800 text-sm font-normal">
                                          Project Manager
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                        {values?.role !== "Admin" && (
                          <div className="mb-6">
                            <label className="text-Neutral900 font-bold text-xs">
                              Client Assignment
                            </label>
                            <div className="mt-2">
                              <div className="flex items-center justify-between">
                                <div className="flex items-center filter-checkbox">
                                  <input
                                    type="checkbox"
                                    id="all_clients"
                                    defaultChecked
                                    checked={values?.assign_all_client}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "assign_all_client",
                                        !values?.assign_all_client
                                      );
                                      if (values?.assign_all_client !== true) {
                                        setFieldValue("clients", []);
                                      }
                                    }}
                                    name="A3-confirmation"
                                    value="yes"
                                    className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                                  />
                                  <div className="bg-white border-Neutral300 border-[1.5px] custom-chackbox rounded-md hover:bg-AccentLight hover:border-black w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                                    <img src={Checkmark} alt="" />
                                  </div>
                                  <label
                                    className=" cursor-pointer"
                                    htmlFor="all_clients"
                                  >
                                    <span className="text-Neutral700 text-xs font-medium block">
                                      Assign to all clients automatically
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {!values?.assign_all_client && (
                              <div className="mb-6 mt-4">
                                <div className="mt-2 relative">
                                  <Menu
                                    as="div"
                                    className="relative inline-block text-left w-full"
                                  >
                                    <Menu.Button className="rounded-[4px] client-name bg-Neutral200 inline-flex w-full focus-visible:outline-none popup-btn relative px-[16px] pb-[10px] pt-[10px]">
                                      <span className="focus-visible:outline-none">
                                        <div className="members-text flex flex-wrap text-[14px] items-center text-Neutral800 font-medium">
                                          Select Clients Manually
                                          <svg
                                            className="absolute right-[16px]"
                                            width="11"
                                            height="8"
                                            viewBox="0 0 11 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                              fill="#5A5A66"
                                            />
                                          </svg>
                                        </div>
                                      </span>
                                    </Menu.Button>
                                    {errors?.clients && touched?.clients && (
                                      <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                        {errors?.clients}
                                      </p>
                                    )}
                                    <Transition as={Fragment}>
                                      <Menu.Items className="box shadow-[0px_30px_40px_rgba(0,0,0,0.2)] z-50 absolute top-[41px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 rounded-[0px_0px_4px_4px]">
                                        <div className="bg-white">
                                          <p className="text-Neutral600 flex justify-between text-[10px] font-semibold pt-[9px] pb-[12px] border-t border-Neutral200">
                                            <span>Clients</span>
                                            <span
                                              className="cursor-pointer text-AccentRegular"
                                              onClick={() => {
                                                setFieldValue(
                                                  "clients",
                                                  values?.clients?.length ===
                                                    clientList?.data?.length
                                                    ? []
                                                    : clientList?.data?.map(
                                                        (ele) => ele?._id
                                                      )
                                                );
                                              }}
                                            >
                                              {values?.clients?.length ===
                                              clientList?.data?.length
                                                ? "UNSELECT ALL"
                                                : "SELECT ALL"}
                                            </span>
                                          </p>
                                          <div>
                                            {clientList?.data?.map(
                                              (item, index) => {
                                                return (
                                                  <label htmlFor={item?._id}>
                                                    <div
                                                      className="flex justify-between items-center rounded-lg py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                                      key={index}
                                                    >
                                                      <div className="flex">
                                                        {item?.profile ? (
                                                          <div className="rounded-full h-[24px] w-[24px] overflow-hidden">
                                                            <img
                                                              src={
                                                                item?.profile_path
                                                              }
                                                              className="object-cover"
                                                              alt=""
                                                            />
                                                          </div>
                                                        ) : (
                                                          <div className="h-[24px] w-[24px] flex items-center justify-center rounded-full text-Neutral700 bg-Neutral400 focus-visible:outline-none">
                                                            <span className="text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold">
                                                              {
                                                                item
                                                                  ?.client_name[0]
                                                              }
                                                            </span>
                                                          </div>
                                                        )}
                                                        <p className="text-Neutral900 pl-4 text-sm font-normal">
                                                          {item?.client_name}
                                                        </p>
                                                      </div>
                                                      <div className="flex items-center filter-checkbox">
                                                        <input
                                                          type="checkbox"
                                                          id={item?._id}
                                                          // defaultChecked
                                                          checked={values?.clients?.includes(
                                                            item?._id
                                                          )}
                                                          onBlur={handleBlur}
                                                          onChange={(e) => {
                                                            setFieldValue(
                                                              "clients",
                                                              values?.clients?.includes(
                                                                item?._id
                                                              )
                                                                ? values?.clients.filter(
                                                                    (ele) =>
                                                                      ele !==
                                                                      item?._id
                                                                  )
                                                                : [
                                                                    ...values?.clients,
                                                                    item?._id,
                                                                  ]
                                                            );
                                                          }}
                                                          name="A3-confirmation"
                                                          value="yes"
                                                          className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                                                        />
                                                        <div className="bg-white border-Neutral300 border-[1.5px] custom-chackbox rounded-md hover:bg-AccentLight hover:border-black w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                                                          <img
                                                            src={Checkmark}
                                                            alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </label>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        <div className="mt-8 pb-5">
                          <button
                            disabled={isSubmitting}
                            className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                          >
                            <span className="relative z-10 drop-shadow-Texts">
                              {!loading ? (
                                "Invite to Your Team"
                              ) : (
                                <div className="py-2 flex justify-center items-center relative overflow-hidden z-10">
                                  <div className="pr-2 white dot-falling"></div>
                                </div>
                              )}
                            </span>
                          </button>
                        </div>
                        <div className="">
                          <div className="text-xs text-Neutral700 leading-[18px]font-semibold w-full">
                            Team member will receive a ClientManager email to
                            <br />
                            activate their account and join your team.
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <>
                    <div className="my-6 flex items-center justify-center">
                      <img src={BigShoesAvatar} alt="" />
                    </div>
                    <div className="flex items-center justify-center w-[354px]">
                      <span className="text-center text-sm font-normal text-Neutral800">
                        Spice up your plan in a few clicks to get back to adding
                        more members to your team!
                      </span>
                    </div>
                    <div className="mt-8">
                      {auth?.payment?.payment_gateway === "padddle" ? (
                        <Link
                          className="relative z-10 drop-shadow-Texts"
                          to="/upgrade-plan"
                        >
                          <button className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out">
                            Upgrade Account
                          </button>
                        </Link>
                      ) : (
                        <a
                          href={auth?.payment?.upgrade_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out">
                            Upgrade Account
                          </button>
                        </a>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setModal(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
      <TeamMemberDetailsModal
        modalIsOpen={infoModalOpen}
        setIsOpen={setInfoModalOpen}
      />
    </>
  );
}

export default CreateMemberModal;
