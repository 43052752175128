import { Disclosure } from "@headlessui/react";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";

import SearchIcon from "../../components/Icons/SearchIcon";
import TickIcon from "../../components/Icons/TickIcon";
import WarningIcon from "../../components/Icons/WarningIcon";
import ClientDeactivateConfirmationModal from "../../components/Modals/ClientPortal/ConfirmDeactivateModal";
import { getClientPortalClientDetails } from "../../store/actions/clientAction";
import { useQuery } from "../../hooks/useQuery";

let CLIENT_PORTAL_BASE_URL = "";
if (["dev", "development"].includes(process.env.REACT_APP_ENV)) {
  CLIENT_PORTAL_BASE_URL = "https://devportal.clientmanager.io/";
} else {
  CLIENT_PORTAL_BASE_URL = "https://portal.clientmanager.io/";
}

function ClientPortal() {
  const dispatch = useDispatch();
  const clientsList = useSelector(
    (state) => state.client.clientPortalClientDetails
  );
  const user = useSelector((state) => state.auth.user);
  const [searchedClientsList, setSearchedClientsList] = useState([]);
  const [searchedClient, setSearchedClient] = useState(null);
  const [deleteConfirmationModalData, setDeleteConfirmationModalData] =
    useState({
      isOpen: false,
      client: null,
    });
  const query = useQuery();
  const redirectedClientIdFromNowhere = query.get("clientId");

  useEffect(() => {
    if (redirectedClientIdFromNowhere) {
      const targetClient = clientsList?.data?.find(
        (client) => client?._id === redirectedClientIdFromNowhere
      );
      if (targetClient) setSearchedClient(targetClient);
    }
  }, [clientsList?.data, redirectedClientIdFromNowhere]);

  useEffect(() => {
    dispatch(getClientPortalClientDetails());
  }, [dispatch]);

  const handleActivate = async (client, inviteStatus) => {
    try {
      const inviteData = {
        client_id: client?._id,
        email: client?.email,
        status: inviteStatus,
      };

      const { data } = await axios.post(
        `api/v1/invited-client-portal/invite-client-user`,
        [inviteData]
      );

      if (data?.data?.status) {
        toast.success(data?.message);
      } else {
        toast.success("Client Portal User Deactivated Successfully");
      }

      // Refetch the latest data
      dispatch(getClientPortalClientDetails());
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message || err.message);
    }
  };

  const handleResendInvite = async (client) => {
    try {
      const { data } = await axios.post(
        `api/v1/invited-client-portal/re-invite-client-user`,
        {
          invited_client_id: client?.invited?._id,
        }
      );
      toast.success(data?.message);
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message || err.message);
    }
  };

  const handleCancelInvitation = async (client) => {
    try {
      const res = await axios.post(
        `api/v1/invited-client-portal/cancel-invitation`,
        {
          invited_client_id: client?.invited?._id,
        }
      );

      toast.success(res?.data?.message);
      dispatch(getClientPortalClientDetails());
    } catch (error) {
      console.log("Error in cancel invitation :", error);
      toast.error(error?.response?.data?.errors[0]?.msg);
    }
  };

  const handleSearch = (value) => {
    if (clientsList?.data) {
      const search = clientsList.data?.filter(
        (res) =>
          res.business_name.toLowerCase().includes(value.toLowerCase()) ||
          res.contact_name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchedClientsList([...search]);
    }
  };

  const directOrFilteredClientsList = useMemo(() => {
    // return searchedClientsList.length > 0 ? searchedClientsList : clientsList?.data;
    return searchedClientsList.length > 0
      ? searchedClientsList
      : redirectedClientIdFromNowhere && searchedClient
      ? clientsList?.data?.filter(
          (client) => client?._id !== redirectedClientIdFromNowhere
        )
      : clientsList?.data;
  }, [
    clientsList?.data,
    redirectedClientIdFromNowhere,
    searchedClient,
    searchedClientsList,
  ]);

  return (
    <div>
      <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0 mb-[95px] md:mb-0 md:h-[75vh]">
        <div className="flex flex-col gap-4 mt-20 md:mt-6 md:gap-7">
          <Disclosure
            as="div"
            className="px-4 py-3 space-y-4 border-2 rounded-lg md:py-5 md:px-6 border-Neutral300"
          >
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between w-full text-sm font-bold md:text-xl text-Neutral800">
                  <span>What is client portal?</span>
                  <svg
                    width={24}
                    height={24}
                    strokeWidth={2}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`transition ${open ? "rotate-0" : "rotate-180"}`}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </Disclosure.Button>

                {open && <hr className="border-Neutral300" />}

                <Disclosure.Panel as="p" className="text-sm text-Neutral700">
                  Think of it as a dashboard where you can collaborate with your
                  client.
                  <br />
                  <br />
                  <ul className="ml-5 space-y-2 list-disc">
                    <li>Schedule meetings</li>
                    <li>Work on projects</li>
                    <li>See documents</li>
                    <li>See notices</li>
                    <li>See client requests</li>
                  </ul>
                  <br />
                  …all in one simple page.
                  <br />
                  <br /> You as the “user” have your view. <br /> The “client”
                  has their view. <br />
                  <br /> We make it easy to toggle between each view :) <br />
                  <br />
                  If you have any suggestions how we can make it better, let us
                  know. <br />
                  <br />
                  Happy collaborating!
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <Disclosure
            as="div"
            className="px-4 py-3 space-y-4 border-2 rounded-lg md:py-5 md:px-6 border-Neutral300"
          >
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between w-full text-sm font-bold md:text-xl text-Neutral800">
                  <span>How does it work?</span>
                  <svg
                    width={24}
                    height={24}
                    strokeWidth={3}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`transition ${open ? "rotate-0" : "rotate-180"}`}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </Disclosure.Button>

                {open && <hr className="border-Neutral300" />}

                <Disclosure.Panel as="div" className="flex flex-col gap-6">
                  <p className="text-sm text-Neutral700">
                    Activate your desired client portal(s) below.
                    <br />
                    <br />
                    Your client will receive an email (make sure they check spam
                    folder) where they can activate the portal.
                    <br />
                    <br />
                    Once activated, your client can login via{" "}
                    <Link
                      to="https://portal.clientmanager.io/"
                      className="text-AccentRegular"
                    >
                      https://portal.clientmanager.io/
                    </Link>
                    <br />
                    <br />
                    You can send this link to all clients (if they have an
                    active portal), so for better UX, link it to your website.
                    <br />
                    <br />
                    To customize each portal, login with your main account:{" "}
                    <Link
                      to="https://portal.clientmanager.io/"
                      className="text-AccentRegular"
                    >
                      https://portal.clientmanager.io/
                    </Link>{" "}
                    select the relevant portal and edit it to your liking.
                    <br />
                    <br />
                    Enjoy!
                  </p>
                  <iframe
                    src="https://www.youtube.com/embed/gewyBwmC1h8"
                    title="Client Portal Demo"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                    className="w-full rounded-xl aspect-video md:max-w-xl lg:max-w-3xl"
                  ></iframe>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        {searchedClient && (
          <div className="flex flex-col gap-5 my-7">
            <div className="space-y-1">
              <h4 className="text-sm font-bold md:font-black md:text-2xl text-Neutral800">
                Activate Client Portal for{" "}
                {searchedClient?.user_name ||
                  searchedClient?.business_name ||
                  searchedClient?.contact_name}
              </h4>
              <p className="w-full text-xs md:text-sm text-Neutral700">
                This is where you can activate/deactivate the clients portal
                interface for your clients.
              </p>
            </div>
            <div
              className="flex flex-col gap-3 px-4 py-3 border rounded-lg md:items-center md:flex-row md:justify-between border-Neutral300 bg-Neutral000 group"
              key={`${searchedClient._id}`}
            >
              <div className="flex items-center gap-2">
                {/* Client Profile Image */}
                <div
                  className={`relative grid overflow-hidden rounded-full size-10 place-items-center bg-Neutral200 shrink-0 group-hover:outline group-hover:outline-AccentRegular group-hover:outline-2`}
                >
                  {searchedClient?.profile_path ? (
                    <img
                      src={searchedClient?.profile_path}
                      alt={searchedClient?.user_name}
                      fill
                      objectFit="contain"
                    />
                  ) : (
                    <span className="text-sm font-medium uppercase text-Neutral700">
                      {searchedClient?.user_name?.charAt(0) ||
                        searchedClient?.business_name?.charAt(0) ||
                        searchedClient?.contact_name?.charAt(0)}
                    </span>
                  )}
                </div>
                {/* Client Name */}
                <div className="flex-grow peer">
                  <h3 className="text-sm font-bold text-Neutral700">
                    {(searchedClient?.invited?.status &&
                      searchedClient?.invited?.verifiedAt) ||
                    (searchedClient?.invited &&
                      !searchedClient?.invited?.verifiedAt) ? (
                      <Link
                        to={`${CLIENT_PORTAL_BASE_URL}user/${user?._id}/${searchedClient?._id}/home`}
                        target="_blank"
                        className="text-AccentRegular"
                      >
                        {searchedClient.business_name ||
                          searchedClient.contact_name}
                      </Link>
                    ) : (
                      <span>
                        {searchedClient.business_name ||
                          searchedClient.contact_name}
                      </span>
                    )}
                    {/* {client?.invited && !client?.invited?.verifiedAt && (
                          <span className="pl-1 text-[10px] text-orange-400">Invitation Pending</span>
                        )} */}
                  </h3>
                </div>
              </div>
              <div>
                {searchedClient?.invited &&
                !searchedClient?.invited?.verifiedAt ? (
                  <div className="flex items-end">
                    <div className="inline-flex items-center flex-shrink-0 gap-1 text-Neutral800">
                      <WarningIcon className="size-3" />
                      <p className="text-xs">Invitation Pending</p>
                    </div>
                    <button
                      data-tip={`Resend the invite to ${searchedClient?.email} (ask them to check spam/junk)`}
                      data-for="resend-invitation"
                      className="pl-2 text-xs underline text-AccentRegular hover:text-AccentMediumDark"
                      onClick={() => handleResendInvite(searchedClient)}
                    >
                      Resend Invitation
                    </button>
                    <ReactTooltip
                      id="resend-invitation"
                      delayShow={300}
                      place="top"
                    />
                  </div>
                ) : searchedClient?.email ? (
                  <div className="flex gap-2">
                    <button
                      className="py-1.5 w-[100px] h-9 inline-flex gap-0.5 items-center justify-center px-3 rounded-[2rem] disabled:cursor-not-allowed text-[10px] disabled:bg-Neutral200 disabled:text-Neutral500 bg-Red500 hover:bg-Red600 text-Neutral000"
                      disabled={
                        !searchedClient?.invited ||
                        (searchedClient?.invited &&
                          !searchedClient?.invited?.verifiedAt)
                      }
                      onClick={() => {
                        // handleActivate(client, false);
                        setDeleteConfirmationModalData({
                          isOpen: true,
                          searchedClient,
                        });
                      }}
                    >
                      {searchedClient?.invited?.status === false &&
                      searchedClient?.invited?.verifiedAt ? (
                        <>
                          <span>Deactivated</span>
                          <TickIcon className="size-5" />
                        </>
                      ) : (
                        "Deactivate"
                      )}
                    </button>
                    <button
                      onClick={() => handleActivate(searchedClient, true)}
                      disabled={
                        searchedClient?.invited &&
                        !searchedClient?.invited?.verifiedAt
                      }
                      className={`py-1.5 w-[100px] h-9 justify-center inline-flex gap-0.5 items-center px-3 rounded-[2rem] text-[10px] bg-AccentRegular text-Neutral000 disabled:cursor-not-allowed`}
                    >
                      {searchedClient?.invited?.status &&
                      searchedClient?.invited?.verifiedAt ? (
                        <>
                          <span>Activated</span>
                          <TickIcon className="size-5" />
                        </>
                      ) : (
                        "Activate"
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="inline-flex items-center flex-shrink-0 gap-1.5 text-Orange500">
                    <WarningIcon className="size-4" />
                    <p className="text-xs">
                      Add a valid email before activating
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="my-7">
          <div className="flex flex-col items-center gap-1 md:flex-row md:justify-between font-noto">
            <div className="space-y-1">
              <h4 className="text-sm font-bold md:font-black md:text-2xl text-Neutral800">
                Activate Client Portals
              </h4>
              <p className="w-full text-xs md:text-sm text-Neutral700">
                This is where you can activate/deactivate the clients portal
                interface for your clients.
              </p>
            </div>
            <div className="flex mt-3 w-full md:w-[30%] items-center gap-2 border px-3 py-2 border-Neutral400 text-Neutral600 rounded-md md:rounded-3xl focus-within:outline focus-within:outline-1 focus-within:outline-AccentRegular group">
              <SearchIcon className="size-6 group-focus-within:text-AccentRegular" />
              <input
                type="text"
                className="w-full font-medium bg-transparent focus:outline-none placeholder:text-Neutral500 text-AccentRegular focus:placeholder:text-AccentMediumLight placeholder:font-normal"
                placeholder="Search..."
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col w-full gap-3 my-6 overflow-y-auto max-h-[90vh] scrollbar-w-4 scrollbar-h-4">
            {!!directOrFilteredClientsList.length &&
              directOrFilteredClientsList?.map((client, index) => {
                return (
                  <div
                    className="flex flex-col gap-3 px-4 py-3 border rounded-lg md:items-center md:flex-row md:justify-between border-Neutral300 bg-Neutral000 group"
                    key={`${client._id}-${index}`}
                  >
                    <div className="flex items-center gap-2">
                      {/* Client Profile Image */}
                      <div
                        className={`relative grid overflow-hidden rounded-full size-10 place-items-center bg-Neutral200 shrink-0 group-hover:outline group-hover:outline-AccentRegular group-hover:outline-2`}
                      >
                        {client?.profile_path ? (
                          <img
                            src={client?.profile_path}
                            alt={client?.user_name}
                            fill
                            objectFit="contain"
                          />
                        ) : (
                          <span className="text-sm font-medium uppercase text-Neutral700">
                            {client?.user_name?.charAt(0) ||
                              client?.business_name?.charAt(0) ||
                              client?.contact_name?.charAt(0)}
                          </span>
                        )}
                      </div>
                      {/* Client Name */}
                      <div className="flex-grow peer">
                        <h3 className="text-sm font-bold text-Neutral700">
                          {(client?.invited?.status &&
                            client?.invited?.verifiedAt) ||
                          (client?.invited && !client?.invited?.verifiedAt) ? (
                            <Link
                              to={`${CLIENT_PORTAL_BASE_URL}user/${user?._id}/${client?._id}/home`}
                              target="_blank"
                              className="text-AccentRegular"
                            >
                              {client.business_name || client.contact_name}
                            </Link>
                          ) : (
                            <span>
                              {client.business_name || client.contact_name}
                            </span>
                          )}
                          {/* {client?.invited && !client?.invited?.verifiedAt && (
                          <span className="pl-1 text-[10px] text-orange-400">Invitation Pending</span>
                        )} */}
                        </h3>
                      </div>
                    </div>
                    <div>
                      {client?.invited &&
                      !client?.invited?.verifiedAt &&
                      client?.invited?.status === true &&
                      client?.invited?.accepted === false ? (
                        <div className="flex flex-col items-center gap-1">
                          <div className="inline-flex items-center flex-shrink-0 gap-1 text-Neutral800">
                            <WarningIcon className="size-3" />
                            <p className="text-xs">Invitation Pending</p>
                          </div>
                          <div className="flex gap-2">
                            <ReactTooltip
                              id="resend-invitation"
                              delayShow={300}
                              place="top"
                            />
                            <button
                              data-tip={`Resend the invite to ${client?.email} (ask them to check spam/junk)`}
                              data-for="resend-invitation"
                              className="pl-2 text-xs underline text-AccentRegular hover:text-AccentMediumDark"
                              onClick={() => handleResendInvite(client)}
                            >
                              Resend Invitation
                            </button>
                            <button
                              type="button"
                              className="text-xs text-red-500 underline hover:text-red-700 disabled:cursor-not-allowed"
                              onClick={() => {
                                handleCancelInvitation(client);
                              }}
                            >
                              Cancel Invitation
                            </button>
                          </div>
                        </div>
                      ) : client?.email ? (
                        <div className="flex gap-2">
                          <button
                            className="py-1.5 w-[100px] h-9 inline-flex gap-0.5 items-center justify-center px-3 rounded-[2rem] disabled:cursor-not-allowed text-[10px] disabled:bg-Neutral200 disabled:text-Neutral500 bg-Red500 hover:bg-Red600 text-Neutral000"
                            disabled={
                              !client?.invited ||
                              (client?.invited?.status === false &&
                                !client?.invited?.verifiedAt)
                            }
                            onClick={() => {
                              // handleActivate(client, false);
                              setDeleteConfirmationModalData({
                                isOpen: true,
                                client,
                              });
                            }}
                          >
                            {client?.invited?.status === false &&
                            client?.invited?.verifiedAt ? (
                              <>
                                <span>Deactivated</span>
                                <TickIcon className="size-5" />
                              </>
                            ) : (
                              "Deactivate"
                            )}
                          </button>
                          <button
                            onClick={() => handleActivate(client, true)}
                            disabled={
                              client?.invited &&
                              client?.invited?.verifiedAt &&
                              client?.invited?.status
                            }
                            className={`py-1.5 w-[100px] h-9 justify-center inline-flex gap-0.5 items-center px-3 rounded-[2rem] bg-AccentRegular text-Neutral000 disabled:cursor-not-allowed text-[10px] not-disabled:hover:bg-AccentMediumDark`}
                          >
                            {client?.invited?.status &&
                            client?.invited?.verifiedAt ? (
                              <>
                                <span>Activated</span>
                                <TickIcon className="size-5" />
                              </>
                            ) : (
                              "Activate"
                            )}
                          </button>
                        </div>
                      ) : (
                        <div className="inline-flex items-center flex-shrink-0 gap-1.5 text-Orange500">
                          <WarningIcon className="size-4" />
                          <p className="text-xs">
                            Add a valid email before activating
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <ClientDeactivateConfirmationModal
        isOpen={deleteConfirmationModalData.isOpen}
        setOpen={setDeleteConfirmationModalData}
        // clientDetails={deleteConfirmationModalData.client}
        // handleActivate={handleActivate}
        deactivate={() =>
          handleActivate(deleteConfirmationModalData.client, false)
        }
      />
    </div>
  );
}

export default ClientPortal;
