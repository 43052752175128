import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { useDispatch } from "react-redux";
import {
  DeleteExistingProposal,
  GetProposalListByClientId,
  GetProposalStatusList,
} from "../../store/actions/proposalAction";
import { useSelector } from "react-redux";
import Download from "../../assets/Images/Download.svg";
import Edit from "../../assets/Images/Edit-12px.svg";
import Visible from "../../assets/Images/Visible.svg";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import ProposalsStatus from "../Buttons/ProposalsStatus";
import ConfirmProposalDeleteModal from "./ConfirmProposalDeleteModal";
import MessageIcon from "../Icons/newicons/_MessageIcon";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function ViewProposalList({
  isOpen = false,
  setIsOpen = () => {},
  clientId,
  setSelectedData,
  openModal,
  isEmailModalOpen,
}) {
  const dispatch = useDispatch();
  const proposalList = useSelector(
    (state) => state.proposals.clientProposalList
  );
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [selectedProposal, setSelectedProposal] = useState(null);

  useEffect(() => {
    dispatch(GetProposalStatusList());
  }, [dispatch]);

  useEffect(() => {
    if (
      proposalList?.data?.length === 0 ||
      proposalList?.data?.findIndex(
        (item) => item?.client?._id === clientId
      ) === -1
    ) {
      dispatch(GetProposalListByClientId(clientId));
    }
  }, [proposalList]);

  const openDeleteConfirmationModal = () => {
    setIsConfirmDeleteModalOpen(true);
  };

  const handleDelete = async (id) => {
    dispatch(DeleteExistingProposal(id));
    setSelectedProposal(null);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px] max-h-[500px] w-[85vw] md:w-full">
            <div>
              <div className="text-left">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-4"
                  id="modal-title"
                >
                  Proposal List
                </h3>
              </div>
              <div className="max-h-[400px] overflow-y-auto md:overflow-x-hidden scrollbar-h-4">
                <table
                  className={`w-[725px] table-fixed bg-neutral-50 rounded-lg px-4 py-2 `}
                >
                  {proposalList?.isLoading && (
                    <div className="flex items-center justify-center">
                      <div className="w-32 h-32 border-t-2 border-b-2 rounded-full animate-spin border-Primary300"></div>
                    </div>
                  )}
                  {proposalList?.data?.length === 0 &&
                    !proposalList?.isLoading && (
                      <div className="flex items-center justify-center">
                        <p className="text-sm font-medium text-Neutral700">
                          No Proposal Found
                        </p>
                      </div>
                    )}
                  {proposalList?.data?.length > 0 &&
                    proposalList?.data?.map((proposal, index) => (
                      <tr key={index} className="border-Neutral400">
                        <td className="w-[5%] xl:max-w-[10%] max-w-[10%] py-5 px-3">
                          <p className="flex items-center justify-center h-6 text-sm font-medium text-Neutral900 rounded-fullw-6">
                            {index + 1}
                          </p>
                        </td>
                        <td className="w-[20%] xl:max-w-[10%] max-w-[10%] py-5 px-3">
                          <p className="text-sm font-medium tex t-Neutral900">
                            {proposal?.title
                              ? proposal.title
                              : `Proposal ${index + 1}`}
                          </p>
                        </td>
                        {/* <td className="w-full max-w-[5%]"></td> */}
                        {/* <td className="w-full max-w-[25%] text-left 2xl:px-3 px-2">
                        <p className="text-sm font-medium truncate text-Neutral700">
                          {proposal?.client?.email}
                        </p>
                      </td> */}

                        <td className="text-center w-[5%]">
                          <div className="flex px-1">
                            {proposal?.is_external_proposal === true ? (
                              <a
                                href={`${
                                  proposal?.existing_file_url
                                    ? proposal?.existing_file_url
                                    : proposal?.existing_file_upload?.url
                                }`}
                                target="_blank"
                                className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-[56px] 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out cursor-pointer"
                                rel="noreferrer"
                              >
                                <img
                                  className="5xl:w-[20px]"
                                  src={Visible}
                                  alt=""
                                />
                              </a>
                            ) : (
                              <Link
                                to={`/public-proposal/${proposal.client?._id}/proposal/${proposal?._id}`}
                                target="_blank"
                                className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-[56px] 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                              >
                                <img
                                  className="5xl:w-[20px]"
                                  src={Visible}
                                  alt=""
                                />
                              </Link>
                            )}
                          </div>
                        </td>
                        <td className="text-center w-[5%]">
                          <div className="flex px-1">
                            {proposal?.is_external_proposal ? (
                              <a
                                href={`${
                                  proposal?.existing_file_url
                                    ? proposal?.existing_file_url
                                    : proposal?.existing_file_upload?.url
                                }`}
                                className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[120px] w-[80px] 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out cursor-pointer"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="5xl:w-[20px]"
                                  src={Download}
                                  alt=""
                                />
                              </a>
                            ) : (
                              <Link
                                to={`/admin/clients/manage/${proposal.client?._id}/proposal/${proposal?._id}?preview=true`}
                                target="_blank"
                                className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[120px] w-[80px] 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                              >
                                <img
                                  className="5xl:w-[20px]"
                                  src={Download}
                                  alt=""
                                />
                              </Link>
                            )}
                          </div>
                        </td>
                        <td className="text-center w-[5%]">
                          <div className="flex px-1 ">
                            {proposal?.is_external_proposal === true ? (
                              <button
                                onClick={() => {
                                  openModal(true, proposal);
                                }}
                                className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-[56px] 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                              >
                                <img
                                  className="5xl:w-[14px]"
                                  src={Edit}
                                  alt=""
                                />
                              </button>
                            ) : (
                              <Link
                                to={`/admin/clients/manage/${proposal.client?._id}/proposal/${proposal._id}`}
                                className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-[56px] 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                              >
                                <img
                                  className="xl:w-[14px]"
                                  src={Edit}
                                  alt=""
                                />
                              </Link>
                            )}
                          </div>
                        </td>
                        <td className="text-center w-[5%]">
                          <div className="flex px-1 ">
                            <button
                              onClick={() => {
                                isEmailModalOpen(proposal?._id);
                                setSelectedData(proposal)
                              }}
                              className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-[56px] 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                            >
                              <MessageIcon
                                className="w-[14px]"
                                color="#6D6DF2"
                              />
                            </button>
                          </div>
                        </td>
                        <td className="text-center w-[5%]">
                          <div className="flex px-1">
                            <Menu as="div" className="relative inline-block">
                              <div className="flex items-center">
                                <Menu.Button
                                  className="relative inline-flex justify-center w-full focus-visible:outline-none"
                                  onClick={(e) => {
                                    console.log("e", e);
                                    // e.stopPropagation();
                                  }}
                                >
                                  <span className="text-[8px] 5xl:text-[12px] font-semibold 5xl:w-[75px] w-[48px] 5xl:h-9 h-[28px] flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-[#E56780] focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out mr-2">
                                    <svg
                                      width="14"
                                      height="16"
                                      viewBox="0 0 14 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.16663 4.62195H2.35922H11.9"
                                        stroke="#E56780"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M4.14729 4.62252V3.45426C4.14729 3.14442 4.27294 2.84727 4.49659 2.62818C4.72024 2.40909 5.02359 2.28601 5.33988 2.28601H7.72507C8.04136 2.28601 8.3447 2.40909 8.56836 2.62818C8.79201 2.84727 8.91766 3.14442 8.91766 3.45426V4.62252M10.7065 4.62252V12.8003C10.7065 13.1101 10.5809 13.4073 10.3572 13.6264C10.1336 13.8455 9.83025 13.9686 9.51396 13.9686H3.55099C3.2347 13.9686 2.93136 13.8455 2.7077 13.6264C2.48405 13.4073 2.3584 13.1101 2.3584 12.8003V4.62252H10.7065Z"
                                        stroke="#E56780"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items
                                  className="z-10 origin-top-right absolute -top-[80px] right-[-20px] pt-4 pb-6 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none"
                                  onClick={(e) => {
                                    if (e.target === e.currentTarget) {
                                      e.stopPropagation();
                                    }
                                  }}
                                >
                                  <div className="">
                                    <p className="pb-2 text-xs font-semibold text-Neutral700">
                                      Are you sure?
                                    </p>
                                  </div>
                                  <div
                                    className="overflow-hidden border-0"
                                    onClick={() => {
                                      if (proposal.client_signature.signature) {
                                        setSelectedProposal(proposal);
                                        openDeleteConfirmationModal();
                                      } else {
                                        handleDelete(proposal?._id);
                                      }
                                    }}
                                  >
                                    <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none">
                                      <h3 className="w-full py-4 mt-5 text-xs font-semibold text-center text-white duration-300 rounded cursor-pointer bg-Red400 hover:bg-Red600">
                                        Yes, Delete
                                      </h3>
                                    </Menu.Button>
                                  </div>
                                  <div className="overflow-hidden border-0 !border-transparent divide-0">
                                    <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none">
                                      <h3 className="w-full py-4 mt-3 text-xs font-semibold text-center duration-300 rounded cursor-pointer text-Neutral800 bg-Neutral200 hover:bg-Neutral500 hover:text-Neutral900">
                                        No, Please Keep It
                                      </h3>
                                    </Menu.Button>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </td>

                        <td className="text-center w-[10%]">
                          <div className="flex items-center justify-end px-0 transition duration-300 2xl:px-2">
                            <span
                              className={`text-[10px] 5xl:text-[14px] py-1 px-2 font-semibold rounded-2xl leading-tight`}
                            >
                              <ProposalsStatus
                                status={proposal.proposal_status.name}
                                id={proposal?.client?._id}
                                proposal_id={proposal?._id}
                                isEditMode
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                </table>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>

      <ConfirmProposalDeleteModal
        isOpen={isConfirmDeleteModalOpen}
        setIsOpen={setIsConfirmDeleteModalOpen}
        onDelete={() => handleDelete(selectedProposal?._id)}
      />
    </>
  );
}

export default ViewProposalList;
