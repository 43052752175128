import React from 'react'
import ImgIcon from '../../assets/Images/img-icon.svg';

const Uploadlogo = () => {
  return (
    <>
      <div className='relative'>
        <label htmlFor="uploadlogo" className='placeholder:text-Neutral500 p-2 px-3 text-sm leading-5 w-full focus-visible:outline-none border-[1px] rounded border-Neutral400 hover:border-Neutral800 bg-Neutral100 transition duration-300 border-dashed inline-block cursor-pointer'>
          <span className='flex items-center'>
            <img src={ImgIcon} alt="" />
            <p className='text-Neutral600 ml-2 text-xs font-medium'>Upload Images</p>
          </span>
        </label>
        <input id='uploadlogo' className='hidden' type="file" placeholder='Eg. very_good_password' />
      </div>
    </>
  )
}

export default Uploadlogo