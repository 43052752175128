import React from "react";
import clx from "classnames";

function ProjectCategoryBadge({ text = "", className = "" }) {
  if (!text) {
    return null;
  }

  return (
    <p
      className={clx(
        "text-Neutral900 bg-Neutral200 rounded-md h-[18px] text-[8px] tracking-[.04em] font-semibold uppercase py-1 px-2 leading-[10px]",
        className
      )}
    >
      {text}
    </p>
  );
}

export default ProjectCategoryBadge;
