import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Download from "../../assets/Images/Download.svg";
import Edit from "../../assets/Images/Edit-12px.svg";
import Visible from "../../assets/Images/Visible.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import DashboardButton from "../../components/Buttons/DashboardButton";
import ProposalsStatus from "../../components/Buttons/ProposalsStatus";
import ProposalsListLoader from "../../components/ContentLoaders/ProposalsListLoader";
import MessageIcon from "../../components/Icons/newicons/_MessageIcon";
import FollowUpEmailModal from "../../components/Modals/FollowUpEmailModal";
import { NewEntityTemplateModal } from "../../components/Modals/Templates/NewEntityTemplateModal";
import NewTemplateModal from "../../components/Modals/Templates/NewTemplateModal";
import { SelectTemplate } from "../../components/Modals/Templates/SelectTemplate";
import UploadExistingProposalModal from "../../components/Modals/UploadExistingProposalModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import NoProposalComponent from "../../components/Noproposal";
import NotDataFound from "../../components/NotDataProposal";
import Pagination from "../../components/Pagination";
import SearchBar from "../../components/Search";
import ClientSelect from "../../components/comman/Client/ClientSelect";
import ProposalFilter from "../../components/filter/ProposalFilter";
import { getFullClientList } from "../../store/actions/clientAction";
import {
  DeleteExistingProposal,
  GetProposalList,
  GetProposalStatusList,
} from "../../store/actions/proposalAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const groupByClient = (proposals) => {
  const groupedProposals = proposals.reduce((acc, proposal) => {
    const { client } = proposal;
    if (!acc[client._id]) {
      acc[client._id] = {
        ...client,
        proposals: [],
      };
    }
    acc[client._id].proposals.push(proposal);
    return acc;
  }, {});
  return Object.values(groupedProposals);
};

const Proposals = () => {
  const dispatch = useDispatch();
  const [
    uploadExistingProposalModalIsOpen,
    setUploadExistingProposalModalIsOpen,
  ] = useState(false);
  const [selectClientModalIsOpen, setSelectClientModalIsOpen] = useState(false);
  const [selectTemplateModalIsOpen, setSelectTemplateModalIsOpen] =
    useState(false);
  const [proposalTemplateModalIsOpen, setProposalTemplateModalIsOpen] =
    useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const authSelector = useSelector((state) => state.auth);
  const [searchdata, setSearchData] = useState([]);
  const [selectProposal, setSelectProposal] = useState(null);
  const [proposal, setProposal] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 0,
    totalCount: 0,
    perPage: 10,
  });
  const [proposalStatusFilter, setProposalStatusFilter] = useState("All");
  const [newTemplateModalIsOpen, setNewTemplateModalIsOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const allClients = useSelector((state) => state.client.fullClientList);
  const proposalList = useSelector((state) => state.proposals.proposalList);
  const clientProposalStatusList = useSelector(
    (state) => state.proposals.clientProposalStatusList
  );

  const [groupedProposals, setGroupedProposals] = useState([]);
  const [isExpanded, setIsExpanded] = useState([]);
  const [filterBtn, setFilterBtn] = useState(false);
  const [addfilter, setAddFilter] = useState({
    status: [],
    client: [],
  });
  const [filterProposal, setFilterProposal] = useState([]);

  useEffect(() => {
    if (filterProposal) {
      const grouped = groupByClient(filterProposal);
      let sorted = grouped.sort((a, b) => {
        return new Date(b.proposalId) - new Date(a.proposalId);
      });
      setGroupedProposals(sorted);
    }
  }, [filterProposal, proposalStatusFilter]);

  function openModal() {
    setUploadExistingProposalModalIsOpen(true);
  }

  function closeModal() {
    setUploadExistingProposalModalIsOpen(false);
    setProposal(null);
  }

  const handleChangeSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    const filteredData = proposalList?.data?.docs?.filter((list) =>
      list?.client?.client_name.toLowerCase().includes(value.toLowerCase())
    );

    setPagination({
      ...pagination,
      currentPage: 0,
      totalCount: filteredData.length,
    });
  };

  useEffect(() => {
    if (!allClients?.data?.length) dispatch(getFullClientList());
    // Get the Propposal Status List
    dispatch(GetProposalStatusList());
  }, []);

  const getProposalList = async () => {
    await dispatch(
      GetProposalList({ page: page, perPage: 50, search: searchValue })
    );
  };

  useEffect(() => {
    getProposalList();
  }, [dispatch, page, searchValue]);

  useEffect(() => {
    setSearchData(proposalList?.data?.docs);
    setFilterProposal(proposalList?.data?.docs);
  }, [proposalList?.data?.docs]);

  useEffect(() => {
    const filterData = proposalList?.data?.docs?.filter(
      (value) =>
        (addfilter?.status?.length
          ? addfilter.status.includes(value?.proposal_status?._id)
          : true) &&
        (addfilter?.client?.length
          ? addfilter.client.includes(value.client?._id)
          : true)
      // You can chain more conditions here as needed
    );

    setFilterProposal(filterData);
  }, [addfilter]);

  const handleDelete = async (id) => {
    const reloadProposal = async () => {
      await getProposalList();
    };
    dispatch(DeleteExistingProposal(id, reloadProposal));
  };

  const handleOpenProposalTemplateModal = () => {
    setProposalTemplateModalIsOpen(true);
    setSelectClientModalIsOpen(false);
  };

  const handleOpenSelectTemplateModal = () => {
    setProposalTemplateModalIsOpen(false);
    setSelectTemplateModalIsOpen(true);
  };

  return (
    <>
      <AdminNavbar
        title="Proposals Dashboard"
        subtitle="Create, view, edit and send proposals"
      />
      <div className="px-5 lg:px-12 5xl:px-[96px] mr-auto md:px-5 pt-0 w-full">
        <div className="md:mt-[32px] mt-[16px] text-right">
          <div className="flex items-center justify-between">
            <div className="flex w-full">
              {authSelector?.user?.invited === false ||
              (authSelector?.user?.owner &&
                authSelector?.user?.invited === true) ? (
                <DashboardButton
                  title="Create Proposal"
                  onClick={() => setSelectClientModalIsOpen(true)}
                />
              ) : null}
              {authSelector?.user?.invited === false ||
              (authSelector?.user?.owner &&
                authSelector?.user?.invited === true) ? (
                <div className="ml-4">
                  <DashboardButton
                    title="Upload Proposal"
                    onClick={openModal}
                    variant="secondary"
                  />
                </div>
              ) : null}
            </div>
            <div className="flex items-center justify-center mr-2">
              {/* {proposalStatusFilter !== "All" && (
                <div className="flex items-center">
                  <button
                    onClick={() => setProposalStatusFilter("All")}
                    className="ml-2 focus-visible:outline-none"
                  >
                    <button
                      className={`text-Neutral900 text-sm font-semibold rounded px-2 py-1 flex space-x-2 items-center ${
                        proposalStatusFilter == "accepted"
                          ? "bg-green-200 text-Green900"
                          : proposalStatusFilter == "declined"
                          ? "bg-red-200 text-Red900"
                          : proposalStatusFilter == "sent"
                          ? "bg-blue-200 text-Blue900"
                          : proposalStatusFilter == "none"
                          ? "bg-orange-200 text-Yellow900"
                          : "bg-neutral-200 text-Neutral900"
                      }`}
                    >
                      {proposalStatusFilter === "none"
                        ? "Creating"
                        : proposalStatusFilter}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 6L6 18"
                          stroke="#fff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 6L18 18"
                          stroke="#fff"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </button>
                </div>
              )} */}
            </div>
            {proposalList?.data?.docs?.length > 0 || searchValue !== "" ? (
              <SearchBar
                searchValue={searchValue}
                handleChangeSearch={handleChangeSearch}
                isFilter={true}
                setFilterBtn={setFilterBtn}
              />
            ) : null}
          </div>
        </div>
        <div className="flex items-center pt-4">
          <span className="pr-2 focus-visible:outline-none">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#5A5A66"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                stroke="#5A5A66"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.92993 4.92969L9.16993 9.16969"
                stroke="#5A5A66"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.8301 14.8301L19.0701 19.0701"
                stroke="#5A5A66"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.8301 9.16969L19.0701 4.92969"
                stroke="#5A5A66"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.8301 9.16965L18.3601 5.63965"
                stroke="#5A5A66"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.92993 19.0701L9.16993 14.8301"
                stroke="#5A5A66"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p className="text-xs font-normal text-Neutral800">
            Struggle with Proposals?
          </p>
          <a
            className="ml-1 text-xs font-semibold underline text-AccentRegular"
            target="_blank"
            href="https://www.clientmanager.io/blog/web-design-proposal-free-download"
            rel="noreferrer"
          >
            Read This
          </a>
        </div>
        {proposalList.isLoading &&
        !proposalList?.data?.totalDocs &&
        !searchdata ? (
          <ProposalsListLoader />
        ) : (
          <div className="my-12">
            {(proposalList?.data?.totalDocs ||
              proposalList?.data.totalDocs === 0) &&
              searchValue && (
                <h5 className="mb-3 text-xs font-semibold text-Neutral700">
                  {proposalList?.data.totalDocs}{" "}
                  {proposalList?.data.totalDocs === 1
                    ? "PROPOSAL"
                    : "PROPOSALS"}
                </h5>
              )}
            {(!searchdata || !proposalList?.data?.totalDocs) &&
              !searchValue && <NoProposalComponent />}
            {searchValue && proposalList?.data.totalDocs === 0 && (
              <NotDataFound />
            )}

            {groupedProposals?.map((client, index) => (
              <div
                key={index}
                className="p-4 mb-4 overflow-x-auto bg-white border rounded-lg shadow-md scrollbar-h-4 md:overflow-visible"
              >
                <div className="w-[700px] md:w-full ">
                  <div
                    className="flex flex-wrap items-center justify-between gap-3 cursor-pointer"
                    onClick={() =>
                      setIsExpanded((prev) => {
                        const newArr = [...prev];

                        newArr[index] = !prev[index];

                        return newArr;
                      })
                    }
                  >
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        {client?.profile_path ? (
                          <img
                            className="w-12 h-12 rounded min-w-12 5xl:h-16 5xl:w-16"
                            src={client?.profile_path}
                            alt=""
                          />
                        ) : (
                          <div
                            className={`h-12 w-12 min-w-12 5xl:h-16 5xl:w-16 rounded items-center justify-center bg-Neutral400 text-Neutral700`}
                          >
                            <span className=" text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey capitalize">
                              {client?.client_name?.charAt(0)}
                            </span>
                          </div>
                        )}
                      </div>
                      {/*  */}
                      <div className="flex-col flex-1 min-w-0 space-y-1">
                        <h4 className="w-full px-1 text-sm font-semibold text-Neutral900">
                          {client?.client_name}
                        </h4>
                        <span className="text-[10px] 5xl:text-[14px] py-1 px-2 text-indigo-500 font-semibold border border-violet-200 rounded flex items-center gap-1 w-full min-w-[100px]">
                          <svg
                            className="w-3 h-3"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            ></path>
                          </svg>
                          <span>All Proposals</span>
                        </span>
                      </div>
                      {/*  */}
                    </div>
                    <div className="flex flex-wrap items-center gap-2">
                      {clientProposalStatusList?.data?.map((statusObj) => {
                        const borderColor = (name = "") => {
                          const colorMap = {
                            accepted: `outline-Green700`,
                            declined: `outline-Red700`,
                            sent: `outline-Blue700`,
                            viewed: `outline-Yellow800`,
                            custom: `outline-Blue800`,
                          };
                          return colorMap[name] ?? colorMap.custom;
                        };

                        const isCurrentFilter =
                          proposalStatusFilter === statusObj?.name;

                        return (
                          <div
                            key={statusObj._id} // assuming _id is unique
                            className={`px-4 pt-[18px] pb-2 rounded flex-shrink-0 flex flex-col items-start justify-start min-w-[100px] 3xl:w-[200px] md:w-[100px] text-left cursor-pointer ${
                              statusObj?.color?.bgActive
                            } ${
                              isCurrentFilter &&
                              `outline outline-1 ${borderColor(
                                statusObj?.name
                              )}`
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (statusObj.name === proposalStatusFilter) {
                                setProposalStatusFilter("All");
                              } else {
                                setProposalStatusFilter(statusObj.name);
                              }
                            }}
                          >
                            <h3 className="mb-2 text-3xl font-extrabold leading-5 tracking-tighter text-zinc-500">
                              {
                                client?.proposals?.filter(
                                  (proposal) =>
                                    proposal?.proposal_status?.name ===
                                    statusObj.name
                                ).length
                              }
                            </h3>
                            <p className="opacity-90 text-zinc-600 text-xs w-[100%] uppercase">
                              {statusObj.name.toUpperCase()}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <table
                    className={`w-full table-fixed bg-neutral-50 rounded-lg px-4 py-2 ${
                      isExpanded[index]
                        ? "w-full table-fixed bg-neutral-50 rounded-lg px-4 py-2 mt-4"
                        : "h-0 hidden overflow-hidden"
                    }`}
                  >
                    {client?.proposals
                      .filter((proposal) => {
                        if (proposalStatusFilter !== "All") {
                          return (
                            proposal?.proposal_status?.name ===
                            proposalStatusFilter
                          );
                        } else {
                          return proposal;
                        }
                      })
                      .map((proposal, index) => {
                        return (
                          <tr key={proposal._id} className="border-Neutral400">
                            {/* Proposal Index No. */}
                            <td className="w-[5%] xl:max-w-[10%] max-w-[10%] py-5 px-3">
                              <p className="flex items-center justify-center w-6 h-6 text-sm font-medium rounded-full text-Neutral900">
                                {index + 1}
                              </p>
                            </td>

                            {/* Proposal Title */}
                            <td className="px-3 py-5">
                              <p className="text-sm font-medium truncate text-Neutral900">
                                {proposal.title
                                  ? proposal.title
                                  : `Proposal ${index + 1}`}
                              </p>
                            </td>

                            {/* Proposal's Client's Email. */}
                            <td className="w-full max-w-[25%] text-left 2xl:px-3 px-2">
                              <p className="text-sm font-medium truncate text-Neutral700">
                                {proposal?.client?.email}
                              </p>
                            </td>

                            {/* <td className="text-center w-[5%]">
                              <div className="flex px-1">
                                {proposal?.is_external_proposal === true ? (
                                  <a
                                    href={`${
                                      proposal?.existing_file_url
                                        ? proposal?.existing_file_url
                                        : proposal?.existing_file_upload?.url
                                    }`}
                                    target="_blank"
                                    className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out cursor-pointer"
                                    rel="noreferrer"
                                  >
                                    <img className="5xl:w-[20px]" src={Visible} alt="" />
                                  </a>
                                ) : (
                                  <Link
                                    to={`/public-proposal/${proposal.client._id}/proposal/${proposal?._id}`}
                                    target="_blank"
                                    className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                                  >
                                    <img className="5xl:w-[20px]" src={Visible} alt="" />
                                  </Link>
                                )}
                              </div>
                            </td>

                            <td className="text-center w-[5%]">
                              <div className="flex px-1">
                                {proposal?.is_external_proposal ? (
                                  <a
                                    href={`${
                                      proposal?.existing_file_url
                                        ? proposal?.existing_file_url
                                        : proposal?.existing_file_upload?.url
                                    }`}
                                    className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[120px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out cursor-pointer"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img className="5xl:w-[20px]" src={Download} alt="" />
                                  </a>
                                ) : (
                                  <Link
                                    to={`/admin/clients/manage/${proposal.client._id}/proposal/${proposal?._id}?preview=true`}
                                    target="_blank"
                                    className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[120px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                                  >
                                    <img className="5xl:w-[20px]" src={Download} alt="" />
                                  </Link>
                                )}
                              </div>
                            </td> */}

                            {/* <td className="text-center w-[5%]">
                              <div className="flex px-1 ">
                                {proposal?.is_external_proposal === true ? (
                                  <button
                                    onClick={() => {
                                      setUploadExistingProposalModalIsOpen(true);
                                      setProposal(proposal);
                                    }}
                                    className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                                  >
                                    <img className="5xl:w-[14px]" src={Edit} alt="" />
                                  </button>
                                ) : (
                                  <Link
                                    to={`/admin/clients/manage/${proposal.client._id}/proposal/${proposal._id}`}
                                    className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                                  >
                                    <img className="xl:w-[14px]" src={Edit} alt="" />
                                  </Link>
                                )}
                              </div>
                            </td>

                            <td className="text-center w-[5%]">
                              <div className="flex px-1 ">
                                <button
                                  onClick={() => {
                                    setIsEmailModalOpen(true);
                                    setSelectProposal(proposal);
                                  }}
                                  className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                                >
                                  <MessageIcon className="w-[14px]" color="#6D6DF2" />
                                </button>
                              </div>
                            </td>

                            <td className="text-center w-[5%]">
                              <div className="flex px-1 ">
                                <Menu as="div" className="relative inline-block w-full">
                                  <div className="flex items-center">
                                    <Menu.Button
                                      className="relative inline-flex justify-center w-full focus-visible:outline-none"
                                      onClick={(e) => {
                                        // e.stopPropagation();
                                      }}
                                    >
                                      <span className="text-[8px] 5xl:text-[12px] font-semibold 5xl:w-[75px] w-full 5xl:h-9 h-[28px] flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-[#E56780] focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out mr-2">
                                        <svg
                                          width="14"
                                          height="16"
                                          viewBox="0 0 14 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M1.16663 4.62195H2.35922H11.9"
                                            stroke="#E56780"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M4.14729 4.62252V3.45426C4.14729 3.14442 4.27294 2.84727 4.49659 2.62818C4.72024 2.40909 5.02359 2.28601 5.33988 2.28601H7.72507C8.04136 2.28601 8.3447 2.40909 8.56836 2.62818C8.79201 2.84727 8.91766 3.14442 8.91766 3.45426V4.62252M10.7065 4.62252V12.8003C10.7065 13.1101 10.5809 13.4073 10.3572 13.6264C10.1336 13.8455 9.83025 13.9686 9.51396 13.9686H3.55099C3.2347 13.9686 2.93136 13.8455 2.7077 13.6264C2.48405 13.4073 2.3584 13.1101 2.3584 12.8003V4.62252H10.7065Z"
                                            stroke="#E56780"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </span>
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      className="z-10 origin-top-right absolute -top-[80px] right-[-20px] pt-4 pb-6 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none"
                                      onClick={(e) => {
                                        if (e.target === e.currentTarget) {
                                          e.stopPropagation();
                                        }
                                      }}
                                    >
                                      <div className="">
                                        <p className="pb-2 text-xs font-semibold text-Neutral700">
                                          Are you sure?
                                        </p>
                                      </div>
                                      <div
                                        className="overflow-hidden border-0"
                                        onClick={() => handleDelete(proposal?._id)}
                                      >
                                        <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none">
                                          <h3 className="w-full py-4 mt-5 text-xs font-semibold text-center text-white duration-300 rounded cursor-pointer bg-Red400 hover:bg-Red600">
                                            Yes, Delete
                                          </h3>
                                        </Menu.Button>
                                      </div>
                                      <div className="overflow-hidden border-0 !border-transparent divide-0">
                                        <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none">
                                          <h3 className="w-full py-4 mt-3 text-xs font-semibold text-center duration-300 rounded cursor-pointer text-Neutral800 bg-Neutral200 hover:bg-Neutral500 hover:text-Neutral900">
                                            No, Please Keep It
                                          </h3>
                                        </Menu.Button>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </td> */}

                            <td>
                              <div className="flex gap-1">
                                {/* View Button */}
                                <div className="flex-1 px-1">
                                  {proposal?.is_external_proposal === true ? (
                                    <a
                                      href={`${
                                        proposal?.existing_file_url
                                          ? proposal?.existing_file_url
                                          : proposal?.existing_file_upload?.url
                                      }`}
                                      target="_blank"
                                      className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out cursor-pointer"
                                      rel="noreferrer"
                                    >
                                      <img
                                        className="5xl:w-[20px]"
                                        src={Visible}
                                        alt=""
                                      />
                                    </a>
                                  ) : (
                                    <Link
                                      to={`/public-proposal/${proposal.client._id}/proposal/${proposal?._id}`}
                                      target="_blank"
                                      className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                                    >
                                      <img
                                        className="5xl:w-[20px]"
                                        src={Visible}
                                        alt=""
                                      />
                                    </Link>
                                  )}
                                </div>

                                {/* Download Button */}
                                <div className="flex-1 px-1">
                                  {proposal?.is_external_proposal ? (
                                    <a
                                      href={`${
                                        proposal?.existing_file_url
                                          ? proposal?.existing_file_url
                                          : proposal?.existing_file_upload?.url
                                      }`}
                                      className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[120px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out cursor-pointer"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        className="5xl:w-[20px]"
                                        src={Download}
                                        alt=""
                                      />
                                    </a>
                                  ) : (
                                    <Link
                                      to={`/admin/clients/manage/${proposal.client._id}/proposal/${proposal?._id}?preview=true`}
                                      target="_blank"
                                      className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[120px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                                    >
                                      <img
                                        className="5xl:w-[20px]"
                                        src={Download}
                                        alt=""
                                      />
                                    </Link>
                                  )}
                                </div>

                                {/* Edit Button */}
                                <div className="flex-1 px-1">
                                  {proposal?.is_external_proposal === true ? (
                                    <button
                                      onClick={() => {
                                        setUploadExistingProposalModalIsOpen(
                                          true
                                        );
                                        setProposal(proposal);
                                      }}
                                      className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                                    >
                                      <img
                                        className="5xl:w-[14px]"
                                        src={Edit}
                                        alt=""
                                      />
                                    </button>
                                  ) : (
                                    <Link
                                      to={`/admin/clients/manage/${proposal.client._id}/proposal/${proposal._id}`}
                                      className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                                    >
                                      <img
                                        className="xl:w-[14px]"
                                        src={Edit}
                                        alt=""
                                      />
                                    </Link>
                                  )}
                                </div>

                                {/* Email Button */}
                                <div className="flex-1 px-1">
                                  <button
                                    onClick={() => {
                                      setIsEmailModalOpen(true);
                                      setSelectProposal(proposal);
                                    }}
                                    className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[80px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-AccentRegular focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out"
                                  >
                                    <MessageIcon
                                      className="w-[14px]"
                                      color="#6D6DF2"
                                    />
                                  </button>
                                </div>

                                {/* Delete Button */}
                                <div className="flex-1 px-1">
                                  <Menu
                                    as="div"
                                    className="relative inline-block w-full"
                                  >
                                    <div className="flex items-center">
                                      <Menu.Button
                                        className="relative inline-flex justify-center w-full focus-visible:outline-none"
                                        onClick={(e) => {
                                          // e.stopPropagation();
                                        }}
                                      >
                                        <span className="text-[10px] 5xl:text-[14px] font-semibold 5xl:w-[120px] w-full 5xl:h-9 h-7 flex items-center justify-center rounded leading-[1.4] border border-Neutral300 hover:border-Red400 focus:border-0 focus:outline focus:outline-AccentMediumLight bg-Neutral000 text-AccentRegular transition duration-300 ease-in-out cursor-pointer">
                                          <svg
                                            width="14"
                                            height="16"
                                            viewBox="0 0 14 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1.16663 4.62195H2.35922H11.9"
                                              stroke="#E56780"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M4.14729 4.62252V3.45426C4.14729 3.14442 4.27294 2.84727 4.49659 2.62818C4.72024 2.40909 5.02359 2.28601 5.33988 2.28601H7.72507C8.04136 2.28601 8.3447 2.40909 8.56836 2.62818C8.79201 2.84727 8.91766 3.14442 8.91766 3.45426V4.62252M10.7065 4.62252V12.8003C10.7065 13.1101 10.5809 13.4073 10.3572 13.6264C10.1336 13.8455 9.83025 13.9686 9.51396 13.9686H3.55099C3.2347 13.9686 2.93136 13.8455 2.7077 13.6264C2.48405 13.4073 2.3584 13.1101 2.3584 12.8003V4.62252H10.7065Z"
                                              stroke="#E56780"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      </Menu.Button>
                                    </div>

                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items
                                        className="z-10 origin-top-right absolute -top-[80px] right-[-20px] pt-4 pb-6 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none"
                                        onClick={(e) => {
                                          if (e.target === e.currentTarget) {
                                            e.stopPropagation();
                                          }
                                        }}
                                      >
                                        <div className="">
                                          <p className="pb-2 text-xs font-semibold text-Neutral700">
                                            Are you sure?
                                          </p>
                                        </div>
                                        <div
                                          className="overflow-hidden border-0"
                                          onClick={() =>
                                            handleDelete(proposal?._id)
                                          }
                                        >
                                          <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none">
                                            <h3 className="w-full py-4 mt-5 text-xs font-semibold text-center text-white duration-300 rounded cursor-pointer bg-Red400 hover:bg-Red600">
                                              Yes, Delete
                                            </h3>
                                          </Menu.Button>
                                        </div>
                                        <div className="overflow-hidden border-0 !border-transparent divide-0">
                                          <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none">
                                            <h3 className="w-full py-4 mt-3 text-xs font-semibold text-center duration-300 rounded cursor-pointer text-Neutral800 bg-Neutral200 hover:bg-Neutral500 hover:text-Neutral900">
                                              No, Please Keep It
                                            </h3>
                                          </Menu.Button>
                                        </div>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </div>
                              </div>
                            </td>

                            <td className="text-center w-[10%]">
                              <div className="flex items-center justify-end px-0 transition duration-300 2xl:px-2">
                                <span
                                  className={`text-[10px] 5xl:text-[14px] py-1 px-2 font-semibold ${proposal.status} ${proposal.status} rounded-2xl leading-tight`}
                                >
                                  <ProposalsStatus
                                    status={proposal?.proposal_status?.name}
                                    id={proposal?.client?._id}
                                    proposal_id={proposal?._id}
                                  />
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                </div>
              </div>
            ))}

            {proposalList?.data?.totalDocs ? (
              <Pagination
                currantPage={proposalList.data.page}
                totalData={proposalList.data.totalDocs}
                hasPrevPage={proposalList.data.prevPage}
                hasNextPage={proposalList.data.nextPage}
                fromRecord={proposalList?.data?.fromRecord}
                setPage={setPage}
              />
            ) : null}
          </div>
        )}
      </div>

      <UploadExistingProposalModal
        modalIsOpen={uploadExistingProposalModalIsOpen}
        closeModal={closeModal}
        client={allClients}
        getProposalList={getProposalList}
        proposal={proposal}
      />

      <Modal
        isOpen={selectClientModalIsOpen}
        onRequestClose={() => setSelectClientModalIsOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="View Form Preview Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all w-[80vw] sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-12 rounded-[8px]">
            <div>
              <div className="text-left">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                  id="modal-title"
                >
                  SELECT CLIENT
                </h3>
                <div className="mb-8">
                  <label className="block mb-2 text-xs font-bold text-Neutral900">
                    Client
                  </label>
                  <ClientSelect
                    allClients={allClients}
                    onChange={(e) => {
                      setSelectProposal(e);
                    }}
                  />
                </div>
                <button
                  className={`btn button-hover large-primary-btn text-sm text-center inline-block text-Neutral100 bg-AccentRegular font-semibold w-full min-w-[148px]  p-3 rounded drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out ${
                    !selectProposal && "pointer-events-none"
                  }`}
                  onClick={() => {
                    handleOpenProposalTemplateModal();
                  }}
                >
                  <span className="relative z-10 drop-shadow-Texts">
                    Continue to Proposal Builder
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setSelectClientModalIsOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>

      <ProposalFilter
        filterBtn={filterBtn}
        setFilterBtn={setFilterBtn}
        addfilter={addfilter}
        setAddFilter={setAddFilter}
      />

      <NewEntityTemplateModal
        type={"Proposal"}
        isOpen={proposalTemplateModalIsOpen}
        setIsOpen={setProposalTemplateModalIsOpen}
        clientId={selectProposal?._id}
        onCustomTemplateClick={handleOpenSelectTemplateModal}
      />

      <SelectTemplate
        type={"Proposal"}
        isOpen={selectTemplateModalIsOpen}
        setIsOpen={setSelectTemplateModalIsOpen}
        clientId={selectProposal?._id}
        openNewTemplateModal={() => {
          setSelectTemplateModalIsOpen(false);
          setNewTemplateModalIsOpen(true);
        }}
      />

      <NewTemplateModal
        type={"Proposal"}
        isOpen={newTemplateModalIsOpen}
        setIsOpen={setNewTemplateModalIsOpen}
      />
      {isEmailModalOpen && (
        <FollowUpEmailModal
          isOpen={isEmailModalOpen}
          setIsOpen={setIsEmailModalOpen}
          data={selectProposal?.client}
          type="Proposal"
          id={selectProposal?._id}
          selectedData={selectProposal}
          IsDashboard='dash'
        />
      )}
    </>
  );
};

export default Proposals;
