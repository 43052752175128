import { Menu, Transition } from "@headlessui/react";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  AddUserCategoryAction,
  RemoverUserCategoryAction,
} from "../../../../../store/actions/authAction";
import { UpdateServiceActions } from "../../../../../store/actions/serviceAction";
import { UpdateTaskActions } from "../../../../../store/actions/tasksAction";
import MenuIcon from "../Icons/MenuIcon";

const colorList = [
  { value: "Green400", label: "Green400" },
  { value: "Blue400", label: "Blue400" },
  { value: "Pink400", label: "Pink400" },
  { value: "AccentRegular", label: "AccentRegular" },
  { value: "Red400", label: "Red400" },
  { value: "Orange400", label: "Orange400" },
];

const CategoryTags = ({
  isNew,
  item,
  types,
  clientDetail,
  project,
  user,
  //TODO: hardcode:
  isFullSize,
  user_type = null,
  access_level,
}) => {
  const dispatch = useDispatch();
  const tagDropDownRef = useRef(null);
  const [tagList, setTagList] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);
  const [customTag, setCustomTag] = useState("");
  const [isOpen, setIsOpen] = useState(null);

  const assignColorToTag = (index) => {
    if (index > colorList.length - 1) {
      return colorList[index % colorList.length]?.value;
    } else {
      return colorList[index]?.value;
    }
  };

  useEffect(() => {
    if (
      item?.category &&
      tagList?.length === 0 &&
      currentTags?.length === 0 &&
      types === "task" &&
      user?.category
    ) {
      if (types === "task") {
        const savedTags = item?.category.map((savedTag, i) => {
          return {
            value: savedTag,
            label: savedTag,
            //  assign color to tag from colorList array but item?.category.length > colorList.length then assign color from colorList array again from start
            color: assignColorToTag(i),

            bgColor: assignColorToTag(i),
          };
        });
        let tempCategoryMerge = [];

        if (user?.category?.length) {
          tempCategoryMerge = [
            ...new Set([...tempCategoryMerge, ...user?.category]),
          ];
        }

        const currentTagList = tempCategoryMerge?.filter(
          (defaultTag) => !savedTags?.some((tag) => tag?.value === defaultTag)
        );

        const createTempTagList = currentTagList?.map((item, i) => {
          return {
            value: item,
            label: item,
            color: assignColorToTag(savedTags?.length + i),
            bgColor: assignColorToTag(savedTags?.length + i),
          };
        });

        setTagList(createTempTagList);
        setCurrentTags(savedTags);
      }

      if (types === "service") {
        const savedTags = item?.category.map((savedTag, i) => {
          return {
            value: savedTag,
            label: savedTag,
            color: assignColorToTag(i),
            bgColor: assignColorToTag(i),
          };
        });
        const currentTagList = clientDetail?.category.filter(
          (defaultTag) => !savedTags?.some((tag) => tag.value === defaultTag)
        );

        const createTempTagList = currentTagList?.map((item, i) => {
          return {
            value: item,
            label: item,
            color: assignColorToTag(savedTags?.length + i),
            bgColor: assignColorToTag(savedTags?.length + i),
          };
        });

        setTagList(createTempTagList);
        setCurrentTags(savedTags);
      }
    }
  }, [
    clientDetail?.category,
    currentTags?.length,
    item?.category,
    tagList?.length,
    types,
    user?.category,
  ]);

  const handleRemove = (data) => {
    const removedCategory = dispatch(RemoverUserCategoryAction(data?.value));

    if (removedCategory?.data) {
      setTagList(tagList.filter((e) => e !== data));
    }
  };

  const handleSubmit = useCallback(() => {
    if (JSON.stringify(item?.category) !== JSON.stringify(currentTags)) {
      if (types === "task") {
        if (user_type) {
          dispatch(
            UpdateTaskActions({
              ...item,
              category: currentTags.map((tag) => tag.label),
              user_type: "client_user",
            })
          );
        } else {
          dispatch(
            UpdateTaskActions({
              ...item,
              category: currentTags.map((tag) => tag.label),
            })
          );
        }
      }
      if (types === "service") {
        dispatch(
          UpdateServiceActions({
            ...item,
            category: currentTags?.map((tag) => tag.label),
          })
        );
      }
    }
  }, [currentTags, dispatch, item, types, user_type]);

  useEffect(() => {
    if (isOpen === false) {
      handleSubmit();
    }
  }, [handleSubmit, isOpen]);

  return (
    <Menu
      as="div"
      className={`rounded-t	w-fit py-1.5 pr-2 inline-block text-left relative`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {({ open }) => {
        if (open) setIsOpen(true);
        if (!open && isOpen === true) setIsOpen(false);

        return (
          <>
            <Menu.Button
              ref={tagDropDownRef}
              className="flex items-center justify-center gap-2 focus:outline-none"
              onClick={() => {}}
              disabled={
                (!user_type &&
                  (clientDetail?.is_admin ||
                  clientDetail?.is_manager ||
                  item?.created_by?._id === user?._id
                    ? false
                    : true)) ||
                (user_type && access_level !== "full_access")
              }
            >
              <div className="flex">
                <ul className="flex flex-wrap gap-1">
                  {currentTags?.map((item) => {
                    return (
                      <li
                        key={item.value}
                        className={`rounded-md bg-${item.color} uppercase py-1 px-2 flex flex-nowrap items-center font-semibold text-[10px] text-Neutral000`}
                        onClick={() => {
                          // const isDefaultTag = project?.category.find(
                          //   (tag) => tag === item.value
                          // );

                          // if (isDefaultTag) {
                          //   setTagList([...tagList, item]);
                          // }

                          const newCurrentTags = currentTags.filter((tag) => {
                            return tag.value !== item.value;
                          });
                          setCurrentTags(newCurrentTags);

                          setTagList([...tagList, item]);
                        }}
                      >
                        {item.label}
                      </li>
                    );
                  })}
                </ul>

                <div>{!isNew && <MenuIcon />}</div>
              </div>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right max-h-[45vh] overflow-y-auto absolute z-[99] top-[100%] -left-4 pt-3 pb-2 w-[216px] rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none">
                <div className="">
                  <input
                    className="w-full px-3 mb-3 text-xs font-normal placeholder:text-Neutral600 text-Neutral700 focus:outline-none"
                    placeholder="+Add New Category"
                    value={customTag}
                    onKeyUp={(e) => {
                      e.stopPropagation();
                    }}
                    onKeyDown={async (e) => {
                      e.stopPropagation();
                      if (e.key === "Enter") {
                        if (
                          // defaultTags.filter(
                          //   (t) =>
                          //     t.value.toLowerCase() === customTag.toLowerCase()
                          // ).length === 0
                          project?.category.filter(
                            (t) => t.toLowerCase() === customTag.toLowerCase()
                          ).length === 0
                        ) {
                          const newTag = {
                            value: customTag,
                            label: customTag.toUpperCase(),
                            color: "Orange400",
                            bgColor: "Orange500",
                          };
                          setCurrentTags([...currentTags, newTag]);
                          dispatch(AddUserCategoryAction(customTag));
                          setCustomTag("");
                          setTimeout(() => {
                            tagDropDownRef?.current?.click();
                          }, 0);
                        } else {
                          toast.error("Title already exists");
                        }
                      }
                    }}
                    onChange={(e) => {
                      setCustomTag(e.target.value.toUpperCase());
                    }}
                  />
                </div>
                <div className="project-title-divide">
                  <div className="overflow-hidden border-0 h-[100%] overflow-y-auto currancy-scrollbar scrollbar-w-4 relative flex flex-col gap-1 px-1">
                    <span className="font-semibold text-Neutral600 text-[10px] pl-2">
                      Task Categories
                    </span>
                    {tagList?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="flex items-center px-2 py-1 duration-300 rounded-sm cursor-pointer hover:bg-Neutral200 group"
                          onClick={(e) => {
                            setTimeout(() => {
                              tagDropDownRef?.current?.click();
                              e.stopPropagation();
                            }, 0);

                            const newTagList = tagList.filter((tag) => {
                              return tag.value !== item.value;
                            });
                            setTagList(newTagList);

                            setCurrentTags([...currentTags, item]);
                          }}
                        >
                          <p className="flex items-center w-full gap-2 font-normal uppercase rounded cursor-pointer text-Neutral800 group-hover:text-Neutral900 ">
                            <span
                              className={`w-2 h-2 rounded-full bg-${item?.color}`}
                            ></span>
                            <span className="text-xs font-medium ">
                              {item.value}
                            </span>
                          </p>
                          <button
                            className="text-transparent group-hover:text-Red500"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemove(item);
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.23096 5.04419H4.33181H13.1386"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5.98234 5.04469V4.02247C5.98234 3.75136 6.09832 3.49135 6.30477 3.29965C6.51122 3.10794 6.79123 3.00024 7.08319 3.00024H9.2849C9.57687 3.00024 9.85687 3.10794 10.0633 3.29965C10.2698 3.49135 10.3858 3.75136 10.3858 4.02247V5.04469M12.037 5.04469V12.2002C12.037 12.4714 11.9211 12.7314 11.7146 12.9231C11.5082 13.1148 11.2281 13.2225 10.9362 13.2225H5.43191C5.13994 13.2225 4.85994 13.1148 4.65349 12.9231C4.44704 12.7314 4.33105 12.4714 4.33105 12.2002V5.04469H12.037Z"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </>
        );
      }}
    </Menu>
  );
};

export default CategoryTags;
