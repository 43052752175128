import axios from "axios";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect } from "react";

const MigrateProposal = () => {
  useEffect(() => {
    const getProposalsList = async () => {
      const proposalList = await axios.get(
        "/api/v1/proposal/get-all-proposals"
      );
      const aaa = proposalList?.data?.data?.filter(
        (item) =>
          item?.content?.content?.blocks?.length > 0 ||
          item?.content?.blocks?.length > 0
      );
      if (aaa.length > 0) {
        aaa.map(async (item) => {
          const qqq = await EditorState.createWithContent(
            convertFromRaw(item?.content?.content || item?.content)
          );
          const rawContent = convertToRaw(qqq.getCurrentContent());
          const convertHtml = draftToHtml(rawContent);
          const value = {
            ...item,
            content: convertHtml,
            old_content: item?.content,
            cover: item?.cover?.url ? item?.cover : item?.content?.cover,
          };
          const { data } = await axios.post(
            "/api/v1/proposal/save-all-proposal-content",
            value
          );
          console.log("data", data);
        });
      } else {
        alert("All proposal content is migrated");
      }
    };
    getProposalsList();
  }, []);

  return <div>MigrateProposal</div>;
};

export default MigrateProposal;
